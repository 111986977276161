import React, {useEffect, useRef} from 'react';
import ListOfButtons from './ListOfButtons';
import MarkButton from './MarkButton';
import {faP} from '@fortawesome/pro-solid-svg-icons';
import {faBold} from '@fortawesome/pro-solid-svg-icons';
import {faItalic} from '@fortawesome/pro-solid-svg-icons';
import {faUnderline} from '@fortawesome/pro-solid-svg-icons';
import {faAlignRight} from '@fortawesome/pro-solid-svg-icons';
import {faAlignLeft} from '@fortawesome/pro-solid-svg-icons';
import {faAlignJustify} from '@fortawesome/pro-solid-svg-icons';
import {faAlignCenter} from '@fortawesome/pro-solid-svg-icons';
import {faChevronDown} from '@fortawesome/pro-solid-svg-icons';
import {faQuoteLeft} from '@fortawesome/pro-solid-svg-icons';
import {faH1} from '@fortawesome/pro-solid-svg-icons';
import {faH2} from '@fortawesome/pro-solid-svg-icons';


const FixedToolbar = ({TEXT_ALIGN_TYPES, contentButtons}) => {
    //const editor = useSlate();
    //const inFocus = useFocused();
    //const ref = useRef();
  
    //const {selection} = editor;
  
  
    return (
      <>
         <div
          className={" text-sm  flex flex-row justify-center place-items-center  gap-4 z-20 h-12"}
        >
            <div>
              <ListOfButtons TEXT_ALIGN_TYPES={TEXT_ALIGN_TYPES} type="block" buttons={contentButtons} />
            </div>
            <div>
              <ListOfButtons TEXT_ALIGN_TYPES={TEXT_ALIGN_TYPES} type="block" buttons={[{'text': 'Left','format': 'left', 'icon': faAlignLeft}, {'text': 'Center', 'format': 'center', 'icon': faAlignCenter}, {'text': 'Right', 'format': 'right', 'icon': faAlignRight}, {'text': 'Justify', 'format': 'justify', 'icon': faAlignJustify}]} />
            </div>
            <div>
              <MarkButton format="bold" icon={faBold} />
            </div>
            <div>
              <MarkButton format="italic" icon={faItalic} />
            </div>
            <div>
              <MarkButton format="underline" icon={faUnderline} />
            </div>
            
        </div>
      </>
    )
  }

export default FixedToolbar;