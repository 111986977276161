import React from 'react';
import Modal from '../../general/Modal';
import Button from '../../general/Button';

const ModalContent = ({setLoading, loading, deleteDeck, item, closeFunction}) => {
    const deleteDeckClick = async () => {
        setLoading(true);
        await deleteDeck(item.id);
        closeFunction();
        setLoading(false); 
    }
    return (
        <>
            {!loading &&
                <>
                    <div className="bg-red-500 text-center p-4 rounded-lg shadow-lg">
                        <div className="text-xl text-white font-bold">Are you sure you want to continue?</div>
                    </div>
                    <div className="text-base text-center md:w-3/4 m-auto text-gray-700 px-4 py-4">By clicking on the button below, you confirm that you want to delete your deck <span className="font-bold">{item.name}</span>. This operation is irreversible.</div>
                    <div className="flex flex-row gap-3 justify-center">
                        <div>
                            <Button color="charcoal" text="Cancel" onClick={closeFunction} />
                        </div>
                        <div>
                            <Button color="red" text="DELETE DECK" onClick={deleteDeckClick} />
                        </div>
                    </div>
                </>
            }
            {loading && 
                <>
                    <div className="text-center">
                        Deleting deck...
                    </div>
                </>
            }
        </>
    )
}

const AllDecksViewDeleteItemModal = ({setLoading, loading, deleteDeck, item, closeFunction}) => {

    return (
        <>
            {item !== null &&
                <Modal size="big" content={<ModalContent loading={loading} setLoading={setLoading} deleteDeck={deleteDeck} item={item} closeFunction={closeFunction} />} />
            }
        </>
    )
};

export default AllDecksViewDeleteItemModal;
