import React, {useEffect, useState, useMemo} from 'react';
import usePages from '../../hooks/usePages';
import { createEditor, Descendant } from 'slate'
import { Slate, Editable, withReact } from 'slate-react'

const TermsOfService = () => {
    const editor = useMemo(() => withReact(createEditor()), []);
    const {fetchPage} = usePages();
    const [page, setPage] = useState(null);

    useEffect(()=>{
        const fetchData = async () => {
            let r = await fetchPage("terms-of-service");
            console.log(r);
            setPage(r);
        }
        fetchData();
    },[]);

  return (
    <>
        <div className="flex flex-col gap-4">
            {page !== null &&
                <>
                    <h1>{page.title.en}</h1>
                    <div className="text-center text-gray-600 text-sm">
                        Last updated: {page.last_updated_timestamp.toDate().toDateString()}
                    </div>
                    <div className="sm:w-4/6 mx-auto w-full text-center">
                        <Slate editor={editor} value={JSON.parse(page.body.en)}>
                            <Editable readOnly placeholder="" />
                        </Slate>
                    </div>
                </>
            }
        </div>
    </>
  )
}

export default TermsOfService;