

export default function useDebounce() {

    const debounce = (func, timeout = 300) => {
        let timer;
        return (...args) => {
          clearTimeout(timer);
          timer = setTimeout(() => { func.apply(this, args); }, timeout);
        };
    }

    const inverseDebounce = (func, timeout = 300) => {
        let timer;
        return (...args) => {
          if (!timer) {
            func.apply(this, args);
          }
          clearTimeout(timer);
          timer = setTimeout(() => {
            timer = undefined;
          }, timeout);
        };
      }

    return {
        debounce, 
        inverseDebounce
    }
}