import React, {useState, useEffect} from 'react';
import useFavorites from '../../hooks/useFavorites';
import {useLanguagesContext} from '../../contexts/LanguagesContext';
import {Link} from 'react-router-dom';
import DecksCard from '../decks/DecksCard';
import MyLibraryMenu from '../../components/menus/MyLibraryMenu';
import MyContentMenu from '../../components/menus/MyContentMenu';

const MyFavorites = () => {
    const {getUserFavorites} = useFavorites();
    const [favorites, setFavorites] = useState(null);
    const [openDeckMenuId, setOpenDeckMenuId] = useState(null);
    
    let {allLanguages,
        globalSelectedTargetLanguage
    } = useLanguagesContext();

    useEffect(()=>{
        const fetchData = async () => {
            console.log("Fetching favorites");
            let r = await getUserFavorites({'target': globalSelectedTargetLanguage});
            setFavorites(r[1]);
        }

        if (globalSelectedTargetLanguage !== null){
            fetchData();
        }
        
    },[getUserFavorites, globalSelectedTargetLanguage]);

    const closeDeckMenu = () => {
        setOpenDeckMenuId(null);
    }

    const openDeckMenuClick = (e, id) => {
        e.preventDefault();
        setOpenDeckMenuId(id);
    }
    //<MyLibraryMenu activeName="favorites" />
    return (
        <>
            <div className=" ">
                <div className="flex flex-col gap-8">
                    
                    <MyContentMenu activeName="favorites" />
                    <div>
                        {(allLanguages !== null && globalSelectedTargetLanguage !== null) &&
                            <>
                                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 text-center">
                                    {favorites !== null && favorites.map((item, index)=>(
                                        <>
                                            <DecksCard showAdminOptions={true} closeDeckMenu={closeDeckMenu} openDeckMenuId={openDeckMenuId} openDeckMenuClick={openDeckMenuClick} showProfileLink={false} baseLink={"decks"} languages={allLanguages} key={'deck_'+index} deck={item} />
                                        </> 
                                        ))
                                    }
                                    
                                    </div>
                                    {(favorites !== null && favorites.length === 0) &&
                                        <>
                                            <div className="text-center w-5/6 m-auto">
                                                You haven't saved any public {allLanguages[0][globalSelectedTargetLanguage]['name']['en']} decks to your favorites yet. Get started by saving your favorites in the <Link to="/decks">public decks</Link> directory.
                                            </div>
                                        </>
                                    }
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default MyFavorites;