import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {motion} from 'framer-motion';
import Icon from '../../../components/general/Icon';
import DeleteTableModal from '../../../components/tables/modals/DeleteTableModal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/pro-solid-svg-icons';
import {faLoader} from '@fortawesome/pro-solid-svg-icons';
import {faPlay} from '@fortawesome/pro-solid-svg-icons';
import {faBook} from '@fortawesome/pro-solid-svg-icons';
import {faEllipsisV} from '@fortawesome/pro-solid-svg-icons';
import {faTableColumns} from '@fortawesome/pro-solid-svg-icons';
import {faFolder} from '@fortawesome/pro-solid-svg-icons';
import {faNote} from '@fortawesome/pro-solid-svg-icons';
import {faCardsBlank} from '@fortawesome/pro-solid-svg-icons';
import {faUser} from '@fortawesome/pro-duotone-svg-icons';
import {faPencil} from '@fortawesome/pro-duotone-svg-icons';
import {faTrash} from '@fortawesome/pro-duotone-svg-icons';
import {faHeart} from '@fortawesome/pro-regular-svg-icons';
import {faHeart as faHeartSolid} from '@fortawesome/pro-solid-svg-icons';
import {faCopy} from '@fortawesome/pro-duotone-svg-icons';
import useTables from '../../../hooks/useTables';
import {useLanguagesContext} from '../../../contexts/LanguagesContext';
import Select from 'react-select';
import AddTableModal from '../../../components/tables/modals/AddTableModal';
import NoActiveTargetLanguage from '../../../components/errors/NoActiveTargetLanguage';
import MyFoldersTreeView from '../../../components/folders/MyFoldersTreeView';
import MyLibraryMenu from '../../../components/menus/MyLibraryMenu';

const MyTables = () => {
    const navigate = useNavigate();
    const [showAddTableModal, setShowAddTableModal] = useState(false);
    const {getTablesFromCurrentUserInRealtime, 
    saveNewTable, 
    deleteTable} = useTables();
    const [tables, setTables] = useState(null);
    const [showDeleteTableModal, setShowDeleteTableModal] = useState(false);
    const [deleteTableData, setDeleteTableData] = useState(null);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [viewMode, setViewMode] = useState("organized");
    const [tablesDbLoading, setTablesDbLoading] = useState(true);
    let {allLanguages, activeUserLanguages, globalSelectedTargetLanguage, setGlobalSelectedTargetLanguage, globalSelectedSourceLanguage, setGlobalSelectedSourceLanguage, activeTargetLanguageOptions, sourceLanguageOptions} = useLanguagesContext();

    // new table
    const [newTableSourceLanguage, setNewTableSourceLanguage] = useState(globalSelectedSourceLanguage);
    const [newTableName, setNewTableName] = useState("");
    const [newTableSaving, setNewTableSaving] = useState(false);
    const defaultTemplateOptions = [{label: 'No template', value: 'default'}, {label: 'Standard verb tenses', value: 'verb-tenses'}];
    const [selectedTemplateOption, setSelectedTemplateOption] = useState(defaultTemplateOptions[0]);

    const changeTemplateOption = (option) => {
        setSelectedTemplateOption(option);
    }

    // menu options
    const [openTableMenuId, setOpenTableMenuId] = useState(null);

    useEffect(()=>{
        if (globalSelectedTargetLanguage !== null){
            activeUserLanguages.forEach((lang)=>{    
                if (lang.target_language === globalSelectedTargetLanguage){
                    setGlobalSelectedSourceLanguage(lang.source_language);
                    setNewTableSourceLanguage(lang.source_language);
                }
            })
            !tablesDbLoading && setTablesDbLoading(true);
            getTablesFromCurrentUserInRealtime(globalSelectedTargetLanguage, setTables);
            setTablesDbLoading(false);
        }
    },[globalSelectedTargetLanguage]);

    useEffect(()=>{
        if (tables !== null){
            setTablesDbLoading(false);
        } else {
            setTablesDbLoading(true);
        }
    },[tables])

    const changeSelectedTargetLanguage = (option) => {
        setTables(null);
        setGlobalSelectedTargetLanguage(option.value);
    }

    const closeAddTableModal = () => {
        setShowAddTableModal(false);
        setNewTableName("");
    }

    const createTableClick = () => {
        setShowAddTableModal(true);
    }

    const saveNewTableInDb = async () => {
        setNewTableSaving(true);
        setTablesDbLoading(true);
        let r = await saveNewTable(globalSelectedTargetLanguage, newTableSourceLanguage, newTableName, selectedTemplateOption.value);
        if (r) {closeAddTableModal()};
        setTablesDbLoading(false);
        setNewTableSaving(false);
    }

    const openTableMenuClick = (e, id) => {
        e.preventDefault();
        e.stopPropagation();
        setOpenTableMenuId(id);
    }

    const closeTableMenu = () => {
        setOpenTableMenuId(null);
    }

    const baseLink = "my-tables";

    const tableClick = (table) => {
        navigate('/'+baseLink+'/'+table.id);
    }

    const openDeleteTableModal = (e, table) => {
        e.stopPropagation();
        setDeleteTableData(table);
        setShowDeleteTableModal(true);
        closeTableMenu();
    }

    const closeDeleteTableModal = () => {
        setDeleteTableData(null);
        setShowDeleteTableModal(false);
    }

    const generalBackgroundClick = () => {
        closeTableMenu();
        closeShowOptionsMenu();
    }

    const deleteTableClick = async () => {
        setDeleteLoading(true);
        await deleteTable(deleteTableData['id']);
        setDeleteLoading(false);
        setShowDeleteTableModal(false);
    }

    const toggleViewModeClick = () => {
        if (viewMode === "organized"){
            setViewMode("all");
        } else {
            setViewMode("organized");
        }  
    }

    const [showOptionsMenu, setShowOptionsMenu] = useState(false);
    const openShowOptionsMenu = (e) => {
        e.stopPropagation();
        setShowOptionsMenu(!showOptionsMenu);
    }

    const closeShowOptionsMenu = () => {
        setShowOptionsMenu(false);
      }

    return (
    <>
        
        {(activeUserLanguages !== null && activeUserLanguages.length === 0) ?
            <>
              <NoActiveTargetLanguage />
            </>
        :
            <>
                <div onClick={generalBackgroundClick} className="  flex flex-col gap-8">
                    <MyLibraryMenu activeName="my-decks" />
                    <div>
                    <div onClick={(e)=>openShowOptionsMenu(e)} className="absolute sm:right-4 sm:top-4 right-2 top-2 p-2 cursor-pointer text-xl">
                        <Icon color="charcoal" icon={<FontAwesomeIcon icon={faEllipsisV} />} /> 
                    </div>
                    {showOptionsMenu && 
                        <>
                            <div className="absolute sm:right-8 sm:top-10 right-6 top-6 overflow-visible text-base w-48 z-10 rounded-lg shadow-xl bg-white ">
                                <div className="flex text-sm whitespace-nowrap font-bold text-gray-700 flex-col justify-start place-items-start">
                                    <div onClick={toggleViewModeClick} className="flex cursor-pointer flex-row gap-3 hover:bg-gray-100 w-full rounded-lg  p-3">
                                        <div>
                                            <Icon color="charcoal" icon={<FontAwesomeIcon icon={viewMode === "organized" ? faTableColumns : faFolder} />} />
                                        </div>
                                        <div>
                                            View {viewMode === "organized" ? ' all tables ' : ' folders '}
                                        </div>
                                    </div>
                                    <Link to={'/my-library'} className="no-underline w-full" >
                                        <div className="flex flex-row gap-3 hover:bg-gray-100 w-full rounded-lg  p-3">
                                            <div>
                                                <Icon color="red" icon={<FontAwesomeIcon icon={faBook} />} />
                                            </div>
                                            <div>
                                                My library
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to={'/my-decks'} className="no-underline w-full" >
                                        <div className="flex flex-row gap-3 hover:bg-gray-100 w-full rounded-lg  p-3">
                                            <div>
                                                <Icon color="red" icon={<FontAwesomeIcon icon={faCardsBlank} />} />
                                            </div>
                                            <div>
                                                View only decks
                                            </div>
                                        </div>
                                    </Link>

                                    <Link to={'/my-notes'} className="no-underline w-full" >
                                        <div className="flex flex-row gap-3 hover:bg-gray-100 w-full rounded-lg  p-3">
                                            <div>
                                                <Icon color="yellow" icon={<FontAwesomeIcon icon={faNote} />} />
                                            </div>
                                            <div>
                                                View only notes
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </>
                    }   
                    <h1>My {(globalSelectedTargetLanguage !== null && allLanguages !== null) && allLanguages[0][globalSelectedTargetLanguage]['name']['en']} tables</h1>
                    {viewMode === "organized" &&
                        <>
                            <MyFoldersTreeView filterContentList={["tables"]} outputDesign={"page"} />
                        </>
                    }
                    {viewMode === "all" &&
                        <>
                            {tablesDbLoading && 
                                <>
                                    <div className="text-center z-10 text-4xl absolute left-1/2 top-1/2 text-black">
                                        <FontAwesomeIcon icon={faLoader} className="fa-spin" />
                                    </div>
                                </>
                            }
                            <div className={(tablesDbLoading ? 'opacity-30 ' : '') + "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 text-center"}>
                                <motion.div onClick={createTableClick} whileHover={{y:-3}} className={"relative  bg-charcoal rounded-lg p-4 h-64 shadow-2xl cursor-pointer flex flex-col justify-evenly"}>
                                    <div className="bg-white font-bold text-xl rounded-lg shadow-2xl p-4 max-h-48 overflow-auto gap-2 flex flex-col">
                                        Create table <Icon hover="none" color="charcoal" icon={<FontAwesomeIcon icon={faPlus} />} /> 
                                    </div>
                                </motion.div>
                                {tables !== null &&
                                    <>
                                        {tables.map((table, index)=>(
                                            <>
                                            <motion.div onClick={()=>tableClick(table)} whileHover={{y:-3}} className={"shadow-2xl cursor-pointer flex flex-col justify-evenly relative rounded-lg p-4 h-64 bg-persian-green"}>
                                                <div onClick={(e)=>openTableMenuClick(e, table.id)} className="absolute right-0 top-0 p-2 text-2xl">
                                                    <Icon  color="white" icon={<FontAwesomeIcon icon={faEllipsisV} />} /> 
                                                </div>
                                                {'row_order' in table &&
                                                    <>
                                                        <div className="absolute left-0 bottom-0 p-2 text-xs text-white">
                                                            <span className="font-bold">{table['row_order'].length}</span> rows 
                                                        </div>
                                                    </>
                                                }
                                                {(openTableMenuId === table.id) && 
                                                    <>
                                                        <div className="absolute overflow-visible text-base w-36 z-10 rounded-lg shadow-xl bg-white right-0 top-0">
                                                            <div className="flex font-bold text-gray-700 flex-col justify-start place-items-start">
                                                                <Link onClick={(e)=>e.stopPropagation()} to={'/my-tables/'+table.id+'/play'} className="no-underline w-full" >
                                                                    <div className="flex flex-row gap-3 hover:bg-gray-100 w-full rounded-lg  p-3">
                                                                        <div>
                                                                            <Icon color="green" icon={<FontAwesomeIcon icon={faPlay} />} />
                                                                        </div>
                                                                        <div>
                                                                            Play
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                                <Link to={'/'+baseLink+'/'+table.id} className="no-underline w-full" >
                                                                    <div className="flex flex-row gap-3 hover:bg-gray-100 w-full rounded-lg  p-3">
                                                                        <div>
                                                                            <Icon color="blue" icon={<FontAwesomeIcon icon={faPencil} />} />
                                                                        </div>
                                                                        <div>
                                                                            Edit
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                                <div onClick={(e)=>openDeleteTableModal(e, table)} className="flex flex-row gap-3 hover:bg-gray-100 w-full rounded-lg  p-3">
                                                                    <div>
                                                                        <Icon color="red" icon={<FontAwesomeIcon icon={faTrash} />} />
                                                                    </div>
                                                                    <div>
                                                                        Delete
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                <div className="bg-white rounded-lg shadow-2xl p-4 max-h-48 overflow-auto gap-2 flex flex-col">
                                                    <div className="text-base font-bold">{table['name']}</div>
                                                    <div className="text-sm italic max-h-32 overflow-auto">{('description' in table && table['description'] !== "" && table['description'] !== null) && table['description']}</div>
                                                    
                                                </div>
                                            </motion.div>
                                            </>
                                        ))}
                                    </>
                                }
                            </div>
                            {showAddTableModal && 
                                <>
                                    <AddTableModal changeTemplateOption={changeTemplateOption} selectedTemplateOption={selectedTemplateOption} defaultTemplateOptions={defaultTemplateOptions} sourceLanguageOptions={sourceLanguageOptions} allLanguages={allLanguages} targetLanguage={globalSelectedTargetLanguage} tableName={newTableName} setTableName={setNewTableName} setSourceLanguage={setNewTableSourceLanguage} sourceLanguage={newTableSourceLanguage} saveNewTable={saveNewTableInDb} newTableSaving={newTableSaving} setNewTableSaving={setNewTableSaving} closeFunction={closeAddTableModal} />
                                </>
                            }
                            
                            {showDeleteTableModal &&
                                <>
                                    <DeleteTableModal loading={deleteLoading} deleteTableClick={deleteTableClick} closeFunction={closeDeleteTableModal} table={deleteTableData} />
                                </>
                            }  
                        </>
                    }
                </div>
                </div>
            </>
        }
        
    </>
  )
}

export default MyTables