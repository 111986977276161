import {projectFirestore, timeStamp} from '../firebase/config.js';
import { useAuth } from "../contexts/AuthContext";
import {useUserInfoContext} from '../contexts/UserInfoContext';
import { useLocation } from "react-router-dom";

export default function useQuotas() {
    let {currentUser} = useAuth();
    const location = useLocation();

    const getGoogleTranslateProcessedDocNameForCurrentMonth = () => {
        let date  = new Date();
        let monthNumber = date.getMonth()+1;
        let year = date.getFullYear();
        let docName = monthNumber+"_"+year;
        return docName;
      }
      
    const getNumberOfTranslationsThisMonthFromCurrentUser = async () => {
        if (currentUser === null){return false}
        let docName = getGoogleTranslateProcessedDocNameForCurrentMonth();
        let query = projectFirestore.collection("users").doc(currentUser.uid).collection("private-data").doc("api-usage").collection("processed").doc("google-translate-items").collection("months").doc(docName);
        let snapshot = await query.get();
        let data = null;
        if (snapshot.exists){
          data = snapshot.data();
        }
        return data;
    }

    const getFreeQuotaLimits = () => {
        let googleTranslateTranslations = 50;
      
        return {
          'numberOfGoogleTranslations': googleTranslateTranslations
        }
      }

    return {
        getNumberOfTranslationsThisMonthFromCurrentUser, 
        getFreeQuotaLimits, 
        getGoogleTranslateProcessedDocNameForCurrentMonth
    }
}