import React from 'react';

function FlashcardsMenu({score, nbOfAnswers, cardsLeft, playMode}) {
    return (
        <>
            <div className="flex flex-row justify-center gap-5">
                <div><span className="font-bold">Score</span>: {score}/{nbOfAnswers}</div>
                <div><span className="font-bold">Cards left</span>: {cardsLeft}</div> 
            </div>           
        </>
    )
}

export default FlashcardsMenu;
