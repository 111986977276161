import React, {useEffect, useRef} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
  
const IconListElement = ({ type, text, format, activateFunction, icon, showIcon, isSelected, isActivated }) => {
    return (
      <>
        <div className={"whitespace-nowrap flex flex-row gap-4 justify-start px-2 py-3 cursor-pointer"}
        onMouseDown={event => {
          event.preventDefault();
          if (isActivated){
            if (type === "block"){
              //TextEditor.toggleBlock(editor, format);
            } 
            else if (type === "mark"){
              //TextEditor.toggleMark(editor, format);
            } 
            else if (type === "functions"){
              activateFunction();
            } 
          }
        }}
        >
          {showIcon &&
            <div className="text-gray-600">
              <FontAwesomeIcon icon={icon} />
            </div>
          }
          <div className={isSelected && " "}>
            {text}
          </div>
      </div>
      </>
    )
  }

export default IconListElement