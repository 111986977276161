import React, {useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/pro-solid-svg-icons';

const NotificationMessage = ({notificationStatus, message, type, delayTime, showMessageBool, setShowMessageBool}) => {
    let bg_color = "";

    const closeNotificationClick = () => {
        setShowMessageBool(false);
    }

    if (type === "save-success" || notificationStatus === "success"){
        message = "Changes saved successfully";
        bg_color = "persian-green-gradient ";
    }

    if (type === "save-error" || notificationStatus === "error"){
        message = "An error occurred";
        bg_color = "burnt-sienna-gradient ";
    }

    if (delayTime === undefined){
        delayTime = 3000; // default
    }

    useEffect(()=>{
        // reset this after timeout
        if (showMessageBool === true){
            setTimeout(function(){
                setShowMessageBool(false);
           },delayTime); 
        };
    },[showMessageBool, setShowMessageBool, delayTime]);

    return (
        <>
            {(message !== undefined && showMessageBool) &&
                <div className={"fixed w-3/4 my-32 md:w-1/4 sm:my-10 rounded-lg text-center shadow-lg font-bold text-white bottom-0 left-1/2 transform -translate-x-1/2 "+bg_color}>
                    <div className="px-10 py-5 text-lg">
                        {message}
                    </div>
                    <div onClick={closeNotificationClick} className="absolute cursor-pointer text-sm right-0 top-0 p-2">
                        <FontAwesomeIcon icon={faX} />
                    </div>
                </div>
            }
        </>
    )
}

export default NotificationMessage;
