import React, {useState, useEffect, useRef, useCallback} from 'react';
import Icon from '../general/Icon';
import { Link, useNavigate } from 'react-router-dom';
import CountryFlag from '../languages/CountryFlag';
import YalangoComputer from '../../assets/svg/yalango/yalango_computer.svg';
import NormalTextInput from '../general/input/NormalTextInput';
import Button from '../general/Button';
import YalangoSvg from '../../assets/svg/yalango/yalango.svg';
import YalangoQuestionSvg from '../../assets/svg/yalango/yalango_question.svg';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/pro-solid-svg-icons';
import {faSpinner} from '@fortawesome/pro-duotone-svg-icons';
import {faSearch} from '@fortawesome/pro-solid-svg-icons';
import {faQuestion} from '@fortawesome/pro-solid-svg-icons';
import useUserSettings from '../../hooks/useUserSettings';
import SimpleErrorBar from '../general/SimpleErrorBar';
import useLanguages from '../../hooks/useLanguages';
import useGoals from '../../hooks/useGoals';
import Select from 'react-select';
import SourceLanguageExplanation from './explanationModals/SourceLanguageExplanation';
import {useLanguagesContext} from '../../contexts/LanguagesContext';
import {useUserInfoContext} from '../../contexts/UserInfoContext';
import EmailVerification from '../../components/settings/EmailVerification';
import {useUserStatisticsContext} from '../../contexts/UserStatisticsContext'; 

const WelcomeToYalango = () => {
    const {verifyThatCurrentUserExistsInDatabase,
        updateAccountNameForCurrentUser, 
        fetchUserActionsFromCurrentUser, 
        setTopLevelActionToTrueForCurrentUser
    } = useUserSettings();
    const {refreshUserInfo} = useUserInfoContext();
    const {allLanguages, sourceLanguageOptions} = useLanguagesContext();
    const {activateDailyGoalsNeedRefresh} = useUserStatisticsContext();
    const {addNewLanguages} = useLanguages();
    const  {saveDailyGoalsInTargetLanguageApi} = useGoals();
    const [viewIndex, setViewIndex] = useState(1);
    const views = ["database-setup", "welcome", "name", "languages"]; //"email-verification"
    const [name, setName] = useState("");
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const navigate = useNavigate();
    const [nbOfSelectedLanguages, setNbOfSelectedLanguages] = useState(0);
    const [langOptions, setLangOptions] = useState(null);
    const [langOptionsDict, setLangOptionsDict] = useState(null);

    useEffect(()=>{
        if (sourceLanguageOptions !== null){
            setLangOptions(sourceLanguageOptions[0]);
            setLangOptionsDict(sourceLanguageOptions[1]);
        }
    },[sourceLanguageOptions])

    const [selectedSourceLanguage, setSelectedSourceLanguage] = useState("en");

    const saveNameButtonRef = useRef(null);

    const [searchLanguagesString, setSearchLanguagesString] = useState("");
    const [filteredLanguages, setFilteredLanguages] = useState(null);
    const searchLanguagesOnChange = (e) => {
        setSearchLanguagesString(e.target.value);
    }

    useEffect(()=>{
        if (allLanguages !== null){
            if (searchLanguagesString === ""){
                setFilteredLanguages(allLanguages);
                return;
            }
            let filt_list = [];
            let filt_dict = allLanguages[0];
            for (const lang of allLanguages[1]){
                if (allLanguages[0][lang]['name']['en'].toLowerCase().includes(searchLanguagesString.toLowerCase())){
                    filt_list.push(lang);
                }
            }
            let newLangs = [filt_dict, filt_list];
            setFilteredLanguages(newLangs);
        }
    },[searchLanguagesString, allLanguages]);

    useEffect(()=>{
        if (allLanguages !== null){
            setFilteredLanguages(allLanguages);
        }
    },[allLanguages])

    //Loading
    const [savingNameLoading, setSavingNameLoading] = useState(false);
    const [savingLanguagesLoading, setSavingLanguagesLoading] = useState(false);

    // Errors 
    const [showAccountNameError, setShowAccountNameError] = useState(false);
    const [showAccountNameErrorMsg, setShowAccountNameErrorMsg] = useState("");
    const [saveLanguagesError, setSaveLanguagesError] = useState(false);
    const [saveLanguagesErrorMsg, setSaveLanguagesErrorMsg] = useState("");
    const [databaseCheckError, setDatabaseCheckError] = useState(false);
    const [databaseCheckErrorMsg, setDatabaseCheckErrorMsg] = useState("");

    // Modal
    const [showSourceLanguageGuideModal, setShowSourceLanguageGuideModal] = useState(false);

    useEffect(()=>{
        setNbOfSelectedLanguages(selectedLanguages.length);
    },[selectedLanguages]);

/*     useEffect(()=>{
        function sortByKey(array, key) {
            return array.sort(function(a, b) {
                var x = a[key]; var y = b[key];
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            });
        }

        const fetchLangs = async () =>  {
            let langs = await getAllLanguages();
            setAllLanguages(langs);
            let options = [];
            let options_dict = {};
            for (const lang of langs[1]){
                options.push({
                    value: lang, 
                    label: langs[0][lang]['name']['en']
                });

                options_dict[lang] = {
                    value: lang, 
                    label: langs[0][lang]['name']['en']
                };
            }

            options = sortByKey(options, 'label');
            setLangOptions(options);
            setLangOptionsDict(options_dict);
        }  
        //fetchLangs();
    },[]); */

    useEffect(()=> {
        //document.body.style.overflow = 'hidden';
        const showOrNot = async () => {
            let actions = await fetchUserActionsFromCurrentUser();
            if (!('welcome_to_yalango' in actions) || actions['welcome_to_yalango'] === false){
                //
            } else {
                navigate("/dashboard");
            }
        };

        showOrNot();
    },[]);

/*     useEffect(()=>{
        const doCheck = async () => {
            let success = await verifyThatCurrentUserExistsInDatabase();
            console.log(success);
            if (success){
                setViewIndex(viewIndex+1);
            }     
            else {
                setDatabaseCheckError(true);
                setDatabaseCheckErrorMsg("Something went wrong. Please refresh the page and try again.");
            }
        };
        if (viewIndex === 0 && showModal === true){
            //doCheck();
        }
    },[viewIndex, showModal]); */

    const nextPage = () => {
        setViewIndex(viewIndex+1);
    }

    const updateAccountNameChange = (e) => {
        setName(e.target.value);
        if (showAccountNameError === true){
            setShowAccountNameError(false);
            setShowAccountNameErrorMsg("");
        }
    }

    const checkIfAccountNameEnter = (e) => {
        if (e.keyCode === 13){
            saveNameButtonRef.current.click();
        }
    }

    const saveNameClick = async () => {
        let success = false;
        if (name !== ""){
            setSavingNameLoading(true);
            success = await updateAccountNameForCurrentUser(name);
            setSavingNameLoading(false);
            if (success) {
                setViewIndex(viewIndex+1);
                refreshUserInfo();
            } else {
                setShowAccountNameError(true);
                setShowAccountNameErrorMsg("Something went wrong. Please try again.");
            }
        } else {
            setShowAccountNameError(true);
            setShowAccountNameErrorMsg("Your account name cannot be blank.");
        }
    }

    const finishGetStarted = async () => {
        const finish = async () => {
            await setTopLevelActionToTrueForCurrentUser('welcome_to_yalango');
            navigate("/dashboard?guide=true");
        }
        await finish();
    }

    const saveLanguagesClick = async () => {
        if (selectedSourceLanguage === null){
            setSaveLanguagesError(true);
            setSaveLanguagesErrorMsg("Please select a source language.");
            return;
        }
        if (selectedLanguages.length === 0){
            setSaveLanguagesError(true);
            setSaveLanguagesErrorMsg("Please select at least one language to study.");
            return;
        }
        setSavingLanguagesLoading(true);
        let db_data = [];
        selectedLanguages.forEach(lang=>{
            db_data.push({
                'target_language': lang,
                'source_language': selectedSourceLanguage,
                'name': allLanguages[0][lang]['name']['en']
            })
        });
        let success = await addNewLanguages(db_data);
        for (const lang of selectedLanguages){
            await saveDailyGoalsInTargetLanguageApi([10,10,10,10,10,10,10], lang);
        }
        activateDailyGoalsNeedRefresh();
        if (success) {
            setViewIndex(viewIndex+1);
            
        } else {
            setSaveLanguagesError(true);
            setSaveLanguagesErrorMsg("Something went wrong. Please try again.");
        }
        setSavingLanguagesLoading(false);
        await finishGetStarted();
    }

    const doLater = async () => {
        setViewIndex(viewIndex+1);
        setName(null);
    }

    const doLanguagesLater = async () => {
        setViewIndex(viewIndex+1);
    }



    const closeFunction = () => {
        //document.body.style.overflow = 'auto';
        navigate("/dashboard?guide=true");
    }

    const resetSelectedLanguages = () => {
        setSelectedLanguages([]);
        setSaveLanguagesError(false);
        setSaveLanguagesErrorMsg("");
    }

    const sourceLanguageSelectChange = (option) => {
        setSelectedSourceLanguage(option.value);
        setSaveLanguagesError(false);
        setSaveLanguagesErrorMsg("");
    }
    
    const selectLanguage = (e) => {
        const new_language = e.currentTarget.getAttribute('data-language-code');
        //console.log(new_language);
        //console.log("Selected.", selectedLanguages);
        if (!(selectedLanguages.includes(new_language))){
            setSelectedLanguages([...selectedLanguages, new_language]);
        } else {
            const new_languages = [];
            selectedLanguages.forEach(lang=>{
                if (lang !== new_language){
                    new_languages.push(lang);
                }
            });
            setSelectedLanguages(new_languages);
        }  
        setSaveLanguagesError(false);
        setSaveLanguagesErrorMsg("");
    }

    const showSourceLanguageGuideClick = () => {
        setShowSourceLanguageGuideModal(true);
    }

    const closeSourceLanguageGuideModal = () => {
        setShowSourceLanguageGuideModal(false);
    }

    return (
        <>
                <div className="overflow-y-auto   bg-gradient-to-r p-4 pb-24 scrollbar-thin scrollbar-thumb-charcoal scrollbar-track-gray-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
                    <div className="absolute text-black top-0 right-0 px-8 py-4 text-3xl">
                        <Icon onClick={closeFunction} icon={<FontAwesomeIcon icon={faTimes} />} />
                    </div>
                    <div className="w-5/6 m-auto py-4 ">
                        <div className=" bg-gray-300 w-full rounded-full">
                            <div className={"bg-persian-green transition-width transition-500 ease w-" + (viewIndex) + "/4 text-xs font-medium text-white text-center p-2 rounded-l-full"}> {viewIndex}/{views.length-1}</div>
                        </div>
                    </div>
                    <div className="flex mt-16 mb-8 justify-center items-center text-center ">
                        {views[viewIndex] === "database-setup" &&
                            <>
                                <div className="flex flex-col place-content-center">
                                    
                                    <div className="text-xl m-auto p-8 ">
                                        {!databaseCheckError ?
                                            <>
                                                <div className="my-4 text-7xl text-persian-green">
                                                    <FontAwesomeIcon icon={faSpinner} className="fa-pulse" />
                                                </div>
                                                <div className="my-2 text-base">
                                                    Please wait while we set up your account...
                                                </div>
                                            </>
                                        :
                                            <div className="my-2 w-96 text-white bg-red-500 p-4 rounded-lg shadow-lg text-sm">
                                                {databaseCheckErrorMsg}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </>
                        }
                        
                        {views[viewIndex] === "welcome" &&
                            <>
                                <div className="h-full flex flex-col justify-center ">
                                    <div>
                                        <img src={YalangoSvg} alt="Yalango mascot sad" className="w-72 m-auto p-4 mx-auto my-0" />
                                    </div>
                                    <div className="text-lg m-auto p-8 ">
                                        <div className="my-2">
                                            Welcome to Yalango! We're so happy to welcome you onboard! Let's quickly walk through the setup of your new account.
                                        </div>
                                    </div>
                                    <div className="text-2xl flex flex-row justify-center">
                                        <Button onClick={nextPage} text={<div className="font-bold">Get started</div>} color="bg-charcoal" />
                                    </div>
                                </div>
                            </>
                        }
                    
                        {views[viewIndex] === "name" &&
                            <>
                                <div className="flex flex-col place-content-center">
                                    <div>
                                        <img src={YalangoQuestionSvg} alt="Yalango question" className="w-3/6 m-auto p-4 mx-auto my-0" />
                                    </div>
                                    <div className="flex flex-col gap-2 text-xl m-auto p-8 ">
                                        <div className="my-2 text-2xl">
                                            First off, what's your name? 
                                        </div>
                                        <div className="w-96 m-auto">
                                            <NormalTextInput onKeyDown={checkIfAccountNameEnter} value={name} onChange={updateAccountNameChange} />
                                        </div> 
                                        <div className="text-sm w-96">
                                        {showAccountNameError ?
                                            <>
                                                <SimpleErrorBar message={showAccountNameErrorMsg} />
                                            </>
                                        :
                                            <>
                                                <div className="italic ">
                                                    Your name will not be shown publicly.
                                                </div>
                                            </>
                                        }
                                        </div>
                                    </div>
                                    <div className="text-2xl flex flex-row justify-center">
                                        <Button disabledDesign={showAccountNameError ? true : false} disabled={showAccountNameError ? true : false} innerRef={saveNameButtonRef} onClick={saveNameClick} text={savingNameLoading ? <><div className="font-bold"><FontAwesomeIcon icon={faSpinner} className="fa-pulse" /></div></>:<><div className="font-bold">Next step</div></>} color="bg-charcoal" />
                                    </div>
                                    <div onClick={doLater} className="text-gray-500 my-2 underline cursor-pointer">
                                        Skip this step for now
                                    </div>
                                </div>
                            </>
                        }
                        {views[viewIndex] === "languages" &&
                            <>
                            <div className="flex flex-col gap-6 w-full  place-content-center">
                                    <div className="text-3xl font-bold flex flex-row gap-1 lg:gap-4 justify-center">
                                        <div> 
                                            My languages
                                        </div>
                                    </div>
                                    <div className="text-sm mt-4">Choose the languages you wish to study on Yalango.</div>
                                    
                                    <div className="text-xl w-full mt-4 ">
                                        <div className="flex flex-row gap-2 justify-center place-items-center">
                                            <div className="text-2xl">
                                                <FontAwesomeIcon icon={faSearch} />
                                            </div>
                                            <div>
                                                <NormalTextInput placeHolder="Search for a language" onChange={searchLanguagesOnChange} value={searchLanguagesString} />
                                            </div>
                                        </div>
                                        
                                        <div className="mt-6 h-96 sm:w-5/6 mx-auto w-full m-auto overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-thumb-charcoal scrollbar-track-gray-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
                                            
                                            <div className="grid grid-cols-3 mx-auto lg:grid-cols-5 w-full  ">
                                                {filteredLanguages !== null && filteredLanguages[1].map((lang)=>(
                                                    <>
                                                        <div key={"lang_"+lang} onClick={selectLanguage} data-language-code={lang} className={(selectedLanguages.includes(lang) && " bg-charcoal text-white ") +"   rounded-xl p-4 flex flex-col place-items-center cursor-pointer"}>
                                                            <div>
                                                                <CountryFlag size="4em" countryCode={filteredLanguages[0][lang]['country_flag']} flagName={filteredLanguages[0][lang]['name']['en']} />    
                                                            </div>
                                                            <div className="text-center text-base font-bold">
                                                                {filteredLanguages[0][lang]['name']['en']}
                                                            </div>
                                                        </div>
                                                    </>
                                                ))
                                            }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <div className="flex flex-row gap-4 justify-center items-center">
                                            <div className="font-bold">
                                                I already speak: 
                                            </div>
                                            <div>
                                                {langOptionsDict !== null &&
                                                    <Select value={langOptionsDict[selectedSourceLanguage]} onChange={sourceLanguageSelectChange} options={langOptions} autoFocus={false}  className="w-48 text-gray-500 text-base text-left"  />
                                                }
                                            </div>
                                            <div>
                                                <div onClick={showSourceLanguageGuideClick} className="bg-white cursor-pointer shadow-lg border border-dashed border-gray-200 px-4 py-2 rounded-full">
                                                    <Icon  hover="none" color="black" icon={<FontAwesomeIcon icon={faQuestion} />} />
                                                </div>
                                            </div>
                                        </div>
                                        {showSourceLanguageGuideModal && 
                                            <>
                                                <SourceLanguageExplanation closeFunction={closeSourceLanguageGuideModal} />
                                            </>
                                        }
                                    </div>
                                    <div className="my-4">
                                        <div className="flex flex-row justify-center gap-2">
                                            <div>
                                                <span className="px-2 py-1  font-bold rounded-full">{nbOfSelectedLanguages} {nbOfSelectedLanguages === 1 ? <>language</> : <>languages</> } selected </span>
                                            </div>
                                            {nbOfSelectedLanguages > 0 &&
                                                <div onClick={resetSelectedLanguages} className="underline cursor-pointer">
                                                    Reset
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {saveLanguagesError &&
                                        <>
                                            <div className="my-2 w-96 m-auto text-white bg-red-500 p-4 rounded-lg shadow-lg text-sm">
                                                {saveLanguagesErrorMsg}
                                            </div>
                                        </>
                                    }
                                    <div className="text-2xl flex flex-row justify-center">
                                        <Button disabled={saveLanguagesError ? true : false} disabledDesign={saveLanguagesError ? true : false} onClick={saveLanguagesClick} text={savingLanguagesLoading ? <><div className="font-bold"><FontAwesomeIcon icon={faSpinner} className="fa-pulse" /></div></>:<><div className="font-bold">Save languages</div></>} color="green" />
                                    </div>
                                    <div onClick={doLanguagesLater} className="text-gray-500 my-2 underline cursor-pointer">
                                        Skip this step for now
                                    </div>
                                </div>
                            </>
                        }
                        {views[viewIndex] === "email-verification" &&
                            <>
                                <div className="flex flex-col gap-6 place-content-center">
                                    <div className="hidden">
                                        <img src={YalangoComputer} alt="Tim from Yalango on the computer." className="w-48 m-auto" />
                                    </div>
                                    <div className="text-2xl muted-text font-bold">
                                        Please verify your email
                                    </div>
                                   
                                    <div>
                                        <EmailVerification onSuccess={finishGetStarted} hideDashboardLink={true} emailIsSent={true} />
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    
                    
                        
                    
                </div>
        </>
    )
}

export default WelcomeToYalango;
