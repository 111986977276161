import React, { useCallback } from 'react';
import {projectFirestore, projectFunctions, timeStamp, firebase} from '../firebase/config.js';
import { useAuth } from "../contexts/AuthContext";


export default function useNotes() {

    let {currentUser} = useAuth();

    const getNotesFromCurrentUserInRealtime = async (targetLanguage, setStateFunc) => {
        let query =  projectFirestore.collection('notes').where('uid', '==', currentUser.uid).where('target_ISO_639-1', '==', targetLanguage);
        query.onSnapshot(querySnapshot => {
            let notes = [];
            for (const doc of querySnapshot.docs){
                notes.push(doc.data());
            }
            setStateFunc(notes);
        });
    }

    const getAllNotesFromCurrentUserInRealtime = async (setStateFunc) => {
        let query =  projectFirestore.collection('notes').where('uid', '==', currentUser.uid);
        return query.onSnapshot(querySnapshot => {
            let notes = [];
            for (const doc of querySnapshot.docs){
                let d = doc.data();
                d['doc_id'] = doc.ref.id;
                notes.push(d);
            }
            setStateFunc(notes);
        });
    }

    const saveChangesToNote = useCallback(async ({noteDocId=undefined, blocks=null, name=null}) => {
        if (noteDocId === undefined){
            return;
        }
        let noteUpdates = {};
        if (blocks !== null){
            noteUpdates["blocks"] = blocks;
        } 
        if (name !== null){
            noteUpdates["name"] = name;
        }
        //add more update options here later
        if (Object.keys(noteUpdates).length === 0){
            return;
        }
        let res = await projectFirestore.collection("notes").doc(noteDocId).set({
            ...noteUpdates,
            'last_updated': timeStamp
        }, {merge:true});
    }, []);

    const saveNewNote = async (targetLanguage, sourceLanguage, noteName, type, parentFolderDocId) => {
        const f =  projectFunctions.httpsCallable('addNewNote');
        return f({
            'name': noteName, 
            'target_ISO_639-1': targetLanguage,
            'source_ISO_639-1': sourceLanguage, 
            'type': type,
            'parentFolderDocId': "top_level", 
            'destinationFolder': parentFolderDocId
        });
    }

    const renameNote = async (noteDocId, newName) => {
        console.log("Renaming");
        return projectFirestore.collection("notes").doc(noteDocId).set({
            'name': newName, 
            'last_updated': timeStamp
        }, {merge: true});
    }

    const deleteNoteFromDocId = async (noteDocId) => {
        let r = await projectFirestore.collection("notes").doc(noteDocId).delete();
        return r;
    }

    const fetchNote = async (noteId) => {
        const path =  projectFirestore.collection('notes').where('note_id', "==", noteId).where("uid", "==", currentUser.uid);
        let snapshots = await path.get();
        if (!snapshots.empty && snapshots.docs.length === 1){
            let snap = snapshots.docs[0];
            if (snap !== null && snap !== undefined){
                let d = snap.data();
                d['note_doc_id'] = snap.ref.id;
                return d;
            }
        }
    }

    const fetchNoteApi = async (noteId) => {
        const f = projectFunctions.httpsCallable("fetchPublicNoteOrUserNote");
        let r = await f({'noteId': noteId});
        return r; 
    }

    const fetchNoteContent = async (contentDocId) => {
        const path =  projectFirestore.collection('notes-content').doc(contentDocId);
        let snapshot = await path.get();
        if (snapshot.exists){
            let d = snapshot.data();
            d['note_content_doc_id'] = snapshot.ref.id;
            return d;
        }
    }

    const saveNoteContent = async (contentDocId, content) => {
        console.log(contentDocId, content);
        const path =  projectFirestore.collection('notes-content').doc(contentDocId);
        return path.set({'content': JSON.stringify(content),
            'last_updated': timeStamp
        }, {merge:true});
    }

    return {
        getNotesFromCurrentUserInRealtime, 
        saveNewNote, 
        fetchNote, 
        fetchNoteContent, 
        saveNoteContent, 
        fetchNoteApi,
        getAllNotesFromCurrentUserInRealtime, 
        renameNote, 
        deleteNoteFromDocId, 
        saveChangesToNote
    }
}