import React, {useEffect, useState} from 'react';
import Modal from '../general/Modal';
import Button from '../general/Button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {faCopy} from '@fortawesome/pro-solid-svg-icons';
import {faCheck} from '@fortawesome/pro-solid-svg-icons';

const ModalContent = (props) => {
    const {closeFunction, type, item} = props;
    const [textCopied, setTextCopied] = useState(false);
    
    const copyTextClick = () => {
        navigator.clipboard.writeText("https://yalango.com/post/"+item.comment_doc_id);
        setTextCopied(true);
    }
  return (
    <>
        {type === "comment" &&
            <>
                <div className="flex flex-col gap-3">
                    <h1>Share post</h1>
                    <div className="text-sm text-center">
                        Copy this link to share this post.
                    </div>
                    <div className=" bg-gray-200 rounded-md text-center flex flex-row gap-3">
                        <div className="w-5/6 p-3 overflow-auto text-sm underline">
                            https://yalango.com/post/{item.comment_doc_id}
                        </div>
                        <div className="w-1/6 hover:bg-white cursor-pointer flex flex-col justify-center border-l border-charcoal border-solid" onClick={copyTextClick} >
                            <FontAwesomeIcon icon={textCopied ? faCheck : faCopy} />
                        </div>
                    </div>
                </div>
            </>
        }
    </>
  )
}

const ShareItemModal = (props) => {
    const {closeFunction, type, item} = props;
    return (
        <>
            <Modal bgColor="bg-gray-100" size="2xl" closeFunction={closeFunction} content={<ModalContent type={type} item={item} closeFunction={closeFunction} />} />
        </>
    )
}

export default ShareItemModal;