import React from 'react';
import Button from '../../../general/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/pro-solid-svg-icons';
import { faThumbsDown, faRotate } from '@fortawesome/pro-solid-svg-icons';

const FlashcardsButtons = ({failedButtonRef, failedWord, correctWord, flipCard, targetSide, setTargetSide}) => {
    return (
        <>
            <div className="flex flex-row gap-3 justify-center">
                <Button  icon={faThumbsDown} text={"Wrong"} color="red" onClick={failedWord} />
                <Button icon={faRotate} text="Flip" color="charcoal" onClick={flipCard} />
                <Button icon={faThumbsUp} text={"Correct"} color="green" onClick={correctWord} />
            </div>
        </>
    )
}

export default FlashcardsButtons;
