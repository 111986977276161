import React from 'react';
import Button from '../../../../general/Button';
import Modal from '../../../../general/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/pro-duotone-svg-icons';
import { faThumbsDown } from '@fortawesome/pro-duotone-svg-icons';
import { faRotateRight } from '@fortawesome/pro-duotone-svg-icons';

const ModalContent = ({closeFunction}) => {
    return (
        <>
            <div className="overflow-auto h-72 sm:h-full p-4">
                <h1>How to play</h1>
                <p className="text-center my-2">Look at the word or phrase shown on the screen. Do you know what the translation is? Type your answer in the input box and click on <span className="font-bold">Check answer</span>. </p>
                <p className="text-center my-2">If you typed the correct answer, the answer is shown in green with a star on both sides of the word. If you didn't get it right, the correct answer will show in green on the screen. In this scenario, you can either click on <span className="font-bold">Next exercise</span> to continue, or on <span className="font-bold">My answer was correct!</span> if you made a typo or wrote something with the same meaning. This will be recorded as a correct answer in your statistics.</p>
                <p>If you don't want to answer the question, click on <span className="font-bold">Skip exercise</span>.</p>

                <div className="p-4 text-center">
                    <Button text="Continue playing" onClick={closeFunction} />
                </div>
            </div>
        </>   
    )
}

const InputHelpModal = ({closeFunction}) => {
  return (
    <>
        <Modal closeFunction={closeFunction} content={<ModalContent closeFunction={closeFunction} />} size="3xl"/>
    </>
  )
};

export default InputHelpModal;
