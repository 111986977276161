import React, {useState, useEffect, useCallback} from 'react';
import Button from '../general/Button';
import Modal from '../general/Modal';
import {useNavigate} from 'react-router-dom';
import {motion} from 'framer-motion';
import SimpleErrorBar from '../general/SimpleErrorBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faNote} from '@fortawesome/pro-solid-svg-icons';
import {faFolderPlus} from '@fortawesome/pro-solid-svg-icons';
import {faBackwardStep} from '@fortawesome/pro-solid-svg-icons';
import {faCardsBlank} from '@fortawesome/pro-solid-svg-icons';
import {faTableColumns} from '@fortawesome/pro-solid-svg-icons';
import { faLoader } from '@fortawesome/pro-solid-svg-icons';
import { faThumbsDown } from '@fortawesome/pro-duotone-svg-icons';
import { faRotateRight } from '@fortawesome/pro-duotone-svg-icons';
import {AddDeckModalContent} from '../vocabulary/modals/AddDeckModal';
import Select from 'react-select';
import useTables from '../../hooks/useTables';
import useNotes from '../../hooks/useNotes';
import {useLanguagesContext} from '../../contexts/LanguagesContext';

const ModalContent = ({...props}) => {
    const {item, 
        closeFunction, 
        renameFolder, 
        renameDeck,
        renameTable,
        renameNote,
        renameText,
        renameCourse,
        setShowRenameItemModal} = props;

    const [newName, setNewName] = useState(null);
    const [loading, setLoading] = useState(false);

    const [functions, setFunctions] = useState({
        'folder': renameFolder, 
        'deck': renameDeck, 
        'table': renameTable,
        'note': renameNote, 
        'text': renameText, 
        'course': renameCourse
    });

    useEffect(()=>{
        if (item !== null && item.hasOwnProperty("text")){
            setNewName(item.text);
        }
    },[item]);

    const saveChangesClick = async () => {
        setLoading(true);
        await functions[item.content_type](item.doc_id, newName);
        closeFunction();
        setLoading(false);
    }

    const checkIfEnter = (e) => {
        if (e.key === "Enter"){
            saveChangesClick();
        }
    }

    return (
        <>
            <div onKeyDown={checkIfEnter} className="h-full flex flex-col gap-5 py-4">
                <h1>Rename {item?.content_type}</h1>
                {!loading ?
                    <>    
                        <div>
                            <input autoFocus value={newName} onChange={(e)=>setNewName(e.target.value)} className="bg-gray-200 appearance-none border-2 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white" />
                        </div>
                        <div className="flex flex-col-reverse sm:flex-row justify-center gap-1 sm:gap-3 ">
                            <Button key="cancel_button whitespace-nowrap" text="Cancel" color="red" onClick={(e)=>closeFunction(e)} />
                            <Button key="save_changes_button" text={"Save changes"} color="green" onClick={(e)=>saveChangesClick(e)} />
                        </div>
                    </>
                :
                    <>
                        <div className="text-center text-4xl">
                            <FontAwesomeIcon icon={faLoader} className="fa-spin" />
                        </div>
                    </>
                }
            </div>
        </>   
    )
}

const RenameItemModal = ({...props}) => {
    const {closeFunction} = props;
  return (
    <>
        <Modal bgColor="bg-gray-100" closeFunction={closeFunction} content={<ModalContent {...props} />} size="3xl"/>
    </>
  )
};

export default RenameItemModal;
