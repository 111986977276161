import React from 'react';
import { useLanguagesContext } from '../../../../contexts/LanguagesContext';
import RenderChineseWords from './RenderChineseWords';

const RenderWord = (props) => {
    const {userLanguages} = useLanguagesContext();
    const {text, language, data, targetOrSource, targetSize, pinyinSize} = props;
    const fontSizes = {
        "xs": "text-xs",
        "sm": "text-sm",
        "base": "text-base",
        "md": "text-md",
        "lg": "text-lg",
        "xl": "text-xl",
        "2xl": "text-2xl",
        "3xl": "text-3xl",
        "4xl": "text-4xl",
        "5xl": "text-5xl",
        "6xl": "text-6xl",
        "7xl": "text-7xl",
        "8xl": "text-8xl",
        "9xl": "text-9xl",
    }
    console.log(userLanguages, language)
    return (
        <>
            <div >
                {language === "zh" ?
                    <>
                        <RenderChineseWords text={text} pinyinSize={fontSizes[pinyinSize]} fontSize={fontSizes[targetSize]} language={language} data={data} targetOrSource={targetOrSource} />
                    </>
                :
                    <>
                        <div className={targetSize !== undefined ? fontSizes[targetSize] : ""}>
                            {text}
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default RenderWord;