import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBooks} from '@fortawesome/pro-solid-svg-icons';
import {faHeart} from '@fortawesome/pro-solid-svg-icons';
import {faGlobe} from '@fortawesome/pro-solid-svg-icons';
import {faRobot} from '@fortawesome/pro-solid-svg-icons';
import {Link} from 'react-router-dom';

const MyLibraryMenu = ({activeName}) => {

    const colors = {
        'library': 'sandy-brown', 
        'explore': 'charcoal',
        'favorites': 'burnt-sienna', 
        'ai-texts': 'charcoal'
    };

    const [items, setItems] = useState([
        {'text': "My library", 'activeName': 'library', 'icon': faBooks, 'url': '/my-library'},
        {'text': "Explore", 'activeName': 'explore', 'icon': faGlobe, 'url': '/decks'},
    ]);
    //{'text': "Favorites", 'activeName': 'favorites', 'icon': faHeart, 'url': '/my-favorites'},
    // {'text': "AI texts", 'activeName': 'ai-texts', 'icon': faRobot, 'url': '/texts'},

    return (
        <>
            <div className="flex flex-row overflow-x-auto gap-2 md:gap-8 justify-center">
                {items.map((item, itemIndex)=>(
                    <>
                    
                        <div key={"library_menu_item_"+itemIndex} className="group whitespace-nowrap text-xs w-26 sm:w-32  lg:text-base lg:w-48">
                            <Link className="no-underline" to={item.url}>
                                {activeName === item.activeName ?
                                    <div className={'bg-'+colors[item.activeName] + " font-bold text-white  transition ease-in-out transition-duration-500 cursor-pointer rounded-lg shadow-xl p-2 lg:py-2 lg:px-4 "}>
                                        <div className="flex flex-row place-items-center gap-2 sm:gap-5">
                                            <div className={" group-hover:text-white text-base sm:text-lg"}>
                                                <FontAwesomeIcon icon={item.icon} />
                                            </div>
                                            <div className="  group-hover:text-white">
                                                {item.text}
                                            </div>
                                        </div>
                                    </div>
                                :
                                    <div className={'bg-white group-hover:bg-'+colors[item.activeName] + " font-bold transition ease-in-out transition-duration-500 cursor-pointer rounded-lg shadow-xl p-2 lg:py-2 lg:px-4 "}>
                                        <div className="flex flex-row place-items-center gap-2 sm:gap-5">
                                            <div className={"text-" + colors[item.activeName] + " group-hover:text-white text-base sm:text-lg"}>
                                                <FontAwesomeIcon icon={item.icon} />
                                            </div>
                                            <div className=" text-gray-600 group-hover:font-bold group-hover:text-white">
                                                {item.text}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </Link>
                        </div>
                    </>
                ))}
            </div>
        </>
    )
}

export default MyLibraryMenu;