import React, {useState, useEffect} from 'react';
import RenderCommentReplies from './RenderCommentReplies';
import UserComment from './UserComment';
import WriteComment from './WriteComment';
import useComments from '../../hooks/useComments';
import { faReply } from '@fortawesome/pro-solid-svg-icons';
import {Helmet} from 'react-helmet';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const RenderCommentAndReplies = ({commentDocId}) => {

    const {fetchCommentInRealtimeFromDocId} = useComments();
    const [comment, setComment] = useState(null);
    const [commentRepliesDocIds, setCommentRepliesDocIds] = useState([]);
    const [loading, setLoading] = useState(true);
    const [replyType, setReplyType] = useState("reply");

    useEffect(()=>{
        let unsub = null;
        const fetchData = async () => {
            setLoading(true);
            unsub = await fetchCommentInRealtimeFromDocId(commentDocId, setComment);
            setLoading(false);
        }
        if (commentDocId !== null && commentDocId !== undefined){
            setComment(null);
            fetchData();
        }
        return (()=>{
            unsub !== null && unsub();
        })
    },[commentDocId]);

    useEffect(()=>{
        if (comment === undefined || comment === null){return false}
        if (comment.hasOwnProperty("replies_doc_ids")){
            setCommentRepliesDocIds(comment.replies_doc_ids);
        } else {
            setCommentRepliesDocIds([]);
        }
    },[comment]);


    const onSubmittedReply = async () => {
        //
    }

    const activeReplyTypeClass = "font-bold";

    return (
        <>
            {comment !== null && comment !== false && loading === false &&
                <>
                     <Helmet>
                        <title>{(comment.hasOwnProperty("author_name") && comment.author_name !== "") ? comment.author_name + " published a post - " : ""} Yalango</title>
                        <meta name="description" content={"Explore the feed on Yalango and interact with other users."} />
                    </Helmet>
                    <div className="flex flex-col gap-6">
                        <div className="flex flex-col gap-3">
                            <h1>Original post</h1>
                            <UserComment comment={comment} hideCommentOptions={true} commentLocation={"original_post_page"} displaySourceInformation={true} />
                        </div>
                        <div className="flex flex-col gap-3">
                            <h1>Replies ({comment.hasOwnProperty("number_of_replies") ? comment.number_of_replies : 0})</h1>
                            <div className="flex flex-row gap-3 justify-center sm:justify-start">
                                <div className={(replyType === "reply" ? activeReplyTypeClass : "cursor-pointer") + " cursor-pointer hover:bg-charcoal hover:text-white bg-white p-2 border border-text-gray-600 border-solid rounded-lg text-sm shadow-lg flex flex-row gap-2"} onClick={()=>setReplyType("reply")}>
                                    <div>
                                        <FontAwesomeIcon icon={faReply} />
                                    </div>
                                    <div>
                                        Write a reply
                                    </div>
                                </div>
                                <div className={(replyType === "correction" ? activeReplyTypeClass : "cursor-pointer") + " cursor-pointer hover:bg-charcoal hover:text-white bg-white p-2 border border-text-gray-600 border-solid rounded-lg text-sm shadow-lg flex flex-row gap-2"} onClick={()=>setReplyType("correction")}>
                                    <div className="text-persian-green group-hover:text-white">
                                        <FontAwesomeIcon icon={faCheck} />
                                    </div>
                                    <div>
                                        Correct original post
                                    </div>
                                </div>
                            </div>
                            <WriteComment inputDesign={replyType} commentParentMetadata={comment} showPostOptions={false} postType={"reply"} onSubmitFinished={onSubmittedReply} commentSourceMetadata={comment} commentSource={comment.comment_source} sourceId={comment.hasOwnProperty('source_id') ? comment.source_id : null} />
                            <RenderCommentReplies repliesDocIds={commentRepliesDocIds} parentCommentDocId={comment.comment_doc_id} />
                        </div>
                    </div>
                </>
            }
            {comment === false &&
                <>
                    <div>
                        This post doesn't exist or is currently unavailable.
                    </div>
                </>
            }
        </>
    )
}

export default RenderCommentAndReplies;