import React from 'react';
import MyStarsCard from '../../components/stars/MyStarsCard';

const Analytics = () => {
  return (
    <>
        <div className=" ">
            <h1>Yalango Analytics</h1>
            <div className="m-0 w-full grid grid-cols-1 sm:grid-cols-2">
                <div>
                    <MyStarsCard />
                </div>
            </div>
        </div>
    </>
  )
}

export default Analytics;