import React, {useState, useEffect} from 'react';
import Button from '../../general/Button';
import Modal from '../../general/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLoader } from '@fortawesome/pro-solid-svg-icons';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import { faFolder } from '@fortawesome/pro-solid-svg-icons';
import { faThumbsDown } from '@fortawesome/pro-duotone-svg-icons';
import {faCircleExclamation} from '@fortawesome/pro-solid-svg-icons';
import { faRotateRight } from '@fortawesome/pro-duotone-svg-icons';

const ModalMenuItem = ({moveItemCurrentParent, modalMenuItemParent, itemThatIsMoving, menuItem, toggleItems, folderClick, selectedFolder}) => {
    console.log("Menu item: ", menuItem, selectedFolder);
    /* 

    <>
                <div className="ml-4 hover:bg-gray-200 p-3 rounded-lg relative cursor-not-allowed flex flex-row justify-start gap-6 place-items-center">
                    <div className="flex flex-row gap-3">
                        <div className="text-burnt-sienna">
                            <FontAwesomeIcon icon={faFolder} />
                        </div>
                        <div className="text-gray-400 ">
                            <FontAwesomeIcon icon={faCircleExclamation} />
                        </div>
                    </div>
                    <div>
                        <div className="line-through">
                            {menuItem.text}
                        </div>
                        <div className="text-sm opacity-70 text-gray-600">
                            A folder cannot be moved inside itself.
                        </div>
                    </div>
                </div>
                </>
    */
    return (
        <>
            {menuItem.doc_id !== itemThatIsMoving.doc_id &&
                <>
                    <div>
                        <div onClick={() => folderClick(menuItem, modalMenuItemParent)} className="hover:bg-gray-200 p-3 rounded-lg relative cursor-pointer flex flex-row justify-start gap-6 place-items-center">
                            <div className="flex flex-row gap-3">
                                <div>
                                    <input readOnly={true} checked={(selectedFolder !== null && 'doc_id' in selectedFolder && selectedFolder.doc_id === menuItem.doc_id) ? true : false} type="checkbox" />
                                </div>
                                <div className="text-sandy-brown">
                                    <FontAwesomeIcon icon={faFolder} />
                                </div>
                                <div className="text-gray-400 ">
                                    <FontAwesomeIcon icon={toggleItems[menuItem.doc_id] ? faCaretDown : faCaretRight} />
                                </div>
                            </div>
                            <div>
                                {menuItem.text}
                            </div>
                        </div>
                        {(toggleItems[menuItem.doc_id]) &&
                            <>
                                {menuItem.children.map((childMenuItem)=>(
                                    <>
                                        {childMenuItem.content_type === "folder" &&
                                            <>
                                                <div key={childMenuItem.doc_id} className="ml-4">
                                                    <ModalMenuItem  modalMenuItemParent={menuItem} itemThatIsMoving={itemThatIsMoving} menuItem={childMenuItem} toggleItems={toggleItems} folderClick={folderClick} selectedFolder={selectedFolder} />
                                                </div>
                                            </>
                                        }
                                    </>
                                ))}
                            </>
                        }
                        
                    </div>
                </>  
            }
        </>
    )
}

const ModalContent = ({closeFunction, moveItemCurrentParent, folders, item, loading, menuItems, moveItemToFolderSaveChanges}) => {
    let startToggle = {};
    if (menuItems !== null){
        menuItems.forEach((item, index) => {
            startToggle[item.doc_id] = false;
        });
    }
    const topLevelFolder = [{
        'text': 'Top level',
        'doc_id': 'top_level', 
        'content_type': 'folder',
        'children': menuItems
    }];
    startToggle[topLevelFolder.doc_id] = true;

    const [selectedFolder, setSelectedFolder] = useState(null);
    const [toggleItems, setToggleItems] = useState(startToggle);

    const [page, setPage] = useState("folder-selection");

    const folderClick = (folder, modalMenuItemParent) => {
        console.log("Clicked on ", folder, "with parent: ", modalMenuItemParent);
        toggleItem(folder.doc_id);
        folder['parent_folder'] = modalMenuItemParent;
        setSelectedFolder(folder);
    }
    const toggleItem = (doc_id) => {
        if (doc_id === undefined) { return null }
        let copy = JSON.parse(JSON.stringify(toggleItems));
        let current = copy[doc_id];
        let n = null;
        if (current) { n = false }
        else { n = true }
        copy[doc_id] = n;
        setToggleItems(copy);
    }

    const closeToggleItem = (doc_id) => {
        if (doc_id === undefined) { return null }
        let copy = JSON.parse(JSON.stringify(toggleItems));
        copy[doc_id] = false;
        setToggleItems(copy);
    }

    const saveChangesClick = async () => {
        await moveItemToFolderSaveChanges(selectedFolder);
    }

    console.log(moveItemCurrentParent, selectedFolder, menuItems);

    return (
        <>
            <div className="overflow-auto min-h-48 w-full sm:h-full">
                <div className="text-lg  text-center font-bold mb-4 text-gray-600 ">Move selected {item.content_type} '<span className="italic text-gray-600">{item.text}</span>'</div>
                {!loading ?
                    <>
                        {page === "folder-selection" &&
                            <>
                                <div className="max-h-96 overflow-y-auto">
                                    {topLevelFolder.map((menuItem)=>
                                        <>
                                            {menuItem['content_type'] === "folder" &&
                                                <>
                                                    <ModalMenuItem modalMenuItemParent={topLevelFolder} moveItemCurrentParent={moveItemCurrentParent} itemThatIsMoving={item} selectedFolder={selectedFolder} toggleItems={toggleItems} folderClick={folderClick} menuItem={menuItem} />
                                                </>
                                            }
                                        </>
                                    )}
                                </div>
                                {selectedFolder !== null &&
                                    <>
                                        <div className="my-4 text-sm text-center text-gray-600">
                                            {selectedFolder.doc_id !== item.doc_id ?
                                                <>
                                                    {moveItemCurrentParent.doc_id !== selectedFolder.doc_id ? 
                                                        <>
                                                            <span className="italic">On save</span>: Move {item.content_type} <span className="font-bold">{item.text}</span> to {selectedFolder.doc_id === "top_level" ? <>top level</> : <>folder <span className="font-bold">{selectedFolder.text}</span></>}
                                                            <div className="flex flex-col-reverse sm:flex-row justify-center gap-1 sm:gap-3 mt-6 ">
                                                                <Button key="cancel_button whitespace-nowrap" text="Cancel" color="red" onClick={(e)=>closeFunction(e)} />
                                                                <Button onClick={(e)=>saveChangesClick()} key="save_button" text={"Move " + item.content_type} color="green" /> 
                                                            </div>
                                                        </>
                                                    :
                                                        <>
                                                            <span className="italic">Error</span>: can't move <span className="font-bold">{item.text}</span> to <span className="font-bold">{selectedFolder.text}</span> because it's already in this folder 
                                                            <div className="flex flex-col-reverse sm:flex-row justify-center gap-1 sm:gap-3 mt-6 ">
                                                                <Button key="cancel_button whitespace-nowrap" text="Cancel" color="red" onClick={(e)=>closeFunction(e)} />
                                                            </div>
                                                        </>
                                                    }
                                                </>
                                            :
                                                <>
                                                    <span className="italic">Error</span>: can't move <span className="font-bold">{item.text}</span> to <span className="font-bold">{item.text}</span> because it's the same folder
                                                    <div className="flex flex-col-reverse sm:flex-row justify-center gap-1 sm:gap-3 mt-6 ">
                                                        <Button key="cancel_button whitespace-nowrap" text="Cancel" color="red" onClick={(e)=>closeFunction(e)} />
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </>
                                }
                               
                            </>
                        }
                    </>
                : 
                    <>
                        <div className="text-center text-4xl">
                            <FontAwesomeIcon icon={faLoader} className="fa-spin" />
                        </div>
                    </>
                }
            </div>
        </>   
    )
}

const ChangeItemFolderModal = ({moveItemCurrentParent, closeFunction, folders, item, loading, menuItems, moveItemToFolderSaveChanges }) => {
    
    return (
    <>
        <Modal closeFunction={closeFunction} content={<ModalContent moveItemCurrentParent={moveItemCurrentParent} moveItemToFolderSaveChanges={moveItemToFolderSaveChanges} item={item} menuItems={menuItems} loading={loading} folders={folders} closeFunction={closeFunction} />} size="3xl"/>
    </>
  )
};

export default ChangeItemFolderModal;
