import React, {useEffect, useState} from 'react';
import useUserSettings from '../hooks/useUserSettings';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../components/general/Button';
import Icon from '../components/general/Icon';
import Modal from '../components/general/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faUsers} from '@fortawesome/pro-duotone-svg-icons';
import {faUserGroup} from '@fortawesome/pro-duotone-svg-icons';
import {faCog} from '@fortawesome/pro-duotone-svg-icons';
import {Helmet} from 'react-helmet';

const MyProfile = () => {

    let {fetchCurrentUserInfo, addUsernameToCurrentUser} = useUserSettings();
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showPrivacyInfo, setShowPrivacyInfo] = useState(false);
    const [getStartedLoading, setGetStartedLoading] = useState(false);

    useEffect(()=>{
        const fetchData = async () => {
            let data = await fetchCurrentUserInfo();
            //console.log(data);
            setUserInfo(data);
        }
        fetchData();
    },[]);

    useEffect(()=>{
        if (userInfo !== null && userInfo !== undefined){
            //console.log(userInfo);
            setLoading(false);
        }
    },[userInfo]);

    const togglePrivacyInfo = () => {
        setShowPrivacyInfo(!showPrivacyInfo);
    }

    const hidePrivacyInfoModal = () => {
        setShowPrivacyInfo(false);
    }

    const makePublicProfileClick = async () => {
        setGetStartedLoading(true);
        await addUsernameToCurrentUser();
        navigate("/settings/profile");
        setGetStartedLoading(false);
    }

    return (
        <>
            <Helmet>
                <title>My profile - Yalango</title>
                <meta name="description" content={"Your public profile on Yalango."} />
            </Helmet>
            {!loading &&
                <>
                    <div className="">
                        {('public_profile' in userInfo && userInfo['public_profile'] === false) &&
                            <>
                                <div className="  bg-persian-green h-full min-h-screen flex flex-col justify-center">
                                    <div className="">
                                        <div className="bg-white md:w-4/6 m-auto shadow-2xl rounded-lg md:px-0 px-8 py-8 text-black">
                                            <div className="text-center text-4xl p-4">Be social with Yalango</div>
                                            <div className="md:w-4/6 flex flex-col gap-5 m-auto text-center text-lg">
                                                <div>
                                                    Make a profile and be discovered by other users. Add your friends and encourage each other in study circles.
                                                </div>
                                                <div>
                                                    <Button text={getStartedLoading ? "Creating profile..." : "Create my profile"} onClick={makePublicProfileClick} />
                                                </div>
                                                <div onClick={togglePrivacyInfo} className="text-white rounded-lg shadow-lg  text-base ">
                                                    <div className="font-bold rounded-lg cursor-pointer bg-charcoal p-4">
                                                        1. What does this mean for my privacy and data?
                                                    </div>
                                                    {showPrivacyInfo &&
                                                        <>
                                                            <Modal closeFunction={hidePrivacyInfoModal} size="normal" content={<PrivacyModalContent userInfo={userInfo} />} />
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        {('public_profile' in userInfo && userInfo['public_profile'] === true) &&
                            <>
                            <div className=" ">
                                <h1>Social network</h1>
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 place-items-center">
                                    <Link to={"/u/"+userInfo.username} className="no-underline">
                                        <div className="flex flex-col w-64 h-64 place-items-center p-10 rounded-full shadow-lg border-2 bg-white border-gray-200 border-dashed">
                                            <div className="text-xl font-bold">
                                                Public profile
                                            </div>
                                            <div className="text-9xl">
                                                <Icon color="blue" hover={"none"} icon={<FontAwesomeIcon icon={faUsers} />} />
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to="/friends" className="no-underline">
                                        <div className="flex flex-col w-64 h-64 place-items-center p-10 rounded-full shadow-lg border-2 bg-white border-gray-200 border-dashed">
                                            <div className="text-xl font-bold">
                                                Friends
                                            </div>
                                            <div className="text-9xl">
                                                <Icon color="green" hover={"none"} icon={<FontAwesomeIcon icon={faUserGroup} />} />
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to="/settings/profile" className="no-underline">
                                        <div className="flex flex-col w-64 h-64 place-items-center p-10 rounded-full shadow-lg border-2 bg-white border-gray-200 border-dashed">
                                            <div className="text-xl font-bold">
                                                Settings
                                            </div>
                                            <div className="text-9xl">
                                                <Icon color="black" hover={"none"} icon={<FontAwesomeIcon icon={faCog} />} />
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            </>
                        }
                    </div>
                </>
            }
        </>
    )
}

const PrivacyModalContent = ({userInfo}) => {
    return (
        <>
            <h1>Your privacy</h1>
            <div className="text-base flex flex-col gap-3 rounded-lg text-black px-6 py-4">
                <div>
                    We are planning to offer advanced privacy options for user profiles, so you can choose exactly what you want to share with other users.
                </div>
                <div className="">
                    Please note that the username and display name you choose in your profile settings will be public to everyone, regardless of your privacy settings.
                </div>
            </div>
        </>
    )
}

export default MyProfile;
