import React from 'react';
import {motion} from 'framer-motion';
import YalangoHead from '../../../../assets/svg/yalango/YalangoHead.svg';

const MemoryGridItem = (props) => {
    const {correctAnswerIds, firstItemCardId, secondItemCardId, cardClick, memoryCards, card, index} = props;

    const isFirstItemShown = (card.card_id === firstItemCardId);
    const isSecondItemShown = (card.card_id === secondItemCardId);
    const isSolved = correctAnswerIds.includes(card.answer_id);

    const solvedClass = "bg-persian-green text-white font-bold shadow-green-900";
    const hiddenItemClass = "bg-white text-charcoal shadow-charcoal";

    return (
        <>
            <motion.div whileHover={{y:-4}} key={"bubble_"+index} whileTap={(!isFirstItemShown && !isSecondItemShown &&!isSolved) && {scale:0.9,rotate:360}} onClick={() => cardClick(card)} className={`${isSolved ? solvedClass : hiddenItemClass} + "  cursor-pointer m-2 flex relative text-center shadow-lg rounded-full  flex-grow p-3 h-24 w-24 md:h-40 md:w-40 overflow-auto`}>
                <div className="m-auto">
                    <div className="text-base md:text-2xl">
                        {((isFirstItemShown || isSecondItemShown) || isSolved) &&
                            card.text
                        }
                    </div>
                </div>
                {!isFirstItemShown && !isSecondItemShown && !isSolved &&
                    <div className="z-0 absolute left-0 top-0 w-full m-auto h-full">
                        <img src={YalangoHead} className="w-4/6 m-auto h-full" alt="memory" />
                    </div>
                }
            </motion.div>
        </>
    )
}


const MemoryGrid = (props) => {
    const {correctAnswerIds, firstItemCardId, secondItemCardId, cardClick, memoryCards} = props;
    return (
        <>
            <div className="m-auto md:p-6 w-full lg:w-5/6 grid grid-cols-4 gap-12 md:gap-4 place-items-center">
                {memoryCards.map((card, index)=>(
                    <>
                        <MemoryGridItem {...{...props, card, index}}  />
                    </>
                ))}
                
            </div>
        </>
    )
}

export default MemoryGrid;
