import React, {useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const LanguageSelector = ({userLanguages, allLanguages, selectedLanguage, setSelectedLanguage, setSelectedSection, setSelectedCategory, setSelectedItem, sourceLanguage, setSourceLanguage}) => {
    const location = useLocation();
    let navigate = useNavigate();
    let currentPath = location.pathname;

/*     useEffect(() => {
        setQueryLanguage(query);
    },[]) */

    useEffect(()=>{
        //console.log("Change: ", selectedLanguage);
    },[selectedLanguage]);

    const setParams = (value = "", name) => {
        const searchParams = new URLSearchParams();
        searchParams.set(`${name}`, value);
        return searchParams.toString();
    }

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }

    const query = useQuery();

    const setQueryLanguage = () => {
        const queryLanguage = query.get("lang");
        updateLanguageState(queryLanguage);
    }

    const updateLanguageState = (lang) => {
        setSelectedLanguage(lang);
        setSelectedSection(-1);
        setSelectedCategory(-1);
        setSelectedItem(-1);
    }

    const handleChangeLanguage = (e) => {
        setSelectedLanguage(e.target.value);
        let dataset = e.target.options[e.target.selectedIndex].dataset;
        setSourceLanguage(dataset['sourceLang']);
        setSelectedSection(-1);
        setSelectedCategory(-1);
        setSelectedItem(-1);

        const url = setParams(e.target.value, "lang");
        navigate(currentPath+'?'+url);
    }
    
    return (
        <>
        {userLanguages.length > 1 &&
            <>
                <div className="absolute">
                    <form>
                        <select className="capitalize" value={selectedLanguage} onChange={handleChangeLanguage}>
                            {Object.keys(allLanguages).length !== 0 && userLanguages.map((lang, index) => (
                                <>
                                    <option key={'selector_'+index} data-source-lang={lang['source_language']} value={allLanguages[lang['target_language']]['ISO_639-1']}>{allLanguages[lang['target_language']]['name']['en']}</option>
                                </>
                            ))}
                        </select>
                    </form>
                </div>
            </>
        }
        </>
    )
}

export default LanguageSelector;