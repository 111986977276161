import React, {useState, useEffect} from 'react';
import Button from '../../../general/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { faArrowAltRight } from '@fortawesome/pro-solid-svg-icons';

import { faThumbsDown, faThumbsUp } from '@fortawesome/pro-solid-svg-icons';

const ContextGame = ({reviewFinished, setReviewFinished, isPremium, currentCardIndex, setCurrentCardIndex, nbOfAnswers, setNbOfAnswers, cardsLeft, setCardsLeft, gameScreen, score, setScore, setGameScreen, mistakes, setMistakes, audioLoading, autoPlayPronunciations, globalHandleSkipAnswer, globalHandleCorrectAnswer, globalHandleWrongAnswer,  isInMix, googleTextToSpeechClick, playMode, setPlayMode, showLoadingScreen, setShowLoadingScreen, cards, deck, decks, loadingItems, refreshData, howManyItems, backToPlayPageLink, playModeArray, id, type}) => {
    const [showAnswer, setShowAnswer] = useState(false);

    const wrongClick = () => {
        goToNextCard()
        setNbOfAnswers(nbOfAnswers+1);
    }

    const correctClick = () => {
        setScore(score+1);
        setNbOfAnswers(nbOfAnswers+1);
        goToNextCard();
    }

    const goToNextCard = () => {
        if (currentCardIndex < cards.length - 1){
            setShowAnswer(false);
            setCurrentCardIndex(currentCardIndex + 1);
            setCardsLeft(cardsLeft-1);

        }
        else {
            setGameScreen("endscreen")
        }
    }

    const revealClick = () => {
        setShowAnswer(true);
    }

/*     useEffect(()=>{
        if (cards !== null && cards !== undefined && cards.length > currentCardIndex+1){
            if (!(cards[currentCardIndex].hasOwnProperty("context_sentence") && cards[currentCardIndex]["context_sentence"] !== null)){
                goToNextCard();
            }
        }
    }, [currentCardIndex, cards]); */
  
    return (
        <>
            {(loadingItems === false && showLoadingScreen === false) &&
                    <>
            <div className="h-full justify-center gap-10 flex-col flex">
               
                {cards !== null && cards.length > currentCardIndex && cards[currentCardIndex].hasOwnProperty("context_sentence") && cards[currentCardIndex]["context_sentence"] !== null &&
                    <>
                        <div className="text-xl 3xl:text-5xl text-center ">
                            {cards[currentCardIndex]["context_sentence"].toLowerCase().indexOf(cards[currentCardIndex]["target"].toLowerCase()) !== -1 &&
                                <>    
                                    {cards[currentCardIndex]["context_sentence"].split("").map((letter, letterIndex) => (
                                        <>
                                            {(cards[currentCardIndex]["context_sentence"].toLowerCase().indexOf(cards[currentCardIndex]["target"].toLowerCase()) <= letterIndex) && (letterIndex <= (cards[currentCardIndex]["context_sentence"].toLowerCase().indexOf(cards[currentCardIndex]["target"].toLowerCase()) + cards[currentCardIndex]["target"].length-1)) ?
                                                <>
                                                    {!showAnswer &&
                                                    <span className="text-persian-green" key={"letter_"+letterIndex}>
                                                        {letter}
                                                    </span>
                                                    }
                                                    {(showAnswer && letterIndex === (cards[currentCardIndex]["context_sentence"].toLowerCase().indexOf(cards[currentCardIndex]["target"].toLowerCase()) + cards[currentCardIndex]["target"].length-1)) &&
                                                        <>
                                                            <span className="text-persian-green " key={"source_"+letterIndex}>
                                                                { } {cards[currentCardIndex]["source"].toLowerCase()} { }
                                                            </span>
                                                        </>
                                                    }
                                                
                                                </>
                                                :
                                                <span key={"letter_"+letterIndex}>
                                                    {letter}
                                                </span>
                                                
                                            }
                                        </>
                                    ))}                    
                                </>
                            }
                            
                        </div>
                        <div className="muted-text text-lg 3xl:text-3xl text-center">
                    {showAnswer ? 
                    <>
                    Did you get it right?
                    </>
                    :
                    <>
                    What does the text in <span className="font-bold text-persian-green">green</span> mean?</>
}
                </div>
                        {showAnswer  &&
                                <>
                                    <div className="text-center text-persian-green font-bold text-lg 3xl:text-3xl ">
                                        {cards[currentCardIndex]["target"].toLowerCase()} = {cards[currentCardIndex]["source"].toLowerCase()}
                                    </div>
                                </>
                            }
                        {!showAnswer ?
                            <div className="flex flex-row justify-center gap-2">
                                <div onClick={revealClick}>
                                    <Button  icon={faMagnifyingGlass} text={"Reveal translation"} color="charcoal" />
                                </div>
                            </div>
                            :
                            <div className="flex flex-row justify-center gap-2">
                                 <div onClick={wrongClick}>
                                    <Button  icon={faThumbsDown} text={"Wrong"} color="red" />
                                </div> 
                                <div onClick={correctClick}>
                                    <Button  icon={faThumbsUp} text={"Correct"} color="green" />
                                </div> 
                            </div>
                           
                        }
                        
                    </>
                }
                {cards !== null && cards.length === 0 &&
                    <>
                        <div className="text-center">
                            No sentences found for the selected cards.
                        </div>
                        
                    </>
                }
            </div>
            </>
            }
        </>
  )
}

export default ContextGame;