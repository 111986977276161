import React, {useEffect, useState} from 'react';
import RenderComments from './RenderComments';
import useComments from '../../hooks/useComments';

const RenderCommentReplies = (props) => {
    const {repliesDocIds, parentCommentDocId} = props;
    console.log(parentCommentDocId);
    const {fetchCommentReplies, 
        fetchCommentRepliesInRealtime} = useComments();
    const [loading, setLoading] = useState(true);
    const [commentReplies, setCommentReplies] = useState(null);

    const fetchReplies = async () => {
        setLoading(true);
        let replies = await fetchCommentReplies({repliesDocIds});
        setCommentReplies(replies);
        setLoading(false);
    }

    const refresh = async () => {
        setLoading(true);
        setLoading(false);
    }

    useEffect(()=>{
        let unsub = null;
        const fetchData = async () => {
            if (parentCommentDocId !== undefined){
                unsub = await fetchCommentRepliesInRealtime({parentCommentDocId, sortByValue: "highest_rating", setComments: setCommentReplies, setLoading:setLoading});
            } else if (repliesDocIds !== undefined){
                fetchReplies();
            }
        }
        if (repliesDocIds !== undefined || parentCommentDocId !== undefined){
            fetchData();
        }
        return () =>{
            unsub !== null && unsub();
        }
    },[repliesDocIds, parentCommentDocId]);

    return (
        <>
            <div className="w-full">
                <RenderComments comments={commentReplies} loading={loading} refreshComments={refresh} />
            </div>
        </>
    )
}

export default RenderCommentReplies;