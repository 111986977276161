import React from 'react';
import {Link} from 'react-router-dom';
import Peder from '../../assets/svg/yalango/Peder.svg';
import FooterMenu from '../../components/menus/FooterMenu';

const AboutYalango = () => {
  return (
        <>
            <div className="  p-8 bg-50 min-h-screen flex flex-col gap-8 h-full sm:w-1/2 m-auto">
                <h1>About Yalango</h1>
                <div className="flex flex-col gap-1 place-items-center justify-start">
                    <img src={Peder} alt="Peder" className="w-4/6 rounded-full shadow-xl mx-auto sm:w-96 my-0" />
                    <div className="text-gray-600 text-sm bg-white p-6 rounded-xl shadow-xl text-lg">Hi there, my name is <Link to={"/u/peder"}>Peder B. Helland</Link> and I'm the creator of Yalango!</div>
                </div>
                <div className="flex flex-col gap-3">
                  <p>
                    I love studying and learning about languages, but I was missing an easy way to organize and review what I learnt across different languages. This drove me to start Yalango - a free web platform combining multiple useful language tools in one place.
                  </p>
                  <p>
                    My goal with Yalango is to create powerful and flexible tools for serious language learners. I'm particularly interested in creating tools based on an accurate understanding of your current language level and then adapt the materials to your level.
                  </p>
                  <p>
                    I have already come a long way creating the foundational features on Yalango, such as decks, tables, vocabulary, games, AI texts, and more. However, I'm looking forward to continue building a better and more powerful tool for you in 2023.
                  </p>
                  <p>
                    I'd love to hear your thoughts at <a className="" href="mailto:hello@yalango.com">hello@yalango.com</a>, or by providing feedback directly on the website.
                  </p>
                  <p>
                    Best wishes to you on your language learning and I hope Yalango will prove to be helpful!
                  </p>
                  <p>
                    - Peder B. Helland
                  </p>
                </div>
               
            </div>
        </>
  )
}

export default AboutYalango;