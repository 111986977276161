import React from 'react';
import ForumsHome from '../../components/forums/ForumsHome';

const Forums = () => {
  return (
    <div className="  p-4 h-full min-h-screen">
        <ForumsHome />
    </div>
  )
}

export default Forums