import React from 'react';
import YalangoThumbsUpSvg from '../../assets/svg/yalango/yalango_thumbs_up_without_circle.svg';
import Button from '../general/Button';
import {Link} from 'react-router-dom';
import { useAuth } from "../../contexts/AuthContext";

const GeneralSuccessPage = ({headline, message, goBackLink, goBackText}) => {
  return (
    <div className="h-full w-full flex flex-col justify-center">
        <div className="">
            <img src={YalangoThumbsUpSvg} alt="Yalango mascot happy" className="w-36 m-auto p-4 sm:w-48 lg:w-64 mx-auto my-0" />
        </div>
        <div className=" flex flex-col justify-center place-items-center w-full text-center gap-4">
            <div className="text-2xl text-gray-700 font-bold">
                {headline}
            </div>
            <div className="w-full md:w-3/6 m-auto">
                {message}
            </div>
            <div>
                {goBackLink !== null &&
                    <Link className="no-underline" to={goBackLink}>
                        <Button color="green" text={goBackText} />
                    </Link>
                }
            </div>
        </div>
        </div>
  )
}

export default GeneralSuccessPage;