import React from 'react';
import YalangoSvg from '../../assets/svg/yalango/yalango.svg';
import YalangoBook from '../../assets/svg/yalango/yalango_book.svg';
import YalangoQuestion from '../../assets/svg/yalango/yalango_question.svg';
import YalangoThumbsUp from '../../assets/svg/yalango/yalango_thumbs_up_without_circle.svg';
import YalangoComputer from '../../assets/svg/yalango/yalango_computer.svg';
import {Link} from 'react-router-dom';
import Button from '../../components/general/Button';
import {useAuth} from '../../contexts/AuthContext';

const FeaturesOverview = () => {
    const {currentUser} = useAuth();
  return (
    <>
        <div className="  text-center overflow-x-hidden">
            <div className="h-full px-4 flex flex-col gap-4 justify-center">
                <h1>Features on Yalango</h1>
                <div className="group relative my-8 sm:px-6 pb-6 sm:p-8   w-full lg:w-4/6 m-auto">
                    <div className="group-hover:rotate-0 lg:-rotate-6  transition duration-300 ease-in-out sm:absolute transform  sm:-left-2 text-center text-white sm:-top-2 text-2xl font-bold  bg-gradient-to-r from-persian-green to-beautiful-blue rounded-lg shadow-lg p-2">
                        Gamified learning
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 justify-center">
                        <div className="text-gray-700 flex flex-col gap-4 text-justify">
                            <div>
                                Yalango combines the flexibility of software like <span className="font-bold text-beautiful-blue">Anki</span> with fun gamification features in the style of <span className="font-bold text-beautiful-blue">Duolingo</span>. 
                            </div>
                            <div>
                                Create your own decks with words you wish to study, or <span className="font-bold text-beautiful-blue">easily import</span> decks from Anki, Quizlet, csv files, and more.
                            </div>
                            <div>
                                Leverage <span className="font-bold text-beautiful-blue">spaced repetition</span> to review your decks with <span className="font-bold text-beautiful-blue">flashcards</span>, <span className="font-bold text-beautiful-blue">quizes</span>, <span className="font-bold text-beautiful-blue">writing challenges</span>, and <span className="font-bold text-beautiful-blue">memory</span> games.
                            </div>
                            <div>
                                Yalango keeps track of all your <span className="font-bold text-beautiful-blue">progress</span> across the website to generate exercises and show learning materials based on your current <span className="font-bold text-beautiful-blue">vocabulary</span>.
                            </div>
                            <div>
                                To make it more fun, you can also set  <span className="font-bold text-beautiful-blue">daily goals</span> and track your  <span className="font-bold text-beautiful-blue">streak</span>.
                            </div>
                            <div>
                                Want to try the games before signing up? 
                            </div>
                            <div className="text-white w-32 text-center cursor-pointer transition duration-500 ease-in-out group-hover:from-beautiful-blue group-hover:to-persian-green p-2 bg-gradient-to-r from-persian-green to-beautiful-blue rounded-lg shadow-lg font-bold">
                                    <Link className="no-underline" to={"/decks/29660637823/play?items=10&mode=target"}>
                                        Play now
                                    </Link>
                                </div>
                        </div>
                        <div className="p-4 transform group-hover:rotate-0 flex flex-col gap-3  transition duration-500 ease-in-out">
                            <img src={YalangoThumbsUp} alt="Tim from Yalango reading a book." className="w-48 m-auto" />
                            <div className="text-center text-sm text-gray-600">
                                "Hello, my name's Tim. I'm your new study buddy!"
                            </div>
                        </div>
                    </div>
                </div>
                <div className="group relative my-8 sm:px-6 pb-6 sm:p-8   w-full lg:w-4/6 m-auto">
                    <div className="group-hover:rotate-0 lg:-rotate-6  transition duration-300 ease-in-out sm:absolute transform  sm:-left-2 text-center text-white sm:-top-2 text-2xl font-bold  bg-gradient-to-r from-persian-green to-beautiful-blue rounded-lg shadow-lg p-2">
                        Organize your studies
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 justify-center">
                    
                        <div className="text-gray-700 flex flex-col gap-4 text-justify">
                            <div>
                                Learning a language takes many years and most people use a variety of learning materials. 
                            </div>
                            <div>
                                Yalango helps you to <span className="font-bold text-beautiful-blue">review and organize</span> everything you learn across different environments.
                            </div>
                            <div>
                                We accomplish this with <span className="font-bold text-beautiful-blue">My Library</span>, which you can think of as Google Drive for everything you learn in your target language.
                            </div>
                            <div>
                                Easily <span className="font-bold text-beautiful-blue">create folders and nested folders</span> to organize all your content. You can for example have one folder where you save everything you've learnt in a language class, and another folder for words you've learnt on Duolingo.
                            </div>
                            <div>
                                After you've created folders of content, you can for example <span className="font-bold text-beautiful-blue">study all the items inside</span> at the same time.
                            </div>
                        </div>
                        <div className="p-4 flex flex-col gap-3 transition duration-500 ease-in-out">
                            <img src={YalangoBook} alt="Tim from Yalango on the computer." className="w-56 m-auto" />
                            <div className="text-center text-sm text-gray-600">
                                "You can also soon save interactive notes on Yalango!"
                            </div>
                        </div>
                    
                    </div>
                </div>
                <div className="group relative my-8 sm:px-6 pb-6 sm:p-8   w-full lg:w-4/6 m-auto">
                    <div className="group-hover:rotate-0 lg:-rotate-6  transition duration-300 ease-in-out sm:absolute transform  sm:-left-2 text-center text-white sm:-top-2 text-2xl font-bold  bg-gradient-to-r from-persian-green to-beautiful-blue rounded-lg shadow-lg p-2">
                        Your data
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 justify-center">
                    
                        <div className="text-gray-700 flex flex-col gap-4 text-justify">
                            <div>
                                Some services try to <span className="font-bold text-beautiful-blue">lock you</span> into their own ecosystem. This is the opposite of Yalango's philosophy.
                            </div>
                            <div>
                                With Yalango, you can <span className="font-bold text-beautiful-blue">easily import</span> and <span className="font-bold text-beautiful-blue">export</span> all your data as you'd like. It's your data and we actually encourage you to use many different services alongside Yalango!
                            </div>
                        </div>
                        <div className="p-4 flex flex-col gap-3 transition duration-500 ease-in-out">
                            <img src={YalangoComputer} alt="Tim from Yalango on the computer." className="w-56 m-auto" />
                            <div className="text-center text-sm text-gray-600">
                                "You own your data!"
                            </div>
                        </div>
                    </div>
                </div>
                <div className="group relative my-8 sm:px-6 pb-6 sm:p-8   w-full lg:w-4/6 m-auto">
                    <div className="group-hover:rotate-0 lg:-rotate-6  transition duration-300 ease-in-out sm:absolute transform  sm:-left-2 text-center text-white sm:-top-2 text-2xl font-bold  bg-gradient-to-r from-persian-green to-beautiful-blue rounded-lg shadow-lg p-2">
                        List of features
                    </div>
                    <div className="grid grid-cols-1 justify-center">
                        <div className="text-gray-700 flex flex-col gap-4 text-justify">
                            <div>
                                We are busy making powerful tools for language learning, based on your feedback. Here are some of the features we already support in the free plan:
                            </div>
                            <ul className="list-disc">
                                <li>Create unlimited decks with words and phrases</li>
                                <li>Create unlimited tables for practicing conjugations, tenses, and more</li>
                                <li>Study unlimited languages (180 languages supported), with a separate dashboard and content for each language</li>
                                <li>Practice decks and tables with flashcards, quizes, typing, and memory games</li>
                                <li>Spaced repetition exercises to optimize learning</li>
                                <li>Keep track of your progress for each word in My Vocabulary</li>
                                <li>Review and practice recent mistakes</li>
                                <li>Organize all your content in folders and subfolders in My Library</li>
                                <li>Practice all items in a folder at the same time</li>
                                <li>Discover content by other users in the public directory</li>
                                <li>Set a custom daily goal and track your streak</li>
                                <li>Study AI-generated texts in your target language adapted to your level</li>
                                <li>Automatic machine translations (500 per month)</li>
                                <li>Import decks from Quizlet, csv files, text input, and more</li>
                                <li>Export all the data you need (to use with other services as well)</li>
                                <li>Study YouTube videos on Yalango</li>
                            </ul>
                            <div>We also have a paid premium plan that gives you the following extra features:</div>
                            <ul className="list-disc">
                                <li>Automatic audio for all your decks</li>
                                <li>Unlimited automatic machine translations</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="text-center flex flex-row justify-center mb-16">
                    <Link className="text-4xl no-underline" to={currentUser !== null ? '/dashboard' : "/register"}>
                        <Button color="green" text={currentUser !== null ? "Go to dashboard" : "Get started for free"} />
                    </Link>
                </div>
            </div>
        </div>
    </>
  )
}

export default FeaturesOverview