import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import SectionSettingsModal from '../modals/SectionSettingsModal';
import '../../../css/CardGrid.css';
import Card from './Card';
import CardItems from './CardItems';
import SectionMenu from './SectionMenu';
import Button from '../../general/Button';
import {projectFirestore} from '../../../firebase/config.js';
import { useAuth } from "../../../contexts/AuthContext";
import useLanguages from '../../../hooks/useLanguages';
import GeneralFeedbackCircle from '../../feedback/components/GeneralFeedbackCircle';

const AwaitSectionCardGrid = ({deckIds, section}) => {
    //console.log("Section start: ", section);
    let {currentUser} = useAuth();
    let {getAllLanguages} = useLanguages();

    /* DISPLAY MODES */
    const [zoomMode, setZoomMode] = useState(false);

    /* MODAL */
    const [showModal, setShowModal] = useState(false);
    const [modalMode, setModalMode] = useState("");

    const [selectedCard, setSelectedCard] = useState("");
    const [sourceInput, setSourceInput] = useState("");
    const [targetInput, setTargetInput] = useState("");

    const [allLanguages, setAllLanguages] = useState(null);

    /* DOCS */
    const [docs, setDocs] = useState(null);
    const [deck, setDeck] = useState(null);

    const [loadingItems, setLoadingItems] = useState(true);

    /* Fetch data once in beginning */
    useEffect(() => {
        if (deckIds !== undefined) {
            //console.log(deckIds);
            fetchAllItems(deckIds);
        };    
    }, [deckIds]);

    useEffect(()=>{
        const fetchAllLanguages = async () => {
            let all_languages = await getAllLanguages();
            setAllLanguages(all_languages);
        }

        fetchAllLanguages();
    },[]);

    useEffect(() => {   
        //console.log("SECTION: ", section);
    }, [section]);

    const fetchAllItems = async (deck_ids) => {
        let items_list = [];
        //console.log("DECK IDS: ", deck_ids);
        if (deck_ids.length > 0){
            for (let deck_id of deck_ids) {
                //console.log("STARTING LOOP FOR: ", deck_id);
                const docRef = projectFirestore.collection('decks').where('uid','==', currentUser.uid).where('id', '==', deck_id);
                let items = await findItemsInDeck(docRef, deck_id);
                items_list = [...items_list, ...items];
            };
        };
        if (docs !== null){
            setDocs(oldArray => [...oldArray, ...items_list]);
        } else {
            setDocs(items_list);
        }
    };

    useEffect(()=>{
        if (docs !== null){
            //console.log("Check docs ", docs);
            setLoadingItems(false);
        }
    },[docs])

    const findItemsInDeck = (docRef, deck_id) => {
        //console.log("Starting findItemsInDeck for DOC REF: ", docRef);
        let currentDeck;
        let items = docRef.get().then((querySnapshot) => {
            if (querySnapshot.empty){
                currentDeck = null;
                return null;
            }
            currentDeck = querySnapshot.docs[0].data();
            return querySnapshot.docs[0].ref.collection('items').get();
        }).then(querySnapshot => { 
            const temp_items_list = [];
            if (querySnapshot !== null){
                querySnapshot.forEach((doc) => {
                    temp_items_list.push({...doc.data(), deck: currentDeck, 'target_ISO_639-1':currentDeck['target_ISO_639-1'], 'source_ISO_639-1':currentDeck['source_ISO_639-1'], id:doc.id, deck_id: deck_id});
                });
            }
            return temp_items_list;

        }); 
        return items;
    };

    const resetInputData = () => {
        setTargetInput("");
        setSourceInput("");
    }
    
    return (
        <>  
            {section !== undefined && 
                <SectionMenu SectionSettingsModal={SectionSettingsModal} zoomMode={zoomMode} setZoomMode={setZoomMode} modalMode={modalMode} showModal={showModal} setShowModal={setShowModal} resetInputData={resetInputData} docs={docs} setDocs={setDocs} modalMode={modalMode} setModalMode={setModalMode} setTargetInput={setTargetInput} setSourceInput={setSourceInput} targetInput={targetInput} sourceInput={sourceInput} fetchAllItems={fetchAllItems} section={section} />
            }
            { loadingItems === true ?
                    <p className="text-center">Loading items...</p>
            :
                docs.length > 0 ? 
                <>
                    <CardItems allLanguages={allLanguages} zoomMode={zoomMode} docs={docs} modalMode={modalMode} setModalMode={setModalMode} Card={Card} />
                    
                </>
            : 
                <div className="text-black flex flex-col items-center justify-content gap-2 m-10">
                    <p className="text-base">There are no items in this section. You can come back to this page after you've added cards to decks inside this section.</p> 
                    <div classCenter="text-center">
                        <Link to={'/decks'}><Button color="green" text="Add items"></Button></Link>
                    </div>
                </div>
            } 
        </>
    )
}

export default AwaitSectionCardGrid;