import {projectFirestore, projectFunctions, timeStamp, firebase} from '../firebase/config.js';
import { useAuth } from "../contexts/AuthContext";

export default function useStars() {
    const {currentUser} = useAuth();

    const getCurrentUserStars = async () => {
        if (currentUser === null){return}
        let query = projectFirestore.collection("users").doc(currentUser.uid).collection("private-data").doc("stars");
        let results = await query.get();
        let stars = false;
        if (results.exists){
            stars = results.data();
        }
        return stars;
    }

    return {
        getCurrentUserStars
    }
}