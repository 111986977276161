import React, {useEffect, useState} from 'react';
import useDecks from '../../../../hooks/useDecks';
import {Link} from 'react-router-dom';
import ScoreScreen from '../../../../components/vocabulary/games/common-assets/ScoreScreen';
import MemoryGrid from './MemoryGrid';
import MemoryEndScreen from './MemoryEndScreen';
import Button from '../../../general/Button';

import { faX } from '@fortawesome/pro-solid-svg-icons';
import { faQuestion } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingGame from '../common-assets/LoadingGame';
import Icon from '../../../../components/general/Icon';
import MemoryHelpModal from './modals/MemoryHelpModal';

const MemoryGame = ({reviewFinished, nbOfAnswers, setNbOfAnswers, setReviewFinished, playMode, gameScreen, score, setScore, setGameScreen, setPlayMode, showLoadingScreen, setShowLoadingScreen, cards, deck, decks, loadingItems, refreshData, howManyItems, backToPlayPageLink, id, type}) => {

    const [nbOfSkips, setNbOfSkips] = useState(0);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    
    const [memoryCards, setMemoryCards] = useState([]);
    const [firstItemCardId, setFirstItemCardId] = useState(null);
    const [secondItemCardId, setSecondItemCardId] = useState(null);

    const [firstItemAnswerId, setFirstItemAnswerId] = useState(null);
    const [secondItemAnswerId, setSecondItemAnswerId] = useState(null);

    const [correctAnswerIds, setCorrectAnswerIds] = useState([]);

    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("Something went wrong.");

    let nbOfTiles = 16; //number of tiles (computer)
    let nbOfCards = Math.round(nbOfTiles/2); 

    const playAgain = async() =>{
        await refreshData();
        setFirstItemCardId(null);
        setSecondItemCardId(null);
        setFirstItemAnswerId(null);
        setSecondItemAnswerId(null);
        setCorrectAnswerIds([]);
        setScore(0);
        setCurrentQuestionIndex(0);
        setNbOfAnswers(0);
        setGameScreen("game");
        
    }
    
    const cardClick = (item) =>{
        if (!correctAnswerIds.includes(item.answer_id)){
            if (firstItemCardId === null){
                console.log(item);
                setFirstItemCardId(item.card_id);
                setFirstItemAnswerId(item.answer_id);
            }
            else if (secondItemCardId === null && item.card_id !== firstItemCardId){
                console.log(item);
                setSecondItemCardId(item.card_id);
                setSecondItemAnswerId(item.answer_id);
            }
        }
    }

    useEffect(()=>{
        const resetValues = () => {
            setFirstItemCardId(null);
            setSecondItemCardId(null);
            setFirstItemAnswerId(null);
            setSecondItemAnswerId(null);
        }

        resetValues();
    },[correctAnswerIds])

    useEffect(()=>{

        const resetValues = () => {
            setFirstItemCardId(null);
            setSecondItemCardId(null);
            setFirstItemAnswerId(null);
            setSecondItemAnswerId(null);
        }

        if (firstItemAnswerId !== null && secondItemAnswerId !== null){
            if (firstItemAnswerId === secondItemAnswerId){
                if (!correctAnswerIds.includes(firstItemAnswerId)){
                     // correct answers
                    setTimeout(function(){
                        setCorrectAnswerIds([...correctAnswerIds, firstItemAnswerId]);
                        setScore(score+1);
                    },750); //delay is in milliseconds 
                } 
            }
            else {
                // add delay
                setTimeout(function(){
                    setNbOfAnswers(nbOfAnswers+1);
                    resetValues();
               },750); //delay is in milliseconds 
            }       
        }

        if (correctAnswerIds.length === nbOfCards){
            // End game
            setTimeout(function(){
                setGameScreen("endscreen");
           },750); //delay is in milliseconds 
        }
    }, [firstItemAnswerId, secondItemAnswerId, correctAnswerIds])

    useEffect (()=>{
        function shuffleArray(array) {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
            }
            return array;
        }

        const createCards = () => {
            // chose nbOfTiles/2 cards
            let randomNumbers = shuffleArray([...Array(Math.round(nbOfTiles/2)).keys()]);
            //console.log(randomNumbers);
            let chosenCards = [];
            for (let i=0;i<randomNumbers.length;i++) {
                chosenCards.push({
                    'text': cards[randomNumbers[i]]['target'], 
                    'answer_id': cards[randomNumbers[i]]['doc_id'], 
                    'card_id': i
                });
                chosenCards.push({
                    'text': cards[randomNumbers[i]]['source'], 
                    'answer_id': cards[randomNumbers[i]]['doc_id'],
                    'card_id': i+randomNumbers.length
                });
            
            }
            //console.log(chosenCards);
            setMemoryCards(shuffleArray(chosenCards));
        }

        if (cards !== null && loadingItems===false){
            if (cards.length>0 && cards.length >= nbOfCards){
                createCards();
            }
            if (cards.length < nbOfCards){
                setError(true);
                setErrorMessage("You need to have at least "+nbOfCards + " items in your " + type + " in order to play the memory game.");
            }
        }
    },[cards, loadingItems, nbOfCards, type, nbOfTiles]);

    const [showHelpModal, setShowHelpModal] = useState(false);

    const helpClick = () => {
        setShowHelpModal(true);
    }

    const closeHelpModal = () => {
        setShowHelpModal(false);
    }
    /*
                {(!loadingItems && !showLoadingScreen && gameScreen!=="endscreen") &&
            <>
            <div className="flex flex-row justify-center gap-6">
                <div>
                    <span className="text-gray-600 font-bold">Score</span>: {score}
                </div>
            </div>

            </>
            }
    */
    return (
        <>

            {(!loadingItems && !showLoadingScreen) &&
                <>
                <div className="p-8 flex flex-col items-center justify-center h-full w-full gap-4">
                    {error === false ?
                        <>
                            {(gameScreen==="game" & cards.length>=0) &&                           
                                <MemoryGrid correctAnswerIds={correctAnswerIds} firstItemCardId={firstItemCardId} secondItemCardId={secondItemCardId} cardClick={cardClick} memoryCards={memoryCards} />
                            }
                            {gameScreen==="endscreen" &&
                                <ScoreScreen score={score} nbOfAnswers={nbOfCards} backToPlayPageLink={backToPlayPageLink} playAgainClick={playAgain} />
                            }
                        </>
                    :
                        <>
                            <div>
                                <div className="flex">
                                    <div className="text-base m-auto p-4 text-center">
                                        <div className="max-h-xs text-black text-center flex flex-col place-items-center">
                                            <p>{errorMessage}</p>
                                            <Link className="no-underline" to={backToPlayPageLink} ><Button text="Go to play page" color="green" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>    
                        </>
                    }

                </div>
                </>
            }
        </>
    )
}

export default MemoryGame;
