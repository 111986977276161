import React, {useState} from 'react';
import { motion, AnimateSharedLayout  } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/pro-regular-svg-icons';
import Icon from './Icon';

const Modal = ({z_content, overflow, content, size, height, closeFunction, bgColor}) => {
    let sizeCode = "";
    let z_index_backdrop = "z-20";
    let z_index_content = "z-30";
    let defaultCloseValue = false;
    let heightCode = "";

    if (overflow === undefined){
        overflow = " overflow-y-auto ";
    }

    if (bgColor === undefined){
        bgColor = "bg-white";
    }

    if (closeFunction !== undefined) {
        defaultCloseValue = true;
    };
    
    if (height !== undefined){
        heightCode = height + " ";
    }

    const showCloseButton = defaultCloseValue;

    if (z_content !== undefined){
        z_index_content = "z-"+z_content.toString();
    }

    if (size === "normal"){
        sizeCode = "min-w-xs max-w-sm sm:max-w-5xl"
    }
    if (size === "big"){
        sizeCode = "min-w-xs max-w-sm md:max-w-5xl md:min-w-lg"
    }
    if (size === "2xl"){
        sizeCode = "min-w-xs max-w-sm md:max-w-5xl md:min-w-2xl"
    }
    if (size === "3xl"){
        sizeCode = " w-5/6 max-w-screen md:max-w-3xl md:min-w-2xl"
    }

    
    return (
        <>
            <div className="h-screen  z-20 left-0 top-0 fixed flex flex-col justify-center place-items-center w-full">
                <motion.div onClick={(e) => e.stopPropagation() } className={sizeCode+" " + heightCode + " " + z_index_content + " " + overflow + " " + bgColor +  " max-w-5/6  max-h-5/6 text-black m-6 bg-opacity-100 border shadow-xl rounded-lg"}>
                    {showCloseButton &&
                        <div className="relative">
                            <div className="absolute right-0 px-4 py-2 text-xl">
                                <Icon onClick={closeFunction} icon={<FontAwesomeIcon icon={faTimes} />} />
                            </div>
                        </div>
                    }
                    <div className={"p-8 rounded-lg " + bgColor}> 
                        {content}
                    </div>
                </motion.div>
                <div onClick={showCloseButton && closeFunction }  className={z_index_backdrop + " bg-gray-600 opacity-70  fixed left-0 top-0 w-screen h-screen "}>
                </div>
            </div>
        </>
    )
}

export default Modal;
