import React, {useState} from 'react';
import Button from '../../../components/general/Button';
import YalangoReadingSvg from '../../../assets/svg/yalango/yalango_book.svg';
import YalangoThumbsUpSvg from '../../../assets/svg/yalango/yalango_thumbs_up_without_circle.svg';
import {Link, useNavigate} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight} from '@fortawesome/pro-solid-svg-icons';
import {faArrowLeft} from '@fortawesome/pro-solid-svg-icons';

const MyDecksGetStarted = ({setShowGetStarted, userLanguages, setActionToTrueForCurrentUser, viewOnly}) => {
    const [viewIndex, setViewIndex] = useState(0);
    const views = ["welcome", "public", "statistics", "advanced"];
    const navigate = useNavigate();

    if (userLanguages !== undefined && userLanguages.length === 0){
        views.push("languages");
    }

    const nextPage = () => {
        if (viewIndex === views.length - 1){
            finishGetStarted();
        } else {
            setViewIndex(viewIndex+1);
        }  
    }

    const previousPage = () => {
        if (viewIndex > 0){
            setViewIndex(viewIndex-1);
        }  
    }

    const finishGetStarted = async () => {
        const finish = async () => {
            if (viewOnly === undefined){
                await setActionToTrueForCurrentUser('my_decks', 'introduction');
                setShowGetStarted(false);
            } else if (viewOnly === true){
                // guide 
                navigate("/my-decks");
            }
        }

        await finish();
    }
    /*  
 {views[viewIndex] === "structure" &&
                        <>
                        <div className="min-h-screen h-full overflow-y-auto pt-2 pb-32 lg:pb-0 lg:pt-0  bg-gradient-to-r from-burnt-sienna to-sandy-brown flex flex-col gap-3 justify-center">
                                
                                <div className="flex flex-col place-items-center lg:flex-row justify-center gap-2">
                                    <div className="sm:w-1/2 text-left flex flex-col gap-8 text-lg p-8 ">
                                        <div className="text-5xl font-bold ">
                                            Working with decks
                                        </div>
                                        <div className="text-sm italic">
                                            Page {viewIndex+1}/{views.length}
                                        </div>
                                        <div>
                                            There are two viewing modes for Yalango Decks: <span className="font-bold">a simple view</span> and an <span className="font-bold">organized view</span>.
                                            With the simple view, you see all your decks in a target language at the same time in a grid. We recommend starting like this in the beginning to get comfortable creating and playing with decks.
                                        </div>
                                        <div>
                                            The organized view lets you organize your decks into <span className="font-bold">sections</span> and <span className="font-bold">subsections</span>.
                                            You can think of this as folders. You can play with any section or subsection, which means practicing all the decks that are categorized inside at the same time. This comes in very handy as your collection of decks grows.
                                        </div>
                                        <div>
                                            Please note that you can <span className="font-bold">easily switch</span> between these two modes at any time.
                                        </div>
                                        <div className="flex flex-row gap-2">
                                            <div className="text-2xl ">
                                                <Button disabledDesign={viewIndex > 0 ? false : true} onClick={viewIndex > 0 && previousPage} text={<FontAwesomeIcon icon={faArrowLeft} />} color="charcoal" />
                                            </div>
                                            <div className="text-2xl ">
                                                <Button onClick={nextPage} text={<FontAwesomeIcon icon={faArrowRight} />} color="charcoal" />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <img src={YalangoThumbsUpSvg} alt="Yalango mascot" className="w-4/6 m-auto p-4 sm:w-64 mx-auto my-0" />
                                    </div>
                                </div>
                                
                            </div>
                        </>
                    }
    */

    return (
        <>
            <div className="">
                <div className="h-full  w-full  m-auto text-center text-white">
                    {views[viewIndex] === "welcome" &&
                        <>
                            <div className="min-h-screen h-full overflow-y-auto pt-2 pb-32 lg:pb-0 lg:pt-0 bg-gradient-to-r from-burnt-sienna to-sandy-brown flex flex-col gap-3 justify-center">
                                
                                <div className="flex flex-col place-items-center lg:flex-row justify-center gap-2">
                                    <div className="sm:w-1/2 text-left flex flex-col gap-8 text-lg p-8 ">
                                        <div className="text-5xl font-bold ">
                                            Yalango Decks
                                        </div>
                                        <div className="text-sm italic">
                                            Page {viewIndex+1}/{views.length}
                                        </div>
                                        <div>
                                            Unleash the power of Yalango Decks to organize words you learn across multiple languages.  
                                            A <span className="font-bold">deck</span> is a collection of words or phrases that you want to remember. Each item in a deck is called a <span className="font-bold">card</span>.
                                        </div>
                                        <div>
                                            After you've created a deck, you can practice the cards with <span className="font-bold">fun games</span> such as flashcards and quizzes.
                                        </div>
                                        <div className="flex flex-row gap-2">
                                            <div className="text-2xl ">
                                                <Button disabledDesign={viewIndex > 0 ? false : true} onClick={viewIndex > 0 && previousPage} text={<FontAwesomeIcon icon={faArrowLeft} />} color="charcoal" />
                                            </div>
                                            <div className="text-2xl ">
                                                <Button onClick={nextPage} text={<FontAwesomeIcon icon={faArrowRight} />} color="charcoal" />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <img src={YalangoReadingSvg} alt="Yalango mascot" className="w-4/6 m-auto p-4 sm:w-64 mx-auto my-0" />
                                    </div>
                                </div>
                                
                            </div>
                        </>
                    }
                   
                    {views[viewIndex] === "public" &&
                        <>
                            <div className="min-h-screen h-full overflow-y-auto pt-2 pb-32 lg:pb-0 lg:pt-0  bg-gradient-to-r from-burnt-sienna to-sandy-brown flex flex-col gap-3 justify-center">
                                
                                <div className="flex flex-col place-items-center lg:flex-row justify-center gap-2">
                                    <div className="sm:w-1/2 text-left flex flex-col gap-8 text-lg p-8 ">
                                        <div className="text-5xl font-bold ">
                                            Public decks
                                        </div>
                                        <div className="text-sm italic">
                                            Page {viewIndex+1}/{views.length}
                                        </div>
                                        <div>
                                            Although the most powerful way to use Yalango Decks is to build your own decks as your vocabulary is growing, you can also browse Yalango's selection of <span className="font-bold">public decks</span>.
                                            If you find a public deck in your target language you like, you can easily <span className="font-bold">duplicate</span> it to your own library and modify it as you want.
                                        </div>
                                        <div>
                                            All decks you create are <span className="font-bold">private by default</span>, but if you wish to share your deck with other learners, you can make it public in the deck settings.
                                        </div>
                                        <div className="flex flex-row gap-2">
                                            <div className="text-2xl ">
                                                <Button disabledDesign={viewIndex > 0 ? false : true} onClick={viewIndex > 0 && previousPage} text={<FontAwesomeIcon icon={faArrowLeft} />} color="charcoal" />
                                            </div>
                                            <div className="text-2xl ">
                                                <Button onClick={nextPage} text={<FontAwesomeIcon icon={faArrowRight} />} color="charcoal" />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <img src={YalangoReadingSvg} alt="Yalango mascot" className="w-4/6 m-auto p-4 sm:w-64 mx-auto my-0" />
                                    </div>
                                </div>
                                
                            </div>
                        </>
                    }
                    {views[viewIndex] === "statistics" &&
                        <>
                            <div className="min-h-screen h-full overflow-y-auto pt-2 pb-32 lg:pb-0 lg:pt-0  bg-gradient-to-r from-burnt-sienna to-sandy-brown flex flex-col gap-3 justify-center">
                                
                                <div className="flex flex-col place-items-center lg:flex-row justify-center gap-2">
                                    <div className="sm:w-1/2 text-left flex flex-col gap-8 text-lg p-8 ">
                                        <div className="text-5xl font-bold ">
                                            Statistics & vocabulary
                                        </div>
                                        <div className="text-sm italic">
                                            Page {viewIndex+1}/{views.length}
                                        </div>
                                        <div>
                                           Yalango Decks is an intelligent system that <span className="font-bold">tracks your progress</span> as you play with your decks.
                                           You can access these statistics at any time in your dashboard. Based on your progress, Yalango generates a <span className="font-bold">list of your vocabulary</span> for each language you're learning. 
                                        </div>
                                        <div>
                                            In your vocabulary you can easily see which words you're <span className="font-bold">struggling with</span> and which ones you <span className="font-bold">master</span>.
                                            Based on this, Yalango can generate exercises with different difficulty levels for you.
                                        </div>
                                        <div className="flex flex-row gap-2">
                                            <div className="text-2xl ">
                                                <Button disabledDesign={viewIndex > 0 ? false : true} onClick={viewIndex > 0 && previousPage} text={<FontAwesomeIcon icon={faArrowLeft} />} color="charcoal" />
                                            </div>
                                            <div className="text-2xl ">
                                                <Button onClick={nextPage} text={<FontAwesomeIcon icon={faArrowRight} />} color="charcoal" />
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div>
                                        <img src={YalangoReadingSvg} alt="Yalango mascot" className="w-4/6 m-auto p-4 sm:w-64 mx-auto my-0" />
                                    </div>
                                </div>
                                
                            </div>
                        </>
                    }
                    {views[viewIndex] === "advanced" &&
                        <>
                            <div className="min-h-screen h-full overflow-y-auto pt-2 pb-32 lg:pb-0 lg:pt-0  bg-gradient-to-r from-burnt-sienna to-sandy-brown flex flex-col gap-3 justify-center">
                                
                                <div className="flex flex-col place-items-center lg:flex-row justify-center gap-2">
                                    <div className="sm:w-1/2 text-left flex flex-col gap-8 text-lg p-8 ">
                                        <div className="text-5xl font-bold ">
                                            Packed with useful features
                                        </div>
                                        <div className="text-sm italic">
                                            Page {viewIndex+1}/{views.length}
                                        </div>
                                        <div>
                                           In addition to all this, we offer loads of useful features to make it easier to build your decks, such as:
                                        </div>
                                        <div>
                                            <ul className="list-disc ml-8">
                                                <li>
                                                    Import CSV files
                                                </li>
                                                <li>
                                                    Bulk add cards
                                                </li>
                                                <li>
                                                    Auto-translation
                                                </li>
                                                <li>
                                                    Advanced custom fields
                                                </li>
                                                <li>
                                                    Text-to-speech
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="flex flex-row gap-2">
                                            <div className="text-2xl ">
                                                <Button disabledDesign={viewIndex > 0 ? false : true} onClick={viewIndex > 0 && previousPage} text={<FontAwesomeIcon icon={faArrowLeft} />} color="charcoal" />
                                            </div>
                                            <div className="text-2xl ">
                                                <Button onClick={nextPage} text={viewOnly ? "Go to my decks" : "Get started now"} color="charcoal" />
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div>
                                        <img src={YalangoReadingSvg} alt="Yalango mascot" className="w-4/6 m-auto p-4 sm:w-64 mx-auto my-0" />
                                    </div>
                                </div>
                                
                            </div>
                        </>
                    }
                    
                    {views[viewIndex] === "languages" &&
                        <>
                            <div className="text-3xl font-bold p-3 flex flex-row gap-1 lg:gap-4 justify-center">
                                <div> 
                                    Languages
                                </div>
                            </div>
                            <div className="text-lg lg:w-96 m-auto p-8 font-bold">
                                <div>In order to use Yalango Decks, you need to have at least 1 <span className="underline">active</span> language added to your account.</div>
                                
                                {userLanguages.length > 0 ?
                                    <>
                                        <div className="my-2">
                                        {userLanguages.length === 1 ? <span>This language is </span> : <span>These languages are </span>} currently connected to your account:
                                        </div>
                                        <div className="max-h-36 my-2 overflow-y-auto">
                                            {userLanguages.map((lang, index)=>(
                                                <div>
                                                    {lang.target_language}
                                                </div>
                                            ))
                                            }
                                        </div>
                                        <div className="text-2xl ">
                                            <Button onClick={finishGetStarted} text={<div className="font-bold">Get started</div>} color="bg-persian-green" />
                                        </div>
                                    </>
                                :
                                    <>
                                        <div className="p-4">
                                            Please add a language you want to learn before accessing Yalango Decks.
                                        </div>
                                        <div className="text-2xl ">
                                            <Link to="/my-languages"><Button text={<div className="font-bold">Go to my languages</div>} color="bg-burnt-sienna" /></Link>
                                        </div>
                                    </>
                                }
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
    )
}

export default MyDecksGetStarted;
