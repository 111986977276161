import React, {useState, useEffect, useRef} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import useLanguages from '../../../hooks/useLanguages';
import useDecks from '../../../hooks/useDecks';
import useSearch from '../../../hooks/useSearch';
import useQueryStrings from '../../../hooks/useQueryStrings';
import {useLanguagesContext} from '../../../contexts/LanguagesContext';
import {faDownload} from '@fortawesome/pro-solid-svg-icons';
import '../../../css/CardGrid.css';
import Card from '../cards/Card';
import {Helmet} from 'react-helmet';
import queryString from 'query-string';
import CardItems from '../cards/CardItems';
import Icon from '../../general/Icon';
import CardMenu from '../cards/CardMenu';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEllipsisV} from '@fortawesome/pro-solid-svg-icons'; 
import DeckNotFound from '../../errors/DeckNotFound';
import {projectFirestore} from '../../../firebase/config.js';
import { faCog } from '@fortawesome/pro-solid-svg-icons';
import {faAngleRight} from '@fortawesome/pro-solid-svg-icons';
import {faFileImport} from '@fortawesome/pro-solid-svg-icons';
import {faAngleLeft} from '@fortawesome/pro-solid-svg-icons';
import {motion} from 'framer-motion';
import { useAuth } from "../../../contexts/AuthContext";
import GeneralFeedbackCircle from '../../feedback/components/GeneralFeedbackCircle';
import {DebounceInput} from 'react-debounce-input';
import {useMyContentContext} from '../../../contexts/MyContentContext';

const CardGrid = (props) => {
    let {currentUser} = useAuth();
    let {allLanguages} = useLanguagesContext();
    let {fetchAllItemsInUserDeckRealtime, 
        fetchPaginatedItemsInUserDeck,
        fetchDeckFromCurrentUserRealtime, 
        fetchDeckCustomFieldsFromCurrentUserRealtime, 
        saveChangesToDeckCard} = useDecks();

    let {getScopedTypesenseDeckCardsApiKey} = useMyContentContext();

    const [error, setError] = useState(false);
    let {addAndRemoveQueryStrings, 
        getQueryStrings} = useQueryStrings();

    const {searchCurrentUserDeckCards, 
        getScopedDeckCardsApiKey} = useSearch();

    const orderByChange = (option) => {
        setSortByValue(option.value);
        setMustRefreshCards(true);
        fetchCardsFunction(searchTerm, currentPage, option.value);
    }

    const [searchTerm, setSearchTerm] = useState("");
    const [localCardChanges, setLocalCardChanges] = useState({}); //add doc ID whenever change has been made

    let location = useLocation();
    const navigate = useNavigate();

    const searchInputChange = (e) => {
        setCurrentPage(1); // always go to beginning on search
        setSearchTerm(e.target.value.trim());
        setMustRefreshCards(true);
        fetchCardsFunction(e.target.value.trim(), currentPage, sortByValue);
    }

    const incrementNumberOfCards = (inc) => {
        setNumberOfCards(numberOfCards+inc);
    }

    const decrementNumberOfCards = (dec) => {
        if (numberOfCards > 0){
            setNumberOfCards(numberOfCards-dec);
        }
    }

    const [sortByValue, setSortByValue] = useState("last_updated_timestamp_asc");
    const sortByOptions = [
        {label: 'Target alphabetically', value:'target_alphabetically'}, 
        {label: 'Source alphabetically', value:'source_alphabetically'},  
        {label: 'Last updated (ascending)', value:'last_updated_timestamp_asc'},
        {label: 'Last updated (descending)', value:'last_updated_timestamp_desc'},  
        {label: 'Created (ascending)', value:'created_timestamp_asc'}, 
        {label: 'Created (descending)', value:'created_timestamp_desc'}    
    ];
    const [sortByOptionsDict, setSortByOptionsDict] = useState(sortByOptions.reduce(function(result, item) {
        var key = item.value; 
        result[key] = item;
        return result;
      }, {}));

    

    /* DISPLAY MODES */
    const [zoomMode, setZoomMode] = useState(false);

    /* MODAL */
    const [showModal, setShowModal] = useState(false);
    const [modalMode, setModalMode] = useState(null);

    const [selectedCard, setSelectedCard] = useState(null);
    const [showOptionsMenu, setShowOptionsMenu] = useState(false);

    /* DOCS */
    const [deletedCardDocIds, setDeletedCardDocIds] = useState([]);
    const [newCards, setNewCards] = useState([]); //good to avoid problems with synch of typesense (latency)
    const [docs, setDocs] = useState(null);
    const [deck, setDeck] = useState(null);
    const [customFields, setCustomFields] = useState(null);
    const [lastVisibleItem, setLastVisibleItem] = useState(null);
    const [firstVisibleItem, setFirstVisibleItem] = useState(null);
/*     const [pageSize, setPageSize] = useState(25);
    const [originalPageSize, setOriginalPageSize] = useState(25); */
    const [deckItemsLoading, setDeckItemsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(null);

    const [numberOfCards, setNumberOfCards] = useState(null);
    const [numberOfResults, setNumberOfResults] = useState(null);
    const [numberOfCardsPerPage, setNumberOfCardsPerPage] = useState(50);
    const [numberOfPages, setNumberOfPages] = useState(null);

    /* CARDS */
    const [editCardId, setEditCardId] = useState(0);
    const [targetInput, setTargetInput] = useState("");
    const [sourceInput, setSourceInput] = useState("");
    const [editCardCustomFields, setEditCardCustomFields] = useState({});

    /* Languages */
    const [sourceLanguage, setSourceLanguage] = useState(null); //target will never change

    /* EDIT AUTHORIZATION */
    const [editAuthorization, setEditAuthorization] = useState(false); // defaults to false

    /* LOADING */
    const [loading, setLoading] = useState(false);
    const [cardsLoading, setCardsLoading] = useState(true);

    const [formatMode, setFormatMode] = useState("typesense");
    const [queryStringsRead, setQueryStringsRead] = useState(false);
    const [searchDocId, setSearchDocId] = useState(null);

    const addDocIdToDeletedCardsList = (docId) => {
        let list = [...deletedCardDocIds, docId];
        list = [...new Set(list)];
        setDeletedCardDocIds(list);
    }

    const addNewCardToList = (card) => {
        let list = [card, ...newCards];
        setNewCards(list);
        changeWasFromAddingCardRef.current = false;
    }

    const updateLocalChangesVariable = ({cardDocId, targetWord, sourceWord}) => {
        let copy = JSON.parse(JSON.stringify(localCardChanges));
        if (!copy.hasOwnProperty(cardDocId)){
            copy[cardDocId] = {};
        }
        if (targetWord !== undefined && targetWord !== null){
            copy[cardDocId]['target'] = targetWord;
        }
        if (sourceWord !== undefined && sourceWord !== null){
            copy[cardDocId]['source'] = sourceWord;
        }
        console.log("local: ", copy);
        setLocalCardChanges(copy);

        let newDocs = [];
        let counter = 0;
        for (const doc of docs){
            console.log(doc);
            let dataToAdd = JSON.parse(JSON.stringify(doc));
            if (copy.hasOwnProperty(doc.id)){
                let localChange = copy[doc.id];
                if (localChange.hasOwnProperty("target")){
                    dataToAdd['target'] = localChange.target;
                }
                if (localChange.hasOwnProperty("source")){
                    dataToAdd['source'] = localChange.source;
                }
            }
            newDocs.push(dataToAdd);
            counter++;
        }
        setDocs(newDocs);
    }

    const targetWordOnChange = async (e, cardDocId) => {
        console.log(e.target.value, cardDocId);
        if (cardDocId === undefined){return}
        let newValue = e.target.value.trim();
        let success = await saveChangesToDeckCard({targetWord: newValue, cardDocId: cardDocId});
        console.log("Success: ", success);
        if (success){
            updateLocalChangesVariable({cardDocId: cardDocId, targetWord: newValue})
        }
    }

    const sourceWordOnChange = async (e, cardDocId) => {
        console.log(e.target.value, cardDocId);
        if (cardDocId === undefined){return}
        let newValue = e.target.value.trim();
        let success = await saveChangesToDeckCard({sourceWord: newValue, cardDocId: cardDocId});
        console.log("Success: ", success);
        if (success){
            updateLocalChangesVariable({cardDocId: cardDocId, sourceWord: newValue})
        }
    }
    
    useEffect(()=>{
        if (docs !== null && docs.length > 0 && customFields !== null && customFields !== undefined){
            for (const doc of docs){
                if (doc.id === editCardId){
                    setTargetInput(doc.target);
                    setSourceInput(doc.source);
                    if (formatMode === "typesense"){
                        let obj = {};
                        customFields.forEach(field=>{
                            if (doc.hasOwnProperty("custom_fields."+field.data.id)){
                                obj[field.data.id] = doc["custom_fields."+field.data.id];
                            } else {
                                obj[field.data.id] = "";
                            }
                        });
                        setEditCardCustomFields(obj);
                    } else {
                        if ('custom_fields' in doc){
                            setEditCardCustomFields(doc['custom_fields']);
                        }
                    }
                }
            }
        }
    }, [docs, editCardId, formatMode, customFields]);

    useEffect(()=>{
        if (numberOfResults !== null && numberOfCardsPerPage !== null){
            setNumberOfPages(Math.ceil(numberOfResults/numberOfCardsPerPage));
        }
    },[numberOfResults, numberOfCardsPerPage]);

    /* Fetch data once in beginning */
    useEffect(() => {
        const fetchData = async () => {
            await fetchAllItems();
        }
        if (props.deck_id !== null && props.deck_id !== undefined){
            fetchData();
        }
    }, [props.deck_id]);

    useEffect(()=>{
        if (deck !== null){
            if ('number_of_items' in deck){
                setNumberOfCards(parseInt(deck['number_of_items']));
            }
           
        }
    },[deck]);

    // CHECK PRIVACY AFTER DECK IS FETCHED
    useEffect(() => {
        if (currentUser !== null && deck !== null){
            if (currentUser.uid === deck.uid ){
                setEditAuthorization(true);
                //console.log("ye");
            }
        }

    }, [deck, currentUser])

    const fetchAllItems = async () => {
        await fetchDeckFromCurrentUserRealtime(props.deck_id, setDeck, setError);
    }

/*     const loadMoreClick = () => {
        if (pageSize < deck.number_of_items){
            setPageSize(pageSize+25);
            setCurrentPage(currentPage+1);
        }
    } */

    useEffect(()=>{
        if (numberOfResults !== null && numberOfCardsPerPage !== null){
            setNumberOfPages(Math.ceil(numberOfResults/numberOfCardsPerPage));
        }
    },[numberOfResults, numberOfCardsPerPage]);


    const [isAddingItemsExternally, setIsAddingItemsExternally] = useState(null);
    const [isAddingItemsExternallyFinished, setIsAddingItemsExternallyFinished] = useState(null);
    const firstTimeCustomFieldsRef = useRef(true);
    const changeWasFromAddingCardRef = useRef(false);
    const [mustRefreshCards, setMustRefreshCards] = useState(false);

    const [initialCardsFetched, setInitialCardsFetched] = useState(false);



    useEffect(()=>{
        let unsub = null;

        const unsubFunction = () => {
            console.log("unsubbing custom fields");
            unsub !== null && unsub();
        }

        const fetchData = async () => {
            firstTimeCustomFieldsRef.current = false;
            unsub = await fetchDeckCustomFieldsFromCurrentUserRealtime(deck.doc_id, setCustomFields);
        }

        if (deck !== null && changeWasFromAddingCardRef.current === false){
            if (deck.hasOwnProperty('temp_adding_items')){
                if (deck['temp_adding_items'] === true){
                    setIsAddingItemsExternally(true);
                    setCardsLoading(true);
                } else {
                    if (isAddingItemsExternally === true){
                        setIsAddingItemsExternallyFinished(true);
                    }
                    setIsAddingItemsExternally(false);
                    if (isAddingItemsExternally !== true){
                        setCardsLoading(false); //
                        setMustRefreshCards(true);
                    }
                }
            } else {
                setIsAddingItemsExternally(false);
                setMustRefreshCards(true);
            }
        }
        if (deck !== null && firstTimeCustomFieldsRef.current === true){
            fetchData();
        }
        return () => {
            unsubFunction();
        }
    },[deck,changeWasFromAddingCardRef]);


    useEffect(()=>{
        if (isAddingItemsExternallyFinished === true){
            // finished multi import
            console.log("Finished import: ", isAddingItemsExternallyFinished, currentPage, searchTerm, sortByValue);
            if (currentPage !== null && searchTerm !== null && sortByValue !== null){
                fetchCardsFunction(searchTerm, currentPage, sortByValue);
            }
        }
    },[isAddingItemsExternallyFinished, currentPage, searchTerm, sortByValue])

    useEffect(()=>{
        const getQueryStringsHelper = async () => {
            const parsed = await getQueryStrings();
            let p = null;
            let q = null;
            let s =  null;
            if ('page' in parsed){
                console.log(parsed['page']);
                try {
                    setCurrentPage(parseInt(parsed['page']));
                    p = parseInt(parsed['page']);
                }
                catch {
                    setCurrentPage(1);
                    p = 1; 
                }
            } else {
                console.log("no page")
                setCurrentPage(1);
                p = 1;
            }

            if ('q' in parsed){
                setSearchTerm(parsed['q']); 
                q = parsed['q'];
            } else {
                q = "";
            }

            if ('sort' in parsed && sortByOptionsDict.hasOwnProperty(parsed['sort'])){
                setSortByValue(parsed['sort']);
                s = parsed['sort'];
            } else {
                setSortByValue('last_updated_timestamp_desc');
                s = 'last_updated_timestamp_desc';
            }

            if ('id' in parsed){
                setSearchDocId(parsed['id']);
            }

            setQueryStringsRead(true);
            console.log(p, q, s);
            if (isAddingItemsExternally === false && numberOfCards > 0){
                console.log("SHOULD HAPPEN ONCE: ", p, q, s);
                await fetchCardsFunction(q, p, s);
            } else if (numberOfCards === 0){
                setDocs([]); 
                setNumberOfResults(0);
                setMustRefreshCards(false);
                setCardsLoading(false);
            }
        }

        if (!queryStringsRead && deck !== null && isAddingItemsExternally !== null && sortByOptionsDict !== null && props.deck_id !== null && numberOfCards !== null && numberOfCardsPerPage !== null){
            // intial load
            getQueryStringsHelper();
        }
    }, [queryStringsRead, deck, isAddingItemsExternally, sortByOptionsDict, props.deck_id, numberOfCardsPerPage, numberOfCards]);

    useEffect(()=>{
        //useQueryStrings
        if (currentPage !== null){
            let add = [{'name': 'page', 'value': currentPage}];
            let remove = [];
            if (searchTerm !== ""){
                add.push({'name': 'q', 'value': searchTerm});
            } else {
                remove.push('q');
            }
            if (sortByValue !== null){
                add.push({'name': 'sort', 'value': sortByValue});
            } else {
                remove.push('sort');
            }
            addAndRemoveQueryStrings(add, remove);
        }
    }, [currentPage, searchTerm, sortByValue]);
/* 
    useEffect(()=>{
        const fetchData = async () => {
            await fetchCardsFunction();
        }
        //console.log("Change: ", numberOfCardsPerPage, isAddingItemsExternally, isAddingItemsExternallyFinished, searchDocId, sortByValue, props.deck_id, searchTerm, currentPage, queryStringsRead)
        if (mustRefreshCards === true){
            if (numberOfCardsPerPage !== null  && isAddingItemsExternally === false && isAddingItemsExternallyFinished !== false && props.deck_id !== null && sortByValue !== null && queryStringsRead){
                // search or change after initial load
                //fetchData(); 
            }
        }

        // don't include deck in dependencies!! Will be updated for example when adding new card
    },[numberOfCardsPerPage, mustRefreshCards, isAddingItemsExternally, isAddingItemsExternallyFinished, searchDocId, sortByValue, props.deck_id, searchTerm, currentPage, queryStringsRead]);
 */
    const fetchCardsFunction = async (s, p, sort) => {
        if (isAddingItemsExternally === true){return null}
        console.log("Search: ", s, ", Page:  ", p, " , Sort: ", sort);
        console.log("FETCHING fetchCardsFunction().", Math.random(0,1));
        setCardsLoading(true);
        let results = null;
        //
        let apiKey = await getScopedTypesenseDeckCardsApiKey();
        results = await searchCurrentUserDeckCards(s, props.deck_id, apiKey.key, p, numberOfCardsPerPage, sort, customFields, searchDocId);
        
        if (results !== undefined && results !== null){
            let l = []; //ensure no cards are ignored due to latency problems with typesense
            console.log("Deleted: ", deletedCardDocIds);
            results['hits'].forEach((result)=>{
                if (s === ""){
                    if (!deletedCardDocIds.includes(result.document['id'])){
                        console.log("Search: ", s);
                        let alreadyAdded = false;
                        for (const nCard of newCards){
                            if (nCard.id === result.document['id']){
                                alreadyAdded = true;
                            }
                        }
                        if (!alreadyAdded){
                            // check if local changes
                            console.log("typesense card: ", result.document)
                            let dataToAdd = JSON.parse(JSON.stringify(result.document));

                            if (localCardChanges.hasOwnProperty(result.document['id'])){
                                let localChange = localCardChanges[result.document['id']];
                                if (localChange.hasOwnProperty("target")){
                                    dataToAdd['target'] = localChange.target;
                                }
                                if (localChange.hasOwnProperty("source")){
                                    dataToAdd['source'] = localChange.source;
                                }
                            }
                            l.push({...dataToAdd});
                        }
                    } 
                }  
                else {
                    if (!deletedCardDocIds.includes(result.document['id'])){
                        l.push({...result.document});
                    }
                }
            })
            setDocs([...l, ...newCards]); //results['hits'].map((hit)=>{return {...hit.document}})
            setNumberOfResults(results['found']);
        }
        setMustRefreshCards(false);
        setCardsLoading(false);
    }

    const openShowOptionsMenu = (e) => {
        e.stopPropagation();
        setShowOptionsMenu(!showOptionsMenu);
      }

      const closeShowOptionsMenu = () => {
        setShowOptionsMenu(false);
      }

      const generalBackgroundClick = () => {
        closeShowOptionsMenu();
    }

    const changePageNumber = (number) => {
        setCurrentPage(number);
        setMustRefreshCards(true);
        fetchCardsFunction(searchTerm, number, sortByValue);
    }
/* 
    const fetchCards = async (type) => {
        let r = null;
        if (type === "next"){
            r = await fetchPaginatedItemsInUserDeck(deck.doc_id, props.deck_id, numberOfCardsPerPage, lastVisibleItem, type, sortByValue, searchTerm);
        } else if (type === "previous"){
            r = await fetchPaginatedItemsInUserDeck(deck.doc_id, props.deck_id, numberOfCardsPerPage, firstVisibleItem, type, sortByValue, searchTerm);
        } else {
            r = await fetchPaginatedItemsInUserDeck(deck.doc_id, props.deck_id, numberOfCardsPerPage, lastVisibleItem, type, sortByValue, searchTerm);
        }
        if (r !== null && r !== undefined){
            if ('documents' in r){
                setDocs(r['documents']);
            }
            if ('firstVisible' in r){
                setFirstVisibleItem(r['firstVisible']);
            }
            if ('lastVisible' in r){
                setLastVisibleItem(r['lastVisible']);
            }
        } 
    } */

    /*
        {docs.length < deck.number_of_items &&
        <>
            <div className="text-black font-bold flex gap-10 cursor-pointer flex-row justify-center">
                <div className="bg-persian-green text-white p-3 rounded-lg shadow-2xl " onClick={loadMoreClick}>
                    Load more
                </div>
            </div>
        </>
    }
    */

    const refreshCards = async () => {
        // not a good way because latency between firebase and typesense makes it difficult to time
        //await fetchCardsFunction();
    }

    return (
        <>
            {error && 
                <>
                    <DeckNotFound />
                </>
            }
            {(deck !== null && !error) &&
                <>
                    <Helmet>
                        <title>{deck.name} - Yalango</title>
                        <meta name="description" content={"Study the deck " + deck.name + " on Yalango with interactive games."} />
                    </Helmet>
                </>
            }
            {!error &&
                <>
                    <div onClick={generalBackgroundClick}>
                        <div onClick={(e)=>openShowOptionsMenu(e)} className="absolute sm:right-4 sm:top-4 right-2 top-2 p-2 cursor-pointer text-xl">
                            <Icon color="charcoal" icon={<FontAwesomeIcon icon={faEllipsisV} />} /> 
                        </div>
                    {showOptionsMenu && 
                        <>
                            <div className="absolute sm:right-8 sm:top-10 right-6 top-6 overflow-visible text-base  z-10 rounded-lg shadow-xl bg-white ">
                                <div className="flex text-sm whitespace-nowrap font-bold text-gray-700 flex-col justify-start place-items-start">
                                     <Link to={'/my-decks/'+props.deck_id+'/settings'} className="no-underline w-full" >
                                        <div className="flex flex-row gap-3 hover:underline w-full rounded-lg  p-3">
                                            <div>
                                                <Icon color="charcoal" icon={<FontAwesomeIcon icon={faCog} />} />
                                            </div>
                                            <div>
                                                Deck settings
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to={'/my-decks/'+props.deck_id+'/import'} className="no-underline w-full" >
                                        <div className="flex flex-row gap-3 hover:underline w-full rounded-lg  p-3">
                                            <div>
                                                <Icon color="charcoal" icon={<FontAwesomeIcon icon={faFileImport} />} />
                                            </div>
                                            <div>
                                                Bulk create & import
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to={'/my-decks/'+props.deck_id+'/export'} className="no-underline w-full" >
                                        <div className="flex flex-row gap-3 hover:underline w-full rounded-lg  p-3">
                                            <div>
                                                <Icon color="charcoal" icon={<FontAwesomeIcon icon={faDownload} />} />
                                            </div>
                                            <div>
                                                Export data
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </>
                    }
                        
                        <div className="flex flex-col gap-2">
                        <CardMenu changeWasFromAddingCardRef={changeWasFromAddingCardRef} addNewCardToList={addNewCardToList} incrementNumberOfCards={incrementNumberOfCards} refreshCards={refreshCards} setCardsLoading={setCardsLoading} cardsLoading={cardsLoading} setEditCardCustomFields={setEditCardCustomFields} editCardCustomFields={editCardCustomFields} customFields={customFields} sourceLanguage={sourceLanguage} setSourceLanguage={setSourceLanguage} editAuthorization={editAuthorization} editCardId={editCardId} setEditCardId={setEditCardId} targetInput={targetInput} setTargetInput={setTargetInput} sourceInput={sourceInput} setSourceInput={setSourceInput} zoomMode={zoomMode} setZoomMode={setZoomMode} modalMode={modalMode} showModal={showModal} setShowModal={setShowModal} docs={docs} setDocs={setDocs} setModalMode={setModalMode} fetchAllItems={fetchAllItems} deck={deck} deck_id={props.deck_id} allLanguages={allLanguages} />
                        
                        <CardItems targetWordOnChange={targetWordOnChange} sourceWordOnChange={sourceWordOnChange} localCardChanges={localCardChanges} setLocalCardChanges={setLocalCardChanges} addDocIdToDeletedCardsList={addDocIdToDeletedCardsList} decrementNumberOfCards={decrementNumberOfCards} numberOfCards={numberOfCards} numberOfCardsPerPage={numberOfCardsPerPage} cardsLoading={cardsLoading} apiType={"typesense"}  numberOfPages={numberOfPages} searchInputChange={searchInputChange} searchTerm={searchTerm} sortByOptionsDict={sortByOptionsDict} sortByValue={sortByValue} sortByOptions={sortByOptions} orderByChange={orderByChange} setDocs={setDocs} customFields={customFields} currentPage={currentPage} deck={deck} allLanguages={allLanguages} sourceLanguage={sourceLanguage} setSourceLanguage={setSourceLanguage} editAuthorization={editAuthorization} editCardId={editCardId} setEditCardId={setEditCardId} zoomMode={zoomMode} docs={docs} modalMode={modalMode} setModalMode={setModalMode} Card={Card} setShowModal={setShowModal} />
                        
                        {(numberOfPages !== null && numberOfPages > 1) && 
                            <>
                                <div className="grid grid-cols-5 my-2 sm:grid-cols-7 md:grid-cols-12 lg:grid-cols-18 w-5/6 m-auto justify-center place-items-center gap-2">
                                    {[...Array(numberOfPages)].map((val, index)=>(
                                        <>
                                            
                                            <motion.div onClick={()=>changePageNumber(index+1)} whileHover={{scale:1.03}} key={"page_"+index} className={(currentPage === (index+1) ? 'bg-charcoal text-white ': 'bg-white ' ) + " cursor-pointer text-center p-2 px-4 rounded-lg shadow-xl"}>
                                                {index+1}
                                            </motion.div>
                                            
                                        </> 
                                    ))}
                                </div>
                            </>
                        }
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default CardGrid;