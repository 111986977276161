import React, {useState} from 'react';
import MyDecksGetStarted from './../../decks/mydecks/MyDecksGetStarted';

const DecksGuide = () => {
    const [showGetStarted, setShowGetStarted] = useState(true);
  return (
    <>
        <div className="min-h-screen h-full overflow-y-auto">
            <MyDecksGetStarted setShowGetStarted={setShowGetStarted} viewOnly={true} />
        </div>
    </>
  )
}

export default DecksGuide