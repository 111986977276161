import React, {useState} from 'react'
import DeckBlock from './DeckBlock';
import TableBlock from './TableBlock';
import QuillEditor from '../editor/QuillEditor';

const Block = (props) => {

    const {blockType, blockName, blockIndex, blockParams} = props;

    const [blocks, setBlocks] = useState({
        deck: <DeckBlock blockIndex={blockIndex} {...blockParams}   />,
        table: <TableBlock blockIndex={blockIndex} {...blockParams} />, 
        html: <QuillEditor blockIndex={blockIndex} {...blockParams} />
    });

    return (
        <>
            <div className="bg-white rounded-xl p-4 shadow-xl flex flex-col gap-4">
                <div className="text-sm muted-text font-semibold">
                    {blockIndex + 1}. {blockName}
                </div>
                <div>
                    {blocks[blockType]}
                </div>
            </div>
        </>
    )
};

export default Block;