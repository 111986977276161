import React, {useState, useEffect} from 'react';
import {faEllipsisV} from '@fortawesome/pro-solid-svg-icons';
import Icon from '../../components/general/Icon';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';
import {faPencil} from '@fortawesome/pro-duotone-svg-icons';
import {faCalendarCheck} from '@fortawesome/pro-duotone-svg-icons';
import useGoals from '../../hooks/useGoals';
import {useUserStatisticsContext} from '../../contexts/UserStatisticsContext'; 
import {useLanguagesContext} from '../../contexts/LanguagesContext';
import CountryFlag from '../../components/languages/CountryFlag';
import {faPlay, faFire} from '@fortawesome/pro-duotone-svg-icons';
import {faRotateRight} from '@fortawesome/pro-solid-svg-icons';
import {faArrowDownWideShort} from '@fortawesome/pro-solid-svg-icons';
import Skeleton from 'react-loading-skeleton';
import ReactTooltip from 'react-tooltip';
import Button from '../../components/general/Button';
import VocabularyCard from '../../components/vocabulary/VocabularyCard';
import GoalsProgressBar from './GoalsProgressBar';
const GoalsCard = (props) => {

    const {language} = props;
    let { globalSelectedTargetLanguage, activeUserLanguages, allLanguages, setGlobalSelectedTargetLanguage } = useLanguagesContext();
    const [selectedLanguageDbData, setSelectedLanguageDbData] = useState(null);
    
    const { getDailyStatsInActiveTargetLanguages,
        getDailyStatsInLanguage,  
        getDailyGoalsInLanguage, 
        refreshUserGoalsFunction,
        refreshUserGoalsIfNeeded,
        refreshDailyStreakIfNeeded, 
        refreshDailyStatsIfNeeded,
        dailyGoals,
        dailyStats,
        currentDailyStreak, 
        refreshDailyStreaks,
        refreshDailyStatsFunction} = useUserStatisticsContext();

    useEffect(()=>{
        if (language !== null){
            if (language !== "all" && allLanguages !== null){
                setSelectedLanguageDbData({[`${language}`]: allLanguages[0][language]});
            } 
            else if (language == "all"){
                if (activeUserLanguages !== null && allLanguages !== null) {
                    // create object with all languages
                    let obj = {};
                    for (const lang of activeUserLanguages){
                        obj[lang.target_language] = allLanguages[0][lang.target_language];
                    }
                    console.log(obj);
                    setSelectedLanguageDbData(obj);
                }
            }
        }
    },[language, allLanguages, activeUserLanguages]);
    
    const [allDataArray, setAllDataArray] = useState([]);
    const [dailyProgressWidth, setDailyProgressWidth] = useState(0);
    const [verifiedStreak, setVerifiedStreak] = useState(false);
    const [filteredDailyStreak, setFilteredDailyStreak] = useState(null);
    const [dailyStatsLoading, setDailyStatsLoading] = useState(false);
    const [totalCorrectItems, setTotalCorrectItems] = useState(null);
    const [totalCorrectItemsPerDayGoalToday, setTotalCorrectItemsPerDayGoalToday] = useState(null);
    const [waitToConstructArray, setWaitToConstructArray] = useState(true);


    const [allDataLoading, setAllDataLoading] = useState(true);

    const refreshCardClick = async () => {
        resetValues();
        await refreshDailyStatsFunction();
        await refreshUserGoalsFunction();
        await refreshDailyStreaks();
    }

    let today = new Date();
    const [todayNumber, setTodayNumber] = useState(today.getDay() === 0 ? 6 : today.getDay() - 1);

    const [showGoalsMenu, setShowGoalsMenu] = useState(false);
    const openShowGoalsMenu = (e) => {
        e.stopPropagation();
        setShowGoalsMenu(true);
    }

    const closeShowGoalsMenu = () => {
        setShowGoalsMenu(false);
    }

    const generalBackgroundClick = () => {
        closeShowGoalsMenu();
    }

    const {getCurrentUserGoals, 
            getCurrentUserDailyStreak, 
            validateStreak,
            refreshCurrentStreakAPI, 
            getCurrentUserDailyStreakInTargetLanguage} = useGoals();


    useEffect(()=>{
        const doEverything = async () => {
            let {g, s, ds} = await refreshDataIfNeeded();
            if (g !== dailyGoals || s !== currentDailyStreak || ds !== dailyStats){
                setAllDataLoading(true);
            }
            if (s !== undefined && s !== null && s !== false){
                s = await verifyStreak(s);
            }
            let w = await calculateProgressWidths(g, ds);
            await addAllFinalData(g, s, ds, w);
        }
        const refreshDataIfNeeded = async () => {
            setWaitToConstructArray(true);
            let g = await refreshUserGoalsIfNeeded();
            let s = await refreshDailyStreakIfNeeded();
            console.log("Streak: ", s);
            let ds = await refreshDailyStatsIfNeeded();
           
            if (g !== false && g !== null && g !== undefined){
                //setLocalDailyGoals(g);
            } else {
                g = dailyGoals;
            }
            if (s !== false && s !== null && s !== undefined){
                //setLocalDailyStreak(s);
            } else {
                s = currentDailyStreak;
            }
            if (ds !== false && ds !== null && ds !== undefined){
                //setLocalDailyStats(ds);
            } else {
                ds = dailyStats;
            }
            console.log("Updated values: ", g, s, ds);
            setWaitToConstructArray(false);
            return {g, s, ds}
        }

        const verifyStreak = async (streakToBeVerified) => {
            const fetchData = async (lang) => {
                let {streakOK, streak} = await validateStreak(lang === "all" ? streakToBeVerified : {[`${lang}`]: streakToBeVerified[lang]});
                console.log(streakToBeVerified, streak, streakOK);    
                let newDailyStreak = JSON.parse(JSON.stringify(streakToBeVerified));
                for (const lang of Object.keys(streakOK)){
                    if (!streakOK[lang]){
                        //refreshCurrentStreakAPI(lang);
                        newDailyStreak[lang]['current_streak'] = 'current_streak' in streak[lang] ? streak[lang]['current_streak'] :  0;
                        newDailyStreak[lang]['current_streak_dates'] = 'current_streak_dates' in streak[lang] ? streak[lang]['current_streak_dates'] : [];
                    }
                }   
                return newDailyStreak;
            }
    
            const fetchAllData = async (lang) => {
                let newStreak = await fetchData(lang);
                setFilteredDailyStreak(newStreak);
                setVerifiedStreak(true);
                return newStreak;
            }

            let newStreak = await fetchAllData(language);
            return newStreak;
        }

        const calculateProgressWidths = async (g, ds) => {
            let langs_list = [];
            if (language !== "all"){
                langs_list.push(language);
            } else if (language === "all" && activeUserLanguages !== null){
                for (const l of activeUserLanguages){
                    langs_list.push(l.target_language);
                }
            }
            let w = {};
            for (const lang of langs_list){
                let widthHasBeenSet = false;
                console.log("Goals & stats: ", g, ds);
                if (ds !== null && ds !== false && ds.hasOwnProperty(lang) && ds[lang].hasOwnProperty('total_correct_items') && g.hasOwnProperty(lang) && g[lang].hasOwnProperty('total_correct_items_per_day')){
                    console.log("Setting width", ds);
                    // globalSelectedTargetLanguage in dailyStats && 'total_correct_items_per_day_goal_today' in dailyStats[globalSelectedTargetLanguage] && 'total_correct_items' in dailyStats[globalSelectedTargetLanguage}
                    w[lang] = 0;
                    let up = ds[lang]['total_correct_items'];
                    let down = g[lang]['total_correct_items_per_day'][todayNumber];
                    let ratio = 0;
                    console.log(lang, down);
                    if (down === 0){
                        w[lang] = 100;
                    } else {
                        ratio = up/down;
                    }
                    if (ratio >= 1){
                        w[lang] = 100;
                    } else if (ratio > 0){
                        let percentage = Math.round(ratio*100);
                        w[lang] = percentage;
                    }
                    console.log("Width: ", w);
                    widthHasBeenSet = true;
                   // w[lang] = w;
                    //setDailyProgressWidth(w); 
                    //setDailyStatsLoading(false);
                    }
                else if (ds === false){
                    w[lang] = 100;
                    //setDailyProgressWidth(0);
                    //setDailyStatsLoading(false);
                } else {
                    console.log(lang, " else")
                    if (g.hasOwnProperty(lang) && g[lang].hasOwnProperty('total_correct_items_per_day') && g[lang]['total_correct_items_per_day'][todayNumber] === 0){
                        w[lang] = 100;
                    } else {
                        w[lang] = 0;
                    }
                }
            }
            setDailyProgressWidth(w);
            setDailyStatsLoading(false);
            return w;
        }

        const addAllFinalData = async (g, s, ds, w) => {
            console.log(ds, s, w);
            let array = [];
            g !== null && g !== undefined && Object.keys(g).forEach((lang)=>{
                let obj = {};
                obj['target_language'] = lang;
                obj['goals'] = g[lang];
                obj['stats'] = (ds.hasOwnProperty(lang) && ds[lang] !== false && ds[lang].hasOwnProperty("total_correct_items")) ? ds[lang] : {'total_correct_items': 0};
                obj['width'] = w.hasOwnProperty(lang) ? w[lang] : false;
                obj['daily_streak'] = (s !== false && s.hasOwnProperty(lang) && s[lang].hasOwnProperty("current_streak")) ? s[lang] : {'current_streak': 0};
                obj['language_db_data'] = selectedLanguageDbData.hasOwnProperty(lang) ? selectedLanguageDbData[lang] : false;
                console.log(lang, obj);
                if (obj['goals'] !== false && obj['stats'] !== false && obj['width'] !== false && obj['language_db_data'] !== false && obj['daily_streak'] !== false){
                    try {
                        if (obj['goals']['total_correct_items_per_day'][todayNumber] > 0){
                            // show the goals in the dashboard
                            array.push(obj);
                        }
                        else {
                            array.push(obj);
                        }
                    }
                    catch {
                        // do nothing
                    }
                }
            });
            console.log("Array: ", array);
            setAllDataArray(array);
            setAllDataLoading(false);
            ReactTooltip.rebuild();
        }

        if (language !== null && activeUserLanguages !== null && selectedLanguageDbData !== null && todayNumber !== null && dailyGoals !== null && dailyStats !== null && currentDailyStreak !== null){
            doEverything();
        }
    },[language, activeUserLanguages, selectedLanguageDbData, todayNumber, dailyGoals, dailyStats, currentDailyStreak]);

    const resetValues = () => {
        setAllDataArray([]);
        setAllDataLoading(true);
        setDailyProgressWidth(null);
        setTotalCorrectItems(null);
        setTotalCorrectItemsPerDayGoalToday(null);
        setVerifiedStreak(false);
        setDailyStatsLoading(true);
    }

    const goalClick = (lang) => {
        setGlobalSelectedTargetLanguage(lang["ISO_639-1"]);
    }

    const onBackgroundClick = () => {
        setShowGoalsMenu(false);
    }
    //                   <h3>My progress today</h3>
    return (
    <>
        <div className="w-full">
            <ReactTooltip place="bottom" id="tooltip-goals" effect="solid" />
            <div className=" flex flex-col gap-0  relative">
                <div className="flex hidden flex-row justify-end">
 
                    <div onClick={(e)=>openShowGoalsMenu(e)} className="text-right text-charcoal cursor-pointer text-base">
                        <FontAwesomeIcon icon={faEllipsisV} /> 
                    </div>
                </div>
                {showGoalsMenu && 
                        <>
                            <div className="absolute overflow-visible text-base w-36 z-20 rounded-lg shadow-xl bg-white right-1 top-6">
                                <div className="flex font-bold text-gray-700 flex-col justify-start place-items-start">
                                    <Link to={'/my-goals/daily'} className="no-underline w-full" >
                                        <div className="flex flex-row gap-3 hover:underline w-full rounded-lg  p-3">
                                            <div>
                                                <Icon color="blue" icon={<FontAwesomeIcon icon={faPencil} />} />
                                            </div>
                                            <div>
                                                Edit goals
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div onClick={onBackgroundClick} className="w-screen h-screen fixed z-10 left-0 top-0">

                            </div>
                        </>
                    }
                
                <div className="flex flex-col gap-4">
    
                    <div className="flex flex-col gap-6 pb-3">
                        {allDataArray.map((allData, langIndex)=>(
                            <>
                                <div className="flex flex-col gap-2" key={"row_"+langIndex}>
                                    <div className="flex flex-row gap-6 place-items-center justify-between md:gap-4">
                                        <div className="flex flex-row gap-2 place-items-center">
                                            <div className="rounded-lg shadow-lg overflow-hidden">
                                                {allData["language_db_data"] !== false &&
                                                    <CountryFlag size="1em" countryCode={allData["language_db_data"]['country_flag']} flagName={allData["language_db_data"]["name"]["en"]} />
                                                }
                                            </div>
                                            <div className="muted-text font-bold">
                                                <Link onClick={()=>goalClick(allData["language_db_data"])} className="no-underline border-b-2 border-charcoal border-dotted" to={'/my-goals/daily'}>
                                                    {allData["language_db_data"]["name"]["en"]}
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="flex flex-row gap-4 place-items-center">
                                            <div className="hidden">
                                                <Link className="no-underline" to="/my-calendar">
                                                    <div data-for="tooltip-goals" data-tip={"Current daily streak"} className="flex flex-row gap-1 text-sm md:text-base md:gap-2">
                                                        <div className={allData["daily_streak"]['current_streak'] === 0 ? " text-charcoal": ' text-burnt-sienna '}>
                                                            <FontAwesomeIcon icon={faFire} />
                                                        </div>
                                                        <div className=" text-gray-600 font-bold">
                                                            {allData["daily_streak"]['current_streak']}
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div>
                                                <div  data-for="tooltip-goals" data-tip={"Your vocabulary in " +  allData["language_db_data"]["name"]["en"]} >
                                                    <VocabularyCard language={allData['target_language']} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div  className="flex flex-row justify-evenly place-items-center md:gap-4 gap-2">
                                       
                                        <div className="w-full">
                                            <GoalsProgressBar allData={allData} text={"Today"} todayNumber={todayNumber} tooltipData={"Your " +allData["language_db_data"]["name"]["en"]+ " progress today"} />  
                                        </div>
                                    
                                       
                                        <div className="hidden sm:block">
                                            
                                        </div>
                                        <div>
                                            <Link  data-for="tooltip-goals" data-tip={"Practice your vocabulary"} className="flex flex-row text-gray-600 font-bold gap-2 text-sm no-underline place-items-center" to={"/my-vocabulary/"+allData.target_language+"/mix?level=spaced-repetition&items=10&mode=mix"}>
                                                <Button size="small" text={"Play"} hideTextOnSmallScreen={true} color="green" icon={faPlay} />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ))}
                        {allDataLoading &&
                            <>
                                <Skeleton height={100} />
                            </>
                        }
                        {allDataArray.length === 0 && allDataLoading === false &&
                            <>
                                <div className="flex flex-col place-items-center gap-2">
                                    <div className="text-sm italic">
                                        No goals added yet. Get started by setting a daily goal.
                                    </div>
                                    <div>
                                        <Link className="no-underline" to={"/my-goals/daily"}>
                                            <Button text="Set daily goal" color="green" />
                                        </Link>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default GoalsCard;