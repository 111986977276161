import React, {useState} from 'react';
import YalangoHead from '../../assets/svg/yalango/YalangoHead.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faHouse, faChevronDown, faInbox, faPiano,  faMusic, faSquareInfo, faCircleDot, faX } from "@fortawesome/pro-solid-svg-icons";
import {Link} from 'react-router-dom'
import Button from '../../components/general/Button';

const ComputerMenu = (props) => {
    const {items} = props;
    return (
        <>
            <div className="flex flex-row place-items-center gap-36 justify-start p-8">
            <div>
                <Link className="no-underline" to={"/"}>
                <img className="w-12" src={YalangoHead} aria-label="Yalango logo" />
                </Link>
            </div>
            <div className="flex font-semibold muted-text place-items-center flex-row gap-16">
                {items.map((item, itemIndex)=>(
                    <div key={"item_"+itemIndex}>
                        {item.name === "Dashboard" ?
                            <>
                                <Link className="no-underline" to={item.link}>
                                 <Button color="green" size="small" text="Dashboard" /> 
                                </Link>
                            </>
                        :
                        <Link className="no-underline" to={item.link}>
                            {item.name}
                        </Link>
                        }
                    </div>
                ))}
            </div>
        </div>
        </>
    )
}

const MobileMenu = (props) => {
    const {items} = props;
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    }
    
    const closeMobileMenu = () =>  {
        setMobileMenuOpen(false)
    }

    return (
        <>
            <div className="fixed z-50 left-0 top-0 dark:text-white">
                <div className={`${mobileMenuOpen ? 'hidden' : 'block'} p-4 z-50`}>
                    <FontAwesomeIcon icon={mobileMenuOpen ? faX : faBars} onClick={toggleMobileMenu} className="text-3xl cursor-pointer" />
                </div>
                <div className={`fixed left-0  top-0 w-2/3 h-screen bg-white dark:bg-dark-mode-nav z-30 p-6 text-lg transform ${mobileMenuOpen ? "translate-x-0 opacity-100 " : "-translate-x-full opacity-0"} transition ease-in-out duration-300`}>
                    <div className="absolute top-4 left-4 p-6 z-50">
                        <FontAwesomeIcon icon={faX} onClick={toggleMobileMenu} className="text-lg cursor-pointer" />
                    </div>
                    <div className="flex flex-col gap-12 mt-24">
                        {items.map((item, itemIndex)=>(
                            <div onClick={closeMobileMenu} key={"item_"+itemIndex}>
                                {item.name === "Dashboard" ?
                                    <>
                                        <Link className="no-underline" to={item.link}>
                                        <Button color="green" size="small" text="Dashboard" /> 
                                        </Link>
                                    </>
                                :
                                <Link className="no-underline" to={item.link}>
                                    {item.name}
                                </Link>
                                }
                        </div>
                        ))}
                    </div>
                </div> 
                <div className="relative">
                    <div onClick={closeMobileMenu} className={`fixed w-screen h-screen transition backdrop-filter ease-in-out duration-500 ${mobileMenuOpen ? ' z-20 bg-black opacity-50 filter block backdrop-blur-md' : 'opacity-0 hidden w-0 h-0'}`}>
                    </div>
                </div>
                </div>
        </>
    )
}

const HomepageNavbar = () => {
    const [items, setItems] = useState([
        {
            name: "Home",
            link: "/"
        },
        {
            name: "Languages",
            link: "/languages"
        },
        {
            name: "Explore",
            link: "/decks"
        },
        {
            name: "Pricing",
            link: "/pricing"
        },
        {
            name: "Help",
            link: "/help"
        },
        {
            name: "Dashboard",
            link: "/dashboard"
        }
    
    ])
  return (
    <>
    <div className="hidden lg:w-full lg:block">
        <ComputerMenu items={items} />
    </div>
     <div className="block lg:hidden">
        <MobileMenu items={items} />
    </div> 
    </>
  )
}

export default HomepageNavbar