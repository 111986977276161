import React, {useEffect, useState} from 'react';
import FillInGameScreen from './FillInGameScreen';
import {useParams} from "react-router-dom";
import useTables from '../../../../hooks/useTables';

const FillInGame = ({id, table, tableRows, tableColumns}) => {
    const [gameScreen, setGameScreen] = useState("game");

    return (
        <>
            {(gameScreen === "game") &&
                <>
                    <FillInGameScreen table={table} tableRows={tableRows} tableColumns={tableColumns} />
                </>
            }
        </>
    )
}

export default FillInGame;