import React from 'react'
import Button from '../../general/Button';

const DeckBlock = (props) => {
    const {block, blockIndex, mode} = props;
    return (
        <>
            <div className="flex flex-col place-items-center gap-4">
                <div className="flex flex-row justify-center gap-3">
                    <div className="font-semibold muted-text">
                        Selected deck:
                    </div>
                    <div>
                        {block.deck_id}
                    </div>
                
                </div>
                <div>
                    <Button text="Select deck" />
                </div>
            </div>
        </>
    )
}

export default DeckBlock;