import React, { useState, useEffect, useRef } from 'react';
import Button from '../../../../general/Button';
import HanziWriter from 'hanzi-writer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faArrowAltRight, faThumbsDown, faThumbsUp } from '@fortawesome/pro-solid-svg-icons';
import CharacterBox from '../characters/CharacterBox';
import RenderChineseWords from '../../common-assets/RenderChineseWords';
import SourceTranslation from '../common-assets/SourceTranslation';

const StrokeOrderHanziGame = (props) => {
    /*
        The cards passed into this game are already filtered in cloud function to only contain target_script = "hanzi". Make sure that this works when mixing into the mix mode as well.
    */


        const {
          generalHanziCurrentPinyin,
          generalHanziCurrentSource,
          generalHanziWriter,
          setGeneralHanziWriter,
          generalHanziChosenCharacter,
          generalHanziWrongClick,
          generalHanziCorrectClick,
          generalHanziGoToNextCardFunction,
          generalHanziGoToNextCard,
          generalHanziQuickGoToNextCard,
          generalHanziClearCharacterElement,
          generalHanziCreateWriter,
          generalHanziCharacterRef, 
          generalHanziActiveCharacterShown,
          reviewFinished,
          setReviewFinished,
          isPremium,
          currentCardIndex,
          setCurrentCardIndex,
          nbOfAnswers,
          setNbOfAnswers,
          cardsLeft,
          setCardsLeft,
          gameScreen,
          score,
          setScore,
          setGameScreen,
          mistakes,
          setMistakes,
          audioLoading,
          autoPlayPronunciations,
          globalHandleSkipAnswer,
          globalHandleCorrectAnswer,
          globalHandleWrongAnswer,
          isInMix,
          googleTextToSpeechClick,
          playMode,
          setPlayMode,
          showLoadingScreen,
          setShowLoadingScreen,
          cards,
          deck,
          decks,
          loadingItems,
          refreshData,
          howManyItems,
          backToPlayPageLink,
          playModeArray,
          id,
          type
  } = props;
  const [showAnswer, setShowAnswer] = useState(false);

  const wrongClick = () => {
    generalHanziWrongClick();
  };

  const correctClick = () => {
    generalHanziCorrectClick();
  };

  const goToNextCardFunction = () => {
    generalHanziGoToNextCardFunction();
  }

  const goToNextCard = () => {
    generalHanziGoToNextCard();
  };

  const quickGoToNextCard = () => {
    generalHanziQuickGoToNextCard();
  }

  const createWriter = (options, additionalSetup) => {
    console.log("Creating writer")
    generalHanziCreateWriter(options, additionalSetup);
  };

  const clearCharacterElement = ()  => {
    generalHanziClearCharacterElement();
  }


  useEffect(() => {
    if (generalHanziChosenCharacter !== null) {
      generalHanziActiveCharacterShown.current = generalHanziChosenCharacter;
      const writerOptions = {
        width: 350,
        height: 350,
        padding: 20,
        drawingWidth: 30, // Adjust this value to change the stroke width
        showHintAfterMisses:2,
        showOutline: true,
        showCharacter: false,
        quizStartStrokeNum: 0,
      };
      createWriter(writerOptions, (writer) => {
        writer.quiz({
          onMistake: function(strokeData) {
            wrongClick();
          },
          onCorrectStroke: function(strokeData) {
            correctClick();
          },
          onComplete: function(summaryData) {
            goToNextCard();
          }
        });
    });

    }
  }, [generalHanziChosenCharacter]);

  return (
    <>
        <div className="h-full overflow-y-auto justify-center gap-10 2xl:gap-12 flex-col place-items-center flex">           
            <div className="flex flex-row gap-2 text-6xl">
              <RenderChineseWords text={cards[currentCardIndex].target} highlightCharacter={generalHanziChosenCharacter} highlightColor={"text-persian-green"} language="zh" data={cards[currentCardIndex]} targetOrSource="target" fontSize="text-3xl lg:text-4xl" pinyinSize="text-lg lg:text-2xl" />
            </div>
            <CharacterBox ref={generalHanziCharacterRef} />
            <SourceTranslation generalHanziCurrentSource={generalHanziCurrentSource} card={cards[currentCardIndex]}  />
            <div>
                <div className="flex justify-center gap-6 underline muted-text cursor-pointer">
                    <div onClick={() => quickGoToNextCard()}>
                      Skip character
                    </div>
                </div>
            </div>
        
        {cards !== null && cards.length === 0 &&
            <>
            <div className="text-center">
                No characters found.
            </div>
            </>
                }
        </div>

    </>
  );
};

export default StrokeOrderHanziGame;
