import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleExclamation} from '@fortawesome/pro-solid-svg-icons';

const SimpleErrorBar = ({message}) => {
    return (
        <div className="bg-gradient-to-r from-red-50 to-red-300 rounded-xl shadow-lg my-2 flex w-full flex-row justify-start place-items-center  text-black text-center">
            <div className="bg-red-600 text-center rounded-lg shadow-lg p-4 text-white">
                <FontAwesomeIcon icon={faCircleExclamation} />
            </div>
            <div className="p-4 w-full text-sm text-center font-bold text-gray-700 ">
                {message}
            </div>
        </div>
    )
}

export default SimpleErrorBar;
