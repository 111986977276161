import React, {useEffect, useState} from 'react';
import QuillEditor from '../../../components/notes/editor/QuillEditor';
import {useParams} from "react-router-dom";
import useNotes from '../../../hooks/useNotes';
import {Helmet} from 'react-helmet';
import DeckBlock from '../../../components/notes/blocks/DeckBlock';
import TableBlock from '../../../components/notes/blocks/TableBlock';
import Button from '../../../components/general/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLoader } from '@fortawesome/pro-solid-svg-icons';
import Block from '../../../components/notes/blocks/Block';
import { faPlus } from '@fortawesome/pro-duotone-svg-icons';


const EditMyNote = () => {
  let { id } = useParams();
  let noteId = parseInt(id);
  const [note, setNote] = useState(null);
  const [noteName, setNoteName] = useState(null);
  const [noteDocId, setNoteDocId] = useState(null);
  const [loadingNote, setLoadingNote] = useState(true);
  const [noteBlocks, setNoteBlocks] = useState([]);
  const [savingToDb, setSavingToDb] = useState(false);
  const [showAddBlockMenu, setShowAddBlockMenu] = useState(false);
  const [itemTypes, setItemTypes] = useState(["html", "deck", "table"]);

  const {
    fetchNote, 
    fetchNoteContent, 
    saveNoteContent, 
    saveChangesToNote
  } = useNotes();

  const saveNoteChanges = async () => {
    console.log("Save to database: ", noteBlocks);
    setSavingToDb(true);
    await saveChangesToNote({noteDocId: noteDocId, blocks: noteBlocks, name: noteName});
    setSavingToDb(false);
  }

  const htmlOnUpdate = ({html=null, blockIndex=null}) => {
    console.log("HTML update: ", html, blockIndex);
    if (html === null || blockIndex === null){
      return;
    }
    let newBlocks = [...noteBlocks];
    newBlocks[blockIndex].content = html;
    setNoteBlocks(newBlocks);
  }

  useEffect(()=>{
    const fetchData = async () => {
      setLoadingNote(true);
      let r_note = await fetchNote(noteId);
      setNote(r_note);
      if (r_note !== null && r_note !== undefined){
        if (r_note.hasOwnProperty('blocks')){
          setNoteBlocks(r_note.blocks);
        }
        if (r_note.hasOwnProperty('name')){
          setNoteName(r_note.name);
        }
        if (r_note.hasOwnProperty('note_doc_id')){
          setNoteDocId(r_note.note_doc_id);
        }
        console.log(r_note)
      }
      setLoadingNote(false);
    }
    if (noteId !== null){
      fetchData();
    }
  },[noteId]);

  //<NoteEditor note={note} loadingNote={loadingNote} noteContent={noteContent} />
  //<CustomNoteEditor mode={"edit"} note={note} noteContent={noteContent} loadingNote={loadingNote} />
  console.log("note:", note,  noteBlocks)

  const addBlockClick = () => {
    setShowAddBlockMenu(true);
  };

  const addItemClick = (itemType) => {
    console.log("Adding item: ", itemType);
    let newBlocks = [...noteBlocks];
    let newBlock = {
      type: itemType,
      content: ""
    };
    newBlocks.push(newBlock);
    setNoteBlocks(newBlocks);
  };

  return (
    <>
        <div className="md:px-4 py-4 pb-32 sm:pb-16 sm:p-8 sm:ml-20 sm:min-h-screen sm:h-full min-h-screen h-full flex flex-col gap-10">
            {savingToDb &&
              <>
                <div className="fixed left-0 top-0 w-screen h-screen flex flex-row justify-center place-items-center">
                  <FontAwesomeIcon icon={faLoader} className="text-persian-green z-30 fa-spin text-7xl" />
                </div>
              </>
            }
            {note !== null &&
            <>
              <Helmet>
                  <title>{note.name} - Yalango</title>
                  <meta name="description" content={"Study the note " + note.name + " on Yalango with interactive games."} />
              </Helmet>
              <div className="flex flex-row justify-between">
                <div className="font-bold text-lg">
                  <input onChange={(e)=>setNoteName(e.target.value)} value={noteName} className="p-3 rounded-xl " />
                </div>
                <Button onClick={saveNoteChanges} text="Save changes" color="green" />
              </div>
              
              {noteBlocks !== undefined && noteBlocks !== null && noteBlocks.length > 0 &&
                <>
                  <div className="flex flex-col gap-10">
                    {noteBlocks.map((block, blockIndex) => (
                      <div key={"block_"+blockIndex}>
                        {block.type === "html" &&
                          <Block blockType={block.type} blockName={"Text block"} blockIndex={blockIndex} blockParams={{"text": block.content, "htmlOnUpdate": htmlOnUpdate}} />
                        }
                        {block.type === "deck" &&
                          <>
                            <Block blockType={block.type} blockName={"Deck block"} blockIndex={blockIndex} blockParams={{"mode": "edit", "block": block}} />
                          </>
                        }
                        {block.type === "table" &&
                          <>
                            <Block blockType={block.type} blockName={"Table block"} blockIndex={blockIndex} blockParams={{"mode": "edit", "block": block}} />
                          </>
                        }
                      </div>
                    ))}
                  </div>
                </>
              }
              <div className="flex flex-col w-64 mx-auto place-items-center gap-0">
                <div className="w-full">
                  <Button onClick={addBlockClick} icon={faPlus} text="Add element" color="charcoal" />
                </div>
                {showAddBlockMenu &&
                  <>
                    {itemTypes.map((itemType, index) => (
                      <div onClick={()=>addItemClick(itemType)} key={"item_"+index} className="bg-white w-full p-4 cursor-pointer text-center font-semibold rounded-xl flex flex-col gap-2 shadow-lg">
                        Add {itemType}
                      </div>
                    ))}
                  </>
                }

              </div>
              
            </>
            }
        </div>
    </>
  )
}

export default EditMyNote