import React from 'react'

const Leaf = (props) => {
    let c = "";
    if (props.leaf.bold){
      c = c + " font-bold ";
    }
    if (props.leaf.italic){
      c = c + " italic ";
    }
    if (props.leaf.underline){
      c = c + " underline ";
    }
    return (
      <span {...props.attributes} className={c}>
        {props.children}
      </span>
    )
} 

export default Leaf