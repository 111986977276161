import React, {useState} from 'react';
import ProgressBar from '../general/progress/ProgressBar';

const GoalsProgressBar = (props) => {
    const {allData, text, todayNumber, tooltipData} = props;
  return (
    <>
        <div>
            <ProgressBar tooltipData={tooltipData} width={allData['width']} text={text} progressNumber={allData['stats']['total_correct_items']} progressGoal={allData['goals']['total_correct_items_per_day'][todayNumber]} />
        </div>
    </>
  )
}

export default GoalsProgressBar;