import React from 'react';
import {projectFirestore, projectFunctions, timeStamp, firebase} from '../firebase/config.js';
import { useAuth } from "../contexts/AuthContext";

export default function useTables() {

    let {currentUser} = useAuth();

    //projectFunctions.useEmulator("localhost", 5001); // local testing

    const getTablesFromCurrentUserInRealtime = async (targetLanguage, setStateFunc) => {
        let query =  projectFirestore.collection('tables').where('uid', '==', currentUser.uid).where('target_ISO_639-1', '==', targetLanguage);
        query.onSnapshot(querySnapshot => {
            let tables = [];
            for (const doc of querySnapshot.docs){
                tables.push(doc.data());
            }
            setStateFunc(tables);
        });
    }

    const saveChangesToTable = async ({tableId, tableName, sourceLanguage, privacy, publicTitle, description}) => {
        console.log(tableId, privacy);
        if (tableId === undefined || tableId === null || currentUser === null){return false}
        const collectionRef = projectFirestore.collection('tables');
        const docRef = collectionRef.where('uid', '==', currentUser.uid).where('id', '==', tableId);

        return docRef.get().then(async (snapshots)=>{
            if (snapshots.size === 1){
                let doc_id = snapshots.docs[0].id;
                let updateRef = collectionRef.doc(doc_id);
                let obj = {
                    'last_updated_timestamp': timeStamp,
                };
                if (sourceLanguage !== undefined && sourceLanguage !== null){
                    obj['source_ISO_639-1'] = sourceLanguage;
                }
                if (tableName !== undefined && tableName !== null && tableName !== ""){
                    obj['name'] = tableName;
                }
                if (privacy !== undefined && privacy !== null){
                    obj['privacy'] = privacy;
                }
                if (publicTitle !== null && publicTitle !== undefined){
                    obj['title'] = publicTitle.trim();
                }
                if (description !== null && description !== undefined){
                    obj['description'] = description.trim();
                }
                await updateRef.update(obj).catch(err=>console.log(err));
                return true;
            };
        }).catch(()=>{
            return false;
        });
    }

    const getAllTablesFromCurrentUserInRealtime = async (setStateFunc) => {
        let query =  projectFirestore.collection('tables').where('uid', '==', currentUser.uid);
        return query.onSnapshot(querySnapshot => {
            let tables = [];
            for (const doc of querySnapshot.docs){
                let d = doc.data();
                d['doc_id'] = doc.ref.id;
                tables.push(d);
            }
            setStateFunc(tables);
        });
    }

    const getTablesFromCurrentUser = async (targetLanguage) => {
        let query =  projectFirestore.collection('tables').where('uid', '==', currentUser.uid).where('target_ISO_639-1', '==', targetLanguage);
        return query.get().then(querySnapshot => {
            let tables = [];
            for (const doc of querySnapshot.docs){
                let d = doc.data();
                d['table_doc_id'] = doc.ref.id;
                tables.push(d);
            }
            return tables;
        });
    }

    const getTableFromCurrentUserInRealtime = async (tableId, setTable) => {
        let query =  projectFirestore.collection('tables').where('uid', '==', currentUser.uid).where('id', '==', tableId);
        return query.onSnapshot(querySnapshot => {
            if (querySnapshot.docs.length === 1){
                let d = querySnapshot.docs[0].data();
                d['doc_id'] = querySnapshot.docs[0].id;
                console.log("table: ", d);
                setTable(d);
            } else if (querySnapshot.docs.length === 0){
                return false;
            }
        });
    }

    const getTableFromCurrentUser = async (tableId) => {
        let query =  projectFirestore.collection('tables').where('uid', '==', currentUser.uid).where('id', '==', tableId);
        return query.get().then(querySnapshot => {
            if (querySnapshot.docs.length === 1){
                let d = querySnapshot.docs[0].data();
                d['doc_id'] = querySnapshot.docs[0].id;
                return d;
            } else if (querySnapshot.docs.length === 0){
                return false;
            }
        }).catch((err)=>{
            return false;
        });
    }

    const getPublicTable = async ({tableId}) => {
        // fetch table where uid is current user OR privacy is public
        let query = projectFirestore.collection("tables").where("privacy", "==", "public").where("id", "==", tableId);
        return query.get().then((snapshot)=>{
            if (snapshot.empty){return null}
            let data = snapshot.docs[0].data();
            data['doc_id'] = snapshot.docs[0].ref.id;
            return data;
        });
    }

    const getTableColumnsFromCurrentUserInRealtime = async (table, setTableColumns) => {
        let query =  projectFirestore.collection('tables').doc(table['doc_id']).collection("columns");
        query.onSnapshot(querySnapshot => {
            if (querySnapshot.empty){setTableColumns([])};
            if (querySnapshot.docs.length > 0){
                let docs = Array(querySnapshot.docs.length);
                let order = table['column_order'];
                querySnapshot.docs.forEach(doc=>{
                    let d = doc.data();
                    d['doc_id'] = doc.id;
                    let pos = order.indexOf(d.id);
                    if (pos > -1){
                        docs[pos] = d;
                    }
                });
                setTableColumns(docs);
            } else {
                setTableColumns([]);
            }
        });
    }

    const getTableColumnsFromCurrentUser = async (table) => {
        let query =  projectFirestore.collection('tables').doc(table['doc_id']).collection("columns");
        return query.get().then(querySnapshot => {
            if (querySnapshot.empty){return []};
            if (querySnapshot.docs.length > 0){
                let docs = Array(querySnapshot.docs.length);
                let order = table['column_order'];
                let not_added = [];
                querySnapshot.docs.forEach(doc=>{
                    let d = doc.data();
                    d['doc_id'] = doc.id;
                    let pos = order.indexOf(d.id);
                    if (pos > -1){
                        docs[pos] = d;
                    } else {
                        not_added.push(d);
                    }
                });
                docs = docs.filter(n => n);
                not_added.forEach((el)=>docs.push(el));
                return docs;
            } else {
                return [];
            }
        });
    }

    const getTableColumnsFromPublicTable = async ({table}) => {
        let query =  projectFirestore.collection('tables').doc(table['doc_id']).collection("columns");
        return query.get().then(querySnapshot => {
            if (querySnapshot.empty){return []};
            if (querySnapshot.docs.length > 0){
                let docs = Array(querySnapshot.docs.length);
                let order = table['column_order'];
                let not_added = [];
                querySnapshot.docs.forEach(doc=>{
                    let d = doc.data();
                    d['doc_id'] = doc.id;
                    let pos = order.indexOf(d.id);
                    if (pos > -1){
                        docs[pos] = d;
                    } else {
                        not_added.push(d);
                    }
                });
                docs = docs.filter(n => n);
                not_added.forEach((el)=>docs.push(el));
                return docs;
            } else {
                return [];
            }
        });
    }

    const getTableRowsFromCurrentUserInRealtime = async (tableDoc, rowOrder, setTableItems) => {
        console.log(tableDoc, rowOrder, setTableItems);
        let query =  projectFirestore.collection('tables').doc(tableDoc).collection("rows");
        query.onSnapshot(querySnapshot => {
            if (querySnapshot.docs.length > 0){
                let items = [];
                querySnapshot.docs.forEach((doc)=>{
                    items.push(doc.data());
                });
                if (rowOrder !== null && rowOrder !== undefined){
                    let ordered_list = [];
                    rowOrder.forEach((row)=>{
                        items.forEach((item)=>{
                            if (row === item.id){
                                ordered_list.push(item);
                            }
                        });
                    });
                    items.forEach((item)=>{
                        if (ordered_list.indexOf(item) === -1){
                            ordered_list.push(item);
                        }
                    })
                    setTableItems(ordered_list);
                } else {
                    setTableItems(items);
                }
            } else {
                setTableItems([]);
            }
        });
    }

    const getTableRowsFromCurrentUser = async (table) => {
        let query =  projectFirestore.collection('tables').doc(table['doc_id']).collection("rows");
        return query.get().then(querySnapshot => {
            if (querySnapshot.docs.length > 0){
                let items = [];
                querySnapshot.docs.forEach((doc)=>{
                    items.push({'doc_id': doc.ref.id, 'table_id': table.id, ...doc.data()});
                })
                if (table['row_order'] !== null && table['row_order'] !== undefined){
                    let ordered_list = [];
                    table['row_order'].forEach((row)=>{
                        items.forEach((item)=>{
                            if (row === item.id){
                                ordered_list.push(item);
                            }
                        });
                    });
                    items.forEach((item)=>{
                        if (ordered_list.indexOf(item) === -1){
                            ordered_list.push(item);
                        }
                    })
                    return ordered_list;
                } else {
                    return items;
                }
            } else {
                return [];
            }
        });
    }

    const getTableRowsFromPublicTable = async ({table}) => {
        let query =  projectFirestore.collection('tables').doc(table['doc_id']).collection("rows");
        return query.get().then(querySnapshot => {
            if (querySnapshot.docs.length > 0){
                let items = [];
                querySnapshot.docs.forEach((doc)=>{
                    items.push({'doc_id': doc.ref.id, 'table_id': table.id, ...doc.data()});
                })
                if (table['row_order'] !== null && table['row_order'] !== undefined){
                    let ordered_list = [];
                    table['row_order'].forEach((row)=>{
                        items.forEach((item)=>{
                            if (row === item.id){
                                ordered_list.push(item);
                            }
                        });
                    });
                    items.forEach((item)=>{
                        if (ordered_list.indexOf(item) === -1){
                            ordered_list.push(item);
                        }
                    })
                    return ordered_list;
                } else {
                    return items;
                }
            } else {
                return [];
            }
        });
    };

    const getSpecificTableRow = async (tableDocId, tableId, tableRowDocId) => {
        let query =  projectFirestore.collection('tables').doc(tableDocId).collection("rows").doc(tableRowDocId);
        return query.get().then(querySnapshot => {
            if (querySnapshot.exists){
                let row = {'doc_id': querySnapshot.ref.id, 'table_id': tableId, ...querySnapshot.data()};
                return row;
            } else {
                return null;
            }
        });
    }

    const getXRandomTableRowsFromCurrentUser = async (table, howManyItems) => {
        const f =  projectFunctions.httpsCallable('getXRowsFromTable');
        const results = await f({'tableId': table.id, 'howManyItems': howManyItems});
        let rows = results['data']['rows'];
        return rows;
    }

    const getXRandomTableRowsFromPublicTable = async ({table, howManyItems}) => {
        const f =  projectFunctions.httpsCallable('getXRowsFromPublicTable');
        const results = await f({'tableId': table.id, 'howManyItems': howManyItems});
        let rows = results['data']['rows'];
        return rows;
    }

    const generateUniqueTableId = async () => {
        const generateTableId =  projectFunctions.httpsCallable('generateTableId');
        const results = await generateTableId();
        let id = results['data'];
        return id;
    }

    const saveNewTable = async (targetLanguage, sourceLanguage, name, selectedTemplateValue, type, parentFolderDocId) => {
        if (currentUser === null){return false};
        if (name === "" || name === null || name === undefined){return false};
        
        const saveNewTableFunction =  projectFunctions.httpsCallable('saveNewTable');
        let data = await saveNewTableFunction({
            'targetLanguage': targetLanguage,
            'sourceLanguage': sourceLanguage, 
            'name': name, 
            'startTemplate': selectedTemplateValue, 
            'type': type, 
            'parentFolderDocId': "top_level", 
            'destinationFolder': parentFolderDocId
        }).catch(err=>{return false});
        if (data === false){return false};

        return data;
    };

    const addNewTableColumn = async (tableDoc, columnName) => {
        if (columnName === null || columnName === undefined || columnName === ""){
            columnName = "Untitled";
        }
        const saveNewColumn = projectFunctions.httpsCallable("saveNewTableColumn");
        return saveNewColumn({
            'tableDoc': tableDoc,
            'columnName': columnName
        })
    }

    const renameTableColumn = async (tableDoc, columnDoc, name) => {
        const rename = projectFunctions.httpsCallable("renameTableColumn");
        return rename({
            'tableDoc': tableDoc,
            'columnDoc': columnDoc,
            'name': name
        });
    }

    const renameTable = async (tableDoc, name) => {
        const query = projectFirestore.collection("tables").doc(tableDoc);
        return query.set({
            'name': name, 
            'last_updated_timestamp': timeStamp
        }, {merge: true});
    }

    const changeTableColumnsOrder = async (table, order) => {
        const changeOrder = projectFunctions.httpsCallable("changeTableColumnsOrder");
        return changeOrder({
            'colOrder': order,
            'tableDoc': table['doc_id']
        });
    }

    const saveChangesToTableRows = async (table, originalRows, newRows) => {
        const save = projectFunctions.httpsCallable("saveChangesToTableRows");
        return save({
            'originalRows': originalRows,
            'newRows': newRows, 
            'tableDoc': table['doc_id']
        })
    }

    const getTableAndContentFromCurrentUser = async (tableId) => {
        let table = await getTableFromCurrentUser(tableId);
        let rows = await getTableRowsFromCurrentUser(table);
        let columns = await getTableColumnsFromCurrentUser(table);

        return {'table': table, 'rows': rows, 'columns': columns};
    }

    const deleteTableColumns = async (tableId, colIds, rows) => {
        let query = projectFirestore.collection("tables").where('uid', '==', currentUser.uid).where("id", "==", tableId);
        let snapshot = await query.get();
        if (!snapshot.empty && snapshot.docs.length === 1){
            let tableDocId = snapshot.docs[0].ref.id;
            for (const colId of colIds){
                let colSnap = await projectFirestore.collection("tables").doc(tableDocId).collection("columns").where("id", "==", colId).get();
                if (!colSnap.empty && colSnap.docs.length === 1){
                    let colDocId = colSnap.docs[0].ref.id;
                    await projectFirestore.collection("tables").doc(tableDocId).collection("columns").doc(colDocId).delete();
                }
            }
            // delete values from rows 
            for (const row of rows){
                if ('values' in row){
                    let newValuesObj = {};
                    Object.entries(row['values']).forEach((value, index)=>{
                        let col = value[0];
                        let row_value = value[1];
                        if (colIds.indexOf(parseInt(col)) === -1){
                            newValuesObj[parseInt(col)] = row_value;
                        }
                    });
                    let query = projectFirestore.collection("tables").doc(tableDocId).collection("rows").where("id", "==", row['id']);
                    let snap = await query.get();
                    if (!snap.empty && snap.docs.length === 1){
                        let doc_id = snap.docs[0].ref.id;
                        await projectFirestore.collection("tables").doc(tableDocId).collection("rows").doc(doc_id).set({'values': newValuesObj}, {merge:true}).catch((err)=>console.log(err));
                    }
                }
            }
        } else {
            return false;
        }
    }

    const deleteTable = async (tableId) => {
        let query = projectFirestore.collection("tables").where('uid', '==', currentUser.uid).where("id", "==", tableId);
        let snapshot = await query.get();
        if (!snapshot.empty && snapshot.docs.length === 1){
            let tableDocId = snapshot.docs[0].ref.id;
            
            let rows_snapshot = await projectFirestore.collection("tables").doc(tableDocId).collection("rows").get().catch((err)=>console.log(err));
            if (!rows_snapshot.empty){
                for (const doc of rows_snapshot.docs ){
                    await projectFirestore.collection("tables").doc(tableDocId).collection("rows").doc(doc.ref.id).delete().catch((err)=>console.log(err));
                };
            }
            let columns_snapshot = await projectFirestore.collection("tables").doc(tableDocId).collection("columns").get().catch((err)=>console.log(err));
            if (!columns_snapshot.empty){
                for (const doc of columns_snapshot.docs ){
                    await projectFirestore.collection("tables").doc(tableDocId).collection("columns").doc(doc.ref.id).delete().catch((err)=>console.log(err));
                };
            }

            await projectFirestore.collection("tables").doc(tableDocId).delete().catch((err)=>console.log(err));
        } else {
            return false;
        }
    }

    const deleteTableFromDocId = async (docId) => {
        let path = projectFirestore.collection("tables").doc(docId);
        let r = await path.delete();
        return r;
    }


return {
    getTablesFromCurrentUserInRealtime, 
    saveNewTable, 
    getTableFromCurrentUserInRealtime, 
    getTableRowsFromCurrentUserInRealtime, 
    getTableColumnsFromCurrentUserInRealtime, 
    addNewTableColumn, 
    renameTableColumn, 
    changeTableColumnsOrder, 
    saveChangesToTableRows, 
    saveChangesToTable,
    getTableFromCurrentUser, 
    getTableColumnsFromCurrentUser, 
    getTableColumnsFromPublicTable,
    getTableRowsFromCurrentUser, 
    getXRandomTableRowsFromCurrentUser,
    getXRandomTableRowsFromPublicTable,
    getTablesFromCurrentUser, 
    getTableAndContentFromCurrentUser,
    getAllTablesFromCurrentUserInRealtime, 
    deleteTable,
    deleteTableColumns, 
    renameTable, 
    deleteTableFromDocId, 
    getSpecificTableRow, 
    getPublicTable, 
    getTableRowsFromPublicTable
  }
}