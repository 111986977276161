import React, {useState, useEffect} from 'react';
import CategorySettingsModal from '../modals/CategorySettingsModal';
import '../../../css/CardGrid.css';
import Card from './Card';
import CardItems from './CardItems';
import CategoryMenu from './CategoryMenu';
import Button from '../../general/Button';
import {projectFirestore} from '../../../firebase/config.js';
import { useAuth } from "../../../contexts/AuthContext";
import useLanguages from '../../../hooks/useLanguages';
import GeneralFeedbackCircle from '../../feedback/components/GeneralFeedbackCircle';


import {Link} from 'react-router-dom';


const CardGrid = ({deckIds, category}) => {

    let {currentUser} = useAuth();
    let {getAllLanguages} = useLanguages();
    
    /* DISPLAY MODES */
    const [zoomMode, setZoomMode] = useState(false);

    /* MODAL */
    const [showModal, setShowModal] = useState(false);
    const [modalMode, setModalMode] = useState("");

    const [selectedCard, setSelectedCard] = useState("");
    const [sourceInput, setSourceInput] = useState("");
    const [targetInput, setTargetInput] = useState("");


    const [allLanguages, setAllLanguages] = useState(null);

    /* DOCS */
    const [docs, setDocs] = useState(null);
    const [deck, setDeck] = useState({});
    const [nbOfWords, setNbOfWords] = useState(0);

    const [loadingItems, setLoadingItems] = useState(true);

    /* Fetch data once in beginning */
    useEffect(() => {
        if (deckIds !== undefined) {
            fetchAllItems(deckIds);
        };    
    }, [deckIds]);

    useEffect(()=>{
        const fetchAllLanguages = async () => {
            let all_languages = await getAllLanguages();
            setAllLanguages(all_languages);
        }

        fetchAllLanguages();
    },[]);

    useEffect(()=> {
        if (docs !== null){
            setNbOfWords(docs.length);
            //console.log(docs.length);
        }
    },[docs]);

    const fetchAllItems = async (deck_ids) => {
        if (deck_ids.length > 0){
            let items_list = [];
            //console.log("DECK IDS: ", deck_ids);
            for (let deck_id of deck_ids) {
                console.log("STARTING LOOP FOR: ", deck_id, currentUser.uid);
                const docRef = projectFirestore.collection('decks').where('uid', '==', currentUser.uid).where('id', '==', deck_id);
                let items = await findItemsInDeck(docRef, deck_id);
                items_list = [...items_list, ...items];
            };
            if (docs !== null){
                setDocs(oldArray => [...oldArray, ...items_list]);
            } else {
                setDocs(items_list);
            }
        } else {
            setDocs([]);
        };

    };

    useEffect(()=>{
        if (docs !== null){
            //console.log("Check docs ", docs);
            setLoadingItems(false);
        }
    },[docs])

    const findItemsInDeck = async (docRef, deck_id) => {
        let currentDeck;
        let items = await docRef.get().then((querySnapshot) => {
            if (querySnapshot.empty){
                currentDeck = null;
                return null;
            }
            currentDeck = querySnapshot.docs[0].data();
            //console.log("Current Deck: ", currentDeck);
            return querySnapshot.docs[0].ref.collection('items').get();
        }).then(querySnapshot => { 
            const temp_items_list = [];
            if (querySnapshot !== null){
                querySnapshot.forEach((doc) => {
                    temp_items_list.push({...doc.data(), deck: currentDeck, 'target_ISO_639-1':currentDeck['target_ISO_639-1'], 'source_ISO_639-1':currentDeck['source_ISO_639-1'], id:doc.id, deck_id: deck_id});
                });
            }
            return temp_items_list;
        }); 
        return items;
    };

    const resetInputData = () => {
        setTargetInput("");
        setSourceInput("");
    }

    //console.log(allLanguages);
    
    return (
        <>
            <CategoryMenu CategorySettingsModal={CategorySettingsModal} nbOfWords={nbOfWords} zoomMode={zoomMode} setZoomMode={setZoomMode} modalMode={modalMode} showModal={showModal} setShowModal={setShowModal} resetInputData={resetInputData} docs={docs} setDocs={setDocs} modalMode={modalMode} setModalMode={setModalMode} setTargetInput={setTargetInput} setSourceInput={setSourceInput} targetInput={targetInput} sourceInput={sourceInput} fetchAllItems={fetchAllItems} category={category} />
            { loadingItems === true ?
            <>
                <div className="text-black flex flex-col items-center justify-content gap-2">
                    <p>Loading items...</p>
                </div>
            </>
            :
                (docs.length > 0 && allLanguages !== null) ? 
                    <>
                    <CardItems zoomMode={zoomMode} allLanguages={allLanguages} docs={docs} modalMode={modalMode} setModalMode={setModalMode} Card={Card} />
                    
                    </>
            : 
                <div className="text-black flex flex-col items-center justify-content gap-2 m-4 p-4">
                    <p className="text-base">There are no items in this category. You can come back to this page after you've added cards to decks in this category.</p> 
                    <div classCenter="text-center">
                        <Link to={'/decks'}><Button color="green" text="Add items"></Button></Link>
                    </div>
                </div>
            } 
        </>
    )
}

export default CardGrid;