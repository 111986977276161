import React from 'react';
import MyLibraryMenu from '../../components/menus/MyLibraryMenu';

const Explore = () => {
  return (
    <>
        <div className=" ">
            <div className="flex flex-col gap-8">
                <MyLibraryMenu activeName="explore" />
                <h1>Explore</h1>
            </div>
        </div>
    </>
  )
}

export default Explore;