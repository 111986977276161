import React from 'react';
import RegisterComponent from '../../components/authentication/RegisterComponent';

function Register() {
    return (
        <div className=" ">
            <RegisterComponent />
        </div>   
    )
}

export default Register;
