import React from 'react';
import {Link} from 'react-router-dom';
import Button from '../../components/general/Button';

const MyGoals = () => {
  return (
    <>
        <div className="  p-4 h-full min-h-screen">
            <h1>My goals</h1>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
                <div className="bg-white flex flex-col gap-3 text-center rounded-lg shadow-xl p-3">
                    <div className="font-bold text-gray-600 text-lg">
                        Daily goals
                    </div>
                    <div className="text-sm text-gray-600">
                        Set daily study goals and track your progress.
                    </div>
                    <div>
                        <Link to="/my-goals/daily">
                            <Button text="Edit goals" color="green" />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default MyGoals