import React from 'react';
import ImportDeck from '../../components/decks/ImportDeck';

const ImportContentPage = () => {
  return (
    <>
         <div className="  flex flex-col gap-4">
            <h1>Bulk upload and import</h1>
            <div>
              <div className="">
                <h2>Decks</h2>
              </div>
              <ImportDeck id={null} />
            </div>
        </div>
    </>
  )
}

export default ImportContentPage;