import React, { forwardRef } from 'react';

const CharacterBox = forwardRef((props, ref) => {
    return(
        <>
            <div className="flex flex-row justify-center place-items-center ">
                <div className="size-96 sm:size-96 lg:size-136 mx-auto ">
                <div className="relative h-full w-full flex flex-row justify-center place-items-center " >
                    <div ref={ref} className="z-20 w-[350px] h-[350px] bg-white m-auto border-solid border-gray-300 border  h-full flex flex-row justify-center place-items-center  w-full h-full" >
                        
                    </div>
                    
                    <div className="hidden absolute left-0 opacity-30  z-0 top-0 w-full h-full ">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-full h-full "  viewBox="0 0 100 100" preserveAspectRatio="none">
                        <line x1="0" y1="10" x2="100" y2="10" stroke="#cccccc" strokeWidth="0.5" />
                        <line x1="0" y1="20" x2="100" y2="20" stroke="#cccccc" strokeWidth="0.5" />
                        <line x1="0" y1="30" x2="100" y2="30" stroke="#cccccc" strokeWidth="0.5" />
                        <line x1="0" y1="40" x2="100" y2="40" stroke="#cccccc" strokeWidth="0.5" />
                        <line x1="0" y1="50" x2="100" y2="50" stroke="#cccccc" strokeWidth="0.5" />
                        <line x1="0" y1="60" x2="100" y2="60" stroke="#cccccc" strokeWidth="0.5" />
                        <line x1="0" y1="70" x2="100" y2="70" stroke="#cccccc" strokeWidth="0.5" />
                        <line x1="0" y1="80" x2="100" y2="80" stroke="#cccccc" strokeWidth="0.5" />
                        <line x1="0" y1="90" x2="100" y2="90" stroke="#cccccc" strokeWidth="0.5" />

                        
                        <line x1="10" y1="0" x2="10" y2="100" stroke="#cccccc" strokeWidth="0.5" />
                        <line x1="20" y1="0" x2="20" y2="100" stroke="#cccccc" strokeWidth="0.5" />
                        <line x1="30" y1="0" x2="30" y2="100" stroke="#cccccc" strokeWidth="0.5" />
                        <line x1="40" y1="0" x2="40" y2="100" stroke="#cccccc" strokeWidth="0.5" />
                        <line x1="50" y1="0" x2="50" y2="100" stroke="#cccccc" strokeWidth="0.5" />
                        <line x1="60" y1="0" x2="60" y2="100" stroke="#cccccc" strokeWidth="0.5" />
                        <line x1="70" y1="0" x2="70" y2="100" stroke="#cccccc" strokeWidth="0.5" />
                        <line x1="80" y1="0" x2="80" y2="100" stroke="#cccccc" strokeWidth="0.5" />
                        <line x1="90" y1="0" x2="90" y2="100" stroke="#cccccc" strokeWidth="0.5" />
                        </svg>
                    </div>
                </div>
                </div>
            </div>
        </>
    )
});

export default CharacterBox;