import {useState, useEffect} from 'react';
import queryString from 'query-string';
import {useLocation, useNavigate } from 'react-router-dom';

export default function useQueryStrings() {
    let navigate = useNavigate();
    let location = useLocation();
    const [currentPath, setCurrentPath] = useState(location.pathname);
    const [currentSearch, setCurrentSearch] = useState(location.search);

    useEffect(()=>{
        setCurrentPath(location.pathname);
        setCurrentSearch(location.search);
    },[location]);

    const getQueryStrings = async () => {
        const parsed_strings = await queryString.parse(location.search);
        return parsed_strings;
    }

    const createQueryParams = (value = "", name) => {
        const searchParams = new URLSearchParams(currentSearch);
        searchParams.set(`${name}`, value);
        return searchParams.toString();
    }

    const setQueryStrings = (value, name) => {
        let url = createQueryParams(value, name);
        navigate(currentPath+'?'+url);
    }

    const removeQueryStrings = (name) => {
        const searchParams = new URLSearchParams(currentSearch);
        searchParams.delete(name);
        let url = searchParams.toString();
        //console.log("URL: ", url);
        navigate(currentPath+'?'+url);
    } 

    const addAndRemoveQueryStrings = (addList, removeList) => {
        const searchParams = new URLSearchParams(currentSearch);
        removeList.forEach((name)=>{
            searchParams.delete(name);
        })
        addList.forEach((add)=>{
            searchParams.set(`${add.name}`, add.value);
        })
        let url  = searchParams.toString();
        navigate(currentPath+'?'+url);
    }

    return {
        getQueryStrings, 
        removeQueryStrings,
        createQueryParams,
        setQueryStrings,
        addAndRemoveQueryStrings
    };
}