import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-duotone-svg-icons';
import { faCardsBlank } from '@fortawesome/pro-solid-svg-icons';
import { faRobot } from '@fortawesome/pro-solid-svg-icons';
import {Link} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';

const CommentTopicLine = (props) => {
    const {comment} = props;

    const navigate = useNavigate();

    const usernameClick = (e) => {
        e.stopPropagation();
        navigate("/u/"+comment.comment_source_author_username);
    }

    const itemClick = (e) => {
        e.stopPropagation();
        navigate((comment.comment_source === "deck" ? "/decks/"+comment.source_id : comment.comment_source === "text" ? "/texts/" + comment['comment_target_ISO_639-1']+"/"+comment.source_id + "/" + comment.comment_source_name : ""));
    }


    return (
        <>
            <div className="flex w-full text-xs overflow-auto flex-row place-items-center font-bold italic text-gray-600 gap-1 my-1  p-3">
                {comment.hasOwnProperty("comment_source") &&
                    <>
                        <div className="mr-4 text-burnt-sienna text-xl">
                            <FontAwesomeIcon icon={comment.comment_source === "deck" ? faCardsBlank: comment.comment_source === "text" ? faRobot : null} />
                        </div>
                    </>
                }
                <div>
                    Comment on 
                </div>
                {comment.hasOwnProperty("comment_source_name") && comment.hasOwnProperty("comment_source") && comment.hasOwnProperty("source_id") ?
                    <>
                        <div className="">
                            "<span onClick={itemClick} className="underline">{comment.comment_source_name}</span>"
                        </div>
                    </>
                    :
                    <>
                        <div className="">
                            an item
                        </div>
                    </>
                }
                {comment.hasOwnProperty("comment_source_author_displayname") && comment.hasOwnProperty("comment_source_author_username") &&
                    <>
                        <div className="">
                            by <span className="underline" onClick={usernameClick}>{comment.comment_source_author_displayname}</span>
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default CommentTopicLine;