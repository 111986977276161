import React from 'react';
import Button from '../../../../general/Button';
import Modal from '../../../../general/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/pro-duotone-svg-icons';
import { faThumbsDown } from '@fortawesome/pro-duotone-svg-icons';
import { faRotateRight } from '@fortawesome/pro-duotone-svg-icons';

const ModalContent = ({closeFunction}) => {
    return (
        <>
            <div className="overflow-auto h-72 sm:h-full p-4">
                <h1>How to play</h1>
                <p className="text-center my-2">Look at the first item shown on the screen. Do you know what the translation is? Choose the option you think is correct by either clicking or it or by typing <span className="font-bold">1</span>, <span className="font-bold">2</span>, <span className="font-bold">3</span>, or <span className="font-bold">4</span> on your keyboard. </p>
                <p className="text-center my-2">If you chose the correct option, the option will turn green. If you chose a wrong option, all wrong options will turn red and the correct option will turn green. If you don't want to answer the question, click on <span className="font-bold">Skip question</span>.</p>
                <div className="text-xl text-center font-bold text-gray-700 my-6">Keyboard shortcuts</div>
                <div className="flex flex-col place-items-center gap-2">
                    <div className="flex flex-row justify-start gap-8">
                        <div className="font-bold text-gray-700">
                            1
                        </div>
                        <div>
                            Choose first option
                        </div>
                    </div>
                    <div className="flex flex-row justify-start gap-8">
                        <div className="font-bold text-gray-700">
                            2
                        </div>
                        <div>
                            Choose second option
                        </div>
                    </div>
                    <div className="flex flex-row justify-start gap-8">
                        <div className="font-bold text-gray-700">
                            3
                        </div>
                        <div>
                            Choose third option
                        </div>
                    </div>
                    <div className="flex flex-row justify-start gap-8">
                        <div className="font-bold text-gray-700">
                            4
                        </div>
                        <div>
                            Choose fourth option
                        </div>
                    </div>
                </div>
                <div className="p-4 text-center">
                    <Button text="Continue playing" onClick={closeFunction} />
                </div>
            </div>
        </>   
    )
}

const QuizHelpModal = ({closeFunction}) => {
  return (
    <>
        <Modal closeFunction={closeFunction} content={<ModalContent closeFunction={closeFunction} />} size="3xl"/>
    </>
  )
};

export default QuizHelpModal;
