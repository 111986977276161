import {projectFirestore, timeStamp} from '../firebase/config.js';
import { useAuth } from "../contexts/AuthContext";

export default function useFavorites() {
    
    let {currentUser} = useAuth();

    const addOrRemoveDeckFavorite = async (deckData) => {
        console.log(deckData);
        let id =  deckData['typesense_deck_id'] !== undefined ? deckData['typesense_deck_id'] : deckData['id'];
        const query = projectFirestore.collection("favorites").where('deck_id', '==', id).where('uid', '==', currentUser.uid);
        let snapshot = await query.get();
        let exists = !snapshot.empty;
        if (exists){
            let ref = snapshot.docs[0].ref.id;
            return deleteDeckFromFavorites(deckData, ref);
        } else {
            return addDeckToFavorites(deckData);
        }
    }

    const deleteDeckFromFavorites = async (deckData, docRef) => {
        return projectFirestore.collection("favorites").doc(docRef).delete();
    }

    const addDeckToFavorites = async (deckData) => {
        let title = null;
        if ('title' in deckData){
            if ('name' in deckData && deckData['title'] === "" && deckData['name'] !== ""){
                title = deckData['name'];
            } else {
                title = deckData['title'];
            }
        } else if ('name' in deckData){
            title = deckData['name'];
        } else if ('deck_name' in deckData){
            title = deckData['deck_name'];
        }
        if ('id' in deckData){
            await projectFirestore.collection("favorites").add({
                'deck_id': deckData['typesense_deck_id'] !== undefined ? deckData['typesense_deck_id'] : deckData['id'],
                'deck_name': title,
                'deck_description': deckData['description'],
                'deck_created_timestamp': deckData.hasOwnProperty("created_timestamp") ? deckData['created_timestamp'] : null,
                'added_to_favorites_timestamp': timeStamp, 
                'deck_doc_id': deckData['doc_id'] !== undefined ? deckData['doc_id'] : null, 
                'uid': currentUser.uid,
                'deck_author': deckData['author'], 
                'deck_author_uid': deckData['uid'], 
                'type': 'deck', 
                'source_ISO_639-1': deckData['source_ISO_639-1'],
                'target_ISO_639-1': deckData['target_ISO_639-1']
            }).catch((err)=>{
                return false;
            });
        }
    }

    const getUserFavorites = async (data) => {
        let target = null;
        if (data !== undefined){
            if ('target' in data){
                target = data['target'];
            }
        }

        let query = projectFirestore.collection("favorites").where("uid", "==", currentUser.uid); 
        if (target !== null){
            query = query.where("target_ISO_639-1", "==", target);
        }
        let snapshot = await query.orderBy('added_to_favorites_timestamp').get();
        let favorites = [];
        let d = {};
        d['decks'] = {};
        if (!snapshot.empty){
            snapshot.docs.forEach((doc)=>{
                let o = doc.data();
                o['doc_id'] = doc.ref.id;
                favorites.push(o);
                d['decks'][doc.data().deck_id] = o;
            });
        }
        return [d, favorites];
    }

    const getUserFavoritesRealtime = async (setState) => {
        const query = projectFirestore.collection("favorites").where("uid", "==", currentUser.uid); 
        
        return query.onSnapshot((snapshot)=> {
            let favorites = [];
            let d = {};
            d['decks'] = {};
            if (!snapshot.empty){
                snapshot.docs.forEach((doc)=>{
                    let o = doc.data();
                    o['doc_id'] = doc.ref.id;
                    favorites.push(o);
                    d['decks'][doc.data().deck_id] = o;
                }); 
            }
            setState([d, favorites]);
        });
    }
    
    return {
        addDeckToFavorites, 
        getUserFavorites,  
        addOrRemoveDeckFavorite, 
        deleteDeckFromFavorites, 
        getUserFavoritesRealtime
    };
}