import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import YalangoHead from '../../assets/svg/yalango/YalangoHead.svg';
import {Link} from 'react-router-dom';
import { useAuth } from "../../contexts/AuthContext";
import { useSocialContext } from "../../contexts/SocialContext";
import useComments from '../../hooks/useComments';
import ShareItemModal from '../../components/general-modals/ShareItemModal';
import useRatings from '../../hooks/useRatings';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { faCardsBlank } from '@fortawesome/pro-solid-svg-icons';
import { faMessage } from '@fortawesome/pro-solid-svg-icons';
import { faReply } from '@fortawesome/pro-solid-svg-icons';
import { faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { faRobot } from '@fortawesome/pro-solid-svg-icons';
import CommentTopicLine from './comment-parts/CommentTopicLine';
import CommentMenu from './comment-parts/CommentMenu';
import CommentReplyOptionsRow from './comment-parts/CommentReplyOptionsRow';
import ItemRating from '../rating/ItemRating';
import CommentBodyCorrectionsRequest from './comment-parts/CommentBodyCorrectionsRequest';
import CommentBodyCorrections from './comment-parts/CommentBodyCorrections';

import { faEye } from '@fortawesome/pro-solid-svg-icons';
import CountryFlag from '../../components/languages/CountryFlag';
import {motion} from 'framer-motion';

import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { faCaretUp as faCaretUpLight } from '@fortawesome/pro-light-svg-icons';
import { faCaretDown as faCaretDownLight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WriteComment from './WriteComment';
import {useLanguagesContext} from '../../contexts/LanguagesContext';
import RenderCommentReplies from './RenderCommentReplies';

const UserComment = (props) => {
    const {comment, refreshComments, hideCommentOptions, commentLocation, displaySourceInformation} = {...props};
    const {allLanguages} = useLanguagesContext();
    const navigate = useNavigate();
    const {currentUser} = useAuth();
    const {myRatings, myRatingsFetched} = useSocialContext();
    const {deleteComment} = useComments();
    const {postRating, removeRating} = useRatings();
    const [rating, setRating] = useState(comment.hasOwnProperty("rating") ? comment.rating : 0);
    const [originalRating, setOriginalRating] = useState(comment.hasOwnProperty("rating") ? comment.rating : 0);
    const [name, setName] = useState(comment.hasOwnProperty("author_name") ? comment.author_name : "Anonymous");
    const [date, setDate] = useState(comment.hasOwnProperty("created_timestamp") ? comment.created_timestamp !== null ? comment.created_timestamp.toDate().toDateString() : "" : "");
    const [commentBody, setCommentBody] = useState(comment.comment);
    const [username, setUserName] = useState(comment.hasOwnProperty("author_username") ? comment.author_username : null);
    const [uid, setUid] = useState(comment.hasOwnProperty("uid")? comment.uid : null);
    const [userVote, setUserVote] = useState(null);
    const [showReplyBox, setShowReplyBox] = useState(false);
    console.log(comment);
    const [profilePhoto, setProfilePhoto] = useState((comment.hasOwnProperty("author_profile_photo") && comment.author_profile_photo !== null && comment.author_profile_photo !== "") ? comment.author_profile_photo : "https://storage.googleapis.com/soothing-languages.appspot.com/images/ugc/profile-photos/resized_500x500/default_user_500x500.jpeg");
    const [showReplies, setShowReplies] = useState(false);
    const [showCorrectionBox, setShowCorrectionBox] = useState(false);
    const [loading, setLoading] = useState(false);
    const [numberOfReplies, setNumberOfReplies] = useState(comment.hasOwnProperty("number_of_replies") ? comment.number_of_replies : 0);

    const [showCommentMenu, setShowCommentMenu] = useState(false);
    const [showSharingModal, setShowSharingModal] = useState(false);

    console.log(profilePhoto);
    const topicsLabels ={
        'corrections_request': "Can you correct me?",
        'introduction': "Introducing myself"
    }

    useEffect(()=>{
/*         if (myRatings !== null && comment !== null){
            myRatings.forEach((r)=>{
                if (r.hasOwnProperty("source_doc_id")){
                    if (r.source_doc_id === comment.comment_doc_id){
                        console.log(r.source_doc_id, comment.comment_doc_id);
                        setUserVote(r.rating);
                    }
                }
            })
        } */
        if (comment !== null){
            setNumberOfReplies(comment.hasOwnProperty("number_of_replies") ? comment.number_of_replies : 0);
        }
    },[myRatings, comment]);

    const deleteCommentClick = async (e) => {
        console.log("Delete ", comment.comment_doc_id);
        e.stopPropagation();
        if (currentUser === null){return null}
        await deleteComment({commentDocId: comment.comment_doc_id}).catch(err=>console.log(err));
        if (commentLocation === "original_post_page"){
            navigate("/feed");
        }
        await refreshComments();
    }
    //postRating
/*     const upvoteClick = async (e) => {
        e.stopPropagation();
        if (currentUser === null){return null}
        await postRating(comment.comment_doc_id, comment.uid, "comment", "upvote")
        .catch(err=>console.log(err))
        .then(()=>{
            let increment = 1;
            if (userVote === "downvote"){
                increment = 2;
            }
            setUserVote("upvote");
            setRating(rating + increment);
        });
    }
    const downvoteClick = async (e) => {
        e.stopPropagation();
        if (currentUser === null){return null}
        await postRating(comment.comment_doc_id, comment.uid, "comment", "downvote")
        .catch(err=>console.log(err))
        .then(()=>{
            let decrement = 1;
            if (userVote === "upvote"){
                decrement = 2;
            }
            setUserVote("downvote");
            setRating(rating - decrement);
        });
    }

    const removeRatingClick = async (e) => {
        e.stopPropagation();
        if (currentUser === null){return null}
        await removeRating(comment.comment_doc_id)
        .catch(err=>console.log(err))
        .then(()=>{
            setUserVote(null);
            setRating(userVote === "upvote" ? rating - 1 : rating + 1);
        });
    } */

    const replyClick = (e) => {
        e.stopPropagation();
        setShowReplyBox(!showReplyBox);
        setShowCorrectionBox(false);
    }

    const onReplyFinished = async () => {
        setShowReplyBox(false);
        setShowCorrectionBox(false);
        setShowReplies(true);
        setNumberOfReplies(numberOfReplies+1);
        setLoading(true);
        setLoading(false);
    }

    const showRepliesClick = (e) => {
        e.stopPropagation();
        setShowReplies(!showReplies);
    }

    const usernameClick = (e) => {
        e.stopPropagation();
        if (username !== null && username !== undefined){
            navigate("/u/"+username);
        }
    }
    
    const giveCorrectionsClick = (e) => {
        e.stopPropagation();
        setShowCorrectionBox(!showCorrectionBox);
        setShowReplyBox(false);
    }

    /*
    correct word for word
    {comment.comment_correction_submitted.split(" ").map((word, wordIndex)=>(
        <>
            {(comment.comment_correction_original.split(" ").length > wordIndex && word.replace(".", "").replace(",", "").replace(" ", "") === comment.comment_correction_original.split(" ")[wordIndex].replace(".", "").replace(",", "").replace(" ", "")) ?
                <span key={"word_"+wordIndex}>
                    {word}&nbsp; 
                </span>
            :
                <>
                    {comment.comment_correction_original.split(" ").length > wordIndex ?
                        <>
                            <span className="line-through text-burnt-sienna">
                                {comment.comment_correction_original.split(" ")[wordIndex]}&nbsp;
                            </span>
                            <span className="text-persian-green">
                                {word}&nbsp;
                            </span>
                        </>
                    :
                        <>
                            <span className=" text-persian-green">
                                {word}&nbsp;
                            </span>
                        </>
                    }
                </>
            }
        </>
                                                ))}

    */


    const goToPostPage = (e) => {
        e.stopPropagation();
        if (comment.hasOwnProperty("comment_doc_id")) {
            navigate("/post/"+comment.comment_doc_id);
        }
    }

    const shareCommentClick = () => {
        setShowSharingModal(!showSharingModal);
        setShowCommentMenu(false);
    }

    const closeSharingModal = () => {
        setShowSharingModal(false);
    }


    return (
        <>
            <div className="flex flex-col gap-3 w-full">
                <div className="border-gray-400 w-full relative pl-1 rounded-lg border-dotted">
                    <div className="absolute right-0 top-0">
                        <CommentMenu commentLocation={commentLocation} shareCommentClick={shareCommentClick} refreshComments={refreshComments} comment={comment} commentPosterUid={uid} showCommentMenu={showCommentMenu} setShowCommentMenu={setShowCommentMenu} />
                    </div>
                    <motion.div onClick={(e)=>goToPostPage(e)} class={(commentLocation === "original_post_page" ? ' text-charcoal ' : ' text-charcoal ' ) + " cursor-pointer  h-full  relative flex flex-col gap-1"}>
                        

                    {(displaySourceInformation === true && comment.hasOwnProperty("comment_source") && comment.comment_source !== "feed") && 
                            <>
                                <CommentTopicLine usernameClick={usernameClick} comment={comment} />
                            </>
                        }
                        <div className="flex py-4 flex-row place-items-center gap-5 h-full">
                            <div className="flex w-full flex-col gap-6 relative">

                                <div className="flex flex-row gap-2 justify-between">
                                    <div class="flex flex-row items-center">
                                        <div  onClick={usernameClick}  className="w-12 h-12 shadow-xl rounded-full mr-2 overflow-hidden">
                                            <img class="w-full" src={profilePhoto} alt="User Avatar" />
                                        </div>
                                        <div class="flex-col flex">
                                            <div className="flex flex-row place-items-center gap-6">
                                                <div onClick={usernameClick} class="text-base underline font-medium">
                                                    {name}
                                                </div>
                                                
                                                <div>
                                                {(allLanguages !== null && ((comment.hasOwnProperty("comment_language_feed") && comment.comment_language_feed !== "not_specified") || (comment.hasOwnProperty("comment_topic") && comment.comment_topic !== "not_specified"))) &&
                                                        <>
                                                            <div className="flex text-xs flex-row place-items-center gap-2">
                                                                {(comment.hasOwnProperty("comment_language_feed") && comment.comment_language_feed !== "not_specified") &&
                                                                    <>
                                                                    <div className="">
                                                                        <CountryFlag size="1.5em" countryCode={allLanguages[0][comment.comment_language_feed]['country_flag']} flagName={allLanguages[0][comment.comment_language_feed]['name']['en']} />
                                                                    </div>
                                                                    </>
                                                                }
                                                                {(comment.hasOwnProperty("comment_topic") && comment.comment_topic !== "not_specified") &&
                                                                    <>
                                                                    <div className="font-bold italic text-gray-600">
                                                                        {topicsLabels[comment.comment_topic]}
                                                                    </div>
                                                                    </>
                                                                }
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                            <div class="text-xs">{date}</div>
                                        </div>
                                    </div>
                                </div>
                                {comment.hasOwnProperty("design") && comment.design === "correction" 
                                    ? 
                                        <>
                                           <CommentBodyCorrections comment={comment} />
                                        </>
                                    :
                                    comment.design === "standard" ?
                                        <>
                                            <div class="whitespace-pre-wrap">
                                                {commentBody}
                                            </div>
                                        </>
                                    :
                                    comment.design === "corrections_request" ?
                                        <>
                                            <CommentBodyCorrectionsRequest comment={comment} />
                                        </>
                                    :
                                    <div class="mt-4 p-2">
                                        {commentBody}
                                    </div>
                                }
                                
                                
                                <div className="flex border-solid border-t-3 border-charcoal flex-row justify-start place-items-center p-2 text-gray-600 gap-3 sm:gap-6 text-xs sm:text-sm">
                                    <ItemRating item={comment} itemType="comment" />
                                {hideCommentOptions !== true &&
                                    <>
                                        <CommentReplyOptionsRow showRepliesClick={showRepliesClick} numberOfReplies={numberOfReplies} replyClick={replyClick} giveCorrectionsClick={giveCorrectionsClick} />
                                    </>
                                }
                            </div>
                            </div>
                        </div>
                    </motion.div>
                </div>
                {showReplyBox && 
                    <div className="ml-6 ">
                        <WriteComment inputDesign={"reply"} commentParentMetadata={comment} commentSource={comment.comment_source} commentSourceMetadata={comment} sourceId={comment.hasOwnProperty('source_id') ? comment.source_id : null} onSubmitFinished={onReplyFinished} postType={"reply"} />
                    </div>
                }
                {showCorrectionBox && 
                    <div className="ml-6 ">
                        <WriteComment inputDesign={"correction"} commentParentMetadata={comment} commentSource={comment.comment_source} commentSourceMetadata={comment} sourceId={comment.hasOwnProperty('source_id') ? comment.source_id : null} onSubmitFinished={onReplyFinished} postType={"reply"} />
                    </div>
                }
                {(showReplies && !loading && comment.hasOwnProperty("replies_doc_ids") && comment.replies_doc_ids.length > 0) && 
                    <div className="ml-6">
                        <RenderCommentReplies parentCommentDocId={comment.comment_doc_id}  />
                    </div>
                }
                {showSharingModal &&
                    <>
                        <ShareItemModal type={"comment"} item={comment} closeFunction={closeSharingModal} />
                    </>
                }
            </div>
        </>
    )
}

export default UserComment;