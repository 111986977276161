import React, { useState, useEffect, useRef } from 'react';
import Button from '../../../../general/Button';
import HanziWriter from 'hanzi-writer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faArrowAltRight, faThumbsDown, faThumbsUp } from '@fortawesome/pro-solid-svg-icons';
import CharacterBox from '../characters/CharacterBox';
import RenderChineseWords from '../../common-assets/RenderChineseWords';
import SourceTranslation from '../common-assets/SourceTranslation';

const AnimateHanziGame = (props) => {
    /*
        The cards passed into this game are already filtered in cloud function to only contain target_script = "hanzi". Make sure that this works when mixing into the mix mode as well.
    */

    

    const {
          generalHanziCurrentPinyin,
          generalHanziCurrentSource,
          generalHanziWriter,
          setGeneralHanziWriter,
          generalHanziChosenCharacter,
          generalHanziWrongClick,
          generalHanziCorrectClick,
          generalHanziGoToNextCardFunction,
          generalHanziGoToNextCard,
          generalHanziQuickGoToNextCard,
          generalHanziClearCharacterElement,
          generalHanziCreateWriter,
          generalHanziCharacterRef, 
          generalHanziActiveCharacterShown,
          reviewFinished,
          setReviewFinished,
          isPremium,
          currentCardIndex,
          setCurrentCardIndex,
          nbOfAnswers,
          setNbOfAnswers,
          cardsLeft,
          setCardsLeft,
          gameScreen,
          score,
          setScore,
          setGameScreen,
          mistakes,
          setMistakes,
          audioLoading,
          autoPlayPronunciations,
          globalHandleSkipAnswer,
          globalHandleCorrectAnswer,
          globalHandleWrongAnswer,
          isInMix,
          googleTextToSpeechClick,
          playMode,
          setPlayMode,
          showLoadingScreen,
          setShowLoadingScreen,
          cards,
          deck,
          decks,
          loadingItems,
          refreshData,
          howManyItems,
          backToPlayPageLink,
          playModeArray,
          id,
          type
  } = props;

  const [showAnswer, setShowAnswer] = useState(false);
  console.log("new cards: ", cards[currentCardIndex])

  const wrongClick = () => {
    generalHanziWrongClick();
  };

  const correctClick = () => {
    generalHanziCorrectClick();
  };

  const goToNextCardFunction = () => {
    generalHanziGoToNextCardFunction();
  }

  const goToNextCard = () => {
    generalHanziGoToNextCard();
  };

  const quickGoToNextCard = () => {
    generalHanziQuickGoToNextCard();
  }

  const createWriter = (options, additionalSetup) => {
    console.log("Creating writer")
    generalHanziCreateWriter(options, additionalSetup);
  };

  const clearCharacterElement = ()  => {
    generalHanziClearCharacterElement();
  }

  useEffect(() => {
    let quizReference = null;
    if (generalHanziChosenCharacter !== null) {
        generalHanziActiveCharacterShown.current = generalHanziChosenCharacter;
        // Define shared writer options
        const writerOptions = {
          width: 350,
          height: 350,
          padding: 20,
          showOutline: true,
          strokeAnimationSpeed: 0.5,
          delayBetweenStrokes: 10,
        };

        // Animate character and setup quiz on completion
        createWriter(writerOptions, (writer) => {
          writer.animateCharacter({
            onComplete: () => {
              setTimeout(() => {
                console.log("Animation completed: ", generalHanziChosenCharacter, generalHanziActiveCharacterShown.current)
                if (generalHanziChosenCharacter === generalHanziActiveCharacterShown.current) { // to prevent issues when fast skipping
                  createWriter({
                    ...writerOptions,
                    drawingWidth: 30,
                    showHintAfterMisses: 2,
                    showCharacter: false,
                    quizStartStrokeNum: 0,
                  }, (quizWriter) => {
                    quizReference = quizWriter;
                    quizWriter.quiz({
                      onMistake: wrongClick,
                      onCorrectStroke: correctClick,
                      onComplete: goToNextCard,
                    });
                  });
                };
                }, 700);
            }
        });
      });
    }

    return () => {
    };
  }, [generalHanziChosenCharacter]);



  return (
    <>
        <div className="h-full justify-center gap-10 2xl:gap-12 flex-col place-items-center flex">        
          <div className="flex flex-row gap-2 text-6xl">
            <RenderChineseWords text={cards[currentCardIndex].target} highlightCharacter={generalHanziChosenCharacter} highlightColor={"text-persian-green"} language="zh" data={cards[currentCardIndex]} targetOrSource="target" fontSize="text-3xl lg:text-4xl" pinyinSize="text-lg lg:text-2xl" />
          </div>
          <CharacterBox ref={generalHanziCharacterRef} />
          <div className="">
            <SourceTranslation generalHanziCurrentSource={generalHanziCurrentSource} card={cards[currentCardIndex]}  />
          </div>
          <div>
              <div className="flex justify-center gap-6 underline muted-text cursor-pointer">
                  <div onClick={() => quickGoToNextCard()}>
                    Skip character
                  </div>
              </div>
          </div>
      
      {cards !== null && cards.length === 0 &&
          <>
          <div className="text-center">
              No characters found.
          </div>
          </>
      }
      </div>
    </>
  );
};

export default AnimateHanziGame;
