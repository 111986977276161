import React, {useState, useEffect} from 'react';
import {useLanguagesContext} from '../../contexts/LanguagesContext';
import {Link} from 'react-router-dom';
import ExploreMenu from '../../components/menus/ExploreMenu';

const ForumsHome = () => {
  //loop over AllLanguages from the other files and display them in the forum as a kind of "subforum"
  //each subforum should have a link to the forum for that language
  const {allLanguages, targetLanguageOptions} = useLanguagesContext();
  const [folders, setFolders] = useState(null);

  useEffect(()=>{
    if (allLanguages !== null){
      let l = [{
        'url-suffix': "general",
        'name': 'General discussions'
      }];
      for (const [index, language] of Object.entries(allLanguages[0])){
        let o = {
          'url-suffix': language['name']['en'].replace(' ', '-'), 
          'name': language['name']['en'],
        }
        l.push(o);
      }
      console.log("Folders: ", l)
      setFolders(l);
    }
  },[allLanguages])

  return (
    <>
        <div >
            <div className="flex flex-col gap-3">
              <ExploreMenu activeName={"feed"} />
              
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
                {folders !== null && folders.map((folder, index)=>(
                    <>
                      <Link className="no-underline" key={"folder_"+index} to={"/forums/"+folder['url-suffix']}>
                      <div  className="bg-white flex flex-col gap-2 place-items-center shadow-md rounded-md p-6 m-2">
                        <div className="text-lg font-bold">{folder['name']}</div>
                      </div>
                      </Link>
                    </>
                ))}
              </div>
            </div>
        </div>
    </>
  )
}

export default ForumsHome;