import React, {useState, useEffect} from 'react';
import AddVocabularyModal from '../modals/AddVocabularyModal';
import DeckSettingsModal from '../modals/DeckSettingsModal';
import AddMultipleWordsModal from '../modals/AddMultipleWordsModal';
import Button from '../../general/Button';
import useDecks from '../../../hooks/useDecks';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { faCog } from '@fortawesome/pro-solid-svg-icons';
import { faPlay } from '@fortawesome/pro-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import { faLock } from '@fortawesome/pro-duotone-svg-icons';
import { faGlobe } from '@fortawesome/pro-duotone-svg-icons';
import { faGrid2Plus } from '@fortawesome/pro-solid-svg-icons';
import Icon from '../../../components/general/Icon';
import {DebounceInput} from 'react-debounce-input';

const CardMenu = ({editCardCustomFields, changeWasFromAddingCardRef, addNewCardToList, incrementNumberOfCards, cardsLoading, refreshCards, setCardsLoading, setEditCardCustomFields, customFields, allLanguages, sourceLanguage, setSourceLanguage, editAuthorization, editCardId, setEditCardId, targetInput, setTargetInput, sourceInput, setSourceInput, zoomMode, setZoomMode, modalMode, setShowModal, showModal, resetInputData, docs, setDocs, setModalMode, deck, deck_id}) => {

    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const [customFieldsOpen, setCustomFieldsOpen] = useState(false);
    
    const {saveChangesToDeck} = useDecks();

    const [showPrivacyBox, setShowPrivacyBox] = useState(false);

    /* SHOW ELEMENTS */
    const [showAddVocabularyDropdown, setShowAddVocabularyDropdown] = useState(false);
    const [showAddMultipleWordsModal, setShowAddMultipleWordsModal] = useState(false);
    const [showMetadata, setShowMetadata] = useState(false);

    const [wordsToAdd, setWordsToAdd] = useState([]);

    useEffect(()=>{
        if (editAuthorization === true){
            ReactTooltip.rebuild();
        }
    },[editAuthorization]);

    useEffect(() => {
        if (deck !== null){
            try {
                setSourceLanguage(deck['source_ISO_639-1']);
            }catch (error) {
                console.log(error);
                //console.log("ERROR: ", error);
            }
        }
    }, [deck, allLanguages]);

    const handleRandomizeButton = () => {
        let randomOrder = shuffle([...docs]);
        setDocs(randomOrder);
    }


    function shuffle(a) {
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
    }

    const handleAddWordButton = () => {
        setShowModal(true);
    }

    const handleAddMultipleWordsButton = () => {
        setShowAddMultipleWordsModal(true);
    }

    const showHideAddMultipleWordsModal = () => {
        setShowAddMultipleWordsModal(!showAddMultipleWordsModal);
    }

    const handleSettingsButtonClick = () => {
        setShowSettingsModal(true);
    }

    const hideSettingsModal = () => {
        setShowSettingsModal(false);
    }

    const handleShowMetadataClick = () => {
        setShowMetadata(!showMetadata);
    }

    const togglePrivacyBox = () => {
        setShowPrivacyBox(!showPrivacyBox);
    }

    const privacyOptionChanged = async (e) => {
        console.log(e.target.value);
        let newPrivacyOption = e.target.value;
        await saveChangesToDeck({privacy: newPrivacyOption, deckId: deck_id})
    }

    const renameDeckNameOnInput = async (e) => {
        console.log(e.target.value);
        await saveChangesToDeck({deckName: e.target.value.trim(), deckId: deck_id})
    }

    const deckDescriptionOnInput = async (e) => {
        console.log(e.target.value);
        await saveChangesToDeck({publicDescription: e.target.value.trim(), deckId: deck_id})
    }

    /*
                {editAuthorization && 
                    <>
                        <Link className="no-underline" to={"/my-decks/"+deck_id+"/settings"}>
                            <Button data-for="card-menu-tooltip" data-tip="Change the settings of this deck" text={""} icon={faCog} type="icon" color="charcoal" />
                        </Link>
                    </>
                }
                {editAuthorization && 
                    <>
                        <Link className="no-underline" to={"/my-decks/"+deck_id+"/import"}>
                            <Button data-for="card-menu-tooltip" data-tip="Create many cards at the same time" text={"Upload"} icon={faGrid2Plus} color="charcoal" type="icon" onClick={handleAddMultipleWordsButton} />
                        </Link>
                    </>
                }
                            <div className="flex flex-row justify-center" >
                <Link className="no-underline" to={`/my-decks/${deck_id}/play`}><Button data-for="card-menu-tooltip" data-tip="Play this deck" text={"Play"} icon={faPlay} type="icon" color="green" /> </Link>
            </div> 
    */

    //{editAuthorization && <Button data-for="card-menu-tooltip" data-tip="Add card" text={<FontAwesomeIcon icon={faPlus} />} type="icon" onClick={handleAddWordButton} onMouseEnter={()=>setShowAddVocabularyDropdown(true)} onMouseLeave={()=>setShowAddVocabularyDropdown(false)} />}
    return (
        <>
            <ReactTooltip place="bottom" id="card-menu-tooltip" effect="solid" />
            <div className="flex flex-col gap-4">
            <div className="pl-2 flex w-full flex-row justify-start place-items-center">
                <div className="flex flex-row gap-4 justify-start place-items-start">
                    <div className="text-xl relative">
                        {deck !== null &&
                            <>
                                <div onClick={togglePrivacyBox}>
                                    {(deck.privacy === "private") && 
                                        <Icon data-place="left" data-for="card-menu-tooltip"  icon={<FontAwesomeIcon icon={faLock} />} color="charcoal" hover="none" cursor="pointer" /> 
                                    }
                                    {(deck.privacy === "public") && 
                                        <Icon data-place="left" data-for="card-menu-tooltip"  icon={<FontAwesomeIcon icon={faGlobe} />} color="charcoal" hover="none" cursor="pointer" /> 
                                    }
                                </div>
                                {showPrivacyBox &&
                                    <>
                                    <div className="absolute left-0 top-8 bg-white rounded-xl w-48 shadow-xl p-5 z-20">
                                        <fieldset>
                                            <div className="flex flex-col gap-3">
                                                <div>
                                                    <div className="flex flex-row gap-2 font-bold text-base">
                                                        <input onChange={privacyOptionChanged} type="radio" id="private" value="private" checked={deck.privacy === "private" ? true : false} />
                                                        <label for="private">Private</label>
                                                    </div>
                                                    <div className="text-xs muted-text">
                                                        Only you can see this deck.
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="flex flex-row gap-2 font-bold text-base">
                                                        <input onChange={privacyOptionChanged} type="radio" id="public" value="public" checked={deck.privacy === "public" ? true : false} />
                                                        <label for="public">Public</label>
                                                    </div>
                                                    <div className="text-xs muted-text">
                                                            Everyone can find and study your deck.
                                                        </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <div onClick={togglePrivacyBox} className="z-10 w-screen h-screen fixed left-0 top-0 ">

                                    </div>
                                    </>
                                }
                            </>
                        }
                        {deck === null &&
                            <>
                                <Skeleton count={1} height={30} />
                            </>
                        }
                    </div>
                    <div className="">
                        <div className="font-bold muted-text text-lg">
                        {deck !== null ? 
                        <DebounceInput
                            className={" text-left hover:underline bg-transparent font-semibold border-none outline-none"}
                            minLength={1}
                            value={deck.name}
                            debounceTimeout={1000}
                            onChange={event => renameDeckNameOnInput(event)} 
                        /> : 
                        <Skeleton count={1} height={30} />}
                        </div>
                        
                    </div>
                    <div>
                    
                    </div>
                </div>
                
            </div>
            <div className="pl-2 muted-text text-sm w-full">
                {deck !== null ? 
                <DebounceInput
                    className={" text-left w-full hover:underline bg-transparent italic border-none outline-none"}
                    minLength={1}
                    value={deck.hasOwnProperty("description") ? deck.description : ""}
                    placeholder={"No description added yet"}
                    debounceTimeout={1000}
                    onChange={event => deckDescriptionOnInput(event)} 
                /> : 
                <Skeleton count={1} height={20} />}
            </div>
            </div>
            
            {(allLanguages !== null && Object.keys(allLanguages).length !== 0 && customFields !== null && editCardCustomFields !== null && deck !== null) &&
                <AddVocabularyModal changeWasFromAddingCardRef={changeWasFromAddingCardRef} addNewCardToList={addNewCardToList} incrementNumberOfCards={incrementNumberOfCards} setDocs={setDocs} deck={deck} editCardCustomFields={editCardCustomFields} setEditCardCustomFields={setEditCardCustomFields} customFieldsOpen={customFieldsOpen} setCustomFieldsOpen={setCustomFieldsOpen} customFields={customFields} editCardId={editCardId} setEditCardId={setEditCardId} sourceLanguage={sourceLanguage}  allLanguages={allLanguages} sourceInput={sourceInput} setSourceInput={setSourceInput} setTargetInput={setTargetInput} targetInput={targetInput} deck_id={deck_id} showModal={showModal} setShowModal={setShowModal} modalMode={modalMode} setModalMode={setModalMode} docs={docs} />
            }

            {showSettingsModal === true &&
                <>
                    {(allLanguages !== null && deck !== null) && Object.keys(allLanguages).length !== 0 &&
                        sourceLanguage !== "" &&
                            <DeckSettingsModal customFields={customFields} closeFunction={hideSettingsModal} deck={deck} deck_id={deck_id} setShowSettingsModal={setShowSettingsModal} allLanguages={allLanguages} sourceLanguage={sourceLanguage} setSourceLanguage={setSourceLanguage} />
                    }
                </>
            }

            {(showAddMultipleWordsModal === true && deck !== null && allLanguages !== null) &&
                <>
                    <AddMultipleWordsModal setDocs={setDocs} docs={docs} setCardsLoading={setCardsLoading} cardsLoading={cardsLoading} refreshCards={refreshCards} deck={deck} allLanguages={allLanguages} sourceLanguage={sourceLanguage} wordsToAdd={wordsToAdd} setWordsToAdd={setWordsToAdd} showHideAddMultipleWordsModal={showHideAddMultipleWordsModal} />
                </>
            }

            
        </> 
    )
}

export default CardMenu;
