import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import { projectAuth } from '../../firebase/config';
import Button from '../../components/general/Button';
import useQueryStrings from '../../hooks/useQueryStrings';
import NormalTextInput from '../../components/general/input/NormalTextInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';

const AuthHandler = () => {
  const {getQueryStrings} = useQueryStrings();

  const [mode, setMode] = useState(null);
  const [oobCode, setOobCode] = useState(null);
  const [apiKey, setApiKey] = useState(null);
  const [continueUrl, setContinueUrl] = useState(null);
  const [error, setError] = useState(false);
  const [verifySuccess, setVerifySuccess] = useState(null);
  const [verifyError, setVerifyError] = useState(false);
  const [userEmail, setUserEmail] = useState(null);
  const [showResetPasswordField, setShowResetPasswordField] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [newPasswordSuccess, setNewPasswordSuccess] = useState(false);
  const [loading, setLoading] = useState(true);

  const newPasswordOnChange = (e) => {
    setNewPassword(e.target.value);
  }

  // Params loading
  useEffect(()=>{
      const fetchData = async () => {
        let r = await getQueryStrings();
        if (r === undefined || r === null){return null}
        let acceptableModes = ["resetPassword", "verifyEmail"];
        if ("mode" in r){
          if (acceptableModes.includes(r['mode'])){
            setMode(r['mode'])
          }
          else {
            setError(true);
          }
        }
        if ('oobCode' in r){
          setOobCode(r['oobCode']);
        }
        if ('apiKey' in r){
          setApiKey(r['apiKey']);
        }
        if ('continueUrl' in r){
          setContinueUrl(r['continueUrl']);
        }
      }
      fetchData();
  },[]);

  useEffect(()=>{
    const handleVerifyEmail = async () => {
      await projectAuth.applyActionCode(oobCode).then((resp) => {
        setVerifySuccess(true);
      }).catch((error) => {
        setVerifyError(true);
        setVerifySuccess(false);
      });
      setLoading(false);
    }

    const handleResetPassword = async () => {
      await projectAuth.verifyPasswordResetCode(oobCode).then((email) => {
        setShowResetPasswordField(true);
        setUserEmail(email);
      }).catch((error) => {
        setPasswordError(true);
      });    
      setLoading(false);
    }

    if (mode === "verifyEmail" && oobCode !== null){
      handleVerifyEmail();
    }

    if (mode === "resetPassword" && oobCode !== null){
      handleResetPassword();
    }
  },[mode, oobCode]);

  const handleSaveNewPassword = async () => {
    setLoading(true);
    await projectAuth.confirmPasswordReset(oobCode, newPassword).then((resp) => {
      setShowResetPasswordField(false);
      setNewPasswordSuccess(true);
      setPasswordError(false);
      setNewPasswordError(false);
    }).catch((error) => {
      setNewPasswordError(true);
    });
    setLoading(false);
  }

  return (
    <>
      <div className="  relative h-screen">
        {loading === true &&
          <>
            <div className="z-30 absolute text-3xl opacity-80 top-1/2 h-full left-1/2">
              <FontAwesomeIcon className={"fa-pulse"} icon={faSpinner} />
            </div>
          </>
        }
        {mode === "resetPassword" &&
          <>
            <div className="flex flex-col justify-center h-full gap-3">
              <h1>Reset your password</h1>
              <div className="flex flex-col place-items-center gap-3">
                {showResetPasswordField === true &&
                  <>
                    <div className="w-72 opacity-70 cursor-not-allowed">
                      <NormalTextInput value={userEmail} disabled={true} />
                    </div>
                    <div className="w-72">
                      <NormalTextInput type="password" autoFocus={true} placeHolder={"New password"} value={newPassword} onChange={newPasswordOnChange} />
                    </div>
                    <div>
                        <Button onClick={handleSaveNewPassword} text={"Save new password"} color="green" />
                    </div>
                  </>
                }
                {newPasswordSuccess === true &&
                  <>
                    <div>
                      Your password has been successfully updated. You can now log in with your new password.
                    </div>
                    <div>
                      <Link to={"/login"} className="no-underline">
                        <Button text={"Log in"} color="green" />
                      </Link>
                    </div>
                  </>
                }
                {newPasswordError === true &&
                  <>
                    <div>
                      Something went wrong. This might be due to the activation code being inactive or invalid, or your new password being too weak. 
                    </div>
                  </>
                }
                {passwordError === true &&
                  <>
                    <div>
                      The activation code is inactive or invalid. Please try to <Link to="/login/forgotten-password">reset</Link> your password again.
                    </div>
                  </>
                }
              </div>
            </div>
          </>
        }
        {mode === "verifyEmail" &&
          <>
            <div className="flex flex-col gap-3">
              <h1>Verify your email</h1>
              <div className="flex flex-col place-items-center gap-3">
                {verifySuccess === true &&
                  <>
                    <div>
                      Your email has been successfully verified.
                    </div>
                    <div >
                      <Link to={"/dashboard"} className="no-underline">
                        <Button text={"Go to dashboard"} color="green" />
                      </Link>
                    </div>
                  </>
                }
                {verifyError === true &&
                  <>
                    <div>
                      The activation code is inactive or invalid. Please try to <Link to="/settings/account">verify</Link> your email address again.
                    </div>
                  </>
                }
              </div>
            </div>
          </>
        }
        {error === true &&
          <>
            <div>
              Something went wrong.
            </div>
          </>
        }
      </div>
    </>
  )
}

export default AuthHandler;