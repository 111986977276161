import React, { useState, useEffect, useRef } from 'react';
import Button from '../../../general/Button';
import HanziWriter from 'hanzi-writer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faArrowAltRight, faThumbsDown, faThumbsUp } from '@fortawesome/pro-solid-svg-icons';
import CharacterBox from './characters/CharacterBox';
import WriteHanziGame from './write-hanzi/WriteHanziGame';
import AnimateHanziGame from './animate-hanzi/AnimateHanziGame';
import StrokeOrderHanziGame from './stroke-order-hanzi/StrokeOrderHanziGame';

const HanziGames = (props) => {
    /*
        The cards passed into this game are already filtered in cloud function to only contain target_script = "hanzi". Make sure that this works when mixing into the mix mode as well.
    */
  const {hanziGame, reviewFinished, setReviewFinished, isPremium, currentCardIndex, setCurrentCardIndex, nbOfAnswers, setNbOfAnswers, cardsLeft, setCardsLeft, gameScreen, score, setScore, setGameScreen, mistakes, setMistakes, audioLoading, autoPlayPronunciations, globalHandleSkipAnswer, globalHandleCorrectAnswer, globalHandleWrongAnswer, isInMix, googleTextToSpeechClick, playMode, setPlayMode, showLoadingScreen, setShowLoadingScreen, cards, deck, decks, loadingItems, refreshData, howManyItems, backToPlayPageLink, playModeArray, id, type} = props;
  const [showAnswer, setShowAnswer] = useState(false);
  const generalHanziCharacterRef = useRef();
  const generalHanziActiveCharacterShown = useRef(null);
  const [generalHanziWriter, setGeneralHanziWriter,] = useState(null); // State to hold the HanziWriter instance
  const [generalHanziCurrentPinyin, setGeneralHanziCurrentPinyin] = useState(null);
  const [generalHanziCurrentSource, setGeneralHanziCurrentSource] = useState(null);
  const [generalHanziChosenCharacter, setGeneralHanziChosenCharacter] = useState(null);


  const generalHanziWrongClick = () => {
    setNbOfAnswers(prevNbOfAnswers => prevNbOfAnswers + 1);
  };

  const generalHanziCorrectClick = () => {
    setScore(prevScore => prevScore + 1);
    setNbOfAnswers(prevNbOfAnswers => prevNbOfAnswers + 1);
  };

  const generalHanziGoToNextCardFunction = () => {
    if (currentCardIndex < cards.length - 1) {
      // Prepare for the next character by clearing the writer instance and variables
      setGeneralHanziWriter(null); 
      setGeneralHanziCurrentSource(null);
      setGeneralHanziCurrentPinyin(null); 
      setGeneralHanziChosenCharacter(null);

      setCurrentCardIndex(currentCardIndex + 1);
      setCardsLeft(cardsLeft - 1);
      } 
      else {
        setGameScreen("endscreen");
      }

  }

  const generalHanziGoToNextCard = () => {
    setTimeout(() => {
        generalHanziGoToNextCardFunction();
    }, 1500);
  };

  const generalHanziQuickGoToNextCard = () => {
    generalHanziGoToNextCardFunction();
  }

  const generalHanziClearCharacterElement = () => {
    const { current: characterElement } = generalHanziCharacterRef;
    if (characterElement) {
      while (characterElement.firstChild) {
        characterElement.removeChild(characterElement.firstChild);
      }
    }
  };

  const generalHanziCreateWriter = (options, additionalSetup) => {
    generalHanziClearCharacterElement();
    const { current: characterElement } = generalHanziCharacterRef;
    if (characterElement && generalHanziChosenCharacter) {
      const writer = HanziWriter.create(characterElement, generalHanziChosenCharacter, options);
      if (additionalSetup) {
        additionalSetup(writer);
      }
      return writer;
    }
  };

  useEffect(() => {
    if (!loadingItems && !showLoadingScreen && deck["target_ISO_639-1"] == "zh" && cards?.length > 0 && currentCardIndex < cards.length) {

      function getRandomInt(min, max) {
        min = Math.ceil(min); // Ensure the minimum is rounded up to the nearest whole number
        max = Math.floor(max); // Ensure the maximum is rounded down to the nearest whole number
        return Math.floor(Math.random() * (max - min + 1)) + min; // The maximum is inclusive and the minimum is inclusive
      }

      const characters = cards[currentCardIndex].target.split("");
      const randomIndex = getRandomInt(0, characters.length - 1);;
      const chosenCharacter_ = characters[randomIndex];
      setGeneralHanziChosenCharacter(chosenCharacter_);
      if (cards[currentCardIndex].hasOwnProperty("target_pinyin_basic") !== null) {
        setGeneralHanziCurrentPinyin(cards[currentCardIndex].target_pinyin_basic[randomIndex]);
      }

      if (cards[currentCardIndex].hasOwnProperty("source") !== null) {
        setGeneralHanziCurrentSource(cards[currentCardIndex].source);
      }

    }
  }, [loadingItems, showLoadingScreen, cards, currentCardIndex, deck]);


  const generalHanziVariables = {
    generalHanziWrongClick, 
    generalHanziCorrectClick, 
    generalHanziGoToNextCardFunction, 
    generalHanziGoToNextCard, 
    generalHanziQuickGoToNextCard, 
    generalHanziChosenCharacter, 
    generalHanziWriter, 
    setGeneralHanziWriter, 
    generalHanziCurrentPinyin,
    generalHanziCurrentSource, 
    generalHanziClearCharacterElement, 
    generalHanziCreateWriter, 
    generalHanziCharacterRef, 
    generalHanziActiveCharacterShown
  }

  return (
    <>
      {(loadingItems === false && showLoadingScreen === false) &&
        <>
            <div className="min-h-screen h-full pb-16  justify-center gap-6 flex-col flex ">
                {deck["target_ISO_639-1"] == "zh" &&
                    <>                    
                    {cards !== null && cards.length === 0 ?
                        <>
                            <div className="text-center">
                                No characters found.
                            </div>
                        </>
                        :
                        <>
                            {hanziGame === "write-hanzi" &&
                                <>
                                    <WriteHanziGame {...{
                                        ...props, 
                                        ...generalHanziVariables
                                        }} 
                                    />
                                </>
                            }
                            {hanziGame === "animate-hanzi" &&
                                <>
                                    <AnimateHanziGame {...{
                                         ...props, 
                                         ...generalHanziVariables
                                        }} 
                                    />
                                </>
                            }
                             {hanziGame === "stroke-order-hanzi" &&
                                <>
                                    <StrokeOrderHanziGame {...{
                                         ...props, 
                                         ...generalHanziVariables
                                        }} 
                                    />
                                </>
                            }
                        </>
                    }
                    
                   
                    </>
                }
                 {deck["target_ISO_639-1"] != "zh" &&
                        <>
                        <div className="text-center">
                            This game only works for Mandarin Chinese at the moment.
                        </div>
                    </>
                    }
            </div>
        </>
      }
    </>
  );
};

export default HanziGames;
