import React, {useState, useEffect} from 'react';
import LanguageSelector from '../components/languages/LanguageSelector';
import {firebase, projectStorage, projectFirestore} from '../firebase/config.js';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/pro-solid-svg-icons';
import { faInfo } from '@fortawesome/pro-light-svg-icons';

import Icon from '../components/general/Icon';
import { useAuth } from "../contexts/AuthContext";

/* const Input = (props) => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        //console.log(props);
      }
    }
    return <input type="text" value={props.value} onChange={handleKeyDown} />
} */

const deleteEnabled = false;

const DecksCard = ({currentUser, items, selectedItem, setSelectedItem, categories, selectedCategory, selectedLanguage, sourceLanguage}) => {
    const [newInputValue, setNewInputValue] = useState("");
    const [showNewInput, setShowNewInput] = useState(false);
    
    const [editItemId, setEditItemId] = useState(-1);
    const [editItemName, setEditItemName] = useState("");

    const addDeckIdToCategory = (deck_id) => {
        //console.log("Deck id", deck_id);
        const collectionRef = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('decks').collection('categories'); 
        const docRef = collectionRef.where('category_id', '==', selectedCategory);
        docRef.get().then(snapshots => {
            if (snapshots.size === 1){
                snapshots.forEach(snapshot => {
                    let doc_id = snapshot.id;
                    let updateRef = collectionRef.doc(doc_id);
                    updateRef.update({
                        'decks.id': firebase.firestore.FieldValue.arrayUnion(deck_id), 
                        [`decks.favorite.${deck_id}`]: false
                    });      
                });
            }
        }); 
    }

    const removeDeckIdFromCategory = (deck_id) => {
        const collectionRef = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('decks').collection('categories'); 
        const docRef = collectionRef.where('category_id', '==', selectedCategory);
        docRef.get().then(snapshots => {
            if (snapshots.size === 1){
                snapshots.forEach(snapshot => {
                    let doc_id = snapshot.id;
                    let updateRef = collectionRef.doc(doc_id);
                    updateRef.update({
                        'decks.id': firebase.firestore.FieldValue.arrayRemove(deck_id), 
                        [`decks.favorite.${deck_id}`]: firebase.firestore.FieldValue.delete()
                    });      
                });
            }
        }); 
    }
    

    const handleAddItem = () => {
        const collectionRef = projectFirestore.collection('decks'); 
        let generator = 100000000000;
        let id = Math.floor(Math.random() * generator);
        const checkIfExists = (id) => {
            collectionRef.where('id', '==', id).get().then(function(querySnapshot) {
                if (!querySnapshot.empty) {
                    id = Math.floor(Math.random() * generator);
                    checkIfExists(id);
                }
                else {
                    if (newInputValue.length>0){
                        collectionRef.add({
                            'name': newInputValue, 
                            'uid': currentUser.uid,
                            'id': id, 
                            'source_ISO_639-1': sourceLanguage,
                            'target_ISO_639-1': selectedLanguage, 
                            'privacy': 'private', 
                            'description': '', 
                            'title': '', 
                            'tags': ''
                        });
                    }
                    
                    addDeckIdToCategory(id);
                    setNewInputValue("");
                    setShowNewInput(false);
                }
            }); 
        }
        checkIfExists(id);
    }

    const deleteItemClick = (e) => {
        if (deleteEnabled) {
            let id = parseInt(e.currentTarget.getAttribute('data-id'));
            const collectionRef = projectFirestore.collection('decks'); 
            const docRef = collectionRef.where('id', '==', id);
            docRef.get().then(snapshots => {
                if (snapshots.size === 1){
                    snapshots.forEach(snapshot => {
                        let doc_id = snapshot.id;
                        let deleteRef = collectionRef.doc(doc_id);
                        deleteRef.delete();   
                    });
    
                    // DELETE DECK ID FROM CATEGORY ARRAY
                    removeDeckIdFromCategory(id);
                }
            }); 
        }
        else {
            //console.log("Delete functionaly disabled!");
        }

    }
    

    const handleAddItemClick = () => {
        if (showNewInput === true) {
            if (newInputValue.length > 0){
                handleAddItem();
            }
        }
        else {
            setShowNewInput(true);
        }
    }

    const changeNewInputValue = (e) => {
        setNewInputValue(e.target.value.trim());
    }

    const checkIfInputEnter = (e) => {
        if (e.keyCode === 13){
            handleAddItem();
        }
    }

    const handleCancelAddItemClick = () => {
        setShowNewInput(false);
    }

    const handleItemClick = (e) => {
        const selected_id = parseInt(e.currentTarget.getAttribute('data-id'));
        setSelectedItem(selected_id);
    }

    const editItemNameOnChange = (e) => {
        setEditItemName(e.currentTarget.value);
    }

    const categoryData = categories.filter( x =>  x['category_id'] === selectedCategory )[0];

    return (
         
        <div className="shadow rounded-lg">
            <div className="">
                <h3 className="text-center rounded-sm p-3">Decks</h3>
            </div>
            <div className="bg-gray-50">
                {typeof(categoryData) !== 'undefined' &&
                    items.map(item => (
                        <CardRow currentUser={currentUser} selectedLanguage={selectedLanguage} setSelectedItem={setSelectedItem} setEditItemId={setEditItemId} setEditItemName={setEditItemName} selectedCategory={selectedCategory} categories={categories} editItemId={editItemId} deleteItemClick={deleteItemClick} setEditItemId={setEditItemId} checkIfInputEnter={checkIfInputEnter} editItemName={editItemName} editItemNameOnChange={editItemNameOnChange} item={item} key={item.id+"_deck_row"} selectedItem={selectedItem} handleItemClick={handleItemClick} />
                    ))
                }
                
    
            </div>
        </div>
    )
}

const CardRow = ({currentUser, item, selectedLanguage, selectedCategory, categories, editItemId, setEditItemId, deleteItemClick, checkIfInputEnter, editItemName, setEditItemName, editItemNameOnChange, handleItemClick, selectedItem, setSelectedItem}) => {
    const categoryData = categories.filter( x =>  x['category_id'] === selectedCategory )[0];
    ////console.log(categoryData);

    const markAsFavorite = (e) => {
        let id = parseInt(e.currentTarget.getAttribute('data-id'));
        const collectionRef = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('decks').collection('categories'); 
        const docRef = collectionRef.where('decks.id', 'array-contains', id);
        docRef.get().then(snapshots => {
            if (snapshots.size === 1){
                snapshots.forEach(snapshot => {
                    let doc_id = snapshot.id;
                    let old_value = snapshot.data()['decks']['favorite'][id];
                    let updateRef = collectionRef.doc(doc_id);
                    updateRef.update({
                        [`decks.favorite.${id}`]: !old_value, 
                    });
                });
            }
        }); 
    }

    const addFavoriteFieldToItem = (item) => {
        let id = item.id;
        const collectionRef = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('decks').collection('categories'); 
        const docRef = collectionRef.where('decks.id', 'array-contains', id);
        const new_item = {...item};
        docRef.get().then(snapshots => {
            if (snapshots.size === 1){ 
                snapshots.forEach(snapshot => {
                    let old_value = snapshot.data()['decks']['favorite'][id];
                    new_item['favorite'] = old_value;  
                });
            }
        }); 
        return new_item;
    }

    const editItemInDatabase = () => {
        const collectionRef = projectFirestore.collection('decks'); 
        const docRef = collectionRef.where('id', '==', editItemId);
        docRef.get().then(snapshots => {
            if (snapshots.size === 1){
                snapshots.forEach(snapshot => {
                    if (editItemName.length>0){
                        let doc_id = snapshot.id;
                        let updateRef = collectionRef.doc(doc_id);
                        updateRef.update({
                            'name': editItemName, 
                        });
                    }
                });
                setEditItemName("");
                setEditItemId(-1);
            }
        }); 
    }

    const editItemClick = (e) => {
        let id = parseInt(e.currentTarget.getAttribute('data-id'));
        let name = e.currentTarget.getAttribute('data-name');

        setEditItemName(name);
        setEditItemId(id);   
    }

    const checkIfEditItemInputEnter = (e) => {
        if (e.keyCode === 13){
            editItemInDatabase();
        }
    }

    const saveChangesToDatabase = () => {
        editItemInDatabase();
    }

    const selectDeckIfUnselected = () => {
        if (typeof categoryData['decks'] !== 'undefined'){
            if (categoryData['decks']['id'].includes(item.id)){
                if (selectedItem === -1){
                    setSelectedItem(item.id);
                }
            }
        }
    }

    selectDeckIfUnselected();
    
    return (
        <>
            {item['target_ISO_639-1'] === selectedLanguage &&
                typeof categoryData['decks'] !== 'undefined' ? (categoryData['decks']['id'].includes(item.id) &&
                    selectedCategory !== -1 &&
                        <div key={item.id} data-id={item.id} onClick={handleItemClick}>
                        <div className={"group flex flex-row cursor-pointer justify-between hover:bg-gray-100 " + ((item.id === selectedItem) ? 'bg-gray-100' : '')} >
                            <div className="px-5 w-5/6 py-2" onDoubleClick={editItemClick} data-id={item.id} data-name={item.name}>
                                { editItemId === item.id 
                                ? <input autoFocus className="appearance-none px-1 bg-transparent border-none focus:outline-none" onBlur={saveChangesToDatabase} type="text" value={editItemName} onChange={editItemNameOnChange} onKeyDown={checkIfEditItemInputEnter} /> 
                                : <Link to={`/decks/${item.id}`}><span> <span className="group">{item.name.length > 20 ? item.name.substr(0,20)+'...' : item.name}</span></span></Link>
                                }
                            </div>
                            <div className={"px-5 py-2  " + ((item.id !== selectedItem) ? 'opacity-0' : '') + ' group-hover:opacity-100'}>
                                <div className="flex flex-row justify-between gap-3 ">
                                    <div data-id={item.id} className="">
                                        <Link to={`/decks/${item.id}/play`}><Icon icon={<FontAwesomeIcon icon={faPlay} />} color="green" /></Link>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                ) : null  
            }
        </>
    )
}

const Categories = ({currentUser, selectedLanguage, updateCategoryNameInDatabase, setSelectedCategory, categories, setEditCategoryInput, editCategoryName, checkIfEditCategoryNameInputEnter, setShowNewCategoryInput, handleAddCategory, editCategoryNameOnChange, editCategoryInput, editCategoryClick, selectedCategory, selectedSection, handleCategoryClick, showNewCategoryInput, changeNewCategoryInputValue, newCategoryButtonText, handleAddCategoryClick}) => {

    const checkIfCategoryInputEnter = (e) => {
        if (e.keyCode === 13){
            handleAddCategory();
        }
    }

    const handleCloseAddCategoryClick = () => {
        setShowNewCategoryInput(false);
    }

    return (
         
        <div className="shadow rounded-lg">
            <div className="">
                <h3 className=" text-center rounded-sm p-3">Categories</h3>
            </div>
            <div className="bg-gray-50">
            
                { categories.map(category => (
                    category.section_id === selectedSection &&
                    <CategoryRow currentUser={currentUser} selectedLanguage={selectedLanguage} setSelectedCategory={setSelectedCategory} updateCategoryNameInDatabase={updateCategoryNameInDatabase} setEditCategoryInput={setEditCategoryInput} checkIfEditCategoryNameInputEnter={checkIfEditCategoryNameInputEnter} editCategoryName={editCategoryName} editCategoryNameOnChange={editCategoryNameOnChange} editCategoryInput={editCategoryInput} category={category} editCategoryClick={editCategoryClick} key={category.category_id+"_category_row"} selectedCategory={selectedCategory} handleCategoryClick={handleCategoryClick} />
                ))}
            </div>
        </div>
    )
}

const CategoryRow = ({currentUser, category,selectedLanguage, updateCategoryNameInDatabase, setEditCategoryInput, editCategoryName, checkIfEditCategoryNameInputEnter, editCategoryNameOnChange, editCategoryInput, editCategoryClick, selectedCategory,setSelectedCategory, handleCategoryClick}) => {

    const deleteCategoryClick = (e) => {
        if (deleteEnabled) {
            let category_id = parseInt(e.currentTarget.getAttribute('data-id'));
            //console.log("Deleting", category_id);
            const collectionRef = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('decks').collection('categories'); 
            const docRef = collectionRef.where('category_id', '==', category_id);
            docRef.get().then(snapshots => {
                if (snapshots.size === 1){
                    snapshots.forEach(snapshot => {
                        //console.log(snapshot);
                        let doc_id = snapshot.id;
                        let deleteRef = collectionRef.doc(doc_id);
                        deleteRef.delete();   
                    });
                }
            }); 
        }
        else {
            //console.log("Delete functionaly disabled!");
        }
    }

    const markAsFavorite = (e) => {
        let category_id = parseInt(e.currentTarget.getAttribute('data-id'));
        const collectionRef = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('decks').collection('categories'); 
        const docRef = collectionRef.where('category_id', '==', category_id);
        docRef.get().then(snapshots => {
            if (snapshots.size === 1){
                snapshots.forEach(snapshot => {
                    let doc_id = snapshot.id;
                    let old_value = snapshot.data()['favorite'];
                    let updateRef = collectionRef.doc(doc_id);
                    updateRef.update({
                        'favorite': !old_value, 
                    });      
                });
            }
        }); 
    }

    const saveChangesToDatabase = () => {
        updateCategoryNameInDatabase();
    }

    const selectCategoryIfUnselected = () => {
        if (selectedCategory === -1){
            setSelectedCategory(category.category_id);
        }
    }

    return (
        <>
            {category['target_ISO_639-1'] === selectedLanguage &&
                <div key={category.category_id} data-id={category.category_id} onClick={handleCategoryClick}>
                    <div className={"group flex flex-row cursor-pointer justify-between hover:bg-gray-100 " + ((category.category_id === selectedCategory) ? 'bg-gray-100' : '')} >
                        <div className="px-5 w-5/6 py-2" onDoubleClick={editCategoryClick} data-id={category.category_id} data-name={category.name}>
                            { editCategoryInput === category.category_id 
                            ? <input autoFocus className="appearance-none px-1 bg-transparent border-none focus:outline-none" onBlur={saveChangesToDatabase} type="text" value={editCategoryName} onChange={editCategoryNameOnChange} onKeyDown={checkIfEditCategoryNameInputEnter} /> 
                            : <Link to={`/decks/category/${category.category_id}`}><span> <span>{category.name.substr(0, 30)}</span></span></Link>
                            }
                        </div>
                        <div className={"px-5 py-2  " + ((category.category_id !== selectedCategory) ? 'opacity-0' : '') + ' group-hover:opacity-100'}>
                            <div className="flex flex-row justify-between gap-3 ">
                                <div data-id={category.category_id} className="cursor-pointer opacity-50 hover:opacity-100 text-green-600">
                                    <Link to={`/decks/category/${category.category_id}/play`}>
                                        <Icon icon={<FontAwesomeIcon icon={faPlay} />} color="green" />
                                    </Link>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

const SectionRows = ({currentUser, selectedLanguage, sections, updateSectionNameInDatabase, setEditSectionInput, checkIfEditSectionNameInputEnter, editSectionName,editSectionNameOnChange, editSectionInput, editSectionClick, handleCloseAddSectionClick, showNewSectionInput, checkIfSectionInputEnter, changeNewSectionInputValue, handleAddSectionClick, selectedSection, handleSectionClick}) => {
    return(
    <>
        
            { sections.map(section => (
                <SectionRow currentUser={currentUser} selectedLanguage={selectedLanguage} updateSectionNameInDatabase={updateSectionNameInDatabase} setEditSectionInput={setEditSectionInput} checkIfEditSectionNameInputEnter={checkIfEditSectionNameInputEnter} editSectionName={editSectionName} editSectionNameOnChange={editSectionNameOnChange} editSectionInput={editSectionInput} section={section} editSectionClick={editSectionClick} key={section.section_id+"_section_row"} selectedSection={selectedSection} handleSectionClick={handleSectionClick} />
            ))}
        
    </>
    )
}

const SectionRow = ({currentUser, section, selectedLanguage, updateSectionNameInDatabase, setEditSectionInput, editSectionName, checkIfEditSectionNameInputEnter, editSectionNameOnChange, editSectionInput, editSectionClick, selectedSection, handleSectionClick}) => {

    const deleteSectionClick = (e) => {
        if (deleteEnabled) {
            let section_id = parseInt(e.currentTarget.getAttribute('data-id'));
            //console.log("Deleting", section_id);
            const collectionRef = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('decks').collection('sections'); 
            const docRef = collectionRef.where('section_id', '==', section_id);
            docRef.get().then(snapshots => {
                if (snapshots.size === 1){
                    snapshots.forEach(snapshot => {
                        let doc_id = snapshot.id;
                        let deleteRef = collectionRef.doc(doc_id);
                        deleteRef.delete();   
                    });
                }
            }); 
        }
        else {
            //console.log("Delete functionality disabled!");
        }
    }

    const markAsFavorite = (e) => {
        let section_id = parseInt(e.currentTarget.getAttribute('data-id'));
        const collectionRef = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('decks').collection('sections'); 
        const docRef = collectionRef.where('section_id', '==', section_id);
        docRef.get().then(snapshots => {
            if (snapshots.size === 1){
                snapshots.forEach(snapshot => {
                    let doc_id = snapshot.id;
                    let old_value = snapshot.data()['favorite'];
                    let updateRef = collectionRef.doc(doc_id);
                    updateRef.update({
                        'favorite': !old_value, 
                    });      
                });
            }
        }); 
    }

    const saveChangesToDatabase = () => {
        updateSectionNameInDatabase();
    }

    return (
        <>
            { section['target_ISO_639-1'] === selectedLanguage && 
                <div key={section.section_id} data-id={section.section_id} onClick={handleSectionClick}>
                    <div className={"group flex flex-row cursor-pointer justify-between hover:bg-gray-100 " + ((section.section_id === selectedSection) ? 'bg-gray-100' : '')} >
                        <div className="px-5 w-5/6 py-2" onDoubleClick={editSectionClick} data-id={section.section_id} data-name={section.name}>
                            { editSectionInput === section.section_id 
                            ? <input autoFocus className="appearance-none px-1 bg-transparent border-none focus:outline-none" onBlur={saveChangesToDatabase} type="text" value={editSectionName} onChange={editSectionNameOnChange} onKeyDown={checkIfEditSectionNameInputEnter} /> 
                            : <Link to={`/decks/section/${section.section_id}`}><span> <span>{section.name.substr(0, 30)}</span></span></Link>
                            }
                        </div>
                        <div className={"px-5 py-2  " + ((section.section_id !== selectedSection) ? 'opacity-0' : '') + ' group-hover:opacity-100'}>
                            <div className="flex flex-row justify-between gap-3 ">
                                <div data-id={section.section_id} className="cursor-pointer opacity-50 hover:opacity-100 text-green-600">
                                    <Link to={`/decks/section/${section.section_id}/play`}>
                                        <Icon icon={<FontAwesomeIcon icon={faPlay} />} color="green" />
                                    </Link>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

const NewSectionInput = ({showNewSectionInput, changeNewSectionInputValue}) => {
    return (
        <>
            {showNewSectionInput && <input type="text" onChange={changeNewSectionInputValue} />} 
        </>
    )
}

const Decks = () => {

    let {currentUser} = useAuth();
    //console.log(currentUser.uid);

    /* Fetch data once in beginning */
    const [sections, setSections] = useState([]);
    const [categories, setCategories] = useState([]);
    const [decks, setDecks] = useState([]);

    const [selectedSection, setSelectedSection] = useState(-1);
    const [selectedCategory, setSelectedCategory] = useState(-1);

    const [showNewSectionInput, setShowNewSectionInput] = useState(false);
    const [showNewCategoryInput, setShowNewCategoryInput] = useState(false);

    const [newSectionButtonText, setNewSectionButtonText] = useState("Add section");
    const [newCategoryButtonText, setNewCategoryButtonText] = useState("Add category");

    const [newSectionInputValue, setNewSectionInputValue] = useState("");
    const [newCategoryInputValue, setNewCategoryInputValue] = useState("");

    const [editSectionInput, setEditSectionInput] = useState(-1);
    const [editSectionName, setEditSectionName] = useState("");

    const [editCategoryInput, setEditCategoryInput] = useState(-1);
    const [editCategoryName, setEditCategoryName] = useState("");

    const [userLanguages, setUserLanguages] = useState([]);
    const [allLanguages, setAllLanguages] = useState({});
    const [allLanguageCodes, setAllLanguageCodes] = useState([]);

    const [selectedLanguage, setSelectedLanguage] = useState("");
    const [sourceLanguage, setSourceLanguage] = useState("");

    const [selectedItem, setSelectedItem] = useState(-1);

    const [loading, setLoading] = useState(true);

    useEffect(() => {

        const plusUid = "sqLkHmGgtpdVeOt45iJZfccHhpy2";

        // IMPORTANT: THIS USER UID BELONGS TO Yalango ADMIN ACCOUNT
        const docRef = projectFirestore.collection('users').doc(plusUid).collection('private-data').doc('decks'); 
        const categoryRef = docRef.collection('categories');
        const sectionRef = docRef.collection('sections');
        const decksRef = projectFirestore.collection('decks');

        categoryRef.orderBy('favorite', 'desc').onSnapshot((docs) => {
            let categoryData = [];
            docs.forEach((doc) => {
                categoryData.push(doc.data());
            });
            if (categoryData.length > 0){
                setCategories(categoryData);
                //setSelectedCategory(categoryData[0]['category_id']);
            } else {
                setCategories([]);
                //setSelectedCategory(-1);
            };
        });

        sectionRef.orderBy('favorite', 'desc').onSnapshot((docs) => {
            let sectionData = [];
            docs.forEach((doc) => {
                sectionData.push(doc.data());
            });
            //console.log(sectionData.length);
            if (sectionData.length > 0){
                setSections(sectionData);
                //setSelectedSection(sectionData[0]['section_id']);
            } else {
                setSections([]);
                //setSelectedSection(-1);
            };
        });

        decksRef.where('uid', '==', plusUid).get().then((docs) => {
            let decksData = [];
            docs.forEach((doc) => {
                decksData.push(doc.data());
            });
            if (decksData.length > 0){
                setDecks(decksData);
            } else {
                setDecks([]);
            };
        });

        getUserLanguages();
        getAllLanguages();
    }, []);

/*     const handleAddSectionClick = () => {
        //console.log(showNewSectionInput);
        if (showNewSectionInput === true) {
            if (newSectionInputValue.length > 0){
                handleAddSection();
            }
        }
        else {
            setShowNewSectionInput(true);
            //console.log("hei");
        }
    }

    const handleCloseAddSectionClick = () => {
        setShowNewSectionInput(false);
    } */
/* 
    const handleAddCategoryClick = () => {
        if (showNewCategoryInput === true) {
            if (newCategoryInputValue.length > 0){
                handleAddCategory();
            }
        }
        else {
            setShowNewCategoryInput(true);
            setNewCategoryButtonText("Save category");
        }
    } */
/* 
    const handleAddSection = () => {
        const collectionRef = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('decks').collection('sections'); 
        let section_id = Math.floor(Math.random() * 100000000);
        const checkIfExists = (section_id) => {
            collectionRef.where('section_id', '==', section_id).get().then(function(querySnapshot) {
                if (!querySnapshot.empty) {
                    section_id = Math.floor(Math.random() * 100000000);
                    checkIfExists(section_id);
                }
                else {
                    if (newSectionInputValue.length>0){
                        collectionRef.add({
                            'name': newSectionInputValue, 
                            'section_id': section_id, 
                            'favorite': false, 
                            'target_ISO_639-1': selectedLanguage
                        });
                    }
                    setNewSectionInputValue("");
                    setShowNewSectionInput(false);
                    setNewSectionButtonText("Add section");
                }
            }); 
        }
        checkIfExists(section_id);
    }; */

/*     const handleAddCategory = () => {
        const collectionRef = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('decks').collection('categories'); 
        let collection_id = Math.floor(Math.random() * 100000000);
        const checkIfExists = (collection_id) => {
            collectionRef.where('collection_id', '==', collection_id).get().then(function(querySnapshot) {
                if (!querySnapshot.empty) {
                    collection_id = Math.floor(Math.random() * 100000000);
                    checkIfExists(collection_id);
                }
                else {
                    if (newCategoryInputValue.length>0){
                        collectionRef.add({
                            'category_id': collection_id,
                            'name': newCategoryInputValue, 
                            'section_id': selectedSection, 
                            'favorite': false,
                            'target_ISO_639-1': selectedLanguage
                        });
                    }
                    setNewCategoryInputValue("");
                    setShowNewCategoryInput(false);
                    setNewCategoryButtonText("Add category");
                }
            }); 
        }
        checkIfExists(collection_id);    
    }; */

    const handleCategoryClick = (e) => {
        const selected_id = parseInt(e.currentTarget.getAttribute('data-id'));
        setSelectedCategory(selected_id);
        setSelectedItem(-1);
    }

    const handleSectionClick = (e) => {
        const selected_id = parseInt(e.currentTarget.getAttribute('data-id'));
        setSelectedSection(selected_id);
        setSelectedCategory(-1);
        setSelectedItem(-1);

/*         //Close edit input when clicking on other row, doesn t work
        if (selected_id !== editSectionInput){
            //console.log("WOW", editSectionInput);
            setEditSectionInput(-1);
        } */
        
    }
/* 
    const changeNewSectionInputValue = (e) => {
        setNewSectionInputValue(e.target.value.trim());
    }

    const checkIfSectionInputEnter = (e) => {
        if (e.keyCode === 13){
            handleAddSection();
        }
    } */
/* 
    const editSectionClick = (e) => {
        let id = parseInt(e.currentTarget.getAttribute('data-id'));
        let name = e.currentTarget.getAttribute('data-name');

        setEditSectionName(name);
        setSelectedSection(id);
        setEditSectionInput(id);   
    }

    const editCategoryClick = (e) => {
        let id = parseInt(e.currentTarget.getAttribute('data-id'));
        let name = e.currentTarget.getAttribute('data-name');

        setEditCategoryName(name);
        setSelectedCategory(id);
        setEditCategoryInput(id);   
    } */
/* 
    const editSectionNameOnChange = (e) => {
        //console.log(e.currentTarget.value);
        setEditSectionName(e.currentTarget.value);
    }

    const editCategoryNameOnChange = (e) => {
        setEditCategoryName(e.currentTarget.value);
    }

    const updateSectionNameInDatabase = () => {
        const collectionRef = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('decks').collection('sections'); 
        const docRef = collectionRef.where('section_id', '==', editSectionInput);
        docRef.get().then(snapshots => {
            if (snapshots.size === 1){
                snapshots.forEach(snapshot => {
                    if (editSectionName.length>0){
                        let doc_id = snapshot.id;
                        let updateRef = collectionRef.doc(doc_id);
                        updateRef.update({
                            'name': editSectionName, 
                        });
                    }
                });
                setEditSectionName("");
                setEditSectionInput(0);
                //console.log("input", editSectionInput);
            }
        }); 
  
    }

    const updateCategoryNameInDatabase = () => {
        const collectionRef = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('decks').collection('categories'); 
        const docRef = collectionRef.where('category_id', '==', editCategoryInput);
        docRef.get().then(snapshots => {
            if (snapshots.size === 1){
                snapshots.forEach(snapshot => {
                    if (editCategoryName.length>0){
                        let doc_id = snapshot.id;
                        let updateRef = collectionRef.doc(doc_id);
                        updateRef.update({
                            'name': editCategoryName, 
                        });
                    }
                });
                setEditCategoryName("");
                setEditCategoryInput(0);
            }
        }); 
  
    } */

    /* const checkIfEditSectionNameInputEnter = (e) => {
        if (e.keyCode === 13){
            updateSectionNameInDatabase();
            
        }
    }

    const checkIfEditCategoryNameInputEnter = (e) => {
        if (e.keyCode === 13){
            updateCategoryNameInDatabase(); 
        }
    }

    const changeNewCategoryInputValue = (e) => {
        setNewCategoryInputValue(e.target.value.trim());
    } */

    const [showModal, setShowModal] = useState(false);
    

    const handleAddNewDeck = () => {setShowModal(true)};
    const changeShowModal = (value) => {setShowModal(value)};
    
    const getUserLanguages = () => {
        const docRef = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('languages'); 
        if (docRef !== undefined){
            docRef.onSnapshot((docs) => {
                //console.log(docs.data());
                let data = docs.data()['ISO_639-1'];
                setUserLanguages(data);
                setSelectedLanguage(data[0]['target_language']);
                setSourceLanguage(data[0]['source_language']);
                setLoading(false); // only need this before we can show everything
            });
        }
        else {
            setUserLanguages([]);
        }
    }

    const getAllLanguages = () => {
        const ref = projectFirestore.collection('languages'); 
        ref.get().then((docs) => {
            let dict = {};
            let language_codes = [];
            docs.forEach((doc) => {
                dict[`${doc.data()['ISO_639-1']}`] = doc.data();
                language_codes.push(doc.data()['ISO_639-1']);
            })
            //console.log(dict);
            setAllLanguages(dict);
            setAllLanguageCodes(language_codes);
        });
    }

    const selectFirstItems = () => {
    
        if (selectedSection === -1 & sections.length > 0){
            //setSelectedSection(sections[0]['section_id']);
            sections.forEach((section)=>{
                if (section['target_ISO_639-1'] === selectedLanguage){
                    setSelectedSection(section['section_id']);
                }
            })
        }

        if (selectedCategory === -1 && categories.length > 0){
            categories.forEach((category)=>{
                if (category['target_ISO_639-1'] === selectedLanguage && category['section_id'] === selectedSection){
                    setSelectedCategory(category['category_id']);
                }
            })
        };

        if (selectedItem === -1 & decks.length > 0){
            if (categories.length > 0){
                //console.log(categories);
                decks.forEach((deck)=>{
                    if (deck['target_ISO_639-1'] === selectedLanguage){
                        categories.forEach((category)=>{
                            //console.log("CATEGORY: ", category);
                            /* if (category['decks']['id'].includes(deck['id'])){
                                //console.log("YES: ", deck);
                                setSelectedItem(deck['id']);
                            }     */
                        });
                    };
                });
            };
        }
    };
    
    selectFirstItems();
    //console.log("Selected section: ", selectedSection);
    //console.log("Selected category: ", selectedCategory);
    //console.log("Selected item ", selectedItem);

    //console.log(categories);

    const [activeSectionsCounter, setActiveSectionsCounter] = useState(0);
    const [activeCategoriesCounter, setActiveCategoriesCounter] = useState(0);
    const [activeDecksCounter, setActiveDecksCounter] = useState(0);

    useEffect(()=>{
        const countActiveItems = () => {
            let s_nb = 0;
            for (const section of sections){
                if ( section['target_ISO_639-1'] === selectedLanguage ){
                    s_nb++; 
                }  
            }
            setActiveSectionsCounter(s_nb);

            let c_nb = 0;
            for (const category of categories){
                if ( category['target_ISO_639-1'] === selectedLanguage ){
                    c_nb++; 
                }  
            }
            setActiveCategoriesCounter(c_nb);

            let d_nb = 0;
            for (const deck of decks){
                //console.log("dkjoej: ", deck);
                if ( deck['target_ISO_639-1'] === selectedLanguage ){
                    d_nb++; 
                }  
            }
            setActiveDecksCounter(d_nb);
        }

        countActiveItems();
    }, [selectedLanguage, sections])

    //console.log("Active: ", activeSectionsCounter, activeCategoriesCounter, activeDecksCounter);
    //console.log(allLanguages);
    return (
        <>
            <div className="">
                <div className=" ">
                    <LanguageSelector userLanguages={userLanguages} allLanguages={allLanguages} sourceLanguage={sourceLanguage} setSourceLanguage={setSourceLanguage} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} setSelectedSection={setSelectedSection} setSelectedCategory={setSelectedCategory} setSelectedItem={setSelectedItem} />
                    <h1>Yalango+</h1>
                    {loading === false &&
                        <>
                            {userLanguages.length > 0 & Object.keys(allLanguages).length !== 0 ?
                                activeSectionsCounter !== 0 & activeCategoriesCounter !== 0 & activeDecksCounter !== 0 ?
                                    <>
                                        <div className="grid grid-cols-1 gap-8 lg:grid-cols-3 lg:gap-6">
                                            <div className="shadow rounded-lg">
                                                <div className="">
                                                    <h3 className=" text-center rounded-sm p-3">Sections</h3>
                                                </div>
                                                <div className="">
                                                    <SectionRows currentUser={currentUser} selectedLanguage={selectedLanguage} setEditSectionInput={setEditSectionInput} sections={sections} showNewSectionInput={showNewSectionInput} selectedSection={selectedSection} handleSectionClick={handleSectionClick} />
                                                    
                                                    <div className="text-center">
                                                        {/* <NewSectionInput handleAddSectionClick={handleAddSectionClick} showNewSectionInput={showNewSectionInput} changeNewSectionInputValue={changeNewSectionInputValue} /> */}
                                                        {/* <button className="p-3 px-5 bg-green-700 rounded-lg text-white shadow hover:bg-green-800" onClick={handleAddSectionClick} >{newSectionButtonText}</button> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <Categories currentUser={currentUser} selectedLanguage={selectedLanguage} setSelectedCategory={setSelectedCategory} categories={categories} selectedSection={selectedSection} selectedCategory={selectedCategory} showNewCategoryInput={showNewCategoryInput} newCategoryButtonText={newCategoryButtonText} handleCategoryClick={handleCategoryClick} />
                                            <DecksCard currentUser={currentUser} setSelectedItem={setSelectedItem} selectedItem={selectedItem} selectedLanguage={selectedLanguage} items={decks} categories={categories} selectedCategory={selectedCategory} sourceLanguage={sourceLanguage} />                
                                        </div>
                                    </>
                                    : 
                                    <>
                                        <div className="flex flex-row justify-center">
                                            <div className="bg-blue-50 rounded-lg shadow-md p-4 text-center w-3/4"> Yalango+ is unfortunately not available in <span className="capitalize">{allLanguages[selectedLanguage]['name']['en']}</span> yet.</div>
                                        </div>
                                    </>
                            :
                                <>
                                    <div className="flex flex-row justify-center">
                                        <div className="bg-blue-50 rounded-lg shadow-md p-4 text-center w-3/4"><span className="text-blue-500"><FontAwesomeIcon icon={faInfo} /></span> In order to start using decks, you need to add one or more languages in your <Link to='/dashboard'>dashboard</Link>.</div>
                                    </div>
                                </>
                            }
                        </>
                    }
                </div>
            </div>
        </>
    )
}

export default Decks;
