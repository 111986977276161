import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faPen, faTrash, faFolderPlus, faPlus, faPlay, faUpDownLeftRight } from '@fortawesome/pro-solid-svg-icons';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import Icon from "../../components/general/Icon";


const LibraryItemMenu = (props) => {
    
    const {closeMenu, iconColor="muted-text", renameItemClick, deleteItemClick, closeCreateItemModal, openCreateSubItemModal, openCreateSubFolderModal, openCreateFolderModal, parentFolder, libraryItemMenuID, setMoveItemCurrentParent, changeItemFolderClick, showingLibraryItemMenuID, setShowingLibraryItemMenuID, item} = props;
    console.log(libraryItemMenuID, showingLibraryItemMenuID)
    const navigate = useNavigate();

    const menuClick = (e) => {
        e.stopPropagation();
        if (libraryItemMenuID === showingLibraryItemMenuID){
            setShowingLibraryItemMenuID(null);
        } else {
            setShowingLibraryItemMenuID(libraryItemMenuID);
        }
    }

    const backgroundClick = (e) => {
        setShowingLibraryItemMenuID(null);
    }

    const changeFolderOptionMenuClick = (e) => {
        e.stopPropagation();
        setShowingLibraryItemMenuID(null);
        setMoveItemCurrentParent(parentFolder);
        changeItemFolderClick(e, item);
    }

    const renameItemClickHelper = (e) => {
        e.stopPropagation();
        setShowingLibraryItemMenuID(null);
        renameItemClick(e, item);
    }

    const deleteItemClickHelper = (e) => {
        e.stopPropagation();
        setShowingLibraryItemMenuID(null);
        deleteItemClick(e, item);
    }

    const openCreateSubFolderModalClick = (e) => {
        e.stopPropagation();
        setShowingLibraryItemMenuID(null);
        setMoveItemCurrentParent(item); //
        openCreateSubFolderModal(e, item);
    }

    const openCreateSubItemModalClick = (e) => {
        e.stopPropagation();
        setShowingLibraryItemMenuID(null);
        openCreateSubItemModal(e, item);
    }

    const navigateToPlayAllDecks = (e) => {
        e.stopPropagation();
        backgroundClick();
        closeMenu();
        navigate('/my-folders/'+item.doc_id+"/decks/play");
    }

    const navigateToStudyNote = (e) => {
        e.stopPropagation();
        backgroundClick();
        closeMenu();
        navigate("/my-notes/note/"+item.id+"/study");
    }

    const navigateToPlayDeck = (e) => {
        e.stopPropagation();
        backgroundClick();
        closeMenu();
        navigate("/my-decks/"+item.id+"/play");
    }

    const navigateToPlayTable = (e) => {
        e.stopPropagation();
        backgroundClick();
        closeMenu();
        navigate("/my-tables/"+item.id+"/play");
    }

    const navigateToEditStudyNote = (e) => {
        e.stopPropagation();
        backgroundClick();
        closeMenu();
        navigate("/my-notes/note/"+item.id);
    }

    const navigateToEditDeck = (e) => {
        e.stopPropagation();
        backgroundClick();
        closeMenu();
        navigate("/my-decks/"+item.id);
    }

    const navigateToEditTable = (e) => {
        e.stopPropagation();
        backgroundClick();
        closeMenu();
        navigate("/my-tables/"+item.id);
    }
    

    return (
        <>  
            <AnimatePresence>
                {showingLibraryItemMenuID === libraryItemMenuID ?
                    <>
                        <motion.div initial={{opacity: 0}} animate={{opacity:1}} exit={{opacity:0}} onClick={backgroundClick} className="fixed left-0 z-10 top-0 w-screen h-screen bg-gray-500 bg-opacity-50 cursor-default">
                        </motion.div>
                        <motion.div 
                            variants={{
                                initial: {
                                    height: 0,
                                },
                                animate: {
                                    height: 'auto',
                                    transition: {
                                        when: 'beforeChildren',
                                        duration: 0.10
                                    },
                                },
                                exit: {
                                    height: 0,
                                    transition: {
                                        when: 'afterChildren',
                                    },
                                },
                            }}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                        className="absolute overflow-visible text-sm  z-30 rounded-lg shadow-xl bg-white right-0 top-0">
                            <motion.div 
                            variants={{
                                    initial: {
                                        opacity: 0,
                                    },
                                    animate: {
                                        opacity: 1,
                                        duration: 0.10
                                    },
                                    exit: {
                                        opacity: 0,
                                    }
                                }}
                            className="flex font-bold text-gray-700 flex-col justify-start place-items-start">
                                {item.content_type === "folder" &&
                                    <>
                                        <div onClick={(e)=>navigateToPlayAllDecks(e)}  className="flex flex-row gap-3 hover:bg-gray-100 w-full  p-3">
                                            <div>
                                                <Icon color="persian-green" icon={<FontAwesomeIcon icon={faPlay} />} />
                                            </div>
                                            <div>
                                                Play all decks
                                            </div>
                                        </div>
                                        <div onClick={(e)=>openCreateSubItemModalClick(e)}  className="flex flex-row gap-3 hover:bg-gray-100 w-full  p-3">
                                            <div>
                                                <Icon color="charcoal" icon={<FontAwesomeIcon icon={faPlus} />} />
                                            </div>
                                            <div>
                                                Create subitem
                                            </div>
                                        </div>
                                        <div onClick={(e)=>openCreateSubFolderModalClick(e)}  className="flex flex-row gap-3 hover:bg-gray-100 w-full  p-3">
                                            <div>
                                                <Icon color="charcoal" icon={<FontAwesomeIcon icon={faFolderPlus} />} />
                                            </div>
                                            <div>
                                                Create subfolder
                                            </div>
                                        </div>
                                        
                                    </>
                                }
                                {item.content_type === "note" &&
                                    <>
                     
                                            <div onClick={(e)=>navigateToStudyNote(e)} const className="flex flex-row gap-3 hover:bg-gray-100 w-full  p-3">
                                                <div>
                                                    <Icon color="persian-green" icon={<FontAwesomeIcon icon={faPlay} />} />
                                                </div>
                                                <div>
                                                    Study note
                                                </div>
                                            </div>
                                            <div onClick={(e)=>navigateToEditStudyNote(e)} const className="flex flex-row gap-3 hover:bg-gray-100 w-full  p-3">
                                                <div>
                                                    <Icon color="charcoal" icon={<FontAwesomeIcon icon={faPen} />} />
                                                </div>
                                                <div>
                                                    Edit note
                                                </div>
                                            </div>
                                        
                                    </>
                                }
                                {item.content_type === "deck" &&
                                    <>
                                        <div onClick={(e)=>navigateToPlayDeck(e)} const className="flex flex-row gap-3 hover:bg-gray-100 w-full  p-3">
                                            <div>
                                                <Icon color="persian-green" icon={<FontAwesomeIcon icon={faPlay} />} />
                                            </div>
                                            <div>
                                                Play deck
                                            </div>
                                        </div>
                                        <div onClick={(e)=>navigateToEditDeck(e)} const className="flex flex-row gap-3 hover:bg-gray-100 w-full  p-3">
                                            <div>
                                                <Icon color="charcoal" icon={<FontAwesomeIcon icon={faPen} />} />
                                            </div>
                                            <div>
                                                Edit deck
                                            </div>
                                        </div>
                                    
                                    </>
                                }
                                {item.content_type === "table" &&
                                    <>
                     
                                            <div onClick={(e)=>navigateToPlayTable(e)} const className="flex flex-row gap-3 hover:bg-gray-100 w-full  p-3">
                                                <div>
                                                    <Icon color="persian-green" icon={<FontAwesomeIcon icon={faPlay} />} />
                                                </div>
                                                <div>
                                                    Play table
                                                </div>
                                            </div>
                                            <div onClick={(e)=>navigateToEditTable(e)} const className="flex flex-row gap-3 hover:bg-gray-100 w-full  p-3">
                                                <div>
                                                    <Icon color="charcoal" icon={<FontAwesomeIcon icon={faPen} />} />
                                                </div>
                                                <div>
                                                    Edit table
                                                </div>
                                            </div>
                                    </>
                                }
                                
                                <div onClick={renameItemClickHelper}  className="flex flex-row gap-3 hover:bg-gray-100 w-full   p-3">
                                    <div>
                                        <Icon color="charcoal" icon={<FontAwesomeIcon icon={faPen} />} />
                                    </div>
                                    <div>
                                        Rename
                                    </div>
                                </div>
                                <div onClick={changeFolderOptionMenuClick}  className="flex flex-row gap-3 hover:bg-gray-100 w-full   p-3">
                                    <div>
                                        <Icon color="charcoal" icon={<FontAwesomeIcon icon={faUpDownLeftRight} />} />
                                    </div>
                                    <div>
                                        Move {item.content_type}
                                    </div>
                                </div>
                                <div onClick={deleteItemClickHelper}  className="flex flex-row gap-3 hover:bg-gray-100 w-full   p-3">
                                    <div>
                                        <Icon color="red" icon={<FontAwesomeIcon icon={faTrash} />} />
                                    </div>
                                    <div>
                                        Delete
                                    </div>
                                </div>
                                
                            </motion.div>
                        </motion.div>
                    </>
                    :
                    <>
                        <div onClick={menuClick} className={`${iconColor} absolute px-3 right-0 text-gray-600`}>
                            <FontAwesomeIcon icon={faEllipsisV} />
                        </div>
                    </>
                }
            </AnimatePresence>
        </>
    )
}

export default LibraryItemMenu;