import {projectFunctions} from '../firebase/config.js';

export default function useSearch() {
    const Typesense = require("typesense");
    // use InstantSearch.js ?
    
    const searchPublicTexts = async (query, target, textType, numberOfResultsPerPage, currentPage, sortByValue) => {
        const publicTextsKey = "Z09nUGI5UUVseVdHRnVxdk1sWllaU0RPSGRZRFdLdllKVWRDV0pDd3hIQT1QamNKeyJmaWx0ZXJfYnkiOiJwcml2YWN5OnB1YmxpYyJ9";
        const client = new Typesense.Client({
            'nodes': [{
            'host': 'lj5bmk4is71xnaeup.a1.typesense.net',
            'port': '443',
            'protocol': 'https'
            }],
            'apiKey': publicTextsKey,
            'connectionTimeoutSeconds': 2
        });
        if (query === ""){
            query = "*";
        }
        let targetFilter = null;
        let sourceFilter = null;
        let sortBy = null;
        let textTypeFilter = null;

        if (sortByValue === undefined){
            sortBy = 'rating:desc';
        }
        if (sortByValue !== undefined){
             if (sortByValue === 'last_updated_timestamp_asc'){
                sortBy = 'last_updated_timestamp:asc';
             } else if (sortByValue === 'last_updated_timestamp_desc'){
                sortBy = 'last_updated_timestamp:desc';
             } else if (sortByValue === 'created_timestamp_asc'){
                sortBy = 'created_timestamp:asc';
             } else if (sortByValue === 'created_timestamp_desc'){
                sortBy = 'created_timestamp:desc';
             } else if (sortByValue === 'most_relevant'){
                sortBy = 'rating:desc';
             }
        }
        if (target !== "all"){
            targetFilter = 'target_ISO_639-1:'+target;
        }
        if (textType !== "all"){
            textTypeFilter = 'content_type:='+ textType;
        }
        let filters = [targetFilter, textTypeFilter];
        let filterBy = null;
        filters.forEach((filter, index)=>{
            if (filter !== null){
                if (filterBy === null){
                    filterBy = filter;
                } else {
                    filterBy = filterBy + " && " + filter;
                }
            }
        });
        const searchParameters = {
            'q': query,
            'query_by': 'title,body',
            'filter_by': filterBy,
            'sort_by': sortBy,
            'per_page': numberOfResultsPerPage,
            'page': currentPage
        };

        const searchResults = await client.collections('texts')
            .documents()
            .search(searchParameters);
        
        return searchResults;
    }

    const searchPublicDecks = async (query, target, source, deckType, numberOfResultsPerPage, currentPage, sortByValue) => {
        const publicDecksKey = "Rk16VG5wMkhKeWFuR3puNmg2SkYyV0xYTytPU0xKZk9KUFFORlZucys1Zz1TMVN3eyJmaWx0ZXJfYnkiOiJwcml2YWN5OnB1YmxpYyJ9";
        const client = new Typesense.Client({
            'nodes': [{
            'host': 'lj5bmk4is71xnaeup.a1.typesense.net',
            'port': '443',
            'protocol': 'https'
            }],
            'apiKey': publicDecksKey,
            'connectionTimeoutSeconds': 2
        });

        if (query === ""){
            query = "*";
        }
        let targetFilter = null;
        let sourceFilter = null;
        let sortBy = null;
        let deckTypeFilter = null;
        const yalangoUid = "d1AsNHNfQUWYYoplP3UNdKcATss1";

        if (sortByValue === undefined){
            sortBy = 'number_of_items:desc';
        }
        if (sortByValue !== undefined){
             if (sortByValue === 'last_updated_timestamp_asc'){
                sortBy = 'last_updated_timestamp:asc';
             } else if (sortByValue === 'last_updated_timestamp_desc'){
                sortBy = 'last_updated_timestamp:desc';
             } else if (sortByValue === 'created_timestamp_asc'){
                sortBy = 'created_timestamp:asc';
             } else if (sortByValue === 'created_timestamp_desc'){
                sortBy = 'created_timestamp:desc';
             } else if (sortByValue === 'most_relevant'){
                sortBy = 'rating:desc';
             }
        }

        if (target !== "all"){
            targetFilter = 'target_ISO_639-1:'+target;
        }
        if (source !== "all"){
            sourceFilter = 'source_ISO_639-1:'+source;
        }
        if (deckType !== "all"){
            if (deckType === "yalango"){
                deckTypeFilter = 'uid:='+ yalangoUid
            } 
            else if (deckType === "community"){
                deckTypeFilter = 'uid:!='+ yalangoUid
            }
        }
        let filters = [targetFilter, sourceFilter, deckTypeFilter];
        let filterBy = null;
        filters.forEach((filter, index)=>{
            if (filter !== null){
                if (filterBy === null){
                    filterBy = filter;
                } else {
                    filterBy = filterBy + " && " + filter;
                }
            }
        });
        // Search for notes with matching text
        const searchParameters = {
            'q': query,
            'query_by': 'name,description',
            'filter_by': filterBy,
            'sort_by': sortBy,
            'per_page': numberOfResultsPerPage,
            'page': currentPage
        };

        const searchResults = await client.collections('decks')
            .documents()
            .search(searchParameters);
        
        return searchResults;
    }

    const getScopedDeckCardsApiKey = async () => {
        let getApiKey = projectFunctions.httpsCallable('getScopedDeckCardsTypesenseApiKeyForCurrentUser');
        let apiKeyResult = await getApiKey();
        let apiKey = null;
        
        console.log("API: ", apiKeyResult);
        if (apiKeyResult.hasOwnProperty('data')){
            if (apiKeyResult['data'].hasOwnProperty('key')) {
                apiKey = apiKeyResult['data'];
            }
        }

        return apiKey;

    }

    const searchCurrentUserDeckCards = async (searchTerm, deck_id, apiKey, currentPage, numberOfResultsPerPage, sortByValue, customFields) => {
        let sortBy = null;
        let customFieldsString = "";
        if (customFields !== null && customFields !== undefined){
            customFields.forEach((field)=>{
                customFieldsString = customFieldsString + "custom_fields." + field.data.id + ",";
            });
        }
        if (sortByValue === undefined){
            sortBy = 'number_of_items:desc';
        }
        if (sortByValue !== undefined){
             if (sortByValue === 'last_updated_timestamp_asc'){
                sortBy = 'last_updated_timestamp:asc';
             } else if (sortByValue === 'last_updated_timestamp_desc'){
                sortBy = 'last_updated_timestamp:desc';
             } else if (sortByValue === 'created_timestamp_asc'){
                sortBy = 'created_timestamp:asc';
             } else if (sortByValue === 'created_timestamp_desc'){
                sortBy = 'created_timestamp:desc';
             } else if (sortByValue === "target_alphabetically"){
                sortBy = 'target:asc';
             } else if (sortByValue === "source_alphabetically"){
                sortBy = 'source:asc';
             } else if (sortByValue === 'most_relevant'){
                sortBy = 'rating:desc';
             }
             else {
                sortBy = 'rating:desc';
             }
        }
 
        if (apiKey === null){
            return null;
        }
        const client = new Typesense.Client({
            'nodes': [{
            'host': 'lj5bmk4is71xnaeup.a1.typesense.net',
            'port': '443',
            'protocol': 'https'
            }],
            'apiKey': apiKey,
            'connectionTimeoutSeconds': 2
        });

        if (searchTerm === "" || searchTerm === null){
            searchTerm = "*";
        }

        let filterBy = 'deck_id:'+deck_id;

        const searchParameters = {
            'q': searchTerm,
            'query_by': 'target, source,'+customFieldsString,
            'sort_by': sortBy,
            'filter_by': filterBy,
            'per_page': numberOfResultsPerPage,
            'page': currentPage
        };

        console.log("Params: ", searchParameters);

        const searchResults = await client.collections('deck-cards')
            .documents()
            .search(searchParameters);
/* 
        console.log("Results: ", searchResults);
        if (searchTerm !== null && searchTerm.length === 20){
            const docSearchResults = await client.collections('deck-cards').documents(searchTerm).retrieve();
            console.log("Direct: ", docSearchResults);
        } */
        return searchResults;

    }

    const searchAllCurrentUserDeckCards = async (searchTerm, apiKey, currentPage, numberOfResultsPerPage, sortByValue, targetLanguage) => {
        let sortBy = null;
        let customFieldsString = "";
        if (sortByValue === undefined){
            sortBy = 'number_of_items:desc';
        }
        if (sortByValue !== undefined){
             if (sortByValue === 'last_updated_timestamp_asc'){
                sortBy = 'last_updated_timestamp:asc';
             } else if (sortByValue === 'last_updated_timestamp_desc'){
                sortBy = 'last_updated_timestamp:desc';
             } else if (sortByValue === 'created_timestamp_asc'){
                sortBy = 'created_timestamp:asc';
             } else if (sortByValue === 'created_timestamp_desc'){
                sortBy = 'created_timestamp:desc';
             } else if (sortByValue === "target_alphabetically"){
                sortBy = 'target:asc';
             } else if (sortByValue === "source_alphabetically"){
                sortBy = 'source:asc';
             } else {
                sortBy = 'last_updated_timestamp:asc';
             }
        }
 
        if (apiKey === null){
            return null;
        }
        const client = new Typesense.Client({
            'nodes': [{
            'host': 'lj5bmk4is71xnaeup.a1.typesense.net',
            'port': '443',
            'protocol': 'https'
            }],
            'apiKey': apiKey,
            'connectionTimeoutSeconds': 2
        });

        if (searchTerm === "" || searchTerm === null){
            searchTerm = "*";
        }

        const searchParameters = {
            'q': searchTerm,
            'query_by': 'target, source,'+customFieldsString,
            'sort_by': sortBy,
            'filter_by': 'target_ISO_639-1:'+targetLanguage,
            'per_page': numberOfResultsPerPage,
            'page': currentPage
        };

        const searchResults = await client.collections('deck-cards')
            .documents()
            .search(searchParameters);

        return searchResults;

    }

    return {
        searchPublicDecks, 
        searchPublicTexts,
        searchCurrentUserDeckCards, 
        getScopedDeckCardsApiKey, 
        searchAllCurrentUserDeckCards
    }
}