import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-duotone-svg-icons';
import { faCardsBlank } from '@fortawesome/pro-solid-svg-icons';
import { faRobot } from '@fortawesome/pro-solid-svg-icons';
import {Link} from 'react-router-dom';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { faCaretUp as faCaretUpLight } from '@fortawesome/pro-light-svg-icons';
import { faCaretDown as faCaretDownLight } from '@fortawesome/pro-light-svg-icons';
import { faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import useRatings from '../../hooks/useRatings';
import { useSocialContext } from "../../contexts/SocialContext";
import { useAuth } from "../../contexts/AuthContext";

const ItemRating = (props) => {
    const {item, itemType} = props;
    const {myRatings, myRatingsFetched} = useSocialContext();
    const {postRating, removeRating} = useRatings();
    const [rating, setRating] = useState(item.hasOwnProperty("rating") ? item.rating : 0);
    const [originalRating, setOriginalRating] = useState(item.hasOwnProperty("rating") ? item.rating : 0);
    const [userVote, setUserVote] = useState(null);
    const {currentUser} = useAuth();

    console.log("Item: ", item);

    const docIdNames = {
        'comment': 'comment_doc_id', 
        'deck': 'doc_id', 
        'text': 'doc_id'
    }

    const removeRatingClick = async (e) => {
        e.stopPropagation();
        if (currentUser === null){return null}
        await removeRating(item[docIdNames[itemType]])
        .catch(err=>console.log(err))
        .then(()=>{
            setUserVote(null);
            setRating(userVote === "upvote" ? rating - 1 : rating + 1);
        });
    }

    const downvoteClick = async (e) => {
        e.stopPropagation();
        if (currentUser === null){return null}
        await postRating(item[docIdNames[itemType]], (item.hasOwnProperty("uid") ? item.uid : "d1AsNHNfQUWYYoplP3UNdKcATss1"), itemType, "downvote")
        .catch(err=>console.log(err))
        .then(()=>{
            let decrement = 1;
            if (userVote === "upvote"){
                decrement = 2;
            }
            setUserVote("downvote");
            setRating(rating - decrement);
        });
    };


    const upvoteClick = async (e) => {
        e.stopPropagation();
        if (currentUser === null){return null}
        await postRating(item[docIdNames[itemType]], (item.hasOwnProperty("uid") ? item.uid : "d1AsNHNfQUWYYoplP3UNdKcATss1"), itemType, "upvote")
        .catch(err=>console.log(err))
        .then(()=>{
            let increment = 1;
            if (userVote === "downvote"){
                increment = 2;
            }
            setUserVote("upvote");
            setRating(rating + increment);
        });
    };

    useEffect(()=>{
        if (myRatings !== null && item !== null){
            myRatings.forEach((r)=>{
                if (r.hasOwnProperty("source_doc_id")){
                    if (r.source_doc_id === item[docIdNames[itemType]]){
                        console.log(r.source_doc_id, item[docIdNames[itemType]]);
                        setUserVote(r.rating);
                    }
                }
            })
        }
    },[myRatings, item]);

    return (
        <>
            <div className={"h-full flex flex-row place-items-center gap-4 justify-center"}>
                <div>
                    {userVote === null ?
                    <div onClick={upvoteClick} className={(currentUser === null ? "cursor-not-allowed " : "") + " group cursor-pointer text-3xl"}>
                        <div className="group-hover:hidden">
                            <FontAwesomeIcon icon={faCaretUpLight} />
                        </div>
                        <div className="hidden group-hover:block">
                            <FontAwesomeIcon icon={faCaretUp} />
                        </div>
                    </div>
                    :
                    userVote === "upvote" ? 
                        <div onClick={removeRatingClick} className={(currentUser === null ? "cursor-not-allowed " : "") + "text-persian-green cursor-pointer text-3xl"}>
                            <div className="">
                                <FontAwesomeIcon icon={faCaretUp} />
                            </div>
                        </div>
                    :
                        <div onClick={upvoteClick} className={(currentUser === null ? "cursor-not-allowed " : "") + " group cursor-pointer text-3xl"}>
                            <div className="group-hover:hidden">
                                <FontAwesomeIcon icon={faCaretUpLight} />
                            </div>
                            <div className="hidden group-hover:block">
                                <FontAwesomeIcon icon={faCaretUp} />
                            </div>
                        </div>
                    }
                </div>
                <div className=" font-bold">
                    {rating}
                </div>
                <div>
                    {userVote === null ?
                    <div onClick={downvoteClick} className={(currentUser === null ? "cursor-not-allowed " : "") + " group cursor-pointer text-3xl"}>
                        <div className="group-hover:hidden">
                            <FontAwesomeIcon icon={faCaretDownLight} />
                        </div>
                        <div className="hidden group-hover:block">
                            <FontAwesomeIcon icon={faCaretDown} />
                        </div>
                    </div>
                    :
                    userVote === "downvote" ? 
                        <div onClick={removeRatingClick} className={(currentUser === null ? "cursor-not-allowed " : "") + "text-burnt-sienna cursor-pointer text-3xl"}>
                            <div className="">
                                <FontAwesomeIcon icon={faCaretDown} />
                            </div>
                        </div>
                    :
                        <div onClick={downvoteClick} className={(currentUser === null ? "cursor-not-allowed " : "") + " group cursor-pointer text-3xl"}>
                            <div className="group-hover:hidden">
                                <FontAwesomeIcon icon={faCaretDownLight} />
                            </div>
                            <div className="hidden group-hover:block">
                                <FontAwesomeIcon icon={faCaretDown} />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default ItemRating;