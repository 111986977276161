import React, {useEffect, useState,  useRef} from 'react';
import { Editable, withReact, useSlate, Slate, useFocused, ReactEditor } from 'slate-react';
import {
    Editor,
    Text, 
    Transforms,
    createEditor,
    Descendant,
    Range,
    Element as SlateElement,
  } from 'slate';
import ListOfButtons from './ListOfButtons';
import MarkButton from './MarkButton';
import {faP} from '@fortawesome/pro-solid-svg-icons';
import {faBold} from '@fortawesome/pro-solid-svg-icons';
import {faQuoteLeft} from '@fortawesome/pro-solid-svg-icons';
import {faItalic} from '@fortawesome/pro-solid-svg-icons';
import {faUnderline} from '@fortawesome/pro-solid-svg-icons';
import {faAlignRight} from '@fortawesome/pro-solid-svg-icons';
import {faAlignLeft} from '@fortawesome/pro-solid-svg-icons';
import {faAlignJustify} from '@fortawesome/pro-solid-svg-icons';
import {faAlignCenter} from '@fortawesome/pro-solid-svg-icons';
import {faChevronDown} from '@fortawesome/pro-solid-svg-icons';
import {faH1} from '@fortawesome/pro-solid-svg-icons';
import {faH2} from '@fortawesome/pro-solid-svg-icons';


const HoveringToolbar = ({TEXT_ALIGN_TYPES, contentButtons}) => {
    const editor = useSlate();
    const inFocus = useFocused();
    const ref = useRef();
    const [completelyRemove, setCompletelyRemove] = useState(false);
  
    const {selection} = editor;
  
    useEffect(() => {
      const el = ref.current;
      if (!el) {
        return
      }

      if (inFocus === false || selection === null || Range.isCollapsed(selection)){
        setCompletelyRemove(true);
      } else {
        setCompletelyRemove(false);
      }
     
      
      if (
        !selection ||
        !inFocus ||
        Range.isCollapsed(selection) ||
        Editor.string(editor, selection) === ''
      ) {
        el.removeAttribute('style')
        return
      }
      const toolbarWidth = 480; //w-120 in pixels
      const domSelection = window.getSelection();
      const domRange = domSelection.getRangeAt(0)
      const rect = domRange.getBoundingClientRect()
      el.style.opacity = '1'
      el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight - 10}px`;
      let l_calc = rect.left +window.pageXOffset -el.offsetWidth / 2 +rect.width / 2;
      if (l_calc<0){l_calc = 0};
      let window_width = window.innerWidth;
      if (l_calc+toolbarWidth >window_width){
        l_calc = window_width - toolbarWidth;
      }
      el.style.left = `${l_calc}px`;
    }, [selection, inFocus, editor]);
  
    return (
      <> 
        
         <div
          ref={ref}
          className="absolute z-20  h-12 transition transition-duration-500 ease-out "
          onMouseDown={e => {
            // prevent toolbar from taking focus away from editor
            e.preventDefault();
          }}
        >
        {!completelyRemove &&
          <>
            <div className=" text-sm flex flex-row justify-center place-items-center gap-4 px-2 bg-white rounded-lg shadow-xl">
            <div>
              <ListOfButtons TEXT_ALIGN_TYPES={TEXT_ALIGN_TYPES} type="block" buttons={contentButtons} />
            </div>
            <div>
              <ListOfButtons TEXT_ALIGN_TYPES={TEXT_ALIGN_TYPES} type="block" buttons={[{'text': 'Left','format': 'left', 'icon': faAlignLeft}, {'text': 'Center', 'format': 'center', 'icon': faAlignCenter}, {'text': 'Right', 'format': 'right', 'icon': faAlignRight}, {'text': 'Justify', 'format': 'justify', 'icon': faAlignJustify}]} />
            </div>
            <div>
              <MarkButton format="bold" icon={faBold} />
            </div>
            <div>
              <MarkButton format="italic" icon={faItalic} />
            </div>
            <div>
              <MarkButton format="underline" icon={faUnderline} />
            </div>
            </div>
          </>
        }
        </div>
        
      </>
    )
  }

export default HoveringToolbar