import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import HelpArticleBody from './HelpArticleBody';
import HelpArticleHeader from './HelpArticleHeader';
import HelpArticleFooter from './HelpArticleFooter';
import HelpArticleHeading from './HelpArticleHeading';

const HelpArticleTemplate = ({article}) => {
  return (
    <>
        <div className="flex flex-col  gap-4 justify-center place-items-center">
            <div className="w-full">
                <HelpArticleHeader article={article} />
            </div>
            <div className="">
                <HelpArticleHeading article={article} />
            </div>
            <div className="text-left  text-sm w-full sm:w-5/6 md:w-4/6">
                <Link to="/help">
                    Go back 
                </Link>
            </div>
            <div className=" text-sm sm:text-base p-6 w-full sm:w-5/6 md:w-4/6">
                <HelpArticleBody article={article} />
            </div>
            <div>
                <HelpArticleFooter article={article} />
            </div>
        </div>
    </>
  )
}

export default HelpArticleTemplate