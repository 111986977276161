import React, {useState, useEffect} from 'react';
import RenderComments from './RenderComments';
import useComments from '../../hooks/useComments';
import SortByButton from '../../components/general/sorting/SortByButton';

const UserFeed = ({uid}) => {
    const {fetchCommentsByUid} = useComments();
    const [comments, setComments] = useState(null);
    const [loading, setLoading] = useState(false);
    const [sortByValue, setSortByValue] = useState("most_recent");

    const refreshComments = async () => {
        setLoading(true);
        let c = await fetchCommentsByUid({uid, sortByValue});
        setComments(c);
        setLoading(false);
    }

    const sortByOptions = [
        {label: 'Highest rated', value:'highest_rating'}, 
        {label: 'Most recent', value:'most_recent'}  
    ];
    const [sortByOptionsDict, setSortByOptionsDict] = useState(sortByOptions.reduce(function(result, item) {
        var key = item.value; 
        result[key] = item;
        return result;
      }, {}));

      const orderByChange = async (option) => {
        setSortByValue(option.value);
    }

    useEffect(()=>{
        const fetchData = async () => {
            setLoading(true);
            let c = await fetchCommentsByUid({uid, sortByValue});
            setComments(c);
            setLoading(false);
        }

        if (uid !== null && uid !== undefined && sortByValue !== null){
            fetchData();
        }
    },[uid, sortByValue]);

    return (
        <>
            <div>
                {comments !== null && !loading &&
                    <>
                        <div className="text-sm w-full flex flex-row justify-end mb-2 m-4">
                            <SortByButton textSize={"sm"} onChange={orderByChange} value={sortByOptionsDict[sortByValue]} options={sortByOptions} />
                        </div>
                        <div>
                            <RenderComments loading={loading} comments={comments} refreshComments={refreshComments} commentLocation={"feed"} />
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default UserFeed;