import React from 'react'

const TableBlock = (props) => {
    const {block, blockIndex, mode} = props;
    return (
        <>
            <div>
                Table: {block.table_id}
            </div>
        </>
    )
}

export default TableBlock;