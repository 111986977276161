import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash} from '@fortawesome/pro-solid-svg-icons';
import {faPencil} from '@fortawesome/pro-solid-svg-icons';
import TableWidget from '../../widgets/tables/TableWidget';

const TableBlock = ({align, blockIndex, draggingId, provided, element, studyMode, attributes, children, openTableSelectorClick, deleteElement, numberOfPreviewRows}) => {
    //console.log(provided.draggableProps['data-rbd-draggable-id'], draggingId, element);  
  return (
      <>
        <div className="w-full m-auto" {...attributes}>
          <div contentEditable={false} style={{ userSelect: "none" }}>
            <div className="relative p-5 my-2">
             {!studyMode &&
                <div className="absolute z-10 text-sm left-0 top-6 flex flex-row gap-1">
                <div onClick={()=>openTableSelectorClick(element)} className=" text-white font-bold bg-persian-green px-3 py-2 cursor-pointer rounded-lg shadow-xl">
                    <FontAwesomeIcon icon={faPencil} />
                </div>
                <div className=" text-white font-bold bg-burnt-sienna px-3 py-2  cursor-pointer rounded-lg shadow-xl" onClick={()=>deleteElement(blockIndex)}>
                  <FontAwesomeIcon icon={faTrash} />
                </div>
                </div>
              }
              {(element.tableId === null && !studyMode) ?
                <div className="flex flex-row justify-center gap-4">
                  <div onClick={()=>openTableSelectorClick(element)} className="text-gray-600 font-bold bg-white p-4 cursor-pointer rounded-lg shadow-lg">
                    Choose a table
                  </div>
                </div>
              :
                <>
                  {draggingId !== provided.draggableProps['data-rbd-draggable-id'] ?
                  <div className="">
                    
                    <div className="py-5 z-0">
                      <TableWidget numberOfPreviewRows={numberOfPreviewRows} tableId={element.tableId} includePlay={true} showName={false} />
                    </div>
                  </div>
                  : 
                    <div className="h-18 bg-persian-green text-white rounded-lg shadow-xl p-4 flex flex-col justify-center text-xl text-center"> 
                        <div>Table: <span className="font-bold">{element.tableId}</span></div>
                    </div>
                  }
                </>
              }
            </div>
          </div>
          {children}
        </div>
      </>
    )
  }

export default TableBlock