import React from 'react';
import Button from '../../../../general/Button';
import Modal from '../../../../general/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/pro-duotone-svg-icons';
import { faThumbsDown } from '@fortawesome/pro-duotone-svg-icons';
import { faRotateRight } from '@fortawesome/pro-duotone-svg-icons';

const ModalContent = ({closeFunction}) => {
    return (
        <>
            <div className="overflow-auto h-72 sm:h-full p-4">
                <h1>How to play</h1>
                <p className="text-center my-2">Look at the card in front of you. Do you know what the translation is? Flip the card by either clicking on the card, clicking on the <span className="font-bold">Flip card</span> button, clicking on the <span className="font-bold">spacebar</span> key, or by clicking on the <span className="font-bold">down arrow</span> key. If you got it right, click on the <span className="font-bold">thumbs up</span> button (<span className="text-persian-green"><FontAwesomeIcon icon={faThumbsUp} /></span>). 
                If not, click on the <span className="font-bold">thumbs down</span> button (<span className="text-burnt-sienna"><FontAwesomeIcon icon={faThumbsDown} /></span>).</p>
                <div className="text-xl text-center font-bold text-gray-700 my-6">Keyboard shortcuts</div>
                <div className="flex flex-col place-items-center gap-2">
                    <div className="flex flex-row justify-start gap-8">
                        <div className="font-bold text-gray-700">
                            Spacebar
                        </div>
                        <div>
                            Flip current card
                        </div>
                    </div>
                    <div className="flex flex-row justify-start gap-8">
                        <div className="font-bold text-gray-700">
                            Left arrow
                        </div>
                        <div>
                            I got it wrong
                        </div>
                    </div>
                    <div className="flex flex-row justify-start gap-8">
                        <div className="font-bold text-gray-700">
                            Right arrow
                        </div>
                        <div>
                            I got it right
                        </div>
                    </div>
                    <div className="flex flex-row justify-start gap-8">
                        <div className="font-bold text-gray-700">
                            Down arrow
                        </div>
                        <div>
                            Flip current card
                        </div>
                    </div>
                </div>
                <div className="p-4 text-center">
                    <Button text="Continue playing" onClick={closeFunction} />
                </div>
            </div>
        </>   
    )
}

const FlashcardHelpModal = ({closeFunction}) => {
  return (
    <>
        <Modal closeFunction={closeFunction} content={<ModalContent closeFunction={closeFunction} />} size="3xl"/>
    </>
  )
};

export default FlashcardHelpModal;
