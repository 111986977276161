import {projectFirestore, projectFunctions, timeStamp, firebase} from '../firebase/config.js';


export default function useStatistics() {

    const translateText = async (text, target) => {
        const func =  projectFunctions.httpsCallable('translateTextWithGoogle');
        let error = null;
        const results = await func({text, target}).catch((err)=>{
            error = err;
            return false;
        });
        if (results !== false){
            return {'success': true, 'data': results['data']};
        } else {
            return {'success': false, 'error': error};
        }
    }

    const translateMultipleTexts = async (texts, target) => {
        const func =  projectFunctions.httpsCallable('translateMultipleTextsWithGoogle');
        let error = null;
        const results = await func({texts, target}).catch((err)=>{
            error = err;
            return false;
        });
        if (results !== false){
            return {'success': true, 'data': results['data']};
        } else {
            return {'success': false, 'error': error};
        }
    }

    return {
        translateText, 
        translateMultipleTexts
    }
}