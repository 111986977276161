import React from 'react'
import GoalsCard from '../components/goals/GoalsCard'

const TestPage = () => {
  return (
    <div className=" ">
        <GoalsCard language={"no"} />
    </div>
  )
}

export default TestPage