import React, {useState, useEffect, useCallback, useRef} from 'react';
import {useParams} from 'react-router-dom';
import useTexts from '../../hooks/useTexts';
import useTranslate from '../../hooks/useTranslate';
import useQueryStrings from '../../hooks/useQueryStrings';

import CountryFlag from '../../components/languages/CountryFlag';
import useDecks from '../../hooks/useDecks';
import DefaultImage from '../../assets/svg/yalango/YalangoAI.svg';
import {Link} from 'react-router-dom';
import {faLoader, faBrain, faFloppyDisk} from '@fortawesome/pro-solid-svg-icons';
import {faEye} from '@fortawesome/pro-solid-svg-icons';
import {faX, faThumbsUp} from '@fortawesome/pro-solid-svg-icons';
import {faEyeSlash} from '@fortawesome/pro-solid-svg-icons';
import useVocabulary from '../../hooks/useVocabulary';
import Button from '../../components/general/Button';
import ItemRating from '../../components/rating/ItemRating';
import { createEditor, Descendant, Transforms } from 'slate';
import DeckSelectorModal from '../../components/general-modals/decks/DeckSelectorModal';
import { Slate, Editable, withReact } from 'slate-react';
import { faTrash, faAngleRight, faAngleLeft, faHome, faLanguage, faFileLines, faSchool } from '@fortawesome/pro-solid-svg-icons';
import {useLanguagesContext} from '../../contexts/LanguagesContext';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import { useAuth } from "../../contexts/AuthContext";
import { useUserStatisticsContext } from "../../contexts/UserStatisticsContext";
import CommentSection from '../../components/comments/CommentSection';
import pinyin from "pinyin";

const TextOptions = (props) => {
    const {sentences, setPage,  placementTestClick, currentSentenceIndex, showPinyin, sentenceView, setSentenceView, translateSentenceClick, setShowVocabulary, showVocabulary, pinyinToggleClick, targetLang, page, currentUser, nextStepClick} = props;
    return (
        <>
            <ReactTooltip place="bottom" id="tooltip-text-menu" effect="solid" />
            <div className="fixed bottom-0 p-16 bg-white w-full text-lg z-10 flex md:flex-row flex-row text-2xl gap-12  justify-evenly ">

                    <div data-for="tooltip-text-menu" data-tip="Overview" onClick={()=>setPage("intro")} className="cursor-pointer muted-text">
                        <div className="flex text-gray-600 font-bold flex-row place-content-center justify-start gap-2">
                            <div>
                                <FontAwesomeIcon icon={faHome} /> 
                                
                            </div>
                            <div className="md:block hidden">
                                Text overview
                            </div>

                        </div>
                    </div>

                    <div data-for="tooltip-text-menu" data-tip="Toggle sentence view" onClick={()=>setSentenceView(!sentenceView)} className="cursor-pointer muted-text">
                        <div className="flex text-gray-600 font-bold flex-row place-content-center justify-start gap-2">
                            <div>
                                <FontAwesomeIcon icon={faFileLines} /> 
                            </div>
                            <div className="md:block hidden">
                                {sentenceView ? "Full text" : "Sentence view"}
                            </div>
                        </div>
                    </div>
                    
                    <div data-for="tooltip-text-menu" data-tip="Toggle vocabulary" onClick={()=>setShowVocabulary(!showVocabulary)} className="text-center  cursor-pointer">
                        <div className="flex text-gray-600 font-bold flex-row place-content-center justify-start gap-2">
                            <div>
                                <FontAwesomeIcon icon={showVocabulary ? faEye : faEyeSlash} className="" />
                            </div>
                            <div className="md:block hidden">
                                Toggle vocabulary
                            </div>
                        </div>
                    </div>
                    {targetLang === "zh" &&
                    <div className="flex flex-row justify-center place-items-center  gap-6">
                    
                            <div data-for="tooltip-text-menu" data-tip="Toggle pinyin" className="text-center cursor-pointer" onClick={pinyinToggleClick}>
                                <div className="flex text-gray-600 font-bold flex-row place-content-center justify-start gap-2">
                                    <div>
                                        <FontAwesomeIcon icon={showPinyin ? faEye : faEyeSlash} className="" />
                                    </div>

                                </div>
                            </div>
                    
                        
                    </div>
                 }
                {sentenceView === true && page === "text" &&
                    <div data-for="tooltip-text-menu" data-tip="Translate" onClick={()=>translateSentenceClick(sentences[currentSentenceIndex])} className="cursor-pointer muted-text">
                        <div className="flex text-gray-600 font-bold flex-row place-content-center justify-start gap-2">
                            <div>
                                <FontAwesomeIcon icon={faLanguage} /> 
                            </div>
                            <div className="md:block hidden">
                                Translate sentence
                            </div>

                        </div>
                    </div>
                    }
                <div className="hidden flex flex-row justify-center place-items-center  gap-6">
                <div data-for="tooltip-text-menu" data-tip="Placement test" className="text-center cursor-pointer" onClick={placementTestClick}>
                            <div className="flex text-gray-600 font-bold flex-row place-content-center justify-start gap-2">
                                <div>
                                    <FontAwesomeIcon icon={faSchool} className="" />
                                </div>
                            </div>
                        </div>
                  
                    
                </div>
              
            </div>
            
        </>
    )
}

const RenderWord = (props) => {
    let {closePopup, showPinyin, alreadyKnownVocabularyPopup, splitString, selectedVocabularyMetadata, allLanguages, popupLoading, translationError, googleTranslations, sourceLanguage, errorMsg, sentence, dotString, currentUser, vocabInformation, wordClick, word, sentenceIndex, wordIndex, childIndex, paragraphIndex, unknownWordRef, getWordRef, selectedWordClass, unknownWordClass, activeVocabularyWordClass, knownVocabularyWordClass, clickedUnknownWords, activeVocabularyTargetWords, targetLang, knownVocabulary, ignoreList, removeIgnoreListFromWord, showVocabulary} = props;
    //style={{"line-height": "5rem"}}

    let pinyinWord = word;
    if (showPinyin){
        let pinyinArray = pinyin(word);
        if (pinyinArray.length>0){
            if (pinyinArray[0].length > 0){
                pinyinWord = pinyinArray[0][0];
            }
        }
    }
    return (
        <>
         {currentUser !== null &&
            <span  className={((showPinyin && word !== pinyinWord) ? "mx-3 leading-26 " : "leading-relaxed ") + " relative"}>
               
                <span className={(showVocabulary && !ignoreList.includes(word)) ? ("   " + ((knownVocabulary[targetLang].includes(removeIgnoreListFromWord(word.toLowerCase())) || Number.isInteger(parseInt(word))) ? knownVocabularyWordClass : activeVocabularyTargetWords[targetLang].includes(removeIgnoreListFromWord(word.toLowerCase())) ? activeVocabularyWordClass : !clickedUnknownWords.includes(removeIgnoreListFromWord(word.toLowerCase())) ? unknownWordClass : selectedWordClass)) : " border-b border-dotted hover:border-gray-600  border-transparent decoration-dotted"} >
                    {showPinyin &&
                        <>
                            <span className="absolute font-bold -top-4 text-xs  text-center w-full">
                                {(!ignoreList.includes(word) && word !== pinyinWord) && <>{pinyin(word)} </>}
                            </span>
                        </>
                    }
                    
                    <span onClick={()=>wordClick(word, wordIndex, sentenceIndex, childIndex, paragraphIndex)}  className="cursor-pointer">
                        {ignoreList.includes((ignoreList.includes(word.slice(-1))? word.slice(0,word.length-1) : word).slice(0,1)) ? (ignoreList.includes(word.slice(-1))? word.slice(0,word.length-1) : word).slice(1, word.length) :(ignoreList.includes(word.slice(-1))? word.slice(0,word.length-1) : word) }  
                    </span>

                        
                    {unknownWordRef === getWordRef(removeIgnoreListFromWord(word.toLowerCase()), sentenceIndex, wordIndex, childIndex, paragraphIndex)  &&
                        <>
                            <div onClick={closePopup} className="w-screen h-screen z-10 fixed left-0 top-0">

                            </div>
                            <span  className="p-3 py-4 min-w-36 overflow-x-auto whitespace-nowrap absolute top-7 rounded-lg shadow-2xl  -left-12 z-20 bg-white">
                                <div className="absolute cursor-pointer right-2 top-2 text-gray-600 text-xs" onClick={closePopup}>
                                    <FontAwesomeIcon icon={faX} />
                                </div>
                            
                                {(selectedVocabularyMetadata !== null && allLanguages !== null) &&
                                    <>
                                        {selectedVocabularyMetadata !== false &&
                                        <div className="flex flex-col gap-2">
                                            
                                            <div className="flex flex-row w-full place-items-center justify-start gap-2">
                                                <div className="w-8">
                                                    <CountryFlag size="1.3em" countryCode={allLanguages[0][selectedVocabularyMetadata['source_ISO_639-1']]['country_flag']} flagName={allLanguages[0][selectedVocabularyMetadata['source_ISO_639-1']]['name']['en']} />
                                                </div>
                                                <div className="text-sm overflow-x-auto">
                                                    {selectedVocabularyMetadata.source}
                                                </div>
                                            </div>
                                            <div className="flex flex-row w-full place-items-center justify-start gap-2">
                                                <div className="w-8">
                                                    <CountryFlag size="1.3em" countryCode={allLanguages[0][selectedVocabularyMetadata['target_ISO_639-1']]['country_flag']} flagName={allLanguages[0][selectedVocabularyMetadata['target_ISO_639-1']]['name']['en']} />
                                                </div>
                                                <div className="text-sm">
                                                    {selectedVocabularyMetadata.target}
                                                </div>
                                            </div>
                                            {targetLang === "zh" &&
                                                <>
                                                    <div className="flex text-sm flex-row gap-2">
                                                        <div className="muted-text font-bold">
                                                            Pinyin:
                                                        </div>
                                                        <div>
                                                            {!ignoreList.includes(word) && <>{pinyin(word)} </>}
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            <div className="text-xs text-gray-600">
                                                Part of your vocabulary:
                                            </div>
                                            <div className="flex flex-row w-full place-items-center justify-between gap-2">
                                                <div className="text-xs text-persian-green">
                                                    {parseInt(selectedVocabularyMetadata.total_statistics.percentage.correct)}% correct
                                                </div>
                                                <div className="text-xs text-right text-gray-600">
                                                    <Link to={"/my-decks/"+selectedVocabularyMetadata.deck_ids[0]+"?q="+selectedVocabularyMetadata.source + "+" + selectedVocabularyMetadata.target}>
                                                        Edit
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        {selectedVocabularyMetadata === false &&
                                            <>
                                                <div className="text-xs text-right text-gray-600">
                                                    Word not found in vocabulary.
                                                </div>
                                            </>
                                        }
                                    </>
    
                                }
                                {popupLoading &&
                                    <div className="text-center text-lg m-auto w-5/6">
                                        <FontAwesomeIcon icon={faLoader} className="fa-spin" />
                                    </div>
                                }
                                {alreadyKnownVocabularyPopup &&
                                    <>
                                        <div className="text-xs text-right text-gray-600 p-2">
                                            You've previously added this word to your <em className="italic">Known Vocabulary</em> list.
                                        </div>
                                    </>
                                }
                                {( selectedVocabularyMetadata === null) && 
                                    <>
                                        {(!translationError && googleTranslations !== null && googleTranslations.hasOwnProperty(removeIgnoreListFromWord(word.toLowerCase()))) &&
                                        <div className="flex font-normal text-black flex-col gap-2">
                                            <div className="flex flex-row w-full place-items-center justify-start gap-2">
                                                <div className="w-8">
                                                    <CountryFlag size="1.3em" countryCode={allLanguages[0][sourceLanguage]['country_flag']} flagName={allLanguages[0][sourceLanguage]['name']['en']} />
                                                </div>
                                                <div className="text-sm overflow-x-auto">
                                                    {googleTranslations[removeIgnoreListFromWord(word.toLowerCase())]}
                                                </div>
                                            </div>
                                            <div className="flex flex-row w-full place-items-center justify-start gap-2">
                                                <div className="w-8">
                                                    <CountryFlag size="1.3em" countryCode={allLanguages[0][targetLang]['country_flag']} flagName={allLanguages[0][targetLang]['name']['en']} />
                                                </div>
                                                <div className="text-sm">
                                                    {removeIgnoreListFromWord(word.toLowerCase())}
                                                </div>
                                            </div>
                                            
                                            {targetLang === "zh" &&
                                                <>
                                                    <div className="flex text-sm flex-row gap-2">
                                                        <div className="muted-text font-semibold">
                                                            Pinyin
                                                        </div>
                                                        <div>
                                                            {!ignoreList.includes(word) && <>{pinyin(word)} </>}
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            <div className="text-xs text-gray-600">
                                                Translation by Google
                                            </div>
                                        </div>
                                        }
                                        {translationError &&
                                            <div className=" text-sm font-normal text-black">
                                                <div className="w-48">
                                                    {errorMsg.split(".").map((er, erI)=>(
                                                        <div key={"er_"+erI}>
                                                            {er}
                                                        </div>
                                                    ))}
                                                    
                                                </div>
                                            </div>
                                        }
                                    </>
                                }   
                            </span>
                        </>
                    }
                </span>
                    
                <span className="">
                    {ignoreList.includes(word.slice(-1)) ? word.slice(-1) : wordIndex === sentence.split(splitString).length-1 && word.length > 1 ? <>{dotString}</> : ""}
                </span>
            </span>
        }
            {currentUser === null &&
                <>
                    <span style={{"line-height": "5rem"}} className={(showPinyin ? "mx-4 " : "") + " relative"}>
                    <span onClick={()=>wordClick(word, wordIndex, sentenceIndex, childIndex, paragraphIndex)}  key={"word_"+wordIndex} className=" relative border-b border-dotted hover:border-gray-600 border-transparent decoration-dotted">
                        {showPinyin &&
                            <>
                                <span className="absolute -top-4 text-xs font-bold text-center w-full">
                                    {(!ignoreList.includes(word) && word !== pinyin(word)) && <>{pinyin(word)} </>}
                                </span>
                            </>
                        }
                        <span className="cursor-pointer">{word}</span>
                    
                    {(vocabInformation === getWordRef(removeIgnoreListFromWord(word.toLowerCase()), sentenceIndex, wordIndex, childIndex, paragraphIndex) || unknownWordRef === getWordRef(removeIgnoreListFromWord(word.toLowerCase()), sentenceIndex, wordIndex, childIndex, paragraphIndex))  &&
                        <>
                            <span  className="p-3 py-4 min-w-36 w-64 overflow-x-auto  absolute top-7 rounded-lg shadow-2xl  -left-12 z-20 bg-white">
                                <div className="absolute right-2 cursor-pointer top-2 text-gray-600 text-xs" onClick={closePopup}>
                                    <FontAwesomeIcon icon={faX} />
                                </div>
                                <div className="flex font-normal text-black flex-col gap-2">
                                    <div className="flex flex-row w-full place-items-center justify-start gap-2">
                                        <div className="w-8">
                                            <CountryFlag size="1.3em" countryCode={allLanguages[0][targetLang]['country_flag']} flagName={allLanguages[0][targetLang]['name']['en']} />
                                        </div>
                                        <div className="text-sm">
                                            {removeIgnoreListFromWord(word.toLowerCase())}
                                        </div>
                                    </div>
                                    <div className="text-xs text-gray-600">
                                        Create an account in order to get automatic translations in your native language here.
                                    </div>
                                </div>
                            </span>
                        </>
                        
                    }
                    </span>
                    </span>
                </>
            }
        </>
    )
}

const StudyText = (props) => {
    let { currentUser} = useAuth();
    const {location="public"} = props;
    let {text_id, targetLang, slug } = useParams();
    const {globalSelectedTargetLanguage, userLanguages, allLanguages} = useLanguagesContext();
    const {activeVocabularyTargetWords, 
        setActiveVocabularyTargetWords,
        knownVocabulary,
        setKnownVocabulary,
        vocabularyTargetWordsInTargetLanguage,
        refreshKnownVocabulary, 
        refreshActiveVocabularyTargetWords
    } = useUserStatisticsContext();

    const {addItemToDeck} = useDecks();
    const {translateMultipleTexts, 
        translateText} = useTranslate();

    const {getQueryStrings} = useQueryStrings();
    const [textBody, setTextBody] = useState(null);
    const [selectedTab, setSelectedTab] = useState("text");
    const [text, setText] = useState(null);
    const [userIsLearningThisLanguage, setUserIsLearningThisLanguage] = useState(true);
    const [sentences, setSentences] = useState(null);
    const [showComments, setShowComments] = useState(false);
    const [returnLink, setReturnLink] = useState(location === "public" ? "/texts" : "/my-texts");

    const {fetchText} = useTexts();
    const {fetchTargetWordsDoc, 
        addTargetWordsToKnownVocabulary, 
        fetchKnownVocabulary, 
        fetchTargetWordFromVocabulary
    } = useVocabulary();

    try {
        text_id = parseInt(text_id);
    } catch {
        console.log("Invalid id");
        text_id = null;
    }

    const [textId, setTextId] = useState(text_id);
    const [sourceLanguage, setSourceLanguage] = useState(null);
    const editorRef = useRef();
    if (!editorRef.current) editorRef.current = withReact(createEditor())
    const editor = editorRef.current

    


    useEffect(()=>{
        const fetchData = async () => {
            await refreshActiveVocabularyTargetWords(targetLang);
        }
        if ((activeVocabularyTargetWords === null || !activeVocabularyTargetWords.hasOwnProperty(targetLang)) && currentUser !== null && targetLang !== null){
           fetchData(); 
        }

    },[activeVocabularyTargetWords, currentUser, targetLang]);

    useEffect(()=>{
        const fetchData = async () => {
            await refreshKnownVocabulary(targetLang);
        }
        if (currentUser !== null && (knownVocabulary === null || !knownVocabulary.hasOwnProperty(targetLang)) && targetLang !== null){
           fetchData(); 
        }
    },[knownVocabulary, currentUser, targetLang]);

    useEffect(()=>{
        const fetchData = async () => {
            let r = await fetchText(textId);
            let queryStrings = await getQueryStrings();
            console.log("r: ", r, location)
            setReturnLink(location === "public" ? "/texts" : "/my-texts/"+textId);
            setText(r);
            setTextBody(JSON.parse(r['body']));
            let b = JSON.parse(r['body']);
            if (b !== null){
                let s = [];
                b.forEach(child=>{
                    child.children.forEach((p=>{
                        console.log("Para: ", p.text);
                        p.text.split("\n").forEach((group)=>{
                            group.split(dotString).forEach((sentence)=>{
                                console.log("Sentence: ", sentence);   
                                if (sentence !== ""){ 
                                    s.push(sentence);
                                }
                            })
                        })
                    }))
                });
                console.log("Sentences: ", s);
                setSentences(s);
            }

        }
        if (textId !== null && textBody === null && location !== null && targetLang !== null){
            fetchData();
        }
        if (targetLang !== null && userLanguages !== null){
            let source = userLanguages.filter(u=>u['target_language'] === targetLang)[0];
            if (source !== undefined){
                source = source['source_language'];
            } else {
                source = "en";
                setUserIsLearningThisLanguage(false);
            }
            setSourceLanguage(source);
        }
    },[textId, textBody, targetLang, userLanguages, location]);

    useEffect(()=>{
        console.log("vocab: ", vocabularyTargetWordsInTargetLanguage)
        if (vocabularyTargetWordsInTargetLanguage !== null && targetLang !== null && vocabularyTargetWordsInTargetLanguage.hasOwnProperty(targetLang) && currentUser !== null && textBody !== null){
            calculatePercentageOfVocabulary();
        }
    },[vocabularyTargetWordsInTargetLanguage, targetLang, currentUser, textBody]);

    const [showVocabulary, setShowVocabulary] = useState(true);
    const [unknownWordClass, setUnknownWordClass] = useState(" text-burnt-sienna relative font-bold ");
    const [selectedWordClass, setSelectedWordClass] = useState(" text-persian-green  relative font-bold ");
    const [knownWordClass, setKnownWordClass] = useState("relative ");
    const [knownVocabularyWordClass, setKnownVocabularyWordClass] = useState(" text-gray-600 ");
    const [activeVocabularyWordClass, setActiveVocabularyWordClass] = useState(" border-b border-dotted border-gray-600  decoration-dotted ");
    const [splitString, setSplitString] = useState(targetLang !== "zh" ? /(\s+)/ : "");
    const [dotString, setDotString] = useState(targetLang !== "zh" ? ". " : "。");

    const [placementPage, setPlacementPage] = useState("intro");
    const [placementMode, setPlacementMode] = useState("unknown_words");

    const placementStartClickOnNewWords = () => {
        setPlacementPage("placement-text");
        setPlacementMode("unknown_words");
    }

    const placementStartClickOnKnownWords = () => {
        setPlacementPage("placement-text");
        setPlacementMode("known_words");
    }

    const [googleTranslations, setGoogleTranslations] = useState({});

    const [sentenceView, setSentenceView] = useState(false);
    const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);

    const [currentTextPage, setCurrentTextPage] = useState(0);
    const [sentencesPerPage, setSentencesPerPage] = useState(7);

    const [clickedUnknownWords, setClickedUnknownWords] = useState([]);
    const [listOfAllUnknownWords, setListOfAllUnknownWords] = useState(null);
    const [clickedKnownWords, setClickedKnownWords] = useState([]);

    const [alreadyKnownVocabularyPopup, setAlreadyKnownVocabularyPopup] = useState(false);

    const [ignoreList, setIgnoreList] = useState([".", ",", "?", "!", "。", "，", ":", ";", "`", "¿", "？", "！", "-", "(", ")"]);

    const [wordsToBeSaved, setWordsToBeSaved] = useState([]);

    const [page, setPage] = useState("text"); //text
    const [wordsToBeSavedSelected, setWordsToBeSavedSelected] = useState(null);
    const [vocabInformation, setVocabInformation] = useState(null);
    const [selectedVocabularyMetadata, setSelectedVocabularyMetadata] = useState(null);

    const [unknownWordRef, setUnknownWordRef] = useState(null);
    const [translationError, setTranslationError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const [popupLoading, setPopupLoading] = useState(false);

    const [showPinyin, setShowPinyin] = useState(false);

    const startReadingClick = () => {
        setPage("text");
    }

    const finishTextClick = () => {
        setPage("save-words");
    }

    const getWordRef = (word, sentenceIndex, wordIndex, childIndex, paragraphIndex) => {
        return word+"_"+sentenceIndex+"_"+wordIndex+"_"+childIndex+"_"+paragraphIndex;
    }

    const wordClick = async (word, wordIndex, sentenceIndex, childIndex, paragraphIndex) => {
/*         ignoreList.forEach(ignore=>{
            word = word.replace(ignore, "");
        }) */
        setAlreadyKnownVocabularyPopup(false);
        setPopupLoading(true);
        word = removeIgnoreListFromWord(word).toLowerCase();
        console.log("Unknown: ", unknownWordRef, vocabInformation, word);
        setSelectedVocabularyMetadata(null);
        setUnknownWordRef(vocabInformation === getWordRef(word, sentenceIndex, wordIndex, childIndex, paragraphIndex) ? null : getWordRef(word, sentenceIndex, wordIndex, childIndex, paragraphIndex));
        //setVocabInformation(vocabInformation === getWordRef(word, sentenceIndex, wordIndex, childIndex, paragraphIndex) ? null : getWordRef(word, sentenceIndex, wordIndex, childIndex, paragraphIndex));
        if (ignoreList.includes(word)){
            console.log("ignore: ", word);
            return null} //!showVocabulary || 
        if (word === "" || word === " "){return null}
        if (Number.isInteger(parseInt(word))){return null}
        console.log(vocabularyTargetWordsInTargetLanguage[targetLang].includes(word));
        if (currentUser !== null && vocabularyTargetWordsInTargetLanguage[targetLang].includes(word)){
            if (clickedKnownWords.includes(word)){
                // remove
                let copy = JSON.parse(JSON.stringify(clickedKnownWords));
                setClickedKnownWords(copy.filter(n=>n !== word));
            } else {
                setClickedKnownWords([...clickedKnownWords, word]);
            }
            if (activeVocabularyTargetWords[targetLang].includes(word)){
                console.log("Word: ", word,  getWordRef(word, sentenceIndex, wordIndex, childIndex));
                //setSelectedVocabularyMetadata(null);
                let r= await fetchTargetWordFromVocabulary(word, targetLang);
                console.log(r);
                setSelectedVocabularyMetadata(r);
            } else {
                console.log("yes")
                setAlreadyKnownVocabularyPopup(true);
            }
            
        }
        else {
            if (clickedUnknownWords.includes(word)){
                // remove - remove with delete button instead
                //let copy = JSON.parse(JSON.stringify(clickedUnknownWords));
                //setClickedUnknownWords(copy.filter(n=>n !== word));
            } else {
                setClickedUnknownWords([...clickedUnknownWords, word]);
            }
            if (currentUser !== null){
                if (googleTranslations === null || !googleTranslations.hasOwnProperty(word)){
                    // translate word
                    setGoogleTranslationsLoading(true);
                    let trans = await translateText(word, sourceLanguage);
                    if (trans.success){
                        setTranslationError(false);
                        setErrorMsg("");
                        if (trans.data !== null && trans.data !== false && trans.data !== undefined && trans.data.length > 0){
                            let copy = {};
                            if (googleTranslations !== null){
                                copy = JSON.parse(JSON.stringify(googleTranslations));
                            }
                            copy[word] = trans.data[0].toLowerCase().trim();
                            setGoogleTranslations(copy);
                        }
                    } else {
                        console.log(trans.error.message);
                        setTranslationError(true);
                        setErrorMsg(trans.error.message);
                    }
                    setGoogleTranslationsLoading(false);
                } 
            }
        }
        setPopupLoading(false);
    }

    const addAllUnknownWords = () => {

    }

    const nextStepClick = () => {
        console.log("click")
        setPage("save-words");
        setTranslationError(false);
        setErrorMsg("");
        let list = [];
        let selected = {};
        clickedUnknownWords.forEach(word=>{
            let obj = {
                'target': removeIgnoreListFromWord(word),
                'source': googleTranslations.hasOwnProperty(word) ? googleTranslations[word] : ''
            };
            list.push(obj);
            selected[removeIgnoreListFromWord(word)] = true;
        });
        setWordsToBeSaved([...list]);
        setWordsToBeSavedSelected(selected);
    }

    const removeIgnoreListFromWord = (word) => {
        ignoreList.forEach(ignore=>{
            word = word.replaceAll(ignore, "");
        }) 
        return word;
    }

    const backToTextClick = () => {
        setPage("text");
        setWordsToBeSaved([]);
    }

    const selectWordToBeSaved = (word) => {
        console.log(wordsToBeSaved);
        word = removeIgnoreListFromWord(word);
        if (wordsToBeSaved.indexOf(word)){
            // remove
            let copy = JSON.parse(JSON.stringify(wordsToBeSaved));
            setWordsToBeSaved(copy.filter(n=>n !== word));
        } else {
            setWordsToBeSaved([...wordsToBeSaved, word]);
        }
    }

    const onSourceChange = (word, e) => {
        let copy = JSON.parse(JSON.stringify(wordsToBeSaved));
        for (let i=0; i<copy.length; i++){
            if (copy[i].target === word.target){
                copy[i].source = e.target.value;
            }
        }
        setWordsToBeSaved(copy);
    }

    const onTargetChange = (word, e) => {
        let copy = JSON.parse(JSON.stringify(wordsToBeSaved));
        for (let i=0; i<copy.length; i++){
            if (copy[i].target === word.target){
                copy[i].target = e.target.value;
            }
        }
        setWordsToBeSaved(copy);
    }



    const selectionOnChange = (word) => {
        let selected = JSON.parse(JSON.stringify(wordsToBeSavedSelected));
        selected[word.target] = !selected[word.target];
        setWordsToBeSavedSelected(selected);
    }

    const [allSelected, setAllSelected] = useState(false);

    useEffect(()=>{
        if (wordsToBeSavedSelected !== null){
            let allSelectedTemp = true;
            Object.keys(wordsToBeSavedSelected).forEach((selected)=>{
                if (wordsToBeSavedSelected[selected] === false){
                    allSelectedTemp = false;
                }
            });
            console.log(allSelectedTemp);
            setAllSelected(allSelectedTemp);
        }
    },[wordsToBeSavedSelected, setAllSelected]);

    const selectAllToBeSaved = () => {
        let selected = JSON.parse(JSON.stringify(wordsToBeSavedSelected));
        Object.keys(selected).forEach((sel)=>{
            selected[sel] = true;
        });
        setWordsToBeSavedSelected(selected);
    }

    const deselectAllToBeSaved = () => {
        let selected = JSON.parse(JSON.stringify(wordsToBeSavedSelected));
        Object.keys(selected).forEach((sel)=>{
            selected[sel] = false;
        });
        setWordsToBeSavedSelected(selected);
    }

    const [translating, setTranslating] = useState(false);
    const [googleTranslationsLoading, setGoogleTranslationsLoading] = useState(false);
    const [googleTranslationSentenceLoading, setGoogleTranslationSentenceLoading] = useState(false);

    const autoTranslateAll = async () => {
        setTranslating(true);
        let list = [];
        wordsToBeSaved.forEach((word)=>{
            if (word.source === ""){
                // only translate remaining
                list.push(word.target);
            }
        })
        let results = await translateMultipleTexts(list, userLanguages.filter(u=>u['target_language'] === targetLang)[0]['source_language']);
       
        if (results.success){
            let copy = JSON.parse(JSON.stringify(wordsToBeSaved)); 
            let googleCopy = googleTranslations !== null ? JSON.parse(JSON.stringify(googleTranslations)) : {};
            for (const result of results.data){
                let i = 0;
                for (const c of copy){
                    if (c['target'] === result['text']){
                        copy[i]['source'] = result['translated'][0];
                        googleCopy[result['text']] = result['translated'][0];
                    }
                    i = i + 1;
                }
            }
            setWordsToBeSaved(copy);
            setGoogleTranslations(googleCopy);
        } else {
            setTranslationError(true);
            setErrorMsg(results.error.message);
        }
        setTranslating(false);
    }

    const [showDeckSelectorModal, setShowDeckSelectorModal] = useState(false);

    const [selectedDeck, setSelectedDeck] = useState(null);
    const openDeckSelectorClick = () => {
        setShowDeckSelectorModal(true);
    }
    const closeDeckSelectorClick = () => {
        setShowDeckSelectorModal(false);
    }
    
    const addWordsToDeckClick = () => {
        openDeckSelectorClick();
    }

    const [savingProgress, setSavingProgress] = useState(0);
    const [saving, setSaving] = useState(false);
    const [toBeSavedNumber, setToBeSavedNumber] = useState(null);
    const [showHowToUse, setShowHowToUse] = useState(false);
    const [showSelectedNewWords, setShowSelectedNewWords] = useState(true);

    const saveWordsToDeck = async (deck) => {
        setSaving(true);
        setToBeSavedNumber(wordsToBeSaved.length);
        // add words to setSelectedDeck
        console.log("save to Deck: ", deck, wordsToBeSaved);
        let saved = [];
        if (deck !== null){
            for (const word of wordsToBeSaved){
                if (wordsToBeSavedSelected[word.target] === true){
                    if (word.target.trim() !== "" && word.source.trim() !== ""){
                        await addItemToDeck({deck_id: deck.id, sourceInput: word.source, targetInput: word.target, customFieldsInput: null}).catch((err)=>{console.log("error: ", err)});
                        saved.push(word.target);
                    }
                    setSavingProgress(savingProgress+1);
                }
            }
        }
        let missing = [];
        let missingTargets = [];
        for (const word of wordsToBeSaved){
            if (!saved.includes(word.target)){
                missing.push(word);
                missingTargets.push(word.target);
            }
        }
        //setVocabulary([...vocabularyTargetWordsInTargetLanguage, ...saved]);
        let copy = JSON.parse(JSON.stringify(activeVocabularyTargetWords));
        copy[targetLang] = [...activeVocabularyTargetWords[targetLang], ...saved];
        setActiveVocabularyTargetWords(copy);
        setWordsToBeSaved(missing);
        setClickedUnknownWords(missingTargets);
        if (missing.length === 0){
            setPage("text");
        }
        setSaving(false);
        setSavingProgress(0);
        setToBeSavedNumber(null);
    }

    const addWordsToKnownVocabularyClick = async () => {
        let list = [];
        for (const word of wordsToBeSaved){
            if (wordsToBeSavedSelected[word.target] === true){
                if (word.target.trim() !== ""){
                    //  add 
                    list.push(word.target.trim().toLowerCase());
                }
            }
        }
        setSaving(true);
        setToBeSavedNumber(list.length);
        await addTargetWordsToKnownVocabulary(list, targetLang).catch(err=>{return false});
        //setVocabulary([...vocabularyTargetWordsInTargetLanguage, ...list]);
        let copy = JSON.parse(JSON.stringify(knownVocabulary));
        copy[targetLang] = [...knownVocabulary[targetLang], ...list];
        setKnownVocabulary(copy);
        let missing = [];
        for (const word of wordsToBeSaved){
            if (!list.includes(word.target.toLowerCase())){
                missing.push(word);
            }
        }
        setWordsToBeSaved(missing);
        if (missing.length === 0){
            setPage("text");
            setClickedUnknownWords([]);
        }
        setSaving(false);
        setSavingProgress(0);
        setToBeSavedNumber(null);
    }

    const addClickedPlacementWordsToKnownVocabulary = async () => {
        let clickedList = [];
        for (const word of clickedPlacementWords){
            if (word.trim() !== ""){
                //  add 
                clickedList.push(word.trim().toLowerCase());
            }
        }
        console.log("List: ", clickedList);
        setSaving(true);
        setToBeSavedNumber(clickedList.length);
        if (placementMode === "known_words"){
            // we need to add these words to known vocabulary
            await addTargetWordsToKnownVocabulary(clickedList, targetLang).catch(err=>{return false});
            let copy = JSON.parse(JSON.stringify(knownVocabulary));
            copy[targetLang] = [...knownVocabulary[targetLang], ...clickedList];
            setKnownVocabulary(copy);
        }
        else if (placementMode === "unknown_words"){
            // we need to add all words except these to known vocabulary
            const knownWords = [];
            for (const sentence of sentences){
                for (const word of sentence.split(splitString)){
                    if (!clickedList.includes(word.trim().toLowerCase()) && word.trim() !== "" && !ignoreList.includes(word)){
                        let filteredWord = removeIgnoreListFromWord(word.trim().toLowerCase());
                        knownWords.push(filteredWord);     
                    }
                }
            }
            //remove duplicates from knownwords
            const uniqueKnownWords = [...new Set(knownWords)];
            console.log("Known words: ", uniqueKnownWords);
            await addTargetWordsToKnownVocabulary(uniqueKnownWords, targetLang).catch(err=>{return false});
        }
        //setVocabulary([...vocabularyTargetWordsInTargetLanguage, ...list]);
        setPlacementPage("success");
        setSaving(false);
        setSavingProgress(0);
        setToBeSavedNumber(null);
    }

    const [vocabularyPercentage, setVocabularyPercentage] = useState(null);

    const calculatePercentageOfVocabulary = () => {
        let listOfUnknownWords = [];
        let allWords = [];
        textBody.forEach((paragraph)=>{
            paragraph.children.forEach((child)=>{
                child.text.split("\n").forEach((sentence)=>{
                    sentence.split(splitString).forEach((word)=>{
                        allWords.push(removeIgnoreListFromWord(word.trim().toLowerCase()));
                        if (!ignoreList.includes(word) && !vocabularyTargetWordsInTargetLanguage[targetLang].includes(removeIgnoreListFromWord(word.toLowerCase()))){
                            if (word.trim() !== ""){
                                listOfUnknownWords.push(removeIgnoreListFromWord(word.trim().toLowerCase()));
                            }
                        }
                    })
                })
            })
        });
        listOfUnknownWords = [...new Set(listOfUnknownWords)]; //unique
        allWords = [...new Set(allWords)];
        console.log(listOfUnknownWords.length, allWords.length);
        setListOfAllUnknownWords(listOfUnknownWords);
        setVocabularyPercentage(Math.round(100*((allWords.length - listOfUnknownWords.length)/allWords.length)));
    }

    const addAllUnknownWordsToList = () => {
        let list = [];
        textBody.forEach((paragraph)=>{
            paragraph.children.forEach((child)=>{
                child.text.split("\n").forEach((sentence)=>{
                    sentence.split(splitString).forEach((word)=>{
                        if (!ignoreList.includes(word) && !vocabularyTargetWordsInTargetLanguage[targetLang].includes(removeIgnoreListFromWord(word.toLowerCase()))){
                            if (word.trim() !== ""){
                                list.push(removeIgnoreListFromWord(word.trim().toLowerCase()));
                            }
                        }
                    })
                })
            })
        });
        list = [...new Set(list)]; //unique
        setClickedUnknownWords(list);
        let newList = [];
        let selected = {};
        list.forEach(word=>{
            let obj = {
                'target': removeIgnoreListFromWord(word),
                'source': googleTranslations.hasOwnProperty(word) ? googleTranslations[word] : ''
            };
            newList.push(obj);
            selected[removeIgnoreListFromWord(word)] = true;
        });
        setWordsToBeSaved([...newList]);
        setWordsToBeSavedSelected(selected);
    }

    const [showTranslations, setShowTranslations] = useState(true);

    const removeAllUnknownWordsFromList = () => {
        setClickedUnknownWords([]);
    }

    const removeWordFromList = (word) => {
        let copy = JSON.parse(JSON.stringify(clickedUnknownWords));
        setClickedUnknownWords(copy.filter(n=>n !== word));
    }

    const backgroundClick = () => {

    }

    const closePopup = (e) => {
        e.stopPropagation();
        if (selectedVocabularyMetadata !== null && vocabInformation !== null){
            setVocabInformation(null);
            setSelectedVocabularyMetadata(null);
        }
        if (unknownWordRef !== null){
            setUnknownWordRef(null);
            setTranslationError(false);
            setErrorMsg("");
        } 
    }

    const pinyinToggleClick = () => {
        setShowPinyin(!showPinyin);
    }

    const previousSentenceClick = () => {
        if (currentSentenceIndex !== null && currentSentenceIndex > 0){
            setCurrentSentenceIndex(currentSentenceIndex-1);
        }
    }

    const nextSentenceClick = () => {
        if (sentences !== null && currentSentenceIndex < sentences.length -1){
            setCurrentSentenceIndex(currentSentenceIndex+1);
        }
    }

    const placementTestClick = () => {
        setPage("placement");
    }

    const [clickedPlacementWords, setClickedPlacementWords] = useState([]);

    const placementWordClick = (word) => {
        if (clickedPlacementWords.includes(word)){
            setClickedPlacementWords(clickedPlacementWords.filter(n=>n !== word));
        }
        else {
            setClickedPlacementWords([...clickedPlacementWords, word]);
        }
    }

    const translateSentenceClick = async (sentence) => {
        if (googleTranslations.hasOwnProperty(removeIgnoreListFromWord(sentence.toLowerCase()))){return false}
        if (currentUser !== null){
            if (googleTranslations === null || !googleTranslations.hasOwnProperty(sentence)){
                // translate word
                setGoogleTranslationSentenceLoading(true);
                let trans = await translateText(sentence, sourceLanguage);
                if (trans.success){
                    setTranslationError(false);
                    setErrorMsg("");
                    if (trans.data !== null && trans.data !== false && trans.data !== undefined && trans.data.length > 0){
                        let copy = {};
                        if (googleTranslations !== null){
                            copy = JSON.parse(JSON.stringify(googleTranslations));
                        }
                        copy[removeIgnoreListFromWord(sentence.toLowerCase())] = trans.data[0].toLowerCase().trim();
                        console.log("translations: ", copy);
                        setGoogleTranslations(copy);
                    }
                } else {
                    console.log(trans.error.message);
                    setTranslationError(true);
                    setErrorMsg(trans.error.message);
                }
                setGoogleTranslationSentenceLoading(false);
                setClickedUnknownWords([...clickedUnknownWords, removeIgnoreListFromWord(sentence.toLowerCase())]);
            } 
        }
    }

    /*
                                {text !== null &&
                                <ItemRating itemType={"text"} item={text} />
                                }
                                  <div className="italic">{text !== null && text.title}</div>
                            <div className="text-sm  font-normal flex flex-row place-items-center gap-4 justify-center">
                                {currentUser !== null &&
                                        <div data-for="tooltip-study-text" data-tip="Percentage of words in your vocabulary" className="  font-bold text-xs shadow-xl bg-charcoal text-white rounded-full px-2  text-center py-2">
                                            {vocabularyPercentage !== null ? vocabularyPercentage : <FontAwesomeIcon icon={faLoader} className="fa-spin" />}%
                                        </div>
                                    }

                            </div>
                                                                            {showPinyin &&
                                                    <>
                                                        <div className="muted-text text-base text-center sm:text-xl lg:text-xl">
                                                        {sentences[currentSentenceIndex].split(splitString).map((word, wordIndex)=>(
                                                            <>
                                                                <span className={(showVocabulary && !ignoreList.includes(word)) ? ("   " + ((knownVocabulary[targetLang].includes(removeIgnoreListFromWord(word.toLowerCase())) || Number.isInteger(parseInt(word))) ? "" : activeVocabularyTargetWords[targetLang].includes(removeIgnoreListFromWord(word.toLowerCase())) ? "" : !clickedUnknownWords.includes(removeIgnoreListFromWord(word.toLowerCase())) ? unknownWordClass : selectedWordClass)) : ""} key={"pinyin_"+wordIndex}>
                                                                    {!ignoreList.includes(word) && <>{pinyin(word)} </>}
                                                                </span>
                                                            </>
                                                        ))}
                                                        </div>
                                                    </>
                                                }
                                                <div className="fixed bg-white h-24 sm:h-auto overflow-auto rounded-xl shadow-xl right-8 z-30 bottom-24 sm:bottom-8 p-4">
                    <div className="flex flex-col gap-4">
                        {currentUser !== null &&
                            <div onClick={()=>setShowVocabulary(!showVocabulary)} className="text-center text-sm cursor-pointer">
                                {showVocabulary ?
                                    <>
                                        <div className="flex text-gray-600 font-bold flex-row justify-start gap-2">
                                            <div>
                                                <FontAwesomeIcon icon={faEye} className="" />
                                            </div>
                                            <div>
                                                Vocabulary
                                            </div>
                                        </div>
                                    </>
                                : 
                                    <>
                                        <div className="flex text-gray-600 font-bold flex-row justify-start gap-2">
                                            <div>
                                                <FontAwesomeIcon icon={faEyeSlash} className="" />
                                            </div>
                                            <div>
                                                Vocabulary
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        }
                        {currentUser !== null &&
                            <div  className="text-center text-sm cursor-pointer">
                                {page === "save-words" ?
                                    <>
                                        <div onClick={backToTextClick} className="flex text-gray-600 font-bold flex-row justify-start gap-2">
                                            <div>
                                                <FontAwesomeIcon icon={faEye} className="" />
                                            </div>
                                            <div>
                                                Save words
                                            </div>
                                        </div>
                                    </>
                                : 
                                    <>
                                        <div onClick={nextStepClick} className="flex text-gray-600 font-bold flex-row justify-start gap-2">
                                            <div>
                                                <FontAwesomeIcon icon={faEyeSlash} className="" />
                                            </div>
                                            <div>
                                                Save words
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        }
                        <div onClick={()=>setSentenceView(!sentenceView)} className="text-center text-sm cursor-pointer">
                                {sentenceView ?
                                    <>
                                        <div className="flex text-gray-600 font-bold flex-row justify-start gap-2">
                                            <div>
                                                <FontAwesomeIcon icon={faEye} className="" />
                                            </div>
                                            <div>
                                                Sentence view
                                            </div>
                                        </div>
                                    </>
                                : 
                                    <>
                                        <div className="flex text-gray-600 font-bold flex-row justify-start gap-2">
                                            <div>
                                                <FontAwesomeIcon icon={faEyeSlash} className="" />
                                            </div>
                                            <div>
                                                Sentence view
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        
                        {targetLang === "zh" &&
                        <div className="text-center text-sm cursor-pointer" onClick={pinyinToggleClick}>
                            {showPinyin ?
                                    <>
                                        <div className="flex text-gray-600 font-bold flex-row justify-start gap-2">
                                            <div>
                                                <FontAwesomeIcon icon={faEye} className="" />
                                            </div>
                                            <div>
                                                Pinyin
                                            </div>
                                        </div>
                                    </>
                                : 
                                    <>
                                        <div className="flex text-gray-600 font-bold flex-row justify-start gap-2">
                                            <div>
                                                <FontAwesomeIcon icon={faEyeSlash} className="" />
                                            </div>
                                            <div>
                                                Pinyin
                                            </div>
                                        </div>
                                    </>
                                }
                        </div>
                        }
                    </div>
                </div>
                                
    */

/*     const checkIfHotkey = (e) => {
        console.log("hot key", e.key)
        if (e.key === "ArrowLeft"){
            console.log("Go right")
            previousSentenceClick();
        } else if (e.key === "ArrowRight"){
            console.log("Go right")
            nextSentenceClick();
        }
    };
    
      {page !== "save-words" && currentUser !== null &&
                        <div data-for="tooltip-text-menu" data-tip="Save words" className="text-center cursor-pointer" onClick={nextStepClick}>
                            <div className="flex text-gray-600 font-bold flex-row place-content-center justify-start gap-2">
                                <div>
                                    <FontAwesomeIcon icon={faFloppyDisk} className="" />
                                </div>
                            </div>
                        </div>
                    }
    useEffect(()=>{
        document.addEventListener('keydown', (e)=>checkIfHotkey(e));
        return () => document.removeEventListener("keydown", (e)=>checkIfHotkey(e));
    },[checkIfHotkey])
   
    <Link className="no-underline" to="/texts">
                        <FontAwesomeIcon icon={faX} />
                    </Link>
                    <div className="rounded-xl overflow-hidden  w-full m-auto">
                                    <img alt={text.title+" thumbnail"} className="w-full" src={DefaultImage} />
                                </div>
                                                    <div>
                        <TextOptions setPage={setPage} placementTestClick={placementTestClick} sentenceView={sentenceView} sentences={sentences} currentSentenceIndex={currentSentenceIndex} showPinyin={showPinyin} setSentenceView={setSentenceView} translateSentenceClick={translateSentenceClick} setShowVocabulary={setShowVocabulary} showVocabulary={showVocabulary} pinyinToggleClick={pinyinToggleClick} targetLang={targetLang} page={page} currentUser={currentUser} nextStepClick={nextStepClick} />
                    </div>
 */

    

    return (
        <>
            <ReactTooltip place="bottom" id="tooltip-study-text" effect="solid" />
            <div className="p-6">
                <div className="paper-background">

                </div>
            <div className="text-sm sm:text-base mb-4 flex flex-row justify-end place-items-center  text-gray-600">
                    <div className="">
                        <Link className="" to={returnLink}>
                            <FontAwesomeIcon icon={faX} />
                        </Link>
                    </div>

                    
                </div>
            <div onClick={backgroundClick} className={(saving ? 'opacity-30 ' : 'opacity-100 ' )+ "  relative min-h-screen h-full justify-center flex flex-col pb-16 gap-6 overflow-auto  "}>
                 
                    <div className="flex flex-col gap-4">
                    {saving && 
                        <>
                            <div className="z-20 fixed left-1/2 top-1/2 text-3xl">
                                <FontAwesomeIcon icon={faLoader} className="fa-spin" />
                            </div>
                        </>
                    }
                    {page === "intro" &&
                        <>
                            
                            <div className="flex md:text-base text-sm md:p-6 flex-col w-full lg:w-1/2 m-auto gap-3">
                            <div className="font-bold muted-text text-xl text-center">{text !== null && text.title}</div>
                            {text !== null &&
                            <>
                            <div className="my-4">
                            {(text.hasOwnProperty("thumbnail_500x500") && text['thumbnail_500x500'] !== null && text['thumbnail_500x500'].split("shutterstock").length === 2) ?
                            <>
                                <div className="rounded-xl overflow-hidden shadow-xl w-96 m-auto">
                                    <img alt={text.title+" thumbnail"} className="w-full" src={text['thumbnail_500x500']} />
                                </div>
                            </>
                            :
                            <>
                                
                            </>
                        
                            }
                            </div>
                            </>
                        }
                            <div className="flex flex-row justify-between place-items-center gap-3">
                            
                            {currentUser !== null &&
                                <div className="muted-text cursor-pointer flex flex-row gap-2">
                                    <div data-for="tooltip-study-text" data-tip="Words I already know" className="text-charcoal">
                                        <FontAwesomeIcon icon={faBrain} />
                                    </div>
                                    <div>
                                        <span className="font-bold">{vocabularyPercentage !== null ? vocabularyPercentage : <FontAwesomeIcon icon={faLoader} className="fa-spin" />}%</span>
                                    </div>
                                    <div>
                                        with {listOfAllUnknownWords !== null ? listOfAllUnknownWords.length : <FontAwesomeIcon icon={faLoader} className="fa-spin" />} new words
                                    </div>
                                </div>
                            }
                            {text !== null &&
                                <> 
                                <div className="flex flex-row  muted-text place-items-center gap-4">
                                    
                                    <ItemRating itemType={"text"} item={text} />
                                </div>
                                </>
                            }
                            </div>
   
                            <div className="flex flex-col gap-1 justify-center">
                                <Button text="Take placement test" color="charcoal" onClick={placementTestClick} />
                                <Button text="Study text" color="green" icon={faAngleRight} onClick={startReadingClick} />
                            </div>
                            </div>
                        </>
                    }
                    {page === "text" &&
                        <>
                            
                            <div className="flex  flex-row place-items-center gap-6  justify-end">
                            
                            
                            
                            </div>
                            {showHowToUse &&
                            <div className="text-base lg:w-4/6 mx-auto w-full flex flex-col gap-2 text-left text-gray-600">
                                {currentUser === null &&
                                    <>
                                        <div className="text-sm italic">
                                            If you're logged in to a Yalango account, the words in the text below will be colored as explained here.
                                        </div>
                                    </>
                                }
                                <div className="flex flex-row gap-4 text-sm justify-start place-items-center">
                                    <div className={knownWordClass + " bg-white p-2 rounded-lg shadow-lg w-20 text-center"}>
                                        Learnt
                                    </div>
                                    <div>
                                        These words are part of your learnt vocabulary, meaning words you already know and don't need to review.
                                    </div>
                                </div>
                                <div className="flex flex-row gap-4 text-sm justify-start place-items-center">
                                    <div className={" bg-white p-2 rounded-lg shadow-lg w-20 text-center"}>
                                        <span className={activeVocabularyWordClass}>Active</span>
                                    </div>
                                    <div>
                                        These words are already in your active vocabulary, meaning words you're actively learning. Click to see the vocabulary item. 
                                    </div>
                                </div>
                                <div className="flex flex-row gap-4 text-sm justify-start place-items-center">
                                    <div className={unknownWordClass + " bg-white p-2 rounded-lg shadow-lg w-24 text-center"}>
                                        New
                                    </div>
                                    <div>
                                        These words are new to you. They are neither in your learnt vocabulary nor active vocabulary. Click to see a translation and add it to the 'new words' list under the text.
                                    </div>
                                </div>
                                <div className="flex flex-row gap-4 text-sm justify-start place-items-center">
                                    <div className={selectedWordClass + " bg-white p-2 rounded-lg shadow-lg w-20 text-center"}>
                                        Selected
                                    </div>
                                    <div>
                                        These words have been added to the 'new words' list under the text.
                                    </div>
                                </div>
                            </div>
                            }

                            <div className="flex h-full flex-col gap-24">
                                {selectedTab === "text" &&
                                <div className="w-full h-full   pb-64  lg:w-4/6 3xl:w-1/2 m-auto flex flex-col gap-8">
                                  
                                    {(!sentenceView && textBody !== null && textBody !== undefined) &&
                                        <>
                                            
                                            
                                            {textBody.map((paragraph, paragraphIndex)=>(
                                                <>
                                                    <div className="flex   flex-col gap-2" key={"p_"+paragraphIndex}>
                                                        {paragraph.children.map((child, childIndex)=>(
                                                            <>
                                                                <div className="flex flex-col gap-6" key={"child_"+childIndex}>
                                                                    {child.text.split("\n").map((paragraph, paragraphIndex)=>(
                                                                        <>
                                                                        <div className="flex flex-col gap-2" key={"child_"+childIndex}>
                                                                            {paragraph.split(dotString).map((sentence, sentenceIndex)=>(
                                                                                <>
                                                                            <div key={"sentence_"+sentenceIndex}>
                                                                            <p className="text-xl">
                                                                                {sentence.split(splitString).map((word, wordIndex)=>(
                                                                                    <>
                                                                                        {(currentUser !== null && vocabularyTargetWordsInTargetLanguage !== null && vocabularyTargetWordsInTargetLanguage.hasOwnProperty(targetLang)) ?
                                                                                            <>
                                                                                                <RenderWord key={"word_"+wordIndex} alreadyKnownVocabularyPopup={alreadyKnownVocabularyPopup} closePopup={closePopup} showPinyin={showPinyin} selectedVocabularyMetadata={selectedVocabularyMetadata} allLanguages={allLanguages} popupLoading={popupLoading} translationError={translationError} googleTranslations={googleTranslations} sourceLanguage={sourceLanguage} errorMsg={errorMsg} sentence={sentence} dotString={dotString} currentUser={currentUser} vocabInformation={vocabInformation} splitString={splitString} sentenceIndex={sentenceIndex} wordIndex={wordIndex} childIndex={childIndex} paragraphIndex={paragraphIndex} wordClick={wordClick} word={word} unknownWordRef={unknownWordRef} getWordRef={getWordRef} selectedWordClass={selectedWordClass} unknownWordClass={unknownWordClass} activeVocabularyWordClass={activeVocabularyWordClass} knownVocabularyWordClass={knownVocabularyWordClass} clickedUnknownWords={clickedUnknownWords} activeVocabularyTargetWords={activeVocabularyTargetWords} targetLang={targetLang} knownVocabulary={knownVocabulary} ignoreList={ignoreList} removeIgnoreListFromWord={removeIgnoreListFromWord} showVocabulary={showVocabulary} />
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                <span key={"word_"+wordIndex} >
                                                                                                {word}
                                                                                                </span>
                                                                                            </>
                                                                                        }
                                                                                    
                                                                                    </>
                                                                                ))}
                                                                            
                                                                               
                                                                            </p>
                                                                            </div>
                                                                        
                                                                        </>
                                                                        ))}
                                                                        
                                                                        </div>
                                                                        </>
                                                                    ))}
                                                                    
                                                                </div>
                                                            </>
                                                        ))}
                                                        
                                                    </div>
                                                    
                                                </>
                                            ))}
                                            <div className="flex flex-row justify-center">
                                                <Button onClick={nextStepClick} text="Continue" />
                                            </div>

                                            <div className="text-gray-600 text-xs text-center mt-4">
                                                    This text was last updated {text !== null && text.last_updated_timestamp.toDate().toDateString()} by {text !== null && text.author}
                                            </div>
                                        </>
                                    }
                                    {sentenceView && sentences !== null && sentences.length > currentSentenceIndex && currentSentenceIndex >= 0 &&
                                        <>
                                            <div className="flex flex-col gap-8 mt-12">
                                                
                                                <div className="flex h-full flex-col justify-center gap-4">
                                                    <p className="text-xl text-center sm:text-2xl lg:text-3xl">
                                                        {sentences[currentSentenceIndex].split(splitString).map((word, wordIndex)=>(
                                                            <>
                                                                <RenderWord key={"word_"+wordIndex} showPinyin={showPinyin} closePopup={closePopup} selectedVocabularyMetadata={selectedVocabularyMetadata} allLanguages={allLanguages} popupLoading={popupLoading} translationError={translationError} googleTranslations={googleTranslations} sourceLanguage={sourceLanguage} errorMsg={errorMsg} sentence={sentences[currentSentenceIndex]} dotString={dotString} currentUser={currentUser} vocabInformation={vocabInformation} splitString={splitString} sentenceIndex={currentSentenceIndex} wordIndex={wordIndex} childIndex={0} paragraphIndex={0} wordClick={wordClick} word={word} unknownWordRef={unknownWordRef} getWordRef={getWordRef} selectedWordClass={selectedWordClass} unknownWordClass={unknownWordClass} activeVocabularyWordClass={activeVocabularyWordClass} knownVocabularyWordClass={knownVocabularyWordClass} clickedUnknownWords={clickedUnknownWords} activeVocabularyTargetWords={activeVocabularyTargetWords} targetLang={targetLang} knownVocabulary={knownVocabulary} ignoreList={ignoreList} removeIgnoreListFromWord={removeIgnoreListFromWord} showVocabulary={showVocabulary} />
                                                            </>
                                                        ))}
                                                    </p>

                                                    <p className="text-xl muted-text text-center sm:text-2xl lg:text-3xl">
                                                        {googleTranslations.hasOwnProperty(removeIgnoreListFromWord(sentences[currentSentenceIndex].toLowerCase())) && 
                                                            <>
                                                                {googleTranslations[removeIgnoreListFromWord(sentences[currentSentenceIndex].toLowerCase())]}
                                                            </>
                                                        }
                                                        {googleTranslationSentenceLoading &&
                                                            <>
                                                                <div className="flex flex-row justify-center muted-text">
                                                                    <FontAwesomeIcon icon={faLoader} className="fa-spin" />
                                                                </div>
                                                            </>
                                                        }
                                                    </p>
                                                    
                                                </div>
                                                <div className="flex flex-row muted-text justify-center gap-12 text-lg ">
                                                    <div className="cursor-pointer" onClick={previousSentenceClick}>
                                                        <FontAwesomeIcon icon={faAngleLeft} />
                                                    </div>
                                                    <div>
                                                        {currentSentenceIndex+1} / {sentences.length}
                                                    </div>
                                                    <div className="cursor-pointer"  onClick={nextSentenceClick}>
                                                        <FontAwesomeIcon icon={faAngleRight} />
                                                    </div>
                                                </div>
                                                {currentSentenceIndex+1 === sentences.length &&
                                                <div className="flex flex-row justify-center">
                                                    <Button text="Continue" icon={faAngleRight} color="charcoal" onClick={nextStepClick} />
                                                </div>
                                                }  </div>
                                        </>
                                    }
                                </div>
                                }
                                {selectedTab === "words" &&
                                <>
                                {(currentUser !== null && userIsLearningThisLanguage) &&
                                    <>
                                <div className="my-4 w-full flex flex-col gap-2 lg:w-4/6 m-auto">
                                    <h2><span className={selectedWordClass}>Selected</span> <span className={unknownWordClass}>new</span> words ({clickedUnknownWords.length})</h2>
                                    <div className="text-gray-600 text-xs text-center">
                                        These words are not yet in your vocabulary. Click on a <span className={unknownWordClass}>new</span> word to add it to the list. 
                                    </div>
                                    <div className="flex flex-col gap-3 sm:flex-row justify-evenly">
                                        <div onClick={addAllUnknownWordsToList} className={"underline text-center cursor-pointer text-sm"}>
                                            Add all unknown words to list
                                        </div>
                                        {clickedUnknownWords.length > 0 &&
                                        <div onClick={removeAllUnknownWordsFromList} className="underline text-center cursor-pointer text-sm">
                                            Remove all items from list
                                        </div>
                                        }
                                    </div>
                                    <div className="text-center text-gray-600 underline text-sm cursor-pointer" onClick={()=>setShowSelectedNewWords(!showSelectedNewWords)}>
                                        {showSelectedNewWords ? "Hide list" : "Show list"}
                                    </div>
                                    {showSelectedNewWords === true &&
                                        <div className="">

                                            <div className="flex flex-col gap-3">
                    
                                                
                                                
                                                <div className="flex flex-col gap-3">
                                                    {clickedUnknownWords.map((word, index)=>(
                                                        <>
                                                            <div className="bg-white rounded-lg shadow-lg p-2 text-gray-600 text-center relative" key={"word_"+index}>
                                                                <div onClick={()=>removeWordFromList(word)} className="absolute left-2 top-2 cursor-pointer px-2 text-sm text-burnt-sienna">
                                                                    <FontAwesomeIcon icon={faTrash} />
                                                                </div>
                                                                <div className="flex flex-row w-5/6 text-lg m-auto place-items-center justify-start gap-2">
                                                                    <div className="w-8">
                                                                        <CountryFlag size="1.3em" countryCode={allLanguages[0][targetLang]['country_flag']} flagName={allLanguages[0][targetLang]['name']['en']} />
                                                                    </div>
                                                                    <div className="text-sm overflow-x-auto">
                                                                        {removeIgnoreListFromWord(word.toLowerCase())}
                                                                    </div>
                                                                </div>
                                                                <div className="flex flex-row w-5/6 text-lg m-auto place-items-center justify-start gap-2">
                                                                    <div className="w-8">
                                                                        <CountryFlag size="1.3em" countryCode={allLanguages[0][sourceLanguage]['country_flag']} flagName={allLanguages[0][sourceLanguage]['name']['en']} />
                                                                    </div>
                                                                    <div className="text-sm">
                                                                        {googleTranslations.hasOwnProperty(removeIgnoreListFromWord(word.toLowerCase())) ? googleTranslations[removeIgnoreListFromWord(word.toLowerCase())] : <><span className="italic">Add on the next page</span></>}
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>
                                                        </>
                                                    ))}
                                                </div>
                                                
                                            </div>
                                        </div>
                                    }
                                    {(clickedUnknownWords.length > 0) &&
                                        <div className="text-center my-3">
                                            <Button onClick={nextStepClick} text="Choose how to save these words" color="green" />
                                        </div>
                                    }
                                </div>
                                
                                </>
                                }
                                </>
                                }
                                {showComments &&
                                <div>
                                    <CommentSection commentSourceMetadata={text} sourceId={text_id} commentSource="text" />
                                </div>
                                }
                            </div>
                        </>
                    }
                    {page === "placement" &&
                        <>
                            {placementPage === "intro" &&
                                <div className="text-sm lg:w-4/6 mx-auto w-full flex flex-col gap-4 text-left">
                                    <h1>Placement test</h1>
                                    <div className="underline cursor-pointer" onClick={()=>setPage("text")}>
                                        Go back to text
                                    </div>
                                    <div>
                                        Let Yalango know how much you understand of this text. Words you understand will automatically be added to your known vocabulary. We recommend doing this for multiple texts in order to get more accurate text suggestions in the future.
                                    </div>
                                    <div>
                                        We offer two modes depending on your current language level. You can either click on words you already know, or you can click on words you don't understand. Pick the most efficient option for you based on your current level.
                                    </div>
                                    <div className="flex flex-col sm:flex-row gap-2 justify-center">
                                        <Button onClick={placementStartClickOnNewWords} color="red" text="Select all unknown words" />
                                        <Button onClick={placementStartClickOnKnownWords} color="green" text="Select all words I know" />
                                    </div>
                                    
                                </div>
                            }
                            {placementPage === "placement-text" &&
                                <>
                                   
                                    <div className="text-base   lg:w-4/6 mx-auto w-full flex flex-col gap-12 text-left">
                                        <div className="bg-charcoal p-3  rounded-xl shadow-lg text-white text-center text-sm flex flex-col gap-3  muted-text">
                                            <div className="font-bold">
                                            {placementMode === "unknown_words" ? "Click on the words you don't understand" : "Click on the words you already know"} and then click on the save button. <span onClick={()=>setPlacementPage("intro")} className="underline cursor-pointer">Change mode</span>
                                            </div>
                                            <div>
                                                {placementMode === "unknown_words" ? "This will save all words you don't click on to your known vocabulary." : "This will save the words you click on to your known vocabulary." }
                                            </div>
                                            <div className="underline cursor-pointer font-bold" onClick={()=>setClickedPlacementWords([])}>
                                            Reset selections
                                        </div>
                                        </div>
                                        
                                        <div className="pb-32">
                                            {textBody.map((paragraph, paragraphIndex)=>(
                                                        <>
                                                            <div className="flex   flex-col gap-2" key={"p_"+paragraphIndex}>
                                                                {paragraph.children.map((child, childIndex)=>(
                                                                    <>
                                                                        <div className="flex flex-col gap-6" key={"child_"+childIndex}>
                                                                            {child.text.split("\n").map((paragraph, paragraphIndex)=>(
                                                                                <>
                                                                                <div className="flex flex-col gap-2" key={"child_"+childIndex}>
                                                                                    {paragraph.split(dotString).map((sentence, sentenceIndex)=>(
                                                                                        <>
                                                                                    <div key={"sentence_"+sentenceIndex}>
                                                                                    <p className="text-lg  sm:text-lg lg:text-xl">
                                                                                        {sentence.split(splitString).map((word, wordIndex)=>(
                                                                                            <>
                                                                                                <span className="cursor-pointer border-b border-dotted hover:border-gray-600 border-transparent" onClick={()=>placementWordClick(word)} key={"word_"+wordIndex} >
                                                                                                    <span className={(clickedPlacementWords.includes(word) && placementMode === "known_words") ? "text-persian-green font-bold" : (clickedPlacementWords.includes(word) && placementMode === "unknown_words") ? "text-burnt-sienna font-bold" : "" }>
                                                                                                        {word}
                                                                                                    </span>
                                                                                                </span>
                                                                                            </>
                                                                                        ))}
                                                                                    
                                                                                    
                                                                                    </p>
                                                                                    </div>
                                                                                
                                                                                </>
                                                                                ))}
                                                                                
                                                                                </div>
                                                                                </>
                                                                            ))}
                                                                            
                                                                        </div>
                                                                    </>
                                                                ))}
                                                                
                                                            </div>
                                                            
                                                        </>
                                            ))}
                                        </div>  
                                    </div>
                                    <div className="fixed left-0 bottom-0 w-screen flex flex-col justify-center">
                                        <button 
                                        className="w-full p-4 font-bold text-white uppercase text-base md:text-lg persian-green-gradient"
                                        onClick={addClickedPlacementWordsToKnownVocabulary}>{saving ? <FontAwesomeIcon icon={faLoader} className="fa-spin" /> : "Save and exit"}</button>
                                         <button 
                                        className="w-full p-4 font-bold text-white uppercase text-base md:text-lg charcoal-gradient"
                                        onClick={()=>setPage("text")}>{"Exit test"}</button>
                                    </div>
                                </>
                            }
                            {placementPage === "success" &&
                                <>
                                    <div className="text-base lg:w-4/6 mx-auto w-full flex flex-col gap-6 text-left">
                                        <div className="text-center text-base font-bold muted-text">
                                            Your known vocabulary has been successfully updated. Continue by reading another text.
                                        </div>
                                        <div className="flex flex-row justify-center">
                                            <Link className="no-underline" to="/texts">
                                                <Button text="Find a new text" icon={faAngleRight} color="green" />
                                            </Link>
                                        </div>
                                    </div>
                                </>
                            }
                        </>
                    }
                    {page === "save-words" &&
                        <>
                            <div className="flex flex-col gap-8">
                                <div className="flex flex-col gap-4">
                                    <h1>Great, you finished reading this text! 😍</h1>
                                    <div>
                                    <h3>What did you think about it?</h3>
                                    <div className="text-base text-center">
                                        Rating helps improve the quality of the texts on Yalango.
                                    </div>
                                    </div>
                                    <div className="text-2xl">
                                        <ItemRating itemType={"text"} item={text} />
                                    </div>
                                </div>
                            {wordsToBeSaved.length > 0 &&
                                    <>
                                    <div>
                                <h3>Do you want to save the words you didn't know to a deck?</h3>
                            
                                <div className="text-sm text-center">
                                    Choose what to do with these words (add to decks or add to known vocabulary).
                                </div>
                                </div>
                                </>
    }
                                <div>
                            
                                    <div className="flex flex-col gap-3 w-full lg:w-4/6 m-auto ">
                                    {wordsToBeSaved.length > 0 &&
                                    <>
                                        <div className="flex flex-row justify-between">
                                            <div onClick={allSelected ? deselectAllToBeSaved : selectAllToBeSaved} className="text-sm text-gray-600 cursor-pointer">
                                                {allSelected ? 'Deselect all' : 'Select all'}
                                            </div>
                                            <div onClick={()=>setShowTranslations(!showTranslations)} className="text-sm text-gray-600 cursor-pointer">
                                                {!showTranslations ? 'Show translations' : 'Hide translations'}
                                            </div>
                                            <div onClick={autoTranslateAll} className="text-sm text-gray-600 cursor-pointer">
                                                {translating ? <FontAwesomeIcon icon={faLoader} className="fa-spin" /> : "Auto-translate all items"}
                                            </div>
                                        </div>
                                        {translationError &&
                                            <div className="my-2 text-sm font-normal text-center text-burnt-sienna">
                                                <div className="w-full">
                                                    {errorMsg}  
                                                </div>
                                            </div>
                                        }
                                        </>
                                }
                                        <div className="max-h-136 overflow-y-auto px-2 py-4 flex flex-col gap-3">
                                        {wordsToBeSaved.map((word, index)=>(
                                            <>
                                                <div className={(wordsToBeSavedSelected[word.target] ? 'border border-solid border-pretty-blue ' : ' ') + (" bg-white rounded-lg shadow-lg p-4 text-center relative")} key={"word_"+index}>
                                                    <div className="absolute left-0 top-0  flex flex-col place-items-center gap-3 cursor-pointer p-2 text-sm text-burnt-sienna">
                                                        <div>
                                                            <input onChange={()=>selectionOnChange(word)} checked={wordsToBeSavedSelected[word.target]} type="checkbox" />
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="flex flex-col gap-2 place-items-center">
                                                        <div className="flex pl-4 sm:pl-8 w-full flex-row place-items-center justify-start gap-8">
                                                            <div className="text-gray-600 font-bold w-24">
                                                                {allLanguages[0][targetLang]['name']['en']}
                                                            </div>
                                                            <div className="w-full">
                                                                <input type="text" placeholder="target" onChange={(e)=>onTargetChange(word, e)} value={word.target} className="bg-gray-200 appearance-none border-2  rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white" />
                                                            </div>
                                                        </div>
                                                        {showTranslations &&
                                                        <div className="flex pl-4 sm:pl-8 w-full flex-row place-items-center justify-start gap-8">
                                                            <div className="text-gray-600 font-bold w-24">
                                                                {userLanguages.filter(u=>u['target_language'] === targetLang).length > 0 ? allLanguages[0][userLanguages.filter(u=>u['target_language'] === targetLang)[0]['source_language']]['name']['en'] : "?"}
                                                            </div>
                                                            <div className="w-full">
                                                                <input type="text" placeholder="source" onChange={(e)=>onSourceChange(word, e)} value={word.source} className="bg-gray-200 appearance-none border-2  rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white" />
                                                            </div>
                                                        </div>
                                                        }
                                                    </div>
                                                    
                                                </div>
                                            </>
                                        ))}
                                        {wordsToBeSaved.length === 0 &&
                                            <>
                                                <div className="italic text-center">
                                                    Normally you can save new words to your decks here, but you haven't added translated any words yet. Get started by clicking on a word, translating a sentence, or <span onClick={addAllUnknownWordsToList} className="underline cursor-pointer">add all unknown words</span> to the list.
                                                </div>
                                            </>
                                        }
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center my-3 flex flex-col w-full lg:w-4/6 m-auto gap-2 justify-center">
                                    <div className="flex flex-col place-items-center gap-2 xl:flex-row xl:gap-6 justify-center">
                                        <div className="lg:order-first order-last">
                                            <Button onClick={backToTextClick}  text="Back to text" color="charcoal"  />
                                        </div>
                                        {wordsToBeSaved.length > 0 &&
                                        <>
                                        <div>
                                            <Button onClick={addWordsToKnownVocabularyClick} text="Save to known vocabulary" color="blue" />
                                        </div>
                                        <div className="lg:order-last order-first">
                                            <Button onClick={addWordsToDeckClick} text="Save to deck" color="green" />
                                        </div>
                                        </>
                                        }
                                         <div className="lg:order-last order-first">
                                            <Link to={returnLink} className="no-underline">
                                                <Button text="Exit text" color="charcoal" />
                                            </Link>
                                        </div>
                                    </div>
                                
                                </div>
                                <div>
                                    {saving &&
                                        <>
                                            <div className="text-center text-sm">
                                                Saved {savingProgress}/{toBeSavedNumber !== null ? toBeSavedNumber : 0}
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </>
                    }
                    {showDeckSelectorModal &&
                        <>
                            <DeckSelectorModal saveChangesFunction={saveWordsToDeck} setSelectedDeck={setSelectedDeck} sourceLanguage={sourceLanguage} targetLanguage={targetLang} closeFunction={closeDeckSelectorClick} />
                        </>
                    }
                    </div>
                </div>
            </div>

            <TextOptions setPage={setPage} placementTestClick={placementTestClick} sentenceView={sentenceView} sentences={sentences} currentSentenceIndex={currentSentenceIndex} showPinyin={showPinyin} setSentenceView={setSentenceView} translateSentenceClick={translateSentenceClick} setShowVocabulary={setShowVocabulary} showVocabulary={showVocabulary} pinyinToggleClick={pinyinToggleClick} targetLang={targetLang} page={page} currentUser={currentUser} nextStepClick={nextStepClick} />

        </>
    )
}

export default StudyText;