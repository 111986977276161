import {stripe, loadStripe} from '@stripe/stripe-js';
import {projectFirestore, projectFunctions, firebase, timeStamp} from '../firebase/config.js';
import { useAuth } from "../contexts/AuthContext";
import { useEffect } from 'react';

export default function useStripe() {
    
    let {currentUser, checkIfPremiumUser} = useAuth();
    let stripePromise = null;

    const initializeStripe = async () => {
        if (!stripePromise){
            stripePromise = await loadStripe("pk_live_51Ltn48KC2e7qSKmE9dWZwSHexvLqlKJyL7VaEo9uUvX9kWxX0U540Gm1iAXJy7lynTmAtZtZru5skTlXVfM1ibpe00kHvMV7xQ");
        }
        return stripePromise;
    }

    const createCheckoutSession = async () => {
        if (currentUser === null){return null}
        const checkoutSessionRef = await projectFirestore.collection("stripe-users").doc(currentUser.uid).collection("checkout_sessions").add({
            'price': "price_1M0QiHKC2e7qSKmEulpKg4Je",
            'success_url': "https://yalango.com/premium/success",
            'cancel_url': "https://yalango.com/premium/"
        });

        let unsub = checkoutSessionRef.onSnapshot(async (snap)=>{
            if (snap.exists){
                const { sessionId } = snap.data();
                console.log(snap.data());
                if (sessionId){
                    const stripe = await initializeStripe();
                    await stripe.redirectToCheckout({ sessionId });
                } else {

                }
            }
        });

        return unsub;
    };

    const createStripePortalLink = async () => {
        let func = firebase.app().functions("europe-west3").httpsCallable("ext-firestore-stripe-payments-createPortalLink");
        let {data} = await func({returnUrl: "https://www.yalango.com/premium"});
        window.location.assign(data.url);
    }

    const getUserSubscriptions = async () => {
        if (currentUser === null){return null}
        let snapshot = await projectFirestore.collection("stripe-users").doc(currentUser.uid).collection("subscriptions").get();
        let list = [];
        if (!snapshot.empty){
            snapshot.docs.forEach((doc)=>{
                list.push(doc.data());
            })
        }
        return list;
    }


    return {
        initializeStripe, 
        createCheckoutSession, 
        checkIfPremiumUser, 
        getUserSubscriptions, 
        createStripePortalLink
    }
};