import React, {useEffect, useState} from 'react';
import NoActiveTargetLanguage from '../../../components/errors/NoActiveTargetLanguage';
import {useLanguagesContext} from '../../../contexts/LanguagesContext';
import {motion} from 'framer-motion';
import Icon from '../../../components/general/Icon';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/pro-solid-svg-icons';
import {faCardsBlank} from '@fortawesome/pro-solid-svg-icons';
import {faTableColumns} from '@fortawesome/pro-solid-svg-icons';
import {faBook} from '@fortawesome/pro-solid-svg-icons';
import {faNote} from '@fortawesome/pro-solid-svg-icons';
import Skeleton from 'react-loading-skeleton';
import useNotes from '../../../hooks/useNotes';
import {faEllipsisV} from '@fortawesome/pro-solid-svg-icons';
import AddNoteModal from '../../../components/notes/modals/AddNoteModal';
import {Link, useNavigate} from 'react-router-dom';
import MyFoldersTreeView from '../../../components/folders/MyFoldersTreeView';
import {faFolder} from '@fortawesome/pro-solid-svg-icons';
import MyLibraryMenu from '../../../components/menus/MyLibraryMenu';

const MyNotes = () => {
    let {allLanguages, 
        activeUserLanguages, 
        globalSelectedTargetLanguage, 
        setGlobalSelectedTargetLanguage, 
        globalSelectedSourceLanguage, 
        setGlobalSelectedSourceLanguage, 
        activeTargetLanguageOptions, 
        sourceLanguageOptions
    } = useLanguagesContext();

    const navigate = useNavigate();
    const [viewMode, setViewMode] = useState("organized");
    const {getNotesFromCurrentUserInRealtime, 
        saveNewNote} = useNotes();
    const [notes, setNotes] = useState(null);
    const [showAddNoteModal, setShowAddNoteModal] = useState(false);

    // New note
    const [newNoteName, setNewNoteName] = useState("");
    const [newNoteSaving, setNewNoteSaving] = useState(false);
    const [newNoteError, setNewNoteError] = useState(false);
    const newNoteNameChange = (e) => {
        setNewNoteName(e.target.value);
    }

    const newNoteSourceLanguageChange = (e) => {
        setGlobalSelectedSourceLanguage(e.value);
    }

    const saveNewNoteClick = async () => {
        setNewNoteSaving(true);
        let r = await saveNewNote(globalSelectedTargetLanguage, globalSelectedSourceLanguage, newNoteName);
        if ('data' in r && 'success' in r['data'] && r['data']['success'] === true){
            setShowAddNoteModal(false);
        } else {
            setNewNoteError(true);
        }
        setNewNoteSaving(false);
        
    }

    const createNoteClick = () => {
        setShowAddNoteModal(true);
    }

    const closeAddNoteModal = () => {
        setShowAddNoteModal(false);
    }

    useEffect(()=>{
        let unsubscribe = null;
        const fetchData = async () => {
            getNotesFromCurrentUserInRealtime(globalSelectedTargetLanguage, setNotes);
        }

        if (globalSelectedTargetLanguage !== null){
            fetchData();
        }

        return () => {
            unsubscribe !== null && unsubscribe();
        }

    },[globalSelectedTargetLanguage]);

    const generalBackgroundClick = () => {
        closeShowOptionsMenu();
    }

    const toggleViewModeClick = () => {
        if (viewMode === "organized"){
            setViewMode("all");
        } else {
            setViewMode("organized");
        }  
    }

    const [showOptionsMenu, setShowOptionsMenu] = useState(false);
    const openShowOptionsMenu = (e) => {
        e.stopPropagation();
        setShowOptionsMenu(!showOptionsMenu);
    }

    const closeShowOptionsMenu = () => {
        setShowOptionsMenu(false);
      }

  return (
    <>
        
            {(activeUserLanguages !== null && activeUserLanguages.length === 0) ?
                <>
                    <NoActiveTargetLanguage />
                </>
                :
                <>
                
                <div onClick={generalBackgroundClick} className="  flex flex-col gap-8">
                    <MyLibraryMenu activeName="my-decks" />
                    <div>
                <div onClick={(e)=>openShowOptionsMenu(e)} className="absolute sm:right-4 sm:top-4 right-2 top-2 p-2 cursor-pointer text-xl">
                        <Icon color="charcoal" icon={<FontAwesomeIcon icon={faEllipsisV} />} /> 
                    </div>
                    {showOptionsMenu && 
                            <>
                                <div className="absolute sm:right-8 sm:top-10 right-6 top-6 overflow-visible text-base w-48 z-10 rounded-lg shadow-xl bg-white ">
                                    <div className="flex text-sm whitespace-nowrap font-bold text-gray-700 flex-col justify-start place-items-start">
                                        <div onClick={toggleViewModeClick} className="flex cursor-pointer flex-row gap-3 hover:bg-gray-100 w-full rounded-lg  p-3">
                                            <div>
                                                <Icon color="charcoal" icon={<FontAwesomeIcon icon={viewMode === "organized" ? faNote : faFolder} />} />
                                            </div>
                                            <div>
                                                View {viewMode === "organized" ? ' all notes ' : ' folders '}
                                            </div>
                                        </div>
                                        <Link to={'/my-library'} className="no-underline w-full" >
                                            <div className="flex flex-row gap-3 hover:bg-gray-100 w-full rounded-lg  p-3">
                                                <div>
                                                    <Icon color="red" icon={<FontAwesomeIcon icon={faBook} />} />
                                                </div>
                                                <div>
                                                    My library
                                                </div>
                                            </div>
                                        </Link>
                                        <Link to={'/my-decks'} className="no-underline w-full" >
                                            <div className="flex flex-row gap-3 hover:bg-gray-100 w-full rounded-lg  p-3">
                                                <div>
                                                    <Icon color="red" icon={<FontAwesomeIcon icon={faCardsBlank} />} />
                                                </div>
                                                <div>
                                                    View only decks
                                                </div>
                                            </div>
                                        </Link>
                                            <Link to={'/my-tables'} className="no-underline w-full" >
                                            <div className="flex flex-row gap-3 hover:bg-gray-100 w-full rounded-lg  p-3">
                                                <div>
                                                    <Icon color="blue" icon={<FontAwesomeIcon icon={faTableColumns} />} />
                                                </div>
                                                <div>
                                                    View only tables
                                                </div>
                                            </div>
                                            </Link>

                                    </div>
                                </div>
                            </>
                        }  
                    <div className="">
                        <h1 className="w-64 m-auto">{(allLanguages !== null && globalSelectedTargetLanguage !== null) ? <>My {allLanguages[0][globalSelectedTargetLanguage]['name']['en']} notes</> : <Skeleton count={1} height={30} />}</h1>
                        {viewMode === "organized" &&
                        <>
                            <MyFoldersTreeView filterContentList={["notes"]} outputDesign={"page"} />
                        </>
                        }
                        {viewMode === "all" &&
                            <>
                                <div className={"grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 text-center"}>
                                    <motion.div onClick={createNoteClick} whileHover={{y:-3}} className={"relative  bg-charcoal rounded-lg p-4 h-64 shadow-2xl cursor-pointer flex flex-col justify-evenly"}>
                                        <div className="bg-white font-bold text-xl rounded-lg shadow-2xl p-4 max-h-48 overflow-auto gap-2 flex flex-col">
                                            Create note <Icon hover="none" color="charcoal" icon={<FontAwesomeIcon icon={faPlus} />} /> 
                                        </div>
                                    </motion.div>
                                    {notes !== null ?
                                        <>
                                            {notes.map((note, index)=>(
                                                <>
                                                    <motion.div whileHover={{y:-3}} onClick={()=>navigate("/my-notes/note/"+note.note_id)} key={"note_"+index} className="relative cursor-pointer rounded-lg p-4 h-64 bg-persian-green shadow-2xl  flex flex-col justify-evenly">
                                                        <div className="bg-white rounded-lg shadow-2xl p-4 max-h-48 overflow-auto gap-2 flex flex-col">
                                                            <div className="text-base font-bold">{note['name']}</div>
                                                            <div className="text-sm italic max-h-32 overflow-auto">{'description' in note && note['description'] !== "" ? note['description'] : '' }</div>
                                                        </div>
                                                    </motion.div>
                                                </>
                                            ))}
                                        </>
                                        :
                                        <>
                                            {[...Array(10)].map((v, i)=>(
                                                <>
                                                    <Skeleton key={"skel_"+i} count={1} height={250} />
                                                </>
                                            ))}
                                        </>
                                    }
                                </div>
                            </>
                        }
                    </div>
                    {showAddNoteModal && 
                        <>
                            <AddNoteModal newNoteError={newNoteError} saveNewNoteClick={saveNewNoteClick} targetLanguage={globalSelectedTargetLanguage} newNoteSaving={newNoteSaving} saveNewNote={saveNewNote} newNoteSourceLanguageChange={newNoteSourceLanguageChange} newNoteNameChange={newNoteNameChange} sourceLanguage={globalSelectedSourceLanguage} sourceLanguageOptions={sourceLanguageOptions} allLanguages={allLanguages} newNoteName={newNoteName} closeFunction={closeAddNoteModal} />
                        </>
                    }
                    </div>
                    </div>
                </>
            }
        
    </>
  )
}

export default MyNotes;