import React,{useState, useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom';
import CardGrid from '../../components/vocabulary/cards/CardGrid';
import {useParams} from "react-router-dom";

const Vocabulary = () => {
    const location = useLocation();
    let { id } = useParams();
    const [deckId, setDeckId] = useState(parseInt(id));
    const [backLink, setBackLink] = useState("/my-decks");

    useEffect(()=>{
        let i = parseInt(id);
        setDeckId(i);
        if (location.state !== null){
            const {queryStrings} = location.state;
            if (queryStrings !== null && queryStrings !== undefined){
                setBackLink("/my-decks"+queryStrings);
            } 
        }
    },[location, id]);

    return (
        <>
            <div className="">
                <div className="  ">
                    <CardGrid backLink={backLink} deck_id={deckId} />
                </div>
            </div>
        </>
    )
}

export default Vocabulary;
