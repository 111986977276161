import React from 'react';
import {Link} from 'react-router-dom';
import Icon from '../../components/general/Icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEarthAsia} from '@fortawesome/pro-duotone-svg-icons';
import {faUserGroup} from '@fortawesome/pro-duotone-svg-icons';
import {faCog} from '@fortawesome/pro-duotone-svg-icons';
import {Helmet} from 'react-helmet';

const Tools = () => {
  return (
    <>
        <div className=" ">
            <h1>Yalango tools</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 place-items-center">
                <Link to="/tools/translator" className="no-underline">
                    <div className="flex flex-col w-64 h-64 place-items-center p-10 rounded-full shadow-lg border-2 bg-white border-gray-200 border-dashed">
                        <div className="text-xl font-bold">
                            Translator
                        </div>
                        <div className="text-9xl">
                            <Icon color="green" hover={"none"} icon={<FontAwesomeIcon icon={faEarthAsia} />} />
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    </>
  )
}

export default Tools;