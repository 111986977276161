import React, { useEffect, useState } from 'react';
import useResources from '../../hooks/useResources';

const ResourcesDirectory = () => {
    const {getAllResources} = useResources();
    const [resources, setResources] = useState(null);

    useEffect(()=>{
        const fetchData = async () => {
            let r = await getAllResources();
            setResources(r);
        }
        fetchData();
    },[]);

  return (
    <>
        <div className=" ">
            <h1>Resources</h1>
            <div className="grid grid-cols-3 gap-6 justify-center">
                {resources !== null && resources.map((resource, index)=>(
                    <>
                        <div key={"resource_"+index} className="bg-persian-green  rounded-xl shadow-xl p-6">
                            <div className="flex flex-col gap-2 justify-center place-items-center">
                                <div className="text-center font-bold text-white">{resource.name}</div>
                            </div>
                        </div>
                    </>
                ))}
            </div>
        </div>
    </>
  )
}

export default ResourcesDirectory