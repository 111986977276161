import React, {useState, useEffect} from 'react';
import {useParams} from "react-router-dom";
import InputGame from '../../../components/vocabulary/games/input/InputGame';
import LoadingGame from '../../../components/vocabulary/games/common-assets/LoadingGame';
import useDecks from '../../../hooks/useDecks';
import GeneralErrorPage from '../../../components/errors/GeneralErrorPage';

function Input({type, isPublic}) {
    let { id, lang } = useParams();
    id = parseInt(id); 
    if (type !== "vocabulary"){
        id = parseInt(id);
    }
    const {loadAllGameData, 
        refreshAllGameData} = useDecks();
    const [howManyItems, setHowManyItems] = useState(null);
    const [playMode, setPlayMode] = useState(null);
    const [level, setLevel] = useState(null);
    const [backToPlayPageLink, setBackToPlayPageLink] = useState(null);
    const [deck, setDeck] = useState(null);
    const [decks, setDecks] = useState(null);
    const [paramsLoading, setParamsLoading] = useState(true);
    const [loadingItems, setLoadingItems] = useState(true);
    const [showLoadingScreen, setShowLoadingScreen] = useState(true);
    const [apiFetchStarted, setApiFetchStarted] = useState(false);
    const [error, setError] = useState(false);
    const [errorObject, setErrorObject] = useState("");
    const [cards, setCards] = useState(null);

    const refreshData = async () => {
        await refreshAllGameData(setCards, setShowLoadingScreen, id, howManyItems, type, setLoadingItems, setApiFetchStarted, setDeck, setDecks, lang, level);
    }

    useEffect(()=>{
        const fetchData = async () => {
            await loadAllGameData(setHowManyItems, type, setBackToPlayPageLink, id, setParamsLoading, setApiFetchStarted, setDeck, setDecks, setCards, setLoadingItems, isPublic, lang, setLevel, setPlayMode).catch((error)=>{
                setError(true);
                setErrorObject(error);
            });
        }
        fetchData();
    },[]);
    
    return (
        <>
            <div className="p-8 sm:pb-8 pb-24 h-screen">
                {!error ?
                    <>
                        <InputGame setPlayMode={setPlayMode} playMode={playMode} showLoadingScreen={showLoadingScreen} setShowLoadingScreen={setShowLoadingScreen} deck={deck} decks={decks} loadingItems={loadingItems} refreshData={refreshData} cards={cards} howManyItems={howManyItems} backToPlayPageLink={backToPlayPageLink} id={id} type={type} />
                        <LoadingGame headline="Creating exercises..." subtext="Tim is doing his best to create meaningful exercises for you." showLoading={showLoadingScreen} setShowLoading={setShowLoadingScreen} loading={loadingItems} />
                    </>
                :
                    <>
                        <GeneralErrorPage errorObject={errorObject} goBackLink={backToPlayPageLink} goBackText={"Back to games"} />
                    </>
                }
            </div>
        </>
    )
}

export default Input;
