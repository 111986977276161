import React, {useState, useEffect} from 'react';
import RowOfCards from '../../general/RowOfCards';
import useDecks from '../../../hooks/useDecks';

const RowOfSpecificDecks = ({listOfDeckIds}) => {
    const [items, setItems] = useState(null);
    const [loading, setLoading] = useState(true);
    const {fetchPublicDeck} = useDecks();

    useEffect(()=> {
        const fetchData = async () => {
            let list = [];
            for (const deck_id of listOfDeckIds){
                let r = await fetchPublicDeck(deck_id);
                list.push(r);
            };
            setItems(list);
        }

        if (listOfDeckIds === null){return null}
        fetchData();
    },[listOfDeckIds]);

    return (
        <>
            <div className="p-3 overflow-y-visible overflow-x-auto scrollbar-hide">
                <RowOfCards items={items} />
            </div>
        </>
    )
}

export default RowOfSpecificDecks;