import React from 'react';
import YalangoCryingSvg from '../../assets/svg/yalango/yalango_crying.svg';
import Button from '../general/Button';
import {Link} from 'react-router-dom';
import { useAuth } from "../../contexts/AuthContext";

const GeneralErrorPage = ({errorObject, goBackLink, goBackText, errorHeadline, errorMessage}) => {
  return (
    <div className="h-full flex flex-col justify-center">
        <div className="">
            <img src={YalangoCryingSvg} alt="Yalango mascot sad" className="w-4/6 m-auto p-4 sm:w-64 mx-auto my-0" />
        </div>
        <div className=" flex flex-col justify-center place-items-center text-center gap-4">
            {errorObject !== undefined ?
                <>
                    <div className="text-2xl text-gray-700 font-bold">
                        {errorObject.headline !== undefined ? errorObject.headline : "Something went wrong"}
                    </div>
                    <div className="sm:w-3/6 m-auto">
                        {errorObject.message !== undefined && errorObject.message}
                    </div>
                </>
                :
                <>
                    <div className="text-2xl text-gray-700 font-bold">
                        {errorHeadline}
                    </div>
                    <div className="sm:w-3/6 m-auto">
                        {errorMessage}
                    </div>
                </>
            }
            <div className="flex flex-row justify-center">
                {goBackLink !== null &&
                    <Link className="no-underline" to={goBackLink}>
                        <Button color="green" text={goBackText} />
                    </Link>
                }
            </div>
        </div>
        </div>
  )
}

export default GeneralErrorPage;