import React, {useState, useEffect} from 'react';
import {useParams, Link} from "react-router-dom";
import { motion } from "framer-motion";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPen} from '@fortawesome/pro-duotone-svg-icons';
import {faNote} from '@fortawesome/pro-duotone-svg-icons';
import useQueryStrings from '../../../hooks/useQueryStrings';
import useTables from '../../../hooks/useTables';
import Select from 'react-select';
import TablePlayOptions from '../../../components/tables/games/general/TablePlayOptions';

const MyTablePlay = () => {
    let { id } = useParams();
    id = parseInt(id);
    const [baseUrl, setBaseUrl] = useState("my-tables");
    const [fillInLink, setFillInLink] = useState("/"+baseUrl+"/"+id+"/fill-in");
    const [flashcardsLink, setFlashcardsLink] = useState("/"+baseUrl+"/"+id+"/flashcards");
    const [highlightColumnId, setHighlightColumnId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [title, setTitle] = useState(null);
    const [table, setTable] = useState(null);
    const [tableColumns, setTableColumns] = useState(null);
    let {getTableFromCurrentUser, 
        getTableColumnsFromCurrentUser} =  useTables();
        
    const {getQueryStrings, 
        addAndRemoveQueryStrings} = useQueryStrings();

    const [backLink, setBackLink] = useState("/"+baseUrl+"/"+id);
    const [numberOfItemsSetting, setNumberOfItemsSetting] = useState([
        {'label': '5', 'value': 5}, 
        {'label': '10', 'value': 10}, 
        {'label': '25', 'value': 25}, 
        {'label': '50', 'value': 50},
        {'label': '75', 'value': 75}, 
        {'label': '100', 'value': 100}, 
        {'label': '150', 'value': 150}, 
        {'label': '200', 'value': 200}]);

    const [highlightColumnSetting, setHighlightColumnSetting] = useState(null);
    const [highlightColumnSettingDict, setHighlightColumnSettingDict] = useState(null);

    const [howManyItems, setHowManyItems] = useState(numberOfItemsSetting[0]);

    const howManyItemsOnChange = (option) => {
        setHowManyItems(option);
    }

    const highlightOnChange = (option) => {
        setHighlightColumnId(option.value);
    }

    useEffect(()=>{
        const fetchData = async () => {
            let r = await getTableFromCurrentUser(id);
            setTable(r);
        }
        if (id !== null){
            fetchData();
        }
    },[id]);

    useEffect(()=>{
        const fetchColumns = async () => {
            let r = await getTableColumnsFromCurrentUser(table);
            setTableColumns(r);
            return r;
        }

        const setOptionsForHighlight = (cols) => {
            let list = [];
            let d = {};
            cols.forEach(col=>{
                list.push({
                    'label': col.name,
                    'value': col.id
                });
                d[parseInt(col.id)] = {
                    'label': col.name,
                    'value': col.id
                };
            });
            setHighlightColumnSetting(list);
            setHighlightColumnSettingDict(d);
        }

        const columnsHelper = async () => {
            let cols = await fetchColumns();
            setTableColumns(cols);
            setOptionsForHighlight(cols);
            let highlight = null;
            if (cols !== undefined && 'highlight' in cols){
                try {
                    let h = parseInt(r['highlight']);
                    if (table.column_order.includes(h)){
                        highlight = h;
                        setHighlightColumnId(h);
                    }
                } catch {

                }
            }
            if (highlight === null){
                if (cols !== null && cols !== false){
                    if (table !== null){
                        setHighlightColumnId(parseInt(table?.column_order[0]));
                    }
                }
            }
            
        }

        const fetchData = async () => {
            let r = await getQueryStrings();
            if (r !== undefined && 'items' in r){
                try {
                    numberOfItemsSetting.forEach((option)=>{
                        if (option.value === parseInt(r['items'])){
                            setHowManyItems({'label': r['items'], 'value': parseInt(r['items'])});
                        }
                    })
                } catch {
                    return null;
                }
            };
    
            if (table !== null){
                columnsHelper();
            }
        }


        fetchData();

     
    },[table, numberOfItemsSetting]);

    useEffect(()=>{
        if (id !== null && howManyItems !== null && table !== null && table !== false && baseUrl !== null && highlightColumnId !== null){
            if ('name' in table){
                setTitle(table.name);
            } else {
                setTitle("Games");
            }
            setFlashcardsLink("/"+baseUrl+"/"+id+"/flashcards?items="+howManyItems.value+"&highlight="+highlightColumnId);
            setFillInLink("/"+baseUrl+"/"+id+"/fill-in?items="+howManyItems.value+"&highlight="+highlightColumnId);
            setLoading(false);
        }
    },[id, howManyItems, baseUrl, table, highlightColumnId]);

    useEffect(()=> {
        let addList = [];
        if (howManyItems !== null){
            addList.push({'name': 'items', 'value': howManyItems.value});
        }
        if (highlightColumnId !== null){
            addList.push({'name': 'highlight', 'value': highlightColumnId});
        }
        addAndRemoveQueryStrings(addList, []);
    },[howManyItems, highlightColumnId]);




    /*     
    
*/

    return (
        <>
            <div className=" ">
                <div>
                    <Link to={"/my-tables/"+id}>
                        Back
                    </Link>
                </div>
                <div className="text-2xl my-3 text-center font-bold text-gray-700">{title !== null && title}</div>
                <TablePlayOptions howManyItems={howManyItems} howManyItemsOnChange={howManyItemsOnChange}
                    numberOfItemsSetting={numberOfItemsSetting} 
                    highlightColumnSetting={highlightColumnSetting} 
                    highlightColumnSettingDict={highlightColumnSettingDict} 
                    highlightColumnId={highlightColumnId} 
                    highlightOnChange={highlightOnChange} 
                    flashcardsLink={flashcardsLink}
                    fillInLink={fillInLink} 
                 />
            </div>
        </>
  )
}

export default MyTablePlay;