import React from 'react';
import {Helmet} from 'react-helmet';

const HelmetData = ({title='Yalango', description='', includeYalangoInTitle=true}) => {
  return (
    <Helmet>
        <title>{title}{includeYalangoInTitle && " - Yalango"}</title>
        <meta name="description" content={description} />
    </Helmet>
  )
}

export default HelmetData;