import React, {useState} from 'react';
import Button from '../general/Button';
import {Link} from 'react-router-dom';
import GeneralErrorPage from './GeneralErrorPage';

const NoActiveTargetLanguage = () => {
    const error = "You need to have at least one active target language in order to use this page. Please make at least one language active on your My Languages page.";
    const [errorObject, setErrorObject] = useState({'message': error, 'headline': "Missing active target language"});
    const [goBackLink, setGoBackLink] = useState("/my-languages");
    const [goBackText, setGoBackText] = useState("Go to my languages");
    return (
        <>
            <div className="h-screen">
                <GeneralErrorPage errorObject={errorObject} goBackLink={goBackLink} goBackText={goBackText} />
            </div>
        </>
    );
};

export default NoActiveTargetLanguage;
