import React from 'react'

const H2Block = ({align, contentEditable, attributes, children}) => {
    return (
      <>
        <h2 contentEditable={contentEditable} className={"text-"+align+ " my-2"} {...attributes}>{children}</h2>
      </>
    )
  }

export default H2Block