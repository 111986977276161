import React from 'react'

const H3Block = ({align, contentEditable, attributes, children}) => {
    return (
      <>
        <h3 contentEditable={contentEditable} className={"text-"+align + " my-2"} {...attributes}>{children}</h3>
      </>
    )
  }

export default H3Block