import React from 'react';
import {Link} from 'react-router-dom';

const FooterMenu = () => {
  return (
    <>
        <div className="shadow-xl bg-charcoal text-white sm:ml-22 pt-8 pb-16">
            <div className="flex flex-col sm:flex-row gap-8 justify-evenly">
                <div className="flex flex-col place-items-center sm:place-items-start gap-2">
                    <div className="font-bold  text-lg">
                        Explore
                    </div>
                    <div>
                         <Link to="/features">Features</Link>
                    </div>
                    <div>
                         <Link to="/decks?target=all&source=all">Public decks</Link>
                    </div>
                    <div>
                         <Link to="/languages">Our languages</Link>
                    </div>
                </div>
                <div className="flex flex-col place-items-center sm:place-items-start gap-2">
                    <div className="font-bold text-lg">
                        Yalango
                    </div>
                    <div>
                         <Link to="/help">Help center</Link>
                    </div>
                    <div>
                         <Link to="/about">About</Link>
                    </div>
                    <div>
                        <a className="no-underline" href="mailto:hello@yalango.com">hello@yalango.com</a>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default FooterMenu