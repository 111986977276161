import React from 'react';

const EmailSettings = () => {
  return (
    <>
        <div className="  bg-gray-50">
            <h1>Email settings</h1>
        </div>
    </>
  )
}

export default EmailSettings;