import React from 'react';

const H1Block = ({align, contentEditable, attributes, children}) => {
    return (
      <>
        <h1 contentEditable={contentEditable} className={"text-"+align+ " my-2"} {...attributes}>{children}</h1>
      </>
    )
  }

export default H1Block;