import React from 'react'

const ParagraphBlock = ({align, blockIndex, setValue, onChangeFunction, contentEditable, draggingId, provided, attributes, children}) => {

    return (
      <>
        <p onInput={(e)=>onChangeFunction(e, blockIndex)} contentEditable={contentEditable} className={(draggingId !== provided.draggableProps['data-rbd-draggable-id'] ? 'h-full ' : 'h-16 overflow-auto ')+ "focus:outline-none  text-"+align} {...attributes}>
          {children}
        </p>
      </>
    )
  }

export default ParagraphBlock