import React, {useState, useEffect} from 'react';
import {useParams} from "react-router-dom";
import useHelpCenter from '../../../hooks/useHelpCenter';
import HelpArticleTemplate from '../../../components/help/HelpArticleTemplate';
import {Helmet} from 'react-helmet';

const HelpArticle = () => {
    let { slug } = useParams();

    const {fetchHelpArticle} = useHelpCenter();
    const [article, setArticle] = useState(null);

    useEffect(()=> {
        const fetchData = async () => {
            let r = await fetchHelpArticle(slug);
            setArticle(r);
        }

        if (slug !== null){
            fetchData();
        }
    },[slug]);

    console.log(article);
    
    return (
        <>
            {article !== null && article !== undefined && article !== false && article.hasOwnProperty("title") &&
                <>
                    <Helmet>
                        <title>{article.title.en} - Yalango</title>
                        <meta name="description" content={article.body.en.length > 158 ? article.body.en.substring(0, 155) + "..." : article.body.en} />
                    </Helmet>
                </>
            }
            <div className="  ">
                {article !== false &&
                    <HelpArticleTemplate article={article} />
                }
            </div>
        </>
    )
}

export default HelpArticle;