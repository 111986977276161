import { projectFirestore, timeStamp} from '../firebase/config.js';
import { useAuth } from "../contexts/AuthContext";
import {useLanguagesContext} from '../contexts/LanguagesContext';

export default function useStatistics() {
    let {currentUser} = useAuth();
    const {activeUserLanguages} = useLanguagesContext();

    const addFlashcardStatForCurrentUser = async ({deck_uid, doc_id, deck_id, correct, data_type, view_mode, target_word, source_word, target_iso, source_iso}) => {
        if (target_iso === "" || target_iso === null || source_iso === "" || source_iso === null || (correct !== false && correct !== true)){
            return false;
        }
        if (currentUser === null){return null}
        const query = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('statistics').collection('historic_data').doc('languages').collection(target_iso).doc('flashcards').collection('items');
        let today = new Date();
        let obj = {
            'correct': correct, 
            'target_word': target_word,
            'source_word': source_word,
            'doc_id': doc_id,
            'deck_id': deck_id,
            'deck_uid': deck_uid,
            'view_mode': view_mode,
            'target_ISO_639-1': target_iso,
            'source_ISO_639-1': source_iso,
            'data_type': data_type,
            'timestamp': timeStamp, 
            'UTCTimezoneOffsetInMinutes': today.getTimezoneOffset(), 
            'date': today.getDate() + '.' + (today.getMonth() + 1) + '.' + today.getFullYear()
        };
        console.log(obj);
        await query.add(obj).catch(err=>console.log(err));
    }

    const addReviewStatForCurrentUser = async ({deck_uid, doc_id, deck_id, game, correct, data_type, view_mode, target_word, source_word, target_iso, source_iso}) => {
        console.log("function: ", target_iso, source_iso, correct);
        if (target_iso === "" || target_iso === null || source_iso === "" || source_iso === null || (correct !== false && correct !== true)){
            return false;
        }
        if (currentUser === null){return null}
        const query = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('vocabulary').collection('languages').doc(target_iso).collection('mistakes').doc('reviews').collection("items");
        let today = new Date();
        let obj = {
            'correct': correct, 
            'target_word': target_word,
            'source_word': source_word,
            'source_doc_id': doc_id,
            'deck_id': deck_id,
            'deck_uid': deck_uid,
            'view_mode': view_mode,
            'target_ISO_639-1': target_iso,
            'source_ISO_639-1': source_iso,
            'data_type': data_type,
            'timestamp': timeStamp, 
            'UTCTimezoneOffsetInMinutes': today.getTimezoneOffset(), 
            'date': today.getDate() + '.' + (today.getMonth() + 1) + '.' + today.getFullYear(), 
            'game': game
        };
        console.log("adding ", obj);
        await query.add(obj).catch(err=>console.log("Error: ", err));
    }   

    const getFlashcardStatisticsForCurrentUserInTargetLanguage = async (target_language) => {
        if (currentUser === null){return null}
        const query = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('statistics').collection('historic_data').doc('languages').collection(target_language).doc('flashcards');
        try {
            let data = await query.get();
            if (data.exists === false){
                return null;
            }
            return data.data();
        } catch {
            return null;
        }
    }

    const getQuizStatisticsForCurrentUserInTargetLanguage = async (target_language) => {
        if (currentUser === null){return null}
        const query = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('statistics').collection('historic_data').doc('languages').collection(target_language).doc('quiz');
        try {
            let data = await query.get();
            if (data.exists === false){
                return null;
            }
            return data.data();
        } catch {
            return null;
        }
    }


    const addQuizStatForCurrentUser = async (obj) => {
        console.log(obj);
        if (currentUser !== null){
            let today = new Date();
            obj['UTCTimezoneOffsetInMinutes'] = today.getTimezoneOffset();
            obj['date'] = today.getDate() + '.' + (today.getMonth() + 1) + '.' + today.getFullYear();
            const query = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('statistics').collection('historic_data').doc('languages').collection(obj['target_ISO_639-1']).doc('quiz').collection('items');
            await query.add(obj);
        }
    }

    const addInputStatForCurrentUser = async (obj) => {
        console.log(obj);
        if (currentUser !== null){
            obj['timestamp'] = timeStamp;
            let today = new Date();
            obj['UTCTimezoneOffsetInMinutes'] = today.getTimezoneOffset();
            obj['date'] = today.getDate() + '.' + (today.getMonth() + 1) + '.' + today.getFullYear();
            const query = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('statistics').collection('historic_data').doc('languages').collection(obj['target_ISO_639-1']).doc('input').collection('items');
            await query.add(obj);
        }
    }

    const getGlobalDeckGameStatsInRealtime = async (setStats) => {
        const query = projectFirestore.collection('statistics').doc('public');
        return query.onSnapshot((snap)=>{
            if (snap.exists === false){
                return null;
            }
            setStats(snap.data());
        }); 
    }

    const getInputStatisticsForCurrentUserInTargetLanguage = async (target_language) => {
        if (currentUser === null){return null}
        const query = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('statistics').collection('historic_data').doc('languages').collection(target_language).doc('input');
        try {
            let data = await query.get();
            if (data.exists === false){
                return null;
            }
            return data.data();
        } catch {
            return null;
        }
    }

    const getRealtimeDailyStatsInTargetLanguage = (dailyStats, setDailyStats, isoCode) => {
        if (currentUser === null){return null}
        let today = new Date();
        let docName = today.getDate() + '.' + (today.getMonth() + 1) + '.' + today.getFullYear();
        let path = projectFirestore.collection("users").doc(currentUser.uid).collection("private-data").doc("statistics").collection("processed_data").doc("languages").collection(isoCode).doc('daily').collection('items').doc(docName);
        let unsub = path.onSnapshot((querySnapshot) => {
            if (!querySnapshot.empty){
                let o = JSON.parse(JSON.stringify(dailyStats)) === null ? {} : JSON.parse(JSON.stringify(dailyStats));
                o[isoCode] = querySnapshot.data();
                setDailyStats(o);
            }
        });
        return unsub;
    }

    const getRealtimeDailyStatsInActiveTargetLanguages = async (dailyStats, setDailyStats) => {
        if (currentUser === null){return null}
        if (activeUserLanguages === null){return null}
        let today = new Date();
        let docName = today.getDate() + '.' + (today.getMonth() + 1) + '.' + today.getFullYear();
        let data = {};
        let unsubscribers = [];
        let unsubscribe;
        for await (const language of activeUserLanguages){
            let path = projectFirestore.collection("users").doc(currentUser.uid).collection("private-data").doc("statistics").collection("processed_data").doc("languages").collection(language.target_language).doc('daily').collection('items').doc(docName);
            unsubscribe = await path.onSnapshot((querySnapshot) => {
                if (querySnapshot.exists){
                    data[language.target_language] = querySnapshot.data(); 
                }
            });
            unsubscribers.push(unsubscribe);
        }
        setDailyStats(data);
        return unsubscribers;
    }

    const getDailyStatsInActiveTargetLanguages = async () => {
        if (currentUser === null){return null}
        if (activeUserLanguages === null){return null}
        let today = new Date();
        let docName = today.getDate() + '.' + (today.getMonth() + 1) + '.' + today.getFullYear();
        let data = {};
        for (const language of activeUserLanguages){
            let path = projectFirestore.collection("users").doc(currentUser.uid).collection("private-data").doc("statistics").collection("processed_data").doc("languages").collection(language.target_language).doc('daily').collection('items').doc(docName);
            await path.get().then((querySnapshot) => {
                if (querySnapshot.exists){
                    data[language.target_language] = querySnapshot.data(); 
                }
            });
        }   
        return data;
    }

    const getDailyStatsInTargetLanguage = async (targetLanguage) => {
        if (currentUser === null){return null}
        if (targetLanguage === null){return null}
        let today = new Date();
        let docName = today.getDate() + '.' + (today.getMonth() + 1) + '.' + today.getFullYear();
        let data = false;
        let path = projectFirestore.collection("users").doc(currentUser.uid).collection("private-data").doc("statistics").collection("processed_data").doc("languages").collection(targetLanguage).doc('daily').collection('items').doc(docName);
        await path.get().then((querySnapshot) => {
            console.log(targetLanguage, querySnapshot.exists);
            if (querySnapshot.exists){
                data = querySnapshot.data(); 
            }
        });
          
        return data;
    }

    const getXLastProcessedDataInTargetLanguage = async ({numberOfItems, targetLanguage, dailyGoals, backfillMissingDays}) => {
        let path = projectFirestore.collection("users").doc(currentUser.uid).collection("private-data").doc("statistics").collection("processed_data").doc("languages").collection(targetLanguage).doc('daily').collection('items').orderBy("last_updated", "desc").limit(numberOfItems);
        
        let days = await path.get().then((snapshot)=>{
            if (snapshot.docs.length > 0){
                let l = [];
                snapshot.docs.forEach((doc)=>{
                    let d = doc.data();
                    d['doc_id'] = doc.ref.id;
                    if (!('total_wrong_items' in d)){
                        d['total_wrong_items'] = 0;
                    }
                    l.push(d);
                });
                return l;
            } else {
                return [];
            }
        });
        days = days.reverse();
        console.log("Days: ", days);
        let filteredDays = [];
        days.forEach((day, index)=>{
            let comps = day.doc_id.split(".");
            let date_string = comps[1] + '/' + comps[0] + '/' + comps[2];
            if (index > 0){
                let dateObject = new Date(date_string);
                let previousDocId = days[index-1].doc_id;
                let comps = previousDocId.split(".");
                let date_prev_string = comps[1] + '/' + comps[0] + '/' + comps[2];
                let prevDateObject = new Date(date_prev_string);
                if (Date.parse(dateObject) - Date.parse(prevDateObject) > 86400000){
                    let nbOfDays = (Date.parse(dateObject) - Date.parse(prevDateObject))/86400000;
                    // need to add nbOfDays - 1 extra dates
                    for (let i = 0; i < nbOfDays-1; i++){
                        let date = new Date();
                        date.setDate(prevDateObject.getDate() + (i+1));
                        let date_s = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear();
                        let o = {};
                        o['doc_id'] = date_s;
                        o['total_correct_items'] = 0;
                        o['total_wrong_items'] = 0;
                        o['total_correct_items_per_day_goal_today'] = filteredDays[index-1]['total_correct_items_per_day_goal_today'];
                        filteredDays.push(o);
                    }
                }
                filteredDays.push(day);
            } 
            else {
                filteredDays.push(day);
            }
        });

        // add up to current day
        if (backfillMissingDays === true){
            let today = new Date();
            if (filteredDays.length > 0){
                let lastDocId = filteredDays[filteredDays.length-1].doc_id;
                let comps = lastDocId.split(".");
                let date_last_string = comps[1] + '/' + comps[0] + '/' + comps[2];
                let lastDateObject = new Date(date_last_string);
                let nbOfDays = (Date.parse(today) - Date.parse(lastDateObject))/86400000;
                // need to add nbOfDays - 1 extra dates
                for (let i = 0; i < nbOfDays-1; i++){
                    let date = new Date();
                    date.setDate(lastDateObject.getDate() + (i+1));
                    let date_s = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear();
                    let o = {};
                    o['doc_id'] = date_s;
                    o['total_correct_items'] = 0;
                    o['total_wrong_items'] = 0;
                    o['total_correct_items_per_day_goal_today'] = filteredDays[filteredDays.length-1]['total_correct_items_per_day_goal_today'];
                    filteredDays.push(o);
                }
            } else {
                // fill with empty data
                
                for (let i = 0; i < numberOfItems-1; i++){
                    let date = new Date();
                    date.setDate(date.getDate() - i);
                    let goalToday = 0;
                    if (dailyGoals !== null && dailyGoals !== false){
                        if (dailyGoals.hasOwnProperty('total_correct_items_per_day')){
                            const todayNumber =  date.getDay() === 0 ? 6 : date.getDay() - 1;
                            goalToday = dailyGoals['total_correct_items_per_day'][todayNumber];
                        }
                    }
                    let date_s = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear();
                    let o = {};
                    o['doc_id'] = date_s;
                    o['total_correct_items'] = 0;
                    o['total_wrong_items'] = 0;
                    o['total_correct_items_per_day_goal_today'] = goalToday;
                    filteredDays.push(o);
                }
                filteredDays = filteredDays.reverse();
            }
            filteredDays = filteredDays.slice(-numberOfItems);
        }
        return filteredDays;
    }

    const getDatesInMonth = (month, year) => {
        var date = new Date(year, month, 1);
        var days = [];
        while (date.getMonth() === month) {
          days.push(new Date(date));
          date.setDate(date.getDate() + 1);
        }
        return days;
    }

    function getDatesLastXDays (x) {
        var result = [];
        for (var i=0; i<x; i++) {
            var d = new Date();
            d.setDate(d.getDate() - i);
            result.push(d);
        }
    
        return result.reverse();
    }

    const getProcessedStatisticsDataLastXDays = async ({days, targetLanguage}) => {
        /*
            New strategy: instead of looking up each exact document name one by one, we should instead fetch the X last ones and then check the doc names. Should be faster.
            Optimization:
                - check last available data point and check data. If it's before the interval we're checking, we don't do any other reads. If it's acceptable, we look up all.
        */
        let dates = getDatesLastXDays(7);
        console.log("Dates: ", dates);
        let docNames = [];

        for (const date of dates){
            let monthString = parseInt(date.getMonth()+1).toString();
            console.log("Year: ", date.getFullYear());
            let name = date.getDate() + "." + monthString + "." + date.getFullYear();
            docNames.push(name);
        }

        console.log("names: ", docNames);
        let stats = [];
        let path = projectFirestore.collection("users").doc(currentUser.uid).collection("private-data").doc("statistics").collection("processed_data").doc("languages").collection(targetLanguage).doc('daily').collection('items');
        let dataRows = await getXLastProcessedDataInTargetLanguage({numberOfItems: days, targetLanguage: targetLanguage});
        console.log(dataRows);
        for (const docName of docNames){
            let found = false;
            for (const dataRow of dataRows){
                if (dataRow.doc_id === docName){
                    console.log(docName, dataRow.doc_id)
                    console.log("The same: ", dataRow.doc_id)
                    let d = dataRow;
                    d['exists_in_db'] = true;
                    stats.push(d);
                    found = true;
                }
            };
            if (!found){
                let d = {
                    'doc_id': docName,
                    'exists_in_db': false,
                    'total_correct_items': 0,
                    'total_wrong_items': 0,
                };
                stats.push(d);
            }
        }
        return stats;
    }

    const getProcessedDataInTargetLanguageInMonth = async (month, year, targetLanguage) => {
        let dates = getDatesInMonth(month, year);
        console.log("Dates: ", dates);
        let today = new Date();
        let docNames = [];

        for (const date of dates){
            let monthString = parseInt(date.getMonth()+1).toString();
            console.log("Year: ", date.getFullYear());
            if (date <= today){
                let name = date.getDate() + "." + monthString + "." + date.getFullYear();
                docNames.push(name);
            }
        }
        console.log("names: ", docNames);
        let stats = [];
        let path = projectFirestore.collection("users").doc(currentUser.uid).collection("private-data").doc("statistics").collection("processed_data").doc("languages").collection(targetLanguage).doc('daily').collection('items');
        for (const docName of docNames){
            let s = await path.doc(docName).get().then((snapshot)=>{
                if (snapshot.exists){
                    let d = snapshot.data();
                    d['doc_id'] = snapshot.id;
                    d['exists_in_db'] = true;
                    if (!('total_wrong_items' in d)){
                        d['total_wrong_items'] = 0;
                    }
                    return d;
                } else {
                    return {
                        'doc_id': docName,
                        'exists_in_db': false,
                        'total_correct_items': 0,
                        'total_wrong_items': 0,
                    };
                }
            });
            stats.push(s);
        }
        console.log(targetLanguage, stats);
        return stats;
    }

    const getProcessedVocabularyDataInTargetLanguageToday = async (targetLanguage) => {
        if (currentUser === null){return null}
        let today = new Date();
        let monthString = parseInt(today.getMonth()+1).toString();
        let docName = today.getDate() + "." + monthString + "." + today.getFullYear();
        
        let path = projectFirestore.collection("users").doc(currentUser.uid).collection("private-data").doc("statistics").collection("processed_data").doc("languages").collection(targetLanguage).doc('daily').collection('items');
        let s = await path.doc(docName).get().then((snapshot)=>{
            if (snapshot.exists){
                let d = snapshot.data();
                d['doc_id'] = snapshot.id;
                d['exists_in_db'] = true;
                if (!('total_wrong_items' in d)){
                    d['total_wrong_items'] = 0;
                }
                return d;
            } else {
                return {
                    'doc_id': docName,
                    'exists_in_db': false,
                    'total_correct_items': 0,
                    'total_wrong_items': 0,
                };
            }
        });

        return s;
    }

    const addTableFlashcardsStatistic = async (obj) => {
        if (currentUser === null){return null}
        let today = new Date();
        obj['UTCTimezoneOffsetInMinutes'] = today.getTimezoneOffset();
        obj['timestamp'] = timeStamp;
        obj['date'] = today.getDate() + '.' + (today.getMonth() + 1) + '.' + today.getFullYear();
        const query = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('statistics').collection('historic_data').doc('languages').collection(obj['target_ISO_639-1']).doc('table-flashcards').collection('items');
        await query.add(obj);
    }

    const collectTableFlashcardsStatistics = async ({correct, row, tableColumns, table, howManyItems, highlightColumnId, gamePlayedFrom}) => {
        if (currentUser === null){return}
        console.log(correct, row, tableColumns, table, howManyItems, highlightColumnId, gamePlayedFrom);
        let tableColObject = {};
        tableColumns.forEach((col)=> {
            tableColObject[col.id] = {
                'name': col.name,
                'doc_id': col.doc_id
            }
        });
        let obj = {
            'row_doc_id': row.doc_id,
            'row_id': row.id,
            'table_id': row.table_id,
            'row_values': row.values, 
            'table_doc_id': table.doc_id,
            'table_name': table.name,
            'table_columns': tableColObject,
            'table_column_order': table.column_order,
            'target_ISO_639-1': table['target_ISO_639-1'],
            'source_ISO_639-1': table['source_ISO_639-1'],
            'uid': table['uid'], 
            'how_many_items_in_game': howManyItems,
            'highlight_column_id': highlightColumnId, 
            'correct': correct, 
            'game_played_from': gamePlayedFrom, 
            'table': table

        };
        console.log("Stat: ", obj);
        await addTableFlashcardsStatistic(obj).catch(err=>console.log("Error: ", err));
        return true;
    }

    return {
        addFlashcardStatForCurrentUser, 
        getFlashcardStatisticsForCurrentUserInTargetLanguage,
        addQuizStatForCurrentUser, 
        getQuizStatisticsForCurrentUserInTargetLanguage, 
        getInputStatisticsForCurrentUserInTargetLanguage,
        addInputStatForCurrentUser, 
        getGlobalDeckGameStatsInRealtime, 
        getRealtimeDailyStatsInTargetLanguage, 
        getRealtimeDailyStatsInActiveTargetLanguages, 
        getDailyStatsInActiveTargetLanguages, 
        getXLastProcessedDataInTargetLanguage, 
        getDailyStatsInTargetLanguage, 
        addTableFlashcardsStatistic, 
        addReviewStatForCurrentUser, 
        getProcessedDataInTargetLanguageInMonth, 
        collectTableFlashcardsStatistics, 
        getProcessedVocabularyDataInTargetLanguageToday, 
        getProcessedStatisticsDataLastXDays
    }
}