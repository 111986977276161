import {projectFirestore, projectFunctions, timeStamp, firebase} from '../firebase/config.js';
import { useAuth } from "../contexts/AuthContext";

export default function useResources() {
    
    const getAllResources = async () => {
        const query = projectFirestore.collection("resources");
        const snapshots = await query.get();
        if (!snapshots.empty){
            let l = [];
            snapshots.docs.forEach((doc)=>{
                let o = doc.data();
                o['doc_id'] = doc.ref.id;
                l.push(o);
            });
            return l;
        } else {
            return [];
        }
    }

    return {
        getAllResources
    }
}