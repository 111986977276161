import React, {useState, useEffect} from 'react';
import Modal from '../../general/Modal';
import Icon from '../../general/Icon';
import Button from '../../general/Button';
import {CSVReader} from 'react-papaparse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faUpload} from '@fortawesome/pro-solid-svg-icons';
import {faLoader} from '@fortawesome/pro-solid-svg-icons';
import {faRepeatAlt} from '@fortawesome/pro-duotone-svg-icons';
import {faTrashAlt} from '@fortawesome/pro-duotone-svg-icons';
import {faPlus} from '@fortawesome/pro-duotone-svg-icons';
import Select from 'react-select';

import useDecks from "../../../hooks/useDecks";

const AddMultipleWordsModal = ({deck, setDocs, docs, setCardsLoading, allLanguages, cardsLoading, refreshCards, sourceLanguage, showHideAddMultipleWordsModal, wordsToAdd, setWordsToAdd}) => {
    return (
        <>
            <Modal closeFunction={showHideAddMultipleWordsModal} content={<ModalContent deck={deck} cardsLoading={cardsLoading} setDocs={setDocs} docs={docs} setCardsLoading={setCardsLoading} refreshCards={refreshCards} allLanguages={allLanguages} sourceLanguage={sourceLanguage} wordsToAdd={wordsToAdd} setWordsToAdd={setWordsToAdd} showHideAddMultipleWordsModal={showHideAddMultipleWordsModal} />} size="3xl" />
        </>
    )
}

const ModalContent = ({deck, allLanguages, cardsLoading, setDocs, docs, sourceLanguage, wordsToAdd, setWordsToAdd, setCardsLoading, refreshCards, showHideAddMultipleWordsModal}) => {
    const [showCSVImport, setShowCSVImport] = useState(false);
    const [fileImportContent, setFileImportContent] = useState(null);
    const [newItemsRows, setNewItemsRows] = useState([['', '']]);
    const [csvError, setCsvError] = useState(false);
    const [csvErrorMsg, setCsvErrorMsg] = useState("");
    const [csvUploaded, setCsvUploaded] = useState(false);
    const [showImportCsvButton, setShowImportCsvButton] = useState(true);
    const [showCancelCsvImportButton, setShowCancelCsvImportButton] = useState(false);
    const [progressCounter, setProgressCounter] = useState(0);

    let {addItemToDeck} = useDecks();
    
    let source = sourceLanguage;

    ////console.log("Multiple, deck: ", deck);

    if (sourceLanguage === ""){
        source = "Source language";
    } else {
        source = allLanguages[0][sourceLanguage]['name']['en'];
    }
    let options = [{'label': source, 'value': 'source'}, {'label': allLanguages[0][deck['target_ISO_639-1']]['name']['en'], 'value': 'target'}]
    const [firstColOption, setFirstColOption] = useState(options[1]);
    const [secondColOption, setSecondColOption] = useState(options[0]);
    const [nbNewItems, setNbNewItems] = useState(newItemsRows.length);

    useEffect(()=>{
        let counter = 0;
        newItemsRows.forEach((row,index)=>{
            if (row[0] !== "" && row[1] !== ""){
                counter += 1;
            }
        });
        setNbNewItems(counter);

    },[newItemsRows]);

    const showCSVFunction = () => {
        setShowCSVImport(true);
        setShowImportCsvButton(false);
        setShowCancelCsvImportButton(true);
    }

    const cancelCsvImportFunction = () => {
        setShowCSVImport(false);
        setShowImportCsvButton(true);
        setShowCancelCsvImportButton(false);
        setFileImportContent(null);
    }

    const uploadNewCsvFileFunction = () => {
        setShowCSVImport(true);
        setFileImportContent(null);
        setCsvUploaded(false);
    }

    const handleOnCSVDrop = () => {
        //console.log("hey");
    }

    const handleOnCSVFileLoad = (result) => {
        //console.log("HIEOIJFE");
        //console.log("DATA: CSV: ", result);
        let filtered_result = [];
        let filtered_rows = [];
        console.log("Results: ", result);
        result.forEach((elem)=>{
            //console.log(elem, elem.data.length);
            if (elem.data.length === 2){
                filtered_result.push(elem);
                filtered_rows.push(elem.data);
            }
        });
        if (filtered_result.length === 0){
            setCsvError(true);
            setCsvErrorMsg("Found 0 rows with exactly two columns.");
        }
        setFileImportContent(filtered_result);
        setNewItemsRows([...filtered_rows, ...newItemsRows]);
        setShowCSVImport(false);
        setShowCancelCsvImportButton(false);
        setCsvUploaded(true);
        setShowImportCsvButton(true);
    }

    const handleRemoveCSVFile = () => {
        setCsvError(false);
        setCsvErrorMsg("");
        setFileImportContent(null);
    }

    const flipTargetSourceLangFunc = () => {
        let temp_val = firstColOption;
        setFirstColOption(secondColOption);
        //console.log("Changing", temp_val, secondColOption);
        setSecondColOption(temp_val);
    }

    const changeFirstColOption = (e) => {
        setFirstColOption(e);
        if (e === options[0]){
            setSecondColOption(options[1]);
        } else {
            setSecondColOption(options[0]);
        }
    }

    const changeSecondColOption = (e) => {
        setSecondColOption(e);
        if (e === options[0]){
            setFirstColOption(options[1]);
        } else {
            setFirstColOption(options[0]);
        }
    }

    const rowInputChange = (e) => {
        let word_index = e.target.getAttribute("data-word-index");
        let row_index = e.target.getAttribute("data-row-index");
        let new_value = e.target.value;

        let new_state = [...newItemsRows];
        new_state[row_index][word_index] = new_value;
        setNewItemsRows(new_state);
    }

    const deleteNewItemRow = (e) => {
        let row_index = e.currentTarget.getAttribute("data-row-index");
        let new_state = [];
        newItemsRows.forEach((row, index)=>{
            if (row_index !== index.toString()){
                //console.log(row, index, row_index);
                new_state.push(row);
            }
        });
        setNewItemsRows(new_state);
    }

    const flipNewItemRow = (e) => {
        let row_index = e.currentTarget.getAttribute("data-row-index");
        let new_state = [];
        newItemsRows.forEach((row, index)=>{
            //console.log(row_index, index);
            if (row_index === index.toString()){
                let new_row = [row[1], row[0]];
                new_state.push(new_row);
            } else {
                new_state.push(row);
            }
        });
        setNewItemsRows(new_state);
    }

    const saveNewItems = async () => {
        setCardsLoading(true);
        let newCards = [];
        let index = 0;
        for (const row of newItemsRows){
            let source_input, target_input;
            if (firstColOption['label'] === options[1]['label']){
                source_input = row[1];
                target_input = row[0];
            } else {
                source_input = row[0];
                target_input = row[1];
            };
            if (source_input !== "" && target_input !== ""){
                let newCard = await addItemToDeck({deck_id: deck.id, sourceInput: source_input, targetInput: target_input, outputFormat: "typesense"});
                newCards.push(newCard);
                index = index + 1;
                setProgressCounter(index);
            }
        }
        // prettier
        setTimeout(()=>{
            setDocs([...newCards, ...docs]);
            cancelCsvImportFunction();
            showHideAddMultipleWordsModal();
            setCardsLoading(false);
        },[200]);  
    } 

    const addEmptyRow = () => {
        setNewItemsRows([...newItemsRows, ['', '']]);
    }

    const handleTabClick = (e) => {
        if (e.key === 'Tab'){
            addEmptyRow();
        }
    }

    /*
        
    */

    return (
        <>
            <div className="">

                {showCSVImport &&
                    <>
                        <div className="text-center p-4 py-6">
                            <CSVReader
                                //onDrop={handleOnCSVDrop} doesn't work
                                onFileLoad={handleOnCSVFileLoad}
                                onRemoveFile={handleRemoveCSVFile}
                                config={{
                                    encoding: "ISO-8859-1"
                                }}
                                addRemoveButton
                            >
                                <span><FontAwesomeIcon icon={faUpload} /> Click to a upload a CSV file or drop a file directly into this rectangle.</span>
                            </CSVReader>
                        </div>
                    </>
                }
                {showCancelCsvImportButton &&
                    <>
                        <div className="text-center">
                            <Button color="red" text="Cancel CSV import" onClick={cancelCsvImportFunction} />
                        </div>
                    </>
                }
                
                {(newItemsRows !== null && !cardsLoading) && 
                    <>
                        
                            <>
                                <div className="p-2 rounded-md shadows-lg">
                                    <h1>Add multiple cards</h1>
                                    <div className="max-h-96 min-h-48 overflow-auto">
                                    <table className="w-full p-4 m-auto  shadow-lg bg-white my-4">
                                        <thead>
                                            <tr className="text-center font-bold">
                                                <th width="" className="bg-persian-green z-20 sticky top-0 text-white border px-8 py-4">
                                                    <Select onChange={changeFirstColOption} value={firstColOption} isSearchable={ false } autoFocus={true} options={options} placeholder="Target language" className="min-w-full text-gray-500 text-base w-full"  />
                                                </th>
                                                <th width="" className="bg-persian-green z-20 sticky top-0 text-white border px-8 py-4">
                                                    <Icon onClick={flipTargetSourceLangFunc} icon={<FontAwesomeIcon icon={faRepeatAlt} />} />
                                                </th>
                                                <th width="" className="bg-persian-green z-20 sticky top-0 text-white border  px-8 py-4">
                                                    <Select onChange={changeSecondColOption} value={secondColOption} isSearchable={ false } options={options} placeholder="Target language" className="min-w-full text-gray-500 text-base w-full"  />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {newItemsRows.map((row,row_index)=>(
                                                <>
                                                    <tr className="text-center">
                                                        {row.map((word, word_index)=>(
                                                            <>
                                                                <td className="border px-8 py-4" >
                                                                    {word_index === 0 &&
                                                                        <input data-row-index={row_index} data-word-index={word_index} onChange={rowInputChange} key={"csv_input_"+row_index+"_"+word_index} type="text" className="bg-gray-200 min-w-36 w-full appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white" value={word} />
                                                                    }
                                                                    {word_index === 1 &&
                                                                        <input data-row-index={row_index} data-word-index={word_index} onKeyDown={handleTabClick} onChange={rowInputChange} key={"csv_input_"+row_index+"_"+word_index} type="text" className="bg-gray-200 appearance-none min-w-36 w-full border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white" value={word} />
                                                                    }
                                                                </td>
                                                                {word_index === 0 &&
                                                                    <>
                                                                        <td>
                                                                            <div className="flex flex-row gap-3 justify-center">
                                                                                <Icon color="green" key={"row_flip_"+row_index} data-row-index={row_index} onClick={flipNewItemRow}  icon={<FontAwesomeIcon icon={faRepeatAlt} />} />
                                                                                <Icon color="red" key={"row_delete"+row_index} data-row-index={row_index} onClick={deleteNewItemRow} icon={<FontAwesomeIcon icon={faTrashAlt} />} />
                                                                            </div>
                                                                        </td>
                                                                    </>
                                                                }
                                                            </>
                                                        ))
                                                        }  
                                                    </tr>
                                                </>
                                            ))
                                            }
                                            {newItemsRows.length === 0 &&
                                                <>
                                                    <td colSpan={3} className="border px-8 py-4 text-center italic">
                                                        Start by adding a row.
                                                    </td>
                                                </>
                                            }
                                            <tr className="text-center">
                                                <td className="border px-8 py-4">
                                                </td>
                                                <td className="border px-8 py-4">
                                                    <Icon color="green" icon={<FontAwesomeIcon icon={faPlus} />} onClick={addEmptyRow} />
                                                </td>
                                                <td className="border px-8 py-4"></td>
                                            </tr>
                                        
                                        </tbody>
                                    </table>
                                    </div>
                                    
                                    <div className="text-center text-sm flex flex-col sm:flex-row gap-1 sm:gap-4 justify-center">
                                        <Button color="red" text="Cancel" onClick={showHideAddMultipleWordsModal} />
                                        {(newItemsRows.length > 0 && nbNewItems > 0) &&
                                            <Button text={newItemsRows.length > 1 ? "Add " + nbNewItems + " items to deck" : ("Add " + nbNewItems + (nbNewItems===0 ? " items " : " item ") + " to deck")} onClick={saveNewItems} />
                                        }
                                    </div>   
                                </div>
                            </>
                        
                    </>
                }
                {cardsLoading &&
                    <>
                        <div className="flex flex-col gap-6 place-items-center">
                            <div className="font-bold text-gray-600 text-2xl">
                                Adding cards
                            </div>
                            <div className="text-center text-2xl">
                                <FontAwesomeIcon icon={faLoader} className="fa-spin" />
                            </div>
                            <div>
                                Successfully added <span className="font-bold">{progressCounter}</span> of <span className="font-bold">{nbNewItems}</span> items...
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default AddMultipleWordsModal;
