import React from 'react';
import { Editable, withReact, useSlate, Slate, useFocused, ReactEditor } from 'slate-react';
import {
    Editor,
    Text, 
    Transforms,
    createEditor,
    Descendant,
    Range,
    Element as SlateElement,
  } from 'slate';

const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];
  
const TextEditor = {
    isMarkActive(editor, format) {
      const marks = Editor.marks(editor)
      return marks ? marks[format] === true : false
    },
  
    toggleMark(editor, format) {
      const isActive = TextEditor.isMarkActive(editor, format)
      console.log(format, isActive);
      if (isActive) {
        Editor.removeMark(editor, format)
      } else {
        Editor.addMark(editor, format, true)
      }
    }, 
  
    isBlockActive(editor, format, blockType = 'type') {
        const { selection } = editor
        if (!selection) return false
      
        const [match] = Array.from(
          Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: n =>
              !Editor.isEditor(n) &&
              SlateElement.isElement(n) &&
              n[blockType] === format,
          })
        )
      
        return !!match
      },
  
      toggleBlock(editor, format){
        const isActive = TextEditor.isBlockActive(
          editor,
          format,
          TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
        )
        const isList = LIST_TYPES.includes(format)
      
        Transforms.unwrapNodes(editor, {
          match: n =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            LIST_TYPES.includes(n.type) &&
            !TEXT_ALIGN_TYPES.includes(format),
          split: true,
        })
        let newProperties;
        if (TEXT_ALIGN_TYPES.includes(format)) {
          newProperties = {
            align: isActive ? undefined : format,
          }
        } else {
          newProperties = {
            type: isActive ? 'paragraph' : isList ? 'list-item' : format,
          }
        }
        Transforms.setNodes(editor, newProperties)
      
        if (!isActive && isList) {
          const block = { type: format, children: [] }
          Transforms.wrapNodes(editor, block)
        }
      }
  }

export default TextEditor