import React from 'react';
import {
    motion,
    useMotionValue,
    useTransform,
    useAnimation, 
    useDragControls 
  } from "framer-motion"
import Icon from '../../../../components/general/Icon';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import {faVolumeUp} from '@fortawesome/pro-solid-svg-icons';
import RenderWord from '../common-assets/RenderWord';


function FlashcardsCard({isPremium, audioLoading, googleTextToSpeechClick, playMode, data, flipCard, targetSide, setTargetSide, cardsLeft, nextCard, failedWord, correctWord, gameScreen}) {
    const animation = useAnimation();
    console.log(data);
    let x = useMotionValue(0);
    const opacity = useTransform(
        x, 
        [-300, 0, 300],
        [1, 1, 1]
    );

    
    const background = useTransform(
        x,
        [-300, 0, 300],
        ["#e76f51ff", "#ffffff", "#2a9d8fff"]
    );

    const rotate = useTransform(
        x,
        [-300, 0, 300],
        [-10, 0, 10]
    );

    const variants = {
        toLeft: {
          x: "-100%",
          pointerEvents: "none"
        },
        toRight: {
          x: "100%",
          pointerEvents: "none"
        },
        center: {
          x: 0,
          pointerEvents: "initial"
        }
      };

    const onDragEnd = (event, info) => {
        const offset = info.offset.x
        const velocity = info.velocity.x
        if (offset > 100){
            if(gameScreen !== "end"){
                animation.start("toRight");
                correctWord();
                if (targetSide && playMode === "source_first"){
                    setTargetSide(false);
                }
                else if (!targetSide && playMode === "target_first"){
                    setTargetSide(true);
                }
            }   
        }
        if (offset < -100){
            if(gameScreen !== "end"){
                animation.start("toLeft");
                failedWord();
                if (targetSide && playMode === "source_first"){
                    setTargetSide(false);
                }
                else if (!targetSide && playMode === "target_first"){
                    setTargetSide(true);
                }
            }   
        }
        
    }

    const audioClick = (e) => {
        e.stopPropagation();
        googleTextToSpeechClick(data);
    }

    return (
        <>
            {data !== undefined && data !== null &&
                <div className=" flex flex-col justify-center place-items-center w-screen overflow-x-hidden overflow-y-hidden py-8 h-full">
                    {data.hasOwnProperty("mistake_reviewed") &&
                        <> 
                            {data.mistake_reviewed ?
                                <div className="absolute z-20 -left-5 -rotate-6 transform -top-3 font-bold bg-white rounded-xl shadow-xl p-3 text-persian-green">
                                    Reviewed
                                </div>
                            :
                                <div className="absolute z-20 -rotate-6 transform -left-5 -top-3 font-bold bg-white rounded-xl shadow-xl p-3 text-burnt-sienna">
                                    Not reviewed
                                </div>
                            }
                        </>
                    }
                    <div className="relative">
                    <motion.div
                        drag="x" 
                        onDragEnd={onDragEnd} 
                        style={{ x, background, rotate, opacity }}
                        dragConstraints={{ left: 0, right: 0 }}
                        dragElastic={1.5}
                        variants={variants}
                        animation={animation}
                    className="flex w-80 h-80 sm:w-96 sm:h-96 lg:w-136 lg:h-136 overflow-y-auto z-10 rounded-lg shadow-md cursor-pointer relative bg-white" onClick={flipCard}>
                        <div className=" m-auto p-4 text-center">
                            {data !== undefined ?
                            <motion.div className="max-h-xs text-black ">
                                {playMode === "target_first" &&
                                    <>
                                        {targetSide ? 
                                            <>
                                                <div className="flex w-full flex-row place-items-center text-2xl gap-3">
                                                   
                                                    <div>
                                                        {!audioLoading ? 
                                                            <>
                                                                {(data.hasOwnProperty("google_tts") && data['google_tts'] !== null) &&
                                                                    <div onClick={(e)=>audioClick(e)} className="text-2xl">
                                                                        <Icon color="charcoal" icon={<FontAwesomeIcon icon={faVolumeUp} />} />
                                                                    </div>
                                                                }
                                                            </>
                                                        :
                                                            <div className="text-2xl">
                                                                <Icon color="charcoal" className="fa-beat" icon={<FontAwesomeIcon icon={faVolumeUp} />} />
                                                            </div>
                                                        }
                                                    </div>
                                                    
                                                    <div>
                                                        <RenderWord text={data.target} language={data["target_ISO_639-1"]} data={data} targetOrSource={"target"} />
                                                    </div>
                                                </div>
                                            </>
                                        :
                                            <>
                                                <div className="text-2xl">
                                                    <RenderWord text={data.source} language={data["source_ISO_639-1"]} data={data} />
                                                </div>
                                                {('custom_fields' in data && Object.keys(data['custom_fields']).length > 0 && 'custom_fields_metadata' in data && data['custom_fields_metadata'].length > 0) && 
                                                    <>
                                                        <div className="grid grid-cols-2 text-left gap-4 text-xl">
                                                        {data['custom_fields_metadata'].map((field, index)=>(
                                                            <>
                                                                {(field.data.id in data.custom_fields && data.custom_fields[field.data.id] !== "") &&
                                                                    <>
                                                                        <div key={"name_field"+index} className="font-bold text-gray-600">
                                                                            {field.data.name}
                                                                        </div>
                                                                        <div key={"value_field"+index} className="">
                                                                            {data.custom_fields[field.data.id]}
                                                                        </div>
                                                                    </>
                                                                }
                                                            </>
                                                        ))}
                                                        </div>
                                                    </>
                                                }
                                            </>
                                        }
                                    </>
                                }
                                {playMode === "source_first" &&
                                    <>
                                        {targetSide ? 
                                            <>
                                            <div className="text-2xl">
                                                <RenderWord text={data.source} language={data["source_ISO_639-1"]} data={data} />
                                            </div>
                                            
                                            </>
                                        :
                                            <>
                                            <div className="flex flex-row justify-center place-items-center text-xl gap-3">
                                                    
                                                    <div>
                                                        {!audioLoading ?  
                                                            <>
                                                                {(data.hasOwnProperty("google_tts") && data['google_tts'] !== null) &&
                                                                    <div onClick={(e)=>audioClick(e)} className="text-2xl">
                                                                        <Icon color="charcoal" icon={<FontAwesomeIcon icon={faVolumeUp} />} />
                                                                    </div>
                                                                }
                                                            </>
                                                        :
                                                            <div className="text-2xl">
                                                                <Icon color="charcoal" className="fa-beat" icon={<FontAwesomeIcon icon={faVolumeUp} />} />
                                                            </div>
                                                        }
                                                    </div>
                                                    
                                                    <div>
                                                        <RenderWord text={data.target} language={data["target_ISO_639-1"]} data={data} targetOrSource={"target"} />
                                                    </div>
                                            </div>
                                            {('custom_fields' in data && Object.keys(data['custom_fields']).length > 0 && 'custom_fields_metadata' in data && data['custom_fields_metadata'].length > 0) && 
                                            <>
                                                <div className="grid grid-cols-2 text-left gap-4 text-xl">
                                                {data['custom_fields_metadata'].map((field, index)=>(
                                                    <>
                                                        {(field.data.id in data.custom_fields && data.custom_fields[field.data.id] !== "") &&
                                                            <>
                                                                <div key={"name_field"+index} className="font-bold text-gray-600">
                                                                    {field.data.name}
                                                                </div>
                                                                <div key={"value_field"+index} className="">
                                                                    {data.custom_fields[field.data.id]}
                                                                </div>
                                                            </>
                                                        }
                                                    </>
                                                ))}
                                                </div>
                                            </>
                                            }
                                            </>
                                        }
                                    </>
                                }
                            </motion.div>
                            :
                            <>
                                <div className="italic text-sm">
                                    Error loading data...
                                </div>  
                            </>
                            }
                        </div>
                        
                    </motion.div>

                    {cardsLeft > 3 &&
                    <div className="absolute -top-4 -left-4 z-0 w-80 h-80 sm:w-96 sm:h-96 lg:w-136 lg:h-136 border border-solid border-gray-100 overflow-y-auto bg-gray-50 rounded-lg shadow-md cursor-pointer ">  
                    </div>
                    }
                    {cardsLeft > 2 &&
                    <div className="absolute  -top-3 -left-3 z-0 w-80 h-80 sm:w-96 sm:h-96 lg:w-136 lg:h-136 border border-solid border-gray-100 overflow-y-auto bg-gray-50 rounded-lg shadow-md cursor-pointer ">  
                    </div>
                    }
                    {cardsLeft > 1 &&
                    <div className="absolute flex  -top-2 -left-2 z-0 w-80 h-80 sm:w-96 sm:h-96 lg:w-136 lg:h-136 border border-solid border-gray-100 overflow-y-auto bg-white  rounded-lg shadow-md cursor-pointer ">  
                        {(nextCard !== null && nextCard !== undefined) &&
                            <>
                            <div className="filter blur-sm m-auto p-4 text-center">
                            <motion.div className="max-h-xs text-black ">
                                {playMode === "target_first" &&
                                    <div className="text-xl">
                                        {nextCard.target}
                                    </div>
                                }
                                {playMode === "source_first" &&
                                    <>
                                        <div className="text-xl">
                                            {nextCard.source}
                                        </div>
                                    </>
                                }
                            </motion.div>
                            </div>
                            </>
                        }
                    </div>
                    }
                    </div>
                
                </div>
            }
        
        </>
    )
}

export default FlashcardsCard;
