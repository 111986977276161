import React, {useState} from 'react';
import Button from '../../general/Button';
import Modal from '../../general/Modal';
import Select from 'react-select';
import {useLanguagesContext} from '../../../contexts/LanguagesContext';
import useTables from '../../../hooks/useTables';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLoader} from '@fortawesome/pro-solid-svg-icons';

export const AddTableModalContent = ({closeFunction, newTableSaving, changeTemplateOption, selectedTemplateOption, defaultTemplateOptions, sourceLanguageOptions, allLanguages, targetLanguage, tableName, setTableName, sourceLanguage, setSourceLanguage, setNewTableSaving, saveNewTable}) => {
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const tableNameChange = (e) => {
        setTableName(e.target.value);
    }

    const tableNameKeyDown = (e) => {
        if (e.keyCode === 13) {
            saveNewTable();
        } else {
            if (error === true){
                setError(false);
                setErrorMessage("");
            }
        }
    }

    const changeSourceLanguage = (e) => {
        setSourceLanguage(e.value);
    }

    const saveNewTableClick = () => {
        if (tableName === ""){
            setError(true);
            setErrorMessage("Please choose a name for your new table.");
            return;
        }
        saveNewTable();
    }

    return (
        <>
            <div className={(newTableSaving ? 'opacity-30 relative' : '')}>
                {newTableSaving && 
                    <>
                        <div className="text-center z-10 text-4xl absolute left-1/2 top-1/2 text-black">
                            <FontAwesomeIcon icon={faLoader} className="fa-spin" />
                        </div>
                    </>
                }
                <h1>Create a new {allLanguages[0][targetLanguage]['name']['en']} table</h1>
                    
                    <div className="flex flex-col gap-4">
                        <div>
                            <div className="font-bold text-gray-600">
                                Name of the table
                            </div>
                            <div className="text-center">
                                <input value={tableName} onKeyDown={tableNameKeyDown} onChange={tableNameChange} type="text" className={(error === true ? 'border-red-500 ' : '' ) + "bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white"} />
                            </div>
                            {error === true &&
                                <>
                                    <div className="italic text-red-400">
                                        {errorMessage}
                                    </div>
                                </>
                            }
                        </div>
                        <div>
                            <div className="font-bold text-gray-600">
                                Template
                            </div>
                            <div>    
                                <Select onChange={changeTemplateOption} isSearchable={true} options={defaultTemplateOptions} value={selectedTemplateOption}  />
                            </div>
                        </div>
                        <div>
                            <div className="font-bold text-gray-600">
                                Column language
                            </div>
                            <div>    
                                <Select onChange={changeSourceLanguage} isSearchable={true} options={sourceLanguageOptions[0]} value={sourceLanguageOptions[1][sourceLanguage]}  />
                            </div>
                        </div>
                        <div className="flex flex-row justify-center gap-3">
                            <div>
                                <Button onClick={closeFunction} color="charcoal" text="Cancel" />
                            </div>
                            <div>
                                <Button onClick={saveNewTableClick} color="green" text="Create table" />
                            </div>
                        </div>
                    </div>
                    
            </div>
        </>
    )
}
const AddTableModal = ({changeTemplateOption, selectedTemplateOption, defaultTemplateOptions, sourceLanguageOptions, allLanguages, targetLanguage, tableName, setTableName, sourceLanguage, setSourceLanguage, closeFunction, newTableSaving, setNewTableSaving, saveNewTable}) => {
  return (
    <>
        <Modal overflow="overflow-visible" closeFunction={closeFunction} content={<AddTableModalContent changeTemplateOption={changeTemplateOption} selectedTemplateOption={selectedTemplateOption} defaultTemplateOptions={defaultTemplateOptions} sourceLanguageOptions={sourceLanguageOptions} allLanguages={allLanguages} targetLanguage={targetLanguage} tableName={tableName} setTableName={setTableName} sourceLanguage={sourceLanguage} setSourceLanguage={setSourceLanguage} newTableSaving={newTableSaving} setNewTableSaving={setNewTableSaving} saveNewTable={saveNewTable} closeFunction={closeFunction} />} size="3xl" />
    </>
    )
};

export default AddTableModal;
