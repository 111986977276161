import React from 'react';
import WelcomeToYalango from '../../components/guides/WelcomeToYalango';

const Onboarding = () => {
  return (
    <>
        <div className="">
            <WelcomeToYalango />
        </div>
    </>
  )
}

export default Onboarding