import React, {useEffect, useState} from 'react';
import ReactTooltip from 'react-tooltip';


const ProgressBar = (props) => {
    const {tooltipData="", width=0, autoCalculate=false, text="", progressNumber=0, progressGoal=0} = props;
    const [tooltipId, setTooltipId] = useState("tooltip-progressbar_"+text);

    const [progressWidth, setProgressWidth] = useState(width);

    useEffect(()=>{
        ReactTooltip.rebuild();
        if (autoCalculate === true){
            if (progressNumber > progressGoal){
                setProgressWidth(100);
            } else {
                setProgressWidth((progressNumber/progressGoal)*100);
            }
        } else {
            setProgressWidth(width);
        }
    },[autoCalculate, width])

  return (
    <>
        <ReactTooltip place="bottom" id={tooltipId} effect="float" />
        <div className="w-full h-10 overflow-visible relative striped-charcoal-background rounded-lg shadow-lg">
            <div data-for={tooltipId} data-tip={tooltipData} style={{width: `${progressWidth}%`}} className={(progressWidth > 50 ? '  ' : '  ') + " transition-width striped-green-background motion-reduce:transition-none ease-in-out delay-300  rounded-lg h-10 "}> 
                <div className="absolute w-5/6 text-sm md:text-base flex flex-row gap-4 justify-between top-2 left-2 md:left-8 font-bold text-white">
                    <div className="">
                        {text}
                    </div>
                    <div className=" font-bold flex flex-row gap-0  whitespace-nowrap">
                        <div>
                            {progressNumber}
                        </div>
                        <div>
                        /
                        </div>
                        <div>
                            {progressGoal}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default ProgressBar;