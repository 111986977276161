import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useLanguagesContext} from '../../contexts/LanguagesContext';
import {Link} from 'react-router-dom';
import CountryFlag from '../../components/languages/CountryFlag';
import Button from '../../components/general/Button';
import useLanguages from '../../hooks/useLanguages';
import AddSingleLanguageModal from '../../components/languages/modals/AddSingleLanguageModal';
import RowOfCards from '../../components/general/RowOfCards';
import useDecks from '../../hooks/useDecks';
import {useAuth} from '../../contexts/AuthContext';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-duotone-svg-icons';
import Helmet from 'react-helmet';
import SpecificLanguageTools from '../../components/language-tools/SpecificLanguageTools';

const LanguagePage = () => {
    let { language } = useParams();
    let {allLanguages, setGlobalSelectedSourceLanguage, sourceLanguageOptions, globalSelectedSourceLanguage} = useLanguagesContext();
    const {addNewLanguages, addNewLanguagesToWishlist} = useLanguages();
    const {currentUser} = useAuth();
    const [languageInfo, setLanguageInfo] = useState(null);
    const [decks, setDecks] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showAddLanguageModal, setShowAddLanguageModal] = useState(false);
    const [newLanguageSuccess, setNewLanguageSuccess] = useState(false);
    const [savingNewLanguageLoading, setSavingNewLanguageLoading] = useState(false);
    const [newWishlistLanguageSuccess, setNewWishlistLanguageSuccess] = useState(false);
    const [savingWishlistLanguageLoading, setSavingWishlistLanguageLoading] = useState(false);

    let {fetchAllPublicDecksDataFromOneTargetLanguage} = useDecks();

    useEffect(()=>{
        const setupData = async () =>  {
            for (const lang of Object.keys(allLanguages[0])){
                if (allLanguages[0][lang]['name']['en'].replace(' ', '-') === capitalizeFirstLetter(language)){
                    setLanguageInfo(allLanguages[0][lang]);
                }
            }
        }
        function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
          
        if (allLanguages !== null){
            setupData();
        }
    },[allLanguages]);

    useEffect(()=>{
        const fetchData = async () => {
            let r = await fetchAllPublicDecksDataFromOneTargetLanguage(languageInfo['ISO_639-1'], 4);
            setDecks(r);
        }

        if (languageInfo !== null){
            fetchData();
        }      
    },[languageInfo]);

    useEffect(()=>{
        if (languageInfo !== null && decks !== null && allLanguages !== null){
            setLoading(false);
        }
    },[languageInfo, decks, allLanguages]);

    const addLanguageClick = async () => {
        setShowAddLanguageModal(true);
    }

    const closeAddLanguageModal = () => {
        setShowAddLanguageModal(false);
    }

    const changeSourceLanguage = (option) => {
        setGlobalSelectedSourceLanguage(option.value);
    }

    const saveNewLanguage = async () => {
        setSavingNewLanguageLoading(true);
        let item = [{
            'target_language': languageInfo['ISO_639-1'], 
            'name': languageInfo['name']['en'], 
            'source_language': globalSelectedSourceLanguage
        }];
        await addNewLanguages(item);
        setSavingNewLanguageLoading(false);
        setNewLanguageSuccess(true);   
    }
    
    const addToWishlist = async () => {
        setSavingWishlistLanguageLoading(true);
        let item = [{
            'target_language': languageInfo['ISO_639-1'], 
            'name': languageInfo['name']['en'], 
            'source_language': globalSelectedSourceLanguage
        }];
        await addNewLanguagesToWishlist(item);
        setSavingWishlistLanguageLoading(false);
        setNewWishlistLanguageSuccess(true);   
    }

    return (
        <>
            {languageInfo !== null &&
                <Helmet>
                    <title>Study {languageInfo['name']['en']} with Yalango</title>
                    <meta name="description" content={"Useful content and tools to help you study " + languageInfo['name']['en'] + ". " + (languageInfo['description']['en'] !== "" ? languageInfo['description']['en'] : '')} />
                </Helmet>
            }
            <div className="">   
            <div className=" ">       
            {!loading &&
                <>
                        <div className="flex flex-col gap-8">
                             
                            <div className="text-3xl text-center font-bold text-gray-700">Learn {languageInfo['name']['en']}</div>
                            <div className="flex flex-col justify-center place-items-center gap-4">
                                <div>
                                <CountryFlag size="10em" countryCode={languageInfo['country_flag']} flagName={languageInfo['name']['en']} />
                                </div>
                                {('description' in languageInfo && languageInfo['description']['en'] !== "") &&
                                        <div className="text-center p-4 sm:w-1/2">
                                            {languageInfo['description']['en']} 
                                        </div>
                                }
                            </div>
                            {currentUser !== null &&
                                <>
                                <div className="flex my-2 mb-6 flex-col gap-1 place-items-center justify-center">
                                    <div>
                                        <Button onClick={addLanguageClick} text="Start learning now" color="green" />
                                    </div>
                                    {(!newWishlistLanguageSuccess && !savingWishlistLanguageLoading) &&
                                        <div>
                                            Or <span onClick={addToWishlist} className="underline cursor-pointer">add to your wishlist</span>
                                        </div>
                                    }
                                    {(!newWishlistLanguageSuccess && savingWishlistLanguageLoading) &&
                                        <div className="text-center">
                                            <FontAwesomeIcon icon={faSpinner} className="fa-pulse" />
                                        </div>
                                    }
                                    {(newWishlistLanguageSuccess && !savingWishlistLanguageLoading) &&
                                        <div className="text-center">
                                            {languageInfo['name']['en']} has now been added to your <Link to="/my-languages">wishlist</Link>
                                        </div>
                                    }
                                </div>
                                </>
                            }
                            <SpecificLanguageTools language={languageInfo["ISO_639-1"]} />
                            <div className="flex my-2 flex-col sm:gap-2 justify-center place-items-center">
                                <div className="text-xl text-gray-700 font-bold">
                                    Quick facts about {languageInfo['name']['en']}
                                </div>
                                {('language_family' in languageInfo ) &&
                                    <div className="text-center p-4">
                                        <span className="font-bold text-gray-700">Language family:</span> {languageInfo['language_family']} 
                                    </div>
                                }
                            </div>
                            
                            <div className=" m-2 mb-4 p-4 w-full">  
                                <div className="text-left font-bold text-gray-700 text-xl ">Public decks</div>
                                <div className="italic">Acquire new vocabulary and practice {languageInfo['name']['en']} words and phrases.</div>
                                <RowOfCards languages={allLanguages} items={decks} />
                                {decks.length === 0 && 
                                    <div className=" text-sm font-bold text-gray-700"> 
                                        There are currently no public decks for learning {languageInfo['name']['en']}.
                                    </div>
                                }
                                <div className="py-4"> 
                                {decks.length !== 0 ?
                                    <Link to= {'/decks?target='+languageInfo['ISO_639-1']+'&source=all&decks=all'}>See all public {languageInfo['name']['en']} decks</Link>
                                :
                                    <Link to= {'/decks?target=all&source=all&decks=all'}>See other decks</Link>
                                } 
                                </div> 
                                
                            </div>
                            
                            <div className="text-center">
                                <Link to='/languages'>Discover more languages</Link>
                            </div>  
                        </div>

                    {showAddLanguageModal &&
                        <>
                            <AddSingleLanguageModal savingNewLanguageLoading={savingNewLanguageLoading} newLanguageSuccess={newLanguageSuccess} saveNewLanguage={saveNewLanguage} changeSourceLanguage={changeSourceLanguage} globalSelectedSourceLanguage={globalSelectedSourceLanguage} sourceLanguageOptions={sourceLanguageOptions} language={languageInfo} closeFunction={closeAddLanguageModal} />
                        </>
                    }
                        
                </>
            }
            </div>
            </div>
        </>
    )
}

export default LanguagePage;
