import React, {useState, useEffect} from 'react';
import Modal from '../../general/Modal';
import Button from '../../general/Button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';
import {faLoader} from '@fortawesome/pro-duotone-svg-icons';

const DuplicateDeckModal = ({saving, deck, error, success, newDeck, saveFunction, closeFunction}) => {
  return (
    <>
        <Modal size="normal" closeFunction={closeFunction} content={<ModalContent deck={deck} error={error} success={success} newDeck={newDeck} saving={saving} saveFunction={saveFunction} closeFunction={closeFunction} />} />
    </>
  )
}

const ModalContent = ({saving, success, error, newDeck, deck, saveFunction, closeFunction}) => {
    const [inputName, setInputName] = useState("");
    const [page, setPage] = useState("first-page");
    const onInput = (e) => {
        setInputName(e.target.value);
    }
    useEffect(()=>{
        if (deck !== null){
            if (deck.hasOwnProperty("title") && deck.title !== "" && deck.title !== null){
                setInputName(deck.title);
            } else {
                if (deck.hasOwnProperty("name") && deck.name !== "" && deck.name !== null){
                    setInputName(deck.name);
                }
            }
        }
    },[deck]);

    const saveFunctionClick = async () => {
        await saveFunction(inputName);
        setPage("saving-finished");
    }

    return (
        <>
            <div className="w-full">
            {saving ?
                <>
                    <div className="flex flex-col place-items-center w-full gap-4">
                        <h1>Creating your new deck</h1>
                        <div className="text-center text-4xl text-black">
                            <FontAwesomeIcon icon={faLoader} className="fa-spin" />
                        </div>
                        <div className="text-gray-600 ">
                            Please wait a few moments...
                        </div>
                    </div>
                </>
            :
                <>
                    {page === "first-page" &&
                        <div className="flex w-full flex-col gap-4">
                            <h1>Add this deck to My Library?</h1>
                            <div className=" text-center text-sm flex flex-col gap-4 w-full sm:w-2/3 m-auto">
                                <div>If you think this is a good deck and you want to be able to edit it as one of your own decks, you can make a copy of it to your own library.</div>
                                <div className="italic">Note: If you don't want to edit it or if you want to subscribe to changes by the original creator, add it to your favorites instead. You can still study it and track your progress as normal from My Favorites.</div>
                            </div>
                            <div className="w-full md:w-2/3 lg:w-1/3 m-auto">
                                <input onChange={(e)=>onInput(e)} type="text" placeholder="Name of the new deck" value={inputName} className="bg-gray-200 text-center appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white" />
                            </div>
                            <div className="flex flex-col-reverse  place-items-center w-full sm:flex-row justify-center gap-2 mt-2">
                                <Button onClick={closeFunction} text="Cancel" color="charcoal" />
                                <Button onClick={saveFunctionClick} text="Duplicate deck" color="green" />
                            </div>
                        </div>
                    }
                    {page === "saving-finished" &&
                        <>
                        {success &&
                            <div className="flex w-full flex-col gap-4">
                                <h1>Success!</h1>
                                {newDeck !== null &&
                                    <>
                                        <div className=" text-center text-sm flex flex-col gap-4 w-full sm:w-2/3 m-auto">
                                            <div>The deck has been successfully duplicated! We are now duplicating all the individual cards in the background, but please free to close this page. You can visit the new deck at the link below.</div>
                                        </div>
                                        <div className="flex flex-col-reverse  place-items-center w-full sm:flex-row justify-center gap-2 mt-2">
                                            <Button onClick={closeFunction} text="Close window" color="charcoal" />
                                            <Link className="no-underline" to={"/my-decks/"+newDeck.id}>
                                                <Button text="Go to new deck" color="green" />
                                            </Link>
                                        </div>
                                    </>
                                }
                            </div>
                        }
                        {error &&
                            <div className="flex w-full flex-col gap-4">
                                <h1>Error</h1>
                                <div className=" text-center text-sm flex flex-col gap-4 w-full sm:w-2/3 m-auto">
                                    <div>Something went wrong, unfortunately. Please contact Yalango Support for assistance.</div>
                                </div>
                                <div className="flex flex-col-reverse  place-items-center w-full sm:flex-row justify-center gap-2 mt-2">
                                    <Button onClick={closeFunction} text="Cancel" color="charcoal" />
                                </div>
                            </div>
                        }
                        </>
                    }
                </>
            }
            </div>
        </>
    )
}

export default DuplicateDeckModal;