import React from 'react';
import Button from '../general/Button';

const CancelButton = ({text="Cancel", size="normal", onClick, disabledDesign=false, ...buttonProps}) => {
  return (
    <>
        <Button text={text} color="charcoal" size={size} disabledDesign={disabledDesign} {...buttonProps} onClick={onClick} />
    </>
  )
}

export default CancelButton;