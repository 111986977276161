import React, {useEffect, useState} from 'react';
import useStatistics from '../../hooks/useStatistics';
import {useLanguagesContext} from '../../contexts/LanguagesContext';
import {faCheck} from '@fortawesome/pro-solid-svg-icons';
import {faX} from '@fortawesome/pro-solid-svg-icons';
import {faArrowLeft} from '@fortawesome/pro-solid-svg-icons';
import {faArrowRight} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Skeleton from 'react-loading-skeleton';
import {faQuestion} from '@fortawesome/pro-solid-svg-icons';

const MyCalendar = () => {
    const getDaysInMonth = (month, year) => {
        var date = new Date(year, month, 1);
        var days = [];
        while (date.getMonth() === month) {
          days.push(new Date(date));
          date.setDate(date.getDate() + 1);
        }
        return days;
    }

    const {getProcessedDataInTargetLanguageInMonth} = useStatistics();
    const {globalSelectedTargetLanguage} = useLanguagesContext();

    let today = new Date();
    const [loading, setLoading] = useState(true);
    const [stats, setStats] = useState([]);
    const [currentDateObj, setCurrentDateObj] = useState(today);
    const [monthNames, setMonthNames] = useState(["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]);
    const [currentMonthDays, setCurrentMonthDays] = useState(getDaysInMonth(today.getMonth(), today.getFullYear()));

    const previousMonthClick = () => {
        let newDate = new Date();
        newDate.setMonth(currentDateObj.getMonth()-1);
        setCurrentDateObj(newDate);
    }

    const nextMonthClick = () => {
        let newDate = new Date();
        newDate.setMonth(currentDateObj.getMonth()+1);
        setCurrentDateObj(newDate);
    }

    useEffect(()=>{
        const fetchData = async () => {
            setLoading(true);
            let r = await getProcessedDataInTargetLanguageInMonth(currentDateObj.getMonth(), currentDateObj.getFullYear(), globalSelectedTargetLanguage);
            console.log(r);
            setStats(r);
            setLoading(false);
        }

        if (currentDateObj !== null && globalSelectedTargetLanguage !== null){
            fetchData();
        }
    },[currentDateObj, globalSelectedTargetLanguage]);

    return (
        <>
            <div className=" ">
                
                <div className="flex flex-row text-lg font-bold text-gray-600  gap-6 place-items-center justify-center">
                    <div onClick={previousMonthClick} className="cursor-pointer">
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </div>
                    <div className="">
                        {monthNames[currentDateObj.getMonth()]}, {currentDateObj.getFullYear()}
                    </div>
                    {!((currentDateObj.getMonth() === today.getMonth()) && (currentDateObj.getFullYear() === today.getFullYear())) &&
                    <div onClick={nextMonthClick} className="cursor-pointer">
                        <FontAwesomeIcon icon={faArrowRight} />
                    </div>
                    }
                </div>
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
                    {currentMonthDays !== null && currentMonthDays.map((day, dayIndex)=>(
                        <>
                             {!loading ? 
                                <DayItem today={today} key={"day_"+dayIndex} day={day} stats={stats} dayIndex={dayIndex} />
                            :
                                <>
                                    <Skeleton height={130} count={1} key={"loading_"+dayIndex} />
                                </>
                             }
                        </>
                    ))}
                </div>
            </div>
        </>
    )
}

const DayItem = (props) => {
    const {day, stats, dayIndex, today} = {...props};
    /*
        <div className="font-bold ">
            Correct items: {stats[dayIndex]?.['total_correct_items'] ? stats[dayIndex]['total_correct_items'] : 0}
        </div>
    */
    return (
        <>
            {stats[dayIndex]?.['total_correct_items_per_day_goal_today_accomplished'] ? 
                <>
                    <div className="flex bg-white flex-col place-items-center  gap-3 rounded-xl shadow-xl p-4 relative">
                        <div className="text-sm w-full text-left opacity-80">
                            {day.getDate()}
                        </div>
                    
                        <div className="font-bold p-2 text-persian-green ">
                            <div className="text-2xl">
                                <FontAwesomeIcon icon={faCheck} />
                            </div>
                        </div>
                    </div>
                </>
                :
                <>
                    {day<=today ?
                        <div className=" flex bg-white flex-col place-items-center  gap-3 rounded-xl shadow-xl p-4 relative">
                            <div className="text-sm w-full text-left">
                                {day.getDate()}
                            </div>
                            <div className="font-bold p-2 text-burnt-sienna">
                                <div className="text-2xl">
                                    <FontAwesomeIcon icon={faX} />
                                </div>
                            </div>
                        </div>
                    :
                        <div className=" flex bg-white opacity-40  flex-col place-items-center  gap-3 rounded-xl shadow-xl p-4 relative">
                            <div className="text-sm w-full text-left">
                                {day.getDate()}
                            </div>
                            <div className="font-bold p-2">
                                <div className="text-2xl opacity-40">
                                    <FontAwesomeIcon icon={faQuestion} />
                                </div>
                            </div>
                        </div>
                    }
                </>
            }
        </>
    )
}

export default MyCalendar;