import {projectFirestore, timeStamp} from '../firebase/config.js';
import { useAuth } from "../contexts/AuthContext";
import {useUserInfoContext} from '../contexts/UserInfoContext';
import useLogs from './useLogs';

export default function useLanguageSpecificInfo() {
    
    let {currentUser} = useAuth();

    const chineseScriptsOptionsList = [{'label': 'Simplified characters', 'value': 'simplified'}, {'label': 'Traditional characters', 'value': 'traditional'}, {'label': 'Pinyin', 'value': 'pinyin'}, {'label': 'Zhuyin', 'value': 'zhuyin'}];
    const chineseScriptsOptionsDict = {}; 
    chineseScriptsOptionsList.forEach(option => { chineseScriptsOptionsDict[option.value] = option; });
    const chineseScriptsOptions = [chineseScriptsOptionsList, chineseScriptsOptionsDict];

    const getChineseScripts = () => {

    }

    return {
        chineseScriptsOptions
    }
}