import React, {useState} from 'react';
import Button from '../../general/Button';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/pro-light-svg-icons';
import { faCog } from '@fortawesome/pro-light-svg-icons';


const CategoryMenu = ({CategorySettingsModal, nbOfWords, zoomMode, setZoomMode, modalMode, setShowModal, showModal, resetInputData, docs, setDocs, setModalMode, setTargetInput, setSourceInput, targetInput, sourceInput, fetchAllItems, category}) => {

    const [showSettingsModal, setShowSettingsModal] = useState(false);

    const closeModal = () => {
        setShowSettingsModal(false);
    }


    const settingsButtonClick = () => {
        setShowSettingsModal(true);
    }

    return (
        <>
            <h1>{category.name}</h1>
            <div className="horizontal-center" >
                <Button onClick={settingsButtonClick} text={<FontAwesomeIcon icon={faCog} />} type="icon" color="charcoal" />
                <Link to={`/my-decks/category/${category.category_id}/play`}><Button text={<FontAwesomeIcon icon={faPlay} />} type="icon" color="red" /> </Link>
            </div>   
            {showSettingsModal && 
                <CategorySettingsModal closeFunction={closeModal} category={category} />
            }
        </> 
    )
}

export default CategoryMenu;
