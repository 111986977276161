import React from 'react'

const PrivacyPolicy = () => {
  return (
    <>
        <div className=" ">
            <h1>Privacy policy</h1>
            <div>Coming soon.</div>
        </div>
    </>
  )
}

export default PrivacyPolicy