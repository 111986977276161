import React, {useEffect, useState, useCallback} from 'react';
import {useLanguagesContext} from '../../contexts/LanguagesContext';
import {useUserStatisticsContext} from '../../contexts/UserStatisticsContext';
import useGoals from '../../hooks/useGoals';
import {motion} from 'framer-motion';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {faLoader} from '@fortawesome/pro-solid-svg-icons'; 
import {faArrowDown} from '@fortawesome/pro-solid-svg-icons';
import {faArrowUp} from '@fortawesome/pro-solid-svg-icons';
import Button from '../../components/general/Button';
import NotificationMessage from '../../components/general/NotificationMessage';
import NoActiveTargetLanguage from '../../components/errors/NoActiveTargetLanguage';

const MyDailyGoals = () => {
    const {activeUserLanguages, allLanguages, globalSelectedTargetLanguage} = useLanguagesContext();
    const { 
        saveDailyGoalsInTargetLanguageApi,
        getCurrentUserGoals, 
        getCurrentUserGoalsInTargetLanguage} = useGoals();
    const { 
        refreshUserGoalsFunction} = useUserStatisticsContext();
    const [days, setDays] = useState(['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']);
    const [showIndividualDays, setShowIndividualDays] = useState(false);
    const [itemsPerDay, setItemsPerDay] = useState(null);
    const [saving, setSaving] = useState(false);
    const [showNotificationMessage, setShowNotificationMessage] = useState(false);
    const [notificationStatus, setNotificationStatus] = useState("");
    const [dailyGoals, setDailyGoals] = useState(null);

    const [dailyGoalsLoading, setDailyGoalsLoading] = useState(true);

    const getCurrentUserGoalsCallback = useCallback(async ()=>{
        if (globalSelectedTargetLanguage === null){return null}
        if (!dailyGoalsLoading) {return null};
        let r = await getCurrentUserGoalsInTargetLanguage(globalSelectedTargetLanguage);
        if (r !== false && r !== null){
            setDailyGoals(r);
        } else {
            setDailyGoals({'total_correct_items_per_day': Array(7).fill(0)})
        }
        setDailyGoalsLoading(false);
    },[dailyGoalsLoading, getCurrentUserGoalsInTargetLanguage, globalSelectedTargetLanguage]);

    useEffect(()=>{
        const fetchAsyncData = async () => {
            await getCurrentUserGoalsCallback();
        } 

        if (dailyGoalsLoading){
            fetchAsyncData();
        }
        
        return () => {
        }
    }, [dailyGoalsLoading, getCurrentUserGoalsCallback]);

    useEffect(()=>{
        setDailyGoalsLoading(true);
    },[globalSelectedTargetLanguage])

    useEffect(()=>{
        if (globalSelectedTargetLanguage !== null && dailyGoals !== null && dailyGoals !== false && !dailyGoalsLoading){
            let obj = null;
            let found = false;
            if ('total_correct_items_per_day' in dailyGoals){
                found = true;
                obj = [dailyGoals['total_correct_items_per_day'][0], ...dailyGoals['total_correct_items_per_day']]; 
            }
            
            if (!found){
                obj = Array(8).fill(0);
            }     
            
            setItemsPerDay(obj);
        }
    },[globalSelectedTargetLanguage, dailyGoals, dailyGoalsLoading]);

    const changeGlobalItemsPerDay = (e) => {
        let o = JSON.parse(JSON.stringify(itemsPerDay));
        o = Array(8).fill(parseInt(e.target.value));
        setItemsPerDay(o);
    }

    const changeIndividualDayTarget = (e, index) => {
        let o = JSON.parse(JSON.stringify(itemsPerDay));
        o[index+1] = parseInt(e.target.value);
        setItemsPerDay(o);
    }

    const saveGoals = async () => {
        let o = JSON.parse(JSON.stringify(itemsPerDay));
        o.shift();
        setSaving(true);
        let success = await saveDailyGoalsInTargetLanguageApi(o, globalSelectedTargetLanguage);
        setSaving(false);
        if (success){
            setNotificationStatus("success");
            setShowNotificationMessage(true);
            refreshUserGoalsFunction();
        } else {
            setNotificationStatus("error");
            setShowNotificationMessage(true);
        }
    }

    /*
        <div className="grid grid-cols-3 sm:grid-cols-4 gap-1 w-full m-auto">
            {activeUserLanguages !== null && activeUserLanguages.map((lang, index)=>(
                <>
                    <motion.div onClick={()=>setSelectedLang(lang['target_language'])} whileHover={{scale:1.03}} className={(selectedLang === lang.target_language ? 'bg-charcoal text-white ' : 'bg-gray-100 ') +  "  cursor-pointer font-bold rounded-lg p-2"} key={"lang_"+index}>
                        {lang.name.en}
                    </motion.div>
                </>
            ))}
        </div>
    */

    return (
        <>
            {(activeUserLanguages !== null && activeUserLanguages.length === 0) &&
                <>
                    <NoActiveTargetLanguage />
                </>
            }
            {(activeUserLanguages !== null && activeUserLanguages.length > 0) &&
                <div className=" ">
                    {saving &&
                        <>
                            <div className="text-center z-10 text-4xl absolute left-1/2 top-1/2 text-black">
                                <FontAwesomeIcon icon={faLoader} className="fa-spin" />
                            </div>
                        </>
                    }
                    
                    <div className={(saving ? 'opacity-20 ' : '')+ "md:w-4/6 m-auto w-full p-6 sm:p-8"}>
                        <div className="">
                        <h1>Daily {(allLanguages !== null && globalSelectedTargetLanguage !== null) && allLanguages[0][globalSelectedTargetLanguage]['name']['en']} goals</h1>
                        {activeUserLanguages.length > 1 &&
                            <div className="text-center text-sm">
                                Change the language in the menu if you want to change goals for another language.
                            </div>
                        }
                        {dailyGoalsLoading &&
                            <>
                                <div className="my-7 text-center text-3xl text-charcoal">
                                    <FontAwesomeIcon icon={faSpinner} className="fa-pulse" />
                                </div>
                            </>
                        }
                        {!dailyGoalsLoading && 
                            <>
                            <div>
                                
                                    {itemsPerDay !== null &&
                                    <>
                                    <div className="grid grid-cols-2 place-items-center" >
                                            <div className="font-bold text-gray-600">
                                                Total correct exercises per day
                                            </div>
                                            <div>
                                                <input value={itemsPerDay[0]} onChange={(e)=>changeGlobalItemsPerDay(e)} type="number" className="bg-gray-200 appearance-none border-2  rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white" />
                                            </div>
                                    </div>
                                    <div onClick={()=>setShowIndividualDays(!showIndividualDays)} className="flex cursor-pointer flex-row gap-2 justify-center my-4 text-center font-bold text-gray-600">
                                        <div>
                                            Change goals for specific days
                                        </div>
                                        <div >
                                            <FontAwesomeIcon icon={showIndividualDays ? faArrowUp : faArrowDown} />
                                        </div>
                                    </div>

                                    {showIndividualDays &&
                                        <div className="grid grid-cols-2 gap-3">
                                        {days.map((day, index)=>(
                                            <>
                                                
                                                <div key={'day_'+index}>
                                                    {day}
                                                </div>
                                                <div key={'day_input'+index}>
                                                    <input onChange={(e)=>changeIndividualDayTarget(e, index)} value={itemsPerDay[index+1]} type="number" className="bg-gray-200 appearance-none border-2  rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white" />
                                                </div>
                                            
                                            </>
                                        ))}
                                        </div>
                                    }
                                    
                                    </>
                                }
                            </div>
                            </>
                        }
                        </div>
                        <div className="text-center mt-8 flex flex-row justify-center">
                            <Button onClick={saveGoals} color="green" text="Save goals" />
                        </div>
                    </div>
                    <NotificationMessage notificationStatus={notificationStatus} showMessageBool={showNotificationMessage} setShowMessageBool={setShowNotificationMessage} />
                </div>
            }
        </>
    )
}

export default MyDailyGoals