import React from 'react';
import { Editable, withReact, useSlate, Slate, useFocused, ReactEditor } from 'slate-react';
import TextEditor from './TextEditor';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const MarkButton = ({ format, icon }) => {
    const editor = useSlate();
    let active = TextEditor.isMarkActive(editor, format);
    return (
      <div className={(active ? 'opacity-100 ' : 'opacity-30 ' )+ "  py-2 cursor-pointer"}
        active={TextEditor.isMarkActive(editor, format)}
        onMouseDown={event => {
          event.preventDefault()
          TextEditor.toggleMark(editor, format)
        }}
      >
        <FontAwesomeIcon icon={icon} />
      </div>
    )
  }

export default MarkButton;