import React from 'react';
import Button from '../../general/Button';
import Modal from '../../general/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLoader } from '@fortawesome/pro-solid-svg-icons';
import { faThumbsDown } from '@fortawesome/pro-duotone-svg-icons';
import { faRotateRight } from '@fortawesome/pro-duotone-svg-icons';

const ModalContent = ({closeFunction, newFolderLoading, createFolderMode, newFolderNameOnChange, name, createANewFolder, createANewSubfolder}) => {
    
    const checkIfEnter = (e) => {
        if (e.key === 'Enter') {
            if (createFolderMode === "main") {
                createANewFolder(e);
            } else {
                createANewSubfolder(e);
            }
        }
    }
    return (
        <>
            <div className="overflow-auto h-72 sm:h-full flex flex-col justify-center gap-4">
                {!newFolderLoading ?
                    <>
                        <h1>Create a new {createFolderMode === "main"  ? <>folder</> : <>subfolder</>}</h1>
                        <input onKeyDown={(e)=>checkIfEnter(e)} autoFocus value={name} onChange={newFolderNameOnChange} className="bg-gray-200 appearance-none border-2  rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white" />
                        <div className="flex flex-col-reverse sm:flex-row justify-center gap-1 sm:gap-3 ">
                                <Button key="cancel_button whitespace-nowrap" text="Cancel" color="charcoal" onClick={(e)=>closeFunction(e)} />
                                {createFolderMode === "main" ?
                                    <Button key="save_changes_button" text={"Create folder"} color="green" onClick={(e)=>createANewFolder(e)} />
                                :
                                    <Button key="save_button" text="Create folder" color="green" onClick={(e)=>createANewSubfolder(e)} />
                                }
                        </div>
                    </>
                : 
                    <>
                        <div className="text-center text-4xl">
                            <FontAwesomeIcon icon={faLoader} className="fa-spin" />
                        </div>
                    </>
                }
            </div>
        </>   
    )
}

const CreateFolderModal = ({closeFunction, newFolderLoading, createANewSubfolder, createANewFolder, createFolderMode, newFolderNameOnChange, name }) => {
  return (
    <>
        <Modal closeFunction={closeFunction} content={<ModalContent newFolderLoading={newFolderLoading} createFolderMode={createFolderMode} newFolderNameOnChange={newFolderNameOnChange} name={name} closeFunction={closeFunction} createANewFolder={createANewFolder} createANewSubfolder={createANewSubfolder} />} size="2xl"/>
    </>
  )
};

export default CreateFolderModal;
