import React from 'react';
import ReactCountryFlag from "react-country-flag";
import useFlags from '../../hooks/useFlags';
import {motion} from 'framer-motion';
import ReactTooltip from 'react-tooltip';

const CountryFlag = ({countryCode, flagName, size="2em", showToolTip=true, animation=true}) => {
    let aniScale = 1.05;
    if (flagName === undefined){
        flagName="Flag";
    }
    if (!animation){
        aniScale = 1.00;
    }

    let {fetchFlag} = useFlags();
    let flag = fetchFlag(countryCode);
    let random = Math.random();
    /*
    <ReactCountryFlag countryCode={countryCode} 
                style={{
                    width: size,
                    height: size,
                }}
                title={flagName.toUpperCase()}
                svg
                />
    */
    return (
        <>
            <ReactTooltip place="bottom" id={"tooltip-language-"+flagName+random} effect="solid" />
            <motion.div  data-for={showToolTip ? "tooltip-language-"+flagName+random : ""} data-tip={flagName.slice(0,1).toUpperCase()+flagName.slice(1,flagName.length)} className="flex flex-col justify-center" whileHover={{scale:aniScale}}>
                <img 
                 src={flag} 
                 style={{  width: size }}
                 alt={flagName + " flag"}
                />
            </motion.div>
        </>
    )
}

export default CountryFlag;
