import React, {useEffect, useState} from 'react';
import '../css/Pages.css';
import {Link} from 'react-router-dom';
import Icon from '../components/general/Icon';
import FooterMenu from '../components/menus/FooterMenu';
import YalangoQuestionSvg from '../assets/svg/yalango/yalango_question.svg';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLanguage} from '@fortawesome/pro-duotone-svg-icons';
import { faCardsBlank} from '@fortawesome/pro-duotone-svg-icons';
import {faChartLine} from '@fortawesome/pro-duotone-svg-icons';
import {faBookSparkles} from '@fortawesome/pro-duotone-svg-icons';
import {faNote} from '@fortawesome/pro-duotone-svg-icons';
import {faBoltLightning} from '@fortawesome/pro-duotone-svg-icons';
import {faComments} from '@fortawesome/pro-duotone-svg-icons';
import { faCog } from '@fortawesome/pro-duotone-svg-icons';
import {faToolbox} from '@fortawesome/pro-duotone-svg-icons';
import {faStarShooting} from '@fortawesome/pro-duotone-svg-icons';
import {faTableColumns} from '@fortawesome/pro-duotone-svg-icons';
import WelcomeToYalango from '../components/guides/WelcomeToYalango.js';
import {useUserInfoContext} from '../contexts/UserInfoContext';
import {useUserStatisticsContext} from '../contexts/UserStatisticsContext'; 
import {useLanguagesContext} from '../contexts/LanguagesContext';
import {Helmet} from 'react-helmet';
import Skeleton from 'react-loading-skeleton';
import CountryFlag from '../components/languages/CountryFlag';
import 'react-loading-skeleton/dist/skeleton.css';
import DailyProgressWidget from '../components/statistics/progress/DailyProgressWidget';

const Dashboard = () => {
    const {userInfo} = useUserInfoContext();
    const {dailyStats, 
        dailyGoals, 
        userStatisticsFetched, 
        currentDailyStreak, 
        refreshDailyStatsFunction} = useUserStatisticsContext();
    const {activeUserLanguages, 
        allLanguages} = useLanguagesContext();
    const [greeting, setGreeting] = useState(null);
    const [showModal, setShowModal] = useState(null);
    const [loading, setLoading] = useState(true);
    const today = new Date();
    const todayNumber =  today.getDay() === 0 ? 6 : today.getDay() - 1;

    useEffect(()=> {
        const getGreeting = () => {
            let date = new Date();
            let hour_number = date.getHours();
            let greeting_msg = "";
            if (hour_number < 12 && hour_number >= 6){
                greeting_msg = "Good morning"
            }
            else if (hour_number >= 12 && hour_number <= 17){
                greeting_msg = "Good afternoon";
            }
            else if (hour_number >= 0 && hour_number < 6){
                greeting_msg = "Good night";
            }
            else {
                greeting_msg = "Good evening";
            }
            if (userInfo !== null && userInfo !== undefined && "displayname" in userInfo && userInfo['displayname'] !== ""){
                greeting_msg = greeting_msg + ", " + userInfo['displayname'];
            }
            setGreeting(greeting_msg);
        }        
        getGreeting();
       
    },[userInfo]);

    useEffect(()=>{
        if (showModal !== null){
            setLoading(false);
        }
    },[showModal]);

    useEffect(()=>{
        if (activeUserLanguages !== null){
            refreshDailyStatsFunction();
        }
    },[activeUserLanguages]);

    const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    return (
        <> 
            <Helmet>
                <title>Dashboard - Yalango</title>
                <meta name="description" content={"Your personal dashboard on Yalango."} />
            </Helmet>
            {(loading && showModal === true) &&
                <>
                <div className="fixed w-screen h-screen z-40">
                        
                </div>
                </>
            }
            <WelcomeToYalango showModal={showModal} setShowModal={setShowModal} />
            {showModal === false &&
                <>
                    <div className="  h-full min-h-screen">
                        <h1>{greeting}</h1>
                        <div className="m-auto sm:w-5/6">
                            <div className="sm:w-3/6 m-auto mb-6">
                                <DailyProgressWidget />  
                            </div>
                            <h2>My library</h2>
                            <div className="grid p-3 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 place-items-center">
                                <Link to="/my-decks" className="no-underline">
                                    <div className="flex flex-col w-64 h-64 place-items-center p-10 rounded-full shadow-lg border-2 bg-white border-gray-200 border-dashed">
                                        <div className="text-xl font-bold">
                                            Decks
                                        </div>
                                        <div className="text-9xl">
                                            <Icon color="red" hover={"none"} icon={<FontAwesomeIcon icon={faCardsBlank} />} />
                                        </div>
                                    </div>
                                </Link>
                                
                                <Link to="/my-tables" className="no-underline">
                                    <div className="flex flex-col w-64 h-64 place-items-center p-10 rounded-full shadow-lg border-2 bg-white border-gray-200 border-dashed">
                                        <div className="text-xl font-bold">
                                            Tables
                                        </div>
                                        <div className="text-9xl">
                                            <Icon color="beautiful-blue" hover={"none"} icon={<FontAwesomeIcon icon={faTableColumns} />} />
                                        </div>
                                    </div>
                                </Link>
                                <Link to="/my-notes" className="no-underline">
                                    <div className="flex flex-col w-64 h-64 place-items-center p-10 rounded-full shadow-lg border-2 bg-white border-gray-200 border-dashed">
                                        <div className="text-xl font-bold">
                                            Notes
                                        </div>
                                        <div className="text-9xl">
                                            <Icon color="sandy-brown" hover={"none"} icon={<FontAwesomeIcon icon={faNote} />} />
                                        </div>
                                    </div>
                                </Link>
                                
                                
                            </div>
                                <h2>Vocabulary & statistics</h2>
                                <div className="grid p-3 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 place-items-center">
                                <Link to="/my-vocabulary" className="no-underline">
                                    <div className="flex flex-col w-64 h-64 place-items-center p-10 rounded-full shadow-lg border-2 bg-white border-gray-200 border-dashed">
                                        <div className="text-xl font-bold">
                                            Vocabulary
                                        </div>
                                        <div className="text-9xl">
                                            <Icon color="sandy-brown" hover={"none"} icon={<FontAwesomeIcon icon={faBookSparkles} />} />
                                        </div>
                                    </div>
                                </Link>
                                <Link to="/my-statistics" className="no-underline">
                                    <div className="flex flex-col w-64 h-64 place-items-center p-10 rounded-full shadow-lg border-2 bg-white border-gray-200 border-dashed">
                                        <div className="text-xl font-bold">
                                            Statistics
                                        </div>
                                        <div className="text-9xl">
                                            <Icon color="purple" hover={"none"} icon={<FontAwesomeIcon icon={faChartLine} />} />
                                        </div>
                                    </div>
                                </Link>
                                </div>
                                <h2>Settings & more</h2>
                                <div className="grid p-3 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 place-items-center">
                                <Link to="/my-languages" className="no-underline">
                                    <div className="flex flex-col w-64 h-64 place-items-center p-10 rounded-full shadow-lg border-2 bg-white border-gray-200 border-dashed">
                                        <div className="text-xl font-bold">
                                            Languages
                                        </div>
                                        <div className="text-9xl">
                                            <Icon color="green" hover={"none"} icon={<FontAwesomeIcon icon={faLanguage} />} />
                                        </div>
                                    </div>
                                </Link>
                                
                                
                                <Link to="/tools" className="no-underline">
                                    <div className="flex flex-col w-64 h-64 place-items-center p-10 rounded-full shadow-lg border-2 bg-white border-gray-200 border-dashed">
                                        <div className="text-xl font-bold">
                                            Tools
                                        </div>
                                        <div className="text-9xl">
                                            <Icon color="gray-800" hover={"none"} icon={<FontAwesomeIcon icon={faToolbox} />} />
                                        </div>
                                    </div>
                                </Link>

                                <Link to="/settings" className="no-underline">
                                    <div className="flex flex-col w-64 h-64 place-items-center p-10 rounded-full shadow-lg border-2 bg-white border-gray-200 border-dashed">
                                        <div className="text-xl font-bold">
                                            Settings
                                        </div>
                                        <div className="text-9xl">
                                            <Icon color="charcoal" hover={"none"} icon={<FontAwesomeIcon icon={faCog} />} />
                                        </div>
                                    </div>
                                </Link>
                                </div>
                                <h2>Help & feedback</h2>
                                <div className="grid p-3 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 place-items-center">
                                <Link to="/guides" className="no-underline">
                                    <div className="group">
                                        <div className="relative bg-burnt-sienna transition duration-500 ease-in-out  group-hover:bg-sandy-brown flex flex-col w-64 h-64 place-items-center p-10 rounded-full shadow-lg border-2 bg-white border-gray-200 border-dashed">
                                            <div className="group-hover:-rotate-6  transition duration-300 ease-in-out absolute transform -rotate-12 -left-2 text-white -top-2 text-4xl font-bold bg-gradient-to-r from-burnt-sienna to-sandy-brown rounded-lg shadow-lg p-2">
                                                Guides
                                            </div>
                                            <div className="text-9xl">
                                                <Icon color="blue" includeOpacityChange={false} hover={"none"} svg={true} icon={YalangoQuestionSvg} />
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                                <Link to="/my-feedback" className="no-underline">
                                    <div className="flex flex-col w-64 h-64 place-items-center p-10 rounded-full shadow-lg border-2 bg-white border-gray-200 border-dashed">
                                        <div className="text-xl font-bold">
                                            Feedback
                                        </div>
                                        <div className="text-9xl">
                                            <Icon color="blue" hover={"none"} icon={<FontAwesomeIcon icon={faComments} />} />
                                        </div>
                                    </div>
                                </Link>
                                </div>

                            </div>
                        
                        </div>

                </>
            }
            
        </>
    )
}

export default Dashboard;
