import React from 'react';
import { Link } from 'react-router-dom';
import {motion} from 'framer-motion';
import {faUser} from '@fortawesome/pro-duotone-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import CountryFlag from '../../../components/languages/CountryFlag';
import Icon from '../../../components/general/Icon';

const PublicDeckCard = ({deck, languages}) => {
    //console.log("DECK: ", deck);
    
    return (
        <>
        {(deck !== undefined && languages !== undefined ) &&
            <>
                <Link to={'/decks/'+deck.id} className="no-underline" >
                <motion.div whileHover={{y:-3}} className={"relative text-center rounded-lg p-4 h-64 "+(deck['author']['username'] === "yalango" ? "bg-burnt-sienna " : "bg-persian-green ") + "shadow-lg cursor-pointer flex flex-col justify-evenly"}>
                    <div className="absolute left-0 top-0 p-2">
                        <CountryFlag countryCode={languages[0][deck['target_ISO_639-1']]['country_flag']} size="2em" />
                    </div>
                    {deck['author']['username'] === "yalango" &&
                        <div className="absolute right-0 top-0 p-2 font-bold text-white shadow-lg bg-charcoal rounded-lg">
                            YALANGO
                        </div>
                    }
                    <div className="bg-white rounded-lg shadow-2xl p-4 max-h-48 overflow-auto gap-2 flex flex-col">
                        <div className="text-base font-bold">{deck['title'] !== "" ? deck['title'] : deck['name']}</div>
                        <div className="text-sm italic max-h-32 overflow-auto">{deck['description'] !== "" ? deck['description'] : 'This deck does not have a description.' }</div>
                        <div className="text-xs">
                            <div className="flex flex-row gap-2 justify-center">
                                <Icon color="blue" icon={<FontAwesomeIcon icon={faUser} />} /> 
                                <Link to={'/u/'+ (deck['author']['username'] !== "" ? deck['author']['username'] : '#')}>
                                {deck['author']['displayname'] !== '' ? deck['author']['displayname'] : deck['uid']} 
                                </Link>
                            </div>
                        </div>
                    </div>
                </motion.div>
                </Link>
            </>
        }
        </>
    )
}

export default PublicDeckCard;
