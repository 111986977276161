import React from 'react';
import {useLocation} from 'react-router-dom';
import LoginComponent from '../../components/authentication/LoginComponent';

const Login = () => {
    const location = useLocation();
    let redirect_url = null;
    if ('state' in location){
        if (location.state !== null){
            redirect_url = location.state.from.pathname;
        };
    };

    return (
        <div className="  ">
            <LoginComponent redirect={redirect_url} />
        </div>   
    )
}

export default Login;
