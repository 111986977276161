import React, {useState} from 'react';
import Button from '../../general/Button';
import Modal from '../../general/Modal';
import useDecks from '../../../hooks/useDecks';

const ModalContent = ({category, closeFunction}) => {
    
    const {editCategoryNameForCurrentUser} = useDecks();
    const [categoryName, setCategoryName] = useState(category.name);

    const editCategoryName = (e) => {
        setCategoryName(e.target.value);
    }

    const saveChanges = async () => {
        //firestore
        //console.log("Saving changes...", category);
        let success = await editCategoryNameForCurrentUser(category.category_id,categoryName);
        if (success){
            closeFunction();
        }
    }

    return (
        <>
            <h1>Category settings</h1>
            <div className="flex flex-col justify-center gap-3">
            
                <div className="flex flex-row gap-8 justify-center place-items-center flex-grow font-bold">
                    <div>
                        Name
                    </div>
                    <div className="text-center">
                        <input onChange={editCategoryName} value={categoryName} type="text" className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white"  />
                    </div>
                </div> 
               
                <div className="flex flex-row justify-center gap-3 m-3">
                    <Button onClick={closeFunction} key="cancel_button" text="Cancel" color="red" />
                    <Button onClick={saveChanges} key="save_button" text="Save changes" color="green"  />
                </div>
            </div>
        </>
    )
}

const CategorySettingsModal = ({category, closeFunction}) => {
    return (
        <>
            <Modal closeFunction={closeFunction} content={<ModalContent closeFunction={closeFunction} category={category} />} size="3xl"/>
        </>
    )
}

export default CategorySettingsModal;
