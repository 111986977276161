import React from 'react';
import {useLocation} from 'react-router-dom';
import ForgottenPasswordComponent from '../../components/authentication/ForgottenPasswordComponent';

const ForgottenPassword = () => {

    return (
        <div className="  ">
            <ForgottenPasswordComponent />
        </div>   
    )
}

export default ForgottenPassword;
