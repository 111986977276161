import React, {useState} from 'react';
import Icon from '../../general/Icon';
import Button from '../../general/Button'; 
import Modal from '../../general/Modal';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-duotone-svg-icons';
import {faQuestion} from '@fortawesome/pro-solid-svg-icons';
import Select from 'react-select';
import SourceLanguageExplanation from '../../guides/explanationModals/SourceLanguageExplanation';

const ModalContent = ({savingNewLanguageLoading, newLanguageSuccess, saveNewLanguage, changeSourceLanguage, globalSelectedSourceLanguage, sourceLanguageOptions, language, closeFunction}) => {
    const [showSourceLanguageGuideModal, setShowSourceLanguageGuideModal] = useState(false);
    const showSourceLanguageGuideClick = () => {
        setShowSourceLanguageGuideModal(true);
    }

    const closeSourceLanguageGuideModal = () => {
        setShowSourceLanguageGuideModal(false);
    }

    return (
        <>
            <div>
                <h1>Learn {language['name']['en']} with Yalango</h1>
                {(!newLanguageSuccess && !savingNewLanguageLoading) && 
                <div>
                    <div className="my-2 sm:w-3/4 m-auto text-center">
                        Start learning {language['name']['en']} by adding it your account. After that, you can begin creating decks and practice the language with Yalango.
                    </div>
                    <div className="flex flex-col sm:flex-row gap-4 justify-center items-center">
                        <div className="font-bold text-gray-600">
                            Source language:
                        </div>
                        <div>    
                            <Select onChange={changeSourceLanguage} isSearchable={true} options={sourceLanguageOptions[0]} value={sourceLanguageOptions[1][globalSelectedSourceLanguage]}  />
                        </div>
                        <div>
                            <div onClick={showSourceLanguageGuideClick} className="bg-white cursor-pointer shadow-lg border border-dashed border-gray-200 px-4 py-2 rounded-full">
                                <Icon hover="none" color="black" icon={<FontAwesomeIcon icon={faQuestion} />} />
                            </div>
                        </div>
                    </div>
                    <div className="text-center my-4">
                        <Button onClick={saveNewLanguage} text="Add to my languages" color="green" />
                    </div>   
                </div>
                } 
                {savingNewLanguageLoading &&
                    <>
                        <div className="text-center text-3xl">
                            <FontAwesomeIcon icon={faSpinner} className="fa-pulse" />
                        </div>
                    </>
                }
                {newLanguageSuccess && 
                <>
                    <div className="text-center">
                        {language['name']['en']} has been successfully added to your languages.
                        <div className="flex mt-4 gap-4 flex-row justify-center">
                            <div>
                                <Button text="Close" color="charcoal" onClick={closeFunction} />
                            </div>
                            <div>
                                <Link to="/my-languages"><Button text="Go to My Languages" /></Link>
                            </div>
                        </div>
                    </div>
                </>
                }
                {showSourceLanguageGuideModal && 
                    <>
                        <SourceLanguageExplanation closeFunction={closeSourceLanguageGuideModal} />
                    </>
                }
            </div>
        </>
    )
}

const AddSingleLanguageModal = ({savingNewLanguageLoading, newLanguageSuccess, saveNewLanguage, changeSourceLanguage, globalSelectedSourceLanguage, sourceLanguageOptions, language, closeFunction}) => {
    return (
        <>
            <Modal bgColor="bg-gray-100" size="3xl" content={<ModalContent savingNewLanguageLoading={savingNewLanguageLoading} newLanguageSuccess={newLanguageSuccess} saveNewLanguage={saveNewLanguage} changeSourceLanguage={changeSourceLanguage} globalSelectedSourceLanguage={globalSelectedSourceLanguage} sourceLanguageOptions={sourceLanguageOptions} language={language} closeFunction={closeFunction} />} closeFunction={closeFunction} />
        </>
    )
}

export default AddSingleLanguageModal;
