import React, {useState, useEffect} from 'react';
import {useParams} from "react-router-dom";
import {projectFirestore} from '../firebase/config.js';
import CategoryCardGrid from '../components/vocabulary/cards/CategoryCardGrid';

import { useAuth } from "../contexts/AuthContext";

function DeckCategory() {
    let { id } = useParams();
    const category_id = parseInt(id);

    const { currentUser } = useAuth();

    const [deckIds, setDeckIds] = useState(undefined);
    const [category, setCategory] = useState({});

    useEffect(() => {
        findDeckIds(category_id);
    }, [category_id]);

    const findDeckIds = (category_id) => {
        const collectionRef = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('decks').collection('categories'); 
        const docRef = collectionRef.where('category_id', '==', category_id);
        let deck_ids = [];
        docRef.get().then(snapshots => {
            if (snapshots.size === 1){
                //console.log(snapshots);
                snapshots.forEach(snapshot => {
                    if (snapshot.data()['decks'] !== undefined){
                        let ids = snapshot.data()['decks']['id'];
                        setDeckIds(ids);
                    }
                    else{
                        setDeckIds([]);
                    };
                    setCategory(snapshot.data());
                });
            };
        });
        docRef.onSnapshot(snapshots => {
            if (snapshots.size === 1){
                    setCategory(snapshots.docs[0].data());
            };
        });
    };

    //console.log("Deck IDS are now: ", deckIds);
    //console.log("Category is now: ", category);

    return (
        <div className="bg-gray-100">
            <div className=" ">
                <CategoryCardGrid deckIds={deckIds} category={category} />
            </div>
        </div>
    )
}

export default DeckCategory;
