import React from 'react';
import Button from '../../../general/Button';
import Modal from '../../../general/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/pro-duotone-svg-icons';
import { faThumbsDown } from '@fortawesome/pro-duotone-svg-icons';
import { faRotateRight } from '@fortawesome/pro-duotone-svg-icons';

const ModalContent = ({closeFunction}) => {
    return (
        <>
            <div className="overflow-auto h-72 sm:h-full p-4">
                <h1>How to play</h1>
                <p className="text-center my-2">Test your knowledge of your tables with flashcards. Click on the thumbs down icon for each column you don't know and the thumbs up icon if you know the answer.</p>
                <div className="text-xl text-center font-bold text-gray-700 my-6">Keyboard shortcuts</div>
                <div className="flex flex-col place-items-center gap-2">
                    <div className="flex flex-row justify-start gap-8">
                        <div className="font-bold text-gray-700">
                            Spacebar
                        </div>
                        <div>
                            Flip card to the left
                        </div>
                    </div>
                    <div className="flex flex-row justify-start gap-8">
                        <div className="font-bold text-gray-700">
                            Left arrow
                        </div>
                        <div>
                            I got the card on the left wrong
                        </div>
                    </div>
                    <div className="flex flex-row justify-start gap-8">
                        <div className="font-bold text-gray-700">
                            Right arrow
                        </div>
                        <div>
                            I got the card on the right correct
                        </div>
                    </div>
                    <div className="flex flex-row justify-start gap-8">
                        <div className="font-bold text-gray-700">
                            Down arrow
                        </div>
                        <div>
                            Flip card to the left
                        </div>
                    </div>
                </div>
                <div className="p-4 text-center">
                    <Button text="Continue playing" onClick={closeFunction} />
                </div>
            </div>
        </>   
    )
}

const TableFlashcardsModal = ({closeFunction}) => {
  return (
    <>
        <Modal closeFunction={closeFunction} content={<ModalContent closeFunction={closeFunction} />} size="3xl"/>
    </>
  )
};

export default TableFlashcardsModal;
