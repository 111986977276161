import React from 'react';
import DailyProgressWidget from '../../components/statistics/progress/DailyProgressWidget';

const DailyStats = () => {
  return (
    <>
        <div className=" ">
            <DailyProgressWidget />
        </div>
    </>
  )
}

export default DailyStats;