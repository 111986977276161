import {projectFirestore, projectFunctions, timeStamp, firebase} from '../firebase/config.js';


export default function useHelpCenter() {

    const fetchHelpArticle = async (slug) => {
        let query = projectFirestore.collection("help-center").doc("articles").collection("items").where("slug", "==", slug);
        let snap = await query.get();
        if (!snap.empty && snap.docs.length === 1){
            // only unique slugs
            let data = snap.docs[0].data();
            data['doc_id'] = snap.docs[0].ref.id;
            return data;
        } else {
            return false
        }
    }

    const fetchAllHelpArticlesInFolders = async () => {
        let query = projectFirestore.collection("help-center").doc("articles").collection("items");
        let snap = await query.get();
        let articleList = [];
        if (!snap.empty){
            // only unique slugs
            for (const doc of snap.docs){
                let data = doc.data();
                data['doc_id'] = doc.ref.id;
                articleList.push(data);
            }
        } 
        let obj = {}; // object with folder doc ids as keys
        obj['folder_order'] = [];
        obj['articles'] = {};
        let allFolders = await fetchAllHelpArticleFolders(); // sorted folders
        for (const folder of allFolders){
            obj['folder_order'].push(folder); // keep track of order
            obj['articles'][folder.doc_id] = [];
            let notFoundArticles = [];
            if (folder.hasOwnProperty('children_order')){
                let articleOrder = folder['children_order'];
                for (const articleOrderDocId of articleOrder){
                    let found = false;
                    for (const article of articleList){
                        if (article.doc_id === articleOrderDocId && article.folder_doc_id === folder.doc_id){
                            obj['articles'][folder.doc_id].push(article);
                            found = true;
                        }
                    }
                    if (!found){
                        notFoundArticles.push(articleOrderDocId);
                    }
                }
            } 
            else {
                for (const article of articleList){
                    if (article.folder_doc_id === folder.doc_id){
                        obj['articles'][folder.doc_id].push(article);
                    }
                }
            }
            
            for (const notFoundArticle of notFoundArticles){
                console.log("Did not found this article in folder: ", notFoundArticle, folder);
            }
        }
        return obj;
    }

    const fetchAllHelpArticles = async () => {
        let query = projectFirestore.collection("help-center").doc("articles").collection("items");
        let snap = await query.get();
        let list = [];
        if (!snap.empty){
            // only unique slugs
            for (const doc of snap.docs){
                let data = doc.data();
                data['doc_id'] = doc.ref.id;
                list.push(data);
            }

        } 
        return list;
    }

    const fetchAllHelpArticleFolders = async () => {
        let query = projectFirestore.collection("help-center").doc("folders").collection("items");
        let snap = await query.get();
        let list = [];
        if (!snap.empty){
            // only unique slugs
            for (const doc of snap.docs){
                let data = doc.data();
                data['doc_id'] = doc.ref.id;
                list.push(data);
            }
        } 
        let orderQuery = projectFirestore.collection("help-center").doc("folders");
        let orderSnap = await orderQuery.get();
        let newList = [];
        if (orderSnap.exists){
            let order = orderSnap.data()['folder_order'];
            for (const doc_id of order){
                for (const folder of list){
                    if (doc_id === folder.doc_id){
                        newList.push(folder);
                    }
                }
            }
            return newList;
        } else {
            return list; 
        }
       
    }

    return {
        fetchHelpArticle, 
        fetchAllHelpArticles, 
        fetchAllHelpArticleFolders, 
        fetchAllHelpArticlesInFolders
    }
}