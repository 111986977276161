import React from "react";
import SortByButton from "../general/sorting/SortByButton";
import useUserSettings from "../../hooks/useUserSettings";
import { useUserInfoContext } from "../../contexts/UserInfoContext";

const ChangeViewComponent = (props) => {
    const {view, setView} = props;

    const {updateLibraryViewSetting} = useUserSettings(); 
    const {refreshUserSettings} = useUserInfoContext();
    
    const onViewChange = async (option) => {
        setView(option.value);
        await updateLibraryViewSetting({view: option.value});
        await refreshUserSettings();
    }

    const viewOptions = [{label: 'Tree view', value: 'tree'}, {label: 'Grid view', value: 'grid'}];
    const viewOptionsDict = viewOptions.reduce(function(result, item) {
        var key = item.value; 
        result[key] = item;
        return result;
      }, {});
    return (
        <div>
            <SortByButton onChange={onViewChange} textSize={"base"} value={viewOptionsDict[view]} options={viewOptions} hideLabel={false} rightOrLeft={"left"} />
        </div>
    )
}

export default ChangeViewComponent;