import React, {useState, useEffect, useCallback} from 'react';
import Button from '../general/Button';
import Modal from '../general/Modal';
import {useNavigate} from 'react-router-dom';
import {motion} from 'framer-motion';
import SimpleErrorBar from '../general/SimpleErrorBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faNote} from '@fortawesome/pro-solid-svg-icons';
import {faFolderPlus} from '@fortawesome/pro-solid-svg-icons';
import {faBackwardStep} from '@fortawesome/pro-solid-svg-icons';
import {faCardsBlank} from '@fortawesome/pro-solid-svg-icons';
import {faTableColumns} from '@fortawesome/pro-solid-svg-icons';
import { faLoader, faFontCase } from '@fortawesome/pro-solid-svg-icons';
import {  faSchool, faText, faThumbsDown } from '@fortawesome/pro-duotone-svg-icons';
import { faRotateRight } from '@fortawesome/pro-duotone-svg-icons';
import {AddDeckModalContent} from '../vocabulary/modals/AddDeckModal';
import Select from 'react-select';
import useTables from '../../hooks/useTables';
import useCourses from '../../hooks/useCourses';
import useNotes from '../../hooks/useNotes';
import useTexts from '../../hooks/useTexts';
import {useLanguagesContext} from '../../contexts/LanguagesContext';
import { useAuth } from '../../contexts/AuthContext';

const ModalContent = ({closeFunction, filterContentList, newItemParentFolderDocId, createItemMode, setNewItemType, setNewItemLoading, newItemLoading, newItemType }) => {
    const [page, setPage] = useState("content-type");
    const [loading, setLoading] = useState(true);
    const [filtered, setFiltered] = useState(false);
    const contentClick = useCallback((type) => {
        setNewItemType(type);
        setPage("metadata");
    },[setNewItemType]);

    const {currentUser} = useAuth();

    useEffect(()=>{
        if (filterContentList !== undefined && filterContentList !== null && filterContentList.length === 1){
            let filter = filterContentList[0];
            if (filter === "decks"){
                contentClick("deck");
            }
            if (filter === "tables"){
                contentClick("table");
            }
            if (filter === "notes"){
                contentClick("note");
            }
            setFiltered(true);
            setLoading(false);
        } else {
            setLoading(false);
        }
    },[filterContentList, contentClick]);

    /*
<motion.div onClick={()=>contentClick("note")} whileHover={{scale:1.02}} className="bg-white sm:h-48 sm:w-48 cursor-pointer rounded-xl shadow-lg py-5 px-10 flex flex-col justify-start place-items-center gap-4">
                                    <div className="font-bold text-gray-600 text-lg">
                                        Note
                                    </div>
                                    <div className="text-sandy-brown text-6xl sm:text-8xl">
                                        <FontAwesomeIcon icon={faNote} />
                                    </div>
                                </motion.div>
    */

    return (
        <>
            <div className="h-full  flex flex-col gap-5 py-4">
                {!newItemLoading && !loading ?
                    <>
                        
                        {page === "content-type" &&
                            <>
                            <div className="flex flex-col gap-5">
                            <h1>Create a new {createItemMode === "main" ? "item" : "subitem"}</h1>
                            <div className=" flex flex-col lg:flex-row gap-4 sm:gap-4 justify-evenly w-full">
                                <motion.div onClick={()=>contentClick("deck")} whileHover={{scale:1.02}} className="bg-white cursor-pointer rounded-xl shadow-lg py-5 px-10 flex flex-col justify-start place-items-center gap-4">
                                    <div className="font-bold text-gray-600 text-lg">
                                        Deck
                                    </div>
                                    <div className="text-burnt-sienna text-6xl sm:text-8xl">
                                        <FontAwesomeIcon icon={faCardsBlank} />
                                    </div>
                                    <div className="text-sm muted-text text-center">
                                        A collection of words and phrases you want to remember.
                                    </div>
                                </motion.div>
                                <motion.div onClick={()=>contentClick("table")} whileHover={{scale:1.02}} className="bg-white  cursor-pointer rounded-xl shadow-lg py-5 px-10 flex flex-col justify-start place-items-center gap-4">
                                    <div className="font-bold text-gray-600 text-lg">
                                        Table
                                    </div>
                                    <div className="text-beautiful-blue text-6xl sm:text-8xl">
                                        <FontAwesomeIcon icon={faTableColumns} />
                                    </div>
                                    <div className="text-sm muted-text text-center">
                                        Study conjugations, declensions, or any other type of table.
                                    </div>
                                </motion.div>
                                <motion.div onClick={()=>contentClick("text")} whileHover={{scale:1.02}} className="bg-white  cursor-pointer rounded-xl shadow-lg py-5 px-10 flex flex-col justify-start place-items-center gap-4">
                                    <div className="font-bold text-gray-600 text-lg">
                                        Text
                                    </div>
                                    <div className="text-charcoal text-6xl sm:text-8xl">
                                        <FontAwesomeIcon icon={faFontCase} />
                                    </div>
                                    <div className="text-sm muted-text text-center">
                                        Paste a text in your target language that you want to study.
                                    </div>
                                </motion.div>
                               
                            </div>
                                {currentUser !== null && currentUser !== undefined && currentUser['uid'] === "d1AsNHNfQUWYYoplP3UNdKcATss1" &&
                                    <>
                                        <h2>Beta features for beta testers</h2>
                                        <div className=" flex flex-col lg:flex-row gap-4 sm:gap-4 justify-evenly w-full">
                                            <motion.div onClick={()=>contentClick("note")} whileHover={{scale:1.02}} className="bg-white sm:h-48 sm:w-48 cursor-pointer rounded-xl shadow-lg py-5 px-10 flex flex-col justify-start place-items-center gap-4">
                                                <div className="font-bold text-gray-600 text-lg">
                                                    Note
                                                </div>
                                                <div className="text-sandy-brown text-6xl sm:text-8xl">
                                                    <FontAwesomeIcon icon={faNote} />
                                                </div>
                                            </motion.div>
                                            <motion.div onClick={()=>contentClick("course")} whileHover={{scale:1.02}} className="bg-white sm:h-48 sm:w-48 cursor-pointer rounded-xl shadow-lg py-5 px-10 flex flex-col justify-start place-items-center gap-4">
                                                <div className="font-bold text-gray-600 text-lg">
                                                    Course
                                                </div>
                                                <div className="text-charcoal text-6xl sm:text-8xl">
                                                    <FontAwesomeIcon icon={faSchool} />
                                                </div>
                                            </motion.div>
                                        </div>
                                    </>
                                }
                            </div>
                            </>
                        }
                        {page === "metadata" &&
                            <>
                                {!filtered &&
                                    <div onClick={()=>setPage("content-type")} className="flex flex-row gap-2 text-charcoal text-sm cursor-pointer underline">
                                        <div>
                                            <FontAwesomeIcon icon={faBackwardStep} />
                                        </div>
                                        <div>
                                            Change item type
                                        </div>
                                    </div>
                                }
                                    {newItemType === "deck" && 
                                        <>
                                            <AddDeckModalContent createItemMode={createItemMode} newItemParentFolderDocId={newItemParentFolderDocId} setAddNewDeckLoading={setNewItemLoading} closeFunction={closeFunction} />
                                        </>
                                    }
                                    {newItemType === "table" && 
                                        <>
                                            <AddTableModalContent createItemMode={createItemMode} newItemParentFolderDocId={newItemParentFolderDocId} setAddNewTableLoading={setNewItemLoading} closeFunction={closeFunction} />
                                        </>
                                    }
                                    {newItemType === "note" && 
                                        <>
                                            <AddNoteModalContent setAddNewNoteLoading={setNewItemLoading} newItemParentFolderDocId={newItemParentFolderDocId} createItemMode={createItemMode} closeFunction={closeFunction} />
                                        </>
                                    }
                                     {newItemType === "text" && 
                                        <>
                                            <AddTextModalContent setAddNewTextLoading={setNewItemLoading} closeFunction={closeFunction} newItemParentFolderDocId={newItemParentFolderDocId} createItemMode={createItemMode} />
                                        </>
                                    }
                                    {newItemType === "course" && 
                                        <>
                                            <AddCourseModalContent setNewItemLoading={setNewItemLoading} closeFunction={closeFunction} newItemParentFolderDocId={newItemParentFolderDocId} createItemMode={createItemMode} />
                                        </>
                                    }
                            </>
                        }
                    </>
                : 
                    <>
                        <div className="text-center text-4xl">
                            <FontAwesomeIcon icon={faLoader} className="fa-spin" />
                        </div>
                    </>
                }
            </div>
        </>   
    )
}

const AddNoteModalContent = ({setAddNewNoteLoading, closeFunction, newItemParentFolderDocId, createItemMode}) => {
    let {allLanguages, activeUserLanguages, globalSelectedTargetLanguage, setGlobalSelectedTargetLanguage, globalSelectedSourceLanguage, setGlobalSelectedSourceLanguage, activeTargetLanguageOptions, sourceLanguageOptions} = useLanguagesContext();
    const [newNoteName, setNewNoteName] = useState("");
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [navigateAfterCreation, setNavigateAfterCreation] = useState(true);

    const changeSourceLanguage = (option) => {
        setGlobalSelectedSourceLanguage(option.value);
    }

    const {getNotesFromCurrentUserInRealtime, 
        saveNewNote} = useNotes();

    const navigate = useNavigate();

    const saveNewNoteClick = async () => {
        setAddNewNoteLoading(true);
        let r = await saveNewNote(globalSelectedTargetLanguage, globalSelectedSourceLanguage, newNoteName, createItemMode, newItemParentFolderDocId);
        if (r !== false){
            let id = r['data']['note_id'];
            if (typeof id === 'number' && navigateAfterCreation) {
                navigate("/my-notes/"+id);
            }
        }
        setAddNewNoteLoading(false);
        closeFunction();
        
    }

    const newNoteNameChange = (e) => {
        setNewNoteName(e.target.value);
    }

    const tableNameKeyDown = (e) => {
        if (e.keyCode === 13) {
            saveNewNoteClick();
        } else {
            if (error === true){
                setError(false);
                setErrorMessage("");
            }
        }
    }

    return (
        <>
            <h1>Create a new {allLanguages[0][globalSelectedTargetLanguage]['name']['en']} note</h1>  
            <div className="flex flex-col gap-4">
                <div>
                    <div className="font-bold text-gray-600">
                        Name of the note
                    </div>
                    <div className="text-center">
                        <input value={newNoteName} onKeyDown={tableNameKeyDown} onChange={newNoteNameChange} type="text" className={(error === true ? 'border-red-500 ' : '' ) + "bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white"} />
                    </div>
                    {error === true &&
                        <>
                            <SimpleErrorBar message={errorMessage} />
                        </>
                    }
                </div>
                <div>
                    <div className="font-bold text-gray-600">
                        Source language
                    </div>
                    <div>    
                        <Select onChange={changeSourceLanguage} isSearchable={true} options={sourceLanguageOptions[0]} value={sourceLanguageOptions[1][globalSelectedSourceLanguage]}  />
                    </div>
                </div>
                <div>
                    {error && 
                        <SimpleErrorBar message={"Could not save your note. Please try again."} />
                    }
                </div>
                <div className="flex flex-row gap-2 text-charcoal">
                            <input type="checkbox" checked={navigateAfterCreation} onChange={()=>setNavigateAfterCreation(!navigateAfterCreation)} />
                            <div>
                                Navigate to new note after creation
                            </div>
                        </div>
                <div className="flex flex-row justify-center gap-3">
                    <div>
                        <Button onClick={closeFunction} color="charcoal" text="Cancel" />
                    </div>
                    <div>
                        <Button onClick={saveNewNoteClick} color="green" text="Create note" />
                    </div>
                </div>
            </div>
        </>
    )
}

const AddTableModalContent = ({setAddNewTableLoading, closeFunction, newItemParentFolderDocId, createItemMode}) => {
    let {allLanguages, activeUserLanguages, globalSelectedTargetLanguage, setGlobalSelectedTargetLanguage, globalSelectedSourceLanguage, setGlobalSelectedSourceLanguage, activeTargetLanguageOptions, sourceLanguageOptions} = useLanguagesContext();
    const [newTableName, setNewTableName] = useState("");
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const defaultTemplateOptions = [{label: 'No template', value: 'default'}, {label: 'Standard verb tenses', value: 'verb-tenses'}];
    const [selectedTemplateOption, setSelectedTemplateOption] = useState(defaultTemplateOptions[0]);
    const [navigateAfterCreation, setNavigateAfterCreation] = useState(true);
    const {getTablesFromCurrentUserInRealtime, 
        saveNewTable, 
        deleteTable} = useTables();

    const changeSourceLanguage = (option) => {
        setGlobalSelectedSourceLanguage(option.value);
    }

    const navigate = useNavigate();

    const saveNewTableClick = async () => {
        console.log(globalSelectedTargetLanguage, globalSelectedSourceLanguage, newTableName, selectedTemplateOption.value);
        if (newTableName === ""){
            setError(true);
            setErrorMessage("Table name can't be blank.");
        } else {
            setAddNewTableLoading(true);
            let data = await saveNewTable(globalSelectedTargetLanguage, globalSelectedSourceLanguage, newTableName, selectedTemplateOption.value,  createItemMode, newItemParentFolderDocId);
            if (data !== false){
                let id = data['data']['tableId'];
                if (typeof id === 'number' && navigateAfterCreation) {
                    navigate("/my-tables/"+id);
                }
            }
            setAddNewTableLoading(false);
            closeFunction();
        }
    }

    const tableNameChange = (e) => {
        setNewTableName(e.target.value);
    }

    const tableNameKeyDown = (e) => {
        if (e.keyCode === 13) {
            saveNewTableClick();
        } else {
            if (error === true){
                setError(false);
                setErrorMessage("");
            }
        }
    }

    const changeTemplateOption = (option) => {
        setSelectedTemplateOption(option);
    }
    

    return (
        <>
            <div>
                <h1>Create a new {allLanguages[0][globalSelectedTargetLanguage]['name']['en']} table</h1>
                    
                    <div className="flex flex-col gap-4">
                        <div>
                            <div className="font-bold text-gray-600">
                                Name of the table
                            </div>
                            <div className="text-center">
                                <input value={newTableName} onKeyDown={tableNameKeyDown} onChange={tableNameChange} type="text" className={(error === true ? 'border-red-500 ' : '' ) + "bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white"} />
                            </div>
                            {error === true &&
                                <>
                                    <div className="italic text-red-400">
                                        {errorMessage}
                                    </div>
                                </>
                            }
                        </div>
                        <div>
                            <div className="font-bold text-gray-600">
                                Column language
                            </div>
                            <div>    
                                <Select onChange={changeSourceLanguage} isSearchable={true} options={sourceLanguageOptions[0]} value={sourceLanguageOptions[1][globalSelectedSourceLanguage]}  />
                            </div>
                        </div>
                        <div className="flex flex-row gap-2 text-charcoal">
                            <input type="checkbox" checked={navigateAfterCreation} onChange={()=>setNavigateAfterCreation(!navigateAfterCreation)} />
                            <div>
                                Navigate to new table after creation
                            </div>
                        </div>
                        <div className="flex flex-row justify-center gap-3">
                            <div>
                                <Button onClick={closeFunction} color="charcoal" text="Cancel" />
                            </div>
                            <div>
                                <Button onClick={saveNewTableClick} color="green" text="Create table" />
                            </div>
                        </div>
                    </div>
                    
            </div>
        </>
    )
}

const AddTextModalContent = ({setAddNewTextLoading, closeFunction, newItemParentFolderDocId, createItemMode}) => {
    let {allLanguages, activeUserLanguages, globalSelectedTargetLanguage, setGlobalSelectedTargetLanguage, globalSelectedSourceLanguage, setGlobalSelectedSourceLanguage, activeTargetLanguageOptions, sourceLanguageOptions} = useLanguagesContext();
    const [newTextName, setNewTextName] = useState("");
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const defaultTemplateOptions = [{label: 'No template', value: 'default'}, {label: 'Standard verb tenses', value: 'verb-tenses'}];
    const [selectedTemplateOption, setSelectedTemplateOption] = useState(defaultTemplateOptions[0]);
    const [navigateAfterCreation, setNavigateAfterCreation] = useState(true);
    const {saveNewText
        } = useTexts();

    const changeSourceLanguage = (option) => {
        setGlobalSelectedSourceLanguage(option.value);
    }

    const navigate = useNavigate();

    const saveNewTextClick = async () => {
        console.log(globalSelectedTargetLanguage, globalSelectedSourceLanguage, newTextName, selectedTemplateOption.value);
        if (newTextName === ""){
            setError(true);
            setErrorMessage("Text name can't be blank.");
        } else {
            setAddNewTextLoading(true);
            let data = await saveNewText({targetLanguage:globalSelectedTargetLanguage, title:newTextName, parentFolderDocId:newItemParentFolderDocId});
            if (data !== false){
                let id = data['data']['textId'];
                if (typeof id === 'number' && navigateAfterCreation) {
                    navigate("/my-texts/"+id);
                }
            }
            setAddNewTextLoading(false);
            closeFunction();
        }
    }

    const textNameChange = (e) => {
        setNewTextName(e.target.value);
    }

    const textNameKeyDown = (e) => {
        if (e.keyCode === 13) {
            saveNewTextClick();
        } else {
            if (error === true){
                setError(false);
                setErrorMessage("");
            }
        }
    }

    const changeTemplateOption = (option) => {
        setSelectedTemplateOption(option);
    }
    

    return (
        <>
            <div>
                <h1>Create a new {allLanguages[0][globalSelectedTargetLanguage]['name']['en']} text</h1>    
                <div className="flex flex-col gap-4">
                    <div>
                        <div className="font-bold text-gray-600">
                            Name of the text
                        </div>
                        <div className="text-center">
                            <input value={newTextName} onKeyDown={textNameKeyDown} onChange={textNameChange} type="text" className={(error === true ? 'border-red-500 ' : '' ) + "bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white"} />
                        </div>
                        {error === true &&
                            <>
                                <div className="italic text-red-400">
                                    {errorMessage}
                                </div>
                            </>
                        }
                    </div>
                    <div>
                        <div className="font-bold text-gray-600">
                            Source language
                        </div>
                        <div className="">  
                            <div className="">  
                                <Select onChange={changeSourceLanguage} isSearchable={true} options={sourceLanguageOptions[0]} value={sourceLanguageOptions[1][globalSelectedSourceLanguage]} />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row gap-2 text-charcoal">
                        <input type="checkbox" checked={navigateAfterCreation} onChange={()=>setNavigateAfterCreation(!navigateAfterCreation)} />
                        <div>
                            Navigate to new text after creation
                        </div>
                    </div>
                    <div className="flex flex-row justify-center gap-3">
                        <div>
                            <Button onClick={closeFunction} color="charcoal" text="Cancel" />
                        </div>
                        <div>
                            <Button onClick={saveNewTextClick} color="green" text="Create text" />
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

const AddCourseModalContent = ({setNewItemLoading, closeFunction, newItemParentFolderDocId, createItemMode}) => {
    let {allLanguages, activeUserLanguages, globalSelectedTargetLanguage, setGlobalSelectedTargetLanguage, globalSelectedSourceLanguage, setGlobalSelectedSourceLanguage, activeTargetLanguageOptions, sourceLanguageOptions} = useLanguagesContext();
    const [newItemName, setNewItemName] = useState("");
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [navigateAfterCreation, setNavigateAfterCreation] = useState(true);

    const {createNewCourse} = useCourses();
    const navigate = useNavigate();

    const itemNameKeyDown = (e) => {
        if (e.keyCode === 13) {
            saveNewItemClick();
        } else {
            if (error === true){
                setError(false);
                setErrorMessage("");
            }
        }
    }

    const itemNameChange = (e) => {
        setNewItemName(e.target.value);
    }

    const saveNewItemClick = async () => {
        console.log("New course: ", globalSelectedTargetLanguage, globalSelectedSourceLanguage, newItemName, newItemParentFolderDocId);
        if (newItemName === ""){
            setError(true);
            setErrorMessage("Course name can't be blank.");
        } else {
            setNewItemLoading(true);
            let data = await createNewCourse({targetLanguage:globalSelectedTargetLanguage, sourceLanguage: globalSelectedSourceLanguage, name:newItemName, parentFolderDocId:newItemParentFolderDocId});
            if (data !== false){
                let id = data['data']['itemId'];
                if (typeof id === 'number' && navigateAfterCreation) {
                    navigate("/my-courses/"+id);
                }
            }
            setNewItemLoading(false);
            closeFunction();
        }
    }

    const changeSourceLanguage = (option) => {
        setGlobalSelectedSourceLanguage(option.value);
    }

    return (
        <>
        <div>
                <h1>Create a new {allLanguages[0][globalSelectedTargetLanguage]['name']['en']} course</h1>    
                <div className="flex flex-col gap-4">
                    <div>
                        <div className="font-bold text-gray-600">
                            Name of the course
                        </div>
                        <div className="text-center">
                            <input value={newItemName} onKeyDown={itemNameKeyDown} onChange={itemNameChange} type="text" className={(error === true ? 'border-red-500 ' : '' ) + "bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white"} />
                        </div>
                        {error === true &&
                            <>
                                <div className="italic text-red-400">
                                    {errorMessage}
                                </div>
                            </>
                        }
                    </div>
                    <div>
                        <div className="font-bold text-gray-600">
                            Source language
                        </div>
                        <div className="">  
                            <div className="">  
                                <Select onChange={changeSourceLanguage} isSearchable={true} options={sourceLanguageOptions[0]} value={sourceLanguageOptions[1][globalSelectedSourceLanguage]} />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row gap-2 text-charcoal">
                        <input type="checkbox" checked={navigateAfterCreation} onChange={()=>setNavigateAfterCreation(!navigateAfterCreation)} />
                        <div>
                            Navigate to new course after creation
                        </div>
                    </div>
                    <div className="flex flex-row justify-center gap-3">
                        <div>
                            <Button onClick={closeFunction} color="charcoal" text="Cancel" />
                        </div>
                        <div>
                            <Button onClick={saveNewItemClick} color="green" text="Create course" />
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

const CreateItemModal = ({closeFunction, filterContentList, newItemParentFolderDocId, createItemMode, newItemLoading, setNewItemLoading, setAddNewTextLoading, setNewItemType, newItemType }) => {
  return (
    <>
        <Modal bgColor="bg-gray-100" overflow={"overflow-auto"} closeFunction={closeFunction} content={<ModalContent filterContentList={filterContentList} newItemParentFolderDocId={newItemParentFolderDocId} newItemLoading={newItemLoading} createItemMode={createItemMode} setNewItemLoading={setNewItemLoading} setAddNewTextLoading={setAddNewTextLoading} setNewItemType={setNewItemType} newItemType={newItemType}  closeFunction={closeFunction} />} size="3xl"/>
    </>
  )
};

export default CreateItemModal;
