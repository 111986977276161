import { faChevronRight, faHome } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const ViewFolderPath = (props) => {
    const {folderDocId, menuItems} = props;
    console.log(folderDocId, menuItems);
    const [pathItems, setPathItems] = useState([]);

    useEffect(()=>{

        let currentRecursiveItems = [{text: "Home", link: "/my-library"}];
        const recursiveSearch = (items, isChild) => {
            let foundFolder = false;
            for (const item of items){
                if (item.doc_id === folderDocId){
                    console.log("Found folder: ", item, currentRecursiveItems)
                    currentRecursiveItems.push({...item, link: "/my-library/folder/"+item.doc_id }); //current folder
                    foundFolder = true;
                    setPathItems(currentRecursiveItems);
                    return true;
                }
                else if (item.children !== undefined && item.children !== null){
                    currentRecursiveItems.push({...item, link: "/my-library/folder/"+item.doc_id });
                    const found = recursiveSearch(item.children, true);
                    if (found){return true}
                }
            }
            if (!foundFolder && isChild === true){
                console.log("removing ", currentRecursiveItems[currentRecursiveItems.length - 1])
                currentRecursiveItems.pop();
            }
            return false;
        };


        if (folderDocId === undefined){
            setPathItems(currentRecursiveItems);
        }
        else {
            //inside a folder
            recursiveSearch(menuItems, false);
        }
    },[folderDocId, menuItems]);

    return (
        <>
            <div className="flex flex-row place-items-center gap-4">
                {pathItems !== null && pathItems.map((item, index) => (
                    <div key={index} className="flex flex-row place-items-center gap-4">
                        {pathItems.length < 4 ?
                            <> 
                                <PathItem text={item.text} link={item.link} index={index} />
                                {index !== pathItems.length - 1 &&
                                    <div className="muted-text">
                                        <FontAwesomeIcon icon={faChevronRight} />
                                    </div>
                                }
                            </>
                        :
                            <>
                                {(index < 2 || index > pathItems.length - 3) ?
                                    <>
                                        <PathItem text={item.text} link={item.link} index={index} />
                                        {index !== pathItems.length - 1 &&
                                            <div className="muted-text">
                                                <FontAwesomeIcon icon={faChevronRight} />
                                            </div>
                                        }
                                    </>
                                :
                                    <>
                                        <div>
                                            ...
                                        </div>
                                        {index !== pathItems.length - 1 &&
                                            <div className="muted-text">
                                                <FontAwesomeIcon icon={faChevronRight} />
                                            </div>
                                        }
                                    </>
                                }
                            </>
                        }
                        
                    </div>
                ))}
            </div>
        </>
    )
}

const PathItem = (props) => {
    const {text, link, index} = props;

    return (
        <>
            <Link to={link} className="flex flex-row gap-2">
                {index === 0 &&
                    <div className="muted-text">
                        <FontAwesomeIcon icon={faHome} />
                    </div>
                }
                {text}
            </Link>
        </>
    )
}

export default ViewFolderPath;