import React, {useState, useEffect, useRef, useCallback} from 'react';
import useSearch from '../../../hooks/useSearch';
import useQueryStrings from '../../../hooks/useQueryStrings';
import {useLanguagesContext} from '../../../contexts/LanguagesContext';
import Card from '../../../components/vocabulary/cards/Card';
import SortByButton from '../../../components/general/sorting/SortByButton';
import {DebounceInput} from 'react-debounce-input';
import Skeleton from 'react-loading-skeleton';
import {motion} from 'framer-motion';
import {Link} from 'react-router-dom';
import {useMyContentContext} from '../../../contexts/MyContentContext';

const MyDeckCards = () => {

    let {allLanguages, 
        globalSelectedTargetLanguage
    } = useLanguagesContext();

    const {getScopedTypesenseDeckCardsApiKey} = useMyContentContext();
    const [currentPage, setCurrentPage] = useState(null);
    const [numberOfCards, setNumberOfCards] = useState(null);
    const [numberOfResults, setNumberOfResults] = useState(null);
    const [numberOfCardsPerPage, setNumberOfCardsPerPage] = useState(25);
    const [numberOfPages, setNumberOfPages] = useState(null);
    const [cardsLoading, setCardsLoading] = useState(true);
    const [sortByValue, setSortByValue] = useState("last_updated_timestamp_desc");
    const [searchTerm, setSearchTerm] = useState("");
    const [cards, setCards] = useState(null);
    const [previousLanguage, setPreviousLanguage] = useState(globalSelectedTargetLanguage);

    let firstTimeUseEffect = useRef(true);
    const [sortByOptions, setSortByOptions] = useState([
        {label: 'Target alphabetically', value:'target_alphabetically'}, 
        {label: 'Source alphabetically', value:'source_alphabetically'},  
        {label: 'Last updated (ascending)', value:'last_updated_timestamp_asc'},
        {label: 'Last updated (descending)', value:'last_updated_timestamp_desc'},  
        {label: 'Created (ascending)', value:'created_timestamp_asc'}, 
        {label: 'Created (descending)', value:'created_timestamp_desc'}    
    ]);
    const [sortByOptionsDict, setSortByOptionsDict] = useState(sortByOptions.reduce(function(result, item) {
        var key = item.value; 
        result[key] = item;
        return result;
      }, {}));
    let {addAndRemoveQueryStrings, 
        getQueryStrings} = useQueryStrings();


    const getQueryStringsCallback = useCallback(()=>{
        return getQueryStrings();
    },[getQueryStrings]);

    const {searchAllCurrentUserDeckCards, 
        getScopedDeckCardsApiKey} = useSearch();

    useEffect(()=>{
        if (globalSelectedTargetLanguage !== null && previousLanguage !== globalSelectedTargetLanguage){
            // change of language
            firstTimeUseEffect.current = true;
            setPreviousLanguage(globalSelectedTargetLanguage);
        }
    },[globalSelectedTargetLanguage, previousLanguage])

    useEffect(()=>{

        const fetchCardsFunction = async (p, q, s) => {
            firstTimeUseEffect.current = false;
            setCardsLoading(true);
            let results = null;
            let apiKey = await getScopedTypesenseDeckCardsApiKey();
            results = await searchAllCurrentUserDeckCards(q, apiKey.key, p, numberOfCardsPerPage, s, globalSelectedTargetLanguage);
            if (results !== undefined && results !== null){
                setCards(results['hits'].map((hit)=>{return {...hit.document}}));
                setNumberOfResults(results['found']);
                setNumberOfPages(Math.ceil(results['found']/numberOfCardsPerPage));
                if (q === ""){
                    setNumberOfCards(results['found']);
                }
            }
            setCardsLoading(false);
        }

        const fetchData = async () => {
            let results = await getQueryStrings();
            let p, s, q;
            if (results !== null && results['page'] !== undefined){
                p = parseInt(results['page']);
                if (!isNaN(p)){
                    setCurrentPage(p);
                } else {
                    setCurrentPage(1);
                }
            } else {
                setCurrentPage(1);
                p = 1;
            } 
    
            if (results !== null && results['sort'] !== undefined){
                s = results['sort'];
                setSortByValue(results['sort']);
            } else {
                s = 'last_updated_timestamp_desc';
                setSortByValue('last_updated_timestamp_desc');
            }
    
            
            if (results !== null && results['q'] !== undefined){
                q = results['q'];
                setSearchTerm(results['q']);
            } else {
                q = '';
                setSearchTerm('');
            }
    
            if (globalSelectedTargetLanguage !== null && numberOfCardsPerPage !== null){
                if (firstTimeUseEffect.current){
                    firstTimeUseEffect.current = false;
                    fetchCardsFunction(p, q, s);
                }
            }
        }

        fetchData();
        
    },[getQueryStrings, globalSelectedTargetLanguage, numberOfCardsPerPage, getScopedDeckCardsApiKey, searchAllCurrentUserDeckCards]);

/*     useEffect(()=>{
        const fetchCardsFunction = async () => {
            firstTimeUseEffect.current = false;
            setCardsLoading(true);
            let results = null;
            if (typesenseApi === null){
                let apiKey = await getScopedDeckCardsApiKey();
                setTypesenseApi(apiKey);
                results = await searchAllCurrentUserDeckCards(searchTerm, apiKey, currentPage, numberOfCardsPerPage, sortByValue, globalSelectedTargetLanguage);
            }
            if (typesenseApi !== null){
                results = await searchAllCurrentUserDeckCards(searchTerm, typesenseApi, currentPage, numberOfCardsPerPage, sortByValue, globalSelectedTargetLanguage);
            }
            if (results !== undefined && results !== null){

                setCards(results['hits'].map((hit)=>{return {...hit.document}}));
                setNumberOfResults(results['found']);
                setNumberOfPages(Math.ceil(results['found']/numberOfCardsPerPage));
                if (searchTerm === ""){
                    setNumberOfCards(results['found']);
                }
            }
            setCardsLoading(false);
        }
        
        if (currentPage !== null && globalSelectedTargetLanguage !== null){
            if (!firstTimeUseEffect.current){return}
            fetchCardsFunction();
        }
    },[currentPage, getScopedDeckCardsApiKey, numberOfCardsPerPage, searchAllCurrentUserDeckCards, searchTerm, sortByValue, typesenseApi, globalSelectedTargetLanguage])
 */
    const searchInputChange = (e) => {
        firstTimeUseEffect.current = true;
        addAndRemoveQueryStrings([{'name': 'q', 'value': e.target.value.trim()}, {'name': 'page', 'value': 1}],[]);
    }

    const changePageNumber = (n) => {
        setCurrentPage(n);
        firstTimeUseEffect.current = true;
        addAndRemoveQueryStrings([{'name': 'page', 'value': n}],[]); 
    }

    const orderByChange = (option) => {
        firstTimeUseEffect.current = true;
        addAndRemoveQueryStrings([{'name': 'sort', 'value': option.value}],[]);
    }

    return (
        <>
            <div className="  min-h-screen h-full flex flex-col gap-6">
                <h1>My {(allLanguages !== null && globalSelectedTargetLanguage !== null) && allLanguages[0][globalSelectedTargetLanguage]['name']['en']} cards</h1>
                <div className="flex text-base mt-6 flex-col sm:flex-row gap-8 w-full place-items-center justify-evenly">
                    <div className="justify-self-center w-5/6 m-auto sm:m-0 sm:w-1/3">
                        <DebounceInput 
                            placeHolder="Search for cards" 
                            value={searchTerm} 
                            onChange={searchInputChange} 
                            minLength={1}
                            debounceTimeout={200}
                            type="text" 
                            className="bg-white appearance-none h-12 border-solid border border-gray-300 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white" 
                        />
                    </div>
                    <div className="text-sm justify-self-end">
                        <SortByButton textSize={"sm"} onChange={orderByChange} value={sortByOptionsDict[sortByValue]} options={sortByOptions} />
                    </div>
                </div>
                <div className="mt-4 md:mt-0 text-center sm:text-left md:mx-5 flex flex-row justify-between">
                    <div className="font-bold text-sm text-gray-700 flex flex-row gap-2">

                        <div>
                            Showing {numberOfResults} of {numberOfCards !== null ? <>{numberOfCards.toLocaleString()} cards</> : <Skeleton count={1} height={30} />}
                        </div>
                    </div>
                    
                    <div className="font-bold text-sm text-gray-700">
                        {(currentPage !== null && numberOfPages !== null) ? <>Page: {currentPage}/{numberOfPages === 0 ? numberOfPages + 1 : numberOfPages}</> : <Skeleton count={1} height={30} />}
                    </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2  xl:grid-cols-3 justify-center place-items-center w-5/6 m-auto">
                    {cardsLoading && 
                    <>
                        {Array.apply(null, Array(numberOfCardsPerPage)).map((i, index) => (
                            <div key={"loading_"+index} className="grid-item">
                                <div className="grid-content">
                                    <Skeleton count={1} height={300} />
                                </div>       
                            </div>
                        ))}
                    </>
                    }
                    {(cards !== null && !cardsLoading) &&
                        <>
                            {cards.map((card, cardIndex)=>(
                                <>
                                    <motion.div whileHover={{scale:1.02}} className="lg:h-72 md:w-72 w-56 h-56 cursor-pointer" key={"card_"+cardIndex}>
                                        <Link className="no-underline" to={"/my-decks/"+card.deck_id+"?q="+card.target+"+"+card.source}>
                                            <Card viewMode={"allCards"} allLanguages={allLanguages} apiType={"typesense"} docs={cards} setDocs={setCards} editAuthorization={false} doc={card} gradient="card-blue-gradient" />
                                        </Link>
                                    </motion.div>
                                </>
                            ))}
                        </>
                    }
                </div>
                {(numberOfPages !== null && numberOfPages > 1) && 
                    <>
                        <h2>Pages</h2>
                        <div className="grid grid-cols-5 my-2 sm:grid-cols-7 md:grid-cols-12 lg:grid-cols-18 w-5/6 m-auto justify-center gap-2">
                            {[...Array(numberOfPages)].map((val, index)=>(
                                <>
                                        
                                    <motion.div onClick={()=>changePageNumber(index+1)} whileHover={{scale:1.03}} key={"page_"+index} className={(currentPage === (index+1) ? 'bg-charcoal text-white ': 'bg-white ' ) + " cursor-pointer text-center p-2 px-4 rounded-lg shadow-xl"}>
                                        {index+1}
                                    </motion.div>
                                    
                                </> 
                            ))}
                        </div>
                    </>
                }
            </div>
        </>  
    )
}

export default MyDeckCards;