import React, { useMemo, useState } from 'react'
import Skeleton from 'react-loading-skeleton';
import { createEditor, Descendant } from 'slate'
import { Slate, Editable, withReact } from 'slate-react';
import NoteEditor from '../../components/notes/editor/NoteEditor';

const HelpArticleBody = ({article}) => {
  const editor = useMemo(() => withReact(createEditor()), []);
  const [loading, setLoading] = useState(false);
  /*
<Slate editor={editor} value={JSON.parse(article.body.en)}>
                  <Editable readOnly placeholder="" />
                </Slate>
  */
  return (
    <>
      <div>
        <div>
            {article === null && 
              <>
                  <Skeleton count={1} height={50} />
                  <Skeleton count={5} height={100} />
              </>
            }
            {article !== null &&
              <>
                <NoteEditor mode={"study"} note={null} noteContent={{'content':article.body.en}} loadingNote={loading} />
              </>
            }
        </div>
      </div>
    </>
  )
}

export default HelpArticleBody;