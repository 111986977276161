import React, {useState, useEffect} from 'react';
import FillInGame from '../../../components/tables/games/fill-in/FillInGame';
import TableFlashcardsGame from '../../../components/tables/games/flashcards/TableFlashcardsGame';
import {useParams} from "react-router-dom";
import {Link} from 'react-router-dom';
import Icon from '../../../components/general/Icon';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/pro-solid-svg-icons';
import LoadingGame from '../../../components/vocabulary/games/common-assets/LoadingGame';
import { faQuestion } from '@fortawesome/pro-solid-svg-icons';
import useTables from '../../../hooks/useTables';
import { useAuth } from "../../../contexts/AuthContext";
import useQueryStrings from '../../../hooks/useQueryStrings';
import useStatistics from '../../../hooks/useStatistics';
import ScoreScreen from '../../../components/vocabulary/games/common-assets/ScoreScreen';
import TableFlashcardsModal from '../../../components/tables/games/modals/TableFlashcardsModal';
import TableFillInModal from '../../../components/tables/games/modals/TableFillInModal';
import {useUserStatisticsContext} from '../../../contexts/UserStatisticsContext';
import Button from '../../../components/general/Button';

const TableGame = ({game, type=null, alreadyFetchedData, isInCourse=false, backLink=null, chosenHowManyItems=null, chosenTable, chosenTableColumns, chosenHighlightColumnId, chosenTableRows, designMode, hideInstructions, chosenPlayAgain, chosenBackToGamesFunction, avoidParamsContentId=false, avoidParamsTargetLanguage=false, manualParamNumberOfItems=null, nextLessonId=null}) => {
    let id = null; 
    let lang = null;
    let params = useParams();
    let courseId = null;
    let lessonId = null;
    if (!avoidParamsContentId && !avoidParamsTargetLanguage){
        params = useParams();
        id = params.id;
        lang = params.lang;
        if (alreadyFetchedData !== true){
            id = parseInt(id);
        }
    } else {
        id = avoidParamsContentId;
        lang = avoidParamsTargetLanguage;
        let params = useParams();
        courseId = params.courseId;
        lessonId = params.lessonId;
    }
    
    const {currentUser} = useAuth();
    const {collectTableFlashcardsStatistics}  = useStatistics();
    const {activateDailyStatsNeedRefresh, activateDailyStreakNeedsRefresh} = useUserStatisticsContext();
    const [table, setTable] = useState(chosenTable !== undefined ? chosenTable : null);
    const [tableRows, setTableRows] = useState(chosenTableRows !== undefined ? chosenTableRows : null);
    const [highlightColumnId, setHighlightColumnId] = useState(chosenHighlightColumnId !== undefined ? chosenHighlightColumnId : null);
    const [tableColumns, setTableColumns] = useState(chosenTableColumns !== undefined ? chosenTableColumns : null);
    const [tableLoading, setTableLoading] = useState(true);
    const [showLoadingScreen, setShowLoadingScreen] = useState(true);
    const [backToPlayPageLink, setBackToPlayPageLink] = useState(backLink !== null ? backLink : "/my-tables/"+id+"/play");
    const [gameScreen, setGameScreen] = useState("game");
    const [score, setScore] = useState(0);
    const [nbOfAnswers, setNbOfAnswers] = useState(0);
    const [itemsLeft, setItemsLeft] = useState(null);

    let {getTableFromCurrentUser, 
        getPublicTable,
        getTableColumnsFromCurrentUser, 
        getTableColumnsFromPublicTable,
        getTableRowsFromCurrentUser, 
        getXRandomTableRowsFromCurrentUser, 
        getXRandomTableRowsFromPublicTable, 
        getTableRowsFromPublicTable} =  useTables();

    const [numberOfItemsSetting, setNumberOfItemsSetting] = useState([
            {'label': '5', 'value': 5}, 
            {'label': '10', 'value': 10}, 
            {'label': '25', 'value': 25}, 
            {'label': '50', 'value': 50},
            {'label': '75', 'value': 75}, 
            {'label': '100', 'value': 100}, 
            {'label': '150', 'value': 150}, 
            {'label': '200', 'value': 200}]);

    const [howManyItems, setHowManyItems] = useState(chosenHowManyItems !== null ? chosenHowManyItems : null);
    
    const {getQueryStrings, 
            addAndRemoveQueryStrings} = useQueryStrings();

    useEffect(()=>{
        const fetchData = async (highlight) => {
            let r = false;
            if (type === "course"){
                // get public table
                r = await getPublicTable({tableId:id});
            } else {
                // get user table
                r = await getTableFromCurrentUser({tableId:id});
            }
            if (r !== false){
                setTable(r);
                console.log("table: ", r);
                if (highlight === null){
                    setHighlightColumnId(r.column_order[0]);
                } else {
                    console.log(highlight, r);
                    if (!r.column_order.includes(highlight)){
                        setHighlightColumnId(r.column_order[0]);
                    } else {
                        setHighlightColumnId(highlight);
                    }
                }
            }
        }

        const fetchFirstData = async () => {
            console.log("Already fetched data: ", alreadyFetchedData);
            if (alreadyFetchedData !== true && numberOfItemsSetting !== null && id !== null){
                let r = await getQueryStrings();
                let highlight = null;
                if ('items' in r){
                    numberOfItemsSetting.forEach((option)=>{
                        if (option.value === parseInt(r['items'])){
                            try {
                                if (chosenHowManyItems === null){
                                    setHowManyItems(parseInt(r['items']));
                                }
                                //only if backLink is not set, otherwise don't override
                                if (backLink === null){
                                    setBackToPlayPageLink("/my-tables/"+id+"/play?items="+r['items']);
                                }
                            } catch {
                                if (chosenHowManyItems === null){
                                    setHowManyItems(parseInt(numberOfItemsSetting[0].value));
                                }
                                if (backLink === null){
                                    setBackToPlayPageLink("/my-tables/"+id+"/play?items="+numberOfItemsSetting[0].value);
                                }
                            }
                        }
                    })
                } else {
                    if (chosenHowManyItems === null){
                        setHowManyItems(parseInt(numberOfItemsSetting[0].value));
                    }
                    if (backLink === null){
                        setBackToPlayPageLink("/my-tables/"+id+"/play?items="+numberOfItemsSetting[0].value);
                    }
                }
                if ('highlight' in r){
                    try {
                        highlight = parseInt(r['highlight']);
                    } catch {
                        highlight = null;
                    }
                }
                fetchData(highlight);
            }
        }

        fetchFirstData();

    },[numberOfItemsSetting, id, alreadyFetchedData, type]);

    useEffect(()=>{
        const fetchColumnsData = async () => {
            let r = null; 
            if (type === "course"){
                r = await getTableColumnsFromPublicTable({table: table});
            }
            else {
                r = await getTableColumnsFromCurrentUser(table);
            }
            if (r === null){return null;}
            setTableColumns(r);
        }
        const fetchRowsData = async () => {
            let r2 = null;
            if (type === "course"){
                if ('row_order' in table){
                    r2 = await getXRandomTableRowsFromPublicTable({table: table, howManyItems: howManyItems});
                } else {
                    r2 = await getTableRowsFromPublicTable({table:table});
                }
            }
            else {
                if ('row_order' in table){
                    r2 = await getXRandomTableRowsFromCurrentUser(table, howManyItems);
                } else {
                    r2 = await getTableRowsFromCurrentUser(table);
                }
            }
            console.log("Rows: ", r2);
            setTableRows(r2);
        }

        console.log("Table: ", table, "How many items: ", howManyItems, "Already fetched data: ", alreadyFetchedData)
        if (table !== null && alreadyFetchedData !== true){
            fetchColumnsData();
            if (howManyItems !== null){
                fetchRowsData();
            }
        }
    },[table, howManyItems, alreadyFetchedData, type]);

    useEffect(()=>{
        if (table !== null && tableRows !== null && tableColumns !== null){
            setTableLoading(false);
        }
        if (tableRows !== null){
            setItemsLeft(tableRows.length);
        }
    },[table, tableRows, tableColumns]);

    useEffect(()=>{
        if (chosenTableRows !== undefined && chosenTableRows !== null){
            setTableRows(chosenTableRows);
        }
    },[chosenTableRows]);

    const refreshData = async () => {
        setTableLoading(true);
        setShowLoadingScreen(true);
        setScore(0);
        setItemsLeft(0);
        setNbOfAnswers(0);
        setTable(null);
        setTableRows(null);
        setTableColumns(null);
        let r = await getTable(id);
        if (r !== false){
            setTable(r);
        }
    }

    const playAgainClick = async () => {
        if (!alreadyFetchedData){
            await refreshData();
        } else {
            setTableLoading(true);
            setShowLoadingScreen(true);
            setScore(0);
            setItemsLeft(0);
            setNbOfAnswers(0);
            chosenPlayAgain();
        }
        setGameScreen("game");
    }

    const [showHelpModal, setShowHelpModal] = useState(false);

    const helpClick = () => {
        setShowHelpModal(true);
    }
    
    const closeHelpModal = () => {
        setShowHelpModal(false);
    }

/*     const collectStatistics = async ({correct, row}) => {
        if (currentUser === null){return}
        let tableColObject = {};
        tableColumns.forEach((col)=> {
            tableColObject[col.id] = {
                'name': col.name,
                'doc_id': col.doc_id
            }
        });
        let obj = {
            'row_doc_id': row.doc_id,
            'row_id': row.id,
            'table_id': row.table_id,
            'row_values': row.values, 
            'table_doc_id': table.doc_id,
            'table_name': table.name,
            'table_columns': tableColObject,
            'table_column_order': table.column_order,
            'target_ISO_639-1': table['target_ISO_639-1'],
            'source_ISO_639-1': table['source_ISO_639-1'],
            'uid': table['uid'], 
            'how_many_items_in_game': howManyItems,
            'highlight_column_id': highlightColumnId, 
            'correct': correct, 
            'game_played_from': alreadyFetchedData === true ? 'note' : 'standalone' 

        };
        //console.log("Stat: ", obj);
        await addTableFlashcardsStatistic(obj);
    } */

    const collectStatisticsHelper = async ({correct, row}) => {
        await collectTableFlashcardsStatistics({correct, row, tableColumns, table, howManyItems, highlightColumnId, gamePlayedFrom: alreadyFetchedData === true ? 'note' : 'standalone'});
        activateDailyStatsNeedRefresh();
        activateDailyStreakNeedsRefresh();
    }

    const nextCourseLessonClick = async () => {
        if (nextLessonId !== null){
            navigate(`/course/${courseId}/lesson/${nextLessonId}`);
            console.log("Navigating to next lesson: ", nextLessonId)
        } else {
            navigate(`/course/${courseId}`);
        }
    }

  return (
    <>
        <div className={designMode === "custom" ? "p-8 sm:pb-8 h-160 relative pb-24 overflow-y-auto" : "p-8 sm:pb-8  relative pb-24 h-screen overflow-y-auto"}>
                {hideInstructions !== true &&
                    <div className="absolute right-0 top-0 flex flex-row justify-center gap-6 p-6">  
                        <div className="text-lg" onClick={helpClick}>
                            <div data-tip="How to play" data-for="flashcard-game-tooltip">
                                <Icon color="charcoal" icon={<FontAwesomeIcon icon={faQuestion} />} />
                            </div>
                        </div>  
                        <div className="text-lg">
                            <Link to={backToPlayPageLink !== null ? backToPlayPageLink : "/my-tables/"+id+"/play"}>
                                <div data-tip="Go back to play page" data-for="flashcard-game-tooltip">
                                    <Icon color="charcoal" icon={<FontAwesomeIcon icon={faX} />} />
                                </div>
                            </Link>
                        </div>
                    </div>
                }
                {(!tableLoading && !showLoadingScreen && tableRows !== null && tableRows.length > 0 && table !== null) &&
                    <>
                        {game === "fill-in" &&
                            <>
                                <FillInGame table={table} tableRows={tableRows} tableColumns={tableColumns} id={id} />
                                {showHelpModal &&
                                    <>
                                        <TableFillInModal closeFunction={closeHelpModal} />
                                    </>
                                }
                            </>
                        }
                        {game === "flashcards" &&
                            <>
                                <TableFlashcardsGame designMode={designMode} collectStatistics={collectStatisticsHelper} highlightColumnId={highlightColumnId} score={score} gameScreen={gameScreen} setGameScreen={setGameScreen} itemsLeft={itemsLeft} nbOfAnswers={nbOfAnswers} setItemsLeft={setItemsLeft} setNbOfAnswers={setNbOfAnswers} setScore={setScore} backToPlayPageLink={backToPlayPageLink} table={table} tableRows={tableRows} tableColumns={tableColumns} id={id} />
                                {showHelpModal &&
                                    <>
                                        <TableFlashcardsModal closeFunction={closeHelpModal} />
                                    </>
                                }
                            </>
                        }
                    </>
                }
                {tableRows !== null && tableRows.length == 0 &&
                    <>
                        <div className="flex flex-col justify-center place-items-center gap-3 h-full w-full">
                            <div className="text-center text-xl ">
                                We found no valid rows in your table.
                            </div>
                            <div>
                                <Link className="no-underline" to={backToPlayPageLink !== null ? backToPlayPageLink : "/my-tables/"+id+"/play"}>
                                    <Button text="Go back" color="charcoal" />
                                </Link>
                            </div>
                        </div>
                    </>
                }
                {(!tableLoading && !showLoadingScreen && gameScreen === "endscreen") &&
                    <>
                        <div className="flex flex-col h-full justify-center place-items-center">
                            <ScoreScreen nextCourseLessonClick={nextCourseLessonClick} nextLessonId={nextLessonId} courseId={courseId} type={type} chosenBackToGamesFunction={chosenBackToGamesFunction} designMode={designMode} score={score} playAgainClick={playAgainClick} nbOfAnswers={nbOfAnswers} backToPlayPageLink={backToPlayPageLink} />
                        </div>
                    </>
                }
                <LoadingGame headline="Loading table exercises..." subtext={"Tim is currently making some table exercises for you."} showLoading={showLoadingScreen} setShowLoading={setShowLoadingScreen} loading={tableLoading} />
            </div>
    </>
  )
}

export default TableGame