import React, {useState} from 'react';
import Button from '../../general/Button';
import Modal from '../../general/Modal';
import Select from 'react-select';
import {useLanguagesContext} from '../../../contexts/LanguagesContext';
import useTables from '../../../hooks/useTables';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLoader} from '@fortawesome/pro-solid-svg-icons';

const ModalContent = ({newColumnSaving, closeFunction, newColumnName, saveNewColumn, setNewColumnName}) => {
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const saveNewColumnClick = () => {
        if (newColumnName === ""){
            setError(true);
            setErrorMessage("Please choose a name for your new column.");
            return;
        }
        saveNewColumn();
    }

    const columnNameKeyDown = (e) => {
        if (e.keyCode === 13) {
            saveNewColumn();
        } else {
            if (error === true){
                setError(false);
                setErrorMessage("");
            }
        }
    }

    const columnNameChange = (e) => {
        setNewColumnName(e.target.value);
    }

    return(
        <>
        <div className={(newColumnSaving ? 'opacity-30 relative' : '')}>
            {newColumnSaving && 
                <>
                    <div className="text-center z-10 text-4xl absolute left-1/2 top-1/2 text-black">
                        <FontAwesomeIcon icon={faLoader} className="fa-spin" />
                    </div>
                </>
            }
            <h1>Add a new column</h1>
                <div className="flex flex-col gap-4">
                    <div>
                        <div className="font-bold text-gray-600">
                            Name of the column
                        </div>
                        <div className="text-center">
                            <input autoFocus={true} value={newColumnName} onKeyDown={columnNameKeyDown} onChange={columnNameChange} type="text" className={(error === true ? 'border-red-500 ' : '' ) + "bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white"} />
                        </div>
                        {error === true &&
                            <>
                                <div className="italic text-red-400">
                                    {errorMessage}
                                </div>
                            </>
                        }
                    </div>
                    <div className="flex flex-row justify-center gap-3">
                        <div>
                            <Button onClick={closeFunction} color="charcoal" text="Cancel" />
                        </div>
                        <div>
                            <Button onClick={saveNewColumnClick} color="green" text="Create column" />
                        </div>
                    </div>
                </div>
                
            </div>
        </>
    )
}

const AddTableColumnModal = ({closeFunction, newColumnSaving, newColumnName, saveNewColumn, setNewColumnName}) => {

    return (
        <>
            <Modal closeFunction={closeFunction} content={<ModalContent setNewColumnName={setNewColumnName} closeFunction={closeFunction} newColumnName={newColumnName} newColumnSaving={newColumnSaving} saveNewColumn={saveNewColumn} />} size="3xl" />
        </>
    )
}

export default AddTableColumnModal;