import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import YalangoThumbsUp from '../../assets/svg/yalango/yalango_thumbs_up_without_circle.svg';
import YalangoPremium from '../../assets/svg/yalango/YalangoPremium.svg';
import { useAuth } from '../../contexts/AuthContext';

const PremiumCard = () => {
    let {isPremium} = useAuth();
    return (
        <div data-for="tooltip-dashboard-bottom" data-tip="Your current plan" className=" cursor-pointer text-xs  muted-text ">
            <Link className="no-underline" to="/premium">
                {isPremium ?
                    <>
                        <div className="flex flex-row place-items-center font-bold muted-text gap-2">
                            <div>
                                <img src={YalangoPremium} alt="Yalango Premium" className="w-5" />
                            </div>
                            <div className="border-b-2 border-charcoal border-dotted">
                                Premium
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="flex flex-row place-items-center font-bold muted-text gap-2">
                            <div>
                                <img src={YalangoThumbsUp} alt="Yalango Basic" className="w-5" />
                            </div>
                            <div className="border-b-2 border-charcoal border-dotted">
                                Basic
                            </div>
                        </div>
                    </>
                }
            </Link>
        </div>
    );
};

export default PremiumCard;