import React from 'react';
import YalangoThumbsUp from '../../assets/svg/yalango/yalango_thumbs_up_without_circle.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/pro-solid-svg-icons';

const GeneralInfoMessage = ({heading, text, text2, showMascot, gradient}) => {
    let gradientClass = "";
    if (gradient !== undefined){
        if (gradient === "burnt-sienna"){
            gradientClass = "bg-gradient-to-r from-burnt-sienna to-orange-yellow-crayola";
        }
        if (gradient === "charcoal"){
            gradientClass = "bg-gradient-to-r from-charcoal to-lighter-charcoal border-dashed border border-charcoal";
        }
    } else {
        gradientClass = "bg-gradient-to-r from-burnt-sienna to-orange-yellow-crayola";
    }

    return (
        <>
            <div className={gradientClass + " relative lg:w-1/2 m-auto my-4 flex flex-row place-items-center p-8 justify-between gap-6  rounded-lg shadow-xl"}>
                <div className="absolute bg-white rounded-full shadow-xl px-3 py-1 -top-2 -left-2">
                    <FontAwesomeIcon icon={faInfo} />
                </div>
                {showMascot &&
                    <div className="text-left bg-white rounded-full p-4 px-6">
                        <img src={YalangoThumbsUp} alt="Yalango mascot Tim giving a thumbs up" className="w-32 my-0" />
                    </div>
                }
                <div className="text-left text-white flex flex-col gap-2">
                    <div className="text-xl font-bold ">{heading}</div>
                    <div>{text}</div>
                {text2 !== undefined &&
                    <div>{text2}</div>
                }
                </div>
            </div>
        </>
    )
}

export default GeneralInfoMessage;