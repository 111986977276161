import React from "react";
import { motion , AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faNote, faUpload, faPlus, faFileImport,  faFontCase} from '@fortawesome/pro-solid-svg-icons';
import {Link} from 'react-router-dom';
import Icon from '../../components/general/Icon';

const CreateOrImportContentButton = (props) => {

    const {menuItems, setShowCreateItemMenu, showCreateItemMenu, folderDocId, openCreateSubItemModal, openCreateSubFolderModal} = props; //folderDocId is not undefined -> we are inside a folder


    const createItemButtonClick =  () => {
        setShowCreateItemMenu(!showCreateItemMenu);
    }

    const runCreateItemButtonFunction = async (e, child) => {
        console.log(child)
        setShowCreateItemMenu(false);
        if (child.text.toLowerCase() === "item"){
            //openCreateSubItemModal if we are inside a folder
            if (folderDocId !== undefined){
                openCreateSubItemModal(e, {doc_id: folderDocId});
            } else {
                await child.function(e, child);
            }
        } 
        else if (child.text.toLowerCase() === "folder"){
            //openCreateSubFolderModal if we are inside a folder
            if (folderDocId !== undefined){
                openCreateSubFolderModal(e, {doc_id: folderDocId});
            } else {
                await child.function(e, child);
            }
        } 
        
        else {
            await child.function(e, child);
        }

    }


    return (
        <>
            <div className=" flex flex-row justify-start gap-2  w-full">
                <div className="flex flex-row justify-start w-full ">
                    <div className="relative w-full sm:w-fit ">
                        <div onClick={createItemButtonClick} className="flex w-full sm:w-fit text-lg  shadow-xl rounded-xl flex-row my-2 justify-center gap-2 cursor-pointer p-8 upper bg-gradient-to-r from-charcoal to-persian-green border border-solid border-gray-300 text-white  font-bold text-center ">
                            <div className="">
                                <FontAwesomeIcon icon={faPlus} />
                            </div>
                            <div>
                                Create or import content
                            </div>
                        </div>
                        <AnimatePresence>
                        {showCreateItemMenu &&
                            <>
                                <motion.div 
                                    variants={{
                                        initial: {
                                            height: 0,
                                        },
                                        animate: {
                                            height: 'auto',
                                            transition: {
                                                when: 'beforeChildren',
                                                duration: 0.10
                                            },
                                        },
                                        exit: {
                                            height: 0,
                                            transition: {
                                                when: 'afterChildren',
                                            },
                                        },
                                    }}
                                    initial="initial"
                                    animate="animate"
                                    exit="exit"
                                className="absolute overflow-visible text-sm  z-20 rounded-lg shadow-xl w-full bg-white left-0 top-13"
                                >
                                    <motion.div   variants={{
                                        initial: {
                                            opacity: 0,
                                        },
                                        animate: {
                                            opacity: 1,
                                            duration: 0.10
                                        },
                                        exit: {
                                            opacity: 0,
                                        }
                                    }}>
                                        <div className="p-2 italic flex flex-row border-solid border-gray-300 border-b justify-center muted-text">
                                            Create blank items
                                        </div>
                                    </motion.div>
                                    <div>
                                        
                                            {menuItems.map((child, child_index) => (
                                                <>
                                                    {child.save_to_db === false &&
                                                        <>
                                                            {child.type === "function" &&
                                                                <>
                                                                <motion.div 
                                                                    key={"button_"+child_index}
                                                                    onClick={(e) => runCreateItemButtonFunction(e, child)}
                                                                    variants={{
                                                                            initial: {
                                                                                opacity: 0,
                                                                            },
                                                                            animate: {
                                                                                opacity: 1,
                                                                                duration: 0.10
                                                                            },
                                                                            exit: {
                                                                                opacity: 0,
                                                                            }
                                                                        }}
                                                                    className="flex text-gray-600 text-base flex-col justify-start place-items-start">
                                                                            <div className="flex cursor-pointer  flex-row rounded-lg  gap-3 hover:bg-gray-100 w-full p-3">
                                                                                <div className={child.color}>
                                                                                    <Icon icon={<FontAwesomeIcon icon={child.icon} />} />
                                                                                </div>
                                                                                <div>
                                                                                    New {child.text.toLowerCase()}
                                                                                </div>
                                                                            </div>
                                                                </motion.div>
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </>
                                            ))}
                                    
                                    <motion.div 
                                        key={"button_"+"import"}
                                        
                                        variants={{
                                                initial: {
                                                    opacity: 0,
                                                },
                                                animate: {
                                                    opacity: 1,
                                                    duration: 0.10
                                                },
                                                exit: {
                                                    opacity: 0,
                                                }
                                            }}
                                        className="flex text-gray-600 flex-col justify-start place-items-start">
                                            <Link to={"/import"} className="no-underline w-full">
                                                <div className="p-2 italic flex flex-row border-solid border-gray-300 border-b border-t justify-center muted-text">Import & upload</div>
                                            </Link>
                                                <Link to={"/import/decks/csv"} className="no-underline w-full">
                                                <div className="flex cursor-pointer text-base flex-row rounded-lg  gap-3 hover:bg-gray-100 w-full p-3">
                                                    <div className="text-charcoal">
                                                        <Icon icon={<FontAwesomeIcon icon={faUpload} />} />
                                                    </div>
                                                    <div>
                                                        Upload CSV file
                                                    </div>
                                                </div>
                                                </Link>
                                    </motion.div>
                                    <motion.div 
                                        key={"button_"+"import"}
                                        
                                        variants={{
                                                initial: {
                                                    opacity: 0,
                                                },
                                                animate: {
                                                    opacity: 1,
                                                    duration: 0.10
                                                },
                                                exit: {
                                                    opacity: 0,
                                                }
                                            }}
                                        className="flex text-gray-600 flex-col justify-start place-items-start">
                                            <Link to={"/import/decks/quizlet"} className="no-underline w-full">
                                                <div className="flex cursor-pointer text-base flex-row rounded-lg  gap-3 hover:bg-gray-100 w-full p-3">
                                                    <div className="text-charcoal">
                                                        <Icon icon={<FontAwesomeIcon icon={faFileImport} />} />
                                                    </div>
                                                    <div>
                                                        Import Quizlet deck
                                                    </div>
                                                </div>
                                                </Link>
                                    </motion.div>
                                </div>
                                </motion.div>
                                
                            </>
                        }
                        
                        </AnimatePresence>
                        <div onClick={()=>setShowCreateItemMenu(false)} className={`${showCreateItemMenu ? 'opacity-50  w-screen h-screen' : 'opacity-0  w-0 h-0'} fixed bg-gray-800 transition-opacity ease-in-out duration-300 left-0 top-0 z-10`}>
                        </div>
                    </div>
                </div>
                
            </div>
        </>
    )

}

export default CreateOrImportContentButton;