import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-duotone-svg-icons';
import { faCardsBlank } from '@fortawesome/pro-solid-svg-icons';
import { faRobot } from '@fortawesome/pro-solid-svg-icons';
import {Link} from 'react-router-dom';
import { faMessage } from '@fortawesome/pro-solid-svg-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { faReply } from '@fortawesome/pro-solid-svg-icons';

const CommentReplyOptionsRow = (props) => {
    const {showRepliesClick, numberOfReplies, replyClick, giveCorrectionsClick} = props;
    return (
        <>
            <div onClick={showRepliesClick} className="cursor-pointer p-2 border border-text-gray-600  flex flex-row gap-2">
                <div>
                    <FontAwesomeIcon icon={faMessage} />
                </div>
                <div>
                    {numberOfReplies}
                </div>
            </div>
            <div onClick={replyClick} className="cursor-pointer p-2 border border-text-gray-600  flex flex-row gap-2">
                <div>
                    <FontAwesomeIcon icon={faReply} />
                </div>
                <div>
                    Reply
                </div>
            </div>
            <div onClick={giveCorrectionsClick} className="cursor-pointer p-2 border border-text-gray-600  flex flex-row gap-2">
                <div  className="text-persian-green group-hover:text-white">
                    <FontAwesomeIcon icon={faCheck} />
                </div>
                <div>
                    Correct
                </div>
            </div>
        </>
    )
}

export default CommentReplyOptionsRow;