import React from 'react';
import Skeleton from 'react-loading-skeleton';

const HelpArticleFooter = ({article}) => {
    return (
        <>
            <div className="flex flex-row gap-4 justify-start">
                {article === null ? 
                    <>
                        <Skeleton count={1} height={20} />
                    </>
                :
                    <>
                        <div className="text-xs text-gray-600">
                            Last edited by {article.author} on {article.last_updated_timestamp.toDate().toDateString()}
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default HelpArticleFooter;