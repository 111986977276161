import React, {useState, useEffect} from 'react';
import Button from '../../components/general/Button'
import {Link} from 'react-router-dom';
import { useParams } from 'react-router-dom';
import useVocabulary from '../../hooks/useVocabulary';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faNote, faChartSimple, faCalendarDays, faCardsBlank, faPercentage, faAlarmClock, faBrain} from '@fortawesome/pro-duotone-svg-icons';
import {faBlender} from '@fortawesome/pro-duotone-svg-icons';
import {faBraille} from '@fortawesome/pro-duotone-svg-icons';
import {faBallotCheck} from '@fortawesome/pro-duotone-svg-icons';
import {faMagnifyingGlass} from '@fortawesome/pro-duotone-svg-icons';
import {faBookOpenReader} from '@fortawesome/pro-duotone-svg-icons';
import {faPenField} from '@fortawesome/pro-duotone-svg-icons';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import CountryFlag from '../../components/languages/CountryFlag';
import {useLanguagesContext} from '../../contexts/LanguagesContext';

const VocabularyItemPage = (params) => {
    const { id, targetLang } = useParams();
    const today = new Date();
    const [typeSupported, setTypeSupported] = useState(false);
    const {fetchItemFromVocabularyInLanguage, 
        fetchHistoricalStatisticsItem, 
        fetchHistoricalStatisticsItemsFromList} = useVocabulary();
    const {allLanguages} = useLanguagesContext();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [displayHistoricalGraph, setDisplayHistoricalGraph] = useState(false);
    const [error, setError] = useState(false);
    const [lineChartData, setLineChartData] = useState(null);
    const [lineChartOptions, setLineChartOptions] = useState(null);

    useEffect(()=>{
        const fetchData = async () => {
            if (id !== null && id !== undefined && targetLang !== null && targetLang !== undefined) {
                let r = await fetchItemFromVocabularyInLanguage({id, targetLang});
                setLoading(false);
                if (data === false){
                    setError(true);
                } else {
                    console.log("Data: ", r)
                    console.log("type: ", r.type)
                    setData(r);
                    if (r.type.trim() === "table-row"){
                        console.log("not supported")
                        setTypeSupported(false);
                    } else {
                        setTypeSupported(true);
                    }
                    /* if (r.hasOwnProperty("spaced_repetition_all_historic_correct_timestamps") && r.spaced_repetition_all_historic_correct_timestamps !== null && r.spaced_repetition_all_historic_correct_timestamps.length > 0){
                        let finalData = {};
                        for (let i = 0; i < r.spaced_repetition_all_historic_correct_timestamps.length; i++) {
                            if (i === 0){
                                finalData['labels'] = r.spaced_repetition_all_historic_correct_timestamps.map((day) => day.toDate().toDateString());
                            }
                            finalData['datasets'] = [{
                                label: "Correct exercises",
                                data: r.spaced_repetition_all_historic_correct_timestamps.map((day) => 1),
                            }];
                        }
                        setLineChartData(finalData);
                        const o = {
                            responsive: true,
                            bezierCurve: true,
                            maintainAspectRatio: false, 
                            plugins: {
                                legend: {
                                    position: 'top',
                                },
                                title: {
                                    display: false,
                                    font: {
                                        size: 18
                                    },
                                    text: 'Correct exercises this month',
                                },
                            }, 
                            elements: {
                                line: {
                                    tension: 0.4
                                }
                            }, 
                            scales: {
                                x: {
                                  grid: {
                                    display: false
                                  }
                                },
                                y: {
                                  min: 0,
                                  ticks: {
                                        stepSize: 1,
                                    },
                                  grid: {
                                    display: false
                                  }
                                }
                              }
                        };
                        setLineChartOptions(o);
                    } */
                    let h_f = []; // flashcards
                    let h_i = []; //input
                    let h_q = []; //quiz
                    let finalData = {
                        'datasets': []
                    };
                    if (r.hasOwnProperty("historic_flashcards_data_doc_ids") && r.historic_flashcards_data_doc_ids !== null && r.historic_flashcards_data_doc_ids !== undefined && r.historic_flashcards_data_doc_ids.length > 0){
                        h_f = await fetchHistoricalStatisticsItemsFromList({docIds:r.historic_flashcards_data_doc_ids, game: "flashcards", language: targetLang});
                    }
                    if (r.hasOwnProperty("historic_input_data_doc_ids") && r.historic_input_data_doc_ids !== null && r.historic_input_data_doc_ids !== undefined && r.historic_input_data_doc_ids.length > 0){
                        h_i = await fetchHistoricalStatisticsItemsFromList({docIds:r.historic_input_data_doc_ids, game: "input", language: targetLang});
                    }
                    if (r.hasOwnProperty("historic_quiz_data_doc_ids") && r.historic_quiz_data_doc_ids !== null && r.historic_quiz_data_doc_ids !== undefined && r.historic_quiz_data_doc_ids.length > 0){
                        h_q = await fetchHistoricalStatisticsItemsFromList({docIds:r.historic_quiz_data_doc_ids, game: "quiz", language: targetLang});
                    }
                    console.log("input: ", h_i)
                    //combine and sort all values according to date
                    let combinedList = [...h_f, ...h_i, ...h_q];
                   
                    combinedList = combinedList.filter(Boolean); //filter out false values 
                    combinedList = combinedList.filter((a)=>{if (a.hasOwnProperty("timestamp") && a.timestamp !== undefined && a.timestamp !== null){return a}})
                    combinedList = combinedList.sort((a,b)=>{
                        console.log(a.timestamp, b.timestamp)
                        if (a.hasOwnProperty("timestamp") && a.timestamp !== undefined && a.timestamp !== null && b.hasOwnProperty("timestamp") && b.timestamp !== undefined && b.timestamp !== null){
                            return a?.timestamp.toDate().getTime()-b?.timestamp.toDate().getTime();
                        }
                    });

                    //labels
                    finalData['labels'] = combinedList.map((item) => item?.timestamp.toDate().toDateString());

                    if (combinedList !== null && combinedList !== undefined){ 
                        finalData['datasets'] = [...finalData['datasets'], {
                            label: "Correct (1) & incorrect (-1)",
                            borderColor: "black",
                            backgroundColor: "black",
                            data: combinedList.map((item) => {
                                if (item.correct === true){
                                    return 1;
                                } else {
                                    return -1;
                                }
                            }),
                        }];
                    }
                    if (combinedList.length > 0){
                        setDisplayHistoricalGraph(true);
                    }
                    else {
                        setDisplayHistoricalGraph(false);
                    }
                    setLineChartData(finalData);
                    const o = {
                        responsive: true,
                        bezierCurve: false,
                        maintainAspectRatio: false, 
                        plugins: {
                            legend: {
                                position: 'top',
                            },
                            title: {
                                display: true,
                                font: {
                                    size: 18
                                },
                                text: 'Historical data',
                            },
                        }, 
                        elements: {
                            line: {
                                tension: 0.0
                            }
                        }, 
                        scales: {
                            x: {
                                grid: {
                                display: true
                                }
                            },
                            y: {
                                min: -2,
                                max: 2,
                                ticks: {
                                    stepSize: 1,
                                },
                                grid: {
                                display: true
                                }
                            }
                            }
                    };
                    setLineChartOptions(o);
                }
            }
        }
        if (id !== null && id !== undefined && targetLang !== null && targetLang !== undefined) {
            fetchData();
        }
    },[id, targetLang]);

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    );

    /*
    {(lineChartData !== null && lineChartOptions !== null) &&
                                            <>
                                                    <div className="min-h-80 h-96 p-2">
                                                        
                                                    </div>
                                            </>
                                        }
<Line 
                                                        options={lineChartOptions} 
                                                        data={lineChartData}
                                                        />
    */
                                        console.log(data, allLanguages)
    return (
        <> 
            <div className=" ">
                <div>
                    {loading && <p>Loading...</p>}
                    {error && <p>Error: something went wrong on this page.</p>}
                    {!loading && data !== null && data !== undefined && typeSupported && 
                        <>
                            <div>
                                
                                <div className="flex flex-col gap-8">
                                    <Link className="text-sm" to="/my-vocabulary">
                                        Back
                                    </Link>
                                    <div className="flex bg-primary p-4 rounded-xl shadow-xl text-white flex-row text-xl font-semibold gap-16 justify-center">
                                        {allLanguages !== null && data !== null &&
                                            <>
                                                <div className="flex flex-row gap-4">
                                                    <div className="rounded-lg overflow-hidden">
                                                        {allLanguages[0].hasOwnProperty(data['target_ISO_639-1']) &&
                                                            <CountryFlag countryCode={allLanguages[0][data['target_ISO_639-1']]['country_flag']} flagName={allLanguages[0][data['target_ISO_639-1']]['name']['en']} />
                                                        }
                                                    </div>
                                                    <div>
                                                        {data?.target}
                                                    </div>
                                                </div>
                                                <div className="flex flex-row gap-4">
                                                    <div className="rounded-lg overflow-hidden">
                                                        {allLanguages[0].hasOwnProperty(data['source_ISO_639-1']) &&
                                                            <CountryFlag countryCode={allLanguages[0][data['source_ISO_639-1']]['country_flag']} flagName={allLanguages[0][data['source_ISO_639-1']]['name']['en']} />
                                                        }
                                                    </div>
                                                    <div>
                                                        {data?.source}
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div>
                                            <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                                                <div className="flex h-full flex-col gap-2 justify-center place-items-center">
                                                    <div className="text-gray-700 font-bold text-xl">
                                                        Total
                                                    </div>
                                                    <div className="text-5xl sm:text-6xl  text-charcoal">
                                                        <FontAwesomeIcon className="" icon={faChartSimple} />
                                                    </div>
                                                    <div className="text-lg font-semibold">
                                                        {data?.total_statistics?.answers?.correct ? data?.total_statistics?.answers?.correct : 0}/{(data?.total_statistics?.answers?.correct ? data?.total_statistics?.answers?.correct : 0 ) + (data?.total_statistics?.answers?.wrong ? data?.total_statistics?.answers?.wrong : 0)}
                                                    </div>
                                                </div>
                                                <div className="flex h-full flex-col gap-2 justify-center place-items-center">
                                                    <div className="text-gray-700 font-bold text-xl">
                                                        Flashcards
                                                    </div>
                                                    <div className="text-5xl sm:text-6xl  text-sandy-brown">
                                                        <FontAwesomeIcon className="" icon={faNote} />
                                                    </div>
                                                    <div className="text-lg font-semibold">
                                                        {data?.flashcards_statistics?.answers?.correct ? data?.flashcards_statistics?.answers?.correct : 0}/{(data?.flashcards_statistics?.answers?.wrong ? data?.flashcards_statistics?.answers?.wrong : 0 ) + (data?.flashcards_statistics?.answers?.correct ? data?.flashcards_statistics?.answers?.correct : 0)}
                                                    </div>
                                                </div>
                                                <div className="flex h-full flex-col gap-2 justify-center place-items-center">
                                                    <div className="text-gray-700 font-bold text-xl">
                                                        Quiz
                                                    </div>
                                                    <div className="text-5xl sm:text-6xl text-persian-green">
                                                        <FontAwesomeIcon className="" icon={faBallotCheck} />
                                                    </div>
                                                    <div className="text-lg font-semibold">
                                                        {data?.quiz_statistics?.answers?.correct ? data?.quiz_statistics?.answers?.correct : 0}/{(data?.quiz_statistics?.answers?.correct ? data?.quiz_statistics?.answers?.correct : 0 ) + (data?.quiz_statistics?.answers?.wrong ? data?.quiz_statistics?.answers?.wrong : 0)}
                                                    </div>
                                                </div>
                                                <div className="flex h-full  flex-col gap-2 justify-center place-items-center">
                                                    <div className="text-gray-700 font-bold text-xl">
                                                        Input
                                                    </div>
                                                    <div className="text-5xl sm:text-6xl text-burnt-sienna">
                                                        <FontAwesomeIcon className="" icon={faPenField} />
                                                    </div>
                                                    <div className="text-lg font-semibold">
                                                        {data?.input_statistics?.answers?.correct ? data?.input_statistics?.answers?.correct : 0}/{(data?.input_statistics?.answers?.correct ? data?.input_statistics?.answers?.correct : 0) + (data?.input_statistics?.answers?.wrong ? data?.input_statistics?.answers?.wrong : 0)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    <div className="flex flex-col gap-16 p-4 ">
                                        <div className="flex md:flex-row flex-col gap-8">
                                            <div className="md:w-1/3 bg-primary  text-white  rounded-xl shadow-xl p-4 w-full flex flex-col gap-8">
                                                <div className="text-white text-lg font-semibold text-center">Overview</div>
                                                <div className="text-base text-white flex flex-col gap-6">
                                                    <div className="flex flex-row gap-4">
                                                        <div className="flex flex-row gap-6 place-items-center text-white font-bold">
                                                            <span className="text-white"><FontAwesomeIcon className="" icon={faCalendarDays} /></span> First created:
                                                        </div>
                                                        <div>   
                                                            {data?.created.toDate().toDateString()}
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-row gap-4">
                                                        <div className="flex flex-row gap-6  place-items-center font-bold">
                                                            <span className="text-white"><FontAwesomeIcon className="" icon={faCardsBlank} /></span> In decks:
                                                        </div>
                                                        <div>   
                                                            {data?.deck_ids.map((id, index)=>(
                                                                <>
                                                                    <span key={"deck_"+index}><Link to={"/my-decks/"+id+"?q="+data.target}>{id}</Link></span>
                                                                    {index !== data?.deck_ids.length -1 &&
                                                                        ", "
                                                                    }
                                                                </>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-row gap-4">
                                                        <div className="flex flex-row gap-6  place-items-center font-bold">
                                                            <span className=""><FontAwesomeIcon className="" icon={faPercentage} /></span> Correct reviews:
                                                        </div>
                                                        <div>   
                                                            {data.total_statistics.percentage.correct}%
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-row gap-4">
                                                        <div className="flex flex-row gap-6 place-items-center font-bold">
                                                            <span className=""><FontAwesomeIcon className="" icon={faAlarmClock} /></span> Next planned review:
                                                        </div>
                                                        <div>   
                                                            {(data?.spaced_repetition_next_review && data?.spaced_repetition_next_review !== null && data?.spaced_repetition_finished === false) ? 
                                                                <>
                                                                    <div className={data.spaced_repetition_next_review.toDate() > today ? "text-white" : "text-white"}>
                                                                        {data.spaced_repetition_next_review.toDate().toDateString()} 
                                                                    </div>
                                                                </>
                                                            : 
                                                            <>
                                                            {data?.spaced_repetition_finished === true ?
                                                                <>
                                                                    <div className="text-persian-green font-bold">
                                                                        Finished!
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                -
                                                                </>
                                                            }
                                                            </>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-row place-items-center gap-4">
                                                        <div className="flex flex-row  gap-6  place-items-center  font-bold">
                                                            <span className=""><FontAwesomeIcon className="" icon={faBrain} /></span> Spaced repetition level:
                                                        </div>
                                                        <div>   
                                                            {data?.spaced_repetition_current_index ? data?.spaced_repetition_current_index : 0} 
                                                        </div>
                                                        <div className="text-xs">
                                                            <Link to="/my-vocabulary/spaced-repetition">
                                                                Learn more
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                              
                                            </div>
                                            <div className="md:w-1/2 w-full flex flex-col gap-4">
                                                <div>
                                                {(lineChartData !== null && lineChartOptions !== null && displayHistoricalGraph) &&
                                                    <>
                                                        <div className="min-h-80 h-96 p-2">
                                                            <Line 
                                                            options={lineChartOptions} 
                                                            data={lineChartData}
                                                            />
                                                        </div>
                                                    </>
                                                }  
                                                {!loading && lineChartData !== null && !displayHistoricalGraph &&
                                                    <>  
                                                        <div className="text-center italic muted-text">
                                                            You will see a graph showing historical performance here after your first review.
                                                        </div>
                                                    </>
                                                }
                                                </div> 
                                            
                                            </div>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {!loading && !typeSupported &&
                        <>
                            <div className="flex flex-col gap-8">
                                <h1>Error</h1>
                                <div className="text-center">
                                    We don't support detailed statistics about table rows yet, but we're planning on supporting it soon. Please try again in the future.
                                </div>
                                <div className="flex flex-row justify-center">
                                    <Link to="/my-vocabulary" className="no-underline text-center">
                                        <Button color="green" text={"Go to my library"} />
                                    </Link>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
    )
}

export default VocabularyItemPage