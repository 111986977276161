import React from 'react';
import Button from '../../../../general/Button';
import Modal from '../../../../general/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/pro-duotone-svg-icons';
import { faThumbsDown } from '@fortawesome/pro-duotone-svg-icons';
import { faRotateRight } from '@fortawesome/pro-duotone-svg-icons';

const ModalContent = ({closeFunction}) => {
    return (
        <>
            <div className="overflow-auto h-72 sm:h-full p-4">
                <h1>How to use Browse Mode</h1>
                <p className="text-center my-2">The functionality of this mode is currently quite limited, but it lets you browse through a selection of your items and see the translations and custom fields of each item.</p>
                <div className="p-4 text-center">
                    <Button text="Continue playing" onClick={closeFunction} />
                </div>
            </div>
        </>   
    )
}

const BrowseHelpModal = ({closeFunction}) => {
  return (
    <>
        <Modal closeFunction={closeFunction} content={<ModalContent closeFunction={closeFunction} />} size="3xl"/>
    </>
  )
};

export default BrowseHelpModal;
