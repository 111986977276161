import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowDownWideShort, faBars} from '@fortawesome/pro-solid-svg-icons';
import {motion} from 'framer-motion';

const GeneralHamburgerMenu = ({ textSize = "text-sm", onChange, options, hideLabel = true, rightOrLeft = "right" }) => {
    const [showMenu, setShowMenu] = useState(false);

    const clickOption = (option) => {
        onChange(option);
        setShowMenu(false);
    }

    const backgroundClick = () => {
        setShowMenu(false);
    }
    return (
        <>
            <div className="">
                <div onClick={() => setShowMenu(!showMenu)} className="cursor-pointer">
                    <FontAwesomeIcon icon={faBars} />
                </div>
                <div className="relative">
                    {showMenu && (
                        <>
                            <div className={"absolute top-0 right-0 text-sm " + textSize + " z-20  bg-white p-3 shadow-xl rounded-lg text-gray-600"}>
                                {options !== null && options.map((option, i) => (
                                    <motion.div whileHover={{ scale: 1.02 }} onClick={() => clickOption(option)} key={"option_" + i} className={"whitespace-nowrap p-2 px-4 cursor-pointer flex flex-row gap-3"}>
                                        {option.icon !== undefined &&
                                            <div>
                                                <FontAwesomeIcon icon={option.icon} />
                                            </div>
                                        }
                                        <div>
                                            {option.label}
                                        </div>
                                    </motion.div>
                                ))}
                            </div>
                            <div onClick={backgroundClick} className="w-screen blur-sm filter bg-gray-800 opacity-50 h-screen left-0 top-0 fixed z-10"></div>
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

export default GeneralHamburgerMenu;