import React, { useEffect, useState } from 'react';
import {useLanguagesContext} from '../../contexts/LanguagesContext';
import useTexts from '../../hooks/useTexts';
import useVocabulary from '../../hooks/useVocabulary';
import useSearch from '../../hooks/useSearch';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useUserStatisticsContext } from "../../contexts/UserStatisticsContext";
import Skeleton from 'react-loading-skeleton';
import {Helmet} from 'react-helmet';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import {faLoader} from '@fortawesome/pro-solid-svg-icons';
import Select from 'react-select';
import { useAuth } from "../../contexts/AuthContext";
import TextCard from '../../components/texts/components/TextCard';
import MyLibraryMenu from '../../components/menus/MyLibraryMenu';
import ExploreMenu from '../../components/menus/ExploreMenu';
import {DebounceInput} from 'react-debounce-input';
import {faSliders} from '@fortawesome/pro-solid-svg-icons';
import {motion} from 'framer-motion';
import {faXmark} from '@fortawesome/pro-solid-svg-icons';
import SortByButton from '../../components/general/sorting/SortByButton';
import SearchTexts from '../../components/texts/components/SearchTexts';

const TextsDirectory = () => {

    const {globalSelectedTargetLanguage, allLanguages, activeUserLanguages, 
        globalSelectedSourceLanguage} = useLanguagesContext();
    const {activeVocabularyTargetWords, 
        knownVocabulary,
        vocabularyTargetWordsInTargetLanguage,
        refreshKnownVocabulary, 
        refreshActiveVocabularyTargetWords, 
        manuallySetVocabularyTargetWordsInTargetLanguage
    } = useUserStatisticsContext();

    const {searchPublicTexts} = useSearch();

    let { currentUser } = useAuth();
    const {fetchAllTextsInTargetLanguage, 
        fetchAllTexts} = useTexts();

    const [texts, setTexts] = useState([]);

    
    const [searchTerm, setSearchTerm] = useState("");
    const searchInputChange = (e) => {
        setSearchTerm(e.target.value);
    }
   
    const sortByOptions = [
        {label: 'Most relevant', value:'most_relevant'},
        {label: 'Most recently updated', value:'last_updated_timestamp_desc'},
        {label: 'Most recently created', value:'created_timestamp_desc'},     
        {label: 'Updated (ascending)', value:'last_updated_timestamp_asc'},
        {label: 'Created (ascending)', value:'created_timestamp_asc'},       
    ];
    const sortByOptionsDict = sortByOptions.reduce(function(result, item) {
        var key = item.value; 
        result[key] = item;
        return result;
      }, {});
    const [sortByValue, setSortByValue] = useState(sortByOptions[1].value);

    const [loading, setLoading] = useState(true);

    const [currentPage, setCurrentPage] = useState(null);
    const [numberOfResults, setNumberOfResults] = useState(null);
    const [numberOfResultsPerPage, setNumberOfResultsPerPage] = useState(20);
    const [numberOfPages, setNumberOfPages] = useState(null);
    const [textType, setTextType] = useState('all');
    const [queryStringsRead, setQueryStringsRead] = useState(false);

    const [sourceLangOptions, setSourceLangOptions] = useState(null);
    const [calculcatedPercentages, setCalculatedPercentages] = useState({});
    const [langOptionsDict, setLangOptionsDict] = useState(null);
    const [sourceLanguage, setSourceLanguage] = useState(globalSelectedSourceLanguage !== null ? globalSelectedSourceLanguage : "en");
    const [targetLanguage, setTargetLanguage] = useState(globalSelectedTargetLanguage !== null ? globalSelectedTargetLanguage : "en" );
    let navigate = useNavigate();
    let location = useLocation();
    let currentPath = location.pathname;
    let currentSearch = location.search;
    const {calculatePercentageOfVocabulary} = useVocabulary();

    const setParams = (value = "", name) => {
        const searchParams = new URLSearchParams(currentSearch);
        searchParams.set(`${name}`, value);
        return searchParams.toString();
    }

    const handleTargetLanguageChange = async (option) => {
        setTargetLanguage(option.value);
        const url = setParams(option.value, "target");
        navigate(currentPath+'?'+url);
    }

    useEffect(()=>{
        if (globalSelectedTargetLanguage !== null){
            setTargetLanguage(globalSelectedTargetLanguage);
        }
    },[globalSelectedTargetLanguage])

/*     useEffect(()=>{
        console.log(targetLanguage);
        const fetchData = async () => {
            let r = null;
            if (targetLanguage !== "all"){
                r = await fetchAllTextsInTargetLanguage(targetLanguage);
            } else {
                r = await fetchAllTexts();
            }
            setTexts(r);
            setLoading(false);
        }

        if (targetLanguage !== null){
            fetchData();
            ReactTooltip.rebuild();
        }
    },[targetLanguage]); */


    useEffect(()=>{
        const fetchData = async (target) => {
            await refreshActiveVocabularyTargetWords(target);
        }
        console.log("Active: ", activeVocabularyTargetWords);
        if (currentUser !== null && targetLanguage !== null  && (activeVocabularyTargetWords === null || !activeVocabularyTargetWords.hasOwnProperty(targetLanguage))){
            if (targetLanguage !== "all"){
                fetchData(targetLanguage); 
            } else  {
                // check if all languages are in activeVocabularyTargetWords
                for (const lang of activeUserLanguages){
                    if (!activeVocabularyTargetWords.hasOwnProperty(lang.target_language)){
                        fetchData("all");
                        break;
                    }
                }
            }
        }

    },[currentUser, activeVocabularyTargetWords, targetLanguage, activeUserLanguages]);

    useEffect(()=>{
        const fetchData = async (target) => {
            await refreshKnownVocabulary(target);
        }
        console.log("Known: ", knownVocabulary);
        if (currentUser !== null && targetLanguage !== null && (knownVocabulary === null || !knownVocabulary.hasOwnProperty(targetLanguage)) ){
            if (targetLanguage !== "all"){
                fetchData(targetLanguage); 
            }  
            else  {
                // check if all languages are in knownVocabulary
                for (const lang of activeUserLanguages){
                    if (!knownVocabulary.hasOwnProperty(lang.target_language)){
                        fetchData("all");
                        break;
                    }
                }
            }
        }
    },[knownVocabulary, currentUser, targetLanguage]);

    useEffect(()=>{
        console.log("Target words: ", vocabularyTargetWordsInTargetLanguage, texts, targetLanguage)
        if (vocabularyTargetWordsInTargetLanguage !== null &&  texts !== null){
            let obj = {}; // id as keys
            for (const text of texts){
                let splitString = text['target_ISO_639-1'] !== "zh" ? /(\s+)/ : "";
                let target = text['target_ISO_639-1'];
                let percentage = 0;
                if (vocabularyTargetWordsInTargetLanguage.hasOwnProperty(target)){
                    percentage = calculatePercentageOfVocabulary(JSON.parse(text.body), splitString, vocabularyTargetWordsInTargetLanguage[target]);
                }
                obj[text.text_id] = percentage;
            }
            setCalculatedPercentages(obj);
        }
    },[vocabularyTargetWordsInTargetLanguage, texts, targetLanguage])

    const [contentTypeClasses, setContentTypeClasses] = useState({
        'story': 'bg-charcoal ', 
        'conversation': 'bg-burnt-sienna ',
        'article': 'bg-beautiful-blue ',
        'poem': 'bg-beautiful-blue ',
        'fairytale': 'bg-beautiful-blue '
    })

    const percentageBubbleClass = (id) => {
        if (calculcatedPercentages.hasOwnProperty(id)){
            console.log("Yes: ", calculcatedPercentages[id]);
            let number = calculcatedPercentages[id];
            if (number < 20){
                return "bg-burnt-sienna "
            }
            else if (number < 40){
                return "bg-sandy-brown "
            }
            else if (number < 70){
                return "bg-green-400 "
            }
            else if (number < 100){
                return "bg-persian-green "
            }
            else if (number === 100){
                return "bg-persian-green border-solid border-2 border-white"
            }
        }
    }

    useEffect(()=>{
        if (numberOfResults !== null && numberOfResultsPerPage !== null){
            setNumberOfPages(Math.ceil(numberOfResults/numberOfResultsPerPage));
        }
    },[numberOfResults, numberOfResultsPerPage]);

    const fetchSearchResults = async () => {
        setLoading(true);
        let r = await searchPublicTexts(searchTerm, targetLanguage, textType, numberOfResultsPerPage, currentPage, sortByValue);
        let d_list = [];
        r['hits'].forEach((hit)=>{
            d_list.push(hit.document);
        });
        console.log("Results: ", d_list);
        setTexts(d_list);
        setNumberOfResults(r['found']);
        setCurrentPage(r['page']);
        setLoading(false);
    }

    useEffect(()=>{
        const fetchData = async () => {
            await fetchSearchResults();
        }
        if (targetLanguage !== null && textType !== null && sortByValue !== null){
            fetchData();
        }
    },[targetLanguage, sortByValue, textType, searchTerm, currentPage]);

    /*                            <h1>{targetLanguage !== "all" ? allLanguages[0][targetLanguage]['name']['en'] : "All"} texts</h1>
                            <div className="text-center text-sm">Study {targetLanguage !== "all" ? allLanguages[0][targetLanguage]['name']['en'] : "all languages"}  with texts generated by AI.</div>*/

    const changeSortByValue = (option) => {
        setSortByValue(option.value);
    }
    const changePageNumber = (nb) => {
        setCurrentPage(nb);
        const url = setParams(nb, "page");
        navigate(currentPath+'?'+url);
        window.scrollTo(0, 0);
    }

    // <MyLibraryMenu activeName="explore" />

    return (
        <>
            <Helmet>
                <title>AI texts - Yalango</title>
                <meta name="description" content={"Study languages by reading texts written by AI in your target language."} />
            </Helmet>
            <div className="  min-h-screen h-full">
                <div className="flex flex-col gap-8">
                   
                    <ExploreMenu activeName={"ai-texts"} />
                </div>
                <ReactTooltip place="bottom" id="tooltip-texts" effect="solid" />

                        <div className="p-2 py-6">
                            <SearchTexts textType={textType} setTextType={setTextType} sortByOptions={sortByOptions} searchTerm={searchTerm} searchInputChange={searchInputChange} targetLanguage={targetLanguage} setTargetLanguage={setTargetLanguage} />
                            <div className="flex flex-row justify-start sm:justify-end px-8">
                                <SortByButton textSize={"sm"} onChange={changeSortByValue} value={sortByOptionsDict[sortByValue]} options={sortByOptions} />
                            </div>
                        {(allLanguages !== null && targetLanguage !== null && !loading) &&
                        <>
                        <div className="grid justify-center place-items-center grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 text-center">
                            {texts !== null && texts.map((text, textIndex)=>(
                                <>
                                    <motion.div whileHover={{y:-2}} key={"text_"+textIndex}>
                                       <TextCard text={text} calculcatedPercentages={calculcatedPercentages} />
                                    </motion.div>
                                </>
                            ))}
                        </div>
                        </>
                        }
                      
                        {texts !== null && texts.length === 0 &&
                            <>
                                <div className="text-center">
                                    No texts found. Please try a different search. 
                                </div>
                            </>
                        }
                        </div>
                        {numberOfPages !== null && !loading && 
                            <>
                                <div className="w-full lg:w-5/6 xl:w-2/3 m-auto grid grid-cols-5 sm:grid-cols-8 md:grid-cols-10 lg:grid-cols-12 gap-2 justify-center place-items-center">
                                {[...Array(numberOfPages)].map((val, index)=>(
                                    <>
                                        <motion.div onClick={()=>changePageNumber(index+1)} whileHover={{scale:1.03}} key={"page_"+index} className={(currentPage === (index+1) ? 'bg-charcoal text-white ': 'bg-white ' ) + " cursor-pointer p-2 px-4 rounded-lg shadow-xl"}>
                                            {index+1}
                                        </motion.div>
                                    </> 
                                    ))}
                                </div>
                            </>
                        }
    
                  {loading &&
                    <>
                        <div  className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 text-center">
                        {[...Array(numberOfResultsPerPage)].map((n, i)=>(
                            <>
                                <div className="h-64 p-4 w-full" key={"skeleton_"+i}>
                                    <Skeleton count={1} height={230} />
                                </div>
                            </>
                        ))}
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default TextsDirectory;