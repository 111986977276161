import React from 'react'
import {motion} from 'framer-motion';
import IconListElement from './IconListElement';

const ListMenuOfOptions = ({options, type}) => {

    return (
      <>
        <div className="relative w-64">
          <div className="flex w-full absolute bg-white rounded-lg shadow-xl mt-4 flex-col gap-1">
            {options.map((option, i)=>(
              <>
                <motion.div whileHover={{scale:1.02}} className="px-4" key={"list_item_"+i}>
                  <IconListElement isActivated={true} isSelected={false} showIcon={true} type={type} text={option.text} activateFunction={option.function} icon={option.icon} />
                </motion.div>
              </>
            ))}
          </div>
        </div>
      </>
    )
  }

export default ListMenuOfOptions;