import { projectFirestore, projectFunctions, timeStamp} from '../firebase/config.js';
import { useAuth } from "../contexts/AuthContext";
import {useLanguagesContext} from '../contexts/LanguagesContext';
import { faBowlChopsticksNoodles } from '@fortawesome/pro-thin-svg-icons';

export default function useFolders() {
    let {currentUser} = useAuth();

    const fetchAllFoldersFromCurrentUserInRealtime = async (setFolders) => {
        if (currentUser === null){return []}
        let query = projectFirestore.collection("folders").where("uid", "==", currentUser.uid);
        let unsub = await query.onSnapshot((snapshot) => {
            let l = [];
            if (!snapshot.empty){
                snapshot.docs.forEach((doc)=>{
                    let d = doc.data();
                    d['doc_id'] = doc.ref.id;
                    l.push(d);
                })
            }
            setFolders(l);
        });
        return unsub;
    }

    const fetchGlobalFoldersOrder = async () => {
        if (currentUser === null){return null}
        let query = projectFirestore.collection("users").doc(currentUser.uid).collection("private-data").doc("parent_folder").collection("languages");
        let data = {};
        await query.get().then((snapshot)=>{
            if(snapshot.empty){return null}
            snapshot.docs.forEach((doc)=>{
                data[doc.ref.id] = doc.data();
            })
        });
        return data;
    }

    const fetchGlobalFoldersOrderInRealtime = async (setState) => {
        if (currentUser === null){return null}
        let query = projectFirestore.collection("users").doc(currentUser.uid).collection("private-data").doc("parent_folder").collection("languages");
        let unsub = await query.onSnapshot((snapshot)=>{
            let data = {};
            if(snapshot.empty){return null}
            snapshot.docs.forEach((doc)=>{
                data[doc.ref.id] = doc.data();
            });
            setState(data);
        });
        return unsub;
    }

    const updateGlobalFolderOrderInTargetLanguage = async (doc_ids, language) => {
        if (currentUser === null){return null}
        console.log(doc_ids, language);
        let query = projectFirestore.collection("users").doc(currentUser.uid).collection("private-data").doc("parent_folder").collection("languages").doc(language);
        let data = {
            'doc_ids_order': doc_ids
        };
        let r = await query.set(data, {merge:true});
        return r;
    }

    const createFolder = async (name, language, parentFolderDocId) => {
        if (currentUser === null){return null}
        let f = projectFunctions.httpsCallable('createFolder');
        return f({'name': name, 'language': language, 'parent_doc_id': parentFolderDocId});
    }

    const updateFolderOrder = async (folder_doc_id, new_order) => {
        if (currentUser === null){return null}
        let f = projectFunctions.httpsCallable('updateFolderOrder');
        return f({'folder_doc_id': folder_doc_id, 'new_order': new_order});
    }

    const moveItemToFolder = async (moveItemModalItem, moveItemCurrentParent, destinationFolder) => {
        if (currentUser === null){return null}
        //console.log("calling function: ", moveItemModalItem, moveItemCurrentParent, destinationFolder);
        //let obj = {'item': moveItemModalItem, 'currentParent': moveItemCurrentParent, 'destinationFolder': destinationFolder};
        //console.log("Arguments: ", obj);
        let f = projectFunctions.httpsCallable('moveItemToFolder');
        let r = await f({'item': moveItemModalItem, 'currentParent': moveItemCurrentParent['doc_id'], 'destinationFolder': destinationFolder['doc_id']}); //.catch(err=>{console.log("ERROR: ", err)})
        console.log(r);
        return r;
    }

    const fetchFolder = async (docId) => {
        if (currentUser === null){return false}
        let query = projectFirestore.collection("folders").doc(docId);
        let r = await query.get().catch(err=>{return false});
        if (r !== false && r.exists){
            return r.data();
        } else {
            return false;
        }
    }

    const renameFolder = async (docId, name) => {
        if (currentUser === null){return false}
        let query = projectFirestore.collection("folders").doc(docId);
        let r = await query.set({
            'name': name,
            'last_updated': timeStamp
        }, {merge:true})
        .catch(err=>{return false});
        return r;
    }

    const deleteFolderFromDocId = async (docId) => {
        if (currentUser === null){return false}
        let query = projectFirestore.collection("folders").doc(docId);
        let r = await query.delete().catch(err=>{return false});
        return r;
    }
 
    return {
        fetchAllFoldersFromCurrentUserInRealtime, 
        fetchGlobalFoldersOrder, 
        updateGlobalFolderOrderInTargetLanguage, 
        createFolder, 
        updateFolderOrder, 
        moveItemToFolder, 
        fetchFolder,
        fetchGlobalFoldersOrderInRealtime, 
        renameFolder, 
        deleteFolderFromDocId
    }
  
}