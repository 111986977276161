import React from 'react';
import TranslatorWidget from './TranslatorWidget';
import Modal from '../../general/Modal';

const TranslatorModal = ({closeFunction}) => {
  return (
      <>
            <Modal  content={<ModalContent closeFunction={closeFunction} />} overflow={"overflow-y-visible"} closeFunction={closeFunction} size="3xl" />
      </>
  )
}

const ModalContent = () => {
    return (
        <>
            <div className="">
                <TranslatorWidget />
            </div>
        </>
    )
}

export default TranslatorModal