import React from 'react';
import MyContentSidebarMenu from '../menus/sidebar-menu/menus/MyContentSidebarMenu';

const MyFoldersTreeView = (props) => {
  return (
    <>
        <MyContentSidebarMenu {...props} />
    </>
  )
}

export default MyFoldersTreeView;