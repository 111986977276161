import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Link} from 'react-router-dom';
import RenderCommentAndReplies from '../../components/comments/RenderCommentAndReplies';

const PostPage = () => {
    let { commentDocId } = useParams();
    return (
        <>
            <div className=" ">
                <div className="text-sm">
                    <Link to="/forums">
                        Go to forums
                    </Link>
                </div>
                <div className="p-4">
                    <RenderCommentAndReplies commentDocId={commentDocId} />
                </div>
            </div>
        </>
    )
}

export default PostPage;