import {projectFirestore, projectFunctions, timeStamp, firebase} from '../firebase/config.js';
import { useAuth } from "../contexts/AuthContext";

export default function useTexts() {

    let {currentUser} = useAuth();

    const fetchText = async (id) => {
        let query = projectFirestore.collection("texts").doc("items").collection("items").where("privacy", "==", "public").where("premium", "==", false).where("text_id", "==", parseInt(id));
        let snap = await query.get();
        if (!snap.empty && snap.docs.length === 1){
            // only unique slugs
            let data = snap.docs[0].data();
            data['doc_id'] = snap.docs[0].ref.id;
            return data;
        } else {
            return fetchPersonalText(id);
        }
    }

    const fetchPersonalText = async (id) => {
        if (currentUser === null){return false}
        let query = projectFirestore.collection("texts").doc("items").collection("items").where("uid", "==", currentUser.uid).where("text_id", "==", parseInt(id));
        let snap = await query.get();
        if (!snap.empty && snap.docs.length === 1){
            // only unique slugs
            let data = snap.docs[0].data();
            data['doc_id'] = snap.docs[0].ref.id;
            return data;
        } else {
            return false
        }
    }

    const fetchAllTextsInTargetLanguage = async (targetLang) => {
        let snap = await projectFirestore.collection("texts").doc("items").collection("items").where("target_ISO_639-1", "==", targetLang).where("privacy", "==", "public").where("premium", "==", false).get();
        let docs =[];
        if (!snap.empty){
            snap.docs.forEach((doc)=>{
                let d = doc.data();
                d['doc_id'] = doc.ref.id;
                docs.push(d);
            })
        } 
        return docs;
    }

    const fetchMostRecentTextsInTargetLanguage = async ({targetLang, limit}) => {
        let query =  projectFirestore.collection("texts").doc("items").collection("items").where("target_ISO_639-1", "==", targetLang).where("privacy", "==", "public").where("premium", "==", false);
        query = query.orderBy("created_timestamp", "desc").limit(limit);
        let snap = await query.get();
        let docs =[];
        console.log(snap);
        if (!snap.empty){
            snap.docs.forEach((doc)=>{
                let d = doc.data();
                d['doc_id'] = doc.ref.id;
                docs.push(d);
            })
        } 
        return docs;
    }

    const fetchAllTexts = async () => {
        let snap = await projectFirestore.collection("texts").doc("items").collection("items").where("privacy", "==", "public").where("premium", "==", false).get();
        let docs =[];
        if (!snap.empty){
            snap.docs.forEach((doc)=>{
                let d = doc.data();
                d['doc_id'] = doc.ref.id;
                docs.push(d);
            })
        } 
        return docs;
    }

    const fetchAllTextsFromCurrentUserInRealtime = async (setStateFunc) => {
        let query =  projectFirestore.collection('texts').doc('items').collection('items').where('uid', '==', currentUser.uid);
        return query.onSnapshot(querySnapshot => {
            let texts = [];
            for (const doc of querySnapshot.docs){
                let d = doc.data();
                d['doc_id'] = doc.ref.id;
                texts.push(d);
            }
            setStateFunc(texts);
        });
    }

    const renameText = async (textDocId, newName) => {
        console.log("Renaming");
        return projectFirestore.collection("texts").doc("items").collection("items").doc(textDocId).set({
            'title': newName, 
            'last_updated_timestamp': timeStamp
        }, {merge: true});
    }
    
    const saveChangesToText = async ({textDocId=null, title=null, privacy=null, body=null}) =>  {
        if (textDocId === null || textDocId === undefined){return false}

        let obj = {
            'last_updated': timeStamp
        };

        if (title !== null){
            obj['title'] = title;
            obj['slug'] = title.toLowerCase().replaceAll(" ", "-");
        }
        if (privacy !== null){
            obj['privacy'] = privacy;
        }
        if (body !== null){
            obj['body'] = body;
        }

        console.log("New text: ", obj);

        await projectFirestore.collection("texts").doc("items").collection("items").doc(textDocId).set(obj, {merge: true}).catch(err=>{
            console.log("Error: ", err);
        });

        return true;
    }

    const saveNewText = async ({targetLanguage, title, parentFolderDocId}) => {
        if (currentUser === null){return false};
        if (title === "" || title === null || title === undefined){return false};
        
        const saveNewTextFunction =  projectFunctions.httpsCallable('saveNewText');
        let data = await saveNewTextFunction({
            'targetLanguage': targetLanguage,
            'title': title, 
            'parentFolderDocId': "top_level", 
            'destinationFolder': parentFolderDocId
        }).catch(err=>{return false});
        if (data === false){return false};

        return data;
    }

    const deleteTextFromDocId = async (docId) => {
        let path = projectFirestore.collection("texts").doc("items").collection("items").doc(docId);
        let r = await path.delete();
        return r;
    }


    return {
        fetchText,
        fetchAllTextsInTargetLanguage, 
        fetchAllTexts, 
        fetchMostRecentTextsInTargetLanguage, 
        fetchAllTextsFromCurrentUserInRealtime, 
        renameText, 
        fetchPersonalText, 
        saveChangesToText, 
        saveNewText, 
        deleteTextFromDocId
    }
}