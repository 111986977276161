import React, {useState, useEffect} from 'react';
import RowOfTexts from './RowOfTexts';
import useTexts from '../../../hooks/useTexts';

const RowOfSpecificTexts = ({listOfTextIds}) => {
    const [items, setItems] = useState(null);
    const [loading, setLoading] = useState(true);
    const {fetchText} = useTexts();

    useEffect(()=> {
        const fetchData = async () => {
            let list = [];
            for (const text_id of listOfTextIds){
                let r = await fetchText(text_id);
                list.push(r);
            };
            setItems(list);
        }

        if (listOfTextIds === null){return null}
        fetchData();
    },[listOfTextIds]);

    return (
        <>
            <div className="p-3 py-6 overflow-y-visible overflow-x-auto scrollbar-hide">
                <RowOfTexts items={items} />
            </div>
        </>
    )
}

export default RowOfSpecificTexts;