import React, {useState, useEffect, useCallback} from 'react';
import Skeleton from 'react-loading-skeleton';
import Icon from '../../../components/general/Icon';
import useStatistics from '../../../hooks/useStatistics';
import useGoals from '../../../hooks/useGoals';
import {Link} from 'react-router-dom';
import CountryFlag from '../../../components/languages/CountryFlag';
import {useLanguagesContext} from '../../../contexts/LanguagesContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-duotone-svg-icons';
import {faCheck} from '@fortawesome/pro-duotone-svg-icons';
import {faPlay} from '@fortawesome/pro-duotone-svg-icons';
import {faX} from '@fortawesome/pro-duotone-svg-icons';
import {faArrowDown} from '@fortawesome/pro-solid-svg-icons';
import {faArrowUp} from '@fortawesome/pro-solid-svg-icons';
import {faLanguage} from '@fortawesome/pro-duotone-svg-icons';
import { faCardsBlank} from '@fortawesome/pro-duotone-svg-icons';
import {faChartLine} from '@fortawesome/pro-duotone-svg-icons';
import {faArrowsRotate} from '@fortawesome/pro-duotone-svg-icons';
import {faBookSparkles} from '@fortawesome/pro-duotone-svg-icons';
import {faCalendarCheck} from '@fortawesome/pro-duotone-svg-icons';
import {faBoltLightning} from '@fortawesome/pro-duotone-svg-icons';
import {faComments} from '@fortawesome/pro-duotone-svg-icons';
import {faEarthAsia} from '@fortawesome/pro-duotone-svg-icons';
import {faStarShooting} from '@fortawesome/pro-duotone-svg-icons';
import {faTableColumns} from '@fortawesome/pro-duotone-svg-icons';

const DailyProgressWidget = () => {
    // Loading
    const [userGoalsLoading, setUserGoalsLoading] = useState(true);
    const [dailyStatsLoading, setDailyStatsLoading] = useState(true);
    const [dailyStreakLoading, setDailyStreakLoading] = useState(true);
    const [filteredDailyStreakLoading, setFilteredDailyStreakLoading] = useState(true);
    const [verifiedStreak, setVerifiedStreak] = useState(false);
    const [showData, setShowData] = useState(true);

    const {getDailyStatsInActiveTargetLanguages} = useStatistics();
    const {activeUserLanguages, allLanguages, setGlobalSelectedTargetLanguage} = useLanguagesContext();
    const {getCurrentUserGoals, 
        getCurrentUserDailyStreak, 
        validateStreak,
        refreshCurrentStreakAPI} = useGoals();

    // DATA
    const [dailyStats, setDailyStats] = useState(null);
    const [dailyUserGoals, setDailyUserGoals] = useState(null);
    const [dailyStreak, setDailyStreak] = useState(null);
    const [filteredDailyStreak, setFilteredDailyStreak] = useState(null);
    const [dbCounter, setDbCounter] = useState(0);

    const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const today = new Date();
    const todayNumber =  today.getDay() === 0 ? 6 : today.getDay() - 1;

    // Memoized functions
    const getCurrentUserGoalsCallback = useCallback(async ()=>{
        if (activeUserLanguages === null){return null}
        if (!userGoalsLoading) {return null};
        let r = await getCurrentUserGoals();
        setDailyUserGoals(r);
        setDbCounter((dbCounter)=>dbCounter+1);
        setUserGoalsLoading(false);
    },[userGoalsLoading, getCurrentUserGoals, activeUserLanguages]);

    const getDailyStatsInActiveTargetLanguagesCallback = useCallback(async ()=>{
        if (activeUserLanguages === null){return null}
        let r = await getDailyStatsInActiveTargetLanguages();
        setDailyStats(r);
    }, [getDailyStatsInActiveTargetLanguages, activeUserLanguages]); 

    const getCurrentUserDailyStreakCallback = useCallback(async ()=>{
        if (activeUserLanguages === null){return null}
        let r = await getCurrentUserDailyStreak();
        setDailyStreak(r);
        setDailyStreakLoading(false);
    },[activeUserLanguages, getCurrentUserDailyStreak]);

    const refreshData = async () => {
        setDailyStatsLoading(true);
        setDailyStreakLoading(true);
        setUserGoalsLoading(true);
    }
    
    useEffect(()=>{
        
        const asyncStatsFetch = async () => {
            if (activeUserLanguages !== null){
                await getDailyStatsInActiveTargetLanguagesCallback();
            }
        }

        const asyncStreakFetch = async () => {
            if (activeUserLanguages !== null){
                await getCurrentUserDailyStreakCallback();
            }
        }

        let statsUnsubscribers = null;
        let streakUnsubscribers = null;
        if (userGoalsLoading){
            getCurrentUserGoalsCallback();
        }
        if (dailyStreakLoading){
            asyncStreakFetch();
        }
        if (dailyStatsLoading){
            asyncStatsFetch();
        }
       
        return () => {
            statsUnsubscribers !== null && statsUnsubscribers.forEach(unsub=>{
                unsub();
            });
            streakUnsubscribers !== null && streakUnsubscribers.forEach(unsub=>{
                unsub();
            }); 
        }
    },[dailyStreakLoading, dailyStreak, getCurrentUserDailyStreakCallback, getDailyStatsInActiveTargetLanguagesCallback, activeUserLanguages, dailyStats, userGoalsLoading, dailyStatsLoading, getCurrentUserGoalsCallback]);

    useEffect(()=>{
        if (dailyUserGoals !== null){
            
        }
    }, [dailyUserGoals]);

    useEffect(()=>{
        if (dailyStats !== null){
            setDailyStatsLoading(false);
        }
    }, [dailyStats]);

    useEffect(()=>{
        const fetchData = async () => {
            if (dailyStreak !== null && Object.keys(dailyStreak).length > 0 && !verifiedStreak){
                let validation = await validateStreak(dailyStreak);
                setVerifiedStreak(true);
                let copy = JSON.parse(JSON.stringify(dailyStreak));
                for (const lang of Object.keys(validation)){
                    if (!validation[lang]){
                        refreshCurrentStreakAPI(lang);
                        copy[lang]['current_streak'] = 0;
                        copy[lang]['current_streak_dates'] = [];
                    }
                }   
                setFilteredDailyStreak(copy);
            } else if (dailyStreak !== null){
                let copy = JSON.parse(JSON.stringify(dailyStreak));
                setFilteredDailyStreak(copy);
            }
        }
        if (!verifiedStreak){
            fetchData();
        }
    }, [dailyStreak, verifiedStreak]);

    useEffect(()=>{
        if (filteredDailyStreak !== null){
            setFilteredDailyStreakLoading(false);
        }
    },[filteredDailyStreak]);
    //<CountryFlag countryCode={allLanguages[0][lang['target_language']]['country_flag']} flagName={lang['name']['en']} />
    return (
        <>
            {(activeUserLanguages !== null && activeUserLanguages.length > 0) &&
                <>
                    {(userGoalsLoading || dailyStatsLoading || dailyStreakLoading) ? 
                        <>
                            {activeUserLanguages !== null &&
                                <>
                                    <Skeleton count={1} height={10} />
                                    <Skeleton count={activeUserLanguages.length} height={30} />
                                    <Skeleton count={1} height={10} />
                                </>
                            }
                        </>
                    : 
                        <>
                            <div className="p-3 flex flex-col place-items-center justify-center">
                                <div className=" font-bold flex flex-row justify-center gap-6 text-lg">
                                    
                                    <div className="text-gray-600 font-bold">
                                        Progress today
                                    </div>
                                    
                                    <div onClick={()=>setShowData(!showData)} className="cursor-pointer text-gray-500 justify-self-end">
                                        <FontAwesomeIcon icon={showData ? faArrowUp : faArrowDown} />
                                    </div>
                                </div>
                                
                                {showData &&
                                    <>
                                        <div className="text-sm place-self-end cursor-pointer" onClick={refreshData}>
                                            <FontAwesomeIcon icon={faArrowsRotate} />
                                        </div>
                                        <div className="grid grid-cols-4 gap-4 w-5/6 mx-auto place-items-center">
                                            {activeUserLanguages.map((lang, index)=>(
                                                <>
                                                    <div key={"name_"+index} className="text-gray-600 font-bold">
                                                        <CountryFlag countryCode={allLanguages[0][lang['target_language']]['country_flag']} flagName={lang['name']['en']} />
                                                    </div>
                                                    <div key={"stats_"+index} className="flex flex-row">
                                                        <div className="text-gray-600 text-sm flex flex-row gap-1">
                                                            <div>   
                                                                {lang.target_language in dailyStats ? dailyStats[lang.target_language]['total_correct_items'] : 0} 
                                                            </div>  
                                                            <div>
                                                                {(dailyUserGoals !== null && lang.target_language in dailyUserGoals) &&
                                                                    <>
                                                                        of
                                                                    </>
                                                                }
                                                            </div>
                                                            <div>
                                                                {(dailyUserGoals !== null && lang.target_language in dailyUserGoals) &&
                                                                <> 
                                                                    {dailyUserGoals[lang.target_language]['total_correct_items_per_day'][todayNumber]} 
                                                                </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-row gap-2  ">
                                            
                                                        <div className="text-yellow-500">
                                                            <Link onClick={()=>setGlobalSelectedTargetLanguage(lang.target_language)} to="/my-statistics">
                                                                <Icon icon={<FontAwesomeIcon icon={faCalendarCheck} />} />
                                                            </Link>
                                                        </div>
                                                    
                                                        <div className="font-bold text-gray-600">
                                                            {!filteredDailyStreakLoading &&
                                                                <>
                                                                    {(filteredDailyStreak !== null && lang.target_language in filteredDailyStreak) ? filteredDailyStreak[lang.target_language]['current_streak'] : ''}
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="text-persian-green">
                                                        <Link to={"/my-vocabulary/"+lang.target_language+"/play"}>
                                                            <FontAwesomeIcon icon={faPlay} />
                                                        </Link>
                                                    </div>
                                                </>
                                            ))}
                                        </div>
                                        <div className="text-sm">
                                            <Link to="/my-goals">Edit my goals</Link>
                                        </div>
                                    </>
                                }
                            </div>
                        </>
                    }
                </>
            }
        </>
    )
}

export default DailyProgressWidget;