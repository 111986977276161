import React, {useState, useEffect} from 'react';
import Button from '../../general/Button';
import {useNavigate} from 'react-router-dom';
import Modal from '../../general/Modal';
import Select from 'react-select';
import {useLanguagesContext} from '../../../contexts/LanguagesContext';
import useDecks from '../../../hooks/useDecks';
import useLanguageSpecificInfo from '../../../hooks/useLanguageSpecificInfo';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLoader} from '@fortawesome/pro-solid-svg-icons';

export const AddDeckModalContent = ({closeFunction, setTempSelectedDeck, setAddNewDeckLoading, createItemMode, newItemParentFolderDocId, hideNavigation}) => {
    console.log(hideNavigation);
    let {allLanguages, userLanguages, activeUserLanguages, globalSelectedTargetLanguage, setGlobalSelectedTargetLanguage, globalSelectedSourceLanguage, setGlobalSelectedSourceLanguage, targetLanguageOptions, sourceLanguageOptions} = useLanguagesContext();
    let {handleAddDeckForCurrentUserWithoutCategory} = useDecks();
    const {chineseScriptsOptions} = useLanguageSpecificInfo();

    const navigate = useNavigate();
    const [targetLanguage, setTargetLanguage] = useState(globalSelectedTargetLanguage);
    const [sourceLanguage, setSourceLanguage] = useState(globalSelectedSourceLanguage);
    const [targetScript, setTargetScript] = useState(null);
    const [sourceScript, setSourceScript] = useState(null);

    useEffect(() => {
       activeUserLanguages.forEach(language => {
        console.log(language);
        if (language.target_language === "zh" && language.hasOwnProperty("target_script")){
            console.log(language.target_script)
            setTargetScript(language.target_script);
        } 
        if (language.source_language === "zh" && language.hasOwnProperty("source_script")){
            setSourceScript(language.source_script);
        } 
    });

    }, [activeUserLanguages]);

    const [deckName, setDeckName] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [navigateAfterCreation, setNavigateAfterCreation] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [privacy, setPrivacy] = useState("private");
    const [privacyOptions, setPrivacyOptions] = useState([[{'label': 'Public', 'value': 'public'}, {'label': 'Private', 'value': 'private'}], 
    {'public': {'label': 'Public', 'value': 'public'}, 'private': {'label': 'Private', 'value': 'private'}}]);

    const deckNameChange = (e) => {
        setDeckName(e.target.value);
    }

    const deckNameKeyDown = (e) => {
        if (e.keyCode === 13) {
            saveNewDeck();
        } else {
            if (error === true){
                setError(false);
                setErrorMessage("");
            }
        }
    }

    const changeSourceLanguage = (e) => {
        setSourceLanguage(e.value);
    }

    const changeTargetScript = (option) => {
        setTargetScript(option.value)
    }

    const changeSourceScript = (option) => {
        setSourceScript(option.value)
    }

    const changePrivacy = (e) => {
        setPrivacy(e.value);
    }

    const saveNewDeck = async () => {
        if (deckName.trim() === ""){
            setErrorMessage("The name of the deck cannot be empty.");
            return setError(true);
        } 
        setAddNewDeckLoading(true);
        setLoading(true);
        let id = await handleAddDeckForCurrentUserWithoutCategory(deckName.trim(), sourceLanguage, targetLanguage, privacy, createItemMode, newItemParentFolderDocId, targetScript, sourceScript);
        //setTempSelectedDeck !== undefined && setTempSelectedDeck(id); need to set equal to deck, but then need to return  deck instead of id
        setGlobalSelectedTargetLanguage(targetLanguage);
        setGlobalSelectedSourceLanguage(sourceLanguage);
        if (typeof id === 'number' && navigateAfterCreation && !hideNavigation) {
            navigate("/my-decks/"+id);
        }
        setLoading(false);
        setAddNewDeckLoading(false);
        closeFunction();
    }

    console.log(targetScript, chineseScriptsOptions)

    return (
        <>
            <div className="">
                <h1>Create a new {allLanguages[0][targetLanguage]['name']['en']} deck</h1>
                    {!loading ?
                        <>
                            <div className="flex flex-col gap-4">
                                <div>
                                    <div className="font-bold text-gray-600">
                                        Name of the deck
                                    </div>
                                    <div className="text-center">
                                        <input value={deckName} onKeyDown={deckNameKeyDown} onChange={deckNameChange} type="text" className={(error === true ? 'border-red-500 ' : '' ) + "bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white"} />
                                    </div>
                                    {error === true &&
                                        <>
                                            <div className="italic text-red-400">
                                                {errorMessage}
                                            </div>
                                        </>
                                    }
                                </div>
                                {targetLanguage === "zh" && 
                                    <>
                                        <div>
                                            <div className="font-bold text-gray-600">
                                                Target script
                                            </div>
                                            <div>  
                                                <Select onChange={changeTargetScript} isSearchable={false} options={chineseScriptsOptions[0]} value={chineseScriptsOptions[1][targetScript]}  />
                                            </div>
                                        </div>
                                    </>
                                }
                                <div>
                                    <div className="font-bold text-gray-600">
                                        Source language
                                    </div>
                                    <div>    
                                        <Select onChange={changeSourceLanguage} isSearchable={true} options={sourceLanguageOptions[0]} value={sourceLanguageOptions[1][sourceLanguage]}  />
                                    </div>
                                </div>
                                {sourceLanguage === "zh" && 
                                    <>
                                        <div>
                                            <div className="font-bold text-gray-600">
                                                Source script
                                            </div>
                                            <div>  
                                                <Select onChange={changeSourceScript} isSearchable={false} options={chineseScriptsOptions[0]} value={chineseScriptsOptions[1][sourceScript]}  />
                                            </div>
                                        </div>
                                    </>
                                }
                                <div>
                                    <div className="font-bold text-gray-600">
                                        Privacy
                                    </div>
                                    <div>    
                                        <Select onChange={changePrivacy} isSearchable={false} options={privacyOptions[0]} value={privacyOptions[1][privacy]}  />
                                    </div>
                                </div>
                                {hideNavigation !== true &&
                                    <>
                                        <div className="flex flex-row gap-2 text-charcoal">
                                            <input type="checkbox" checked={navigateAfterCreation} onChange={()=>setNavigateAfterCreation(!navigateAfterCreation)} />
                                            <div>
                                                Navigate to new deck after creation
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className="flex flex-row justify-center gap-3">
                                    <div>
                                        <Button onClick={closeFunction} color="charcoal" text="Cancel" />
                                    </div>
                                    <div>
                                        <Button onClick={saveNewDeck} color="green" text="Create deck" />
                                    </div>
                                </div>
                            </div>
                        </>
                    :
                        <>
                            <div className="text-center text-4xl text-black">
                                <FontAwesomeIcon icon={faLoader} className="fa-spin" />
                            </div>
                        </>
                    }
            </div>
        </>
    )
}
const AddDeckModal = ({closeFunction, setAddNewDeckLoading}) => {
  return (
    <>
        <Modal overflow="overflow-visible" closeFunction={closeFunction} content={<AddDeckModalContent setAddNewDeckLoading={setAddNewDeckLoading} closeFunction={closeFunction} />} size="3xl" />
    </>
    )
};

export default AddDeckModal;
