import React from 'react';
import QuickNote from '../../dashboard/QuickNote';
import Modal from '../../general/Modal';

const QuickNotesModal = ({closeFunction}) => {
  return (
      <>
        <Modal content={<ModalContent closeFunction={closeFunction} />} closeFunction={closeFunction} size="3xl" />
      </>
  )
}

const ModalContent = () => {
    return (
        <>
            <div className="">
                <QuickNote />
            </div>
        </>
    )
}

export default QuickNotesModal