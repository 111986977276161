import React from 'react';
import ReactQuill from 'react-quill'; // Import react-quill
import 'react-quill/dist/quill.snow.css'; // Import the styles you need

const QuillEditor = (props) => {
    const { text, blockIndex, htmlOnUpdate } = props;

    const handleContentChange = (text) => {
        console.log(text)
        htmlOnUpdate({html:text, blockIndex:blockIndex});
    }

    return (
        <div>

            <div className="bg-white">
                <ReactQuill
                    theme="snow"
                    value={text}
                    onChange={handleContentChange}
                    modules={QuillEditor.modules}
                    formats={QuillEditor.formats}
                />
            </div>
        </div>
    );
}

// Specify the modules for react-quill editor
QuillEditor.modules = {
    toolbar: [
        [{ 'header': '1'}, {'header': '2'}, {'font': [] }],
        [{ 'size': [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, 
         {'indent': '-1'}, {'indent': '+1'}],
        ['link'],
        ['clean']
    ],
    clipboard: {
        // Match visual, not semantic, structure
        matchVisual: false,
    }
};

// Specify the formats allowed in the react-quill editor
QuillEditor.formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link',
];

export default QuillEditor;
