import React from 'react';
import { Link } from 'react-router-dom';
import { useLanguagesContext } from '../../contexts/LanguagesContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import {motion} from 'framer-motion';
import CountryFlag from '../languages/CountryFlag';

const CourseCard = (props) => {
    const {course, location="private"} = {...props};
    const {allLanguages} = useLanguagesContext();
    const baseLink = location === "public" ? "/course/" : "/my-courses/";
    return (
        <>
            {course !== null && course !== undefined &&
            <div className="h-full w-full">
                <Link className="no-underline h-full w-full" to={location === "public" ? (baseLink+course["course_id"]) : "/my-courses/"+course.course_id}>
                    <motion.div  whileHover={{y:-3}} className={"relative shadow-lg rounded-lg w-full h-full p-4  w-full bg-white  text-black shadow-lg cursor-pointer relative border border-solid border-gray-100 flex flex-col justify-evenly"}>
                        <div className="flex absolute  p-2  left-1 top-1 flex-row place-items-center justify-start gap-5">
                            <div className="flex flex-row place-items-center text-charcoal gap-1  ">
                                
                                <div className="rounded-lg shadow-xl overflow-hidden ">
                                    <CountryFlag flagName={allLanguages[0][course['source_ISO_639-1']]['name']['en']} countryCode={allLanguages[0][course['source_ISO_639-1']]['country_flag']} size="1.5em" />
                                </div>
                                <div className="text-xs">
                                    <FontAwesomeIcon icon={faArrowRight} />
                                </div>
                                <div className="rounded-lg shadow-xl overflow-hidden">
                                    <CountryFlag flagName={allLanguages[0][course['target_ISO_639-1']]['name']['en']} countryCode={allLanguages[0][course['target_ISO_639-1']]['country_flag']} size="1.5em" />
                                </div>
                                <div className="font-bold">
                                    Course
                                </div>
                            </div>
                        
                        </div>
                        <div className="p-4 max-h-48 overflow-auto gap-4 flex flex-col">
                            <div className="text-base text-center font-bold flex flex-row justify-center gap-2">
                                <div>
                                    {course.name}
                                </div>
                            </div>

                        </div>
                    </motion.div>
                </Link>
            </div>
            }
        </>
    )
}

export default CourseCard;