import React, {useState, useEffect} from 'react';
import YalangoStars from '../../assets/svg/yalango/YalangoStars.svg';
import {useUserStatisticsContext} from '../../contexts/UserStatisticsContext'; 
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/pro-duotone-svg-icons';
import {faRotateRight} from '@fortawesome/pro-solid-svg-icons';
import Skeleton from 'react-loading-skeleton';
import {Link} from 'react-router-dom';
import {faAngleDown} from '@fortawesome/pro-solid-svg-icons';
import {faAngleUp} from '@fortawesome/pro-solid-svg-icons';


const MyStarsCard = () => {

    const { 
        userStars,
        refreshUserStars,
    } = useUserStatisticsContext();

    const [showDetails, setShowDetails] = useState(true);
    const [cardLoading, setCardLoading] = useState(false);
   
    useEffect(()=>{
        if (userStars === null){return}
        //setCardLoading(false);
    },[userStars])

    const refreshCardClick = async () => {
        setCardLoading(true);
        await refreshUserStars();
        setCardLoading(false);
    }

    return (
        <>
            <div className="w-full flex flex-col gap-0 p-0 relative">
                <div className="font-bold text-center text-lg flex flex-row justify-center gap-6 place-items-center text-gray-600">
                    <div>
                        My stars
                    </div>
                    <div>
                        <div className="flex flex-row w-full justify-center text-lg gap-2 text-gray-600">
                            <div className="text-sandy-brown">
                                <FontAwesomeIcon icon={faStar} />
                            </div>
                            <div className="font-bold">
                                {userStars !== null &&
                                    <>
                                        {(userStars === false || !userStars.hasOwnProperty("total_stars")) ? 0 : userStars.total_stars}
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div onClick={()=>setShowDetails(!showDetails)} className="underline text-sm cursor-pointer text-gray-600 opacity-70">
                        {showDetails ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}
                    </div>
                    <div className="text-sm text-gray-600 opacity-70 cursor-pointer">
                        <FontAwesomeIcon onClick={refreshCardClick} icon={faRotateRight} />
                    </div>
                </div> 
                <div className="flex w-full flex-row justify-center">
                    {!cardLoading ? 
                        <div className="flex flex-col transition ease-in-out place-items-center gap-0">
                            {showDetails &&
                                <>
                                 {(userStars !== null && userStars !== false ) &&
                                  <>
                                <div className="text-xs w-full grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-4  justify-center text-gray-600">
                                   
                                       
                                            <div>
                                                <span className="font-bold">{!userStars.hasOwnProperty("public_deck_count") ? 0 : userStars.public_deck_count}</span> public {userStars.public_deck_count === 1 ? "deck" : "decks"}
                                            </div>
                                            <div>
                                                <span className="font-bold">{!userStars.hasOwnProperty("duplicate_deck_count") ? 0 : userStars.duplicate_deck_count}</span> {userStars.duplicate_deck_count === 1 ? "deck" : "decks"} duplicated
                                            </div>
                                            <div>
                                                <span className="font-bold">{!userStars.hasOwnProperty("comments_upvotes") ? 0 : userStars.comments_upvotes}</span> comment {userStars.comments_upvotes === 1 ? "upvote" : "upvotes"}
                                            </div>
                                            <div>
                                                <span className="font-bold">{!userStars.hasOwnProperty("decks_upvotes") ? 0 : userStars.decks_upvotes}</span> deck {userStars.decks_upvotes === 1 ? "upvote" : "upvotes"}
                                            </div>
                                     
                                </div>
                                <div>
                                <Link to={"/help/what-are-yalango-stars"}>Learn more</Link>.
                                </div>
                                </> 
                                    }
                                {!(userStars !== null && userStars !== false ) &&
                                <>
                                    <div className="italic text-sm my-3">
                                        Nothing to show here yet. <Link to={"/help/what-are-yalango-stars"}>Learn more</Link>.
                                    </div>
                                </>
                                }
                                </>

                            }
                        </div>
                    :
                        <div>
                            <Skeleton height={100} />
                        </div>
                    }
                    
                    
                </div>
            </div>
        </>
  )
}

export default MyStarsCard;