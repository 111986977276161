import React, {useEffect, useState} from 'react';
import useHelpCenter from '../../hooks/useHelpCenter';
import HelpArticleHeader from '../../components/help/HelpArticleHeader';
import {Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';

const HelpCenter = () => {
    
    const {fetchAllHelpArticles, 
        fetchAllHelpArticleFolders, 
        fetchAllHelpArticlesInFolders} = useHelpCenter();
    const [articles, setArticles] = useState(null);
    const [articleFolders, setArticleFolders] = useState(null);

    useEffect(()=>{
        const fetchData = async () => {
            let r = await fetchAllHelpArticlesInFolders();
            setArticles(r);
        };

        fetchData();
    },[]);

    return (
        <>
            <Helmet>
                <title>Help Center - Yalango</title>
                <meta name="description" content={"Useful tutorials and guides on how to use Yalango to study languages."} />
            </Helmet>
            <div className="  ">
                <div className="flex flex-col  gap-4 justify-center place-items-center">
                    <div className="w-full">
                        <HelpArticleHeader />
                    </div>
                    <div className="grid grid-cols-1 justify-start place-items-start sm:place-items-start sm:grid-cols-2 md:grid-cols-3 gap-10 p-4 w-full sm:w-5/6 ">
                        {(articles !== null) && 
                            <>
                                {articles.folder_order.map((folder, folderIndex)=>(
                                    <>
                                        <div key={"folder_"+folderIndex}>
                                            <h2 className="text-left">{folder.name.en}</h2>
                                            <div className="flex flex-col gap-3">
                                                {articles.articles[folder.doc_id].map((article, articleIndex)=>(
                                                    <>
                                                        <div key={"article_"+articleIndex}>
                                                            <Link to={"/help/"+article.slug}>
                                                                {article.title.en}
                                                            </Link>
                                                        </div>
                                                    </>
                                                ))}
                                            
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default HelpCenter;