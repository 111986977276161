import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useStatistics from '../../hooks/useStatistics';
import { useLanguagesContext } from '../../contexts/LanguagesContext';
import Icon from '../../components/general/Icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/pro-duotone-svg-icons';
import { faThumbsDown } from '@fortawesome/pro-duotone-svg-icons';
import Select from 'react-select';
import NoActiveTargetLanguage from '../../components/errors/NoActiveTargetLanguage';
import { Helmet } from 'react-helmet';
import GeneralInfoMessage from '../../components/info-messages/GeneralInfoMessage';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

const MyStatistics = () => {
    let { activeUserLanguages, activeTargetLanguageOptions, globalSelectedTargetLanguage, setGlobalSelectedTargetLanguage, setGlobalSelectedSourceLanguage } = useLanguagesContext();
    const [languageOptions, setLanguageOptions] = useState(activeTargetLanguageOptions);
    const [flashcardStats, setFlashcardStats] = useState(null);
    const [flashcardStatsAvailable, setFlashcardStatsAvailable] = useState(null);
    const [quizStats, setQuizStats] = useState(null);
    const [quizStatsAvailable, setQuizStatsAvailable] = useState(null);
    const [inputStats, setInputStats] = useState(null);
    const [inputStatsAvailable, setInputStatsAvailable] = useState(null);

    const [flashcardLoading, setFlashcardLoading] = useState(false);
    const [quizLoading, setQuizLoading] = useState(false);
    const [loading, setLoading] = useState(true);

    const [dailyData, setDailyData] = useState(null);
    const [lineChartData, setLineChartData] = useState(null);
    const [lineChartOptions, setLineChartOptions] = useState(null);
    const [numberOfDays, setNumberOfDays] = useState(7);

    let { getFlashcardStatisticsForCurrentUserInTargetLanguage, getQuizStatisticsForCurrentUserInTargetLanguage,
        getInputStatisticsForCurrentUserInTargetLanguage,
        getXLastProcessedDataInTargetLanguage } = useStatistics();

    useEffect(() => {
        if (activeTargetLanguageOptions !== null) {
            setLanguageOptions(activeTargetLanguageOptions);
        }
    }, [activeTargetLanguageOptions]);

    useEffect(() => {
        const fetchData = async () => {
            let days = await getXLastProcessedDataInTargetLanguage(numberOfDays, globalSelectedTargetLanguage);
            setDailyData(days);
        }
        if (globalSelectedTargetLanguage !== null) {
            fetchData();
        }
    }, [globalSelectedTargetLanguage]);

    useEffect(() => {
        if (dailyData !== null) {
            let labels = dailyData.map((day) => day['doc_id']);
            const d = {
                labels,
                datasets: [
                    {
                        label: 'Correct items',
                        data: dailyData.map((day) => day['total_correct_items']),
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    },
                    {
                        label: 'Goal per day',
                        data: dailyData.map((day) => day['total_correct_items_per_day_goal_today']),
                        borderColor: 'rgb(53, 162, 235)',
                        backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    },
                ],
            };
            setLineChartData(d);
            const o = {
                responsive: true,
               
                maintainAspectRatio: false, 
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: true,
                        font: {
                            size: 22
                        },
                        text: 'My ' + languageOptions[1][globalSelectedTargetLanguage]['label'] + ' statistics',
                    },
                }
            };
            setLineChartOptions(o);
        }
    }, [dailyData]);

    useEffect(() => {
        const fetchFlashcardStats = async () => {
            let data = await getFlashcardStatisticsForCurrentUserInTargetLanguage(globalSelectedTargetLanguage);
            if (data !== null) {
                setFlashcardStats(data);
            } else {
                setFlashcardStatsAvailable(false);
                setLoading(false);
            }
        }
        const fetchQuizStats = async () => {
            let data = await getQuizStatisticsForCurrentUserInTargetLanguage(globalSelectedTargetLanguage);
            if (data !== null) {
                setQuizStats(data);
            } else {
                setQuizStatsAvailable(false);
                setLoading(false);
            }
        }
        const fetchInputStats = async () => {
            let data = await getInputStatisticsForCurrentUserInTargetLanguage(globalSelectedTargetLanguage);
            if (data !== null) {
                setInputStats(data);
            } else {
                setInputStatsAvailable(false);
                setLoading(false);
            }
        }

        if (activeUserLanguages !== null && activeUserLanguages.length > 0) {
            if (globalSelectedTargetLanguage !== null) {
                fetchFlashcardStats();
                fetchQuizStats();
                fetchInputStats();
            }
        } else {
            setLoading(false);
        }

    }, [globalSelectedTargetLanguage, activeUserLanguages]);

    useEffect(() => {
        if (flashcardStats !== null) {
            if ('number_of_answers' in flashcardStats && 'number_of_correct_answers' in flashcardStats && 'number_of_wrong_answers' in flashcardStats) {
                setFlashcardStatsAvailable(true);
            } else {
                setFlashcardStatsAvailable(false);
            }
            setFlashcardLoading(false);
        }
        if (quizStats !== null) {
            if ('number_of_answers' in quizStats && 'number_of_correct_answers' in quizStats && 'number_of_wrong_answers' in quizStats) {
                setQuizStatsAvailable(true);
            } else {
                setQuizStatsAvailable(false);
            }
            setQuizLoading(false);
        }
        if (inputStats !== null) {
            if ('number_of_answers' in inputStats && 'number_of_correct_answers' in inputStats && 'number_of_wrong_answers' in inputStats) {
                setInputStatsAvailable(true);
            } else {
                setInputStatsAvailable(false);
            }
            setQuizLoading(false);
        }
        if (quizStats !== null && flashcardStats !== null && inputStats !== null && activeUserLanguages !== null && globalSelectedTargetLanguage !== null && languageOptions !== null) {
            setLoading(false);
        }

    }, [flashcardStats, quizStats, inputStats, activeUserLanguages, globalSelectedTargetLanguage, languageOptions]);

    const changeSelectedLanguage = (option) => {
        setGlobalSelectedTargetLanguage(option.value);
        if (activeUserLanguages.length > 0) {
            for (const lang of activeUserLanguages) {
                if (lang['target_language'] === option.value) {
                    setGlobalSelectedSourceLanguage(lang['source_language']);
                }
            }
        }
    }

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    );

    return (
        <>
            <Helmet>
                <title>My statistics - Yalango</title>
                <meta name="description" content={"Dive into your language progress with detailed statistics."} />
            </Helmet>
            <div className="  min-h-screen h-full">
                {!loading &&
                    <>
                        {activeUserLanguages !== null && activeUserLanguages.length === 0 ?
                            <>
                                <NoActiveTargetLanguage />
                            </>
                            :
                            <>
                                
                                {(lineChartData !== null && lineChartOptions !== null && (lineChartData['datasets'][0]['data'].length > 0 || lineChartData['datasets'][1]['data'].length > 0)) ?
                                    <>
                                        <div className="w-full h-96 mb-12">
                                            <div className="h-full">
                                                <Line 
                                                options={lineChartOptions} 
                                                data={lineChartData}
                                                />
                                             </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="">
                                            <GeneralInfoMessage gradient={"charcoal"} heading={"You will soon see a graph here"} showMascot={true} text="When you start playing with your decks and vocabulary, you will see a nice little graph of your daily progress here." text2={"Come back to this page after you've started playing to see more statistics."} />
                                        </div>
                                    </>
                                }
                                
                                <h1>All-time statistics</h1>
                                <div className="m-auto grid w-full grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 place-items-center">
                                    <div className="flex w-full flex-col place-items-center rounded-lg shadow-2xl bg-white ">
                                        <div className="text-3xl text-center w-full rounded-lg bg-charcoal p-4 text-white">
                                            Flashcards
                                        </div>
                                        {flashcardStatsAvailable === true &&
                                            <>
                                                <div className="text-3xl grid grid-cols-4">
                                                    <div className="font-bold">
                                                        <Icon hover="none" icon={<FontAwesomeIcon icon={faThumbsUp} />} color="green" />
                                                    </div>
                                                    <div>
                                                        {flashcardStats.number_of_correct_answers}
                                                    </div>
                                                    <div className="font-bold">
                                                        <Icon hover="none" icon={<FontAwesomeIcon icon={faThumbsDown} />} color="red" />
                                                    </div>
                                                    <div>
                                                        {flashcardStats.number_of_wrong_answers}
                                                    </div>
                                                </div>
                                                <div className="text-sm text-gray-700 font-bold text-center p-4">
                                                    {(Math.round((((flashcardStats.number_of_correct_answers / flashcardStats.number_of_answers)) * 100) * 10) / 10)}% correct based on {flashcardStats.number_of_answers} answers.
                                                </div>
                                                <div className="text-xs text-gray-500 text-center p-4">
                                                    See more detailed stats in <Link to="/my-vocabulary">My Vocabulary</Link>.
                                                </div>
                                            </>
                                        }
                                        {flashcardStatsAvailable === false &&
                                            <>
                                                <div className="text-sm text-gray-500 text-center p-4">
                                                    Statistics will show here after you start playing this game.
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="flex w-full flex-col place-items-center rounded-lg shadow-2xl bg-white ">
                                        <div className="text-3xl text-center w-full rounded-lg bg-charcoal p-4 text-white">
                                            Quiz
                                        </div>
                                        {quizStatsAvailable === true &&
                                            <>
                                                <div className="text-3xl grid grid-cols-4">
                                                    <div className="font-bold">
                                                        <Icon hover="none" icon={<FontAwesomeIcon icon={faThumbsUp} />} color="green" />
                                                    </div>
                                                    <div>
                                                        {quizStats.number_of_correct_answers}
                                                    </div>
                                                    <div className="font-bold">
                                                        <Icon hover="none" icon={<FontAwesomeIcon icon={faThumbsDown} />} color="red" />
                                                    </div>
                                                    <div>
                                                        {quizStats.number_of_wrong_answers}
                                                    </div>
                                                </div>
                                                <div className="text-sm text-gray-700 font-bold text-center p-4">
                                                    {(Math.round((((quizStats.number_of_correct_answers / quizStats.number_of_answers)) * 100) * 10) / 10)}% correct based on {quizStats.number_of_answers} answers.
                                                </div>
                                                <div className="text-xs text-gray-500 text-center p-4">
                                                    See more detailed stats in <Link to="/my-vocabulary">My Vocabulary</Link>.
                                                </div>
                                            </>
                                        }
                                        {quizStatsAvailable === false &&
                                            <>
                                                <div className="text-sm text-gray-500 text-center p-4">
                                                    Statistics will show here after you start playing this game.
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="flex w-full flex-col place-items-center rounded-lg shadow-2xl bg-white ">
                                        <div className="text-3xl text-center w-full rounded-lg bg-charcoal p-4 text-white">
                                            Input
                                        </div>
                                        {inputStatsAvailable === true &&
                                            <>
                                                <div className="text-3xl grid grid-cols-4">
                                                    <div className="font-bold">
                                                        <Icon hover="none" icon={<FontAwesomeIcon icon={faThumbsUp} />} color="green" />
                                                    </div>
                                                    <div>
                                                        {inputStats.number_of_correct_answers}
                                                    </div>
                                                    <div className="font-bold">
                                                        <Icon hover="none" icon={<FontAwesomeIcon icon={faThumbsDown} />} color="red" />
                                                    </div>
                                                    <div>
                                                        {inputStats.number_of_wrong_answers}
                                                    </div>
                                                </div>
                                                {inputStats.number_of_answers > 0 &&
                                                    <div className="text-sm text-gray-700 font-bold text-center p-4">
                                                        {(Math.round((((inputStats.number_of_correct_answers / inputStats.number_of_answers)) * 100) * 10) / 10)}% correct based on {inputStats.number_of_answers} answers.
                                                    </div>
                                                }
                                                <div className="text-xs text-gray-500 text-center p-4">
                                                    See more detailed stats in <Link to="/my-vocabulary">My Vocabulary</Link>.
                                                </div>
                                            </>
                                        }
                                        {inputStatsAvailable === false &&
                                            <>
                                                <div className="text-sm text-gray-500 text-center p-4">
                                                    Statistics will show here after you start playing this game.
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </>
                        }
                    </>
                }
            </div>
        </>
    )
}

export default MyStatistics;
