import React, {useState, useEffect} from 'react';
import useComments from '../../hooks/useComments';
import UserComment from './UserComment';
import WriteComment from './WriteComment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-duotone-svg-icons';
import SortByButton from '../../components/general/sorting/SortByButton';
import RenderComments from './RenderComments';
import {useLanguagesContext} from '../../contexts/LanguagesContext';
import Select from 'react-select';

const CommentsFeedComponent = ({language}) => {
    const [loading, setLoading] = useState(false);
    const [sortByValue, setSortByValue] = useState("most_recent");
    const {allLanguages, sourceLanguageOptions} = useLanguagesContext();
    const [languageOptions, setLanguageOptions] = useState(sourceLanguageOptions !== null ? [{label: 'All', value: 'all'},{label: 'General discussions', value: 'general'},...sourceLanguageOptions[0]] : [{label: 'All', value: 'all'}, {label: 'General discussions', value: 'general'}]);
    const [chosenLanguage, setChosenLanguage] = useState(null);
    const handleLanguageChange = (option) => {
        setChosenLanguage(option);
    }

    useEffect(()=>{
        if (sourceLanguageOptions!== null){
            setLanguageOptions(sourceLanguageOptions !== null ? [{label: 'All', value: 'all'}, {label: 'General discussions', value: 'general'}, ...sourceLanguageOptions[0]] : [{label: 'All', value: 'all'}, {label: 'General discussions', value: 'general'}]);
        }
        if (language !== null && language !== undefined){
            if (languageOptions !== null){
                languageOptions.forEach(lang=>{
                    if (lang.label.toLowerCase() === language.toLowerCase()){
                        console.log(lang)
                        setChosenLanguage(lang);
                    } else if (lang.value.toLowerCase() === language.toLowerCase()){
                        //general
                        setChosenLanguage(lang);
                    }
                })
            }
        }
    },[language, languageOptions, sourceLanguageOptions]);



    const {fetchAllComments, 
        fetchAllCommentsInRealtime} = useComments();
    const [comments, setComments] = useState(null);
    const [limitComments, setLimitComments] = useState(25);

    useEffect(()=>{
        if (comments !== null){
            setLoading(false);
        } else {
            setLoading(true);
        }
    },[comments])

    const fetchAllCommentsCall = async () => {
        setLoading(true);
        let c = await fetchAllComments({limit:limitComments, sortByValue: sortByValue});
        setComments(c);
        setLoading(false);
    }

    useEffect(()=>{
        let unsub = null;
        const fetchData = async () => {
            setComments(null);
            unsub = await fetchAllCommentsInRealtime({limit:limitComments, filterLanguage:chosenLanguage.value, sortByValue: sortByValue, setComments: setComments, setLoading: setLoading});            
        }
        if (limitComments !== null && sortByValue !== null && chosenLanguage !== null){
            fetchData();
        }
        return (()=>{
            unsub !== null && unsub();
        })
    },[limitComments, sortByValue, chosenLanguage]);

    const sortByOptions = [
        {label: 'Highest rated', value:'highest_rating'}, 
        {label: 'Most recent', value:'most_recent'}  
    ];
    const [sortByOptionsDict, setSortByOptionsDict] = useState(sortByOptions.reduce(function(result, item) {
        var key = item.value; 
        result[key] = item;
        return result;
      }, {}));

      const orderByChange = async (option) => {
        setSortByValue(option.value);
    }



    const refreshComments = async () => {
        setLoading(true);
        setLoading(false);
    }

    const [showHelp, setShowHelp] = useState(false);


    console.log(comments)
    /*
                    <div className="text-sm text-center text-gray-600">
                        <div className="my-2 underline cursor-pointer text-xl" onClick={()=>setShowHelp(!showHelp)}>
                            How does the feed work?
                        </div>
                        {showHelp &&
                        <>
                        The feed shows posts by users along with comments left on decks and texts. You receive 1 star for each upvote you receive, 
                        so you're rewarded by helping other users when they have questions. If you see a language mistake by a user practicing,
                        you can suggest a correction by clicking on "correct". This way everyone can help each other out. Be kind and respect everyone! 
                        Only language related discussions are allowed - this is not Twitter.
                        </>
                        }
                    </div>
                     <div className="">
                            <div className="font-bold text-gray-600">
                                Language feed
                            </div>
                            <div className="w-full min-w-64">
                                <Select onChange={handleLanguageChange} value={chosenLanguage} isSearchable={false} options={languageOptions !== null ? languageOptions : []} autoFocus={false} placeholder="Language" className=" text-gray-500 text-base" />
                            </div>
                        </div>
    */
    console.log(allLanguages, chosenLanguage)
    return (
        <>
            <div>
                <h1>{(chosenLanguage !== null && chosenLanguage.value !== "all" && chosenLanguage.value !== "general" && allLanguages !== null && allLanguages !== undefined) ? allLanguages[0][chosenLanguage.value]["name"]["en"] + " forums"  : "General discussions"}</h1>
                <div className="flex flex-col gap-3 place-items-center">
                <div className="w-full lg:w-5/6 m-auto">
                    
                </div>
                <div className="w-full min-h-80 lg:w-5/6 m-auto flex flex-col gap-3 place-items-center">

                    <div className="text-sm w-full flex flex-col gap-3 sm:flex-row place-items-center justify-between mb-2 m-4">
                       
                        <div className="">
                            <SortByButton textSize={"sm"} onChange={orderByChange} value={sortByOptionsDict[sortByValue]} options={sortByOptions} />
                        </div>
                    </div>
                    {chosenLanguage !== null &&
                    <WriteComment alreadyChosenLanguage={chosenLanguage} showPostOptions={false} postType={"post"} onSubmitFinished={refreshComments} commentSourceMetadata={null} commentSource={"feed"} sourceId={null} />
                    }
                    <div className="w-full">
                    {comments !== null &&
                        <div className=" w-full">
                            <RenderComments commentLocation={"feed"} loading={loading} comments={comments.filter(comment=>comment.comment_type !== "reply")} refreshComments={refreshComments} />
                        </div>
                    }
                    </div>
                </div>
                </div>
            </div>
        </>
    )
}

export default CommentsFeedComponent;