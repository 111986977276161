import React , {useState} from "react";
import {DebounceInput} from 'react-debounce-input';
import pinyin from "pinyin";
import { Link } from "react-router-dom";

const HanziToPinyinConverterPage = () => {

    const [text, setText] = useState("");   
    const [pinyinText, setPinyinText] = useState("");

    const convertToPinyin = (e) => {
        // convert text to pinyin
        let text_ = e.target.value;
        console.log(text_);
        let pinyin_ = pinyin(text_, {group: true, segment: "segmentit", });
        console.log(pinyin_);
        let pinyinString_ = pinyin_.join(" ");
        console.log(pinyinString_)
        setPinyinText(pinyinString_);
    }

    return (
        <div className="flex flex-col gap-8 min-h-screen pb-24 sm:min-h-0 w-full lg:w-4/6 xl:w-1/2 mx-auto">
            <Link to="/learn/Mandarin-Chinese" className="">Back to Mandarin Chinese</Link>
        <h1>Mandarin Chinese to pinyin converter</h1>
            <div className="flex flex-col gap-4 "> 
                <div className="muted-text font-bold">
                    Chinese text
                </div>
                <DebounceInput
                    className={"w-full h-44 p-4 border outline-none border-gray-300 rounded-md resize-none"}
                    minLength={1}
                    element="textarea"
                    value={text}
                    placeholder="Add a Chinese text here to convert to pinyin..."
                    debounceTimeout={700}
                    onChange={event => convertToPinyin(event)} 
                />
            </div>
            <div className="flex flex-col gap-4"> 
                <div className="muted-text font-bold">
                    Pinyin
                </div>
                <div className="text-lg">
                    {pinyinText}
                </div>
            </div>
        </div>
    );
};

export default HanziToPinyinConverterPage;
