import React, {useState, useEffect} from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {useLanguagesContext} from '../../contexts/LanguagesContext';
import { useAuth } from "../../contexts/AuthContext";
import ReactTooltip from 'react-tooltip';
import YalangoHead from '../../assets/svg/yalango/YalangoHead.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSchool, faChartLine, faSpeaker } from '@fortawesome/pro-duotone-svg-icons';
import CountryFlag from '../../components/languages/CountryFlag';
import {faNote} from '@fortawesome/pro-duotone-svg-icons';
import {faPlay, faChartSimple} from '@fortawesome/pro-duotone-svg-icons';
import {faComments} from '@fortawesome/pro-duotone-svg-icons';
import {faBars, faBooks, faBrain, faBug, faDirections, faInfo, faLanguage, faLeftToLine, faNotes, faQuestion} from '@fortawesome/pro-solid-svg-icons';
import {faTableColumns} from '@fortawesome/pro-duotone-svg-icons';
import {  faCardsBlank } from '@fortawesome/pro-duotone-svg-icons';
import { faCog } from '@fortawesome/pro-duotone-svg-icons';
import { faSignOut } from '@fortawesome/pro-duotone-svg-icons';

import {faBookSparkles} from '@fortawesome/pro-duotone-svg-icons';
import { faSignIn } from '@fortawesome/pro-duotone-svg-icons';
import { faGlobe } from '@fortawesome/pro-duotone-svg-icons';
import { faRobot } from '@fortawesome/pro-duotone-svg-icons';
import { faHeadSideBrain } from '@fortawesome/pro-solid-svg-icons';
import { faUser } from '@fortawesome/pro-duotone-svg-icons';
import WidgetMenu from '../menus/widget-menu/WidgetMenu';
import SimpleNavigationComponent from '../navigation/SimpleNavigationComponent';
import MyLibrarySidebarMenu from '../folders/components/MyLibrarySidebarMenu';

import Icon from './Icon';
import GeneralFeedback from '../feedback/modals/GeneralFeedback';
import TranslatorModal from '../widgets/translator/TranslatorModal';
import QuickNotesModal from '../widgets/quick-notes/QuickNotesModal';

const ComputerMenuItem = (props) => {
    const {currentPath, path, icon, title, color} = props;
    return ( 
        <>
            <Link className="no-underline" to={path}>
                <div className={"flex flex-col gap-1 place-items-center text-charcoal   " + (currentPath === path ? "   underline " : "")}>
                    <div>
                        <Icon icon={<FontAwesomeIcon icon={icon} />} color={color} />
                    </div>
                    <div className="text-sm font-semibold">
                        {title}
                    </div>
                </div>
            </Link>
        </>
    )
}

const ComputerMenu = ({navbarCollapsed, setNavbarCollapsed, toggleLanguageMenu, showLanguageSelector, languageClick, logOutClick, globalSelectedTargetLanguage, setGlobalSelectedTargetLanguage, activeUserLanguages, allLanguages}) => {
    let navigate = useNavigate();
    let location = useLocation();
    let {currentUser} = useAuth();

    const [showMenu, setShowMenu] = useState(true);
    
    let [currentPath, setCurrentPath] = useState(location.pathname);
    useEffect(()=>{
        setCurrentPath(location.pathname);
    },[location, location.pathname]);

    /*
    {currentUser !== null &&
                    <>
                    <div data-for="menu-tooltip" data-tip="My profile" className={currentPath === "/my-profile" ? "border-solid border-b-2 border-blue-500" : ""}>
                        <Link to={"/my-profile"}>
                            <Icon icon={<FontAwesomeIcon icon={faUser} />} color="blue" />
                        </Link>
                    </div>
                    </>
                    }
                    <div data-for="menu-tooltip" data-tip="My vocabulary" className={currentPath === "/my-vocabulary" ? "border-solid border-b-2 border-sandy-brown" : ""}>
                                <Link className="" to="/my-vocabulary">
                                    <Icon icon={<FontAwesomeIcon icon={faHeadSideBrain} />} color="yellow" />
                                </Link>
                            </div>
    */

    const navigateToMyLanguages = () => {
        toggleLanguageMenu();
        navigate("/my-languages");
    }

    /*
                            <div data-for="menu-tooltip" data-tip="My progress" className={currentPath === "/dashboard" ? "border-solid border-b-2 border-charcoal" : ""}>
                                <Link className="" to="/dashboard">
                                    <Icon icon={<FontAwesomeIcon icon={faChartSimple} />} color="charcoal" />
                                </Link>
                            </div>
                            {currentUser !== null &&
                    <div>
                        <ComputerMenuItem path={"/settings"} currentPath={currentPath} icon={faCog} title={"Settings"} />
                    </div>  
                    }

        {currentUser !== null &&
            <div>
                <ComputerMenuItem path={"/decks"} currentPath={currentPath} color={"persian-green"} icon={faGlobe} title={"Explore"} />
            </div>  
        }

        {currentUser !== null &&
            <div>
                <ComputerMenuItem path={"/settings"} currentPath={currentPath} color={"persian-green"} icon={faCog} title={"Settings"} />
            </div>  
        }
    */

    const showMenuClick = ()=> {
        setNavbarCollapsed(false);
        setShowMenu(true); 
        ReactTooltip.rebuild();
    }

    const closeMenuClick = ()=> {
        ReactTooltip.hide();
        setShowMenu(false);
        setNavbarCollapsed(true);
    }

    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [showTranslatorModal, setShowTranslatorModal] = useState(false);
    const [showNotesModal, setShowNotesModal] = useState(false);
    return(
        <>
            <ReactTooltip id="menu-tooltip" place="right" effect="solid" />
            <div className="fixed  w-full z-10 bg-white">
                {showMenu ?
                    <nav className="fixed bg-white h-full  p-4 text-2xl shadow-lg">
                       
                        <div className="h-4/6  flex flex-col justify-center items-center gap-16 ">
                        {currentUser !== null ?
                            <>
                                {(allLanguages !== null && globalSelectedTargetLanguage !== null && activeUserLanguages !== null && activeUserLanguages.length > 1) &&
                            <>
                                
                            </>
                        }
                                <div>
                                    <ComputerMenuItem path={"/dashboard"} currentPath={currentPath} color={"persian-green"} icon={faSchool} title={"Study"} />
                                
                                </div>
                                <div>
                                    <ComputerMenuItem path={"/my-library"} currentPath={currentPath} color={"persian-green"} icon={faBooks} title={"Library"} />
                                
                                </div>
                        
                                <div>
                                    <ComputerMenuItem path={"/my-vocabulary"} currentPath={currentPath} color={"persian-green"} icon={faChartLine} title={"Progress"} />
                                </div>  
                                </>
                            : 
                                <>
                                <div data-for="menu-tooltip" data-tip="Home" className={currentPath === "/" ? "border-solid border-b-2 border-persian-green" : ""}>
                                    <Link className="" to="/">
                                        <Icon icon={<FontAwesomeIcon icon={faHome} />} color="persian-green" />
                                    </Link>
                                </div>
                                <div data-for="menu-tooltip" data-tip="Public decks" className={currentPath === "/decks" ? "border-solid border-b-2 border-burnt-sienna" : ""}>
                                    <Link className="" to="/decks">
                                        <Icon icon={<FontAwesomeIcon icon={faCardsBlank} />} color="red" />
                                    </Link>
                                </div>
                                
                            </>
                        }


                        
                        
                        
                        {currentUser !== null &&
                            <>
                                {(allLanguages !== null && globalSelectedTargetLanguage !== null && activeUserLanguages !== null ) &&
                            
                                    <div  className="relative " >
                                                <div className="cursor-pointer rounded-lg shadow-xl overflow-hidden"  onClick={toggleLanguageMenu}>
                                                    <CountryFlag size="1.3em" animation={false} countryCode={allLanguages[0][globalSelectedTargetLanguage]['country_flag']} flagName={allLanguages[0][globalSelectedTargetLanguage]['name']['en']} />
                                                </div>
                                            {showLanguageSelector &&
                                                <>
                                                    <div className="absolute h-56 overflow-y-auto -top-8 bg-white rounded-lg shadow-lg left-16 w-48 text-base z-30">
                                                        <div className="font-bold text-gray-600 text-base text-left p-3">
                                                            <div className="underline cursor-pointer" onClick={navigateToMyLanguages}>
                                                                My languages
                                                            </div>
                                                        </div>
                                                        {activeUserLanguages.map((lang, index)=>(
                                                            <>
                                                                <div onClick={()=>languageClick(lang)} key={"l_"+index} className={(globalSelectedTargetLanguage === lang['target_language'] ? ' bg-charcoal text-white ' : ' bg-white text-gray-600 hover:bg-persian-green ' )+" p-3 cursor-pointer hover:text-white font-bold  flex flex-row place-items-center gap-2"}>
                                                                    <div className="">
                                                                        <CountryFlag size="1.3em" countryCode={allLanguages[0][lang['target_language']]['country_flag']} flagName={allLanguages[0][lang['target_language']]['name']['en']} />
                                                                    </div>
                                                                    <div>
                                                                        {lang.name.en}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ))}
                                                    </div>
                                                    <div onClick={toggleLanguageMenu} className=" z-20 h-screen w-screen fixed top-0 left-0">

                                                    </div>
                                                </>
                                            }
                                    </div>
                                }
                            </>
                        }
                        {currentUser !== null ?
                            <>
                            
                            </>
                            :
                            <>
                                <Link data-for="menu-tooltip" data-tip="Log in" to="/login">
                                    <Icon icon={<FontAwesomeIcon icon={faSignIn} />} color="charcoal" />
                                </Link>
                            </>
                        }
                        </div>
                        <div className="flex flex-col gap-2  justify-center place-items-center ">
                            <div className="flex text-gray-700 flex-col place-items-center gap-2 text-lg">
                            {currentUser !== null &&
                                <div data-for="menu-tooltip" onClick={()=>setShowNotesModal(true)} data-tip="Sticky notes" className="underline cursor-pointer">
                                    <FontAwesomeIcon icon={faNotes} />
                                </div>
}
                                {currentUser !== null &&
                                <div data-for="menu-tooltip" onClick={()=>setShowTranslatorModal(true)} data-tip="Translator" className="underline cursor-pointer">
                                    <FontAwesomeIcon icon={faLanguage} />
                                </div>
}   
                                <div data-for="menu-tooltip" onClick={()=>setShowFeedbackModal(true)} data-tip="Send feedback" className="underline cursor-pointer">
                                    <FontAwesomeIcon icon={faBug} />
                                </div>
                                <div>
                                    <Link data-for="menu-tooltip" data-tip="Explore" to="/decks">
                                        <FontAwesomeIcon icon={faGlobe} />
                                    </Link>
                                </div>
                                
                                
                                <div>
                                    <Link data-for="menu-tooltip" data-tip="Help center" to="/help">
                                        <FontAwesomeIcon icon={faQuestion} />
                                    </Link>
                                </div>
                                <div>
                                    <Link data-for="menu-tooltip" data-tip="Settings" to="/settings">
                                        <FontAwesomeIcon icon={faCog} />
                                    </Link>
                                </div>
                                {currentUser !== null &&
                                <div  data-for="menu-tooltip" data-tip="Log out" className=" underline cursor-pointer" onClick={logOutClick}>
                                    <FontAwesomeIcon icon={faLeftToLine} />
                                </div>
                                }
                            </div>
                            <div  data-for="menu-tooltip" data-tip="Collapse menu" className="hidden text-lg text-gray-400 cursor-pointer" onClick={closeMenuClick}>
                                <FontAwesomeIcon icon={faLeftToLine} />
                            </div>
                        </div>
                    </nav>    
                    :
                    <div className="absolute left-8 top-8 text-3xl muted-text cursor-pointer" onClick={showMenuClick}>
                        <FontAwesomeIcon icon={faBars} />
                    </div>
                }
                {showFeedbackModal &&
                        <>
                            <GeneralFeedback closeFunction={()=>setShowFeedbackModal(false)} />
                        </>
                }
                {showTranslatorModal &&
                    <>
                        <TranslatorModal closeFunction={()=>setShowTranslatorModal(false)} />
                    </>
                }
                {showNotesModal &&
                    <>
                        <QuickNotesModal closeFunction={()=>setShowNotesModal(false)} />
                    </>
                }
            </div>
        </>
    )
}

const MobileMenu = ({logOutClick, toggleLanguageMenu, showLanguageSelector, languageClick, globalSelectedTargetLanguage, setGlobalSelectedTargetLanguage, activeUserLanguages, allLanguages}) => {
    let navigate = useNavigate();
    let location = useLocation();
    let {currentUser} = useAuth();
    let [currentPath, setCurrentPath] = useState(location.pathname);

    useEffect(()=>{
        setCurrentPath(location.pathname);
    },[location, location.pathname]);

    const [showTimMenu, setShowTimMenu] = useState(false);

    /*
                 <div className="text-base">
                        <WidgetMenu menuDesign="mobile-menu" />
                    </div>
                    <div>
                        <SimpleNavigationComponent />
                    </div>
    */


    const navigateToMyLanguages = () => {
        toggleLanguageMenu();
        navigate("/my-languages");
    }
    /**/

    /*
  <div data-for="menu-tooltip" data-tip="Explore" className={currentPath === "/decks" || currentPath === "/texts" || currentPath === "/feed" ? "border-solid border-b-2 border-beautiful-blue" : ""}>
                        <Link className="" to="/decks">
                            <Icon icon={<FontAwesomeIcon icon={faGlobe} />} color="persian-green" />
                        </Link>
                    </div>
                    {currentUser !== null &&
                    <div>
                        <Link to="/settings" data-for="menu-tooltip" data-tip="Settings">
                            <Icon icon={<FontAwesomeIcon icon={faCog} />} color="persian-green" />
                        </Link>
                    </div>
                    }
    */
    return (
        <>
            <div className="fixed bottom-0 left-0 z-10 w-screen bg-white">
                <nav className="flex flex-row place-items-center flex-grow justify-around p-6 text-2xl shadow-inner">
                    
                    {currentUser === null &&
                        <>
                            <Link to="/login">
                                <Icon icon={<FontAwesomeIcon icon={faSignIn} />} color="persian-green" />
                            </Link>
                        </>
                    }
                    {currentUser !== null &&
                        <div data-for="menu-tooltip" data-tip="Study" className={currentPath === "/dashboard" ? "border-solid border-b-2 border-persian-green" : ""}>
                            <Link className="no-underline flex flex-col place-items-center" to="/dashboard">
                                <Icon icon={<FontAwesomeIcon icon={faSchool} />} color="persian-green" />
                                <div className="text-xs font-semibold">
                                    Study
                                </div>
                            </Link>
                        </div>
                    }
                    {currentUser !== null &&
                        <div data-for="menu-tooltip" data-tip="My library" className={currentPath === "/my-library" ? "border-solid border-b-2 border-persian-green" : ""}>
                            <Link className="no-underline flex flex-col place-items-center" to="/my-library">
                                <Icon icon={<FontAwesomeIcon icon={faBooks} />} color="persian-green" />
                                <div className="text-xs font-semibold">
                                    Library
                                </div>
                            </Link>
                        </div>
                    }
                    
                    
                        <div className={(currentPath === "/" || currentPath === "/my-vocabulary") ? "border-solid border-b-2 border-charcoal" : ""}>
                        <Link className="no-underline flex flex-col place-items-center" to={currentUser !== null ? "/my-vocabulary" : "/"}>
                            <Icon icon={<FontAwesomeIcon icon={faChartLine} />} color="persian-green" />
                            <div className="text-xs font-semibold">
                                Progress
                            </div>
                            </Link>
                        </div>
                 
                  
                    {(allLanguages !== null && globalSelectedTargetLanguage !== null && activeUserLanguages !== null ) &&
                        <>
                            <div className="relative flex flex-col justify-center" >
                                <div className="flex flex-col place-items-center justify-center gap-1 cursor-pointer" onClick={toggleLanguageMenu}>
                                <div className="cursor-pointer rounded-lg shadow-xl overflow-hidden mt-1" >
                                    <CountryFlag showToolTip={false} size="1.3em" countryCode={allLanguages[0][globalSelectedTargetLanguage]['country_flag']} flagName={allLanguages[0][globalSelectedTargetLanguage]['name']['en']} />
                                    
                                </div>
                                <div className="text-xs font-semibold">
                                        Language
                                    </div>
                                </div>
                            {showLanguageSelector &&
                                <>
                                    <div className="absolute w-48 h-72 overflow-y-auto bottom-14 bg-white font-bold rounded-lg shadow-lg -left-32 text-base z-30">
                                        <div className="font-bold text-gray-600 text-base text-left p-3">
                                            <div className="underline cursor-pointer" onClick={navigateToMyLanguages}>
                                                My languages
                                            </div>
                                        </div>
                                        {activeUserLanguages.map((lang, index)=>(
                                            <>
                                                <div onClick={()=>languageClick(lang)} key={"l_"+index} className={(globalSelectedTargetLanguage === lang['target_language'] ? ' bg-charcoal text-white ' : ' bg-white text-gray-600 hover:bg-persian-green hover:font-bold ' )+" p-3 text-center cursor-pointer hover:text-white justify-start flex flex-row place-items-center gap-2"}>
                                                    <div>
                                                        <CountryFlag size="1.3em" countryCode={allLanguages[0][lang['target_language']]['country_flag']} flagName={allLanguages[0][lang['target_language']]['name']['en']} />
                                                    </div>
                                                    <div>
                                                        {lang.name.en}
                                                    </div>
                                                </div>
                                            </>
                                        ))}
                                    </div>
                                    <div onClick={toggleLanguageMenu} className=" z-20 h-screen w-screen fixed top-0 left-0">

                                    </div>
                                </>
                            }
                            </div>
                        </>
                    }

                   
                    
                    
                </nav>
            </div>
        </>
    )
}

function NewNavbar(props) {
    const {navbarCollapsed, setNavbarCollapsed} = props;
    let {logout} = useAuth();
    const navigate = useNavigate();
    let {currentUser} = useAuth();
    const [loading, setLoading] = useState(true);
    const {activeUserLanguages, 
        globalSelectedTargetLanguage, 
        setGlobalSelectedTargetLanguage, 
        allLanguages} = useLanguagesContext();

    const logOutClick = async () => {
        try {
            await logout();
            navigate("/");
          } catch {
              return null;
          };
    }


    const mql = window.matchMedia('(max-width: 640px)');
    let [mobileView, setMobileView] = useState(mql.matches);//

    window.addEventListener('resize', () => {
        let width = window.matchMedia('(max-width: 640px)');
        if (width.matches){
            if (mobileView === false){
                console.log("Mobile");
                setMobileView(true);
            }
        } else {
            if (mobileView === true){
                console.log("Computer")
                setMobileView(false);
            }
        }
        
      });

    useEffect(()=>{
    if (currentUser === null){
        setLoading(false);
    } else {
        if (allLanguages !== null){
            setLoading(false);
        }
    }
    },[activeUserLanguages, allLanguages, currentUser, globalSelectedTargetLanguage])

    const [showLanguageSelector, setShowLanguageSelector] = useState(false);
    const languageClick = (lang) => {
        setShowLanguageSelector(false);
        setGlobalSelectedTargetLanguage(lang['target_language']);
    }

    const toggleLanguageMenu = () => {
    setShowLanguageSelector(!showLanguageSelector);
    }

    return (
        <>
            {!loading &&
                <>
                    {mobileView ? 
                        <>
                        <MobileMenu logOutClick={logOutClick} showLanguageSelector={showLanguageSelector} toggleLanguageMenu={toggleLanguageMenu} languageClick={languageClick} allLanguages={allLanguages} globalSelectedTargetLanguage={globalSelectedTargetLanguage} setGlobalSelectedTargetLanguage={setGlobalSelectedTargetLanguage} activeUserLanguages={activeUserLanguages} />
                        </>
                    :  
                        <>
                        <ComputerMenu navbarCollapsed={navbarCollapsed} setNavbarCollapsed={setNavbarCollapsed} showLanguageSelector={showLanguageSelector} toggleLanguageMenu={toggleLanguageMenu} languageClick={languageClick} allLanguages={allLanguages} globalSelectedTargetLanguage={globalSelectedTargetLanguage} setGlobalSelectedTargetLanguage={setGlobalSelectedTargetLanguage} activeUserLanguages={activeUserLanguages} logOutClick={logOutClick} />
                        </>
                    }
                </>
            }
        </>
    )
}

export default NewNavbar;
