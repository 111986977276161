import React from 'react'

const QuoteBlock = ({align, contentEditable, attributes, children}) => {
    return (
      <>
        <blockquote contentEditable={contentEditable} className={"text-"+align + " border-l-4 ml-6 my-2  border-persian-green border-solid text-lg p-3"} {...attributes}>
          {children}
        </blockquote>
      </>
    )
  }

export default QuoteBlock;