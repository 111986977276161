import React from 'react';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLanguage} from '@fortawesome/pro-duotone-svg-icons';
import YalangoBookSvg from '../../assets/svg/yalango/yalango_book.svg';
import Icon from '../../components/general/Icon';

const Guides = () => {
  return (
    <>
        <div className="  h-full min-h-screen">
            <h1>Guides</h1>
            <div className="grid p-3 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 place-items-center">
                <Link to="/guides/decks" className="no-underline">
                    <div className="group">
                        <div className="relative bg-burnt-sienna transition duration-500 ease-in-out  group-hover:bg-sandy-brown flex flex-col w-64 h-64 place-items-center p-10 rounded-full shadow-lg border-2 bg-white border-gray-200 border-dashed">
                            <div className="group-hover:-rotate-6  transition duration-300 ease-in-out absolute transform -rotate-12 -left-2 text-white -top-2 text-4xl font-bold bg-gradient-to-r from-burnt-sienna to-sandy-brown rounded-lg shadow-lg p-2">
                                Decks
                            </div>
                            <div className="text-9xl">
                                <Icon color="blue" includeOpacityChange={false} hover={"none"} svg={true} icon={YalangoBookSvg} />
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    </>

  )
}

export default Guides;