import React, {useEffect, useState} from 'react';
import { Editable, withReact, useSlate, Slate, useFocused, ReactEditor } from 'slate-react';
import {
    Editor,
    Text, 
    Transforms,
    createEditor,
    Descendant,
    Range,
    Element as SlateElement,
  } from 'slate';
import IconListElement from './IconListElement';
import TextEditor from './TextEditor';
import {faChevronDown} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {motion} from 'framer-motion';

const ListOfButtons = ({buttons, type, TEXT_ALIGN_TYPES}) => {
    const editor = useSlate();
    const [showMenu, setShowMenu] = useState(false);
    const [selectedButton, setSelectedButton] = useState(null);
    useEffect(()=>{
      if (buttons !== null && buttons !== undefined){
        if (type === "block"){
          let selected = false;
          buttons.forEach((button,i)=>{
            let r = TextEditor.isBlockActive(editor, button['format'], TEXT_ALIGN_TYPES.includes(button['format']) ? 'align' : 'type');
            if (r){
              setSelectedButton(button);
              selected = true;
            }
          });
          if (!selected){
            setSelectedButton(buttons[0]);
          }
        }
      }
      return (()=>{
        setShowMenu(false);
      })
    },[buttons, type]);
  
    const selectedOptionClick = () => {
      setShowMenu(!showMenu);
    } 
  
    return (
      <>
        <div className="relative z-10">
        {selectedButton !== null &&
          <>
            <div onClick={selectedOptionClick} className="flex cursor-pointer flex-row gap-1 place-items-center ">
              <IconListElement  showIcon={true} isActivated={false} isSelected={true} type={type} text={selectedButton.text} format={selectedButton.format} icon={selectedButton.icon} />
              <div>
                <FontAwesomeIcon icon={faChevronDown} />
              </div>
            </div>
          </>
        }
        {showMenu &&
        <div className="flex absolute z-20 bg-white rounded-lg shadow-xl mt-4 flex-col gap-1">
          {buttons.map((button, i)=>(
            <>
              {selectedButton !== button &&
                <motion.div whileHover={{scale:1.02}} className="px-4" key={"list_item_"+i}>
                  <IconListElement isActivated={true} isSelected={false} showIcon={true} type={type} text={button.text} format={button.format} icon={button.icon} />
                </motion.div>
              }
            </>
          ))}
        </div>
        }
        </div>
      </>
    )
  }

export default ListOfButtons