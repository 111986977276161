import React, {useState} from 'react';
import Button from '../../general/Button';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/pro-light-svg-icons';
import { faCog } from '@fortawesome/pro-light-svg-icons';

const SectionMenu = ({SectionSettingsModal, nbOfWords, zoomMode, setZoomMode, modalMode, setShowModal, showModal, resetInputData, docs, setDocs, setModalMode, setTargetInput, setSourceInput, targetInput, sourceInput, fetchAllItems, section}) => {

    const [showSettingsModal, setShowSettingsModal] = useState(false);

    const closeModal = () => {
        setShowSettingsModal(false);
    }

    const settingsButtonClick = () => {
        setShowSettingsModal(true);
    }

    return (
        <>
            <h1>{section.name}</h1>
            <div className="horizontal-center" >
                <Button onClick={settingsButtonClick} text={<FontAwesomeIcon icon={faCog} />} type="icon" color="charcoal" />
                <Link to={`/my-decks/section/${section.section_id}/play`}><Button text={<FontAwesomeIcon icon={faPlay} />} type="icon" color="red" /> </Link> 
            </div>  
            {showSettingsModal && 
                <SectionSettingsModal closeFunction={closeModal} section={section} />
            } 
        </> 
    )
}

export default SectionMenu;
