import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-duotone-svg-icons';
import { faCardsBlank } from '@fortawesome/pro-solid-svg-icons';
import { faRobot } from '@fortawesome/pro-solid-svg-icons';
import {Link} from 'react-router-dom';

const CommentBodyCorrectionsRequest = (props) => {
    const {comment} = props;
    return (
        <>
            <div className="flex flex-col gap-4">
                <div className="border-l-4 italic  p-2 border-solid border-persian-green whitespace-normal flex flex-col gap-3">
                    <div className="flex flex-row gap-2  ">
                        <div className="">
                            {comment.hasOwnProperty("comment_text_to_be_corrected_request") && comment.comment_text_to_be_corrected_request}
                        </div>
                    </div>
                </div>
                <div>
                    {comment.hasOwnProperty("comment_additional_message_in_corrections_request") && comment.comment_additional_message_in_corrections_request}
                </div>
            </div>
        </>
    )
}

export default CommentBodyCorrectionsRequest;