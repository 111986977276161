import React, {useEffect, useState} from 'react';
import Button  from '../../components/general/Button';
import ProgressBar  from '../../components/general/progress/ProgressBar'; 
import useStripe from '../../hooks/useStripe';
import useQuotas from '../../hooks/useQuotas';
import { useAuth } from "../../contexts/AuthContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-duotone-svg-icons';
import {faCheck} from '@fortawesome/pro-solid-svg-icons';
import Skeleton from 'react-loading-skeleton';
import YalangoPremium from '../../assets/svg/yalango/YalangoPremium.svg';

const Premium = () => {
    const {createCheckoutSession, 
        getUserSubscriptions, 
        createStripePortalLink} = useStripe();

    const {getNumberOfTranslationsThisMonthFromCurrentUser, 
        getFreeQuotaLimits} = useQuotas();
    let {currentUser, isPremium, checkIfPremiumUser} = useAuth();

    const [loading, setLoading] = useState(false);
    const [subscriptions, setSubscriptions] = useState(null);
    const [numberOfGoogleTranslations, setNumberOfGoogleTranslations] = useState(null);
    const [numberOfGoogleTranslationsLimit, setNumberOfGoogleTranslationsLimit] = useState(null);

    const createCheckoutSessionClick = async () => {
        setLoading(true);
        await createCheckoutSession();
    }
    useEffect(()=>{
        const fetchSubscriptionDetails = async () => {
            let r = await getUserSubscriptions();
            setSubscriptions(r);
            console.log(r);
        }

        const fetchTranslations = async () => {
            let r = await getNumberOfTranslationsThisMonthFromCurrentUser();
            let limits = getFreeQuotaLimits();
            setNumberOfGoogleTranslations(r === null ? false : r);
            if (limits !== null && limits.hasOwnProperty("numberOfGoogleTranslations")){
                setNumberOfGoogleTranslationsLimit(limits['numberOfGoogleTranslations'])
            }
        }

        const doEveryting = async () => {
            let r = await checkIfPremiumUser();
            console.log("Premium: ", r);
            if (r === true){
                await fetchSubscriptionDetails();
            }
            await fetchTranslations();
        }
        doEveryting(); 
    },[]);

    const goToCustomerPortalClick = async () => {
        setLoading(true);
        console.log("portal")
        await createStripePortalLink();
    }

    return (
        <>
            <div className="   text-sm ">    
                <div className="flex flex-col gap-8 p-2 sm:w-4/6 m-auto">
                        <div className="flex flex-row gap-4 justify-center place-items-center">
                            <img src={YalangoPremium} alt="Yalango Premium" className="w-12" />
                            <h1 className="font-bold">Yalango Premium</h1>
                        </div>
                        <div className="text-left">Yalango Premium is a paid version of Yalango that unlocks premium features and increases quota limits on certain features.</div>

                        <div className="flex flex-col place-items-center gap-12 w-full ">
                            <div className="flex flex-col place-items-center w-full gap-3">
                                <h3 className="w-full">Automatic Translations</h3>
                                <div className="text-left w-full">
                                    In order to provide automatic machine translations, we use Google Translate's official API. This is a paid service and we therefore place a limit of 50 translations per month for free users. Upgrade to Premium in order to remove this limit.
                                </div>
                                <div className="flex flex-row justify-center w-full ">
                                    {numberOfGoogleTranslations !== null && numberOfGoogleTranslationsLimit !== null ?
                                        <>
                                            <ProgressBar tooltipData="Automatic Google translations" text="This month" autoCalculate={true} progressNumber={numberOfGoogleTranslations === false ? 0 : numberOfGoogleTranslations.number_of_translations} progressGoal={numberOfGoogleTranslationsLimit} />
                                        </>
                                    :
                                        <>
                                            <div className="w-full">
                                                <Skeleton height={50} count={1} /> 
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="flex flex-col place-items-center w-full gap-3">
                                <h3 className="w-full">Text-To-Speech</h3>
                                <div className="text-left w-full">
                                    Yalango Premium gives you instant access to text-to-speech capabilities for all your decks. This feature uses Google's official Text-To-Speech API to generate the audio files, and is therefore a paid feature. 
                                </div>
                            </div>
                        </div>
                        {isPremium ?
                            <>
                                
                                <div className="flex flex-col gap-4 place-items-center">
                                    
                                    {subscriptions !== null && subscriptions.map((subscription,i)=>(
                                        <>
                                            <div className="md:w-4/6 m-auto w-full flex flex-col gap-4" key={"sub_"+i}>
                                                <h3 className="w-full">Subscription details</h3>
                                                <div className="text-left">
                                                    You currently have an active subscription to Yalango Premium.
                                                </div>
                                                <div className="flex flex-row gap-2 justify-between">
                                                    <div className="text-gray-600 font-bold">
                                                        Subscription started
                                                    </div>
                                                    <div>
                                                        {subscription.created.toDate().toDateString()}
                                                    </div>
                                                </div>
                                                <div className="flex flex-row gap-6 justify-between">
                                                    <div className="text-gray-600 font-bold">
                                                        Price per month
                                                    </div>
                                                    <div>
                                                        ${subscription.items[0].plan.amount/100} {subscription.items[0].plan.currency} 
                                                    </div>
                                                </div>
                                                <div className="flex flex-row gap-6 justify-between">
                                                    <div className="text-gray-600 font-bold">
                                                        Next payment
                                                    </div>
                                                    <div>
                                                        {subscription.current_period_end.toDate().toDateString()}
                                                    </div>
                                                </div>
                                                <div className="text-center">
                                                    Premium is not yet available for purchase.
                                                </div>
                                                <div className="text-center hidden  flex-row justify-center">
                                                    <Button onClick={goToCustomerPortalClick} color="green" text={!loading ? "Manage subscription" : <FontAwesomeIcon icon={faSpinner} className="fa-pulse" />} />
                                                </div>
                                                
                                            </div>
                                        </>
                                    ))}
                                </div>
                            </>
                        :
                            <>
                                <div className="text-center text-xl font-bold">
                                     Premium is not yet available for purchase.
                                </div>
                                <div className="flex hidden flex-col place-items-center">
                                    <Button onClick={createCheckoutSessionClick} text={!loading ? "Upgrade to Premium" : <FontAwesomeIcon icon={faSpinner} className="fa-pulse" />} color="green" />
                                </div>
                            </>
                        }
                </div>
            </div>
        </>
    )
}

export default Premium;