import React, {useEffect, useState} from 'react';
import CountryFlag from '../../components/languages/CountryFlag';
import {Link} from 'react-router-dom';
import {useLanguagesContext} from '../../contexts/LanguagesContext';
import Select from 'react-select';
import {Helmet} from 'react-helmet';
import useQueryStrings from '../../hooks/useQueryStrings';
import FooterMenu from '../../components/menus/FooterMenu';
import {motion} from 'framer-motion';

const AllLanguages = () => {
    const {addAndRemoveQueryStrings, getQueryStrings} = useQueryStrings();
    const {allLanguages, languageFamilyOptions, selectedLanguageFamily, setSelectedLanguageFamily} = useLanguagesContext();
    const [shownLanguages, setShownLanguages] = useState(null);
    const [loading, setLoading] = useState(true);
    const [qsRead, setQsRead] = useState(false);

    const handleFamilyChange = (option) => {
        setSelectedLanguageFamily(option);
        addAndRemoveQueryStrings([{name: 'family', value: option.value.replace(" ", "+")}],[]);
    } 

    useEffect(()=>{
        const fetchData = async () => {
            let qs = await getQueryStrings();
            if ("family" in qs){
                for (const l of languageFamilyOptions){
                    if (l['value'] === qs['family']){
                        setSelectedLanguageFamily(l);
                    }
                }          
            }
            setQsRead(true);
        }
        if (loading && languageFamilyOptions !== null){
            fetchData();
        }
    },[languageFamilyOptions, loading])

    useEffect(()=>{
        if (selectedLanguageFamily !== null && allLanguages !== null && qsRead === true){
             if (selectedLanguageFamily.value === "all"){
                 setShownLanguages(allLanguages);
             } else {
                 let lang_codes = [];
                 let items = allLanguages[0];
                 for (const l of allLanguages[1]){
                    if (selectedLanguageFamily.value === allLanguages[0][l]['language_family']){
                        lang_codes.push(l);
                    }
                 }
                 setShownLanguages([items, lang_codes]);
             }
        }
    },[qsRead, allLanguages, selectedLanguageFamily])

    useEffect(()=> {
        if (allLanguages !== null && selectedLanguageFamily !== null && languageFamilyOptions !== null && qsRead === true){
            setLoading(false);
        }
    },[qsRead, allLanguages, languageFamilyOptions, selectedLanguageFamily])

    return (
        <>
            <Helmet>
                <title>All languages - Yalango</title>
                <meta name="description" content={"Browse through all of the supported languages on Yalango."} />
            </Helmet>
            <div className="">
            <div className=" ">
                <h1>Our languages</h1>
                {!loading  &&
                <>
                <div className="w-5/6 m-auto flex flex-col sm:flex-row gap-3 flex-grow justify-center place-items-center">
                    <div className="w-full flex flex-col flex-grow">
                        <div className="font-bold text-gray-600">
                            Language family
                        </div>
                        <div>    
                            <Select onChange={handleFamilyChange} value={selectedLanguageFamily} options={languageFamilyOptions} autoFocus={false} placeholder="Language family" className=" text-gray-500 text-base" />
                        </div>
                    </div>
                    <div className="w-full flex flex-col flex-grow">
                        <div className="font-bold text-gray-600">
                            
                        </div>
                        <div>    
                            
                        </div>
                    </div>
                    <div className="w-full flex flex-col flex-grow">
                        <div className="font-bold text-gray-600">
                            
                        </div>
                        <div>    
                            
                        </div>
                    </div>
                </div>
                {shownLanguages !== null &&
                    <div className="px-6  text-gray-700 mt-4 text-sm">
                        Showing {shownLanguages[1].length} {shownLanguages[1].length === 1 ? <>language</> : <>languages</>}
                    </div>
                }
                <div className="grid grid-cols-2 md:grid-cols-5">
                    {shownLanguages !== null && shownLanguages[1].map((lang)=>(
                        <>
                            
                            <div className="flex flex-col gap-2 place-items-center">
                                <Link className="no-underline" to={'/learn/'+shownLanguages[0][lang]['name']['en'].replace(' ', '-')}> 
                                    <motion.div whileHover={{y:-2}} className="flex flex-col h-full justify-center gap-2">
                                        <div className="h-24 rounded-xl shadow-xl overflow-hidden flex flex-col justify-center">
                                            <CountryFlag showToolTip={false} size="10em" animation={false} countryCode={shownLanguages[0][lang]['country_flag']} flagName={shownLanguages[0][lang]['name']['en']} />
                                        </div>
                                        <div className="text-center text-gray-600 italic text-sm">
                                                {shownLanguages[0][lang]['name']['en']}
                                        </div>
                                    </motion.div>
                                </Link>
                            </div>
                        </>
                    ))
                    }
                </div>
                </>
                }
            </div>
            
            </div>
        </>
    )
}

export default AllLanguages;
