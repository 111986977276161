import React, {useState} from 'react';
import Button from '../../general/Button';
import Modal from '../../general/Modal';
import Select from 'react-select';
import {useLanguagesContext} from '../../../contexts/LanguagesContext';
import useNotes from '../../../hooks/useNotes';
import SimpleErrorBar from '../../general/SimpleErrorBar';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLoader} from '@fortawesome/pro-solid-svg-icons';

const ModalContent = ({closeFunction, newNoteError, saveNewNoteClick, saveNewNote, newNoteSaving, targetLanguage, newNoteSourceLanguageChange, newNoteName, newNoteNameChange, allLanguages, sourceLanguageOptions, sourceLanguage}) => {

    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const tableNameKeyDown = (e) => {
        if (e.keyCode === 13) {
            saveNewNote();
        } else {
            if (error === true){
                setError(false);
                setErrorMessage("");
            }
        }
    }

    const saveClick = () => {
        if (newNoteName === ""){
            setError(true);
            setErrorMessage("Please choose a name for your new note.");
            return;
        }
        saveNewNoteClick();
    }

    return (
        <>
            <div className={(newNoteSaving ? 'opacity-30 relative' : '')}>
                {newNoteSaving && 
                    <>
                        <div className="text-center z-10 text-4xl absolute left-1/2 top-1/2 text-black">
                            <FontAwesomeIcon icon={faLoader} className="fa-spin" />
                        </div>
                    </>
                }
                <h1>Create a new {allLanguages[0][targetLanguage]['name']['en']} note</h1>  
                    <div className="flex flex-col gap-4">
                        <div>
                            <div className="font-bold text-gray-600">
                                Name of the note
                            </div>
                            <div className="text-center">
                                <input value={newNoteName} onKeyDown={tableNameKeyDown} onChange={newNoteNameChange} type="text" className={(error === true ? 'border-red-500 ' : '' ) + "bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white"} />
                            </div>
                            {error === true &&
                                <>
                                    <SimpleErrorBar message={errorMessage} />
                                </>
                            }
                        </div>
                        <div>
                            <div className="font-bold text-gray-600">
                                Source language
                            </div>
                            <div>    
                                <Select onChange={newNoteSourceLanguageChange} isSearchable={true} options={sourceLanguageOptions[0]} value={sourceLanguageOptions[1][sourceLanguage]}  />
                            </div>
                        </div>
                        <div>
                            {newNoteError && 
                                <SimpleErrorBar message={"Could not save your note. Please try again."} />
                            }
                        </div>
                        <div className="flex flex-row justify-center gap-3">
                            <div>
                                <Button onClick={closeFunction} color="charcoal" text="Cancel" />
                            </div>
                            <div>
                                <Button onClick={saveClick} color="green" text="Create note" />
                            </div>
                        </div>
                    </div>
                    
            </div>
        </>
    )
}
const AddNoteModal = ({closeFunction, newNoteError, saveNewNoteClick, targetLanguage,saveNewNote, newNoteSaving, newNoteSourceLanguageChange, newNoteName, newNoteNameChange, allLanguages, sourceLanguageOptions, sourceLanguage}) => {
  return (
    <>
        <Modal overflow="overflow-visible" closeFunction={closeFunction} content={<ModalContent saveNewNoteClick={saveNewNoteClick} newNoteError={newNoteError} newNoteSaving={newNoteSaving} saveNewNote={saveNewNote} targetLanguage={targetLanguage} newNoteSourceLanguageChange={newNoteSourceLanguageChange} closeFunction={closeFunction} newNoteName={newNoteName} newNoteNameChange={newNoteNameChange} allLanguages={allLanguages} sourceLanguageOptions={sourceLanguageOptions} sourceLanguage={sourceLanguage} />} size="3xl" />
    </>
    )
};

export default AddNoteModal;
