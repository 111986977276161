import React, {useState, useEffect} from 'react';
import WriteComment from './WriteComment';
import UserComment from './UserComment';
import useComments from '../../hooks/useComments';
import RenderComments from './RenderComments';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-duotone-svg-icons';
import SortByButton from '../../components/general/sorting/SortByButton';

const CommentSection = ({sourceId, commentSourceMetadata, commentSource}) => {
    const {fetchCommentsFromSourceId, 
        deleteComment} = useComments();
    const [comments, setComments] = useState(null);
    const [loading, setLoading] = useState(false);
    const [sortByValue, setSortByValue] = useState("most_recent");

    const refreshComments = async () => {
        await fetchAllComments();
    }

    const fetchAllComments = async () => {
        setLoading(true);
        let coms = await fetchCommentsFromSourceId({source_id:sourceId, sortByValue: sortByValue}).catch(err=>console.log(err));
        console.log("New order: ", coms);
        setComments(coms);
        setLoading(false);
    }

    useEffect(()=>{
        const fetchData = async () => {
            await fetchAllComments();
        }
        if (sourceId !== null && sortByValue !== null){
            fetchData();
        }
    },[sourceId, sortByValue]);

    const sortByOptions = [
        {label: 'Highest rated', value:'highest_rating'}, 
        {label: 'Most recent', value:'most_recent'}  
    ];
    const [sortByOptionsDict, setSortByOptionsDict] = useState(sortByOptions.reduce(function(result, item) {
        var key = item.value; 
        result[key] = item;
        return result;
      }, {}));

      const orderByChange = async (option) => {
        setSortByValue(option.value);
        //setMustRefreshCards(true);
        //fetchCardsFunction(searchTerm, currentPage, option.value);
    }

    return (
        <>
            <h2>Comments ({comments !== null ? comments.length : 0})</h2>
            <div className="flex flex-col gap-3 place-items-center">
                <div className="w-full lg:w-5/6">
                    <WriteComment postType={"comment"} onSubmitFinished={refreshComments} commentSourceMetadata={commentSourceMetadata} commentSource={commentSource} sourceId={sourceId} />
                </div>
                <div className="w-full min-h-80 lg:w-5/6 flex flex-col gap-3 ">
                    {comments !== null && comments.length > 0 &&
                        <div className="text-sm w-full flex flex-row justify-end mb-2 m-4">
                            <SortByButton textSize={"sm"} onChange={orderByChange} value={sortByOptionsDict[sortByValue]} options={sortByOptions} />
                        </div>
                    }
                    <div>
                        <RenderComments loading={loading} comments={comments} refreshComments={refreshComments} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default CommentSection;