import React, {useState} from 'react';
import useFriends from '../../hooks/useFriends';
import Button from '../../components/general/Button';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPersonCirclePlus} from '@fortawesome/pro-solid-svg-icons';

const MyFriends = () => {
    const {searchPublicUsernames} = useFriends();
    const [username, setUsername] = useState("");
    const [results, setResults] = useState(null);

    const searchClick = async () => {
        if (username !== ""){
            let r = await searchPublicUsernames(username);
            setResults(r);
        }
    }

    return (
        <>
            <div className="  bg-gray-50">
                <h1>My friends</h1>
                <div className="flex flex-col gap-4 place-items-center">
                    <p className="text-center text-sm">Search for a username.</p>
                    <div className="flex flex-row gap-2 justify-center place-items-center font-bold text-gray-700">
                        <div>
                            @
                        </div>
                        <div>
                            <input onChange={(e)=>setUsername(e.target.value)} type="text" className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white" />
                        </div>
                    </div>
                    <div className="text-center">
                        <Button text="Search" onClick={searchClick} />
                    </div>
                </div>
                <div className="my-6">
                    {results !== null &&
                        <>
                            <div className="flex flex-col gap-2 place-items-center">
                                {results.map((user, index)=>(
                                    <>
                                        <div key={"user_"+index} className="p-6 bg-white rounded-lg shadow-xl flex flex-row gap-8 justify-center place-items-center">
                                            <div>
                                                <Link to={"/u/"+user.username}>{user.displayname}</Link>
                                            </div>
                                            <div className="text-xl">
                                                <FontAwesomeIcon icon={faPersonCirclePlus} /> Add to friends
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
    )
}

export default MyFriends;