import React from 'react';
import EmailVerification from '../../components/settings/EmailVerification';
import YalangoComputer from '../../assets/svg/yalango/yalango_computer.svg';
import {Link, useNavigate} from 'react-router-dom';
import { useAuth } from "../../contexts/AuthContext";
import Button from '../../components/general/Button';
import {Helmet} from 'react-helmet';

const EmailVerificationPage = () => {
    /*
            <div>
                <img src={YalangoComputer} alt="Tim from Yalango on the computer." className="w-48 m-auto" />
            </div>
    */
    let {logout} = useAuth();
    const navigate = useNavigate();
    const logOutClick = async () => {
        try {
            await logout();
            //console.log("success");
            navigate("/login");
          } catch {
            //console.log("Failed to log out");
          };
    }
  return (
    <>
        <div className="  flex flex-col place-items-center gap-8">
            <h1>Please verify your email address</h1>
            <div>
                <EmailVerification />
            </div>
            <div>
                <div className="text-sm text-center">
                    You can also log out and do the email verification later if you prefer.
                </div>
                <div className="text-center flex flex-col place-items-center">
                    <Button text="Log out" color="bg-gray-600" onClick={logOutClick} />
                </div>
            </div>
        </div>
    </>
  )
}

export default EmailVerificationPage;