import React, {useEffect, useState} from 'react';
import Button from '../../../components/general/Button';
import { CSVLink } from "react-csv";
import {useParams} from "react-router-dom";
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTableColumns} from '@fortawesome/pro-solid-svg-icons';
import {faKeyboard} from '@fortawesome/pro-solid-svg-icons';
import useDecks from '../../../hooks/useDecks';
import {Link} from 'react-router-dom';
import Quizlet from '../../../assets/svg/logos/quizlet.svg';
import Excel from '../../../assets/svg/logos/excel.svg';
import {motion} from 'framer-motion';
import ImportDeck from '../../../components/decks/ImportDeck';


const ImportDeckPage = () => {
    let { id } = useParams();
    id = parseInt(id);

    return (
        <>
            <div className=" ">
                <div className="text-xs">
                    <Link to={"/my-decks/"+id}>Back to deck</Link>
                </div>
                <h1>Bulk upload options</h1>
                <ImportDeck id={id} />
                
            </div>
        </>
    )
}

export default ImportDeckPage;