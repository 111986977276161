import React, {useState, useEffect} from 'react';
import {useParams} from "react-router-dom";
import AwaitSectionCardGrid from '../components/vocabulary/cards/AwaitSectionCardGrid';
import {projectFirestore} from '../firebase/config.js';
import { useAuth } from "../contexts/AuthContext";

function DeckSection() {
    let { id } = useParams();
    const section_id = parseInt(id);
    const { currentUser } = useAuth();

    const [deckIds, setDeckIds] = useState([]);
    const [section, setSection] = useState({});

    const [loadingFinished, setLoadingFinished] = useState(false);
    const [allDecksFound, setAllDecksFound] = useState(false);
    const [allCategoriesFound, setAllCategoriesFound] = useState(false);
    const [categoryLoopFinished, setCategoryLoopFinished] = useState(false);

    useEffect(() => {
        findEverything();
    }, []);

    const findEverything = async () => {
        let category_ids = await asyncFindCategoryIds(section_id);
        let deck_ids = await asyncFindDeckIds(category_ids);
        findSection(section_id);
        setDeckIds(deck_ids);
        setLoadingFinished(true);
    }

    const asyncFindDeckIds = async (category_ids) => {
        const collectionRef = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('decks').collection('categories'); 
        let id_list = [];
        for await (const category_id of category_ids){
            const docRef = collectionRef.where('category_id', '==', category_id);
            let ids = await docRef.get().then(snapshots => {
                //console.log(snapshots.docs[0]);
                if (snapshots.size === 1){
                    if (snapshots.docs[0].data()['decks'] !== undefined){
                        return snapshots.docs[0].data()['decks']['id']; 
                    }
                    else {
                        return [];
                    };           
                };
            });
            id_list = [...id_list, ...ids];
        };

        return id_list;
    }; 

    const asyncFindCategoryIds = async () => {
        const collectionRef = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('decks').collection('categories'); 
        const docRef = collectionRef.where('section_id', '==', section_id);
        let category_ids = await docRef.get().then(snapshots => {
            let c_list = [];
            //console.log("SNAPSHOTS: ", snapshots.docs);
            for (const snapshot of snapshots.docs){
                //console.log(snapshot);
                let cat_id = snapshot.data()['category_id'];
                //console.log(cat_id);
                c_list.push(cat_id);
            }
            return c_list;
        });
        return category_ids;
    }

  /*   useEffect(() => {
        //console.log("All categories found: ", allCategoriesFound);
        if (categoryIds !== undefined) {
            //console.log("All categories: ", categoryIds);
            let counter = 1;
            let last_element = false;
            for (const c_id of categoryIds){
                //console.log("Starting category loop for: ", c_id);
                //console.log("Length: ", categoryIds.length);
                if (counter === categoryIds.length){
                    last_element = true;
                };
                findDeckIds(c_id, last_element);
                counter++;
            };    
        };
    }, [allCategoriesFound]); */
/* 
    useEffect(() => {
        if (allDecksFound) {
            //console.log("FINAL updated deck ids:", deckIds, allDecksFound);
        }  
    }, [allDecksFound]);

    useEffect(() => {
        // check if loop is finished and set setAllCategoriesFound(true)
        if (categoryLoopFinished){
            setAllCategoriesFound(true);
        }
    }, [categoryIds]); */

    const findSection = async (section_id) => {
        const collectionRef = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('decks').collection('sections'); 
        const docRef = collectionRef.where('section_id', '==', section_id);
        let data = docRef.onSnapshot(snapshots => {
            if (snapshots.size === 1){
                setSection(snapshots.docs[0].data());
            };
        });
    };

 /*    const findCategoryIds = (section_id) => {
        const collectionRef = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('decks').collection('categories'); 
        const docRef = collectionRef.where('section_id', '==', section_id);
        docRef.get().then(snapshots => {
            //console.log("Length: ", snapshots.size);
            let counter = 1;
            let loop_finished = false;
            snapshots.forEach(snapshot => {
                let cat_id = snapshot.data()['category_id'];
                setCategoryIds([...categoryIds, cat_id]);
                if (counter === snapshots.size){
                    loop_finished = true;
                }
                else {
                    counter++;
                }
            });
            if (loop_finished){
                setCategoryLoopFinished(true);
            };
        });
        
    } */
    /* const findDeckIds = (category_id, last_element) => {
        const collectionRef = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('decks').collection('categories'); 
        const docRef = collectionRef.where('category_id', '==', category_id);
        docRef.get().then(snapshots => {
            if (snapshots.size === 1){
                snapshots.forEach(snapshot => {
                    const ids = snapshot.data()['decks']['id'];
                    setDeckIds([...deckIds, ...ids]);    
                    if (last_element){
                        setAllDecksFound(true);
                    }
                });
            };
        });
    };  */

    return (
        <>
            <div className="bg-gray-100">
                <div className=" ">
                    {loadingFinished === true &&
                        <AwaitSectionCardGrid deckIds={deckIds} section={section} />
                    }
                </div>
            </div>
        </>
    )
}

export default DeckSection;
