import React, {useEffect, useState} from 'react';
import useTables from '../../../hooks/useTables';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlay} from '@fortawesome/pro-solid-svg-icons';
import {faXmark} from '@fortawesome/pro-solid-svg-icons';
import Skeleton from 'react-loading-skeleton';
import FillInGameScreen from '../../tables/games/fill-in/FillInGameScreen';
import TableFlashcardsGame from '../../tables/games/flashcards/TableFlashcardsGame';
import {useMyContentContext} from '../../../contexts/MyContentContext';
import MyTablePlay from '../../../pages/tables/mytables/MyTablePlay';
import TableGame from '../../../pages/tables/games/TableGame';
import TablePlayOptions from '../../../components/tables/games/general/TablePlayOptions';

const TableWidget = ({tableId, includePlay, showName, numberOfPreviewRows}) => {
    const {getTableRowsFromCurrentUser, 
        getTableColumnsFromCurrentUser} = useTables();
    const {queriedTableRows,
        setQueriedTableRows, 
        queriedTableColumns,
        setQueriedTableColumns, 
        tables} 
    = useMyContentContext();
    const [table, setTable] = useState(null);
    const [rows, setRows] = useState(null);
    const [columns, setColumns] = useState(null);
    const [chosenRows, setChosenRows] = useState(null);
    const [loading, setLoading] = useState(true);

    const [playMode, setPlayMode] = useState(false);
    const [playScreen, setPlayScreen] = useState("options");

    const [error, setError] = useState(false);
    const [toggleFullView, setToggleFullView] = useState(false);

    useEffect(()=>{
        const fetchData = async () => {
/*             let r = await getTableAndContentFromCurrentUser(tableId).catch(()=>setError(true));
            if (r !== null && r !== undefined){
                if ('table' in r){
                    setTable(r['table']);
                } else {
                    setError(true);
                }
                if ('columns' in r){
                    setColumns(r['columns']);
                } else {
                    setError(true);
                }
                if ('rows' in r){
                    setRows(r['rows']);
                } else {
                    setError(true);
                }
            } */
            for (const t of tables){
                if (t.id === tableId){
                    setTable(t);
                    if (queriedTableRows.hasOwnProperty(tableId)){
                        console.log("Found queried rows: ", queriedTableRows[tableId]);
                        setRows(queriedTableRows[tableId]['rows']);
                    } else {
                        let r_rows = await getTableRowsFromCurrentUser(t);
                        console.log("Rows: ", r_rows);
                        setRows(r_rows);
                        let copy = JSON.parse(JSON.stringify(queriedTableRows));
                        copy[t.id] = {'rows': r_rows};
                        setQueriedTableRows(copy);
                    }

                    if (queriedTableColumns.hasOwnProperty(tableId)){
                        console.log("Found queried columns: ", queriedTableColumns[tableId]);
                        setColumns(queriedTableColumns[tableId]['columns']);
                    } else {
                        let r_cols = await getTableColumnsFromCurrentUser(t);
                        console.log("Columns: ", r_cols);
                        setColumns(r_cols);
                        let copy = JSON.parse(JSON.stringify(queriedTableColumns));
                        copy[t.id] = {'columns': r_cols};
                        setQueriedTableColumns(copy);
                    }

                }
            }         
            setLoading(false);
        }
        if (tableId !== null && tables !== null){
            setError(false);
            fetchData();
        }
    },[tableId, tables, queriedTableRows, queriedTableColumns]);

    
    const howManyItemsOnChange = (option)=>{setHowManyItems(option)};
    const [numberOfItemsSetting, setNumberOfItemsSetting] = useState([
        {'label': '5', 'value': 5}, 
        {'label': '10', 'value': 10}, 
        {'label': '25', 'value': 25}, 
        {'label': '50', 'value': 50},
        {'label': '75', 'value': 75}, 
        {'label': '100', 'value': 100}, 
        {'label': '150', 'value': 150}, 
        {'label': '200', 'value': 200}]);
    const [howManyItems, setHowManyItems] = useState(numberOfItemsSetting[0]);
    const [highlightColumnSetting, setHighlightColumnSetting] = useState(null);
    const [highlightColumnSettingDict, setHighlightColumnSettingDict] = useState(null);
    const [highlightColumnId, setHighlightColumnId] = useState(null);
    const highlightOnChange = (option) => {setHighlightColumnId(option.value);}

    useEffect(()=>{
        const setOptionsForHighlight = (cols) => {
            let list = [];
            let d = {};
            cols.forEach(col=>{
                list.push({
                    'label': col.name,
                    'value': col.id
                });
                d[parseInt(col.id)] = {
                    'label': col.name,
                    'value': col.id
                };
            });
            setHighlightColumnSetting(list);
            setHighlightColumnSettingDict(d);
            setHighlightColumnId(list[0].value);
        }

        if (columns !== null){
            setOptionsForHighlight(columns);
        };
    },[columns]);

    const shuffleArray = (a) => {
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
    }

    const chooseRandomItemsInList = (allItems, howManyItems) => {
        let chosenItems = [];
        let increasingNumbers = [...Array(allItems.length).keys()];
        let shuffledNumbers = shuffleArray(increasingNumbers);
        let counter = 0;
        for (const n of shuffledNumbers){
          if (counter < howManyItems) {
            chosenItems.push(allItems[n]);
          }
          counter = counter + 1;
        }
        return chosenItems;  
    }

    const customGameClick = (game) => {
        let chosen = chooseRandomItemsInList(rows, howManyItems.value);
        setChosenRows(chosen);
        setPlayScreen(game);
    }

    const playAgainClick = () => {
        let chosen = chooseRandomItemsInList(rows, howManyItems.value);
        setChosenRows(chosen);
    }

    const startEndGameClick = () => {
        if (playMode){
            setPlayScreen("options");
        }
        setPlayMode(!playMode);
    }

    return (
        <>
            <div className="relative">
            {showName &&
                <div className="text-lg text-center font-bold text-gray-600 p-2">{table !== null && table.name}</div>
            }
            {includePlay &&
                <div  className="flex flex-row justify-end  p-2 ">
                    <div className="flex cursor-pointer flex-row place-items-center gap-2" onClick={startEndGameClick}>
                    <div className="text-gray-600 font-bold">
                        {playMode ? "End game" : "Practice"}
                    </div>
                    <div className={playMode ? "text-charcoal" : "text-persian-green"}>
                        <FontAwesomeIcon icon={playMode ? faXmark : faPlay} />
                    </div>
                    </div>
                </div>
            }
            </div>
            {(rows !== null && rows.length === 0 && columns !== null && columns.length === 0) &&
                <>
                    <div className="text-center italic">
                        Table with id <span className="font-bold text-gray-600">{table.id}</span> is missing both columns and rows.
                    </div>
                </>
            }
            {error &&
                <>
                    <div className="text-center italic">
                        Table with id <span className="font-bold text-gray-600">{tableId}</span> not found.
                    </div>
                </>
            }
            {loading &&
                <>
                    <Skeleton count={3} height={50} />
                </>
            }
            {playMode &&
                <>
                    {(table !== null && columns !== null && rows !== null) &&
                        <>
                            {playScreen === "options" &&
                                <div>
                                    <TablePlayOptions howManyItems={howManyItems} howManyItemsOnChange={howManyItemsOnChange}
                                        numberOfItemsSetting={numberOfItemsSetting} 
                                        highlightColumnSetting={highlightColumnSetting} 
                                        highlightColumnSettingDict={highlightColumnSettingDict} 
                                        highlightColumnId={highlightColumnId} 
                                        highlightOnChange={highlightOnChange} 
                                        designMode={"custom"}
                                        customGameClick={customGameClick}
                                    />
                                </div>
                            }
                            {playScreen === "flashcards" &&
                                <>
                                    <div>
                                        <TableGame 
                                           alreadyFetchedData={true}
                                           game="flashcards"
                                           designMode="custom"
                                           hideInstructions={true}
                                           chosenHowManyItems={howManyItems} 
                                           chosenTable={table} 
                                           chosenTableColumns={columns} 
                                           chosenTableRows={chosenRows}
                                           chosenPlayAgain={playAgainClick}
                                           chosenHighlightColumnId={highlightColumnId} 
                                           chosenBackToGamesFunction={startEndGameClick}
                                        />
                                    </div>
                                </>
                            }
                            {playScreen === "fill-in" &&
                                <>
                                    <div>
                                        {chosenRows !== null &&
                                            <FillInGameScreen howManyItems={howManyItems} table={table} tableColumns={columns} tableRows={chosenRows} hideTitle={true} hideInstructions={true} />
                                        }
                                    </div>
                                </>
                            }
                        </>
                    }
                </>
            }
            {!playMode &&
                <>
                    <div className="overflow-x-auto shadow-xl flex flex-col gap-4 p-2">
                    <table className={"w-full lg:table-fixed border-collapse "}>
                        <thead className=" text-white font-bold bg-persian-green">
                            <tr>
                                {columns !== null && columns.map((col, index)=>(
                                    <>
                                            <th key={"head_"+index} className="p-3 w-36 whitespace-nowrap  py-4">
                                                {col.name}
                                            </th>
                                    </>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {rows !== null && rows.map((row, rowIndex)=>(
                                <>
                                    {(rowIndex < numberOfPreviewRows || toggleFullView) &&
                                        <tr key={"tr_"+rowIndex}>
                                            {columns !== null && columns.map((col, colIndex)=>(
                                                <>
                                                    <td className="p-5 w-36 overflow-x-auto whitespace-nowrap  text-center">
                                                        {col.id in row['values'] ? row['values'][col.id] : ''}
                                                    </td>
                                                </>
                                            ))}
                                        </tr>
                                    }

                                </>
                            ))}
                            
                        </tbody>
                    </table>
                    {(rows !== null && rows.length > numberOfPreviewRows) &&
                        <>
                            <div onClick={()=>setToggleFullView(!toggleFullView)} className="text-center text-sm text-gray-600 cursor-pointer">
                                {toggleFullView ? "Minimize" : "Show all rows"}
                            </div>
                        </>
                    }
                    </div>
                </>
            }
        </>
    )
}

export default TableWidget;