import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-duotone-svg-icons';
import { faCardsBlank } from '@fortawesome/pro-solid-svg-icons';
import { faRobot } from '@fortawesome/pro-solid-svg-icons';
import {Link} from 'react-router-dom';

const CommentBodyCorrections = (props) => {
    const {comment} = props;
    return (
        <>
             <div className="flex flex-col gap-4">
                <div className="md:ml-6 whitespace-normal flex flex-col gap-3">
                    <div className="flex flex-row gap-2 p-3 rounded-lg shadow-lg text-burnt-sienna bg-red-50">
                        <div className="font-bold ">
                            Original:
                        </div>
                        <div>
                            {comment.comment_correction_original}
                        </div>
                    </div>
                    <div className="flex flex-row gap-2  p-3 rounded-lg shadow-lg text-persian-green bg-green-50">
                        <div className="font-bold ">
                            Correction:
                        </div>
                        <div>
                            {comment.comment_correction_submitted}
                        </div>
                    </div>
                </div>
                <div>
                    {comment.comment_correction_explanation}
                </div>
            </div>
        </>
    )
}

export default CommentBodyCorrections;