import React, { useEffect, useState } from 'react';
import YalangoComputer from '../../../../assets/svg/yalango/yalango_computer.svg';

const LoadingGame = ({headline, subtext, loading, showLoading, setShowLoading}) => {
    const [timeStart, setTimeStart] = useState(Date.now());
    const [minimumTime, setMinimumTime] = useState(1000);
    const [logoLoaded, setLogoLoaded] = useState(true); //doesnt work
    
    useEffect(()=>{
        const calculateTimePassed = () => {
            let timeNow = Date.now();
            let diff = timeNow - timeStart;
            if (diff < minimumTime){
                setTimeout(()=>{
                    setShowLoading(false);
                },[minimumTime-diff])
            } else {
                setShowLoading(false);
            }
        }
        if (!loading){
            console.log("Calculating");
            calculateTimePassed();
        } else {
            setTimeStart(Date.now());
        }
    },[loading, timeStart, minimumTime]);

    return (
        <>
            {showLoading &&
                <>
                    <div className="max-h-screen h-full  w-full flex flex-col justify-center">
                        {logoLoaded &&
                            <>
                                <div className="">
                                    <img src={YalangoComputer} alt="Yalango mascot happy" className="w-4/6 m-auto p-4 sm:w-64 mx-auto my-0" />
                                </div>
                                <div className=" flex flex-col justify-center place-items-center text-center gap-4">
                                    <div className="text-2xl text-gray-700 font-bold">
                                        {headline === undefined ?
                                            <>
                                                Loading...
                                            </>
                                        :
                                            <>
                                                {headline}
                                            </>
                                        }
                                    </div>
                                    <div>
                                        {subtext === undefined ?
                                            <>
                                                Tim is doing his best to create meaningful exercises for you.
                                            </>
                                        :
                                            <>
                                                {subtext}
                                            </>
                                        }
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </>
            }
        </>
    )
}

export default LoadingGame