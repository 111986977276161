import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircleCheck,  faDiamond, faCircleExclamation, faBarsProgress } from '@fortawesome/pro-duotone-svg-icons';

const ScoreBar = (props) => {
    const {score, nbOfAnswers, cardsLeft} = props;
    /*
                <div className="flex flex-row gap-2 place-items-center"> 
                    <div className="text-burnt-sienna">
                        <FontAwesomeIcon icon={faCircleExclamation} />
                    </div>
                    <div className="font-bold muted-text">
                        {score}
                    </div>
                </div>
    */
    return (
        <>
            <div className="flex flex-row w-full place-items-center justify-evenly md:justify-center gap-8 md:gap-16 text-base sm:text-lg">
                <div className="flex flex-row gap-2 place-items-center"> 
                    <div className="text-persian-green">
                        <FontAwesomeIcon icon={faCircleCheck} />
                    </div>
                    <div className="font-bold muted-text">
                        {score}/{nbOfAnswers}
                    </div>
                </div>

                <div className="flex flex-row gap-2 place-items-center"> 
                    <div className="text-charcoal">
                        <FontAwesomeIcon icon={faBarsProgress} />
                    </div>
                    <div className="font-bold muted-text">
                        {cardsLeft} left
                    </div>
                </div> 
            </div>   
        </>
    )
}

export default ScoreBar;