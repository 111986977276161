import React, {useState, useEffect} from 'react';
import Icon from '../../general/Icon';
import Button from '../../general/Button'; 
import Modal from '../../general/Modal';
import CountryFlag from '../CountryFlag';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-duotone-svg-icons';
import {faQuestion} from '@fortawesome/pro-solid-svg-icons';
import useLanguages from '../../../hooks/useLanguages';
import SimpleErrorBar from '../../general/SimpleErrorBar';
import Select from 'react-select';
import {useLanguagesContext} from '../../../contexts/LanguagesContext';
import SourceLanguageExplanation from '../../guides/explanationModals/SourceLanguageExplanation';

const ModalContent = ({closeFunction}) => {
    let {addNewLanguages} = useLanguages();
    let {allLanguages, 
        globalSelectedSourceLanguage, 
        setGlobalSelectedSourceLanguage, 
        sourceLanguageOptions
    } = useLanguagesContext();

    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [nbOfSelectedLanguages, setNbOfSelectedLanguages] = useState(0);
    const [selectedSourceLanguage, setSelectedSourceLanguage] = useState(globalSelectedSourceLanguage);
    const [savingLanguagesLoading, setSavingLanguagesLoading] = useState(false);
    const [saveLanguagesError, setSaveLanguagesError] = useState(false);
    const [saveLanguagesErrorMsg, setSaveLanguagesErrorMsg] = useState("");

    // Modal
    const [showSourceLanguageGuideModal, setShowSourceLanguageGuideModal] = useState(false);
    useEffect(()=>{
        setNbOfSelectedLanguages(selectedLanguages.length);
    },[selectedLanguages]);

    const saveLanguagesClick = async () => {
        if (selectedSourceLanguage === null){
            setSaveLanguagesError(true);
            setSaveLanguagesErrorMsg("Please select a source language.");
            return;
        }
        if (selectedLanguages.length === 0){
            setSaveLanguagesError(true);
            setSaveLanguagesErrorMsg("Please select at least one language to study.");
            return;
        }
        setSavingLanguagesLoading(true);
        let db_data = [];
        selectedLanguages.forEach(lang=>{
            db_data.push({
                'target_language': lang,
                'source_language': selectedSourceLanguage,
                'name': allLanguages[0][lang]['name']['en']
            })
        });
        let success = await addNewLanguages(db_data);
        if (success) {
            closeFunction();
            setGlobalSelectedSourceLanguage(selectedSourceLanguage);
        } else {
            setSaveLanguagesError(true);
            setSaveLanguagesErrorMsg("Something went wrong. Please try again.");
        }
        setSavingLanguagesLoading(false);
        
    }

    const resetSelectedLanguages = () => {
        setSelectedLanguages([]);
        setSaveLanguagesError(false);
        setSaveLanguagesErrorMsg("");
    }

    const sourceLanguageSelectChange = (option) => {
        setSelectedSourceLanguage(option.value);
        setSaveLanguagesError(false);
        setSaveLanguagesErrorMsg("");
    }
    
    const selectLanguage = (e) => {
        const new_language = e.currentTarget.getAttribute('data-language-code');
        if (!(selectedLanguages.includes(new_language))){
            setSelectedLanguages([...selectedLanguages, new_language]);
        } else {
            const new_languages = [];
            selectedLanguages.forEach(lang=>{
                if (lang !== new_language){
                    new_languages.push(lang);
                }
            });
            setSelectedLanguages(new_languages);
        }  
        setSaveLanguagesError(false);
        setSaveLanguagesErrorMsg("");
    }

    const showSourceLanguageGuideClick = () => {
        setShowSourceLanguageGuideModal(true);
    }

    const closeSourceLanguageGuideModal = () => {
        setShowSourceLanguageGuideModal(false);
    }

    return (
        <>
            <div className="flex flex-col text-center place-content-center">
                
                <div className="mt-4">
                    <div className="flex flex-col sm:flex-row gap-4 justify-center items-center">
                        <div className="font-bold">
                            Source language: 
                        </div>
                        <div>
                            <Select value={sourceLanguageOptions[1][selectedSourceLanguage]} onChange={sourceLanguageSelectChange} options={sourceLanguageOptions[0]} autoFocus={false}  className="w-48 text-gray-500 text-base"  />
                        </div>
                        <div>
                            <div onClick={showSourceLanguageGuideClick} className="bg-white cursor-pointer shadow-lg border border-dashed border-gray-200 px-4 py-2 rounded-full">
                                <Icon  hover="none" color="black" icon={<FontAwesomeIcon icon={faQuestion} />} />
                            </div>
                        </div>
                    </div>
                    {showSourceLanguageGuideModal && 
                        <>
                            <SourceLanguageExplanation closeFunction={closeSourceLanguageGuideModal} />
                        </>
                    }
                </div>
                <div className="text-xl m-auto p-8 mt-4 bg-white">
                    <div className="h-72 m-auto overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-thumb-charcoal scrollbar-track-gray-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
                        <div className="grid grid-cols-2 md:grid-cols-5 ">
                            {allLanguages !== null && allLanguages[1].map((lang)=>(
                                <>
                                    <div key={"lang_"+lang} onClick={selectLanguage} data-language-code={lang} className={(selectedLanguages.includes(lang) && " bg-charcoal text-white ") +"   rounded-xl p-3 flex flex-col place-items-center cursor-pointer"}>
                                        <div>
                                            <CountryFlag size="4em" countryCode={allLanguages[0][lang]['country_flag']} flagName={allLanguages[0][lang]['name']['en']} />    
                                        </div>
                                        <div className="text-center text-base font-bold">
                                            {allLanguages[0][lang]['name']['en']}
                                        </div>
                                    </div>
                                </>
                            ))
                        }
                        </div>
                    </div>
                </div>
                <div className="my-4">
                    <div className="flex flex-row justify-center gap-2">
                        <div>
                            <span className="px-2 py-1  font-bold rounded-full">{nbOfSelectedLanguages} {nbOfSelectedLanguages === 1 ? <>language</> : <>languages</> } selected </span>
                        </div>
                        {nbOfSelectedLanguages > 0 &&
                            <div onClick={resetSelectedLanguages} className="underline cursor-pointer">
                                Reset
                            </div>
                        }
                    </div>
                </div>
                {saveLanguagesError &&
                    <>
                        <div className="sm:w-1/2 m-auto">
                            <SimpleErrorBar message={saveLanguagesErrorMsg} />
                        </div>
                    </>
                }
                <div className="text-2xl flex flex-row justify-center ">
                    <Button disabled={saveLanguagesError ? true : false} disabledDesign={saveLanguagesError ? true : false} onClick={saveLanguagesClick} text={savingLanguagesLoading ? <><div className="font-bold"><FontAwesomeIcon icon={faSpinner} className="fa-pulse" /></div></>:<><div className="font-bold">Add languages </div></>} color="green" />
                </div>
            </div>
        </>
    )
}

const AddLanguagesModal = ({closeFunction}) => {
    return (
        <>
            <Modal bgColor="bg-gray-100" size="3xl" content={<ModalContent closeFunction={closeFunction} />} closeFunction={closeFunction} />
        </>
    )
}

export default AddLanguagesModal;
