import React from 'react';
import {Link} from 'react-router-dom';

function Roadmap() {
    /*
<div className=" ">
                <h1>Roadmap</h1>
                <div className="text-center">
                    General roadmap of the road ahead. See the <Link to="/changelog">changelog</Link> for news about the development.
                    <div className="text-center">
                        <div className="w-full">
                            <table className="m-auto w-5/6 shadow-lg bg-white my-4">   
                                <thead>  
                                    <tr className="text-center font-bold">
                                        <th className="bg-persian-green text-white border px-8 py-4">
                                            Topic
                                        </th>
                                        <th className="bg-persian-green  text-white border  px-8 py-4">
                                            Details
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            <span className="font-bold">Admin dashboard</span>
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            
                                            <ul className="">
                                                <li>
                                                    Show global stats on site and track user engagement
                                                </li>
                                                <li>
                                                    Easy way to create official Yalango decks. 
                                                    <ul className="list-disc pl-10">
                                                        <li>
                                                            Create CSV file with columns in different languages and translations of the same word, title and description. 
                                                        </li>
                                                        <li>
                                                            When the script runs, it should create decks with all combinations (Norwegian - French, French - Norwegian, French - English, Norwegian - French etc.) 
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    Page to easily read feedback from users, save as favorites (highlight) and more
                                                </li>
                                                <li>
                                                    Report inappropriate content and queue in admin dashboard to run through reports
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            External APIs
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul className="">
                                                <li>
                                                    Google Cloud Text-To-Speech
                                                </li>
                                                <li>
                                                    Google Cloud Translate
                                                </li>
                                                <li>
                                                    Stripe (start early)
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            Yalango Plus
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul className="">
                                                <li>
                                                    Decide what to offer plus members
                                                </li>
                                                <li>
                                                    No limits on number of decks and items
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            Global mission statement
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul className="">
                                                <li>
                                                    Preserve endangered languages
                                                </li>
                                                <li>
                                                    High-quality product for free with premium features as add-ons
                                                </li>
                                                <li>
                                                    No advertising
                                                </li>
                                                <li>
                                                    Inspire and promote the enjoyment of learning languages
                                                </li>
                                                <li>
                                                    Increase global knowledge about other languages and cultures
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            Policies
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul className="">
                                                <li>
                                                    Deck policy (inappropriate content, swearing, etc.)
                                                </li>
                                                <li>
                                                    Terms of Service
                                                </li>
                                                <li>
                                                    Privacy policy
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>    
    */
    return (
        <>
                                 
        </>
    )
}

export default Roadmap;
