import React from 'react';
import Skeleton from 'react-loading-skeleton';

const HelpArticleHeading = ({article}) => {
  return (
    <>
        <h1>{article !== null && article !== undefined && article.hasOwnProperty("title") ? article.title.en : <Skeleton count={1} height={20} />}</h1>
    </>
  )
}

export default HelpArticleHeading