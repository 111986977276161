import React, {useEffect, useState} from 'react';
import MyDecksColumn from './MyDecksColumn';
import MyDecksGetStarted from './MyDecksGetStarted';
import {useLanguagesContext} from '../../../contexts/LanguagesContext';
import useQueryStrings from '../../../hooks/useQueryStrings';
import useUserSettings from '../../../hooks/useUserSettings';
import SortByButton from '../../../components/general/sorting/SortByButton';
import MyDecksSidebarMenu from '../../../components/menus/sidebar-menu/menus/MyDecksSidebarMenu';
import useFavorites from '../../../hooks/useFavorites';
import {useUserInfoContext}  from '../../../contexts/UserInfoContext';
import useDecks from '../../../hooks/useDecks';
import {motion} from 'framer-motion';
import Select from 'react-select';
import NoActiveTargetLanguage from '../../../components/errors/NoActiveTargetLanguage';
import DecksCard from '../DecksCard';
import ReactTooltip from 'react-tooltip';
import {Link} from 'react-router-dom';
import Icon from '../../../components/general/Icon';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/pro-solid-svg-icons';
import {faHeart} from '@fortawesome/pro-solid-svg-icons';
import {faBook} from '@fortawesome/pro-solid-svg-icons';
import {faQuestion} from '@fortawesome/pro-solid-svg-icons';
import {faMagnifyingGlass} from '@fortawesome/pro-solid-svg-icons';
import {faTableColumns} from '@fortawesome/pro-solid-svg-icons';
import {faNote} from '@fortawesome/pro-solid-svg-icons';
import {faEllipsisV} from '@fortawesome/pro-solid-svg-icons';

import {faSliders} from '@fortawesome/pro-solid-svg-icons';
import {faFolder} from '@fortawesome/pro-solid-svg-icons';
import {faGrid} from '@fortawesome/pro-duotone-svg-icons';
import {faGlobeEurope} from '@fortawesome/pro-duotone-svg-icons';
import {faLineColumns} from '@fortawesome/pro-duotone-svg-icons';
import { faCardsBlank } from '@fortawesome/pro-solid-svg-icons';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import AddDeckModal from '../../../components/vocabulary/modals/AddDeckModal';
import MoveDeckModal from '../../../components/vocabulary/modals/MoveDeckModal';
import GeneralFeedbackCircle from '../../../components/feedback/components/GeneralFeedbackCircle';
import AllDecksViewDeleteItemModal from '../../../components/vocabulary/modals/AllDecksViewDeleteItemModal';
import {Helmet} from 'react-helmet';
import MyFoldersTreeView from '../../../components/folders/MyFoldersTreeView';
import MyLibraryMenu from '../../../components/menus/MyLibraryMenu';

const NewMyDecks = () => {
    
    // Hooks
    let {allLanguages, activeUserLanguages, globalSelectedTargetLanguage, setGlobalSelectedTargetLanguage, globalSelectedSourceLanguage, setGlobalSelectedSourceLanguage, activeTargetLanguageOptions} = useLanguagesContext();
    let {fetchUserActionsFromCurrentUser, setActionToTrueForCurrentUser} = useUserSettings();
    let {addAndRemoveQueryStrings, getQueryStrings, setQueryStrings, removeQueryStrings} = useQueryStrings();
    let {
        addSectionForCurrentUser,
        addCategoryForCurrentUser,
        handleAddDeckForCurrentUser,
        swapFavoriteStatusForSection, 
        swapFavoriteStatusForCategory, 
        swapFavoriteStatusForDeck, 
        fetchDecksFromCurrentUserInCategory, 
        fetchDeckSectionsFromCurrentUserInTargetLanguage, 
        fetchDeckCategoriesFromCurrentUserInSectionInTargetLanguage, 
        editDeckNameForCurrentUser, 
        editSectionNameForCurrentUser, 
        editCategoryNameForCurrentUser, 
        fetchDeckIdsFromCurrentUserInCategory, 
        fetchDecksFromDeckIdsForCurrentUser, 
        fetchAllDecksFromCurrentUser, 
        fetchAllDecksFromCurrentUserInTargetLanguage,
        fetchAllDecksFromCurrentUserInTargetLanguageInRealtime, 
        deleteDeck, 
        duplicateDeckApi, 
        fetchAllCategoriesFromCurrentUserInTargetLanguageInRealtime, 
        moveDeck
    } = useDecks();

    const { currentGlobalDecksViewMode, 
        setCurrentGlobalDecksViewMode} = useUserInfoContext();

    const {getUserFavorites} = useFavorites();
    const [defaultCurrentGlobalDecksViewMode, setDefaultCurrentGlobalDecksViewMode] = useState(null);

    // State variables
    const [languageOptions, setLanguageOptions] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState(globalSelectedTargetLanguage);
    const [sourceLanguage, setSourceLanguage] = useState(globalSelectedSourceLanguage);
    const [sections, setSections] = useState(null);
    const [categories, setCategories] = useState(null);
    const [filteredCategories, setFilteredCategories] = useState(null);
    const [decks, setDecks] = useState(null);
    const [allDecks, setAllDecks] = useState(null);
    const [allCategories, setAllCategories] = useState(null); // in selected language
    const [uncategorizedDecks, setUncategorizedDecks] = useState(null);
    const [loading, setLoading] = useState(true);
    const [queryStringsRead, setQueryStringsRead] = useState(false);
    const [showGetStarted, setShowGetStarted] = useState(null);
    const [deckIds, setDeckIds] = useState(null);

    const [favorites, setFavorites] = useState(null);

    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [showAddDeckModal, setShowAddDeckModal] = useState(false);
    const [showMoveDeckModal, setShowMoveDeckModal] = useState(false);

    const [viewMode, setViewMode] = useState("organized");
    const viewModeOptions = [{label:"All my decks", value: 'all'}, {label: "Organized view", value:"organized"}, {label: "Favorites", value:"favorites"}];
    const [viewModeOptionsDict, setViewModeOptionsDict] = useState(viewModeOptions.reduce(function(result, item) {
        var key = item.value; 
        result[key] = item;
        return result;
      }, {}));

    useEffect(()=>{
        if (currentGlobalDecksViewMode !== null && defaultCurrentGlobalDecksViewMode === null){
            setDefaultCurrentGlobalDecksViewMode(currentGlobalDecksViewMode);
        }
        if (viewMode !== null){
            //viewMode !== currentGlobalDecksViewMode && setCurrentGlobalDecksViewMode(viewMode);
        }
        else if (currentGlobalDecksViewMode !== null){
            currentGlobalDecksViewMode !== viewMode && setCurrentGlobalDecksViewMode(currentGlobalDecksViewMode);
        } else if (viewMode === null && currentGlobalDecksViewMode !== null){
            setViewMode(currentGlobalDecksViewMode);
        } else if (viewMode === null && currentGlobalDecksViewMode === null){
            setViewMode(viewModeOptions[0]['value']);
        } 
    },[viewMode, currentGlobalDecksViewMode, defaultCurrentGlobalDecksViewMode]);


    const [sortByValue, setSortByValue] = useState("last_updated_timestamp_desc");
    const sortByOptions = [
        {label: 'Alphabetically', value:'alphabetically'}, 
        {label: 'Number of items', value:'number_of_items'},  
        {label: 'Last updated (ascending)', value:'last_updated_timestamp_asc'},
        {label: 'Last updated (descending)', value:'last_updated_timestamp_desc'},  
        {label: 'Created (ascending)', value:'created_timestamp_asc'}, 
        {label: 'Created (descending)', value:'created_timestamp_desc'},      
    ];
    const sortByOptionsDict = sortByOptions.reduce(function(result, item) {
        var key = item.value; 
        result[key] = item;
        return result;
      }, {});
    
    // Selected items
    const [selectedSection, setSelectedSection] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedDeck, setSelectedDeck] = useState(null);
    const [selectedCategorizeDeck, setSelectedCategorizeDeck] = useState(null);

    // Individual loading screens
    const [decksLoading, setDecksLoading] = useState(false);
    const [categoriesLoading, setCategoriesLoading] = useState(false);
    const [sectionsLoading, setSectionsLoading] = useState(false);
    const [allDecksLoading, setAllDecksLoading] = useState(false);
    const [deckDuplicateLoading, setDeckDuplicateLoading] = useState(false);
    const [movingSavingChangesLoading, setMovingSavingChangesLoading] = useState(false);

    const editDeckNameAndUpdateView = async (showEditNameInputField, editNameInputValue) => {
        let success = await editDeckNameForCurrentUser(showEditNameInputField, editNameInputValue);
        if (success === true){
            fetchDecksFromCurrentUserInCategory(selectedCategory, setDecks);
        }   
    }

    useEffect(()=>{
        const fetchData = async () => {
            setLanguageOptions(activeTargetLanguageOptions);
            
            // Query strings
            let qs = await getQueryStrings();
            if ('target' in qs){
                activeUserLanguages.forEach((lang)=>{
                    if (qs['target'] === lang['target_language']){
                        setSelectedLanguage(qs['target']);
                        setGlobalSelectedTargetLanguage(qs['target']);
                        setGlobalSelectedSourceLanguage(lang['source_language']);
                    }
                })  
            };
            if ('createDeckModal' in qs){
                if (qs['createDeckModal'] === "true"){
                    openAddDeckModal();
                }
            }
            if ('s' in qs){
                setSelectedSection(parseInt(qs['s']));
            }
            if ('c' in qs){
                setSelectedCategory(parseInt(qs['c']));
            }
            if ('d' in qs){
                setSelectedDeck(parseInt(qs['d']));
            }
            if ('view' in qs){
                setViewMode(qs['view']);
            }

            setQueryStringsRead(true);
        }

        const checkIfIntroNeeded = async () => {
            let actions = await fetchUserActionsFromCurrentUser();
            //console.log("Actions: ", actions);
            if (!actions['my_decks']['introduction']){
                setShowGetStarted(true);
            } else {
                setShowGetStarted(false);
            }
        }

        if (loading === true && !movingSavingChangesLoading){
            fetchData();
            checkIfIntroNeeded();
        }
        return () => {
            setLoading(false);
        }
    },[activeUserLanguages, movingSavingChangesLoading, allLanguages, activeTargetLanguageOptions, loading]);

    useEffect(()=>{
        if (activeUserLanguages !== null && showGetStarted !== null){
            setLoading(false);
        }
    },[activeUserLanguages, showGetStarted]);

    useEffect(()=>{
        if (globalSelectedTargetLanguage !== null){
            setSelectedLanguage(globalSelectedTargetLanguage);
        }
    },[globalSelectedTargetLanguage])

    useEffect(()=>{
        if (queryStringsRead && !movingSavingChangesLoading){
            if (selectedLanguage === null && languageOptions !== null && activeUserLanguages !== null){
                if (languageOptions[0].length > 0){
                    setSelectedLanguage(languageOptions[0][0]['value']);
                    setGlobalSelectedTargetLanguage(languageOptions[0][0]['value']);
                    if (activeUserLanguages.length > 0 ){
                        for (const lang of activeUserLanguages){
                            if (lang['target_language'] === languageOptions[0][0]['value']){
                                setGlobalSelectedSourceLanguage(lang['source_language']);
                            }
                        }
                    }
                }
            }
        }
    },[queryStringsRead, movingSavingChangesLoading, languageOptions,selectedLanguage, activeUserLanguages, languageOptions]);

    useEffect(()=>{
        let decksUnsubscribe = null;
        let sectionsUnsubscribe = null;
        let categoriesUnsubscribe = null;
        const fetchSections = async () => {
            if (selectedLanguage !== null){
                // Sections
                sectionsUnsubscribe = await fetchDeckSectionsFromCurrentUserInTargetLanguage(selectedLanguage, setSections);            
            } 
        }

        const findSourceLanguage = () => {
            if (selectedLanguage !== null){
                activeUserLanguages.forEach((lang)=>{
                    if (lang.target_language === selectedLanguage){
                        setSourceLanguage(lang.source_language);
                    }
                })
            } else {
                setSourceLanguage(null);
            }
        }
        const doEverything = async () => {
            if (viewMode === "organized"){
/*                 await fetchSections();
                let addList = [];
                if (selectedSection !== null){
                    addList.push({name:'s', value:selectedSection});
                }
                if (selectedCategory !== null){
                    addList.push({name:'c', value:selectedCategory});
                }
                if (selectedDeck !== null){
                    addList.push({name:'d', value:selectedDeck});
                }
                addList.push({name: 'view', value: viewMode})
                //addAndRemoveQueryStrings(addList,[]);
                categoriesUnsubscribe = await fetchAllCategoriesFromCurrentUserInTargetLanguageInRealtime(selectedLanguage, setAllCategories); */
            }
            else if (viewMode === "favorites"){
                let r = await getUserFavorites({'target': selectedLanguage});
                setFavorites(r[1]);
            }
            setAllDecksLoading(true);
            decksUnsubscribe = await fetchAllDecksFromCurrentUserInTargetLanguageInRealtime(selectedLanguage, setAllDecks, sortByValue);
            setAllDecksLoading(false);
            findSourceLanguage();
        }

        if (queryStringsRead && !movingSavingChangesLoading){
            doEverything();
        }  
        return () => {
            decksUnsubscribe !== null && decksUnsubscribe();
            sectionsUnsubscribe !== null && sectionsUnsubscribe();
            categoriesUnsubscribe !== null && categoriesUnsubscribe();
        }
    }, [selectedLanguage, movingSavingChangesLoading, sortByValue, viewMode, activeUserLanguages, selectedCategory, selectedDeck, selectedSection, queryStringsRead]);

    useEffect(()=>{
        // fetch categories in section
        if (selectedSection !== null && allCategories !== null && selectedLanguage !== null && !movingSavingChangesLoading){
            setCategoriesLoading(true);
            let filt_list = [];
            for (const cat of allCategories){
                if (cat.section_id === selectedSection){
                    filt_list.push(cat);
                }
            } 
            setFilteredCategories(filt_list);
            //setCategories(null);   
            //fetchDeckCategoriesFromCurrentUserInSectionInTargetLanguage(selectedLanguage, selectedSection, setCategories);
            setCategoriesLoading(false);
        }
    },[selectedSection, allCategories, selectedLanguage, movingSavingChangesLoading]);

    useEffect(()=>{
        let unsub = null;
        const fetchAsync = async () => {
            unsub = await fetchDecksFromCurrentUserInCategory(selectedCategory, setDecks);
        }
        if (queryStringsRead && !movingSavingChangesLoading){
            if (selectedCategory === null){
                setDecks(null);
            }
            let success = checkBeforeFetchingDecks();

            if (!success) {
                setDecks(null);
            }

            if (success){
                //console.log("Calling the deck fetching function!");
                setDecksLoading(true);
                fetchAsync();
                //fetchDeckIdsFromCurrentUserInCategory(selectedCategory, setDeckIds);
            }
        }
        return () => {
            unsub !== null && unsub();
        }
    },[selectedCategory, queryStringsRead, movingSavingChangesLoading]);

    useEffect(()=>{
        if (decks !== null && !movingSavingChangesLoading) {
            setDecksLoading(false);
        }
    },[decks, movingSavingChangesLoading]);

    // Uncategorized decks
    useEffect(()=>{
        if (allDecks !== null && allCategories !== null && !movingSavingChangesLoading && !decksLoading){
            let uncategorized_list = [];
            for (const d of allDecks){
                let uncat_bool = true;
                for (const c of allCategories){
                    if (c['decks'] !== undefined && c['decks']['id'].includes(d.id)){
                        uncat_bool = false;
                    }
                }
                if (uncat_bool){
                    uncategorized_list.push(d);
                }
            }
            setUncategorizedDecks(uncategorized_list);
        }
    },[allDecks, allCategories, movingSavingChangesLoading, decksLoading]);

    const resetLangVariables = () => {
        setSelectedSection(null);
        setSelectedCategory(null);
        setSections(null);
        setCategories(null);
        setAllCategories(null);
        setUncategorizedDecks(null);
        setDecks(null);
        setSelectedDeck(null);
    }

    const changeSelectedLanguage = (option) => {
        resetLangVariables();
        setSelectedLanguage(option.value);
        addAndRemoveQueryStrings([{name:'target', value: option.value}],['s', 'c', 'd']); // removes
        setGlobalSelectedTargetLanguage(option.value);
        if (activeUserLanguages.length > 0 ){
            for (const lang of activeUserLanguages){
                if (lang['target_language'] === option.value){
                    setGlobalSelectedSourceLanguage(lang['source_language']);
                }
            }
        }
    }

    const changeViewMode = (option) => {
        setViewMode(option.value);
        setQueryStrings(option.value, 'view');
    }

    const checkBeforeFetchingDecks = () => {
        let checkSuccessful = true;

        if (selectedSection === null){
            setSelectedCategory(null);
        }
        if (sections !== null){
            let correctSection = false;
            sections.forEach((s)=> {
                if (s.section_id === selectedSection){
                    correctSection = true;
                }
            })
            if (!correctSection){
                setSelectedCategory(null);
                checkSuccessful = false;
            }  
        }
        if (categories !== null){
            if (selectedCategory !== null){
                let catActiveView = false;
                categories.forEach((c)=> {
                    if (c.category_id === selectedCategory){
                        catActiveView = true;
                    }
                })
                if (!catActiveView){
                    setSelectedCategory(null);
                    checkSuccessful = false;
                }  
            }
        }

        if (selectedCategory === null){
            checkSuccessful = false;
        }

        return checkSuccessful;
        
    }

    const favoriteButtonClickForDeck = async (deck_id) => {
        //console.log("Deck: ", deck_id);
        await swapFavoriteStatusForDeck(deck_id);
    }

    const favoriteButtonClickForCategory = async (section_id) => {
        await swapFavoriteStatusForCategory(section_id);
    }

    const favoriteButtonClickForSection = async (section_id) => {
        await swapFavoriteStatusForSection(section_id);
    }

    const addSection = async (input_value) => {
        if (input_value !== ""){
            await addSectionForCurrentUser(input_value, selectedLanguage);
        }
    }

    const addCategory = async (input_value) => {
        if (input_value !== ""){
            await addCategoryForCurrentUser(input_value, selectedSection, selectedLanguage);
        }
    }

    const addDeck = async (input_value) => {
        let source_lang;
        if (input_value !== ""){
            if (sourceLanguage === null){
                // default
                source_lang = "en";
            } else {
                source_lang = sourceLanguage;
            }
            await handleAddDeckForCurrentUser(input_value, selectedCategory, source_lang, selectedLanguage);
        }
    }

    const showFeedbackModalClick = () => {
        setShowFeedbackModal(true);
    }

    const closeFeedbackModalClick = () =>{
        setShowFeedbackModal(false);
    }

    const openAddDeckModal = () => {
        setShowAddDeckModal(true);
    }

    const closeAddDeckModal = () => {
        setShowAddDeckModal(false);
    }

    const toggleViewModeClick = () => {
        if (viewMode === "organized"){
            setViewMode("all");
            addAndRemoveQueryStrings([{'name': 'view', 'value': "all"}],[]);
        } else {
            setViewMode("organized");
            addAndRemoveQueryStrings([{'name': 'view', 'value': "organized"}],[]);
        }  
    }

    const [addNewDeckLoading, setAddNewDeckLoading] = useState(false);
    const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);
    const [deckDeleteLoading, setDeckDeleteLoading] = useState(false);
    const [deleteDeckData, setDeleteDeckData] = useState(null);
    const [openDeckMenuId, setOpenDeckMenuId] = useState(null);

    const allDecksViewOpenDeleteDeckModal = (e, deck) => {
        e.preventDefault();
        closeDeckMenu();
        setDeleteDeckData(deck);
        setShowDeleteItemModal(true);
    }

    const allDecksViewCloseDeleteDeckModal = () => {
        setShowDeleteItemModal(false);
    }

    const duplicateDeckClick = async (e, deck) => {
        e.preventDefault();
        closeDeckMenu();
        setDeckDuplicateLoading(true);
        await duplicateDeckApi(deck['id']);
        setDeckDuplicateLoading(false);
    }

    const orderByChange = (option) => {
        setSortByValue(option.value);
    }

    const viewModeChange = (option) => {
        setViewMode(option.value);
    }

    const openDeckMenuClick = (e, id) => {
        e.preventDefault();
        setOpenDeckMenuId(id);
    }
    
    const closeDeckMenu = () => {
        setOpenDeckMenuId(null);
    }

    const backgroundClick = (e) => { 
        if (openDeckMenuId !== null){
            closeDeckMenu();
        }   
        closeShowOptionsMenu();
    }

    const moveDeckClick = (d) => {
        setSelectedCategorizeDeck(d);
        setShowMoveDeckModal(true);
    }

    const categorizeClick = (d) => {
        setSelectedCategorizeDeck(d);
        setShowMoveDeckModal(true);
    }

    const closeMoveDeckModal = () => {
        setShowMoveDeckModal(false);
    }

    const moveDeckSaveClick = async (selected_cat, filteredCategories, selected_deck_id) => {
        if (!movingSavingChangesLoading){
            setMovingSavingChangesLoading(true);
            setDecksLoading(true);
            await moveDeck(selected_cat, filteredCategories, selected_deck_id);
            setDecks(null);
            setSelectedDeck(null);
            setMovingSavingChangesLoading(false);
            setDecksLoading(false);
            setShowMoveDeckModal(false);  
        }
    }
    
    /*
    <div data-for="my-decks-tooltip" data-tip="Explore public decks">
                                            <Link to="/decks">
                                                <Icon hover="none" color="blue" icon={<FontAwesomeIcon icon={faGlobeEurope} />} />
                                            </Link>
                                        </div>
    */

    /* 
    <div className="ml-8 cursor-pointer" onClick={()=>setShowOptions(!showOptions)}>
                                            <Icon icon={<FontAwesomeIcon icon={faSliders} />} />
                                        </div>
{showOptions &&
                                        <div className="flex flex-col sm:flex-row justify-start gap-2 sm:gap-8 place-items-center  bg-white z-10  px-6 rounded-xl shadow-3xl">
                                        
                                                                <div className="w-64">
                                                                    <div className=" w-full flex flex-col flex-grow">
                                                                        <div className="font-bold text-gray-600">
                                                                            View
                                                                        </div>
                                                                        <div>
                                                                            <Select onChange={viewModeChange} isSearchable={ false } value={viewModeOptionsDict[viewMode]} options={viewModeOptions} />
                                                                        </div>
                                                                    </div>
                                                                </div>                     
                                        </div>
                                    }
    */

    const [showOptions, setShowOptions] = useState(false);
    /*  if
 {(!loading && showGetStarted && activeUserLanguages !== null && activeUserLanguages.length > 0) &&
                    <MyDecksGetStarted setActionToTrueForCurrentUser={setActionToTrueForCurrentUser} setShowGetStarted={setShowGetStarted} userLanguages={activeUserLanguages} />
                }
    */
    
    const [showOptionsMenu, setShowOptionsMenu] = useState(false);
    const openShowOptionsMenu = (e) => {
        e.stopPropagation();
        setShowOptionsMenu(!showOptionsMenu);
    }

    const closeShowOptionsMenu = () => {
        setShowOptionsMenu(false);
      }

    /**
                                                 <div className="grid grid-cols-1 lg:grid-cols-3 gap-2">
                                                <MyDecksColumn movingSavingChangesLoading={movingSavingChangesLoading} moveDeckClick={moveDeckClick} deleteDeck={deleteDeck} setCategories={setFilteredCategories} setSelectedDeck={setSelectedDeck} addAndRemoveQueryStrings={addAndRemoveQueryStrings} removeQueryStrings={removeQueryStrings} setQueryStrings={setQueryStrings} type="sections" items={sections} selectedItem={selectedSection} loading={sectionsLoading} setSelectedItem={setSelectedSection} favoriteButtonClick={favoriteButtonClickForSection} saveNewItem={addSection} categories={filteredCategories} selectedCategory={selectedCategory} selectedSection={selectedSection} setSelectedCategory={setSelectedCategory} editItemName={editSectionNameForCurrentUser} setDecks={setDecks} fetchDecksFromCurrentUserInCategory={fetchDecksFromCurrentUserInCategory} setLoading={setDecksLoading} title="1. Sections" />
                                                <MyDecksColumn movingSavingChangesLoading={movingSavingChangesLoading} moveDeckClick={moveDeckClick} deleteDeck={deleteDeck} setCategories={setFilteredCategories} setSelectedDeck={setSelectedDeck} addAndRemoveQueryStrings={addAndRemoveQueryStrings} removeQueryStrings={removeQueryStrings} setQueryStrings={setQueryStrings} type="categories" selectedItem={selectedCategory} loading={categoriesLoading} setSelectedItem={setSelectedCategory} favoriteButtonClick={favoriteButtonClickForCategory} items={filteredCategories} saveNewItem={addCategory} categories={categories} selectedCategory={selectedCategory} selectedSection={selectedSection} setSelectedCategory={setSelectedCategory} editItemName={editCategoryNameForCurrentUser} setDecks={setDecks} fetchDecksFromCurrentUserInCategory={fetchDecksFromCurrentUserInCategory} setLoading={setDecksLoading} allCategories={allCategories} title="2. Subsections" />
                                                <MyDecksColumn movingSavingChangesLoading={movingSavingChangesLoading} moveDeckClick={moveDeckClick} deleteDeck={deleteDeck} setCategories={setFilteredCategories} setSelectedDeck={setSelectedDeck} addAndRemoveQueryStrings={addAndRemoveQueryStrings} removeQueryStrings={removeQueryStrings} setQueryStrings={setQueryStrings} type="decks" selectedItem={selectedDeck} loading={decksLoading} setSelectedItem={setSelectedDeck} favoriteButtonClick={favoriteButtonClickForDeck} items={decks} saveNewItem={addDeck} categories={filteredCategories} selectedCategory={selectedCategory} selectedSection={selectedSection} setSelectedCategory={setSelectedCategory} editItemName={editDeckNameAndUpdateView} setDecks={setDecks} fetchDecksFromCurrentUserInCategory={fetchDecksFromCurrentUserInCategory} setLoading={setDecksLoading}  title="3. Decks" />
                                            </div>
                                            {(uncategorizedDecks !== null && sections !== null && sections.length > 0 && allCategories !== null && allCategories.length > 0 && uncategorizedDecks.length > 0 && !movingSavingChangesLoading && !decksLoading) && 
                                                <>
                                                    <div>
                                                        <div className="font-bold text-gray-700 text-center text-xl">Uncategorized decks</div>
                                                        <div className="text-center my-2">These decks haven't been categorized in a section and subsection yet.</div>
                                                            <div className="flex flex-col justify-center place-items-center gap-2">
                                                                {uncategorizedDecks.map((item, index)=>(
                                                                    <>
                                                                        <div className="w-full sm:w-3/6 flex shadow-lg p-4 flex-row justify-between items-center bg-persian-green text-white rounded-lg ">
                                                                            <div className="flex flex-col gap-2">
                                                                                <div className="font-bold">
                                                                                    <Link to={"/my-decks/"+item.id}>{item.name}</Link>
                                                                                </div> 
                                                                                <div className="italic">
                                                                                    {item['number_of_items']} cards
                                                                                </div>
                                                                            </div>
                                                                            <motion.div onClick={()=>categorizeClick(item)} whileHover={{scale:1.02}} className="cursor-pointer flex flex-row gap-2 bg-white text-black px-3 p-2 rounded-xl shadow-xl">
                                                                                <div>
                                                                                    <Icon icon={<FontAwesomeIcon icon={faPlus} />} />
                                                                                </div>
                                                                                <div>
                                                                                    Categorize 
                                                                                 </div>
                                                                            </motion.div>
                                                                        </div>
                                                                    </>
                                                                ))}
                                                            </div>
                                                    </div>
                                                </>
                                            }
                                            {(showMoveDeckModal && selectedCategorizeDeck !== null && allCategories !== null && sections !== null) && 
                                                <>
                                                    <MoveDeckModal moveDeckSaveClick={moveDeckSaveClick} setDecksLoading={setDecksLoading} movingSavingChangesLoading={movingSavingChangesLoading} setMovingSavingChangesLoading={setMovingSavingChangesLoading} moveDeck={moveDeck} sections={sections} categories={allCategories} deck={selectedCategorizeDeck} closeFunction={closeMoveDeckModal} />
                                                </>
                                            }
     * */  
    return (
        <>
            <Helmet>
                <title>My decks - Yalango</title>
                <meta name="description" content="Access, create, and organize your decks on Yalango." />
            </Helmet>
            {(!loading && activeUserLanguages !== null && activeUserLanguages.length > 0 && allLanguages !== null && selectedLanguage !== null && languageOptions !== null) && 
                    <>
                <div className="  flex flex-col gap-8">
                    <MyLibraryMenu activeName="my-decks" />
                   
                        
                        <div onClick={backgroundClick} className="relative">
                                <div onClick={(e)=>openShowOptionsMenu(e)} className="absolute sm:right-4 sm:top-4 right-2 top-2 p-2 cursor-pointer text-xl">
                            <Icon color="charcoal" icon={<FontAwesomeIcon icon={faEllipsisV} />} /> 
                        </div>
                        {showOptionsMenu && 
                                <>
                                    <div className="absolute sm:right-8 sm:top-10 right-6 top-6 overflow-visible text-base w-48 z-10 rounded-lg shadow-xl bg-white ">
                                        <div className="flex text-sm whitespace-nowrap font-bold text-gray-700 flex-col justify-start place-items-start">
                                            <div onClick={toggleViewModeClick} className="flex cursor-pointer flex-row gap-3 hover:bg-gray-100 w-full rounded-lg  p-3">
                                                <div>
                                                    <Icon color={viewMode === "organized" ? "red" : "yellow"} icon={<FontAwesomeIcon icon={viewMode === "organized" ? faCardsBlank : faFolder} />} />
                                                </div>
                                                <div>
                                                    View {viewMode === "organized" ? ' all decks ' : ' folders '}
                                                </div>
                                            </div>
                                            <Link to={'/my-library'} className="no-underline w-full" >
                                            <div className="flex flex-row gap-3 hover:bg-gray-100 w-full rounded-lg  p-3">
                                                <div>
                                                    <Icon color="red" icon={<FontAwesomeIcon icon={faBook} />} />
                                                </div>
                                                <div>
                                                    My library
                                                </div>
                                            </div>
                                            </Link>
                                            <Link to={'/my-decks/cards'} className="no-underline w-full" >
                                                <div className="flex flex-row gap-3 hover:bg-gray-100 w-full rounded-lg  p-3">
                                                    <div>
                                                        <Icon color="charcoal" icon={<FontAwesomeIcon icon={faMagnifyingGlass} />} />
                                                    </div>
                                                    <div>
                                                        Search for cards
                                                    </div>
                                                </div>
                                            </Link>

                                                <Link to={'/my-tables'} className="no-underline w-full" >
                                                <div className="flex flex-row gap-3 hover:bg-gray-100 w-full rounded-lg  p-3">
                                                    <div>
                                                        <Icon color="blue" icon={<FontAwesomeIcon icon={faTableColumns} />} />
                                                    </div>
                                                    <div>
                                                        View only tables
                                                    </div>
                                                </div>
                                                </Link>
                                                <Link to={'/my-notes'} className="no-underline w-full" >
                                                <div className="flex flex-row gap-3 hover:bg-gray-100 w-full rounded-lg  p-3">
                                                    <div>
                                                        <Icon color="yellow" icon={<FontAwesomeIcon icon={faNote} />} />
                                                    </div>
                                                    <div>
                                                        View only notes
                                                    </div>
                                                </div>
                                                </Link>
                                        </div>
                                    </div>
                                </>
                            }   
                        <ReactTooltip effect="solid" id="my-decks-tooltip" />
                                    {activeUserLanguages.length > 0 &&
                                        <>
                                        <h1>{viewMode !== 'favorites' ? <>My {allLanguages[0][selectedLanguage]['name']['en']} decks</> : <>My favorite {allLanguages[0][selectedLanguage]['name']['en']} decks</>}</h1>
        
                                        <div className="flex text-2xl mt-6 mx-8 flex-row place-items-center justify-center sm:justify-end">
    
                                            {viewMode === "all" &&
                                            <div className="text-sm">
                                                <SortByButton textSize={"sm"} onChange={orderByChange} value={sortByOptionsDict[sortByValue]} options={sortByOptions} />
                                            </div>
                                            }
                                        </div>
                                        
                                        {viewMode === "organized" &&
                                            <>
                                                <MyFoldersTreeView filterContentList={["decks"]} outputDesign={"page"} />
                                            </>
                                        }
                                        {viewMode === "all" && 
                                            <>
                                                <div className="p-2 flex flex-col gap-2">
                                                    {allDecks !== null && 
                                                        <>
                                                            
                                                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 text-center">
                                                                <motion.div onClick={openAddDeckModal} whileHover={{y:-3}} className={"relative  bg-charcoal  rounded-lg p-4 h-64 shadow-2xl cursor-pointer flex flex-col justify-evenly"}>
                                                                    <div className="bg-white font-bold text-xl rounded-lg shadow-2xl p-4 max-h-48 overflow-auto gap-2 flex flex-col">
                                                                        Create deck <Icon hover="none" color="charcoal" icon={<FontAwesomeIcon icon={faPlus} />} /> 
                                                                    </div>
                                                                </motion.div>
                                                                {(deckDuplicateLoading || addNewDeckLoading) &&
                                                                    <>
                                                                        <motion.div className={"relative rounded-lg p-4 text-5xl h-64 bg-persian-green text-white shadow-2xl flex flex-col justify-center place-items-center"}>
                                                                            <FontAwesomeIcon icon={faSpinner} className="fa-pulse" />
                                                                        </motion.div>
                                                                    </>
                                                                }
                                                            {allDecks.map((item, index)=>(
                                                                <>
                                                                    <DecksCard closeDeckMenu={closeDeckMenu} openDeckMenuId={openDeckMenuId} openDeckMenuClick={openDeckMenuClick} duplicateDeckClick={duplicateDeckClick} allDecksViewOpenDeleteDeckModal={allDecksViewOpenDeleteDeckModal} showAdminOptions={true} showProfileLink={false} baseLink={"my-decks"} languages={allLanguages} key={'deck_'+index} deck={item} />
                                                                </> 
                                                            ))
                                                            }
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                                {showDeleteItemModal &&
                                                    <>
                                                        <AllDecksViewDeleteItemModal loading={deckDeleteLoading} setLoading={setDeckDeleteLoading} deleteDeck={deleteDeck} closeFunction={allDecksViewCloseDeleteDeckModal} item={deleteDeckData} />
                                                    </>
                                                }  
                                                {showAddDeckModal &&
                                                    <>
                                                        <AddDeckModal setAddNewDeckLoading={setAddNewDeckLoading} closeFunction={closeAddDeckModal} />
                                                    </>
                                                }
                                            </>
                                        }
                                        {viewMode === "favorites" && 
                                            <>
                                                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 text-center">
                                                {favorites !== null && favorites.map((item, index)=>(
                                                    <>
                                                        <DecksCard showAdminOptions={true} closeDeckMenu={closeDeckMenu} openDeckMenuId={openDeckMenuId} openDeckMenuClick={openDeckMenuClick} showProfileLink={false} baseLink={"decks"} languages={allLanguages} key={'deck_'+index} deck={item} />
                                                    </> 
                                                    ))
                                                }
                                            
                                                </div>
                                                {(favorites !== null && favorites.length === 0) &&
                                                    <>
                                                        <div className="text-center w-5/6 m-auto">
                                                            You haven't saved any public {allLanguages[0][selectedLanguage]['name']['en']} decks to your favorites yet. Get started by saving your favorites in the <Link to="/decks">public decks</Link> directory.
                                                        </div>
                                                    </>
                                                }
                                            </>
                                        }
                                            
                                        </>
                                    }
                        </div>
                   
                </div>
                </>
                    }
                {(!loading && activeUserLanguages !== null && activeUserLanguages.length === 0) && 
                    <>
                        <NoActiveTargetLanguage />
                    </>
                }
               
        </>
    )
}

export default NewMyDecks;
