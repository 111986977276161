import React from 'react';
import { useLanguagesContext } from '../../../../../contexts/LanguagesContext';

const SourceTranslation = (props) => {
    const {generalHanziCurrentSource, card} = props;
    const {allLanguages} = useLanguagesContext();
    console.log(allLanguages)
    return (
        <>
            {generalHanziCurrentSource !== null && generalHanziCurrentSource !== "" && generalHanziCurrentSource !== undefined && 
                <div className="flex text-lg lg:text-xl flex-row gap-6 place-items-center ">
                    <div className="muted-text font-semibold">
                        {allLanguages[0][card["source_ISO_639-1"]]["name"]["en"]}:
                    </div>
                    <div className="">
                        {generalHanziCurrentSource}
                    </div>
                    
                </div>
            }
        </>
    )
}

export default SourceTranslation;