import React, {useState, useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-duotone-svg-icons';
import Select from 'react-select';
import useUserSettings from '../../hooks/useUserSettings';
import Button from '../../components/general/Button';
import {useUserInfoContext} from '../../contexts/UserInfoContext';

const DeckSettings = () => {

  const {updateUserDeckSettingsApi} = useUserSettings();

  const {userSettings, 
    refreshUserSettings, 
    userInfoContextLoading} = useUserInfoContext();
  const [oldDeckSettings, setOldDeckSettings] = useState(null);
  const [deckSettings, setDeckSettings] = useState(null);
  const [saving, setSaving] = useState(false);

  const [deckViewModeOptions, setDeckViewModeOptions] = useState([{'value': 'all', 'label': 'All decks'}, {'value': 'organized', 'label': 'Folders'}]);
  
  const [deckViewModeOptionsDict, setDeckViewModeOptionsDict] = useState(null);

  const [deckViewMode, setDeckViewMode] = useState(null);

  useEffect(()=>{
    if (deckViewModeOptions !== null){
      let d = deckViewModeOptions.reduce(function(result, item) {
        var key = item.value; 
        result[key] = item;
        return result;
      }, {});
      setDeckViewModeOptionsDict(d);
    }
  },[deckViewModeOptions]);
  
  useEffect(()=>{
    if (userSettings !== null && deckViewModeOptionsDict !== null) {
      if ('my_decks' in userSettings && 'default_decks_view' in userSettings['my_decks']){
        setDeckViewMode(deckViewModeOptionsDict[userSettings['my_decks']['default_decks_view']]);
      } else {
        setDeckViewMode(deckViewModeOptions[0]);
      }
    } else {
      setDeckViewMode(deckViewModeOptions[0]);
    }
  },[userSettings, deckViewModeOptionsDict, deckViewModeOptions]);

  const deckViewModeOnChange = (option) => {
    setDeckViewMode(option);
  }

  const saveChangesClick = async () => {
    let settings = {
      'default_decks_view': deckViewMode['value']
    };
    setSaving(true);
    await updateUserDeckSettingsApi(settings);
    setSaving(false);
    refreshUserSettings();
  }

  return (
      <>
        <div className=" ">
            <h1>Deck settings </h1>
            {saving &&
              <div className="text-2xl text-center">
                <FontAwesomeIcon icon={faSpinner} className="fa-pulse" />
              </div>
            }
            {(!userInfoContextLoading && !saving) &&
              <div className="w-5/6 m-auto">
                <div className="flex flex-col gap-2">
                  <div className="font-bold text-gray-600">
                    Default view mode
                  </div>
                  <div> 
                    {deckViewMode !== null &&
                      <Select isSearchable={ false } options={deckViewModeOptions} value={deckViewMode} onChange={deckViewModeOnChange} />
                    }
                  </div>
                </div>
                <div className="text-center my-6">
                  <Button onClick={saveChangesClick} text="Save changes" color="green" />
                </div>
              </div>
            }
        </div>
      </>
  );
};

export default DeckSettings;
