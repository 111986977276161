import React, {useEffect, useState} from 'react';
import Button from '../components/general/Button';
import { CSVLink } from "react-csv";
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useDecks from '../hooks/useDecks';
import {useLanguagesContext} from '../contexts/LanguagesContext';

const MyVocabularyExport = () => {

    const [headers, setHeaders] = useState([
        { label: "Source", key: "source" },
        { label: "Target", key: "target" },
        { label: "Source language", key: "source_ISO_639-1"},
        { label: "Target language", key: "target_ISO_639-1"},
        { label: "Number of answers", key: "number_of_answers"},
        { label: "Correct answers", key: "correct_answers"},
        { label: "Wrong answers", key: "wrong_answers"},
        { label: "Correct percentage", key: "percentage_correct"},
        { label: "Created", key: "created" }, 
        { label: "Last updated", key: "last_updated" }
    ]);
    const [data, setData] = useState([]);

    const [fetchingData, setFetchingData] = useState(false);
    const [dataFetched, setDataFetched] = useState(false);

    const {fetchVocabularyInTargetLanguage} = useDecks();
    let { globalSelectedTargetLanguage, activeUserLanguages, allLanguages } = useLanguagesContext();

    useEffect(()=>{
        setData([]);
        setDataFetched(false);
        setFetchingData(false);
    },[globalSelectedTargetLanguage])

    const fetchVocabulary = async () => {
        if (globalSelectedTargetLanguage === null){return null}
        setFetchingData(true);
        let r = await fetchVocabularyInTargetLanguage(globalSelectedTargetLanguage, false, 5000);

        let l = [];
        for (const item of r['vocabulary']){
            let o = {};
            o['created'] = item['created'].toDate();
            o['last_updated'] = item['last_updated'].toDate();
            o['source'] = item['source'];
            o['target'] = item['target'];
            o['source_ISO_639-1'] = item['source_ISO_639-1'];
            o['target_ISO_639-1'] = item['target_ISO_639-1'];
            if ('total_statistics' in item){
                if ('answers' in item['total_statistics']){
                    if ('correct' in item['total_statistics']['answers']){
                        o['correct_answers'] = item['total_statistics']['answers']['correct'];
                    }
                    if ('wrong' in item['total_statistics']['answers']){
                        o['wrong_answers'] = item['total_statistics']['answers']['wrong'];
                    }
                }
                if ('number_of_answers' in item['total_statistics']){
                    o['number_of_answers'] = item['total_statistics']['number_of_answers'];
                }
                if ('percentage' in item['total_statistics']){
                    if ('correct' in item['total_statistics']['percentage']){
                        o['percentage_correct'] = item['total_statistics']['percentage']['correct'] + "%";
                    }
                }
            }
            l.push(o);
        }
        
        setData(l);
        setDataFetched(true);
        setFetchingData(false);
    }

    const downloadDataClick = async () => {
        await fetchVocabulary();
    }

  return (
    <>
        <div className="  min-h-screen h-full">
            <h1>Export your {(allLanguages !== null && globalSelectedTargetLanguage !== null) && allLanguages[0][globalSelectedTargetLanguage]['name']['en']} vocabulary</h1>
            <div className="flex flex-col h-96 w-96 m-auto justify-center place-items-center">
                <div className="bg-white flex flex-col gap-2 text-center rounded-lg shadow-lg p-6">
                    <div className="font-bold text-gray-600 text-lg">Export as a CSV file</div>
                    {fetchingData &&
                        <>
                            <div className="text-lg text-center p-4">
                                <FontAwesomeIcon icon={faSpinner} className="fa-pulse" />
                            </div>
                            <div className="text-sm italic text-gray-600 ">
                                Please wait while we fetch your data.
                            </div>
                        </>
                    }
                    {(!fetchingData && !dataFetched) &&
                        <>
                            <div className="text-sm italic text-gray-600 ">
                                We currently support exporting your vocabulary as a CSV file. Let us know if you wish to have more export formats and options in the future.
                            </div>
                            <div>   
                                <Button onClick={downloadDataClick} text="Fetch data" color="charcoal" />
                            </div>
                        </>
                    }
                    {(dataFetched && !fetchingData) &&
                        <>
                            <div className="text-sm italic text-gray-600 ">
                                Your download is ready! Click on the button below to download.
                            </div>
                            <div>   
                                <CSVLink filename={"Yalango_"+((allLanguages !== null && globalSelectedTargetLanguage !== null) ? allLanguages[0][globalSelectedTargetLanguage]['name']['en']:"")+"_vocabulary.csv"} data={data} headers={headers}>
                                    <Button onClick={downloadDataClick} text="Download data" color="green" />
                                </CSVLink>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    </>
  )
}

export default MyVocabularyExport