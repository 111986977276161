import React, {useState, useEffect} from 'react';
import Button from '../../general/Button';
import {Link} from 'react-router-dom';
import Modal from '../../general/Modal';
import {motion} from 'framer-motion';
import useDecks from '../../../hooks/useDecks';
import {projectFirestore, timeStamp} from '../../../firebase/config.js';
import { useAuth } from "../../../contexts/AuthContext";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLoader} from '@fortawesome/pro-solid-svg-icons';

const ModalContent = ({customFields, closeFunction, deck, deck_id, setShowSettingsModal, allLanguages, sourceLanguage, setSourceLanguage}) => {
    //console.log("Deck: ", deck, sourceLanguage);

    const {saveNewCustomFieldApi, 
        editExistingCustomFieldApi, 
        fetchDeckCustomFieldsFromCurrentUserRealtime} = useDecks();
    
    const [savingChanges, setSavingChanges] = useState(false);

    let {currentUser} = useAuth();
    const [updatedSourceLang, setUpdatedSourceLang] = useState(sourceLanguage);
    const [privacySetting, setPrivacySetting] = useState(deck.privacy);
    const [deckName, setDeckName] = useState(deck.name);
    const [youtubeID, setYoutubeID] = useState(deck.hasOwnProperty('youtube_id') ? deck.youtube_id : "");
    const [selectedTab, setSelectedTab] = useState("general");
    const [editCustomFields, setEditCustomFields] = useState(customFields);
    const [showAddNewCustomField, setShowAddNewCustomField] = useState(false);

    // new custom field
    const [newCustomFieldName, setNewCustomFieldName] = useState("");
    const [newCustomFieldType, setNewCustomFieldType] = useState("text");
    const [customFieldsLoading, setCustomFieldsLoading] = useState(false);

    const handleYouTubeIDChange = (e) => {
        setYoutubeID(e.target.value);
    }

    useEffect(()=>{
        if (customFields !== null){
            setEditCustomFields(customFields);
        }
    },[customFields]);

    let deck_description = "";
    if ('description' in deck){
        deck_description = deck.description;
    }
    const [description, setDescription] = useState(deck_description);

    let deck_title = "";
    if ('title' in deck){
        deck_title = deck.title;
    } ;
    const [publicTitle, setPublicTitle] = useState(deck_title);

    const handleChange = (e) => {
        //console.log(e.target.value);
        setUpdatedSourceLang(e.target.value);
    }

    const handlePrivacyChange = (e) => {
        setPrivacySetting(e.target.value);
        //console.log(e.target.value);
    }

    const handleNameChange = (e) => {
        setDeckName(e.target.value);
    }

    const handleEditCustomFieldName = (e, index) => {
        let copy = JSON.parse(JSON.stringify(editCustomFields));
        copy[index]['data']['name'] = e.target.value;
        setEditCustomFields(copy);
    }

    const handleEditCustomFieldType = (e, index) => {
        let copy = JSON.parse(JSON.stringify(editCustomFields));
        copy[index]['data']['type'] = e.target.value;
        setEditCustomFields(copy);
    }

    const handleNewCustomFieldTypeChange = (e) => {
        setNewCustomFieldType(e.target.value);
    }

    const handleNewCustomFieldNameChange = (e) => {
        setNewCustomFieldName(e.target.value);
    }

    const handleTitleChange = (e) => {
        setPublicTitle(e.target.value);
    }

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    }

    const closeModal = () => {
        setShowSettingsModal(false);
        setUpdatedSourceLang("");
    }

    const saveToDatabase = async () => {
        setSavingChanges(true);
        if (showAddNewCustomField && newCustomFieldName !== ""){
            await saveNewCustomFieldClick();
        }
        for (const [index, oldField] of customFields.entries()){
            if (oldField.data.name !== editCustomFields[index].data.name || oldField.data.type !== editCustomFields[index].data.type){
                await editExistingCustomFieldApi(deck, oldField, editCustomFields[index]);
            }
        };

        const collectionRef = projectFirestore.collection('decks');
        const docRef = collectionRef.where('uid', '==', currentUser.uid).where('id', '==', deck_id);

        await docRef.get().then((snapshots)=>{
            if (snapshots.size === 1){
                let doc_id = snapshots.docs[0].id;
                let updateRef = collectionRef.doc(doc_id);
                //youtubeID
                let obj = {
                    'source_ISO_639-1': updatedSourceLang,
                    'privacy': privacySetting,
                    'last_updated_timestamp': timeStamp,
                    'name': deckName, 
                    'title': publicTitle, 
                    'description': description
                };
                if (youtubeID !== "" && youtubeID !== null){
                    obj['youtube_id'] = youtubeID;
                }
                updateRef.update(obj);
            };
        });
        setSourceLanguage(updatedSourceLang);
        closeModal();
        setSavingChanges(false);
    }

    const saveNewCustomFieldClick = async () => {
        if (newCustomFieldName === ""){return null}
        setCustomFieldsLoading(true);
        await saveNewCustomFieldApi(deck, newCustomFieldName, newCustomFieldType);
        setNewCustomFieldName("");
        setNewCustomFieldType("text");
        setCustomFieldsLoading(false);
        setShowAddNewCustomField(false);
    }

    return (
        <>
            <div className="h-86">
            {!savingChanges ? 
                <>
                <h1>Deck settings</h1>
                <div className="flex flex-row whitespace-nowrap overflow-x-auto justify-start">
                    <motion.div onClick={()=>setSelectedTab("general")} whileHover={{scale:1.00}} className={(selectedTab==="general" ? 'bg-persian-green text-white font-bold ' : 'bg-gray-100 ') + "  px-3 py-2 cursor-pointer rounded-sm shadow-sm"}>
                        General
                    </motion.div>
                    <motion.div onClick={()=>setSelectedTab("metadata")} whileHover={{scale:1.00}} className={(selectedTab==="metadata" ? 'bg-persian-green text-white font-bold ' : 'bg-gray-100 ') + "  px-3 py-2 cursor-pointer rounded-sm shadow-sm"}>
                        Sharing
                    </motion.div>
                    <motion.div onClick={()=>setSelectedTab("custom-fields")} whileHover={{scale:1.00}} className={(selectedTab==="custom-fields" ? 'bg-persian-green text-white font-bold ' : 'bg-gray-100 ') + "  px-3 py-2 cursor-pointer rounded-sm shadow-sm"}>
                        Custom fields
                    </motion.div>
                    <motion.div onClick={()=>setSelectedTab("video")} whileHover={{scale:1.00}} className={(selectedTab==="video" ? 'bg-persian-green text-white font-bold ' : 'bg-gray-100 ') + "  px-3 py-2 cursor-pointer rounded-sm shadow-sm"}>
                        Video
                    </motion.div>
                </div>
                {selectedTab === "general" &&
                    <>
                        <div className="grid grid-cols-2 gap-2"> 
                            <div className="font-bold text-gray-600">
                                Name
                            </div>
                            <div className="">
                                <input type="text" className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white" value={deckName} onChange={handleNameChange} />
                            </div>
                            
                            <div className="font-bold text-gray-600">
                                Source language
                            </div>
                            <div className="">
                                <select className="capitalize" onChange={handleChange} value={updatedSourceLang} defaultValue={updatedSourceLang} >
                                    {Object.keys(allLanguages[0]).map((lang, index) => (
                                        <option key={'source_selector_'+index} value={allLanguages[0][lang]['ISO_639-1']}>{allLanguages[0][lang]['name']['en']}</option>
                                    ))}
                                </select>
                            </div>
                        
                            
                        </div>
                        </>
                    }
                    {selectedTab === "metadata" &&
                    <>
                        <div className="grid grid-cols-2 gap-2"> 
                            <div className="font-bold text-gray-600">
                                Privacy
                            </div>
                            <div className="">
                                <select className="capitalize" onChange={handlePrivacyChange} defaultValue={privacySetting}>
                                    <option key={'privacy_selector_private'} value={"private"}>Private</option>
                                    <option key={'privacy_selector_public'} value={"public"}>Public</option>
                                </select>
                            </div>
                            {privacySetting === "public" &&
                                <>
                                    <div className="font-bold text-gray-600">
                                        Public title
                                    </div>
                                    <div className="">
                                        <input type="text" className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white" value={publicTitle} onChange={handleTitleChange} />
                                    </div>
                                
                                    <div className="font-bold text-gray-600">
                                        Public description
                                    </div>
                                    <div className="">
                                        <textarea type="text" className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white" value={description} onChange={handleDescriptionChange} />
                                    </div>
                                    
                                    <div className="font-bold text-gray-600">
                                        Public URL
                                    </div>
                                    <div>
                                        <Link to={"/decks/"+deck.id}>https://yalango.com/decks/{deck.id}</Link>
                                    </div>
                                </>
                            }
                        </div> 
                    </>
                    }
                    {selectedTab === "custom-fields" &&
                        <>
                            {customFieldsLoading && 
                                <div className="text-center">
                                    <FontAwesomeIcon icon={faLoader} className="fa-spin" />
                                </div>
                            }
                            {!customFieldsLoading &&
                            <>
                            <div className="grid grid-cols-2 gap-2 text-center"> 
                                <div className="font-bold bg-charcoal rounded-lg shadow-sm text-white p-2">
                                    Field name
                                </div>
                                <div className="font-bold bg-charcoal rounded-lg shadow-sm text-white p-2">
                                    Field type
                                </div>
                                {editCustomFields !== null && editCustomFields.map((field, index)=>(
                                    <>
                                        <div key={index+"_custom_field_name"} className="">
                                            <input type="text" className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white" value={field.data.name} onChange={(e)=>handleEditCustomFieldName(e,index)} />
                                        </div>
                                        <div key={index+"_custom_field_type"}>
                                            <select className="capitalize" onChange={(e)=>handleEditCustomFieldType(e, index)} defaultValue={field.data.type}>
                                                <option value={"text"}>Text</option>
                                                <option value={"list"}>List</option>
                                            </select>
                                        </div>
                                    </>
                                ))}
                                {showAddNewCustomField &&
                                <>
                                    <div>
                                        <input type="text" className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white" value={newCustomFieldName} onChange={handleNewCustomFieldNameChange} />
                                    </div>
                                    <div>
                                        <select className="capitalize" onChange={handleNewCustomFieldTypeChange} defaultValue={newCustomFieldType}>
                                            <option value={"text"}>Text</option>
                                            <option value={"list"}>List</option>
                                        </select>
                                    </div>
                                </>   
                            }
                            </div>
                            <div className="flex flex-row gap-6 justify-center mb-8">
                            <div onClick={()=>setShowAddNewCustomField(!showAddNewCustomField)} className="underline text-center cursor-pointer ">
                                {showAddNewCustomField ? 'Remove new custom field' : 'Add a custom field'}
                            </div>
                            
                            </div>
                            </>
                            }
                        </>
                    }
                    {selectedTab === "video" &&
                        <>
                            <div className="my-3 flex flex-col gap-2">
                                <div className="text-sm text-gray-600">
                                    Associate a YouTube video with this deck.
                                </div>
                                <div className="flex flex-col place-items-start sm:flex-row sm:place-items-center gap-2 sm:gap-6 justify-start"> 
                                    <div className="font-bold text-gray-600">
                                        YouTube ID
                                    </div>
                                    <div className="">
                                        <input type="text" className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white" value={youtubeID} onChange={handleYouTubeIDChange} />
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                
                
                <div className="flex flex-col-reverse sm:flex-row justify-center gap-2 sm:gap-3 m-3">
                    <Button key="cancel_button" text="Cancel" color="red" onClick={closeModal} />
                    <Button key="save_button" text="Save changes" color="green" onClick={saveToDatabase} />
                </div>
            </>
            : 
            <>
                <div className="text-center text-4xl">
                    <FontAwesomeIcon icon={faLoader} className="fa-spin" />
                </div>
            </>
            }
            </div>
        </>
    )
}

const DeckSettingsModal = ({customFields, closeFunction, deck,deck_id, setShowSettingsModal, allLanguages, sourceLanguage, setSourceLanguage}) => {
    return (
        <>
            {allLanguages !== undefined &&
                <Modal closeFunction={closeFunction} content={<ModalContent customFields={customFields} closeFunction={closeFunction} deck={deck} deck_id={deck_id} setShowSettingsModal={setShowSettingsModal} allLanguages={allLanguages} setSourceLanguage={setSourceLanguage} sourceLanguage={sourceLanguage} />} size="3xl"/>
            }
        </>
    )
}

export default DeckSettingsModal;
