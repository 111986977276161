import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import H1Block from './H1Block';
import H2Block from './H2Block';
import H3Block from './H3Block';
import ParagraphBlock from './ParagraphBlock';
import QuoteBlock from './QuoteBlock';
import TableBlock from './TableBlock';
import {faUpDownLeftRight} from '@fortawesome/pro-solid-svg-icons';


const Block = ({element, onChangeFunction, blockIndex, deleteElement, setValue, contentEditable, draggingId, innerRef, provided, attributes, studyMode, children, preview, openTableSelectorClick, numberOfPreviewRows}) => {
    const align = element.hasOwnProperty("align") ? element.align : "left";
    return (
      <>
  
        <div attributes={attributes} {...provided.draggableProps} ref={innerRef} className="group flex flex-row justify-start focus:outline-none">
          <div contentEditable={false} style={{ userSelect: "none" }} {...provided.dragHandleProps}  className={(!studyMode ? "w-10" : "w-0" ) + " overflow-x-hidden"}>
            {!studyMode &&
            <div contentEditable={false} style={{ userSelect: "none" }} {...provided.dragHandleProps} className="group-hover:block cursor-move opacity-70 hidden text-lg">
              <FontAwesomeIcon contentEditable={false} style={{ userSelect: "none" }} icon={faUpDownLeftRight} />
            </div>
            }
          </div>
          <div className="w-full overflow-visible">
          {element.type === "heading-one" &&
            <H1Block contentEditable={contentEditable}  align={align}  children={children} />
          }
          {element.type === "heading-two" &&
            <H2Block contentEditable={contentEditable}  align={align}  children={children} />
          }
          {element.type === "heading-three" &&
            <H3Block contentEditable={contentEditable}  align={align} children={children} />
          }
          {element.type === "paragraph" &&
            <ParagraphBlock blockIndex={blockIndex} onChangeFunction={onChangeFunction} setValue={setValue} contentEditable={contentEditable}  draggingId={draggingId}  provided={provided} align={align}  children={children} />
          }
          {element.type === "quote" &&
            <QuoteBlock contentEditable={contentEditable}  align={align} children={children} />
          }
          {element.type === "table-widget" &&
            <TableBlock blockIndex={blockIndex} draggingId={draggingId} provided={provided} numberOfPreviewRows={numberOfPreviewRows} contentEditable={false} style={{ userSelect: "none" }} element={element} studyMode={studyMode} openTableSelectorClick={openTableSelectorClick} deleteElement={deleteElement} align={align} children={children} />
          }
          </div>
        </div>
      </>
    )
}
export default Block;