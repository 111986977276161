import React,{useState, useEffect} from 'react';
import {motion} from 'framer-motion';
import Button from '../../../general/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/pro-solid-svg-icons';
import { faThumbsDown } from '@fortawesome/pro-solid-svg-icons';

const TableFlashcardsGame = ({isInMix, globalHandleWrongAnswer, globalHandleCorrectAnswer, globalHandleSkipAnswer, id, setItemsLeft, designMode, collectStatistics, highlightColumnId, setGameScreen, gameScreen, setNbOfAnswers, nbOfAnswers, setScore, score, itemsLeft, table, tableRows, tableColumns, backToPlayPageLink}) => {

  const [currentIndex, setCurrentIndex] = useState(0);
  const [flippedColumnIds, setFlippedColumnIds] = useState([]);
  const [columnsFinished, setColumnsFinished] = useState([]);
  const [columnIds, setColumnIds] = useState(null);
  const [flashcardsScreen, setFlashCardsScreen] = useState("cards");

/*   useEffect(()=>{
    let l = [];
    for (const col of tableColumns){
      if (parseInt(col.id) !== parseInt(highlightColumnId)){
        l.push(parseInt(col.id));
      }
    }
    setColumnIds(l);
  },[tableColumns, highlightColumnId]); */
  
  const flipCard = () => {
    for (const col of tableColumns){
      if (!flippedColumnIds.includes(parseInt(col.id)) && col.id !== highlightColumnId){
        let previousLength = flippedColumnIds.length;
        setFlippedColumnIds([...flippedColumnIds, parseInt(col.id)]);
        //timeOutCard(col.id);
/*         setTimeout(()=>{
          if ((previousLength + 1) === (tableColumns.length - 1)){
            setFlashCardsScreen("row-finished");
          } else {
            setFlashCardsScreen("cards");
          }
        },[1000]); */
        break;
      }
      if ((flippedColumnIds.length) === (tableColumns.length - 1)){
        setFlashCardsScreen("row-finished");
      } else {
        setFlashCardsScreen("cards");
      }
    }
  }

  useEffect(()=>{
    if (tableRows !== null){
      setFlashCardsScreen("cards");
      setFlippedColumnIds([]);
      setColumnsFinished([]);
      setCurrentIndex(0);
    }
  },[tableRows]);

  const timeOutCard = (columnId) => {
    setTimeout(()=>{
      finishCard(parseInt(columnId)); 
    },[1000]);
  }

  const finishCard = (columnId) =>{
    setColumnsFinished([...columnsFinished, columnId]);
  }

  const flipSpecificCard = (columnId) => {
    if (!flippedColumnIds.includes(parseInt(columnId))){
      setFlippedColumnIds([...flippedColumnIds, parseInt(columnId)]);
      //timeOutCard(columnId);
      let previousLength = flippedColumnIds.length;
      setFlippedColumnIds([...flippedColumnIds, parseInt(columnId)]);
      //timeOutCard(columnId);
/*       setTimeout(()=>{
        if ((previousLength + 1) === (tableColumns.length - 1)){
          setFlashCardsScreen("row-finished");
        } else {
          setFlashCardsScreen("cards");
        }
      },[1000]); */
    } else {
      let newList = [];
      for (const id of flippedColumnIds) {
        if (parseInt(id) !== parseInt(columnId)){
          newList.push(id);
        }
      }
      setFlippedColumnIds(newList);
    }
  }
  
  const wrongClick = () => {
    if (!isInMix){
      setNbOfAnswers(nbOfAnswers+1);
      collectStatistics({'row': tableRows[currentIndex], 'correct': false});
      nextRow();
    }
    if (isInMix){
      collectStatistics({'row': tableRows[currentIndex], 'correct': false});
      globalHandleWrongAnswer();
    }
  }

  const correctClick = () => {
    if (!isInMix){
      setScore(score+1);
      setNbOfAnswers(nbOfAnswers+1);
      collectStatistics({'row': tableRows[currentIndex], 'correct': true});
      nextRow();
    }
    if (isInMix){
      collectStatistics({'row': tableRows[currentIndex], 'correct': true});
      globalHandleCorrectAnswer();
    }
  }

  const nextRow = () => {
    if (currentIndex < tableRows.length-1){
      setCurrentIndex(currentIndex+1);
      setColumnsFinished([]);
      setFlippedColumnIds([]);
      setItemsLeft(tableRows.length-(currentIndex+1));
      setFlashCardsScreen("cards");
    } else {
      setGameScreen("endscreen");
    }
  }

/*   useEffect(()=>{
    const nextRow = () => {
      if (currentIndex < tableRows.length-1){
        setCurrentIndex(currentIndex+1);
        setColumnsFinished([]);
        setFlippedColumnIds([]);
      } else {
        setGameScreen("endscreen");
      }
    }

    let finished = true;
    for (const col of tableColumns){
      if ((parseInt(col.id) !== parseInt(highlightColumnId)) && !columnsFinished.includes(parseInt(col.id))){
        finished = false;
      }
    }
    if (!finished){
      nextRow();
    }
  },[tableColumns, highlightColumnId, columnsFinished, tableRows, currentIndex, setGameScreen]); */

  document.body.onkeyup = function(e){
    if(e.key === " " || e.key === "ArrowDown"){
      // spacebar
      flipCard();
    }
    else if (e.key === "ArrowLeft"){
      wrongClick();
    }
    else if (e.key === "ArrowRight"){
      correctClick();
    }
  }


  return (
    <>
      {gameScreen === "game" &&
        <>
          <div className="flex py-8 flex-col justify-center place-items-center gap-3 relative h-full">
            {isInMix !== true &&
              <div className="flex flex-row justify-center w-full text-sm gap-8">
                <div>
                  <span className="font-bold text-gray-600">Items left</span>: {itemsLeft}
                </div>
                <div>
                <span className="font-bold text-gray-600">Score</span>: {score}
                </div>
              </div>
            }
            {flashcardsScreen === "cards" &&
              <>
              <div className="font-bold text-gray-600 text-3xl my-2">
              {tableRows !== null && Object.entries(tableRows[currentIndex].values).map(([columnId, value], valIndex)=>(
                  <>
                    {parseInt(columnId) === parseInt(highlightColumnId) && 
                      <>
                        {value}
                      </>
                    }
                  </>
              ))}
              </div>
              <motion.div layout className="flex xl:flex-row flex-col w-full  gap-8 justify-center place-items-center max-h-128 p-4 overflow-y-auto">
                {tableColumns !== null && tableColumns.map((tableColumn, colIndex)=>(
                  <>
                    {(!columnsFinished.includes(parseInt(tableColumn['id'])) && parseInt(highlightColumnId) !== parseInt(tableColumn['id'])) &&
                      <>
                    <div key={"value_"+colIndex} className="flex flex-col gap-3" >
                      <div className={"w-64 h-64 overflow-y-auto z-10 rounded-lg shadow-md transition transition-duration-300 ease-in cursor-pointer   " + ((flippedColumnIds !== null && flippedColumnIds.includes(parseInt(tableColumn['id'])) ? ' bg-charcoal text-white ' : ' bg-gradient-radial from-white to-white text-gray-600 '))} onClick={()=>flipSpecificCard(tableColumn['id'])}>
                        <div className="flex justify-center place-items-center h-full relative">
                          {(flippedColumnIds !== null && flippedColumnIds.includes(parseInt(tableColumn['id']))) ? 
                              <>
                                  <div className="text-lg font-bold ">
                                  {tableRows[currentIndex]['values'][tableColumn['id']]}
                                  </div>
                                  <div className=" font-bold absolute top-0 left-0 p-3 text-sm">
                                    {tableColumn.name}
                                  </div>
                              </>
                            :
                              <>
                                  <div className="text-lg font-bold ">
                                  {tableColumn.name}
                                  </div>
                                  
                              </>
                          }
                        </div>
                      </div>
                      
                    </div>
                    </>
                    }
                  </>
                ))}
              
              </motion.div>
            </>
            }
            {flashcardsScreen === "row-finished" &&
              <>
                <div className="h-full justify-center flex flex-col gap-16">
                  <div className="text-lg sm:text-3xl font-bold text-gray-600">Did you get this row correct?</div>
                  <div className="flex flex-col gap-2 place-items-center max-h-64 overflow-y-auto">
                     {tableColumns !== null && tableColumns.map((tableColumn, colIndex)=>(
                        <>
                          {tableRows !== null && Object.entries(tableRows[currentIndex].values).map(([columnId, value], valIndex)=>(
                            <>
                              {(parseInt(tableColumn.id) === parseInt(columnId)) &&
                                <div className="flex flex-row gap-6" key={"solution_"+valIndex}>
                                  <div className="font-bold">
                                    {tableColumn.name}:
                                  </div>
                                  <div>
                                    {value}
                                  </div>
                                </div>
                              }
                            </>
                          ))}
                        </>
                    ))}
                  </div>
                </div>
              </>
            }
            <div className={(designMode !== "custom" ? 'fixed bottom-28 '  : 'absolute bottom-28 ' ) + " flex flex-row justify-center w-5/6 md:w-1/3 m-auto gap-2  sm:bottom-16  z-10"}>
              {flashcardsScreen === "row-finished" &&
                <motion.button whileHover={{scale:1.01}} onClick={()=>wrongClick()} className="bg-burnt-sienna w-full text-white px-3 rounded-lg shadow-lg">
                  <FontAwesomeIcon icon={faThumbsDown} />
                </motion.button>
              }
              {(flashcardsScreen === "cards" && flippedColumnIds !== null && tableColumns !== null) &&
                <motion.button  onClick={()=>flipCard()} className="bg-charcoal whitespace-nowrap w-full font-bold text-white p-3 rounded-lg shadow-lg">
                  {(flippedColumnIds.length) === (tableColumns.length - 1) ? "Finish row" : "Flip next"}
                </motion.button>
              }
              {flashcardsScreen === "row-finished" &&
                <motion.button  onClick={()=>correctClick()} className="bg-persian-green w-full text-white p-3 rounded-lg shadow-lg">
                  <FontAwesomeIcon icon={faThumbsUp} />
                </motion.button>
              }
            </div>
              
            
          </div>
        </>
      }
    </>
  )
}

export default TableFlashcardsGame;