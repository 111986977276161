import React from 'react';
import HelpCenterHeaderSvg from '../../assets/svg/pages/help-center/HelpCenterHeader.svg';

const HelpArticleHeader = ({article}) => {

    return (
        <>
            <div className="w-full relative ">
                <img src={HelpCenterHeaderSvg} alt="Help center illustration" className="w-full sm:w-1/2 m-auto" />
            </div>
        </>
    )
}

export default HelpArticleHeader