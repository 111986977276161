import React, {useState, useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {motion} from 'framer-motion';
import {faUser} from '@fortawesome/pro-duotone-svg-icons';
import {faPlay} from '@fortawesome/pro-duotone-svg-icons';
import {faThumbsUp} from '@fortawesome/pro-solid-svg-icons';
import {faPencil} from '@fortawesome/pro-duotone-svg-icons';
import {faVideo} from '@fortawesome/pro-solid-svg-icons';
import {faComment} from '@fortawesome/pro-solid-svg-icons';
import {faTrash} from '@fortawesome/pro-duotone-svg-icons';
import {faHeart} from '@fortawesome/pro-regular-svg-icons';
import {faCardsBlank} from '@fortawesome/pro-solid-svg-icons';
import {faArrowRight}  from '@fortawesome/pro-solid-svg-icons';
import {faHeart as faHeartSolid} from '@fortawesome/pro-solid-svg-icons';
import {faCopy} from '@fortawesome/pro-duotone-svg-icons';
import {faEllipsisV} from '@fortawesome/pro-solid-svg-icons';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import CountryFlag from '../../components/languages/CountryFlag';
import Icon from '../../components/general/Icon';
import useFavorites from '../../hooks/useFavorites';
import { useUserInfoContext } from '../../contexts/UserInfoContext';
import { useLanguagesContext } from '../../contexts/LanguagesContext';
import { useAuth } from '../../contexts/AuthContext';


const DecksCard = ({closeDeckMenu, location="public", openDeckMenuId, openDeckMenuClick, duplicateDeckClick, allDecksViewOpenDeleteDeckModal, deleteDeck, showAdminOptions=false, deck, baseLink="decks", showProfileLink=true}) => {

    let {currentUser} = useAuth();
    let { favorites } = useUserInfoContext();
    let {addOrRemoveDeckFavorite} = useFavorites();
    let {allLanguages} = useLanguagesContext();

    if (location === "library"){
        baseLink = "my-decks";
    }

    const [id, setId] = useState(null);
    const [title, setTitle] = useState(null);
    const [description, setDescription] = useState(null);
    const [processedDeck, setProcessedDeck] = useState(deck);

    useEffect(()=>{
        if (deck !== undefined && deck !== null){
            if ('typesense_deck_id' in deck){
                setId(deck['typesense_deck_id']);
            } else if ('id' in deck){
                setId(deck['id']);
            } else if ('deck_id' in deck){
                setId(deck['deck_id']);
            }
        
            if ('title' in deck){
                if ('name' in deck && deck['title'] === "" && deck['name'] !== ""){
                    setTitle(deck['name']);
                } else {
                    setTitle(deck['title']);
                }
            } else if ('name' in deck){
                setTitle(deck['name']);
            } else if ('deck_name' in deck){
                setTitle(deck['deck_name']);
            }
        
            if ('description' in deck){
                setDescription(deck['description']);
            } else if ('deck_description' in deck){
                setDescription(deck['deck_description']);
            }

            let newObject = {...deck}
            if (newObject.hasOwnProperty('author.username') === false){
                newObject['author.username']= null;
            }
            if (!newObject.hasOwnProperty(["author.displayname"])){
                newObject['author.displayname'] = null;
                setShowProfileLinkState(false);
            }
            setProcessedDeck(newObject);
        }
    },[deck]);

    const [currentFavorite, setCurrentFavorite] = useState(null);
    const [myDecks, setMyDecks] = useState(null);

    const addToFavoritesClick = async (e) => {
        e.stopPropagation();
        await addOrRemoveDeckFavorite(processedDeck);
    }

    const [showProfileLinkState, setShowProfileLinkState] = useState(showProfileLink);
    const [showAdminOptionsState, setShowAdminOptionsState] = useState(showAdminOptions);

    function getRndInteger(min, max) {
        return Math.floor(Math.random() * (max - min + 1) ) + min;
      }
    


    const navigate = useNavigate();
    let colors = ['green', 'red', 'yellow', 'blue', 'gray', 'indigo', 'purple', 'pink'];
    let color = colors[getRndInteger(0, colors.length-1)];
    let color2 = colors[getRndInteger(0, colors.length-1)];
    
    const deckClick = () => {
        navigate('/'+baseLink+'/'+id);
    }

    //bg-gradient-to-r from-" + color +"-200 via-"+color+"-200 to-"+color2+"-200 hover:from-"+color2+"-200 hover:to-"+color+"-200

    while (color === color2) {
        color2 = colors[getRndInteger(0, colors.length-1)];
    }

    useEffect(()=>{
        if (favorites !== undefined && deck !== undefined){
            if (favorites !== null && 'decks' in favorites[0] && deck !== null && deck['typesense_deck_id'] in favorites[0]['decks']){
                setCurrentFavorite(true);
            } else {
                setCurrentFavorite(false);
            }
        } 
    },[favorites, processedDeck]);


    console.log("processedDeck ", processedDeck, "language ", allLanguages, processedDeck['source_ISO_639-1'], processedDeck['target_ISO_639-1']);

    /* 
                    {!myDecks && deck['author.username'] === "yalango" ?
                        <div onClick={(e)=> e.stopPropagation()} className="absolute text-xs font-bold right-0 bottom-0 p-2 text-gray-600">
                            <Link className="no-underline" to="/u/yalango">Yalango</Link>
                        </div>
                        :
                        <>
                            {(deck['author.username'] !== null && deck['author.username'] !== "" && deck['author.displayname'] !== null && deck['author.displayname'] !== "") &&
                                <>
                                    <div onClick={(e)=> e.stopPropagation()} className="absolute text-xs font-bold right-0 bottom-0 p-2 text-gray-600">
                                        <Link className="no-underline" to={"/u/"+deck['author.username']}>{deck['author.displayname']}</Link>
                                    </div>
                                </>
                            }
                        </>
                    }

                    <div onClick={(e)=> e.stopPropagation()}  className="absolute overflow-visible text-base w-36 z-10 rounded-lg shadow-xl bg-white right-0 top-0">
                                    <div className="flex font-bold text-gray-700 flex-col justify-start place-items-start">
                                        <Link to={'/'+baseLink+'/'+id+'/play'} className="no-underline w-full" >
                                        <div className="flex flex-row gap-3 hover:bg-gray-100 w-full rounded-lg  p-3">
                                            <div>
                                                <Icon color="green" icon={<FontAwesomeIcon icon={faPlay} />} />
                                            </div>
                                            <div>
                                                Play
                                            </div>
                                        </div>
                                        </Link>
                                        {baseLink === "my-decks" &&
                                            <Link to={'/'+baseLink+'/'+id} className="no-underline w-full" >
                                            <div className="flex flex-row gap-3 hover:bg-gray-100 w-full rounded-lg  p-3">
                                                <div>
                                                    <Icon color="blue" icon={<FontAwesomeIcon icon={faPencil} />} />
                                                </div>
                                                <div>
                                                    Edit
                                                </div>
                                            </div>
                                            </Link>
                                        }
                                        {baseLink === "my-decks" &&
                                            <div onClick={(e)=>duplicateDeckClick(e,processedDeck)} className="flex flex-row gap-3 hover:bg-gray-100 w-full rounded-lg  p-3">
                                                <div>
                                                    <Icon color="charcoal" icon={<FontAwesomeIcon icon={faCopy} />} />
                                                </div>
                                                <div>
                                                    Duplicate
                                                </div>
                                            </div>
                                        }
                                        {baseLink === "my-decks" &&
                                            <div onClick={(e)=>allDecksViewOpenDeleteDeckModal(e, processedDeck)} className="flex flex-row gap-3 hover:bg-gray-100 w-full rounded-lg  p-3">
                                                <div>
                                                    <Icon color="red" icon={<FontAwesomeIcon icon={faTrash} />} />
                                                </div>
                                                <div>
                                                    Delete
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
    */

    return (
        <>
            {processedDeck !== null && processedDeck !== undefined &&
                <div className="relative  w-full h-full flex flex-col  justify-center place-items-center overflow-visible">
                    <div className="absolute w-full -top-2 -left-2 z-0  h-full border border-solid border-gray-100 overflow-y-auto rounded-lg shadow-md cursor-pointer ">  
                    </div>
                    <div className="absolute w-full -top-1 -left-1 z-0  h-full border border-solid border-gray-100 overflow-y-auto  rounded-lg shadow-md cursor-pointer ">  
                    </div>
                    <motion.div onClick={deckClick} whileHover={{y:-3}} className={"relative shadow-lg rounded-lg w-full h-full p-4 "+(processedDeck['author.username'] === "yalango" ? " bg-white  text-black " : "  w-full bg-white  text-black ") + "shadow-lg cursor-pointer relative border border-solid border-gray-100 flex flex-col justify-evenly"}>
                        {allLanguages !== null && allLanguages !== undefined && processedDeck !== null && processedDeck !== undefined && processedDeck.hasOwnProperty('source_ISO_639-1') && processedDeck.hasOwnProperty('target_ISO_639-1') && processedDeck["source_ISO_639-1"] !== "" && processedDeck["target_ISO_639-1"] !== "" && processedDeck["source_ISO_639-1"] !== null && processedDeck["target_ISO_639-1"] !== null &&
                            <div className="flex absolute  p-2  left-1 top-1 flex-row place-items-center justify-start gap-5">
                                <div className="flex flex-row place-items-center text-charcoal gap-1  ">
                                   
                                    <div className="rounded-lg shadow-xl overflow-hidden ">
                                        <CountryFlag flagName={allLanguages[0][processedDeck['source_ISO_639-1']]['name']['en']} countryCode={allLanguages[0][processedDeck['source_ISO_639-1']]['country_flag']} size="1.5em" />
                                    </div>
                                    <div className="text-xs">
                                        <FontAwesomeIcon icon={faArrowRight} />
                                    </div>
                                    <div className="rounded-lg shadow-xl overflow-hidden">
                                        <CountryFlag flagName={allLanguages[0][processedDeck['target_ISO_639-1']]['name']['en']} countryCode={allLanguages[0][processedDeck['target_ISO_639-1']]['country_flag']} size="1.5em" />
                                    </div>
                                   
                                </div>
                            
                            </div>
                        }
                        {showAdminOptions &&
                        <div onClick={(e)=> e.stopPropagation()} className="absolute right-0 top-0 p-2 text-2xl">
                            <Icon onBlur={closeDeckMenu} onClick={(e)=>openDeckMenuClick(e, id)} color="white" icon={<FontAwesomeIcon icon={faEllipsisV} />} /> 
                        </div>
                        }
                        <div className="absolute left-0 bottom-0 p-2 text-xs text-gray-600 flex flex-row gap-3">
                            <div className="flex flex-row gap-1 ">
                                <div>
                                    <FontAwesomeIcon icon={faCardsBlank} />
                                </div>
                                <div className="font-bold">{processedDeck.hasOwnProperty("number_of_items") ? processedDeck['number_of_items'] : 0}</div> 
                            </div>
                            <div className="flex flex-row gap-1">
                                <div className="">
                                    <FontAwesomeIcon icon={faThumbsUp} />
                                </div>
                                <div className="font-bold">
                                    {processedDeck.hasOwnProperty("rating") ?
                                        <>
                                            {processedDeck.rating}
                                        </>
                                    :
                                        <>
                                            0
                                        </>
                                    }
                                </div> 
                            </div>
                            <div className="flex flex-row gap-1">
                                <div>
                                    <FontAwesomeIcon icon={faComment} />
                                </div>
                                <div className="font-bold">
                                    {processedDeck.hasOwnProperty("number_of_comments") ?
                                        <>
                                            {processedDeck.number_of_comments}
                                        </>
                                    :
                                        <>
                                            0
                                        </>
                                    }
                                </div> 
                            </div>
                            
                            <div>
                                {processedDeck.hasOwnProperty("youtube_id") && processedDeck.youtube_id !== "" && processedDeck.youtube_id !== null &&
                                    <div className="">
                                        <FontAwesomeIcon icon={faVideo} />
                                    </div>
                                }
                            </div>
                            
                        </div>

                        {(openDeckMenuId === id) && 
                            <>
                                
                            </>
                        }

                        {(currentUser !== undefined && currentUser !== null && currentUser.uid !== null && currentUser.uid !== processedDeck.uid && currentFavorite !== null) &&
                            <div className=" border border-red-200 border-dotted rounded-full absolute -right-1 -top-1 shadow-xl p-1 px-2 text-lg" onClick={(e)=>addToFavoritesClick(e)}>
                                <Icon includeOpacityChange={false} data-for="public-deck-tooltip" data-tip="Add to favorites" color={currentFavorite ? "red" : "red"} icon={<FontAwesomeIcon icon={currentFavorite ? faHeartSolid : faHeart} />} />
                            </div>
                        }
                        <div className="p-4 max-h-48 overflow-auto gap-4 flex flex-col">
                            <div className="text-base text-center font-bold flex flex-row justify-center gap-2">
                                <div>
                                    {title}
                                </div>
                            </div>
                            <div className="text-sm italic max-h-32 overflow-auto text-center">{description}</div>
  
                            {showProfileLinkState &&
                                <>
                                    <div className="text-xs ">
                                        <div onClick={(e)=> e.stopPropagation()}  className="flex flex-row gap-2 justify-center">
                                            {(processedDeck['author.username'] !== null && processedDeck['author.username'] !== "") && 
                                            <>
                                                <Icon color="persian-green" icon={<FontAwesomeIcon icon={faUser} />} /> 
                                                <Link to={'/u/'+  processedDeck['author.username']}>
                                                    {processedDeck['author.displayname'] !== '' ? processedDeck['author.displayname'] : processedDeck['uid']} 
                                                </Link>
                                            </>
                                            }
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </motion.div>
                    
                </div>
            }
         </>
    )
}

export default DecksCard;
