import React from 'react';
import Ge from '../assets/svg/flags/ge.svg';
import Dj from '../assets/svg/flags/dj.svg';
import Za from '../assets/svg/flags/za.svg';
import Gh from '../assets/svg/flags/gh.svg';
import Al from '../assets/svg/flags/al.svg';
import Et from '../assets/svg/flags/et.svg';
import Eg from '../assets/svg/flags/eg.svg';
import Es from '../assets/svg/flags/es.svg';
import Am from '../assets/svg/flags/am.svg';
import In from '../assets/svg/flags/in.svg';
import Ru from '../assets/svg/flags/ru.svg';
import Ir from '../assets/svg/flags/ir.svg';
import Bo from '../assets/svg/flags/bo.svg';
import Az from '../assets/svg/flags/az.svg';
import Ml from '../assets/svg/flags/ml.svg';
import By from '../assets/svg/flags/by.svg';
import Bd from '../assets/svg/flags/bd.svg';
import Vu from '../assets/svg/flags/vu.svg';
import Ba from '../assets/svg/flags/ba.svg';
import Fr from '../assets/svg/flags/fr.svg';
import Bg from '../assets/svg/flags/bg.svg';
import Mm from '../assets/svg/flags/mm.svg';
import Gu from '../assets/svg/flags/gu.svg';
import Mw from '../assets/svg/flags/mw.svg';
import Cn from '../assets/svg/flags/cn.svg';
import Gb from '../assets/svg/flags/gb.svg';
import Ca from '../assets/svg/flags/ca.svg';
import Hr from '../assets/svg/flags/hr.svg';
import Cz from '../assets/svg/flags/cz.svg';
import Dk from '../assets/svg/flags/dk.svg';
import Nl from '../assets/svg/flags/nl.svg';
import Bt from '../assets/svg/flags/bt.svg';
import Us from '../assets/svg/flags/us.svg';
import Esperanto from '../assets/svg/flags/esperanto.svg';
import Basque from '../assets/svg/flags/basque.svg';
import Kurdistan from '../assets/svg/flags/kurdistan.svg';
import Abkhazia from '../assets/svg/flags/abkhazia.svg';
import Interlingua from '../assets/svg/flags/interlingua.svg';
import Galicia from '../assets/svg/flags/galicia.svg';
import Sami from '../assets/svg/flags/sami.svg';
import Ee from '../assets/svg/flags/ee.svg';
import Tg from '../assets/svg/flags/tg.svg';
import Fo from '../assets/svg/flags/fo.svg';
import Fj from '../assets/svg/flags/fj.svg';
import Fi from '../assets/svg/flags/fi.svg';
import Gn from '../assets/svg/flags/gn.svg';
import Ug from '../assets/svg/flags/ug.svg';
import De from '../assets/svg/flags/de.svg';
import Gr from '../assets/svg/flags/gr.svg';
import Gl from '../assets/svg/flags/gl.svg';
import Py from '../assets/svg/flags/py.svg';
import Ht from '../assets/svg/flags/ht.svg';
import Ng from '../assets/svg/flags/ng.svg';
import Il from '../assets/svg/flags/il.svg';
import Na from '../assets/svg/flags/na.svg';
import Pg from '../assets/svg/flags/pg.svg';
import Hu from '../assets/svg/flags/hu.svg';
import Is from '../assets/svg/flags/is.svg';
import Io from '../assets/svg/flags/io.svg';
import Id from '../assets/svg/flags/id.svg';
import Ie from '../assets/svg/flags/ie.svg';
import It from '../assets/svg/flags/it.svg';
import Jp from '../assets/svg/flags/jp.svg';
import Kz from '../assets/svg/flags/kz.svg';
import Kh from '../assets/svg/flags/kh.svg';
import Ke from '../assets/svg/flags/ke.svg';
import Cd from '../assets/svg/flags/cd.svg';
import Rw from '../assets/svg/flags/rw.svg';
import Bi from '../assets/svg/flags/bi.svg';
import Kr from '../assets/svg/flags/kr.svg';
import Tr from '../assets/svg/flags/tr.svg';
import Ao from '../assets/svg/flags/ao.svg';
import Kg from '../assets/svg/flags/kg.svg';
import La from '../assets/svg/flags/la.svg';
import Lv from '../assets/svg/flags/lv.svg';
import Lt from '../assets/svg/flags/lt.svg';
import Lb from '../assets/svg/flags/lb.svg';
import Mk from '../assets/svg/flags/mk.svg';
import Mg from '../assets/svg/flags/mg.svg';
import Mv from '../assets/svg/flags/mv.svg';
import Mt from '../assets/svg/flags/mt.svg';
import My from '../assets/svg/flags/my.svg';
import Im from '../assets/svg/flags/im.svg';
import Nz from '../assets/svg/flags/nz.svg';
import Mh from '../assets/svg/flags/mh.svg';
import Md from '../assets/svg/flags/md.svg';
import Mn from '../assets/svg/flags/mn.svg';
import Nr from '../assets/svg/flags/nr.svg';
import Np from '../assets/svg/flags/np.svg';
import Zw from '../assets/svg/flags/zw.svg';
import No from '../assets/svg/flags/no.svg';
import Af from '../assets/svg/flags/af.svg';
import Pl from '../assets/svg/flags/pl.svg';
import Br from '../assets/svg/flags/br.svg';
import Pk from '../assets/svg/flags/pk.svg';
import Pe from '../assets/svg/flags/pe.svg';
import Ro from '../assets/svg/flags/ro.svg';
import Ch from '../assets/svg/flags/ch.svg';
import As from '../assets/svg/flags/as.svg';
import Cf from '../assets/svg/flags/cf.svg';
import Rs from '../assets/svg/flags/rs.svg';
import Lk from '../assets/svg/flags/lk.svg';
import Sk from '../assets/svg/flags/sk.svg';
import Si from '../assets/svg/flags/si.svg';
import So from '../assets/svg/flags/so.svg';
import Ls from '../assets/svg/flags/ls.svg';
import Se from '../assets/svg/flags/se.svg';
import Ph from '../assets/svg/flags/ph.svg';
import Tj from '../assets/svg/flags/tj.svg';
import Va from '../assets/svg/flags/va.svg';
import Th from '../assets/svg/flags/th.svg';
import To from '../assets/svg/flags/to.svg';
import Tm from '../assets/svg/flags/tm.svg';
import Ua from '../assets/svg/flags/ua.svg';
import Uz from '../assets/svg/flags/uz.svg';
import Vn from '../assets/svg/flags/vn.svg';
import Tibet from '../assets/svg/flags/tibet.svg';
import Volapuk from '../assets/svg/flags/volapuk.svg';
import Catalonia from '../assets/svg/flags/catalonia.svg';
import Yiddish from '../assets/svg/flags/yiddish.svg';
import Wales from '../assets/svg/flags/wales.svg';
import Breton from '../assets/svg/flags/breton.svg';
import Be from '../assets/svg/flags/be.svg';
import Sn from '../assets/svg/flags/sn.svg';
import Cornwall from '../assets/svg/flags/cornwall.svg';
import Tahiti from '../assets/svg/flags/tahiti.svg';
import Sardinia from '../assets/svg/flags/sardinia.svg';

const useFlags = () => {

    let flags = {
        ge: Ge,
        dj: Dj,
        za: Za,
        gh: Gh,
        al: Al,
        et: Et,
        eg: Eg,
        es: Es,
        am: Am,
        in: In,
        ru: Ru,
        ir: Ir,
        bo: Bo,
        az: Az,
        ml: Ml,
        by: By,
        bd: Bd,
        vu: Vu,
        ba: Ba,
        fr: Fr,
        bg: Bg,
        mm: Mm,
        gu: Gu,
        mw: Mw,
        cn: Cn,
        gb: Gb,
        ca: Ca,
        hr: Hr,
        cz: Cz,
        dk: Dk,
        nl: Nl,
        bt: Bt,
        us: Us,
        ee: Ee,
        tg: Tg,
        fo: Fo,
        fj: Fj,
        fi: Fi,
        gn: Gn,
        ug: Ug,
        de: De,
        gr: Gr,
        gl: Gl,
        py: Py,
        ht: Ht,
        ng: Ng,
        il: Il,
        na: Na,
        pg: Pg,
        hu: Hu,
        is: Is,
        io: Io,
        id: Id,
        ie: Ie,
        it: It,
        jp: Jp,
        kz: Kz,
        kh: Kh,
        ke: Ke,
        cd: Cd,
        rw: Rw,
        bi: Bi,
        kr: Kr,
        tr: Tr,
        ao: Ao,
        kg: Kg,
        la: La,
        lv: Lv,
        lt: Lt,
        lb: Lb,
        mk: Mk,
        mg: Mg,
        mv: Mv,
        mt: Mt,
        im: Im,
        nz: Nz,
        mh: Mh,
        md: Md,
        mn: Mn,
        nr: Nr,
        np: Np,
        zw: Zw,
        no: No,
        af: Af,
        pl: Pl,
        br: Br,
        pk: Pk,
        pe: Pe,
        ro: Ro,
        ch: Ch,
        as: As,
        cf: Cf,
        rs: Rs,
        lk: Lk,
        sk: Sk,
        si: Si,
        so: So,
        ls: Ls,
        se: Se,
        ph: Ph,
        tj: Tj,
        th: Th,
        to: To,
        tm: Tm,
        ua: Ua,
        uz: Uz,
        vn: Vn,
        va: Va,
        volapuk: Volapuk,
        kurdistan: Kurdistan,
        be: Be,
        sn: Sn, 
        esperanto: Esperanto,
        interlingua: Interlingua, 
        abkhazia: Abkhazia, 
        basque: Basque, 
        galicia: Galicia, 
        catalonia: Catalonia, 
        yiddish:Yiddish, 
        sami: Sami,
        wales: Wales,
        cornwall: Cornwall,
        breton: Breton, 
        tahiti: Tahiti,
        sardinia: Sardinia, 
        tibet: Tibet, 
        my: My
    }
    const fetchFlag = (code) => {
        return flags[code];
    }

    return {
        fetchFlag
    };
}

export default useFlags;