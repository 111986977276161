import React from 'react';
import UserComment from './UserComment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-duotone-svg-icons';

const RenderComments = (props) => {
    const {loading, comments, refreshComments, commentLocation} = props;
  return (
    <>
        <div className="w-full  m-auto flex flex-col justify-start gap-0 place-items-center">
            {!loading && comments !== null && comments.map((comment, commentIndex)=>(
                <>
                    {comment !== null &&
                        <div className={(loading ? 'opacity-30' : 'opacity-100') + " w-full border-solid border-t border-b border-white"} key={"comment_"+commentIndex}>
                            <UserComment displaySourceInformation={commentLocation === "feed" && comment.comment_source !== "feed" ? true : false} commentLocation={commentLocation} refreshComments={refreshComments} comment={comment} />
                        </div>
                    }
                   
                </>
            ))}
             {comments !== null && comments.length === 0 &&
                <>
                    <div className="text-gray-600">
                        Nothing to show here yet. Start the conversation now!
                    </div>
                </>
            }
            {loading &&
                <>
                    <div className="flex flex-col justify-center my-8 text-center text-3xl">
                        <FontAwesomeIcon icon={faSpinner} className="fa-pulse" />
                    </div>
                </>
            }
        </div>
    </>
  )
}

export default RenderComments