import {projectFirestore, timeStamp} from '../firebase/config.js';
import { useAuth } from "../contexts/AuthContext";
import {useUserInfoContext} from '../contexts/UserInfoContext';

export default function useRatings() {
    
    let {currentUser} = useAuth();
    const {userInfo} = useUserInfoContext();

    const fetchAllRatingsFromCurrentUser = async () => {
        let ref = projectFirestore.collection("user-actions").doc("ratings").collection("items").where("uid", "==", currentUser.uid);
        let results = await ref.get().catch(err=>console.log(err));
        if (results === undefined || results.empty){return []}
        let ratings = [];
        results.forEach((comment, commentIndex)=>{
            let o = {...comment.data()};
            o['doc_id'] = comment.ref.id;
            ratings.push(o);
        });
        return ratings;
    }

    const fetchAllRatingsFromCurrentUserInRealtime = async (setRatings) => {
        let ref = projectFirestore.collection("user-actions").doc("ratings").collection("items").where("uid", "==", currentUser.uid);
        return ref.onSnapshot(results=>{
            if (results === undefined || results.empty){setRatings([])}
            let ratings = [];
            results.docs.forEach((comment, commentIndex)=>{
                let o = {...comment.data()};
                o['doc_id'] = comment.ref.id;
                ratings.push(o);
            });
            setRatings(ratings);
        });
    }

    

    const fetchSpecificRatingFromCurrentUser = async (source_doc_id) => {
        let ref = projectFirestore.collection("user-actions").doc("ratings").collection("items");
        let query = ref.where("uid", "==", currentUser.uid).where("source_doc_id", "==", source_doc_id);
        let results = await query.get().catch(err=>console.log(err));
        if (results.empty){return null}
        let o = results.docs[0].data();
        o['rating_doc_id'] = results.docs[0].ref.id;
        return o;
    }

    const postRating = async (source_doc_id, source_uid, rating_source, rating) => {
        console.log("Adding rating: ", source_doc_id, source_uid, rating_source, rating);
        let exists = await fetchSpecificRatingFromCurrentUser(source_doc_id);
        if (exists !== null){
            // delete existing rating
            await projectFirestore.collection("user-actions").doc("ratings").collection("items").doc(exists.rating_doc_id).delete().catch(err=>console.log(err));
        }
        let ref = projectFirestore.collection("user-actions").doc("ratings").collection("items");
        let obj = {
            'rating_created_timestamp': timeStamp,
            'uid': currentUser.uid,
            'rating': rating,
            'source_doc_id': source_doc_id, 
            'source_uid': source_uid,
            'rating_source': rating_source,
        };
        return ref.add(obj).catch(err=>console.log(err));
    }

    const removeRating = async (source_doc_id) => {
        let query = projectFirestore.collection("user-actions").doc("ratings").collection("items").where("uid", "==", currentUser.uid).where("source_doc_id", "==", source_doc_id);
        let results = await query.get().catch(err=>console.log(err));
        return results.docs[0].ref.delete().catch(err=>console.log(err));
    }

    return {
        fetchAllRatingsFromCurrentUser, 
        fetchAllRatingsFromCurrentUserInRealtime,
        postRating, 
        removeRating
    }

}