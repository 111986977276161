import {projectFirestore, projectFunctions, timeStamp, firebase} from '../firebase/config.js';
import { useAuth } from "../contexts/AuthContext";

export default function useQuickNotes() {

    const {currentUser} = useAuth();

    const fetchQuickNote = async (lang) => {
        if (currentUser === null){return false}
        let query = projectFirestore.collection("quick-notes").where("uid", "==", currentUser.uid).where("target_ISO_639-1", "==", lang);
        let snapshots = await query.get().catch(err=>console.log(err));
        if (!snapshots.empty && snapshots.docs.length === 1){
            let snap = snapshots.docs[0];
            let data = snap.data();
            return data;
        } else {
            return false
        }
    }

    const saveQuickNote = async (text, lang) => {
        if (currentUser === null){return false}
        let query = projectFirestore.collection("quick-notes").where("uid", "==", currentUser.uid).where("target_ISO_639-1", "==", lang);
        let snapshots = await query.get().catch(err=>console.log(err));
        let success = true;
        if (!snapshots.empty && snapshots.docs.length === 1){
            let snap = snapshots.docs[0];
            let newData = {
                text: text,
                last_updated_timestamp: timeStamp
            }
            await snap.ref.update(newData).catch(()=>success=false);
        } else {
            let newData = {
                text: text,
                last_updated_timestamp: timeStamp, 
                uid: currentUser.uid, 
                'target_ISO_639-1': lang
            }
            await projectFirestore.collection("quick-notes").add(newData).catch(()=>success=false);
        }
        return success;
    }

    return {
        fetchQuickNote, 
        saveQuickNote
    }
}