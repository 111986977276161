import React from 'react';
import Button from '../../../../general/Button';
import Modal from '../../../../general/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/pro-duotone-svg-icons';
import { faThumbsDown } from '@fortawesome/pro-duotone-svg-icons';
import { faRotateRight } from '@fortawesome/pro-duotone-svg-icons';

const ModalContent = ({closeFunction}) => {
    return (
        <>
            <div className="overflow-auto h-72 sm:h-full p-4">
                <h1>How to play</h1>
                <p className="text-center my-2">Click on a circle and see what's hidden on the other side. The goal is to find the matching translation in the grid. Try clicking on another circle. If it's correct, both circles will turn green. If it's not correct, they will turn around again. Try selecting two new circles. The game continues until all the circles have turned green.</p>

                <div className="p-4 text-center">
                    <Button text="Continue playing" onClick={closeFunction} />
                </div>
            </div>
        </>   
    )
}

const MemoryHelpModal = ({closeFunction}) => {
  return (
    <>
        <Modal closeFunction={closeFunction} content={<ModalContent closeFunction={closeFunction} />} size="3xl"/>
    </>
  )
};

export default MemoryHelpModal;
