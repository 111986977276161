import React, {useEffect, useState, useRef} from 'react';
import Button from '../../../components/general/Button';
import { CSVLink } from "react-csv";
import {Link} from 'react-router-dom';
import {useParams} from "react-router-dom";
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTableColumns} from '@fortawesome/pro-solid-svg-icons';
import {faKeyboard} from '@fortawesome/pro-solid-svg-icons';
import useDecks from '../../../hooks/useDecks';
import {motion} from 'framer-motion';

const ExportCSV = () => {
    const [headers, setHeaders] = useState([
        { label: "Source", key: "source" },
        { label: "Target", key: "target" },
        { label: "Source language", key: "source_ISO_639-1"},
        { label: "Target language", key: "target_ISO_639-1"},
        { label: "Created", key: "created" }, 
        { label: "Last updated", key: "last_updated" }
    ]);
    const [data, setData] = useState([]);
    const csvInstance = useRef();
    const [deck, setDeck] = useState(null);
    let { id } = useParams();
    id = parseInt(id);
    const [fetchingData, setFetchingData] = useState(false);
    const [dataFetched, setDataFetched] = useState(false);
    const {fetchAllItemsInSingleDeck} = useDecks();

    const fetchDeck = async () => {
        setFetchingData(true);
        let r = await fetchAllItemsInSingleDeck(id);
        let l = [];
        if ('items' in r){
            
            for (const item of r['items']){
                let o = {};
                o['created'] = item['created_timestamp'].toDate();
                o['last_updated'] = item['last_updated_timestamp'].toDate();
                o['source'] = item['source'];
                o['target'] = item['target'];
                o['source_ISO_639-1'] = item['source_ISO_639-1'];
                o['target_ISO_639-1'] = item['target_ISO_639-1'];
                l.push(o);
            }
        }
        if ('deck' in r){
            setDeck(r['deck']);
        }
        setData(l);
        setDataFetched(true);
        setFetchingData(false);
        return true;
    }

    const downloadDataClick = async () => {
        let success = await fetchDeck();
        if (success){
            csvInstance.current.link.click();
            return true;
        }
    }
  return (
    <>
         <div className="  min-h-screen h-full">
            <div className="text-xs">
                <Link to={"/my-decks/"+id+"/export"}>Back to options</Link>
            </div>
        <div className="flex flex-col h-96 w-96 m-auto justify-center place-items-center">
            <div className="flex flex-col gap-2 text-center  p-6">
                <div className="font-bold text-gray-600 text-lg">Export as a CSV file</div>
                {fetchingData &&
                    <>
                        <div className="text-lg text-center p-4">
                            <FontAwesomeIcon icon={faSpinner} className="fa-pulse" />
                        </div>
                        <div className="text-sm italic text-gray-600 ">
                            Fetching data...
                        </div>
                    </>
                }
                {(!fetchingData) &&
                    <>
                        <div className="text-sm italic text-gray-600 ">
                            {dataFetched ? "Your download is ready! Click on the button below if the download doesn't start automatically." : "Click on the button below to start downloading your data."}
                        </div>
                        <div className="flex no-underline flex-row justify-center">   
                            <CSVLink 
                            filename={(deck !== null && 'name' in deck) ? deck.name + '_' + id + ".csv" : 'deck_'+id+'.csv'} 
                            data={data} 
                            headers={headers}
                            ref={csvInstance}
                            >
                            </CSVLink>
                            <Button onClick={dataFetched ? ()=>csvInstance.current.link.click() : downloadDataClick} text="Download data" color="green" />
                        </div>
                    </>
                }
            </div>
        </div>
        </div>
    </>
  )
}

export default ExportCSV;