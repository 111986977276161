import {projectFirestore, projectFunctions, timeStamp, firebase} from '../firebase/config.js';


export default function usePages() {

    const fetchPage = async (slug) => {
        let query = projectFirestore.collection("pages").doc("items").collection("items").where("slug", "==", slug);
        let snap = await query.get().catch(err=>console.log(err));
        if (!snap.empty && snap.docs.length === 1){
            // only unique slugs
            let data = snap.docs[0].data();
            data['doc_id'] = snap.docs[0].ref.id;
            return data;
        } else {
            return false
        }
    }

    return {
        fetchPage
    }

}