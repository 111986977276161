import {projectFirestore, projectFunctions, timeStamp, firebase} from '../firebase/config.js';
import { useAuth } from "../contexts/AuthContext";

export default function useFriends() {
    const {currentUser} = useAuth();

    const searchPublicUsernames = async (username) => {
        const query = projectFirestore.collection("users").where("username", "==", username).where("public_profile", "==", true);
        const snapshots = await query.get();
        if (!snapshots.empty){
            let l = [];
            snapshots.docs.forEach((doc)=>{
                let o = doc.data();
                o['uid'] = doc.ref.id;
                if (doc.ref.id !== currentUser.uid){
                    l.push(o);
                }
            });
            return l;
        } else {
            return [];
        }
    }

    return {
        searchPublicUsernames
    }
}