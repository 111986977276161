import React, {useState, useRef, useEffect, useCallback, useMemo} from 'react';
//import { createEditor, Transforms, Text, Editor } from 'slate';
import { Editable, withReact, useSlate, Slate, useFocused, ReactEditor } from 'slate-react';
import { withHistory } from 'slate-history'
import isHotkey from 'is-hotkey';
import {
  Editor,
  Text, 
  Transforms,
  createEditor,
  Descendant,
  Range,
  Element as SlateElement,
} from 'slate'
import {Link} from 'react-router-dom';
import Icon from '../../../components/general/Icon';
import TableWidget from '../../widgets/tables/TableWidget';
import TableSelectorModal from '../../../components/general-modals/tables/TableSelectorModal';
import {faSpinner} from '@fortawesome/pro-duotone-svg-icons';
import Skeleton from 'react-loading-skeleton';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import useNotes from '../../../hooks/useNotes';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAlignRight} from '@fortawesome/pro-solid-svg-icons';
import {faAlignLeft} from '@fortawesome/pro-solid-svg-icons';
import {faCheck} from '@fortawesome/pro-solid-svg-icons';
import {faAlignJustify} from '@fortawesome/pro-solid-svg-icons';
import {faAlignCenter} from '@fortawesome/pro-solid-svg-icons';
import {faBold} from '@fortawesome/pro-solid-svg-icons';
import {faP} from '@fortawesome/pro-solid-svg-icons';
import {faChevronDown} from '@fortawesome/pro-solid-svg-icons';
import {faItalic} from '@fortawesome/pro-solid-svg-icons';
import {faPencil} from '@fortawesome/pro-solid-svg-icons';
import {faEllipsisVertical} from '@fortawesome/pro-solid-svg-icons';
import {faUnderline} from '@fortawesome/pro-solid-svg-icons';
import {faGripDots} from '@fortawesome/pro-solid-svg-icons';
import {faQuoteLeft} from '@fortawesome/pro-solid-svg-icons';
import {faUpDownLeftRight} from '@fortawesome/pro-solid-svg-icons';
import {faSliders} from '@fortawesome/pro-solid-svg-icons';
import {faFloppyDisk} from '@fortawesome/pro-solid-svg-icons';
import {faPlay} from '@fortawesome/pro-solid-svg-icons';
import {faPlus} from '@fortawesome/pro-solid-svg-icons';
import {faH1} from '@fortawesome/pro-solid-svg-icons';
import {faH2} from '@fortawesome/pro-solid-svg-icons';
import {faH3} from '@fortawesome/pro-solid-svg-icons';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import {faEye} from '@fortawesome/pro-solid-svg-icons';
import {faXmark} from '@fortawesome/pro-solid-svg-icons';
import {faBookBlank} from '@fortawesome/pro-solid-svg-icons';
import {faTable} from '@fortawesome/pro-solid-svg-icons';
import {motion} from 'framer-motion';
import Block from '../slate-editor-components/Block';
import Leaf from '../slate-editor-components/Leaf';
import TextEditor from '../slate-editor-components/TextEditor';
import MarkButton from '../slate-editor-components/MarkButton';
import BlockButton from '../slate-editor-components/BlockButton';
import HoveringToolbar from '../slate-editor-components/HoveringToolbar';
import FixedToolbar from '../slate-editor-components/FixedToolbar';
import ListMenuOfOptions from '../slate-editor-components/ListMenuOfOptions';

//https://docs.slatejs.org/walkthroughs/05-executing-commands
// Define a React component to render leaves.


const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

const NoteEditor = ({note, noteContent, loadingNote, mode}) => {
    const editorRef = useRef();
    const withImages = editor => {
      const { isVoid } = editor
    
      editor.isVoid = element => {
        return element.type === 'table-widget' ? true : isVoid(element)
      }
    
      return editor
    }
    //if (!editorRef.current) {editorRef.current = useMemo(()=>withHistory(withReact(withImages(createEditor()))), [])};
    //const editor = editorRef.current;
    const editor = useMemo(()=>withHistory(withReact(withImages(createEditor()))), []);
    const [initialValue, setInitialValue] = useState(null);
    const [value, setValue] = useState([
      {
        type: "paragraph",
        children: [{ text: "" }],
      },
    ]);
    const {saveNoteContent, 
      renameNote} = useNotes();
    const [showOptions, setShowOptions] = useState(true);
    const [savingToDb, setSavingToDb] = useState(false);
    const [preview, setPreview] = useState(mode === "edit" ? false : true);
    const [noteName, setNoteName] = useState(note === null ? null : note.name);
    const [showTableSelector, setShowTableSelector] = useState(false);
    const [selectedTableInModal, setSelectedTableInModal] = useState(null);
    const [selectionInFocus, setSelectionInFocus] = useState(null);
    const [pathInFocus, setPathInFocus] = useState(null);
    const [showAddContentMenu, setShowAddContentMenu] = useState(false);
    const [showAddContentMenuAtButton, setShowAddContentMenuAtButton] = useState(false);
    const [showAddContentMenuFocus, setShowAddContentMenuFocus] = useState(null);
    const [studyMode, setStudyMode] = useState(mode === "study" ? true : false);
    const [autosaveDelay, setAutosaveDelay] = useState(1000);
    console.log(studyMode);

    //Tables 
    const [numberOfPreviewRows, setNumberOfPreviewRows] = useState(5);

    useEffect(()=>{
      let timeoutID = null;
      if (studyMode === false){
        timeoutID = setTimeout(async ()=>{
          await saveToDatabaseClick();
        },[autosaveDelay]);
      }

      return () => {
        timeoutID !== null && clearTimeout(timeoutID);
      }

    },[value, autosaveDelay, studyMode]);

    useEffect(()=>{
      if (note !== null && noteName !== null){
        let timeoutID = null;
        if (studyMode === false){
          timeoutID = setTimeout(async ()=>{
            setSavingToDb(true);
            await renameNote(note.note_doc_id, noteName);
            setSavingToDb(false);
          },[autosaveDelay]);
        }

        return () => {
          timeoutID !== null && clearTimeout(timeoutID);
        }
      }

    },[noteName, note, autosaveDelay, studyMode])



    useEffect(()=>{
      if (noteContent !== null && noteContent !== undefined){
        setInitialValue(JSON.parse(noteContent.content));
        setValue(JSON.parse(noteContent.content));
      }
    },[noteContent]);
  /* 
    const renderElement = useCallback(({ attributes, children, element }) => {
        const index = parseInt(ReactEditor.findPath(editor, element));
        return (
          <>
            {viewMode === "edit" ?
              <>
             <Draggable key={"draggable_key_"+index} draggableId={"id_"+index} index={index}>
              {(provided) => (
                <Block viewMode={viewMode} provided={provided} innerRef={provided.innerRef} element={element} openTableSelectorClick={openTableSelectorClick} deleteTableWidgetClick={deleteTableWidgetClick} attributes={attributes} children={children} type={element.type} />
              )}
            </Draggable>
            {(showAddContentMenu && showAddContentMenuFocus === index && element.children[0]['text'] === "") &&
                <>
                  <div contentEditable={false} style={{ userSelect: "none" }} className="relative">
                    <div className="absolute left-0">
                      <ListMenuOfOptions type="functions" options={[{'text': 'Insert table','function': openTableSelectorClick, 'icon': faTable}]} />
                    </div>
                  </div>
                </>
              }
            </>
            :
              <Block viewMode={viewMode} element={element} attributes={attributes} children={children} type={element.type} />
            }
          </>
        )
/*         
        } 
      }, [value, editor, viewMode, showAddContentMenu, showAddContentMenuFocus]);

      */
      const renderElement = ({ attributes, children, element }) => {
        const index = parseInt(ReactEditor.findPath(editor, element));
        console.log(element);
        return (
          <>
             <Draggable isDragDisabled={studyMode ? true : false} key={"draggable_key_"+index} draggableId={"id_"+index} index={index}>
              {(provided) => (
                <Block draggingId={draggingId} numberOfPreviewRows={numberOfPreviewRows} studyMode={studyMode} provided={provided} innerRef={provided.innerRef} element={element} openTableSelectorClick={openTableSelectorClick} deleteTableWidgetClick={deleteTableWidgetClick} attributes={attributes} children={children} type={element.type} />
              )}
            </Draggable>
            {(showAddContentMenu && !studyMode && showAddContentMenuFocus === index && element.children[0]['text'] === "") &&
                <>
                  <div contentEditable={false} style={{ userSelect: "none" }} className="relative">
                    <div className="absolute left-0">
                      <ListMenuOfOptions type="functions" options={[{'text': 'Insert table','function': openTableSelectorClick, 'icon': faTable}]} />
                    </div>
                  </div>
                </>
              }
          </>
        )
      };

    // Define a leaf rendering function that is memoized with `useCallback`.
    const renderLeaf = useCallback(props => {
        return <Leaf {...props} />
    }, [])

    const slateContentOnChange = (content) => {
      setValue(content);
      //let c = JSON.stringify(content);
    }

    const saveToDatabaseClick = async () => {
      console.log("Save to database")
      setSavingToDb(true);
      if (noteContent !== null){
        await saveNoteContent(noteContent.note_content_doc_id, value);
      }
      setSavingToDb(false);
    }

    const HOTKEYS = {
      'mod+b': {'type': 'mark', 'mark': 'bold'},
      'mod+i': {'type': 'mark', 'mark': 'italic'},
      'mod+u': {'type': 'mark', 'mark': 'underline'},
      'mod+s': {'type': 'function', 'function': saveToDatabaseClick}
    }

    const addTableWidget = () => {
      Transforms.insertNodes(
        editor,
        { type: "table-widget", 
        tableId: null,
        children: [{ text: "" }] }
      )
      Transforms.insertNodes(
        editor,
        { type: "paragraph", 
        children: [{ text: "" }] }
      )
    }

    const deleteTableWidget = (selection) => {
      Transforms.removeNodes(
        editor,
        selection
      )
    }

    const deleteElement = (element) => {
      let path = ReactEditor.findPath(editor, element);
      console.log(path);
      //ensure always a text node
      
      Transforms.removeNodes(
        editor,
        {at: path}
      )

      
    }

    const deleteTableWidgetClick = (element) => {
      deleteElement(element);
      /* let selection = editor.selection;
      console.log(selection);
      deleteTableWidget(selection);
      setSelectedTableInModal(null); */
    }

    /*
<div className="bg-white text-charcoal rounded-xl px-4 py-2 cursor-pointer shadow-xl" onMouseDown={addTableWidget}>
                    <FontAwesomeIcon icon={faTable} />
                  </div>
    */

    const previewClick = () => {
      //setPreview(!preview);
      console.log(preview);
      //setViewMode("study");
      setShowNoteMenu(!showNoteMenu);
    }

    const openTableSelectorClick = (element) => {
      console.log(element);
      let selection = null;
      let selected = null;
      let path = null;
      if (element !== undefined){
        path = ReactEditor.findPath(editor, element)[0];
      } else {
        selection = editor.selection;
        if (selection !== null){
          path = editor.selection.focus.path[0];
        }
        console.log(selection);
      }
      console.log("Selection and path: ", selection, path );
      if (selection !== null){
        setSelectionInFocus(selection);
      }
      if (path !== null){
        setPathInFocus(path);
      }
      if (selection !== null && selection.anchor !== null) {
        selected = editor.children[selection.anchor.path[0]];
      } else {
        selected = null;
      }
      setShowAddContentMenu(false);
      setShowTableSelector(true);
    }
    
    const closeTableSelectorModal = () => {
      setShowTableSelector(false);
    }

    const addTableIdToTableElement = () => {

    }


    const [showNoteMenu, setShowNoteMenu] = useState(false);
    const [draggingId, setDraggingId] = useState(null);

    /* 
    <div className="bg-white text-charcoal rounded-xl px-4 py-2 cursor-pointer shadow-xl" onMouseDown={addTableWidget}>
                      <FontAwesomeIcon icon={faTable} />
                    </div>

    */

    const onDragEnd = (result) => {
      setDraggingId(null);
      if (!result.destination) return;
      Transforms.moveNodes(editor, {
        at: [result.source.index],
        to: [result.destination.index],
      });
    }

    const onBeforeDragCapture  = (beforeCapture) => {
      setDraggingId(beforeCapture.draggableId);
    }

    const onDragStart = (event) => {
      //closeToggleItem(event.draggableId);
    } 
    
    

    /*
<motion.div whileHover={{scale:1.02}} className="flex flex-row gap-3 px-2" onMouseDown={previewClick}>
                          <div className="text-beautiful-blue">
                            <FontAwesomeIcon icon={preview ? faXmark : faEye } />
                          </div> 
                          <div>
                            {preview ? "Edit note" : "Study now"}
                          </div>
                        </motion.div>
    */

    

    const openAddContentMenu = () => {
      let selection = editor.selection;
      setShowAddContentMenu(true);
      if (selection !== undefined){
        setShowAddContentMenuFocus(selection.focus.path[0]);
      }
    }

    const openAddContentMenuAtButton = () => {
      setShowAddContentMenuAtButton(!showAddContentMenuAtButton);
    }

    const addChosenTableToDocument = (selectedTable) => {
      console.log("Path: ", pathInFocus);
      if (pathInFocus === null) {return}
      Transforms.setNodes(editor, {tableId: selectedTable.id, type: "table-widget"}, {
        at: [pathInFocus]
      });
/*        Transforms.insertNodes(
        editor,
        { type: "paragraph", 
        children: [{ text: "" }] }, 
        {
          at: [pathInFocus !== null ? pathInFocus : editor.children.length]
        }
      )  */
      //ReactEditor.focus(editor);
      //Transforms.select(editor, Editor.end(editor, []));
    }

    const addParagraphAtEnd = () => {
      Transforms.insertNodes(
        editor,
        { type: "paragraph", children: [{ text: '' }] },
        { at: [editor.children.length] }
      )
      ReactEditor.focus(editor);
      Transforms.select(editor, Editor.end(editor, []));
      setShowAddContentMenuAtButton(false);
    }

    const addTableAtSelection = () => {
      console.log("Add table click. Path: ", pathInFocus);

      Transforms.insertNodes(
        editor,
        { tableId: null, type: "table-widget", children: [{ text: '' }]},
        { at: [pathInFocus !== null ? pathInFocus : editor.children.length] }
      );
      setShowAddContentMenuAtButton(false);
      //ReactEditor.focus(editor);
      //Transforms.select(editor, Editor.end(editor, []));
    
    }

    /*       renderLeaf={(props)=>{
                                    if (props.leaf.placeholder && !preview) {
                                      return (
                                        <>
                                          <div {...props} className="relative">
                                          <Leaf {...props} />
                                          <span
                                            style={{ opacity: 0.3, position: "absolute", top: 0, left:0, userSelect: "none" }}
                                            contentEditable={false}
                                          >
                                            Start writing here...
                                          </span>
                                          </div>
                                        </>
                                      );
                                    }
                                    return <Leaf 
                                      {...props}
                                    />
                                  }
                                  }

    */

    const [addItemsOptions, setAddItemsOptions] = useState([{'text': 'Insert table','function': addTableAtSelection, 'icon': faTable}, 
        {'text': 'Insert paragraph','function': addParagraphAtEnd, 'icon': faP}])


/**
 * 
 * <span
                                            style={{ opacity: 0.3, position: "absolute", top: 0, left:0, userSelect: "none" }}
                                            contentEditable={false}
                                          >
                                            Start writing here...
                                          </span>*/

    /* 
<div className="relative">
                    <div className="cursor-pointer text-2xl p-4" onClick={()=>setShowNoteMenu(!showNoteMenu)} >
                      <FontAwesomeIcon icon={faEllipsisVertical} />
                    </div>
                    {showNoteMenu &&
                      <>
                      <div className="absolute z-10 whitespace-nowrap top-12 right-6 p-4 bg-white rounded-lg shadow-xl flex cursor-pointer flex-col gap-3">
                        <motion.div whileHover={{scale:1.02}} className="flex flex-row gap-3 px-2" onMouseDown={saveToDatabaseClick}>
                          <div className="text-charcoal">
                            <FontAwesomeIcon icon={faFloppyDisk} />
                          </div> 
                          <div>
                            Save changes
                          </div>
                        </motion.div>
                        
                        
                      </div>
                    </>
                    }
                    </div>
    */

    const noteNameOnChange = (e) => {
      setNoteName(e.target.value);
    }

    const [contentButtons, setContentButtons] = useState([
      {'text': 'Paragraph','format': 'paragraph', 'icon': faP}, 
      {'text': 'Heading 1', 'format': 'heading-one', 'icon': faH1}, 
      {'text': 'Heading 2', 'format': 'heading-two', 'icon': faH2}, 
      {'text': 'Heading 3', 'format': 'heading-three', 'icon': faH3}, 
      {'text': 'Quote', 'format': 'quote', 'icon': faQuoteLeft}
    ]);

    return (
        <>
          {(!loadingNote) &&
            <>
            {(initialValue !== null && value !== null) &&
              <Slate onChange={(content)=>slateContentOnChange(content)} editor={editor} value={value}>
                {!studyMode &&
                  <>
                  <HoveringToolbar contentButtons={contentButtons} TEXT_ALIGN_TYPES={TEXT_ALIGN_TYPES} />
                  <div className="">
                    <div className=" w-full px-4 text-gray-600  " >
                      <div className="flex flex-col sm:flex-row justify-between w-full place-items-center gap-4">
                        
                        <div className=" text-gray-600 pl-8 flex flex-row gap-4">
                          <div className="w-6">
                            {savingToDb ?
                              <>
                                <div className="">
                                  <FontAwesomeIcon icon={faSpinner} className="fa-pulse" />
                                </div>
                              </>
                              : 
                              <>
                                <div className="text-persian-green">
                                  <FontAwesomeIcon icon={faCheck} />
                                </div>
                              </>
                            }
                          </div>
                          <div className="italic">
                            {note === null ? <Skeleton count={1} height={10} /> : <>
                              <div>
                                <input type="text" className="bg-transparent sm:text-left text-center outline-none border-none italic" onChange={noteNameOnChange} value={noteName} />
                              </div>
                            </>}
                          </div>
                        </div>
                        <Link to={"/my-notes/note/"+note.note_id + "/study"}>
                        
                        </Link>
                        <div className="">
                          <FixedToolbar contentButtons={contentButtons} TEXT_ALIGN_TYPES={TEXT_ALIGN_TYPES} />
                        </div>
                      </div>
                      
                    </div>
                  </div>
                  </>
                }
                <div className="flex flex-col gap-6">
                <div className="">
                <DragDropContext onBeforeCapture={onBeforeDragCapture} onDragEnd={onDragEnd} onDragStart={onDragStart}>
                    <Droppable droppableId="elements">
                        {(provided, snapshot) => (
                            <>
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                              <div className="">
                                <div className={" h-full  w-full   m-auto "}>
                                  <Editable 
                                  readOnly={studyMode ? true : false}
                                  autoFocus={true}
                                  renderElement={renderElement}
                                 
                                  decorate={([node, path]) => {
                                    if (editor.selection != null) {
                                      if (
                                        !Editor.isEditor(node) &&
                                        Editor.string(editor, [path[0]]) === "" &&
                                        Range.includes(editor.selection, path) &&
                                        Range.isCollapsed(editor.selection)
                                      ) {
                                        return [
                                          {
                                            ...editor.selection,
                                            placeholder: true,
                                          },
                                        ];
                                      }
                                    }
                                    return [];
                                  }}
                                 
                                  onKeyDown={event => {
                                    
                                    for (const hotkey in HOTKEYS) {
                                      if (isHotkey(hotkey, event)) {
                                        event.preventDefault();
                                        const command = HOTKEYS[hotkey];
                                        if (command.type === "mark"){
                                          TextEditor.toggleMark(editor, command.mark);
                                        }
                                        else if (command.type === "function"){
                                          command.function();
                                        }
                                      }
                                    }
                                    if (event.key === "+" && !showAddContentMenu){
                                      //event.preventDefault();
                                      //openAddContentMenu();
                                    } else {
                                      setShowAddContentMenu(false);
                                      setShowAddContentMenuFocus(null);
                                    }
                                }}

                                renderLeaf={(props)=>{
                                    if (props.leaf.placeholder && !preview) {
                                      return (
                                        <>
                                          <div {...props} className="relative">
                                          <Leaf {...props} />
                                          
                                          </div>
                                        </>
                                      );
                                    }
                                    return <Leaf 
                                      {...props}
                                    />
                                  }
                                  }
                                  />
                                </div>
                              </div>
                              {provided.placeholder} 
                            </div>
                            
                            </>
                        )}
                      </Droppable>
                    </DragDropContext>
                </div>
                {!studyMode &&
                  <div className="flex flex-row justify-center place-items-center">
                    <div className="relative text-center">
                    <motion.div whileHover={{scale:1.02}} className="text-center flex flex-row place-items-center text-gray-600 gap-1 justify-center">
                      <div onClick={openAddContentMenuAtButton} className="cursor-pointer rounded-lg shadow-xl flex flex-row gap-3 place-items-center bg-white p-4">
                        <FontAwesomeIcon icon={faPlus} />
                        <div>
                          Add item
                        </div>
                      </div>
                    </motion.div>
                    {showAddContentMenuAtButton &&
                        <>
                          <div className="absolute top-12 -left-16">
                            <ListMenuOfOptions type="functions" options={addItemsOptions} />
                          </div>
                        </>
                     }
                    </div>
                    </div>
                }
                </div>
              </Slate>
            }
            {(showTableSelector && !studyMode && note !== null && 'target_ISO_639-1' in note) && 
              <>
                <TableSelectorModal saveChangesFunction={addChosenTableToDocument} addTableIdToTableElement={addTableIdToTableElement} closeFunction={closeTableSelectorModal} targetLanguage={note['target_ISO_639-1']} setSelectedTable={setSelectedTableInModal} />
              </>
            }
            </>
          }
        </>
    )
};



export default NoteEditor;