import React, {useState, useEffect, useRef} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMagnifyingGlass, faCardsBlank, faPlus, faPlay, faPen, faX, faGrid, faTrash, faList, faEllipsisV, faCog, faFileImport, faFileExport} from '@fortawesome/pro-solid-svg-icons';
import {faLoader} from '@fortawesome/pro-solid-svg-icons';
import Icon from '../../../components/general/Icon';
import SortByButton from '../../general/sorting/SortByButton';
import {DebounceInput} from 'react-debounce-input';
import Skeleton from 'react-loading-skeleton';
import useDecks from '../../../hooks/useDecks';
import {Link} from 'react-router-dom';
import {motion} from 'framer-motion';
import Button from '../../../components/general/Button';
import CountryFlag from '../../../components/languages/CountryFlag';
import NumberOfCards from './NumberOfCards';

const PlayButton = (props) => {
    const {openAddCardModal, deck} = props;
    return (
        <>
            <Link to={"/my-decks/"+deck.id+"/play"} className="no-underline">
                <Button text="Play" onClick={openAddCardModal} size="normal" icon={faPlay} color="green" />
            </Link>
        </>
    )
}

const AddCardButton = (props) => {
    const {openAddCardModal} = props;
    return (
        <>
            <Button text="Add" onClick={openAddCardModal} size="normal" icon={faPlus} color="charcoal" />
        </>
    )
}

const SettingsButton = (props) => {
    const {deck} = props;
    return (
        <>
            <Link to={"/my-decks/"+deck.id + "/settings"} className="no-underline">
                <Button text="Settings" size="normal" icon={faCog} color="charcoal" />
            </Link>
        </>
    )
}

const ImportButton = (props) => {
    const {deck} = props;
    return (
        <>
            <Link to={"/my-decks/"+deck.id + "/import"} className="no-underline">
                <Button text="Import" size="normal" icon={faFileImport} color="charcoal" />
            </Link>
        </>
    )
}

const ExportButton = (props) => {
    const {deck} = props;
    return (
        <>
            <Link to={"/my-decks/"+deck.id + "/export"} className="no-underline">
                <Button text="Export" size="normal" icon={faFileExport} color="charcoal" />
            </Link>
        </>
    )
}

const CardItems = ({localCardChanges, targetWordOnChange, sourceWordOnChange, setLocalCardChanges, cardsLoading, addDocIdToDeletedCardsList, decrementNumberOfCards, numberOfCards, numberOfCardsPerPage, numberOfPages, apiType, customFields, orderByChange, searchTerm, searchInputChange, sortByOptionsDict, sortByValue, sortByOptions, setDocs, currentPage, deck, allLanguages, sourceLanguage, setSourceLanguage, editAuthorization, editCardId, setEditCardId, deck_id, zoomMode, docs, Card, modalMode, setModalMode, setShowModal}) => {
 
    const [nbOfWords, setNbOfWords] = useState(null);
    const [searchExpanded, setSearchExpanded] = useState(true);
    const [showCardMenu, setShowCardMenu] = useState(null);
    const [itemsView, setItemsView] = useState("grid");
    const {saveChangesToDeckCard, 
        deleteDeckCard} = useDecks();
    const searchRef = useRef(null);

    const toggleViewSearch = () => {
        //setSearchExpanded(!searchExpanded);
        if (searchRef.current !== null){
            //searchRef.current.focus();
        }
    }

    useEffect(()=>{
        if (docs !== null){
            setNbOfWords(docs.length);
        }
    },[docs]);

    const handleCardClick = (doc) => {
        setModalMode("edit");
    }

    const openAddCardModal = () => {
        setModalMode("add");
        setShowModal(true);
    }

    const openShowCardMenu = (docId)=> {
        console.log(docId)
        if (showCardMenu === docId){
            setShowCardMenu(null);
        } else {
            setShowCardMenu(docId);
        }
    }

    const editCardClick = (docId) => {
        setModalMode("edit");
        setShowCardMenu(null);
        setEditCardId(docId);
        setShowModal(true); 
    }

    const handleDeleteCardClick = async (docId) => {
        let success = await deleteDeckCard({cardDocId: docId});
        if (!success){
            let new_list = [];
            let old_list = [...docs];
            for (const d of old_list){
                if (d['id'] !== docId){
                    new_list.push(d);
                }
            }
            setDocs(new_list);
            addDocIdToDeletedCardsList(docId);
            decrementNumberOfCards(1);
        } else {
            // successfully deleted card
            let new_list = [];
            let old_list = [...docs];
            for (const d of old_list){
                if (d['id'] !== docId){
                    new_list.push(d);
                }
            }
            setDocs(new_list);
            addDocIdToDeletedCardsList(docId);
            decrementNumberOfCards(1);
        }
    }

    /*
                    <div className="font-bold text-sm text-gray-700 flex flex-row place-items-center gap-2">
                        <div>
                            Total cards: 
                        </div>
                        <div>
                            {deck === null ? 
                                <Skeleton count={1} height={30} />
                            :
                                <>
                                    {(deck.hasOwnProperty('temp_adding_items') && deck['temp_adding_items'] === true) ?
                                        <>
                                            {deck.hasOwnProperty('temp_number_of_items_to_add') &&
                                                <div className="flex ml-0 italic opacity-80 text-gray-600 flex-row gap-1 text-xs justify-start place-items-center">
                                                    <div>
                                                        <FontAwesomeIcon icon={faLoader} className="fa-spin" />
                                                    </div>
                                                    <div className="">
                                                        {numberOfCards} (adding {deck['temp_number_of_items_to_add']} items)
                                                    </div>
                                                </div>
                                            }
                                        </>
                                        :
                                        <>
                                            {numberOfCards}
                                        </>
                                    }
                                
                                </>
                            }
                        </div>
                    </div>
 <div className="font-bold text-sm text-muted">
                        {(currentPage !== null && numberOfPages !== null) ? <>Page: {currentPage}/{numberOfPages === 0 ? numberOfPages + 1 : numberOfPages}</> : <Skeleton count={1} height={30} />}
                    </div>
    */
    /*
                {deck !== undefined &&
                    <>
                        <div onClick={openAddCardModal} className={"h-full min-h-72 persian-green-gradient text-white font-bold rounded-lg p-4 shadow-2xl cursor-pointer flex flex-col justify-evenly"}>
                            <div className=" whitespace-nowrap text-center flex flex-row justify-center gap-4  font-bold text-5xl p-4 max-h-48 overflow-auto">
                                <Icon hover="none" color="white" icon={<FontAwesomeIcon icon={faPlus} />} />
                                 <div>
                                    ADD 
                                 </div>
                            </div>
                        </div>
                    </>
                }
                //<NumberOfCards deck={deck} numberOfCards={numberOfCards} />
    */
    

    console.log(docs);
    return (
        <>
            <div className="relative flex flex-col gap-8">
                
                <div className="flex flex-col gap-1">
                    <div className="px-2 flex text-base mt-6 flex-col sm:flex-row gap-8 w-full place-items-center justify-between">
                        <div className="text-sm w-full justify-between flex flex-row place-items-center gap-4">
                            <div className="flex flex-row justify-center place-items-center gap-3">
                                <div className="cursor-pointer" onClick={toggleViewSearch}>
                                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                                </div>
                                {searchExpanded &&
                                    <DebounceInput 
                                        placeHolder={"Search in " + numberOfCards + " cards"} 
                                        value={searchTerm} 
                                        onChange={searchInputChange} 
                                        minLength={1}
                                        inputRef={searchRef}
                                        debounceTimeout={300}
                                        type="text" 
                                        className="text-left text-base w-full max-w-64  bg-transparent border-none outline-none" 
                                    />
                                }
                            </div>
                            
                            { deck !== null &&
                            <div className="hidden xl:flex flex-row gap-3 md:gap-6 justify-center">
                                <div className="flex flex-row justify-center">
                                    <AddCardButton openAddCardModal={openAddCardModal} />
                                </div>
                                <div className="flex flex-row justify-center">
                                    <ImportButton deck={deck} />
                                </div>
                                <div className="flex flex-row justify-center">
                                    <ExportButton deck={deck} />
                                </div>
                                <div className="flex flex-row justify-center">
                                    <SettingsButton deck={deck} />
                                </div>
                                <div className="flex flex-row justify-center ">
                                    <PlayButton deck={deck} openAddCardModal={openAddCardModal} />
                                </div>
                            </div>
                            }
                            <div className="justify-end flex flex-row place-items-center gap-6">
                            
                            <div className="flex flex-row gap-1">
                                <div className={(itemsView === "list" ? "text-charcoal border-solid border-2 border-charcoal rounded-lg shadow-lg " : "text-black ") + " cursor-pointer px-2 py-1"} onClick={()=>setItemsView("list")}>
                                    <FontAwesomeIcon icon={faList} />
                                </div>
                                <div className={(itemsView === "grid" ? "text-charcoal border-solid border-2 border-charcoal rounded-lg shadow-lg " : "text-black ") + " cursor-pointer px-2 py-1"}  onClick={()=>setItemsView("grid")}>
                                    <FontAwesomeIcon icon={faGrid} />
                                </div>
                            </div>
                            <SortByButton rightOrLeft={"left"} hideLabel={true} textSize={"sm"} onChange={orderByChange} value={sortByOptionsDict[sortByValue]} options={sortByOptions} />
                            </div>
                        </div>
                    </div>
                </div>
                { deck !== null &&
                    <>
                    <div className="xl:hidden flex flex-row gap-3 md:gap-6 justify-center">
                        <div className="flex flex-row justify-center">
                            <AddCardButton openAddCardModal={openAddCardModal} />
                        </div>
                        <div className="flex flex-row justify-center md:order-last">
                            <PlayButton deck={deck} openAddCardModal={openAddCardModal} />
                        </div>
                        
                    </div>
                    </>
                }
                {cardsLoading && deck === null &&
                    <>
                        <Skeleton count={2} height={70} />
                    </>
                }

                {itemsView === "grid" &&
                    <>
                    {docs !== null && docs.length > 0 &&
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 3xl:grid-cols-5 4xl:grid-cols-6 5xl:grid-cols-7">
                       
                        {(docs !== null && !cardsLoading && allLanguages !== null) && docs.map((doc, index) => (
                            <div className="grid-item" key={doc.id} data-card_id={doc.id} onClick={(doc)=>handleCardClick(doc)}>
                                <div className="grid-content" style={{fontSize: zoomMode ? '2em' : '1em'}}>
                                    <Card targetWordOnChange={targetWordOnChange} sourceWordOnChange={sourceWordOnChange} addDocIdToDeletedCardsList={addDocIdToDeletedCardsList} decrementNumberOfCards={decrementNumberOfCards} apiType={apiType} docs={docs} setDocs={setDocs} customFields={customFields} deck={deck} allLanguages={allLanguages} sourceLanguage={sourceLanguage} editAuthorization={editAuthorization} doc={doc} gradient="card-blue-gradient" setShowModal={setShowModal} setModalMode={setModalMode} modalMode={modalMode} editCardId={editCardId} setEditCardId={setEditCardId} /> 
                                </div>       
                            </div>
                        ))}
                    </div>
                    }
                    {cardsLoading && 
                        <>
                            <div  className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                            {Array.apply(null, Array(numberOfCardsPerPage)).map((i, index) => (
                                
                                    <div key={"loading_"+index} className="w-full">
                                        <Skeleton count={1} height={300} />
                                    </div>       
                               
                            ))}
                             </div>
                        </>
                    }
                    </>
                }
                {itemsView === "list" &&
                    <>
                     {(docs !== null && !cardsLoading && allLanguages !== null && docs.length > 0) && 
                                <>
                        <div className="flex flex-col gap-14">
                           
                                    {docs.map((doc, index) => (
                                        <>
                                            <div className="relative w-full p-2 md:w-5/6 m-auto" key={"list_item_"+doc.id}>
                                                <div className="absolute right-0 top-0 z-10 ">
                                                    <div onClick={(e)=>openShowCardMenu(doc.id)}  className=" text-base text-charcoal px-3 cursor-pointer">
                                                        <FontAwesomeIcon icon={faEllipsisV} />
                                                    </div>
                                                </div>
                                                {showCardMenu === doc.id &&
                                                        <>
                                                            <div className="absolute right-4 top-4 overflow-visible text-base  z-30 rounded-lg shadow-xl bg-white ">
                                                                <div className="flex text-sm whitespace-nowrap font-bold text-gray-700 flex-col justify-start place-items-start">
                                                                    <div onClick={()=>editCardClick(doc.id)} className="flex cursor-pointer flex-row gap-3 hover:underline w-full rounded-lg  p-3">
                                                                        <div>
                                                                            <Icon color="blue" icon={<FontAwesomeIcon icon={faPen} />} />
                                                                        </div>
                                                                        <div>
                                                                            Edit
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex text-sm whitespace-nowrap font-bold text-gray-700 flex-col justify-start place-items-start">
                                                                    <div onClick={()=>handleDeleteCardClick(doc.id)} className="flex cursor-pointer flex-row gap-3 hover:underline w-full rounded-lg  p-3">
                                                                        <div>
                                                                            <Icon color="red" icon={<FontAwesomeIcon icon={faTrash} />} />
                                                                        </div>
                                                                        <div>
                                                                            Delete card
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>  
                                                            <div onClick={()=>setShowCardMenu(null)} className="fixed left-0 top-0 w-screen h-screen z-20">
                                                            </div>
                                                        </>
                                                    }
                                                <div className="flex flex-col gap-3 lg:flex-row lg:gap-6 place-items-start  justify-center w-full relative">
                                                   
                                                    <div className="w-full flex flex-col gap-2 lg:flex-row lg:gap-6 justify-start">
                                                        <div className="w-full">
                                                            <div className="border-b-2 w-full py-2 border-dotted border-gray-500 flex flex-row place-items-center gap-2">
                                                                <div className="rounded-xl overflow-hidden shadow-xl w-4">
                                                                    <CountryFlag showToolTip={false} animation={false} countryCode={allLanguages[0][deck['target_ISO_639-1']]['country_flag']} size="1em" flagName={allLanguages[0][deck['target_ISO_639-1']]['name']['en']} />
                                                                </div>
                                                                <DebounceInput
                                                                    className={(doc.target.length < 10 ? 'text-xl ' : doc.target.length < 20 ? 'text-lg ' : doc.target.length < 30 ? "text-base " : doc.target.length < 45 ? "text-sm " : "text-xs ")+ " text-left  w-full max-w-96 bg-transparent border-none outline-none"}
                                                                    minLength={1}
                                                                    value={doc.target}
                                                                    debounceTimeout={1000}
                                                                    onChange={event => targetWordOnChange(event, doc.id)} 
                                                                /> 
                                                            </div>
                                                        </div>
                                                        <div className="w-full">
                                                            <div className="border-b-2 py-2 border-dotted border-gray-500 flex flex-row place-items-center gap-2">
                                                                <div className="rounded-xl overflow-hidden shadow-xl">
                                                                    <CountryFlag showToolTip={false} animation={false} countryCode={allLanguages[0][deck['source_ISO_639-1']]['country_flag']} size="1em" flagName={allLanguages[0][deck['source_ISO_639-1']]['name']['en']} />
                                                                </div>
                                                                <DebounceInput
                                                                    className={(doc.source.length < 10 ? 'text-xl ' : doc.source.length < 20 ? 'text-lg ' : doc.source.length < 30 ? "text-base " : doc.source.length < 45 ? "text-sm " : "text-xs ")+" text-left w-full max-w-96 bg-transparent border-none outline-none"}
                                                                    minLength={1}
                                                                    value={doc.source}
                                                                    debounceTimeout={1000}
                                                                    onChange={event => sourceWordOnChange(event, doc.id)} 
                                                                /> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ))}
                        </div>
                        </>
                        }
                    {cardsLoading && 
                        <>
                            {Array.apply(null, Array(numberOfCardsPerPage)).map((i, index) => (
                                <div className="w-full" key={"loading_"+index} >
                                    <>
                                        <div className="w-full lg:w-5/6 sm:m-auto" key={"list_item_"+index}>
                                            <Skeleton count={1} height={60} />
                                        </div>
                                    </>     
                                </div>
                            ))}
                        </>
                    }
                    </>
                    
                }
                {!cardsLoading && docs !== null && docs.length === 0 && numberOfCards === 0 &&
                    <>
                        <div className="flex flex-row justify-center">
                            <div className="flex flex-col place-items-center gap-3">
                                Get started by adding a card to your deck.

                            </div>
                        </div>
                    </>
                }
                {deck !== undefined && !cardsLoading && docs !== null && numberOfCards > 0 &&
                    <>
                        <motion.div whileHover={{scale:1.005}} onClick={openAddCardModal}  className="fixed z-0   cursor-pointer right-4 sm:right-11 bottom-24 sm:bottom-36 p-9 flex flex-col justify-center place-items-center charcoal-gradient text-white rounded-full shadow-2xl">
                            <div className="text-xl font-bold flex flex-row place-items-center justify-center gap-2">
                                <FontAwesomeIcon icon={faPlus} />
                            </div>
                        </motion.div>
                    </>
                }
            </div>
        </>
    )
}

export default CardItems;
