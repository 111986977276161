import React, { useEffect, useState } from 'react';
import {useLanguagesContext} from '../../../contexts/LanguagesContext';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import {faLoader} from '@fortawesome/pro-solid-svg-icons';
import Select from 'react-select';
import {DebounceInput} from 'react-debounce-input';
import {faSliders} from '@fortawesome/pro-solid-svg-icons';
import {motion} from 'framer-motion';
import {faXmark} from '@fortawesome/pro-solid-svg-icons';

const SearchTexts = (props) => {
    let navigate = useNavigate();
    let location = useLocation();
    let currentPath = location.pathname;
    let currentSearch = location.search;
    const setParams = (value = "", name) => {
        const searchParams = new URLSearchParams(currentSearch);
        searchParams.set(`${name}`, value);
        return searchParams.toString();
    }

    const {searchTerm, searchInputChange, textType, setTextType, targetLanguage, setTargetLanguage, targetLanguageOptions, sortByOptions} = props;
    const [showFilterOptions, setShowFilterOptions] = useState(false);
    const [langOptionsDict, setLangOptionsDict] = useState(null);
    const {globalSelectedTargetLanguage, allLanguages, activeUserLanguages, 
        globalSelectedSourceLanguage} = useLanguagesContext();

    const [targetLangOptions, setTargetLangOptions] = useState(null);

    const [textTypeOptions, setTextTypeOptions] = useState([
        {value: 'all',
        label: 'All texts'
        }, 
        {value: 'story',
        label: 'Stories'
        },
        {value: 'conversation',
        label: 'Conversations'
        },
        {value: 'poem',
        label: 'Poems'
        },
        {value: 'article',
        label: 'Articles'
        },
        {value: 'fairytale',
        label: 'Fairytales'
        }
    ]);
    const [textTypeOptionsDict, setTextTypeOptionsDict] = useState(
        {
            'all':{value: 'all',
        label: 'All texts'
        }, 
        'story':
        {value: 'story',
        label: 'Stories'
        },
        'conversation':
        {value: 'conversation',
        label: 'Conversations'
        },
        'poem':
        {value: 'poem',
        label: 'Poems'
        },
        'article':
        {value: 'article',
        label: 'Articles'
        },
        'fairytale':
        {value: 'fairytale',
        label: 'Fairytales'
        }
        }
    );

    const handleTargetLanguageChange = async (option) => {
        setTargetLanguage(option.value);
        const url = setParams(option.value, "target");
        navigate(currentPath+'?'+url);
    }

    const handleTextTypeChange = async (option) => {
        setTextType(option.value);
        const url = setParams(option.value, "type");
        navigate(currentPath+'?'+url);
    }
    
    useEffect(()=>{
        function sortByKey(array, key) {
            return array.sort(function(a, b) {
                var x = a[key]; var y = b[key];
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            });
        }



        const createAllLanguageVariables = async () => {
            let target_options = [];
            let options_dict = {};
            let userTargetLanguagesGroup = {
                label: 'My target languages',
                options: []
            };
            let allTargetLanguagesGroup = {
                label: 'All languages',
                options: []
            };
            for (const lang of allLanguages[1]){
                let addedToTargetUserLanguages = false;
                if (activeUserLanguages !== null && activeUserLanguages.length > 0){
                    for (const u_lang of activeUserLanguages){
                        if (lang === u_lang['target_language']){
                            userTargetLanguagesGroup.options.push({
                                value: lang,
                                label: allLanguages[0][lang]['name']['en']
                            });
                            addedToTargetUserLanguages = true;
                        }
                    }
                } else {
                    addedToTargetUserLanguages = false;
                }
                
                if (!addedToTargetUserLanguages){
                    allTargetLanguagesGroup.options.push({
                        value: lang, 
                        label: allLanguages[0][lang]['name']['en']
                    });
                }
                options_dict[lang] = {
                    value: lang, 
                    label: allLanguages[0][lang]['name']['en']
                };
            }

            options_dict['all'] = {
                value: "all",
                label: 'All languages'
            };

            allTargetLanguagesGroup.options = sortByKey(allTargetLanguagesGroup.options, 'label');
            userTargetLanguagesGroup.options = sortByKey(userTargetLanguagesGroup.options, 'label');

            allTargetLanguagesGroup.options.unshift({
                value: "all",
                label: 'All languages'
            });

            target_options.unshift(allTargetLanguagesGroup);
            target_options.unshift(userTargetLanguagesGroup);

            setTargetLangOptions(target_options);
            setLangOptionsDict(options_dict);
            setDefaultValues(userTargetLanguagesGroup.options);
        
        }

        const setDefaultValues = (target_list) => {
            if (target_list.length > 0){
                if (targetLanguage === null){
                    setTargetLanguage(target_list[0]['value']);
                }
            }
        }

        const fetchEverything = async () => {
            await createAllLanguageVariables();
        }

        if (allLanguages !== null){
            fetchEverything();
        }
    },[allLanguages, activeUserLanguages]);

    console.log("Target: ", targetLanguage);

    return (
    <>
        <div className="flex flex-row justify-center place-items-center gap-8 mb-6">
            <div className="text-xl cursor-pointer" onClick={()=>setShowFilterOptions(!showFilterOptions)}>
                <FontAwesomeIcon icon={faSliders} />
            </div>
            <div className="sm:w-1/3 w-2/3">
                <DebounceInput 
                    placeHolder="Search AI texts" 
                    value={searchTerm} 
                    onChange={searchInputChange} 
                    minLength={1}
                    debounceTimeout={300}
                    type="text" 
                    className="bg-gray-200 appearance-none h-12 sm:text-lg border-solid border border-gray-300 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white"
                />
            </div>
            
        </div>
        {(!showFilterOptions && allLanguages !== null ) &&
        <div className="flex my-4 flex-row sm:text-base text-xs justify-center gap-2">
            {targetLanguage !== "all" && targetLanguage !== null && allLanguages[0].hasOwnProperty(targetLanguage) &&
                <>
                    <motion.div onClick={()=>setTargetLanguage("all")} whileHover={{scale:1.02}} className="group cursor-pointer whitespace-nowrap  bg-white border-solid border border-gray-300 p-3 rounded-lg shadow-xl flex flex-row place-items-center justify-center gap-4">
                        <div>
                            <span className="font-bold">Target:</span> {allLanguages[0][targetLanguage]['name']['en']}
                        </div>
                        <div className="group-hover:text-red-500 text-lg">
                            <FontAwesomeIcon icon={faXmark} />
                        </div>
                    </motion.div>
                </>
            }
             {textType !== "all" &&
                <>
                    <motion.div onClick={()=>setTextType("all")} whileHover={{scale:1.02}} className="group cursor-pointer whitespace-nowrap  bg-white border-solid border border-gray-300 p-3 rounded-lg shadow-xl flex flex-row place-items-center justify-center gap-4">
                        <div>
                            <span className="font-bold">Type:</span> {textType}
                        </div>
                        <div className="group-hover:text-red-500 text-lg">
                            <FontAwesomeIcon icon={faXmark} />
                        </div>
                    </motion.div>
                </>
            }
        </div>
        }
        
        {(showFilterOptions) &&
            <>
                <div className="w-5/6 m-auto flex flex-col sm:flex-row gap-3 flex-grow justify-center place-items-center">
                    <div className="w-full flex flex-col flex-grow">
                        <div className="font-bold text-gray-600">
                            Target language
                        </div>
                        <div>    
                            <Select options={targetLangOptions} value={langOptionsDict[targetLanguage]} autoFocus={false} placeholder="I'm learning" className=" text-gray-500 text-base" onChange={handleTargetLanguageChange} />
                        </div>
                    </div>
                    <div className="w-full flex flex-col flex-grow">
                        <div className="font-bold text-gray-600">
                            Text type
                        </div>
                        <div>    
                            <Select options={textTypeOptions} value={textTypeOptionsDict[textType]} autoFocus={false} placeholder="I'm learning" className=" text-gray-500 text-base" onChange={handleTextTypeChange} />
                        </div>
                    </div>
                </div>

            </>
        }
    </>
    )
}

export default SearchTexts