import React from 'react';
import {projectFirestore, projectFunctions, timeStamp, firebase} from '../firebase/config.js';
import { useAuth } from "../contexts/AuthContext";
import hello from '../translations/various-translations/hello.json';
import {useLanguagesContext} from '../contexts/LanguagesContext';
import {useUserInfoContext} from '../contexts/UserInfoContext';

export default function usePersonalizedMessages() {

    const {userInfo} = useUserInfoContext();

    const getPersonalizedGreetingInLanguage = (language, userInfoData) => {
        if (userInfoData === undefined){
            userInfoData = userInfo;
        }
        let msg = "";
        if (language in hello){
            if (language === "es"){
                msg = "¡";
            }
            msg = msg + hello[language]['hello'];
            msg = msg[0].toUpperCase() + msg.slice(1);
        } else {
            msg = "Hello";
        }
        if (userInfoData !== null && userInfoData !== undefined){
            if ("name" in userInfoData && userInfoData['name'] !== ""){
                msg = msg + " " + userInfoData['name'].split(" ")[0] + "!";
            }
            else if ("displayname" in userInfoData && userInfoData['displayname'] !== ""){
                msg = msg + " " + userInfoData['displayname'] + "!";
            }
        }
        return msg;
    }

    return {
        getPersonalizedGreetingInLanguage
    }
}