import React, {useState, useRef} from 'react';
import {motion} from 'framer-motion';
import { faPlay } from '@fortawesome/pro-solid-svg-icons';
import { faSchool } from '@fortawesome/pro-solid-svg-icons';
import { faGamepad } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';
import {useLanguagesContext} from '../../contexts/LanguagesContext';
import {useUserStatisticsContext} from '../../contexts/UserStatisticsContext'; 
import CountryFlag from '../../components/languages/CountryFlag';
import { useEffect } from 'react';

const PlayRow = (props) => {
    const {item} = {...props};
    return (
        <>
            <Link className="no-underline" to={item.path}>
                <motion.div whileHover={{scale:1.02, y:-2}} className={item.color + " w-full lg:h-32 cursor-pointer flex shadow-xl justify-center flex-col justify-center place-items-center gap-4 rounded-2xl p-6"}>
                    <div className="flex flex-row text-base sm:text-lg md:text-2xl lg:text-3xl place-items-center justify-center gap-6">
                        <div className={item.icon_color}>
                            <FontAwesomeIcon icon={item.icon} />
                        </div>
                        <div className=" text-white font-bold ">
                            {item.text}
                        </div>
                    </div>
                </motion.div>
            </Link>
        </>
    )
}

const Play = () => {
    const [items, setItems] = useState([
       
    ]);
    const  [loading, setLoading] = useState(true);
    let { globalSelectedTargetLanguage, activeUserLanguages, allLanguages } = useLanguagesContext();
    const { dailyGoals } = useUserStatisticsContext();
    let today = new Date();
    const [todayNumber, setTodayNumber] = useState(today.getDay() === 0 ? 6 : today.getDay() - 1);
    const linksUpdated = useRef(false);

    useEffect(()=>{
        linksUpdated.current = false;
    },[globalSelectedTargetLanguage]);

    useEffect(()=>{
        if (globalSelectedTargetLanguage === null || dailyGoals === null){return}
        if (linksUpdated.current === true){return}
        let itemsMetadata = [ {'text': "PLAY", 
        'color': 'bg-gradient-radial from-green-400 to-persian-green',
        'icon': faPlay, 
        'icon_color': 'text-white',
        'path': '/my-vocabulary/'+globalSelectedTargetLanguage+'/mix?level=spaced-repetition&items='+((dailyGoals.hasOwnProperty(globalSelectedTargetLanguage) && dailyGoals[globalSelectedTargetLanguage]?.total_correct_items_per_day?.[todayNumber] !== undefined && dailyGoals[globalSelectedTargetLanguage]?.total_correct_items_per_day?.[todayNumber] !== 0) ? dailyGoals[globalSelectedTargetLanguage]['total_correct_items_per_day'][todayNumber] : 10)+'&mode=mix&back=play',
        },
        {'text': "REVIEW MISTAKES", 
            'color': 'bg-gradient-radial from-red-300 to-burnt-sienna', 
            'icon': faSchool, 
            'icon_color': 'text-white', 
            'path': '/my-vocabulary/'+globalSelectedTargetLanguage+'/review-mistakes?back=play' 
        },
        {'text': "MINIGAMES", 
            'color': 'bg-gradient-radial from-yellow-400 to-sandy-brown', 
            'icon': faGamepad, 
            'icon_color': 'text-white',
            'path': '/my-vocabulary/play' 
        }];
        setLoading(true);
        setItems(itemsMetadata);
        setLoading(false);
       
    },[globalSelectedTargetLanguage, linksUpdated, dailyGoals, todayNumber]);

    return (
        <>
            <div className="  flex flex-col gap-4 justify-center h-full min-h-screen">
                {!loading &&
                    <>
                        <div className="flex flex-row text-gray-600 place-items-center opacity-80 font-bold gap-3 justify-start">
                            <div className="rounded-xl overflow-hidden">
                                <CountryFlag size="1.3em" animation={false} countryCode={allLanguages[0][globalSelectedTargetLanguage]['country_flag']} flagName={allLanguages[0][globalSelectedTargetLanguage]['name']['en']} />
                            </div>
                            <div>
                                {(allLanguages !== null && globalSelectedTargetLanguage !== null) && allLanguages[0][globalSelectedTargetLanguage]['name']['en']}
                            </div>
                        </div>
                        <div className="h-full flex flex-col gap-4 justify-center place-items-center">
                            <div className="relative   rounded-full p-6 md:h-160 md:w-160">
                                <div className="flex w-full h-full flex-col gap-8 justify-center">
                                    {items.map((item, itemIndex)=>(
                                        <>
                                            <PlayRow key={"row_"+itemIndex} item={item} />
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </>
                }
                
            </div>
        </>
    )
}

export default Play