import React, {useState, useEffect} from 'react';
import {useParams, Link} from "react-router-dom";
import { motion } from "framer-motion";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPen} from '@fortawesome/pro-duotone-svg-icons';
import {faNote} from '@fortawesome/pro-duotone-svg-icons';
import Select from 'react-select';

const TablePlayOptions = ({...props}) => {
    const {howManyItems, 
        howManyItemsOnChange, 
        numberOfItemsSetting, 
        highlightColumnSetting, 
        highlightColumnSettingDict, 
        highlightColumnId, 
        highlightOnChange, 
        flashcardsLink,
        fillInLink,
        customGameClick,
        designMode 
    } = props;
  return (
    <>
        <div className="m-auto flex flex-col justify-center place-items-center gap-3">
            <div className="flex flex-col gap-1">
                <div className="font-bold text-sm text-gray-600 text-center">
                    Number of rows to play with
                </div>
                <div className="w-64 text-center">
                    <Select value={howManyItems} onChange={howManyItemsOnChange} isSearchable={false} options={numberOfItemsSetting} />
                </div>
            </div>
            {(highlightColumnSetting !== null && highlightColumnSettingDict !== null && highlightColumnId !== null) &&
                <>
                <div className="flex flex-col gap-1">
                    <div className="font-bold text-sm text-gray-600 text-center">
                        Highlight column
                    </div>
                    <div className="w-64 text-center">
                        <Select value={highlightColumnSettingDict[highlightColumnId]} onChange={highlightOnChange} isSearchable={false} options={highlightColumnSetting} />
                    </div>
                </div>
                </>
            }
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-3">
            <motion.div style={{opacity:0.9}} whileHover={{scale:1.05, opacity:1}} className="">
                {designMode !== "custom" ?
                    <Link to={flashcardsLink} className="no-underline">
                        <div className="flex h-full  flex-col gap-2 justify-center place-items-center">
                            <div className="text-gray-700 font-bold text-xl">
                                Flashcards
                            </div>
                            <div className="text-9xl text-sandy-brown">
                                <FontAwesomeIcon className="" icon={faNote} />
                            </div>
                        </div>
                    </Link>
                :
                    <>
                        <div onClick={()=>customGameClick("flashcards")} className="flex h-full cursor-pointer flex-col gap-2 justify-center place-items-center">
                            <div className="text-gray-700 font-bold text-xl">
                                Flashcards
                            </div>
                            <div className="text-9xl text-sandy-brown">
                                <FontAwesomeIcon className="" icon={faNote} />
                            </div>
                        </div>
                    </>
                }
            </motion.div>
            <motion.div style={{opacity:0.9}} whileHover={{scale:1.05, opacity:1}} className="">
                {designMode !== "custom" ?
                    <>
                        <Link to={fillInLink} className="no-underline">
                            <div className="flex h-full  flex-col gap-2 justify-center place-items-center">
                                <div className="text-gray-700 font-bold text-xl">
                                    Fill in
                                </div>
                                <div className="text-9xl text-burnt-sienna">
                                    <FontAwesomeIcon className="" icon={faPen} />
                                </div>
                            </div>
                        </Link>
                    </>
                :
                    <>
                        <div onClick={()=>customGameClick("fill-in")} className="flex h-full cursor-pointer flex-col gap-2 justify-center place-items-center">
                            <div className="text-gray-700 font-bold text-xl">
                                Fill in
                            </div>
                            <div className="text-9xl text-burnt-sienna">
                                <FontAwesomeIcon className="" icon={faPen} />
                            </div>
                        </div>
                    </>
                }
            </motion.div>
        </div>
    </>
  )
}

export default TablePlayOptions;