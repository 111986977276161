import React from 'react';
import {Link} from 'react-router-dom';
import FooterMenu from '../../components/menus/FooterMenu';

const Pricing = () => {
  return (
        <>
            <div className=" ">
                <h1>Pricing</h1>
                <p className="text-center">Yalango is currently 100% free to use and with no ads. In the future we will introduce a premium plan with additional features.</p>
            </div>
        </>
  )
}

export default Pricing;