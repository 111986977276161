import React, { useContext, useState, useEffect } from "react";
import useUserSettings from '../hooks/useUserSettings';
import useDecks from '../hooks/useDecks';
import useTables from '../hooks/useTables';
import useSearch from '../hooks/useSearch';
import useNotes from '../hooks/useNotes';
import useFolders from '../hooks/useFolders';
import useTexts from '../hooks/useTexts';
import {useAuth} from '../contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-duotone-svg-icons';
import useCourses from "../hooks/useCourses";

const MyContentContext = React.createContext();

export function useMyContentContext() {
    return useContext(MyContentContext);
};

export function MyContentProvider({ children }) {
    const {currentUser} = useAuth();
    const [error, setError] = useState(null);
    const [decks, setDecks] = useState(null);
    const [tables, setTables] = useState(null);
    const [texts, setTexts] = useState(null);
    const [courses, setCourses] = useState(null);
    const [queriedTableRows, setQueriedTableRows] = useState({});
    const [queriedTableColumns, setQueriedTableColumns] = useState({});
    const [folders, setFolders] = useState(null);
    const [globalFoldersOrder, setGlobalFoldersOrder] = useState(null);
    const [notes, setNotes] = useState(null);
    const [loading, setLoading] = useState(true);
    const {fetchAllDecksFromCurrentUserInRealtime} = useDecks();
    const {getAllTablesFromCurrentUserInRealtime} = useTables();
    const {getAllNotesFromCurrentUserInRealtime} = useNotes();
    const {fetchAllFoldersFromCurrentUserInRealtime, 
        fetchGlobalFoldersOrderInRealtime, 
        updateGlobalFolderOrderInTargetLanguage} = useFolders();

    const {fetchAllTextsFromCurrentUserInRealtime} = useTexts();
    const {fetchAllCoursesFromCurrentUserInRealtime} = useCourses();

    const {getScopedDeckCardsApiKey} = useSearch();
    const [scopedTypesenseDeckCardsApiKey, setScopedTypesenseDeckCardsApiKey] = useState(null);

    useEffect(()=>{
        let deckUnsub = null;
        let tableUnsub = null;
        let notesUnsub = null;
        let foldersUnsub = null;
        let globalOrderUnsub = null;
        let textsUnsub = null;
        let coursesUnsub = null;
        const fetchData = async () => {
            deckUnsub = await fetchAllDecksFromCurrentUserInRealtime(setDecks);
            tableUnsub = await getAllTablesFromCurrentUserInRealtime(setTables);
            notesUnsub = await getAllNotesFromCurrentUserInRealtime(setNotes);
            foldersUnsub = await fetchAllFoldersFromCurrentUserInRealtime(setFolders);
            globalOrderUnsub = await fetchGlobalFoldersOrderInRealtime(setGlobalFoldersOrder);
            textsUnsub = await fetchAllTextsFromCurrentUserInRealtime(setTexts);
            coursesUnsub = await fetchAllCoursesFromCurrentUserInRealtime(setCourses);
            setLoading(true);
        }
        if (currentUser !== null){
            fetchData();
        } else {
            setDecks(null);
            setTables(null);
            setNotes(null);
            setCourses(null);
            setFolders(null);
            setTexts(null);
            setGlobalFoldersOrder(null);
            setError(null);
            setQueriedTableRows(null);
            setQueriedTableColumns(null);
            setScopedTypesenseDeckCardsApiKey(null);
        }
        return () => {
            (deckUnsub !== null && deckUnsub !== undefined) && deckUnsub();
            (tableUnsub !== null && tableUnsub !== undefined) && tableUnsub();
            (notesUnsub !== null && notesUnsub !== undefined) && notesUnsub();
            (foldersUnsub !== null && foldersUnsub !== undefined) && foldersUnsub();
            (globalOrderUnsub !== null && globalOrderUnsub !== undefined) && globalOrderUnsub();
            (textsUnsub !== null && textsUnsub !== undefined) && textsUnsub();
            (coursesUnsub !== null && coursesUnsub !== undefined) && coursesUnsub();
        }
    }, [currentUser]);


    const getScopedTypesenseDeckCardsApiKey = async () => {
        if (scopedTypesenseDeckCardsApiKey === null){
            let apiKey = await getScopedDeckCardsApiKey();
            setScopedTypesenseDeckCardsApiKey(apiKey);
            return apiKey;
        } else {
            if (Date.now() > scopedTypesenseDeckCardsApiKey.expires){
                // expired, so regenerate
                let apiKey = await getScopedDeckCardsApiKey();
                setScopedTypesenseDeckCardsApiKey(apiKey);
                return apiKey;
            } else {
                return scopedTypesenseDeckCardsApiKey;
            }
        }
    }

    const value = {
        decks, 
        tables,
        notes,
        folders,
        texts,
        courses,
        globalFoldersOrder,
        updateGlobalFolderOrderInTargetLanguage,
        queriedTableRows, 
        setQueriedTableRows,
        queriedTableColumns, 
        setQueriedTableColumns,
        loading, 
        getScopedTypesenseDeckCardsApiKey, 
        scopedTypesenseDeckCardsApiKey
    }

    return (
        <MyContentContext.Provider value={value}>
            {children}
        </MyContentContext.Provider>
    )
}