import React, {useState} from 'react';
import {faEllipsisV} from '@fortawesome/pro-solid-svg-icons';
import {faShare} from '@fortawesome/pro-solid-svg-icons';
import {useNavigate} from 'react-router-dom';
import Icon from '../../../components/general/Icon';
import { useAuth } from "../../../contexts/AuthContext";
import {Link} from 'react-router-dom';
import useComments from '../../../hooks/useComments';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';

const CommentMenu = (props) => {
    const {setShowCommentMenu, commentLocation, shareCommentClick, refreshComments, comment, showCommentMenu, commentPosterUid} = props;
    const {currentUser} = useAuth();
    const {deleteComment} = useComments();
    const navigate = useNavigate();
    console.log(commentLocation);
    const deleteCommentClick = async (e) => {
        console.log("Delete ", comment.comment_doc_id);
        e.stopPropagation();
        setShowCommentMenu(false);
        if (currentUser === null){return null}
        await deleteComment({commentDocId: comment.comment_doc_id}).catch(err=>console.log(err));
        if (commentLocation === "original_post_page"){
            navigate("/feed");
        } else {
            await refreshComments();
        }
    }

    const commentMenuClick = (e) => {
        e.stopPropagation();
        setShowCommentMenu(!showCommentMenu);
        console.log("Click", showCommentMenu)
    }

    const backdropClick = (e) => {
        e.stopPropagation();
        setShowCommentMenu(false);
    }

    const shareLinkClick = () => {
        shareCommentClick();
    }

    return (
        <>
            
            <div className="relative">
                <div onClick={(e)=>commentMenuClick(e)} className="absolute hover:scale-50 right-0 top-0 p-2 cursor-pointer text-lg z-10">
                    <Icon color="charcoal" icon={<FontAwesomeIcon icon={faEllipsisV} />} /> 
                </div>
                {showCommentMenu &&
                    <>
                        <div onClick={(e)=>backdropClick(e)} className="z-20 w-screen h-screen  left-0 top-0 fixed">
                        </div>
                        <div className=" w-64 text-sm z-30 rounded-lg shadow-xl bg-white right-0 top-0 absolute">
                            <div className="flex w-full font-bold text-gray-700 flex-col justify-start place-items-start">
                                <div onClick={shareLinkClick} className="flex w-full cursor-pointer flex-row gap-3 hover:bg-gray-100 rounded-lg  p-3">
                                    <div>
                                        <Icon color="charcoal" icon={<FontAwesomeIcon icon={faShare} />} />
                                    </div>
                                    <div>
                                        Share
                                    </div>
                                </div>
                                {currentUser !== null && currentUser.uid === commentPosterUid &&
                                    <div onClick={deleteCommentClick} className="flex w-full cursor-pointer flex-row gap-3 hover:bg-gray-100 rounded-lg  p-3">
                                        <div>
                                            <Icon color="red" icon={<FontAwesomeIcon icon={faTrash} />} />
                                        </div>
                                        <div>
                                            Delete
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default CommentMenu;