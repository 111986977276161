import React, {useState, useEffect} from 'react';
import MyFoldersTreeView from '../../components/folders/MyFoldersTreeView';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEllipsisV} from '@fortawesome/pro-solid-svg-icons';
import {faHeart} from '@fortawesome/pro-solid-svg-icons';
import {faX} from '@fortawesome/pro-solid-svg-icons';
import {faQuestion} from '@fortawesome/pro-solid-svg-icons';
import {faTableColumns} from '@fortawesome/pro-solid-svg-icons';
import {faCardsBlank} from '@fortawesome/pro-solid-svg-icons';
import {faNote} from '@fortawesome/pro-solid-svg-icons';
import Icon from '../../components/general/Icon';
import {Link} from 'react-router-dom';
import {faMagnifyingGlass} from '@fortawesome/pro-solid-svg-icons';
import {Helmet} from 'react-helmet';
import HelmetData from '../../components/helmet/HelmetData';
import MyLibraryMenu from '../../components/menus/MyLibraryMenu';
import MyLibrarySidebarMenu from '../../components/folders/components/MyLibrarySidebarMenu';
import MyContentMenu from '../../components/menus/MyContentMenu';
import MyLibraryFolder from './MyLibraryFolder';
import NoActiveTargetLanguage from '../../components/errors/NoActiveTargetLanguage';
import {useLanguagesContext} from '../../contexts/LanguagesContext';
import useQueryStrings from '../../hooks/useQueryStrings';
import { useUserInfoContext } from '../../contexts/UserInfoContext';

const GuideWindow = ({activeGuideContent, nextPage, setActiveGuideContent, setShowGuide}) => {

    let data = {
        'my-library-menu': {
            'title': 'My library',
            'text': 'Your decks, tables, and folders are in My Library. You can also explore public content by other users and add it to your library.',
            'button_text': 'Next step' 
        }, 
        'my-content': {
            'title': 'Creating content',
            'text': 'You create decks and tables by clicking on Create Item. If you wish to organize your content in folders, you can also create folders and nested folders.',
            'button_text': 'Next step' 
        }, 
        'organizing-content': {
            'title': 'Organizing content',
            'text': "You can reorder all your items with drag and drop. To add an item to a folder, click on the item's menu and choose Move Item.",
            'button_text': 'Next step' 
        }, 
    };
    let thisPage = data[activeGuideContent];

    const nextPageClick = () => {
        if (nextPage !== null){
            setActiveGuideContent(nextPage);
        } else {
            setShowGuide(false);
            setActiveGuideContent("my-library-menu");
        }
    }
    return (
        <>
            <div className="flex relative flex-col justify-center place-items-center text-center gap-2 bg-white rounded-lg shadow-lg  p-6 w-3/4 md:w-2/4">
                <div className="text-xs text-charcoal absolute right-0 top-0 p-2" onClick={()=>setShowGuide(false)}>
                    <Icon icon={<FontAwesomeIcon icon={faX} />} />
                </div>
                <div className="font-bold text-gray-600 text-base md:text-lg">
                    {thisPage.title}
                </div>
                <div className="text-sm md:text-base">
                    {thisPage.text}
                </div>
                <div onClick={nextPageClick} className="bg-persian-green my-2 text-white font-bold cursor-pointer rounded-lg shadow-xl p-3">
                    {nextPage !== null ?
                        <>
                            {thisPage.button_text}
                        </>
                    :
                        <>
                            Close guide
                        </>
                    }
                </div>
            </div>
        </>
    )
}

const MyLibrary = (props) => {
  const {libraryPage} = props;
  let {activeUserLanguages, globalSelectedTargetLanguage, loading} = useLanguagesContext();
  const {userSettings} = useUserInfoContext();
  console.log("view: ", userSettings)
  const [showOptionsMenu, setShowOptionsMenu] = useState(false);
  const openShowOptionsMenu = (e) => {
      e.stopPropagation();
      setShowOptionsMenu(!showOptionsMenu);
  }

  const {getQueryStrings} =  useQueryStrings();
  const [showGuide, setShowGuide] = useState(false);
  const [guidePages, setGuidePages] = useState(["my-library-menu", "my-content"]);
  const [activeGuideContent, setActiveGuideContent] = useState(guidePages[0]);
  const [view, setView] = useState(userSettings !== null && userSettings.hasOwnProperty("library_view") ? userSettings["library_view"] : "grid");

  useEffect(()=>{
    if (userSettings !== null) {
      if ('library_view' in userSettings){
        setView(userSettings['library_view']);
      } 
    }
  },[userSettings]);

  useEffect(()=>{
    const fetchData = async () => {
        let r = await getQueryStrings();
        if (r !== null && "guide" in r && r['guide'] === "true"){
            setShowGuide(true);
        }
    }
    
    fetchData();
},[]);

  const closeShowOptionsMenu = () => {
      setShowOptionsMenu(false);
  }

  const generalBackgroundClick = () => {
    closeShowOptionsMenu();
}
//<MyLibraryMenu activeName="library" />
/*
<div className="text-sm w-1/6 hidden lg:block">
                                            <MyLibrarySidebarMenu />
                                        </div>
*/
  return (
    <>
        <HelmetData title={"My Library"} description={"View your library on Yalango."} />
        {(activeUserLanguages !== null && activeUserLanguages.length === 0) ?
                <>
                    <NoActiveTargetLanguage />
                </>
        :
            <>
                <div className="pb-48">
                    <div onClick={generalBackgroundClick} className="  h-full min-h-screen overflow-visible ">
                        <div onClick={(e)=>openShowOptionsMenu(e)} className="absolute sm:right-4 sm:top-4 right-2 top-0 p-2 flex flex-row gap-4 cursor-pointer text-base">
                            <Icon color="charcoal"   icon={<FontAwesomeIcon icon={faEllipsisV} />} /> 
                        </div>
                        {showOptionsMenu && 
                            <>
                                <div>
                                    <div className="absolute sm:right-8 sm:top-10 right-6 top-6 overflow-visible text-base w-48 z-10 rounded-lg shadow-xl bg-white ">
                                        <div className="flex text-sm whitespace-nowrap font-bold text-gray-700 flex-col justify-start place-items-start">
                                            <Link to={'/my-favorites'} className="no-underline w-full" >
                                            <div className="flex flex-row gap-3 hover:bg-gray-100 w-full rounded-lg  p-3">
                                                <div>
                                                    <Icon color="red" icon={<FontAwesomeIcon icon={faHeart} />} />
                                                </div>
                                                <div>
                                                    My favorites
                                                </div>
                                            </div>
                                            </Link>
                                            <Link to={'/my-decks/cards'} className="no-underline w-full" >
                                            <div className="flex flex-row gap-3 hover:bg-gray-100 w-full rounded-lg  p-3">
                                                <div>
                                                    <Icon color="charcoal" icon={<FontAwesomeIcon icon={faMagnifyingGlass} />} />
                                                </div>
                                                <div>
                                                    Search for deck cards
                                                </div>
                                            </div>
                                            </Link>
                                            <Link to={'/my-decks'} className="no-underline w-full" >
                                            <div className="flex flex-row gap-3 hover:bg-gray-100 w-full rounded-lg  p-3">
                                                <div>
                                                    <Icon color="red" icon={<FontAwesomeIcon icon={faCardsBlank} />} />
                                                </div>
                                                <div>
                                                    View only decks
                                                </div>
                                            </div>
                                            </Link>
                                            <Link to={'/my-tables'} className="no-underline w-full" >
                                            <div className="flex flex-row gap-3 hover:bg-gray-100 w-full rounded-lg  p-3">
                                                <div>
                                                    <Icon color="blue" icon={<FontAwesomeIcon icon={faTableColumns} />} />
                                                </div>
                                                <div>
                                                    View only tables
                                                </div>
                                            </div>
                                            </Link>
                                            <div onClick={()=>setShowGuide(true)}  className="flex cursor-pointer flex-row gap-3 hover:bg-gray-100 w-full rounded-lg  p-3">
                                                <div>
                                                    <Icon color="charcoal" icon={<FontAwesomeIcon icon={faQuestion} />} />
                                                </div>
                                                <div>
                                                    Show tutorial
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </>
                        }   
                        <div className="flex flex-col overflow-visible gap-8 h-full">
                            <div className="relative">
                                <div className={(showGuide && activeGuideContent !== "my-library-menu" ? 'opacity-40 blur-sm filter grayscale' : 'opacity-100')}>
                                    
                                   
                                    {showGuide && activeGuideContent === "my-library-menu" && 
                                        <>
                                            <div className="absolute z-40 top-20 flex flex-row justify-center w-full">
                                                <GuideWindow setActiveGuideContent={setActiveGuideContent} nextPage={"my-content"} setShowGuide={setShowGuide} activeGuideContent={activeGuideContent} />
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="relative">
                                <div className={(showGuide && activeGuideContent !== "my-content" && activeGuideContent !== "organizing-content" ? 'opacity-40 blur-sm filter grayscale' : 'opacity-100')}>
                                    {showGuide && activeGuideContent === "my-content" && 
                                        <>
                                            <div className="absolute z-40 top-16 left-0 flex flex-row justify-start w-full">
                                                <GuideWindow setActiveGuideContent={setActiveGuideContent} nextPage={"organizing-content"} setShowGuide={setShowGuide} activeGuideContent={activeGuideContent} />
                                            </div>
                                        </>
                                    }
                                    {showGuide && activeGuideContent === "organizing-content" && 
                                        <>
                                            <div className="absolute z-40 top-32 flex flex-row justify-start w-full">
                                                <GuideWindow setActiveGuideContent={setActiveGuideContent} nextPage={null} setShowGuide={setShowGuide} activeGuideContent={activeGuideContent} />
                                            </div>
                                        </>
                                    }
                                    <div className="flex flex-row gap-3">
                                        <div className="w-full">
                                            <MyFoldersTreeView libraryPage={libraryPage} outputDesign={"page"} view={view} setView={setView} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        }
    </>
  )
}

export default MyLibrary;