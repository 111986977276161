import React from 'react';
import Modal from '../../general/Modal';
import Button from '../../general/Button';
import YalangoHead from '../../../assets/svg/yalango/YalangoHead.svg';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLoader} from '@fortawesome/pro-solid-svg-icons';
import {faUser} from '@fortawesome/pro-duotone-svg-icons';
import  {motion} from 'framer-motion';

const ModalContent = ({sendingNewMessageLoading, currentUser, sendNewMessageClick, newMessage, setNewMessage, messages, selectedItem, closeFunction}) => {
    return (
        <>
            <div className="text-center">
                <div className="mb-4">
                    <div className="p-4 mb-4 text-xl font-bold bg-charcoal rounded-lg shadow-lg text-white">
                        {('status' in selectedItem && selectedItem.status === "read") &&
                            <>
                                Status: Feedback read
                            </>
                        }
                        {('status' in selectedItem && selectedItem.status === "work in progress") &&
                            <>
                                Status: Work in progress
                            </>
                        }
                        {('status' in selectedItem && selectedItem.status === "solved") &&
                            <>
                                Status: Implemented
                            </>
                        }
                        {('status' in selectedItem && selectedItem.status === "solved") &&
                            <>
                                Status: Implemented
                            </>
                        }
                        {!('status' in selectedItem) &&
                            <>
                                Status: Feedback sent
                            </>
                        }
                    </div>
                </div>
                
                {sendingNewMessageLoading ?
                    <>
                        <div className="text-center text-black">
                            <FontAwesomeIcon icon={faLoader} className="fa-spin" />
                        </div>
                    </>
                :
                    <>
                        <div className="max-h-64 p-4 overflow-y-auto">
                            <ChatMessage date={selectedItem.created_timestamp} message={selectedItem.text.toString()} user={"You"} />
                            <div className="text-sm font-bold italic">
                                {('status' in selectedItem && selectedItem.status === "read") &&
                                    <>
                                        Your feedback has been read by Yalango.
                                    </>
                                }
                                {('status' in selectedItem && selectedItem.status === "work in progress") &&
                                    <>
                                        We're working on your request. 
                                    </>
                                }
                                {('status' in selectedItem && selectedItem.status === "solved") &&
                                    <>
                                        We have solved your request.
                                    </>
                                }
                                {!('status' in selectedItem) &&
                                    <>
                                        Your feedback has been successfully sent.
                                    </>
                                }
                            </div>
                            {messages !== null && messages.map((m, index)=>(
                                <>
                                    {'uid' in m &&
                                        <ChatMessage key={"message_"+index} date={m.created_timestamp} message={m.body} user={currentUser.uid === m.uid ? "You" : "Yalango"} />
                                    }
                                </>
                            ))}
                        </div>
                        <div>
                            <textarea onChange={(e)=>setNewMessage(e.target.value)} value={newMessage} className="w-full sm:w-5/6 mt-2 m-auto focus:outline-none h-24 max-h-40 rounded-lg shadow-xl p-3 border-solid  border-2 focus:border-persian-green" />
                        </div>
                        <div className="mt-4 flex flex-row gap-2 justify-center">
                            <div>
                                <Button onClick={closeFunction} text="Close window" color="charcoal" />
                            </div>
                            <div className="text-center">
                                <Button onClick={sendNewMessageClick} text="Send message" color="green" />
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    )
}

const FeedbackItemModal = ({sendingNewMessageLoading, currentUser, sendNewMessageClick, newMessage, setNewMessage, messages, selectedItem, size, closeFunction}) => {
  return (
      <>
          <Modal bgColor="bg-gray-100" size={size} content={<ModalContent sendingNewMessageLoading={sendingNewMessageLoading} currentUser={currentUser} sendNewMessageClick={sendNewMessageClick} newMessage={newMessage} setNewMessage={setNewMessage} messages={messages} selectedItem={selectedItem} closeFunction={closeFunction} />} closeFunction={closeFunction} />
      </>
  )
};

const ChatMessage = ({message, user, date}) => {

    return (
        <>
            <div className="my-4">
                <div className={(user === "You" ? 'justify-end ' : 'justify-start ' ) + " flex flex-row place-items-center gap-4"}>
                    {user === "Yalango" && <ChatIconBubble user={user} /> }
                    <div className="flex flex-col gap-1 w-4/6 ">
                        <div className={(user === "You" ? 'bg-persian-green text-white ' : 'bg-white text-black ') + " text-left rounded-xl shadow-lg py-2 px-4 "}>
                            {message}
                        </div>
                        {(date !== undefined && date !== null) && 
                            <>
                                <div className="text-xs  text-right">
                                    {date.toDate().toDateString()}
                                </div>
                            </>
                        }
                    </div>
                    {user === "You" && <ChatIconBubble user={user} /> }
                </div>
            </div>
        </>
    )
}

const ChatIconBubble = ({user}) => {

    return (
        <>
            <div className="flex flex-col gap-0">
                <div className="text-blue-500 h-16 w-16 text-4xl  rounded-full p-2">
                    {user === "You" ? 
                        <>
                            <FontAwesomeIcon icon={faUser} />
                        </>
                    :
                        <>
                            <img className="w-16" src={YalangoHead} aria-label="Yalango logo" />
                        </>
                    }
                </div>
                <div className="font-bold text-sm text-gray-600">
                    {user === "You" ? "You" : "Yalango"}
                </div>
            </div>
        </>
    )
}

export default FeedbackItemModal;
