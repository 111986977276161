import React, {useState} from 'react';
import Button from '../../general/Button';
import Modal from '../../general/Modal';
import Select from 'react-select';
import {useLanguagesContext} from '../../../contexts/LanguagesContext';
import useTables from '../../../hooks/useTables';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {motion} from 'framer-motion';
import {faLoader} from '@fortawesome/pro-solid-svg-icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const ModalContent = ({saving, table, columns, closeFunction, onDragEnd, saveNewOrder}) => {
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    return(
        <>
        <div className={(saving ? 'opacity-30 ' : '') + " "}>
            {saving && 
                <>
                    <div className="text-center z-10 text-4xl absolute left-1/2 top-1/2 text-black">
                        <FontAwesomeIcon icon={faLoader} className="fa-spin" />
                    </div>
                </>
            }
            <h1>Change column order</h1>
                <div className="h-full flex flex-col gap-4">
                    <div className="h-96 overflow-y-auto">
                    <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="elements">
                                {(provided) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        {columns.map((column, index)=>(
                                            <>
                                                <Draggable key={"draggable_"+index} draggableId={"id_"+index} index={index}>
                                                    {(provided) => (
                                                        <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} className="bg-charcoal text-white font-bold cursor-pointer p-3 my-3 text-center rounded-lg shadow-lg bold-gray">
                                                            {index+1}. {column.name}
                                                        </div>
                                                    )}
                                                </Draggable>
                                            </>
                                        ))}
                                        {provided.placeholder}
                                    </div> 
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                    <div className="flex flex-row justify-center gap-3">
                        <div>
                            <Button onClick={closeFunction} color="charcoal" text="Cancel" />
                        </div>
                        <div>
                            <Button onClick={saveNewOrder} color="green" text="Save changes" />
                        </div>
                    </div>
                </div>
                
            </div>
        </>
    )
}

const TableColumnOrderModal = ({saving, closeFunction, table, columns, onDragEnd, saveNewOrder}) => {

    return (
        <>
            <Modal  closeFunction={closeFunction} content={<ModalContent saveNewOrder={saveNewOrder} onDragEnd={onDragEnd} saving={saving} table={table} closeFunction={closeFunction} columns={columns} />} size="3xl" />
        </>
    )
}

export default TableColumnOrderModal;