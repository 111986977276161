import React from 'react';
import {projectFirestore, timeStamp, firebase, projectFunctions} from '../firebase/config.js';
import { useAuth } from "../contexts/AuthContext";

import useDecks from './useDecks';
import useLanguages from './useLanguages';

export default function useVocabulary() {

    let { currentUser } = useAuth();

    const fetchTargetWordsDoc = async (targetLang) => {
        let query = projectFirestore.collection("users").doc(currentUser.uid).collection("private-data").doc("vocabulary").collection("languages").doc(targetLang).collection("metadata").doc("target_words");
        let r = await query.get();
        let targetWords = [];
        if (r.exists){
            if (r.data().hasOwnProperty("target_words")){
                targetWords.push(...r.data()['target_words']);
            }
        }
        let lowerCase = [];
        for (const word of targetWords){
            lowerCase.push(word.toLowerCase());
        }
        return lowerCase;
    }

    const fetchTargetWordFromVocabulary = async (targetWord, targetLang) => {
        let query = projectFirestore.collection("users").doc(currentUser.uid).collection("private-data").doc("vocabulary").collection("languages").doc(targetLang).collection("items").where("target", "==", targetWord);
        let r = await query.get();
        let doc = false;
        if (!r.empty){
            doc = r.docs[0].data();
        }
        return doc;
    }

    const fetchItemFromVocabularyInLanguage = async ({id, targetLang}) => {
        id = parseInt(id);
        let query = projectFirestore.collection("users").doc(currentUser.uid).collection("private-data").doc("vocabulary").collection("languages").doc(targetLang).collection("items").where("id", "==", id);
        let r = await query.get();
        let doc = false;
        if (!r.empty){
            doc = r.docs[0].data();
        }
        return doc;
    }

    const fetchKnownVocabulary = async (targetLang) => {
        let query = projectFirestore.collection("users").doc(currentUser.uid).collection("private-data").doc("vocabulary").collection("languages").doc(targetLang).collection("metadata").doc("known_target_words");
        let r = await query.get();
        let targetWords = [];
        if (r.exists){
            if (r.data().hasOwnProperty("known_target_words")){
                targetWords.push(...r.data()['known_target_words']);
            }
        }
        console.log("Known: ", targetWords)
        return targetWords;
    }

    const addTargetWordsToKnownVocabulary = async (words, targetLang) => {
        let query = projectFirestore.collection("users").doc(currentUser.uid).collection("private-data").doc("vocabulary").collection("languages").doc(targetLang).collection("metadata").doc("known_target_words");
        let obj = {
            'known_target_words': firebase.firestore.FieldValue.arrayUnion(...words), 
            'last_updated_timestamp': timeStamp
        };
        await query.set(obj, {merge: true}).catch(err=>{console.log(err)});
        return true;
    }

    const removeIgnoreListFromWord = (word, ignoreList) => {
        ignoreList.forEach(ignore=>{
            word = word.replace(ignore, "");
        }) 
        return word;
    }

    const calculatePercentageOfVocabulary = (textBody, splitString, vocabulary) => {
        let listOfUnknownWords = [];
        let ignoreList = [".", ",", "?", "!", "。", "，", ":", ";", "`", "¿", "？", "！", "-", "(", ")"];
        let allWords = [];
        
        textBody.forEach((paragraph)=>{
            paragraph.children.forEach((child)=>{
                child.text.split("\n").forEach((sentence)=>{
                    sentence.split(splitString).forEach((word)=>{
                        word = removeIgnoreListFromWord(word.trim().toLowerCase(), ignoreList);
                        if (word !== "" && word !== " " && !ignoreList.includes(word.trim())){
                            allWords.push(word);
                        }
                        if (!ignoreList.includes(word) && !vocabulary.includes(word)){
                            if (word.trim() !== ""){
                                listOfUnknownWords.push(word);
                            }
                        }
                    })
                })
            })
        });
        listOfUnknownWords = [...new Set(listOfUnknownWords)]; //unique
        allWords = [...new Set(allWords)];
        console.log(listOfUnknownWords.length, allWords.length);
        let percentage = Math.round(100*((allWords.length - listOfUnknownWords.length)/allWords.length));
        return percentage;
    }

    const fetchUnfinishedOverdueSpacedVocabulary = async (uid, targetLang) => {
        let docs = [];
        let addedVocabIds = [];

        let tonightMidnight = new Date();
        tonightMidnight.setDate(tonightMidnight.getDate() + 1);
        tonightMidnight.setHours(0,0,0,0);
        let tonightTimestamp = firebase.firestore.Timestamp.fromDate(tonightMidnight); //tonight

        let lastNightMidnight = new Date(); //last night
        lastNightMidnight.setHours(0,0,0,0);
        let lastNightMidnightTimestamp = firebase.firestore.Timestamp.fromDate(lastNightMidnight);

        let queryToday = projectFirestore.collection("users").doc(uid)
        .collection("private-data").doc("vocabulary").collection("languages")
        .doc(targetLang).collection("items")
        .where("all_sources_deleted", "==", false)
        .where("spaced_repetition_finished", "==", false)
        .where("spaced_repetition_next_review", "<", lastNightMidnightTimestamp);

        let rToday = await queryToday.get();
        if (!rToday.empty){
            rToday.docs.forEach(doc=>{
                let d = doc.data();
                d['doc_id'] = doc.ref.id;
                if (!addedVocabIds.includes(doc.data().id)){
                    let addBool = true;
                    if (d.hasOwnProperty("spaced_repetition_last_correct_timestamp")){
                        let lastCorrectDate = d['spaced_repetition_last_correct_timestamp'].toDate();
                        if (lastCorrectDate < lastNightMidnight){
                            // check if it's been tested today
                            addedVocabIds.push(doc.data().id);
                            docs.push(d);
                        }
                    }
                }
            });
        }

        return docs;
    }

    const fetchUnfinishedSpacedVocabularyDueToday = async (uid, targetLang) => {
        let docs = [];
        let addedVocabIds = [];

        let tonightMidnight = new Date();
        tonightMidnight.setDate(tonightMidnight.getDate() + 1);
        tonightMidnight.setHours(0,0,0,0);
        let tonightTimestamp = firebase.firestore.Timestamp.fromDate(tonightMidnight); //tonight

        let lastNightMidnight = new Date(); //last night
        lastNightMidnight.setHours(0,0,0,0);
        let lastNightMidnightTimestamp = firebase.firestore.Timestamp.fromDate(lastNightMidnight);

        let queryToday = projectFirestore.collection("users").doc(uid)
        .collection("private-data").doc("vocabulary").collection("languages")
        .doc(targetLang).collection("items")
        .where("all_sources_deleted", "==", false)
        .where("spaced_repetition_finished", "==", false)
        .where("spaced_repetition_next_review", "<", tonightTimestamp)
        .where("spaced_repetition_next_review", ">", lastNightMidnightTimestamp);

        let rToday = await queryToday.get();
        if (!rToday.empty){
            rToday.docs.forEach(doc=>{
                let d = doc.data();
                d['doc_id'] = doc.ref.id;
                if (!addedVocabIds.includes(doc.data().id)){
                    let addBool = true;
                    if (d.hasOwnProperty("spaced_repetition_last_correct_timestamp")){
                        let lastCorrectDate = d['spaced_repetition_last_correct_timestamp'].toDate();
                        if (lastCorrectDate < lastNightMidnight){
                            // check if it's been tested today
                            addedVocabIds.push(doc.data().id);
                            docs.push(d);
                        }
                    }
                }
            });
        }

        return docs;
    }

    const fetchUnstartedSpacedVocabulary = async (uid, targetLang, limit) => {
        /* 
            Chooses the next cards based on which cards you've played the least. If you've played two words the same amount of times, it shows the most recently created first.
             .orderBy("created", "desc")
            */ 
        let query = projectFirestore.collection("users").doc(uid).collection("private-data").doc("vocabulary").collection("languages").doc(targetLang).collection("items")
        .where("all_sources_deleted", "==", false)
        .where("spaced_repetition_finished", "==", false)
        .where("spaced_repetition_next_review", "==", null)
        .orderBy("last_updated", "asc")
        .limit(limit);

        let rNull = await query.get();
        
        let docs = [];
        let addedVocabIds = [];

        if (!rNull.empty){
            rNull.docs.forEach(doc=>{
                let d = doc.data();
                d['doc_id'] = doc.ref.id;
                if (!addedVocabIds.includes(doc.data().id)){
                    addedVocabIds.push(doc.data().id);
                    docs.push(d);
                }
            });
        }
        return docs;
    }

    const fetchStartedSpacedVocabularyDueInFuture = async (uid, targetLang, limit) => {
        /*
            Get words due in future. Sort by words longest into the future first to make sure you don't always get the same words. Then search for words played the least.
        */
        let docs = [];
        let addedVocabIds = [];

        let tonightMidnight = new Date();
        tonightMidnight.setDate(tonightMidnight.getDate() + 1);
        tonightMidnight.setHours(0,0,0,0);
        let tonightTimestamp = firebase.firestore.Timestamp.fromDate(tonightMidnight); //tonight

        // Do limit * 5 in order to sort afterwards on client by number_of_answers (show least played)
        let queryFuture = projectFirestore.collection("users").doc(currentUser.uid).collection("private-data").doc("vocabulary").collection("languages").doc(targetLang).collection("items")
        .where("all_sources_deleted", "==", false)
        .where("spaced_repetition_finished", "==", false)
        .where("spaced_repetition_next_review", ">", tonightTimestamp)
        .orderBy("spaced_repetition_next_review", "desc")
        .limit(limit*5);

        let rFuture = await queryFuture.get();
        if (!rFuture.empty){
            let counter = 0;
            rFuture.docs.forEach(doc=>{
                let d = doc.data();
                d['doc_id'] = doc.ref.id;
                if (!addedVocabIds.includes(doc.data().id)){
                    addedVocabIds.push(doc.data().id);
                    docs.push(d);
                }
            });
        }

        // Now we will sort the documents
      
        let acceptedDocs = [];
        console.log("Future: ", docs);
        for (const doc of docs){
            if (doc.hasOwnProperty("total_statistics") && doc['total_statistics'].hasOwnProperty(['number_of_answers'])){
                acceptedDocs.push(doc);
            }
        }
        acceptedDocs = acceptedDocs.sort((a, b) => a.total_statistics.number_of_answers-b.total_statistics.number_of_answers);

        let filteredDocs = [];
        let numberOfCardsAdded = 0;
        for (const doc of acceptedDocs){
            if (numberOfCardsAdded < limit){
                filteredDocs.push(doc);
                numberOfCardsAdded++;
            }
        }

        return filteredDocs;
    }

    const addVocabItemsToListIfNotAlreadyAdded = (docs, addedVocabIds, newDocs) => {
        for (const doc of newDocs){
            if (!addedVocabIds.includes(doc.id)){
                docs.push(doc);
                addedVocabIds.push(doc.id);
            }
        }
    }

    const fetchMostUrgentNextReviewItemsFromSpacedRepetitionVocabularyApi = async ({targetLang, howManyItems}) => {
        let func = projectFunctions.httpsCallable('fetchMostUrgentNextReviewItemsFromSpacedRepetitionVocabulary');
        let results = await func({lang: targetLang, howManyItems: howManyItems});
        console.log("Spaced:", results);
        return results.data.cards;
    }

    const fetchMostUrgentNextReviewItemsFromSpacedRepetitionVocabulary = async (targetLang, howManyItems) => {
        /*
        LEGACY!!! Use API instead to ensure updated algorithm.
        */
        //let i = [0, 1, 2, 4, 8, 16, 32, 64, 128]
        //let perLevel = Math.ceil(limit/i.length);
        let docs = [];
        let addedVocabIds = [];
        let uid = currentUser.uid;

        const exitFunction = (limit, docs) => {
            let missingNumberOfCards = limit - docs.length;
            let exit = false;
            if (missingNumberOfCards <= 0){
                exit = true;
            }
            return {exit, missingNumberOfCards};
        }

        // OVERDUE ITEMS
        let overDueDocs = await fetchUnfinishedOverdueSpacedVocabulary(uid, targetLang);
        addVocabItemsToListIfNotAlreadyAdded(docs, addedVocabIds, overDueDocs);

        let {exit, missingNumberOfCards} = exitFunction(howManyItems, docs);
        if (exit){return docs.slice(0, howManyItems)}
        
        // DUE TODAY
        let dueTodayDocs = await fetchUnfinishedSpacedVocabularyDueToday(uid, targetLang);
        addVocabItemsToListIfNotAlreadyAdded(docs, addedVocabIds, dueTodayDocs);

        ({exit, missingNumberOfCards} = exitFunction(howManyItems, docs));
        if (exit){return docs.slice(0, howManyItems)}


        // UNSTARTED ITEMS
        console.log("Missing ", missingNumberOfCards, " cards after due today");
        let unstartedDocs = await fetchUnstartedSpacedVocabulary(uid, targetLang, missingNumberOfCards);
        addVocabItemsToListIfNotAlreadyAdded(docs, addedVocabIds, unstartedDocs);
       
        ({exit, missingNumberOfCards} = exitFunction(howManyItems, docs));
        if (exit){return docs.slice(0, howManyItems)}

        // FUTURE ITEMS
        console.log("Missing ", missingNumberOfCards, " cards after introducing unstarted vocab");
        let futureDocs = await fetchStartedSpacedVocabularyDueInFuture(uid, targetLang, missingNumberOfCards);
        addVocabItemsToListIfNotAlreadyAdded(docs, addedVocabIds, futureDocs);
        console.log("Future docs: ", futureDocs);

        return docs;
    }

    const fetchItemsBasedOnCurrentIndexFromSpacedRepetitionVocabulary = async (targetLang, howManyItems, index) => {
        let uid = currentUser.uid;
        let docs = [];
        let addedVocabIds = [];

        let query = projectFirestore.collection("users").doc(uid).collection("private-data").doc("vocabulary").collection("languages").doc(targetLang).collection("items")
        .where("all_sources_deleted", "==", false)
        .where("spaced_repetition_finished", "==", false)
        .where("spaced_repetition_current_index", "==", index)
        .orderBy("spaced_repetition_next_review", "asc")
        .limit(howManyItems);

        let r = await query.get();
        if (!r.empty){
            r.docs.forEach(doc=>{
                let d = doc.data();
                d['doc_id'] = doc.ref.id;
                if (!addedVocabIds.includes(doc.data().id)){
                    docs.push(d);
                    addedVocabIds.push(doc.data().id);
                }
            });
        }

        return docs;
    }

    const fetchHistoricalStatisticsItemsFromList = async ({docIds, game, language}) => {
        if (docIds === undefined || docIds === null || language === null || language === undefined){return false}
        let listOfGames = returnPossibleGames();
        if (!listOfGames.includes(game)){return false}
        if (docIds.length === 0){return false}

        let dataArray = await Promise.all(
            docIds.map(async (docId) => {
                let data = await fetchHistoricalStatisticsItem({docId: docId, game: game, language:language});
                return data;
            }
        ));
        console.log(dataArray)
        return dataArray;
    }

    const returnPossibleGames = () => {
        let listOfGames = ["flashcards", "quiz", "input","table-flashcards"];
        return listOfGames;
    }

    const fetchHistoricalStatisticsItem = async ({docId, game, language}) => {
        if (docId === undefined || docId === null || language === null || language === undefined){return false}
        let listOfGames = returnPossibleGames();
        if (!listOfGames.includes(game)){return false}

        let query = projectFirestore.collection("users").doc(currentUser.uid).collection("private-data").doc("statistics").collection("historic_data").doc("languages").collection(language).doc(game).collection("items").doc(docId);
        let r = await query.get();
        if (r.exists){
            return r.data();
        } else {
            return false;
        }
    }

    return {
        fetchTargetWordsDoc, 
        addTargetWordsToKnownVocabulary, 
        fetchKnownVocabulary,  
        fetchTargetWordFromVocabulary, 
        calculatePercentageOfVocabulary, 
        fetchMostUrgentNextReviewItemsFromSpacedRepetitionVocabulary, 
        fetchMostUrgentNextReviewItemsFromSpacedRepetitionVocabularyApi,
        fetchItemsBasedOnCurrentIndexFromSpacedRepetitionVocabulary, 
        fetchItemFromVocabularyInLanguage, 
        fetchHistoricalStatisticsItem, 
        fetchHistoricalStatisticsItemsFromList
    }
}