import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import YalangoHead from '../../assets/svg/yalango/YalangoHead.svg';
import {Link} from 'react-router-dom';
import { useAuth } from "../../contexts/AuthContext";
import useComments from '../../hooks/useComments';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Helmet} from 'react-helmet';
import CommentsFeedComponent from '../../components/comments/CommentsFeedComponent';
import MyLibraryMenu from '../../components/menus/MyLibraryMenu';
import ExploreMenu from '../../components/menus/ExploreMenu';

const CommentFeed = () => {
    let { language } = useParams();
    language = language.replace("-", " ");
    //  <MyLibraryMenu activeName="explore" />
    return (
        <>
            <Helmet>
                <title>Feed - Yalango</title>
                <meta name="description" content={"Explore the feed on Yalango and interact with other users."} />
            </Helmet>
            <div className="  flex flex-col gap-3">
                <div className="flex flex-col w-full gap-8">
                    <div className="p-4">
                        <CommentsFeedComponent language={language} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default CommentFeed;