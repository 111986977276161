import React from 'react';

const MyFlaschardsStatistics = () => {
  return (
    <>
        Stats.
    </>
  );
};

export default MyFlaschardsStatistics;
