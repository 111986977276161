import React, {useState, useEffect, useCallback} from 'react';
import Button from '../general/Button';
import Modal from '../general/Modal';
import {useNavigate} from 'react-router-dom';
import {motion} from 'framer-motion';
import SimpleErrorBar from '../general/SimpleErrorBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faNote} from '@fortawesome/pro-solid-svg-icons';
import {faFolderPlus} from '@fortawesome/pro-solid-svg-icons';
import {faBackwardStep} from '@fortawesome/pro-solid-svg-icons';
import {faCardsBlank} from '@fortawesome/pro-solid-svg-icons';
import {faTableColumns} from '@fortawesome/pro-solid-svg-icons';
import { faLoader } from '@fortawesome/pro-solid-svg-icons';
import { faThumbsDown } from '@fortawesome/pro-duotone-svg-icons';
import { faRotateRight } from '@fortawesome/pro-duotone-svg-icons';
import {AddDeckModalContent} from '../vocabulary/modals/AddDeckModal';
import Select from 'react-select';
import useTables from '../../hooks/useTables';
import useNotes from '../../hooks/useNotes';
import {useLanguagesContext} from '../../contexts/LanguagesContext';

const ModalContent = ({...props}) => {
    const {item, 
        closeFunction, 
        deleteFolder, 
        deleteDeck,
        deleteTable,
        deleteText,
        deleteNote, 
        deleteCourse} = props;

    const [loading, setLoading] = useState(false);
    const [securityInput, setSecurityInput] = useState("");
    const [securityCheckPassed, setSecurityCheckPassed] = useState(item.content_type === "folder" ? false : true);
    const [securityWord, setSecurityWord] = useState("delete");

    const [functions, setFunctions] = useState({
        'folder': deleteFolder, 
        'deck': deleteDeck, 
        'table': deleteTable,
        'note': deleteNote, 
        'text': deleteText, 
        'course': deleteCourse
    });

    const deleteClick = async () => {
        console.log(item.content_type);
        if ((item.content_type === "folder" && securityCheckPassed) || item.content_type !== "folder"){
            setLoading(true);
            await functions[item.content_type](item.doc_id);
            closeFunction();
            setLoading(false);
        }
    }

    const securityInputOnChange = (e) => {
        setSecurityInput(e.target.value);
        if (e.target.value === securityWord){
            setSecurityCheckPassed(true);
        } else {
            setSecurityCheckPassed(false);
        }
    }

    const checkIfEnter = (e) => {
        if (e.key === "Enter"){
            deleteClick();
        }
    }

    return (
        <>
            <div onKeyDown={checkIfEnter} className="h-full flex flex-col place-items-center gap-5 py-4">
                <h1>Delete {item?.content_type}</h1>
                {!loading ?
                    <>    
                        <div className="text-center text-sm flex flex-col gap-4">
                            {item.content_type === "folder" &&
                                <>
                                    <div>
                                        Deleting this folder ("<span className='font-bold'>{item?.text}</span>") is an irreversible operation and will delete all nested content inside. Proceed with care.
                                    </div>
                                    <div>
                                        For added security, please write <span className="font-bold">{securityWord}</span> in the input box below.
                                    </div>
                                    <div>
                                        <input autoFocus type="text" value={securityInput} onChange={(e)=>securityInputOnChange(e)} className="bg-gray-200 w-3/4 m-auto appearance-none border-2 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white" />
                                    </div>
                                </>
                            }
                            {item.content_type === "deck" &&
                                <>
                                    <div>
                                        Deleting this deck ("<span className='font-bold'>{item?.text}</span>") is an irreversible operation and will delete all cards added to it. Proceed with care.
                                    </div>
                                </>
                            }
                            {item.content_type === "table" &&
                                <>
                                    <div>
                                        Deleting this table ("<span className='font-bold'>{item?.text}</span>") is an irreversible operation. Proceed with care.
                                    </div>
                                </>
                            }
                            {item.content_type === "note" &&
                                <>
                                    <div>
                                        Deleting this note ("<span className='font-bold'>{item?.text}</span>") is an irreversible operation. Proceed with care.
                                    </div>
                                </>
                            }
                             {item.content_type === "text" &&
                                <>
                                    <div>
                                        Deleting this text ("<span className='font-bold'>{item?.text}</span>") is an irreversible operation. Proceed with care.
                                    </div>
                                </>
                            }
                             {item.content_type === "course" &&
                                <>
                                    <div>
                                        Deleting this course ("<span className='font-bold'>{item?.text}</span>") is an irreversible operation. Proceed with care.
                                    </div>
                                </>
                            }
                        </div>
                        <div className="flex flex-col-reverse sm:flex-row justify-center gap-1 sm:gap-3 ">
                            <Button key="cancel_button whitespace-nowrap" text="Cancel" color="charcoal" onClick={(e)=>closeFunction(e)} />
                            <Button disabledDesign={securityCheckPassed ? false : true} disabled={securityCheckPassed ? false : true} key="delete_button" text={"Delete " + item.content_type} color="red" onClick={(e)=>deleteClick(e)} />
                        </div>
                    </>
                :
                    <>
                        <div className="text-center text-4xl">
                            <FontAwesomeIcon icon={faLoader} className="fa-spin" />
                        </div>
                    </>
                }
            </div>
        </>   
    )
}

const DeleteItemModal = ({...props}) => {
    const {closeFunction} = props;
  return (
    <>
        <Modal bgColor="bg-gray-100" closeFunction={closeFunction} content={<ModalContent {...props} />} size="3xl"/>
    </>
  )
};

export default DeleteItemModal;
