import {projectFirestore, projectFunctions, timeStamp, firebase} from '../firebase/config.js';


export default function useTextToSpeech() {

    //projectFunctions.useEmulator("localhost", 5001); // local testing

    const textToSpeechWithGoogle = async (card, type) => {
        const func =  projectFunctions.httpsCallable('textToSpeechWithGoogle');
        const results = await func({'card': card, 'type': type});
        console.log("Results: ", results);
        return results['data'];
    }

    const getStandardForvoPronunciation = async (word, language) => {
        const f = projectFunctions.httpsCallable('getStandardForvoPronunciation');
        const results = await f({'word': word, 'language': language});
        return results['data'];
    }

    return {
        textToSpeechWithGoogle, 
        getStandardForvoPronunciation
    }
}

