import React from 'react'

const PremiumSuccess = () => {
  return (
    <>
        <div className=" ">
            <h1>Success</h1>
            <div className="text-center">
                Thank you for signing up for Yalango Premium! You are now ready to start using all the available premium features.
            </div>
        </div>
    </>
  )
}

export default PremiumSuccess;