import React, { useContext, useState, useEffect } from "react";
import useRatings from '../hooks/useRatings';
import {useAuth} from '../contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-duotone-svg-icons';

const SocialContext = React.createContext();

export function useSocialContext() {
    return useContext(SocialContext);
};

export function SocialProvider({ children }) {
    const {currentUser} = useAuth();
    const {fetchAllRatingsFromCurrentUserInRealtime, fetchAllRatingsFromCurrentUser} = useRatings();
    const [myRatings, setMyRatings] = useState(null);
    const [myRatingsFetched, setMyRatingsFetched] = useState(false);

    useEffect(()=> {
        let unsub = null;
        const fetchData = async () => {
            unsub = await fetchAllRatingsFromCurrentUserInRealtime(setMyRatings);
            setMyRatingsFetched(true);
        }

        if (currentUser !== null){
            fetchData();
        }
        return (()=>{
            unsub !== null && unsub();
        });
    },[currentUser]);

    const refreshMyRatings = async () => {
        let ratings = await fetchAllRatingsFromCurrentUser();
        setMyRatings(ratings);
        setMyRatingsFetched(true);
    }

    const value = {
        myRatings, 
        myRatingsFetched, 
        refreshMyRatings
    }

    return (
        <SocialContext.Provider value={value}>
            {children}
        </SocialContext.Provider>
    )
}