import React from 'react';
import YalangoSadSvg from '../../assets/svg/yalango/yalango_sad_without_circle.svg';
import Button from '../general/Button';
import {Link} from 'react-router-dom';
import { useAuth } from "../../contexts/AuthContext";

const DeckNotFound = () => {
    const {currentUser} = useAuth();
  return (
    <>
        <div className="h-screen flex flex-col justify-center">
        <div className="">
            <img src={YalangoSadSvg} alt="Yalango mascot sad" className="w-4/6 m-auto p-4 sm:w-64 mx-auto my-0" />
        </div>
        <div className=" flex flex-col justify-center place-items-center text-center gap-4">
            <div className="text-2xl text-gray-700 font-bold">
                Deck not found
            </div>
            <div>
                We couldn't find a deck at this URL. This either means that the deck doesn't exist, or that you're not authorized to access it.
            </div>
            <div>
                <Link to={currentUser !== null ? '/my-decks' : '/decks'}>
                    <Button color="green" text={currentUser !== null ? 'Go to my decks' : "Explore public decks"} />
                </Link>
            </div>
        </div>
        </div>
    </>
  )
}

export default DeckNotFound;