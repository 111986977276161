import firebase from 'firebase/compat/app';
import 'firebase/compat/storage'; // store images
import 'firebase/compat/firestore'; // database
import 'firebase/compat/auth'; // authentication
import 'firebase/compat/functions'; // functions
import 'firebase/compat/app-check'; // app check
import "firebase/compat/analytics";

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyATBFKrkgb3NSFpS9md3zlaT4yHZ0GLUt0",
    authDomain: "soothing-languages.firebaseapp.com",
    projectId: "soothing-languages",
    storageBucket: "soothing-languages.appspot.com",
    messagingSenderId: "799528087069",
    appId: "1:799528087069:web:dc29027d46b8f8b241e0f4",
    measurementId: "G-XSDK7GZZ2N" 
  };

  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);


  //const projectAppCheck = firebase.appCheck();
  const projectStorage = firebase.storage();
  const projectFirestore = firebase.firestore();
  const projectAuth = firebase.auth();
  const projectFunctions = firebase.functions();
  const projectAppCheck = firebase.appCheck();
  const projectAnalytics = firebase.analytics();
  const timeStamp = firebase.firestore.FieldValue.serverTimestamp();

  if (process.env.NODE_ENV === 'development') {
    projectFunctions.useEmulator("localhost", 5001); // local testing
    window.FIREBASE_APPCHECK_DEBUG_TOKEN=true;
  }

  //Request debug token for app check: window.FIREBASE_APPCHECK_DEBUG_TOKEN=true
  projectAppCheck.activate('6LduhJwdAAAAAGrP_9X5bVuijCLMpZx1rq7uU4T5', true);

  export { firebase, projectFunctions, projectStorage, projectFirestore, projectAuth, projectAnalytics, timeStamp };