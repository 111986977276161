import React, {useState} from 'react';
import Button from '../../general/Button';
import Modal from '../../general/Modal';
import Select from 'react-select';
import {useLanguagesContext} from '../../../contexts/LanguagesContext';
import useTables from '../../../hooks/useTables';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {motion} from 'framer-motion';
import {faLoader} from '@fortawesome/pro-solid-svg-icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const ModalContent = ({saving, loading, table, columns, columnsToDelete, closeFunction, setColumnsToDelete, deleteColumns}) => {
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const columnClick = (columnId) => {
        if (columnsToDelete.indexOf(columnId) !== -1){
            let copy = columnsToDelete.map((col)=>col);
            let index = columnsToDelete.indexOf(columnId);
            copy.splice(index, 1);
            setColumnsToDelete(copy);
        } else {
            setColumnsToDelete([...columnsToDelete, columnId]);
        }
    }

    return(
        <>
        <div className={(saving ? 'opacity-30 ' : '') + " "}>
            {loading ?
                <>
                    <div className="text-center text-4xl text-black">
                        <FontAwesomeIcon icon={faLoader} className="fa-spin" />
                    </div>
                </>
            :
                <>
                <h1>Delete columns</h1>
                <div className="text-center text-sm">Please select the columns you wish to delete.</div>
                <div className="h-full flex flex-col gap-4">
                    <div className="h-96 overflow-y-auto">
                        {columns.map((column, index)=>(
                            <>
                                <div onClick={()=>columnClick(column.id)} className={(columnsToDelete.indexOf(column.id) !== -1 ? 'bg-burnt-sienna ' : 'bg-charcoal ')+ " text-white font-bold cursor-pointer p-3 my-3 text-center rounded-lg shadow-lg bold-gray"}>
                                    {columnsToDelete.indexOf(column.id) !== -1 && <>Delete: </>} {column.name}
                                </div>   
                            </>
                        ))}
                                        
                    </div>
                    <div className="flex flex-row justify-center gap-3">
                        <div>
                            <Button onClick={closeFunction} color="charcoal" text="Cancel" />
                        </div>
                        <div>
                            <Button onClick={deleteColumns} color="red" text={"Delete " + columnsToDelete.length + (columnsToDelete.length === 1 ? " column" : " columns")} />
                        </div>
                    </div>
                </div>
                </>
            }
        </div>
        </>
    )
}

const DeleteTableColumnsModal = ({saving, loading, closeFunction, table, columns, columnsToDelete, setColumnsToDelete, deleteColumns}) => {

    return (
        <>
            <Modal  closeFunction={closeFunction} content={<ModalContent loading={loading} deleteColumns={deleteColumns} columnsToDelete={columnsToDelete} setColumnsToDelete={setColumnsToDelete} saving={saving} table={table} closeFunction={closeFunction} columns={columns} />} size="3xl" />
        </>
    )
}

export default DeleteTableColumnsModal;