import React, {useState, useEffect} from 'react';
import {useParams, Link} from "react-router-dom";
import {useLanguagesContext} from '../../../contexts/LanguagesContext';
import { motion } from "framer-motion";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faNote} from '@fortawesome/pro-duotone-svg-icons';
import {faBlender} from '@fortawesome/pro-duotone-svg-icons';
import {faBraille} from '@fortawesome/pro-duotone-svg-icons';
import {faBallotCheck} from '@fortawesome/pro-duotone-svg-icons';
import {faMagnifyingGlass} from '@fortawesome/pro-duotone-svg-icons';
import {faBookOpenReader} from '@fortawesome/pro-duotone-svg-icons';
import {faPenField} from '@fortawesome/pro-duotone-svg-icons';
import { useLocation } from 'react-router-dom';
import useDecks from '../../../hooks/useDecks';
import useFolders from '../../../hooks/useFolders';
import useQueryStrings from '../../../hooks/useQueryStrings';
import GeneralErrorPage from '../../../components/errors/GeneralErrorPage';
import Select from 'react-select';
import YalangoAI from '../../../assets/svg/yalango/YalangoAI.svg';

const DeckPlay = ({type, isPublic, folderMode}) => {
    let { id } = useParams();
    const {getQueryStrings, 
        addAndRemoveQueryStrings} = useQueryStrings();
    if (type === "deck"){
        id = parseInt(id);
    }
    const {allLanguages, globalSelectedTargetLanguage, activeUserLanguages} = useLanguagesContext();
    const [backToItemsLink, setBackToItemsLink] = useState(null); 
    const [flashCardsLink, setFlashcardsLink] = useState(null);
    const [mixLink, setMixLink] = useState(null);
    const [loading, setLoading] = useState(true);
    const [quizLink, setQuizLink] = useState(null);
    const [memoryLink, setMemoryLink] = useState(null);
    const [browseLink, setBrowseLink] = useState(null);
    const [inputLink, setInputLink] = useState(null);
    const [contextLink, setContextLink] = useState(null);
    const [writeHanziLink, setWriteHanziLink] = useState(null);
    const [animateHanziLink, setAnimateHanziLink] = useState(null);
    const [strokeOrderHanziLink, setStrokeOrderHanziLink] = useState(null);
    const [title, setTitle] = useState(null);
    const [subTitle, setSubTitle] = useState(null);
    const [deck, setDeck] = useState(null);
    const [folder, setFolder] = useState(null);
    const [error, setError] = useState(false);
    const location = useLocation();
    const [baseUrl, setBaseUrl] = useState(null);
    let [currentPath, setCurrentPath] = useState(null);
    const [playMode, setPlayMode] = useState(null);

    const [deckTargetLanguage, setDeckTargetLanguage] = useState(null);

    const {fetchDeckFromCurrentUser, 
        fetchPublicDeck} = useDecks();

    const {fetchFolder} = useFolders();

    const numberOfItemsSetting = [
    {'label': '10', 'value': 10}, 
    {'label': '25', 'value': 25}, 
    {'label': '50', 'value': 50},
    {'label': '75', 'value': 75}, 
    {'label': '100', 'value': 100}, 
    {'label': '150', 'value': 150}, 
    {'label': '200', 'value': 200}];

    const [playModeSetting, setPlayModeSetting] = useState(null);
    
    const [playModeSettingDict, setPlayModeSettingDict] = useState({
        'target':  {'label': 'Target → source', 'value': "target"},
        'source':  {'label': 'Source → target', 'value': "source"},
        'mix':  {'label': 'Mix', 'value': "mix"},
    });

    const [howManyItems, setHowManyItems] = useState(numberOfItemsSetting[0]);
    const exerciseDifficultyOptions = [{label: "Easy", value: "easy"}, {label: "Normal", value:"normal"}, {label: "Hard", value:"hard"}];
    const algorithmOptions = [{label: "Spaced repetition", value: "spaced-repetition"}, {label: "Custom", value:"custom"}];

    let exerciseDifficultyOptionsDict = {};
    exerciseDifficultyOptions.forEach((option)=>{
        exerciseDifficultyOptionsDict[option.value] = option;
    })

    let algorithmOptionsDict = {};
    algorithmOptions.forEach((option)=>{
        algorithmOptionsDict[option.value] = option;
    })
    const [exerciseDifficulty, setExerciseDifficulty] = useState(exerciseDifficultyOptions[1]);
    const [algorithm, setAlgorithm] = useState(algorithmOptions[0].value);

    const howManyItemsOnChange = (option) => {
        setHowManyItems(option);
    }

    const algorithmOnChange = (option) => {
        setAlgorithm(option.value);
    }

    const playModeOnChange = (option) => {
        setPlayMode(option.value);
    }

    useEffect(()=>{
        const fetchData = async () => {
            let r = await getQueryStrings();
            if ('items' in r){
                try {
                    numberOfItemsSetting.forEach((option)=>{
                        if (option.value === parseInt(r['items'])){
                            setHowManyItems({'label': r['items'], 'value': parseInt(r['items'])});
                        }
                    })
                } catch {
                    return null;
                }
            } 
            if ('level' in r){
                if (r['level'] in exerciseDifficultyOptionsDict){
                    setExerciseDifficulty(exerciseDifficultyOptionsDict[r['level']]);
                }
            } 
            if ('mode' in r){
                if (r['mode'] === 'target'){
                    setPlayMode('target');
                } else if (r['mode'] === "source"){
                    setPlayMode('source');
                } else if (r['mode'] === "mix") {
                    setPlayMode("mix");
                } else {
                    setPlayMode('mix');
                }
            } else {
                setPlayMode('mix');
            }
        }

        fetchData();
    },[]);

    useEffect(()=>{
        const fetchData = async () => {
            if (!isPublic){
                let r = await fetchDeckFromCurrentUser(id);
                console.log("deck: ", r)
                setDeck(r);
            } else {
                let r = await fetchPublicDeck(id);
                setDeck(r);
            }
        }
        const fetchFolderData = async () => {
            console.log("Fetching folders");
            let r = await fetchFolder(id);
            console.log("Found folder: ", r);
            setFolder(r);
            if (r === false || r === null || r === undefined){
                setError(true);
            }
        };
        if (id !== null){
            if (type === "deck"){
                fetchData();
            } else if (type === "folder"){
                fetchFolderData();
            }
        }
    },[id, type]);

    useEffect(()=>{
        if (type === "deck"){
            if (isPublic) {
                setBaseUrl("decks");
            } else {
                setBaseUrl("my-decks");
            }
        } else if (type === "category"){
            setBaseUrl("my-decks");
        } else if (type === "section"){
            setBaseUrl("my-decks");
        } else if (type === "vocabulary"){
            setBaseUrl("my-vocabulary");
        } else if (type === "folder"){
            setBaseUrl("my-folders");
        } else if (type === "all-decks"){
            setBaseUrl("my-decks/lang");
        }
    },[isPublic, type, globalSelectedTargetLanguage]);

    useEffect(()=> {
        let addList = [];
        if (howManyItems !== null){
            addList.push({'name': 'items', 'value': howManyItems.value});
        }
        if (exerciseDifficulty !== null && type === "vocabulary"){
            if (algorithm === "spaced-repetition"){
                addList.push({'name': 'level', 'value': 'spaced-repetition'});
            } else {
                addList.push({'name': 'level', 'value': exerciseDifficulty.value});
            }
        } 
        if (playMode === 'target' || playMode === "source" || playMode === "mix"){
            addList.push({'name': 'mode', 'value': playMode});
        }
        addAndRemoveQueryStrings(addList, []);
    },[howManyItems, exerciseDifficulty, type, playMode, algorithm]);

    useEffect(()=>{
        if (type === "deck"){
            if (allLanguages !== null && deck !== null){
                if ('target_ISO_639-1' in deck && 'source_ISO_639-1' in deck){
                    let target = deck['target_ISO_639-1'];
                    let source = deck['source_ISO_639-1'] !== "" ? deck['source_ISO_639-1'] : null;
                    let targetName = (allLanguages !== null && target !== null) ? allLanguages[0][target]['name']['en'] : target;
                    let sourceName = (allLanguages !== null && source !== null) ? allLanguages[0][source]['name']['en'] : "Source";
                    if (targetName === sourceName){
                        targetName = "Target";
                        sourceName = "Source";
                    }
                    setPlayModeSetting([{'label': targetName + ' → ' + sourceName, 'value': 'target'}, {'label': sourceName + ' → ' + targetName, 'value': 'source'}, {'label': "Mix", 'value': 'mix'}]);
                    setPlayModeSettingDict({'target': {'label': targetName + ' → ' + sourceName, 'value': 'target'}, 'source': {'label': sourceName + ' → ' + targetName, 'value': 'source'}, 'mix': {'label': "Mix", 'value': 'mix'}});
                } else {
                    setPlayModeSetting([{'label': 'Target → source', 'value': "target"}, {'label': 'Source → target', 'value': "source"}, {'label': "Mix", 'value': 'mix'}]);
                    setPlayModeSettingDict({'target': {'label': 'Target → source', 'value': "target"}, 'source': {'label': 'Source → target', 'value': "source"}, 'mix': {'label': "Mix", 'value': 'mix'}});
                }
            }
        } else if (type === "vocabulary"){
            if (activeUserLanguages !== null && globalSelectedTargetLanguage !== null){
                for (const language of activeUserLanguages){
                    if (language['target_language'] === globalSelectedTargetLanguage){
                        console.log(language);
                        if ('target_language' in language && 'source_language' in language){
                            let target = language['target_language'];
                            let source = language['source_language'];
                            let targetName = (allLanguages !== null && target !== null) ? allLanguages[0][target]['name']['en'] : target;
                            let sourceName = (allLanguages !== null && source !== null) ? allLanguages[0][source]['name']['en'] : source;
                            if (targetName === sourceName){
                                targetName = "Target";
                                sourceName = "Source";
                            }
                            setPlayModeSetting([{'label': targetName + ' → ' + sourceName, 'value': 'target'}, {'label': sourceName + ' → ' + targetName, 'value': 'source'}, {'label': "Mix", 'value': 'mix'}]);
                            setPlayModeSettingDict({'target': {'label': targetName + ' → ' + sourceName, 'value': 'target'}, 'source': {'label': sourceName + ' → ' + targetName, 'value': 'source'}, 'mix': {'label': "Mix", 'value': 'mix'}});
                        } else {
                            setPlayModeSetting([{'label': 'Target → source', 'value': "target"}, {'label': 'Source → target', 'value': "source"}, {'label': "Mix", 'value': 'mix'}]);
                            setPlayModeSettingDict({'target': {'label': 'Target → source', 'value': "target"}, 'source': {'label': 'Source → target', 'value': "source"}, 'mix': {'label': "Mix", 'value': 'mix'}});
                        }
                    }
                }
            }  
        } else if (type === "folder"){
            if (activeUserLanguages !== null && folder !== null){
                let found = false;
                for (const language of activeUserLanguages){
                    if (language['target_language'] === folder['target_ISO_639-1']){
                        found = true;
                        let target = folder['target_ISO_639-1'];
                        let source = language['source_language'];
                        let targetName = (allLanguages !== null && target !== null) ? allLanguages[0][target]['name']['en'] : target;
                        let sourceName = (allLanguages !== null && source !== null) ? allLanguages[0][source]['name']['en'] : source;
                        if (targetName === sourceName){
                            targetName = "Target";
                            sourceName = "Source";
                        }
                        setPlayModeSetting([{'label': targetName + ' → ' + sourceName, 'value': 'target'}, {'label': sourceName + ' → ' + targetName, 'value': 'source'}, {'label': "Mix", 'value': 'mix'}]);
                        setPlayModeSettingDict({'target': {'label': targetName + ' → ' + sourceName, 'value': 'target'}, 'source': {'label': sourceName + ' → ' + targetName, 'value': 'source'}, 'mix': {'label': "Mix", 'value': 'mix'}});
                    }
                }
                if (!found){
                    setPlayModeSetting([{'label': 'Target → source', 'value': "target"}, {'label': 'Source → target', 'value': "source"}, {'label': "Mix", 'value': 'mix'}]);
                    setPlayModeSettingDict({'target': {'label': 'Target → source', 'value': "target"}, 'source': {'label': 'Source → target', 'value': "source"}, 'mix': {'label': "Mix", 'value': 'mix'}});
                }
            }  
        } else if (type === "all-decks"){
            if (activeUserLanguages !== null && globalSelectedTargetLanguage !== null){
                for (const language of activeUserLanguages){
                    if (language['target_language'] === globalSelectedTargetLanguage){
                        if ('target_language' in language && 'source_language' in language){
                            let target = language['target_language'];
                            let source = language['source_language'];
                            let targetName = (allLanguages !== null && target !== null) ? allLanguages[0][target]['name']['en'] : target;
                            let sourceName = (allLanguages !== null && source !== null) ? allLanguages[0][source]['name']['en'] : source;
                            if (targetName === sourceName){
                                targetName = "Target";
                                sourceName = "Source";
                            }
                            setPlayModeSetting([{'label': targetName + ' → ' + sourceName, 'value': 'target'}, {'label': sourceName + ' → ' + targetName, 'value': 'source'}, {'label': "Mix", 'value': 'mix'}]);
                            setPlayModeSettingDict({'target': {'label': targetName + ' → ' + sourceName, 'value': 'target'}, 'source': {'label': sourceName + ' → ' + targetName, 'value': 'source'}, 'mix': {'label': "Mix", 'value': 'mix'}});
                        } else {
                            setPlayModeSetting([{'label': 'Target → source', 'value': "target"}, {'label': 'Source → target', 'value': "source"}, {'label': "Mix", 'value': 'mix'}]);
                            setPlayModeSettingDict({'target': {'label': 'Target → source', 'value': "target"}, 'source': {'label': 'Source → target', 'value': "source"}, 'mix': {'label': "Mix", 'value': 'mix'}});
                        }
                    }
                }
            }  
        }
    },[allLanguages, deck, type, folder, activeUserLanguages, globalSelectedTargetLanguage]);

    useEffect(()=>{
        if (baseUrl !== null){
            if (type === "deck"){
                if (deck !== null && 'name' in deck){
                    setTitle(deck.name);
                } else {
                    setTitle("Games");
                }
                if (deck !== null && deck.hasOwnProperty("target_ISO_639-1") && deck['target_ISO_639-1'] !== null){
                    setDeckTargetLanguage(deck['target_ISO_639-1']);
                }
                setBackToItemsLink("/"+baseUrl+"/"+id);
                setFlashcardsLink("/"+baseUrl+"/"+id+"/flashcards?items="+howManyItems.value+(playMode !== null ? '&mode='+playMode : ''));
                setMixLink("/"+baseUrl+"/"+id+"/mix?items="+howManyItems.value+(playMode !== null ? '&mode='+playMode : ''));
                setQuizLink("/"+baseUrl+"/"+id+"/quiz?items="+howManyItems.value+(playMode !== null ? '&mode='+playMode : ''));
                setMemoryLink("/"+baseUrl+"/"+id+"/memory?items="+howManyItems.value+(playMode !== null ? '&mode='+playMode : ''));
                setBrowseLink("/"+baseUrl+"/"+id+"/browse?items="+howManyItems.value+(playMode !== null ? '&mode='+playMode : ''));
                setInputLink("/"+baseUrl+"/"+id+"/input?items="+howManyItems.value+(playMode !== null ? '&mode='+playMode : ''));
                setContextLink("/"+baseUrl+"/"+id+"/context?items="+howManyItems.value+(playMode !== null ? '&mode='+playMode : ''));
                setWriteHanziLink("/"+baseUrl+"/"+id+"/write-hanzi?items="+howManyItems.value+(playMode !== null ? '&mode='+playMode : ''));
                setAnimateHanziLink("/"+baseUrl+"/"+id+"/animate-hanzi?items="+howManyItems.value+(playMode !== null ? '&mode='+playMode : ''));
                setStrokeOrderHanziLink("/"+baseUrl+"/"+id+"/stroke-order-hanzi?items="+howManyItems.value+(playMode !== null ? '&mode='+playMode : ''));
            } else if (type === "category"){
                setTitle("Games");
                setBackToItemsLink("/"+baseUrl+"/category/"+id);
                setFlashcardsLink("/"+baseUrl+"/category/"+id+"/flashcards?items="+howManyItems.value);
                setMixLink("/"+baseUrl+"/category/"+id+"/mix?items="+howManyItems.value);
                setQuizLink("/"+baseUrl+"/category/"+id+"/quiz?items="+howManyItems.value);
                setMemoryLink("/"+baseUrl+"/category/"+id+"/memory?items="+howManyItems.value);
                setBrowseLink("/"+baseUrl+"/category/"+id+"/browse?items="+howManyItems.value);
                setInputLink("/"+baseUrl+"/category/"+id+"/input?items="+howManyItems.value);
                setContextLink("/"+baseUrl+"/category/"+id+"/context?items="+howManyItems.value);;
            }
            else if (type === "section"){
                setTitle("Games");
                setBackToItemsLink("/"+baseUrl+"/section/"+id);
                setFlashcardsLink("/"+baseUrl+"/section/"+id+"/flashcards?items="+howManyItems.value);
                setQuizLink("/"+baseUrl+"/section/"+id+"/quiz?items="+howManyItems.value);
                setMemoryLink("/"+baseUrl+"/section/"+id+"/memory?items="+howManyItems.value);
                setBrowseLink("/"+baseUrl+"/section/"+id+"/browse?items="+howManyItems.value);
                setInputLink("/"+baseUrl+"/section/"+id+"/input?items="+howManyItems.value);
                setContextLink("/"+baseUrl+"/section/"+id+"/context?items="+howManyItems.value);
            }
            else if (type === "vocabulary"){
                setTitle("Practice your " + ((allLanguages !== null && globalSelectedTargetLanguage !== null) ? allLanguages[0][globalSelectedTargetLanguage]['name']['en'] : '') + " vocabulary");
                setBackToItemsLink("/"+baseUrl);
                setFlashcardsLink("/"+baseUrl+"/"+globalSelectedTargetLanguage+"/flashcards?items="+howManyItems.value+"&level="+(algorithm === "custom" ? exerciseDifficulty.value : "spaced-repetition")+(playMode !== null ? '&mode='+playMode : ''));
                setMixLink("/"+baseUrl+"/"+globalSelectedTargetLanguage+"/mix?items="+howManyItems.value+"&level="+(algorithm === "custom" ? exerciseDifficulty.value : "spaced-repetition")+(playMode !== null ? '&mode='+playMode : ''));
                setQuizLink("/"+baseUrl+"/"+globalSelectedTargetLanguage+"/quiz?items="+howManyItems.value+"&level="+(algorithm === "custom" ? exerciseDifficulty.value : "spaced-repetition")+(playMode !== null ? '&mode='+playMode : ''));
                setMemoryLink("/"+baseUrl+"/"+globalSelectedTargetLanguage+"/memory?items="+howManyItems.value+"&level="+(algorithm === "custom" ? exerciseDifficulty.value : "spaced-repetition")+(playMode !== null ? '&mode='+playMode : ''));
                setBrowseLink("/"+baseUrl+"/"+globalSelectedTargetLanguage+"/browse?items="+howManyItems.value+"&level="+(algorithm === "custom" ? exerciseDifficulty.value : "spaced-repetition")+(playMode !== null ? '&mode='+playMode : ''));
                setInputLink("/"+baseUrl+"/"+globalSelectedTargetLanguage+"/input?items="+howManyItems.value+"&level="+(algorithm === "custom" ? exerciseDifficulty.value : "spaced-repetition")+(playMode !== null ? '&mode='+playMode : ''));
                setContextLink("/"+baseUrl+"/"+globalSelectedTargetLanguage+"/context?items="+howManyItems.value+"&level="+(algorithm === "custom" ? exerciseDifficulty.value : "spaced-repetition")+(playMode !== null ? '&mode='+playMode : ''));
            }
            else if (type === "folder" && folderMode !== undefined && folder !== null && folder !== false){
                setTitle(folder.name);
                if (folderMode === "decks"){
                    setSubTitle("Play with all your decks in this folder.");
                    setMixLink("/"+baseUrl+"/"+id+"/decks/mix?items="+howManyItems.value+"&level="+exerciseDifficulty.value+(playMode !== null ? '&mode='+playMode : ''));
                    setFlashcardsLink("/"+baseUrl+"/" + id+"/decks/flashcards?items="+howManyItems.value+"&level="+exerciseDifficulty.value+(playMode !== null ? '&mode='+playMode : ''));
                    setQuizLink("/"+baseUrl+"/" + id+"/decks/quiz?items="+howManyItems.value+"&level="+exerciseDifficulty.value+(playMode !== null ? '&mode='+playMode : ''));
                    setMemoryLink("/"+baseUrl+"/" + id+"/decks/memory?items="+howManyItems.value+"&level="+exerciseDifficulty.value+(playMode !== null ? '&mode='+playMode : ''));
                    setBrowseLink("/"+baseUrl+"/" + id+"/decks/browse?items="+howManyItems.value+"&level="+exerciseDifficulty.value+(playMode !== null ? '&mode='+playMode : ''));
                    setInputLink("/"+baseUrl+"/" + id+"/decks/input?items="+howManyItems.value+"&level="+exerciseDifficulty.value+(playMode !== null ? '&mode='+playMode : ''));
                    setContextLink("/"+baseUrl+"/" + id+"/decks/context?items="+howManyItems.value+"&level="+exerciseDifficulty.value+(playMode !== null ? '&mode='+playMode : ''));
                } 
                else if (folderMode === "all"){
                    setSubTitle("Play with all items in this folder.");
                    setMixLink("/"+baseUrl+"/"+id+"/all/mix?items="+howManyItems.value+"&level="+exerciseDifficulty.value+(playMode !== null ? '&mode='+playMode : ''));
                    setFlashcardsLink("/"+baseUrl+"/" + id+"/all/flashcards?items="+howManyItems.value+"&level="+exerciseDifficulty.value+(playMode !== null ? '&mode='+playMode : ''));
                    setQuizLink("/"+baseUrl+"/" + id+"/all/quiz?items="+howManyItems.value+"&level="+exerciseDifficulty.value+(playMode !== null ? '&mode='+playMode : ''));
                    setMemoryLink("/"+baseUrl+"/" + id+"/all/memory?items="+howManyItems.value+"&level="+exerciseDifficulty.value+(playMode !== null ? '&mode='+playMode : ''));
                    setBrowseLink("/"+baseUrl+"/" + id+"/all/browse?items="+howManyItems.value+"&level="+exerciseDifficulty.value+(playMode !== null ? '&mode='+playMode : ''));
                    setInputLink("/"+baseUrl+"/" + id+"/all/input?items="+howManyItems.value+"&level="+exerciseDifficulty.value+(playMode !== null ? '&mode='+playMode : ''));
                    setContextLink("/"+baseUrl+"/" + id+"/all/context?items="+howManyItems.value+"&level="+exerciseDifficulty.value+(playMode !== null ? '&mode='+playMode : ''));
                }
                setBackToItemsLink("/"+baseUrl + "/" + id);
            }  else if (type === "all-decks"){
                setTitle("Practice all your " + ((allLanguages !== null && globalSelectedTargetLanguage !== null) ? allLanguages[0][globalSelectedTargetLanguage]['name']['en'] : '') + " decks");
                setBackToItemsLink("/"+baseUrl);
                setFlashcardsLink("/"+baseUrl+"/"+globalSelectedTargetLanguage+"/flashcards?items="+howManyItems.value+"&level="+exerciseDifficulty.value+(playMode !== null ? '&mode='+playMode : ''));
                setMixLink("/"+baseUrl+"/"+globalSelectedTargetLanguage+"/mix?items="+howManyItems.value+(playMode !== null ? '&mode='+playMode : ''));
                setQuizLink("/"+baseUrl+"/"+globalSelectedTargetLanguage+"/quiz?items="+howManyItems.value+"&level="+exerciseDifficulty.value+(playMode !== null ? '&mode='+playMode : ''));
                setMemoryLink("/"+baseUrl+"/"+globalSelectedTargetLanguage+"/memory?items="+howManyItems.value+"&level="+exerciseDifficulty.value+(playMode !== null ? '&mode='+playMode : ''));
                setBrowseLink("/"+baseUrl+"/"+globalSelectedTargetLanguage+"/browse?items="+howManyItems.value+"&level="+exerciseDifficulty.value+(playMode !== null ? '&mode='+playMode : ''));
                setInputLink("/"+baseUrl+"/"+globalSelectedTargetLanguage+"/input?items="+howManyItems.value+"&level="+exerciseDifficulty.value+(playMode !== null ? '&mode='+playMode : ''));
                setContextLink("/"+baseUrl+"/"+globalSelectedTargetLanguage+"/context?items="+howManyItems.value+"&level="+exerciseDifficulty.value+(playMode !== null ? '&mode='+playMode : ''));
            }
            setLoading(false);
        }
    },[baseUrl, algorithm, id, type, howManyItems, allLanguages, folder, folderMode, globalSelectedTargetLanguage, exerciseDifficulty, deck, playMode]);

    const exerciseDifficultyOnChange = (option) => {
        setExerciseDifficulty(option);
    }

    /*
    {type === "deck" &&
                                <motion.div style={{opacity:0.9}} whileHover={{scale:1.05, opacity:1}}>
                                    <Link to={contextLink !== null ? contextLink : baseUrl} className="no-underline">
                                        <div className="flex h-full  flex-col gap-2 justify-center place-items-center">
                                            <div className="text-gray-700 font-bold text-xl">
                                                Context
                                            </div>
                                            <div className="text-8xl sm:text-9xl text-charcoal">
                                                <FontAwesomeIcon className="" icon={faMagnifyingGlass} />
                                            </div>
                                        </div>
                                    </Link>
                                </motion.div>
                                }
    */

    return (
        <>
            <div className="pb-44">
                <div className="text-xs">
                    {backToItemsLink !== null &&
                        <Link to={backToItemsLink}>Back</Link>
                    }
                </div>
                {(!loading && !error) &&
                    <>
                        <div className="sm:w-5/6 w-full m-auto">
                            <div className="text-lg my-3 text-center font-bold text-gray-700">{title !== null && title}</div>
                            {subTitle !== null &&
                            <div className="text-base my-3 text-center text-gray-700">{subTitle}</div>
                            }
                            <div className="m-auto flex flex-col justify-center place-items-center gap-3">
                                <div className="flex flex-col gap-1">
                                    <div className="font-bold text-sm text-gray-600 text-center">
                                        Number of exercises
                                    </div>
                                    <div className="w-64 text-center shadow-lg">
                                        <Select value={howManyItems} onChange={howManyItemsOnChange} isSearchable={false} options={numberOfItemsSetting} />
                                    </div>
                                </div>
                                {((type === "deck" || type === "vocabulary" || type === "folder" || type === "all-decks") && (playModeSetting !== null && playModeSettingDict !== null)) &&
                                    <>
                                        <div className="flex flex-col gap-1">
                                        <div className="font-bold text-sm text-gray-600 text-center">
                                            Language direction for the exercises
                                        </div>
                                        <div className="w-64 text-center shadow-lg">
                                            <Select value={playModeSettingDict[playMode]} onChange={playModeOnChange} isSearchable={false} options={playModeSetting} />
                                        </div>
                                        </div>
                                    </>
                                }
                                {type === "vocabulary" &&
                                    <>
                                        <div className="flex flex-col gap-1">
                                            <div className="font-bold text-sm text-gray-600 text-center">
                                                Algorithm
                                            </div>
                                            <div className="w-64 text-center shadow-lg">
                                                <Select value={algorithmOptionsDict[algorithm]} onChange={algorithmOnChange} isSearchable={false} options={algorithmOptions} />
                                            </div>
                                        </div>
                                        {algorithm === "custom" &&
                                        <div className="flex flex-col gap-1">
                                            <div className="font-bold text-sm text-gray-600 text-center">
                                                How difficult should it be?
                                            </div>
                                            <div className="w-64 text-center shadow-lg">
                                                <Select value={exerciseDifficulty} onChange={exerciseDifficultyOnChange} isSearchable={false} options={exerciseDifficultyOptions} />
                                            </div>
                                        </div>
                                        }
                                    </>
                                }
                            </div>
                            <div className="w-full mx-auto grid grid-cols-2 sm:grid-cols-3">
                                <motion.div style={{opacity:0.9}} whileHover={{scale:1.05, opacity:1}} className="">
                                    <Link to={mixLink !== null ? mixLink : baseUrl} className="no-underline">
                                        <div className="flex h-full  flex-col gap-2 justify-center place-items-center">
                                            <div className="text-gray-700 font-bold text-xl">
                                                Mix
                                            </div>
                                            <div className="text-8xl sm:text-9xl text-purple-500">
                                                <FontAwesomeIcon className="" icon={faBlender} />
                                            </div>
                                        </div>
                                    </Link>
                                </motion.div>
                                <motion.div style={{opacity:0.9}} whileHover={{scale:1.05, opacity:1}} className="">
                                    <Link to={flashCardsLink !== null ? flashCardsLink : baseUrl} className="no-underline">
                                        <div className="flex h-full  flex-col gap-2 justify-center place-items-center">
                                            <div className="text-gray-700 font-bold text-xl">
                                                Flashcards
                                            </div>
                                            <div className="text-8xl sm:text-9xl text-sandy-brown">
                                                <FontAwesomeIcon className="" icon={faNote} />
                                            </div>
                                        </div>
                                    </Link>
                                </motion.div>
                                <motion.div style={{opacity:0.9}} whileHover={{scale:1.05, opacity:1}}>
                                    <Link to={ quizLink !== null ? quizLink : baseUrl} className="no-underline">
                                        <div className="flex h-full  flex-col gap-2 justify-center place-items-center">
                                            <div className="text-gray-700 font-bold text-xl">
                                                Quiz
                                            </div>
                                            <div className="text-8xl sm:text-9xl text-persian-green">
                                                <FontAwesomeIcon icon={faBallotCheck} />
                                            </div>
                                        </div>
                                    </Link>
                                </motion.div>
                                <motion.div style={{opacity:0.9}} whileHover={{scale:1.05, opacity:1}}>
                                    <Link to={inputLink !== null ? inputLink : baseUrl} className="no-underline">
                                        <div className="flex h-full  flex-col gap-2 justify-center place-items-center">
                                            <div className="text-gray-700 font-bold text-xl">
                                                Input
                                            </div>
                                            <div className="text-8xl sm:text-9xl text-red-500">
                                                <FontAwesomeIcon className="" icon={faPenField} />
                                            </div>
                                        </div>
                                    </Link>
                                </motion.div>
                            
                                <motion.div style={{opacity:0.9}} whileHover={{scale:1.05, opacity:1}}>
                                    <Link to={memoryLink !== null ? memoryLink : baseUrl} className="no-underline">
                                        <div className="flex h-full  flex-col gap-2 justify-center place-items-center">
                                            <div className="text-gray-700 font-bold text-xl">
                                                Memory
                                            </div>
                                            <div className="text-8xl sm:text-9xl text-beautiful-blue">
                                                <FontAwesomeIcon className="" icon={faBraille} />
                                            </div>
                                        </div>
                                    </Link>
                                </motion.div>
                                {type === "deck" &&
                                <motion.div style={{opacity:0.9}} whileHover={{scale:1.05, opacity:1}}>
                                    <Link to={contextLink !== null ? contextLink : baseUrl} className="no-underline">
                                        <div className="flex h-full  flex-col gap-2 justify-center place-items-center">
                                            <div className="text-gray-700 font-bold text-xl">
                                                Context AI
                                            </div>
                                            <div className="text-8xl sm:text-9xl text-charcoal">
                                                <img src={YalangoAI} className="w-40 mx-auto h-full" alt="profile" />
                                            </div>
                                        </div>
                                    </Link>
                                </motion.div>
                                }
                                <motion.div style={{opacity:0.9}} whileHover={{scale:1.05, opacity:1}} className="">
                                    <Link to={browseLink !== null ? browseLink : baseUrl} className="no-underline">
                                        <div className="flex h-full  flex-col gap-2 justify-center place-items-center">
                                            <div className="text-gray-700 font-bold text-xl">
                                                Browse
                                            </div>
                                            <div className="text-8xl sm:text-9xl text-burnt-sienna">
                                                <FontAwesomeIcon className="" icon={faBookOpenReader} />
                                            </div>
                                        </div>
                                    </Link>
                                </motion.div>
                               
                            </div>
                            <div className="mt-16">
                                <div className="text-2xl text-center font-bold text-gray-700">Language specific games</div>
                            </div>
                            <h2 className="mt-16">Practice Chinese characters</h2>
                            <div className="grid grid-cols-2 sm:grid-cols-3">
                            {type === "deck" ?
                                    <>
                                        <motion.div style={{opacity:0.9}} whileHover={{scale:1.05, opacity:1}} className="">
                                            {deckTargetLanguage === "zh" ?
                                                <Link to={animateHanziLink !== null ? animateHanziLink : baseUrl} className="no-underline">
                                                    <div className="flex h-full  flex-col gap-2 justify-center place-items-center">
                                                        <div className="text-gray-700 font-bold text-xl">
                                                            Level 1 - Easy
                                                        </div>
                                                        <div className="text-6xl sm:text-9xl text-persian-green">
                                                            容易
                                                        </div>
                                                    </div>
                                                </Link>
                                                :
                                                <>
                                                    <div className=" cursor-not-allowed opacity-60 flex h-full  flex-col gap-2 justify-center place-items-center">
                                                        <div className="text-gray-700 font-bold text-xl">
                                                            Level 1 - Easy
                                                        </div>
                                                        <div className="text-center text-sm">
                                                            This game is only for decks with Mandarin Chinese as the target language.
                                                        </div>
                                                        <div className="text-6xl sm:text-9xl text-charcoal">
                                                            容易
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </motion.div>
                                        <motion.div style={{opacity:0.9}} whileHover={{scale:1.05, opacity:1}} className="">
                                            {deckTargetLanguage === "zh" ?
                                                <Link to={strokeOrderHanziLink !== null ? strokeOrderHanziLink : baseUrl} className="no-underline">
                                                    <div className="flex h-full  flex-col gap-2 justify-center place-items-center">
                                                        <div className="text-gray-700 font-bold text-xl">
                                                            Level 2 - Medium
                                                        </div>
                                                        <div className="text-6xl sm:text-9xl text-sandy-brown">
                                                            中等
                                                        </div>
                                                    </div>
                                                </Link>
                                                :
                                                <>
                                                    <div className=" cursor-not-allowed opacity-60 flex h-full  flex-col gap-2 justify-center place-items-center">
                                                        <div className="text-gray-700 font-bold text-xl">
                                                            Level 2 - Medium
                                                        </div>
                                                        <div className="text-center text-sm">
                                                            This game is only for decks with Mandarin Chinese as the target language.
                                                        </div>
                                                        <div className="text-6xl sm:text-9xl text-sandy-brown">
                                                            中等
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </motion.div>
                                        <motion.div style={{opacity:0.9}} whileHover={{scale:1.05, opacity:1}} className="">
                                            {deckTargetLanguage === "zh" ?
                                                <Link to={writeHanziLink !== null ? writeHanziLink : baseUrl} className="no-underline">
                                                    <div className="flex h-full  flex-col gap-2 justify-center place-items-center">
                                                        <div className="text-gray-700 font-bold text-xl">
                                                            Level 3 - Hard
                                                        </div>
                                                        <div className="text-6xl sm:text-9xl text-burnt-sienna">
                                                            困难
                                                        </div>
                                                    </div>
                                                </Link>
                                                :
                                                <>
                                                    <div className=" cursor-not-allowed opacity-60 flex h-full  flex-col gap-2 justify-center place-items-center">
                                                        <div className="text-gray-700 font-bold text-xl">
                                                            Level 3 - Hard
                                                        </div>
                                                        <div className="text-center text-sm">
                                                            This game is only for decks with Mandarin Chinese as the target language.
                                                        </div>
                                                        <div className="text-6xl sm:text-9xl text-burnt-sienna">
                                                            困难
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </motion.div>

                                        
                                    </>
                                :
                                <>
                                    <div>
                                        Coming soon...
                                    </div>
                                </>
                                }
                            </div>
                        </div>
                    </>
                }
                {(!loading && error) &&
                    <>
                        <div className="h-screen overflow-hidden">
                        <GeneralErrorPage errorObject={{"headline": "Folder not found", 'message': "We couldn't find a folder at this URL. This either means that the folder doesn't exist, or that you're not authorized to access it."}} goBackLink={"/dashboard"} goBackText={"Go to dashboard"} />
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default DeckPlay;
