import React from 'react';
import Modal from '../../general/Modal';
import Button from '../../general/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';

const ModalContent = ({table, loading, closeFunction, deleteTableClick}) => {
    return (
        <>
            {!loading ?
                <>
                    <div className="bg-burnt-sienna text-center p-4 rounded-lg shadow-lg">
                        <div className="text-xl text-white font-bold">Are you sure you want to continue?</div>
                    </div>
                    <div className="text-base text-center md:w-3/4 m-auto text-gray-700 px-4 py-4">By clicking on the delete button below, you confirm that you want to delete your table entitled <span className="font-bold">{table.name}</span>. This operation is irreversible.</div>
                    <div className="text-sm text-center px-4">This table with ID {table.id} contains {table.row_order.length} rows and {table.column_order.length} columns.</div>
                    <div className="flex flex-row gap-3 justify-center my-2">
                        <div>
                            <Button color="charcoal" text="Cancel" onClick={closeFunction} />
                        </div>
                        <div>
                            <Button color="red" text="Delete table" onClick={deleteTableClick} />
                        </div>
                    </div>
                </>
            : 
                <>
                    <div className=" text-3xl">
                        <FontAwesomeIcon icon={faSpinner} className="fa-pulse" />
                    </div>
                </>
            }
        </>
    )
}

const DeleteTableModal = ({loading, table, closeFunction, deleteTableClick}) => {
  return (
    <>
        <Modal closeFunction={closeFunction} content={<ModalContent loading={loading} closeFunction={closeFunction} deleteTableClick={deleteTableClick} table={table} />} />
    </>
  )
}

export default DeleteTableModal;