import React, {useEffect, useState} from 'react';
import Modal from '../../general/Modal';
import Button from '../../general/Button';
import useTables from '../../../hooks/useTables';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {Link} from 'react-router-dom';
import {motion} from 'framer-motion';
import {useMyContentContext} from '../../../contexts/MyContentContext';

const ModalContent = ({saveChangesFunction, setSelectedTable, targetLanguage, closeFunction}) => {
  const {getTablesFromCurrentUser} = useTables();
  const [loading, setLoading] = useState(false);
  const [tempSelectedTable, setTempSelectedTable] = useState(null);

  let {
    tables
} = useMyContentContext();

  const isSelectedTable = (id) => {
    if (tempSelectedTable !== null && tempSelectedTable.id === id){
      return true; 
    } else {
      return false;
    }
  }

  const selectFinalTableClick = async () => {
    if (tempSelectedTable !== null){
      console.log("Adding table ", tempSelectedTable, "...");
      setSelectedTable(tempSelectedTable);
      await saveChangesFunction(tempSelectedTable);
      closeFunction();
    }
  }
  
  return (
    <>
      <div className="">
        <h1>Select a table</h1>
        {loading &&
            <>
                <div className="h-full p-4 flex flex-col justify-center overflow-hidden text-center text-3xl">
                    <FontAwesomeIcon icon={faSpinner} className="fa-pulse" />
                </div>
            </>
        }
        
        {(!loading && tables !== null) &&
          <>
            <div className="p-4">
            {tables.length === 0 ?
              <>
                <div className="text-center">
                  You don't have any tables in this language yet. Get started by <Link to="/my-tables">creating one now</Link>. 
                </div>
              </>
              :
              <>
                <div className="">
                {tables.map((table, index)=>(
                  <>
                    {table['target_ISO_639-1'] === targetLanguage &&
                      <motion.div onClick={()=>setTempSelectedTable(isSelectedTable(table.id) ? null : table)} whileHover={{scale:1.01}} className={(isSelectedTable(table.id) ? 'bg-charcoal text-white ' :' bg-white text-gray-600 ' ) +" mb-4 cursor-pointer p-3 rounded-lg shadow-xl"} key={"table_"+index}>
                        <span className="font-bold ">{table.name} </span> ({table.column_order.length} columns)
                      </motion.div>
                    }
                  </>
                ))}
                </div>
                <div className="flex flex-row justify-center mt-6">
                  <Button text="Add table" onClick={()=>selectFinalTableClick()} disabledDesign={tempSelectedTable === null} disabled={tempSelectedTable === null} color={tempSelectedTable !== null ? "green" : "bg-gray-500"} />
                </div>
              </>
            }
            </div>
          </>
        }
      </div>
    </>
  )
}

const TableSelectorModal = ({saveChangesFunction, setSelectedTable, targetLanguage, closeFunction}) => {

  return (
    <>
      <Modal bgColor="bg-gray-100" size="2xl" closeFunction={closeFunction} content={<ModalContent saveChangesFunction={saveChangesFunction} closeFunction={closeFunction} targetLanguage={targetLanguage} setSelectedTable={setSelectedTable} />} />
    </>
  )
}

export default TableSelectorModal;