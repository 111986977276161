import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import { useAuth } from "../../../../contexts/AuthContext";
import useDecks from '../../../../hooks/useDecks';
import useStatistics from '../../../../hooks/useStatistics';
import useQueryStrings from '../../../../hooks/useQueryStrings';
import {Link} from 'react-router-dom';
import {faVolumeUp} from '@fortawesome/pro-duotone-svg-icons';
import Icon from '../../../../components/general/Icon';
import Button from '../../../../components/general/Button';
import ScoreScreen from '../../../../components/vocabulary/games/common-assets/ScoreScreen';
import CountryFlag from '../../../../components/languages/CountryFlag';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import LoadingGame from '../common-assets/LoadingGame';
import {faStars} from '@fortawesome/pro-duotone-svg-icons';
import { faX } from '@fortawesome/pro-solid-svg-icons';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { faQuestion } from '@fortawesome/pro-solid-svg-icons';
import {useLanguagesContext} from '../../../../contexts/LanguagesContext';
import {motion} from 'framer-motion';
import {useUserStatisticsContext} from '../../../../contexts/UserStatisticsContext';
import YalangoHappySvg from '../../../../assets/svg/yalango/yalango_thumbs_up_without_circle.svg';
import InputHelpModal from './modals/InputHelpModal';
import RenderWord from '../common-assets/RenderWord';

const InputGame = ({reviewFinished, setReviewFinished, isPremium, currentCardIndex, setCurrentCardIndex, nbOfAnswers, setNbOfAnswers, cardsLeft, setCardsLeft, gameScreen, score, setScore, setGameScreen, mistakes, setMistakes, audioLoading, autoPlayPronunciations, globalHandleSkipAnswer, globalHandleCorrectAnswer, globalHandleWrongAnswer,  isInMix, googleTextToSpeechClick, playMode, setPlayMode, showLoadingScreen, setShowLoadingScreen, cards, deck, decks, loadingItems, refreshData, howManyItems, backToPlayPageLink, playModeArray, id, type}) => {
    let {allLanguages} = useLanguagesContext();
    let {addInputStatForCurrentUser} = useStatistics();
    const {activateDailyStatsNeedRefresh, activateDailyStreakNeedsRefresh} = useUserStatisticsContext();
    let { currentUser } = useAuth();
    const [nbOfItems, setNbOfItems] = useState(0);
    const [answerSubmitted, setAnswerSubmitted] = useState(false);
    const [correct, setCorrect] = useState(null);
    const [gameFinished, setGameFinished] = useState(false);
    const [error, setError] = useState(false);
    const [collectData, setCollectData] = useState(null);
    const location = useLocation();

    const [inputText, setInputText] = useState("");

    useEffect(()=>{
        if (currentUser !== null){
            setCollectData(true);
        } else {
            setCollectData(false);
        }
    },[currentUser]);

    useEffect(()=>{
        if (autoPlayPronunciations === true && currentCardIndex !== null && cards !== null && !audioLoading && playMode === "target_first"){
            googleTextToSpeechClick(cards[currentCardIndex]);
        }
    },[autoPlayPronunciations, currentCardIndex, cards, playMode]);


    useEffect(()=>{
        if (isInMix && cards !== null){
            resetValues(); //reset between two quizzes after each other in mix mode
        }   
    },[cards, isInMix]);

    useEffect(()=>{
        if (cards !== null && !isInMix){
            setCardsLeft(cards.length);
        }
    },[cards, isInMix])

    const handleInputChange = (e) => {
        error && setError(false);
        setInputText(e.target.value);
    }

    const correctAnswer = () => {
        setCorrect(true);
        if (!isInMix){
            setScore(score+1);
        }
        if (collectData){
            addInputStatForCurrentUser(makeStatObject({correct:true, selfCorrection: false}));
        }
    }

    const wrongAnswer = () => {
        setCorrect(false);
        /* if (collectData){
            addInputStatForCurrentUser(makeStatObject({correct:false, selfCorrection: false}));
        } */
    }

    const incrementItemIndex = () => {
        if (!isInMix){
            if (currentCardIndex < cards.length-1){
                setCurrentCardIndex(currentCardIndex + 1);
            } else {
                setGameFinished(true);
                setGameScreen("endscreen");
            }
        }
    }

    const resetValues = () => {
        setAnswerSubmitted(false);
        setInputText("");
        setCorrect(null);
    }

    const handleSubmit = () => { 
        if (inputText.trim() === ""){
            setError(true);
            return;
        }
        let correct_answer = null;
        let currentMode = null;
        if (playMode === "target_first"){
            currentMode = playMode;
        } else if (playMode === "source_first"){
            currentMode = playMode;
        } else if (playMode === "mix") {
            currentMode = playModeArray[currentCardIndex];
        }
        if (currentMode === "target_first"){
            correct_answer = cards[currentCardIndex].source.trim().toLowerCase();
        } else if (currentMode === "source_first") {
            correct_answer = cards[currentCardIndex].target.trim().toLowerCase()
        }
        if (inputText.trim().toLowerCase() === correct_answer) {
            correctAnswer();
            setAnswerSubmitted(true);
            setTimeout(()=>{
                if (!isInMix){
                    incrementItemIndex();
                    setCardsLeft(cardsLeft-1);
                }
                else {
                    globalHandleCorrectAnswer();
                }
            }, 1500); 
        } else {
            wrongAnswer();
            setAnswerSubmitted(true);
        }
        if (!isInMix){
            setNbOfAnswers(nbOfAnswers+1);
        }
    }

    const wrongItemNextQuestion = () => {
        if (collectData){
            addInputStatForCurrentUser(makeStatObject({correct:false, selfCorrection: false}));
            setMistakes([...mistakes, cards[currentCardIndex]]);
        } 
        if (!isInMix){
            incrementItemIndex();
            setCardsLeft(cardsLeft-1);
        }
        else {
            globalHandleWrongAnswer();
        }
    }

    useEffect(()=>{
        resetValues();
    },[currentCardIndex]);


    const skipItemClick = () => {
        if (!isInMix){
            incrementItemIndex();
            setCardsLeft(cardsLeft-1);
        }
        else {
            globalHandleSkipAnswer();
        }
    }

    const wrongItemButIWasCorrectClick = () => {
        if (collectData){
            addInputStatForCurrentUser(makeStatObject({correct:true, selfCorrection: true}));
        } 
        if (!isInMix){
            setScore(score+1);
            incrementItemIndex();
            setCardsLeft(cardsLeft-1);
        }
        else {
            globalHandleCorrectAnswer();
        }
    }

    const playAgainClick = async () => {
        await refreshData();
        setCardsLeft(cards.length);
        setCurrentCardIndex(0);
        resetValues();
        setScore(0);
        setGameFinished(false);
        setGameScreen("game");
    }

    const makeStatObject = ({correct, selfCorrection}) => {
        let obj = {
            'correct': correct,
            'submitted_answer': inputText,
            'correct_answer': cards[currentCardIndex].source,
            'doc_id': cards[currentCardIndex].doc_id,
            'deck_id': cards[currentCardIndex].deck_id,
            'deck_uid': 'deck_uid' in cards[currentCardIndex] ? cards[currentCardIndex].deck_uid : null, 
            'data_type': type,
            'view_mode': playMode !== "mix" ? playMode : playModeArray[currentCardIndex],
            'target_word': cards[currentCardIndex].target,
            'source_word': cards[currentCardIndex].source, 
            'target_ISO_639-1': cards[currentCardIndex]['target_ISO_639-1'],
            'source_ISO_639-1': cards[currentCardIndex]['source_ISO_639-1'], 
            'google_tts': cards[currentCardIndex].hasOwnProperty('google_tts') ? cards[currentCardIndex]['google_tts'] : null,
            'google_tts_target': cards[currentCardIndex].hasOwnProperty('google_tts_target') ? cards[currentCardIndex]['google_tts_target'] : null,
            'self_correction': selfCorrection, 
            'game': isInMix ? 'mix' : 'input'
        }
        activateDailyStatsNeedRefresh();
        activateDailyStreakNeedsRefresh();
        return obj;
    }

    document.body.onkeyup = function(e){
        if(e.key === "Enter"){
            if (!answerSubmitted){
                handleSubmit();
            } else {
                if (correct === false){
                    wrongItemNextQuestion();
                }
            }
        }
    }

    const [showHelpModal, setShowHelpModal] = useState(false);

    const helpClick = () => {
        setShowHelpModal(true);
    }

    const closeHelpModal = () => {
        setShowHelpModal(false);
    }

    /*
                {(!loadingItems && !showLoadingScreen && !gameFinished && !isInMix) &&
                <div className="flex flex-row justify-center gap-6">
                    <div>
                        <span className="text-gray-600 font-bold">Score</span>: {score}
                    </div>
                    <div>
                        <span className="text-gray-600 font-bold">Items left</span>: {cardsLeft}
                    </div>
                </div>
            }
    */

    return (
        <>

                {(!loadingItems && allLanguages !== null && !showLoadingScreen) &&
                    <>
                        <div className="flex flex-col gap-6 h-full justify-center place-items-center">
                            {(!answerSubmitted && !gameFinished) &&
                                <>
                                    <div className="flex w-full flex-row text-3xl sm:text-5xl justify-start gap-10 place-items-center">
                                        <div className="rounded-lg shadow-lg overflow-hidden">
                                            {playMode === "source_first" || (playMode === "mix" && playModeArray[currentCardIndex] === "source_first") ?
                                                <>
                                                    {(cards[currentCardIndex].hasOwnProperty("source_ISO_639-1") && cards[currentCardIndex]['source_ISO_639-1'] !== "") &&
                                                        <CountryFlag animation={false} size="1em" countryCode={allLanguages[0][cards[currentCardIndex]['source_ISO_639-1']]['country_flag']} flagName={allLanguages[0][cards[currentCardIndex]['source_ISO_639-1']]['name']['en']} />
                                                    }
                                                </>
                                            : 
                                                <>
                                                    {(cards[currentCardIndex].hasOwnProperty("target_ISO_639-1") && cards[currentCardIndex]['target_ISO_639-1'] !== "") &&
                                                        <CountryFlag animation={false} size="1em" countryCode={allLanguages[0][cards[currentCardIndex]['target_ISO_639-1']]['country_flag']} flagName={allLanguages[0][cards[currentCardIndex]['target_ISO_639-1']]['name']['en']} />
                                                    }
                                                </>
                                            }
                                            
                                        </div>
                                        <div className="">
                                            {playMode === "source_first" || (playMode === "mix" && playModeArray[currentCardIndex] === "source_first") ?
                                                <>
                                                    {cards[currentCardIndex].source}
                                                </>
                                            : 
                                                <>
                                                    <div className="flex flex-row justify-center place-items-center gap-3">
                                                        <div className="text-lg sm:text-2xl">
                                                            {!audioLoading ?  
                                                                <>
                                                                    {(cards[currentCardIndex].hasOwnProperty("google_tts") && cards[currentCardIndex]['google_tts'] !== null) &&
                                                                        <div onClick={(e)=>googleTextToSpeechClick(cards[currentCardIndex])} className="">
                                                                            <Icon color="charcoal" icon={<FontAwesomeIcon icon={faVolumeUp} />} />
                                                                        </div>
                                                                    }
                                                                </>
                                                            :
                                                                <div className="text-xl sm:text-3xl">
                                                                    <Icon color="charcoal" className="fa-beat" icon={<FontAwesomeIcon icon={faVolumeUp} />} />
                                                                </div>
                                                            }
                                                        </div>
                                                        <div>
                                                            <RenderWord targetSize={"3xl"} pinyinSize={"lg"} text={cards[currentCardIndex].target} language={cards[currentCardIndex]['target_ISO_639-1']} data={cards[currentCardIndex]} targetOrSource="target" />  
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        
                                    </div>
                                </>
                            }
                            {gameFinished && 
                                <>
                                    <ScoreScreen score={score} nbOfAnswers={cards.length} backToPlayPageLink={backToPlayPageLink} playAgainClick={playAgainClick} />
                                 </>
                            }
                            {!gameFinished &&
                                <>
                            <div className="  text-center">
                                {!answerSubmitted ?
                                    <>
                                        <div className="flex  flex-col gap-6">
                                            <div className="flex  flex-row justify-start gap-10 place-items-center">
                                                <div className="text-3xl sm:text-5xl rounded-lg shadow-lg overflow-hidden">
                                                    {('source_ISO_639-1' in cards[currentCardIndex] && 'target_ISO_639-1' in cards[currentCardIndex] && cards[currentCardIndex]['source_ISO_639-1'] !== "" && cards[currentCardIndex]['target_ISO_639-1'] !== "") &&
                                                        <>
                                                            {playMode === "source_first" || (playMode === "mix" && playModeArray[currentCardIndex] === "source_first") ?
                                                                <>
                                                                     {(cards[currentCardIndex].hasOwnProperty("target_ISO_639-1") && cards[currentCardIndex]['target_ISO_639-1'] !== "") &&
                                                                        <CountryFlag animation={false} size="1em" countryCode={allLanguages[0][cards[currentCardIndex]['target_ISO_639-1']]['country_flag']} flagName={allLanguages[0][cards[currentCardIndex]['target_ISO_639-1']]['name']['en']} />
                                                                    }
                                                                </>
                                                            : 
                                                                <>
                                                                     {(cards[currentCardIndex].hasOwnProperty("source_ISO_639-1") && cards[currentCardIndex]['source_ISO_639-1'] !== "") &&
                                                                        <CountryFlag animation={false} size="1em" countryCode={allLanguages[0][cards[currentCardIndex]['source_ISO_639-1']]['country_flag']} flagName={allLanguages[0][cards[currentCardIndex]['source_ISO_639-1']]['name']['en']} />
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                    
                                                </div>
                                                <div className="">
                                                    <input type="text" autoFocus={true} className={(error ? 'border-red-500 ':'')+ " bg-gray-200 appearance-none border-2 border-gray-200 rounded text-left py-2 h-12 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white"} value={inputText} onChange={handleInputChange} />
                                                </div>
                                            </div>
                                            <div className="text-lg text-red-500 text-center">
                                                {error && "Field can't be empty."}
                                            </div>
                                            <div className="flex flex-col gap-4">
                                            <motion.div whileHover={{scale:1.03}} onClick={handleSubmit} className=" bg-persian-green text-white font-bold rounded-lg p-6 shadow-lg text-lg cursor-pointer">
                                            <div className="flex flex-row flex-grow justify-center relative gap-8 text-center">
                                                        <div className="hidden sm:block font-bold text-lg bg-white px-2 left-0 text-black rounded-sm">
                                                            Enter
                                                        </div>
                                                        <div>
                                                            Submit answer
                                                        </div>
                                                    </div>
                                            </motion.div>
                                            <motion.div whileHover={{scale:1.03}} onClick={skipItemClick} className=" bg-charcoal w-full text-lg text-white font-bold rounded-lg p-3 shadow-lg  cursor-pointer">
                                                Skip exercise
                                            </motion.div>
                                            </div>
                                        </div>
                                    </>
                                :
                                    <>
                                        {correct ?
                                            <>
                                                <div className="flex text-3xl flex-row justify-center gap-4">
                                                    <div className="text-sandy-brown">
                                                        <FontAwesomeIcon icon={faStars} className="fa-beat" />
                                                    </div>
                                                    <div className="font-bold text-persian-green flex flex-row gap-2">
                                                        <div>{inputText.charAt(0).toUpperCase() + inputText.slice(1)}</div>
                                                         <div>=</div>
                                                         <div> 
                                                        {playMode === "source_first" || (playMode === "mix" && playModeArray[currentCardIndex] === "source_first") ?
                                                            <>
                                                                {cards[currentCardIndex].source.charAt(0).toUpperCase() + cards[currentCardIndex].source.slice(1)}
                                                            </>
                                                        : 
                                                            <>
                                                                {cards[currentCardIndex].target.charAt(0).toUpperCase() + cards[currentCardIndex].target.slice(1)}
                                                            </>
                                                        }
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="text-sandy-brown">
                                                        <FontAwesomeIcon icon={faStars} className="fa-beat" style={{'--fa-animation-direction': 'reverse'}} />
                                                    </div>
                                                </div>
                                            </> 
                                        :
                                            <>
                                                <div className="flex  flex-col justify-center gap-10">
                                                <div className="flex flex-row text-lg sm:text-5xl justify-start gap-10 place-items-center">
                                                    <div className="">
                                                        {playMode === "source_first" || (playMode === "mix" && playModeArray[currentCardIndex] === "source_first") ?
                                                            <>
                                                                {(cards[currentCardIndex].hasOwnProperty("source_ISO_639-1") && cards[currentCardIndex]['source_ISO_639-1'] !== "") &&
                                                                    <CountryFlag animation={false} size="1em" countryCode={allLanguages[0][cards[currentCardIndex]['source_ISO_639-1']]['country_flag']} flagName={allLanguages[0][cards[currentCardIndex]['source_ISO_639-1']]['name']['en']} />
                                                                }
                                                            </>
                                                        : 
                                                            <>
                                                                 {(cards[currentCardIndex].hasOwnProperty("target_ISO_639-1") && cards[currentCardIndex]['target_ISO_639-1'] !== "") &&
                                                                    <CountryFlag animation={false} size="1em" countryCode={allLanguages[0][cards[currentCardIndex]['target_ISO_639-1']]['country_flag']} flagName={allLanguages[0][cards[currentCardIndex]['target_ISO_639-1']]['name']['en']} />
                                                                }
                                                            </>
                                                        }
                                                        
                                                    </div>
                                                    <div className="">
                                                        {playMode === "source_first" || (playMode === "mix" && playModeArray[currentCardIndex] === "source_first") ?
                                                            <>
                                                                {cards[currentCardIndex].source}
                                                            </>
                                                        : 
                                                            <>
                                                                {cards[currentCardIndex].target}
                                                            </>
                                                        }
                                                    </div>
                                                    
                                                </div>
                                                <div className="flex flex-row text-lg sm:text-5xl justify-start gap-10 place-items-center">
                                                        <div className="">
                                                            {playMode === "source_first" || (playMode === "mix" && playModeArray[currentCardIndex] === "source_first") ?
                                                                <>
                                                                    {(cards[currentCardIndex].hasOwnProperty("target_ISO_639-1") && cards[currentCardIndex]['target_ISO_639-1'] !== "") &&
                                                                        <CountryFlag animation={false} size="1em" countryCode={allLanguages[0][cards[currentCardIndex]['target_ISO_639-1']]['country_flag']} flagName={allLanguages[0][cards[currentCardIndex]['target_ISO_639-1']]['name']['en']} />
                                                                    }
                                                                </>
                                                            : 
                                                                <>
                                                                     {(cards[currentCardIndex].hasOwnProperty("source_ISO_639-1") && cards[currentCardIndex]['source_ISO_639-1'] !== "") &&
                                                                        <CountryFlag animation={false} size="1em" countryCode={allLanguages[0][cards[currentCardIndex]['source_ISO_639-1']]['country_flag']} flagName={allLanguages[0][cards[currentCardIndex]['source_ISO_639-1']]['name']['en']} />
                                                                    }
                                                                </>
                                                            }
                                                            
                                                        </div>
                                                        <div className="">
                                                            <div className="flex w-full flex-col text-lg gap-2">
                                                                <div className="text-red-500 w-full flex flex-row gap-2">
                                                                    <div>
                                                                        You wrote:
                                                                    </div>
                                                                    <div className="font-bold">
                                                                        {inputText}
                                                                    </div>
                                                                </div>
                                                                <div className=" text-persian-green w-full flex flex-row gap-2">
                                                                <div>Correct:</div>
                                                                <div className="font-bold">
                                                                {playMode === "source_first" || (playMode === "mix" && playModeArray[currentCardIndex] === "source_first") ?
                                                                    <>
                                                                        {cards[currentCardIndex].target}
                                                                    </>
                                                                : 
                                                                    <>
                                                                        {cards[currentCardIndex].source}
                                                                    </>
                                                                }
                                                                </div>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                <div className="flex flex-col  gap-6">
                                                    <motion.div onClick={wrongItemNextQuestion} whileHover={{scale:1.03}} className=" bg-persian-green  text-white font-bold rounded-lg p-6 shadow-lg text-lg cursor-pointer">
                                                        <div className="flex flex-row  flex-grow justify-center relative gap-6  text-center">
                                                            <div className="font-bold bg-white text-lg px-2 left-0 text-black rounded-sm">
                                                                Enter
                                                            </div>
                                                            <div>
                                                                Next exercise
                                                            </div>
                                                        </div>
                                                    </motion.div>
                                                    <motion.div onClick={wrongItemButIWasCorrectClick} whileHover={{scale:1.03}} className=" bg-burnt-sienna  text-white font-bold rounded-lg p-3 shadow-lg text-lg cursor-pointer">
                                                        Override: My answer was correct!
                                                    </motion.div>
                                                    
                                                    </div>
                                                </div>
                                                
                                            </>
                                        }
                                    </>
                                }
                            </div>
                            </>
                            }
                            
                        </div>
                    </>
                    
                }
        </>
    )
}

export default InputGame