import React, { useContext, useState, useEffect } from "react";
import useUserSettings from '../hooks/useUserSettings';
import useFavorites from '../hooks/useFavorites';
import {useAuth} from '../contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-duotone-svg-icons';

const UserInfoContext = React.createContext();

export function useUserInfoContext() {
    return useContext(UserInfoContext);
};

export function UserInfoProvider({ children }) {
    const {currentUser} = useAuth();
    const {fetchCurrentUserInfo, 
        fetchCurrentUserSettings, 
        getAccountInformationFromCurrentUser} = useUserSettings();
    const {getUserFavoritesRealtime} = useFavorites();
    const [userInfoContextLoading, setUserInfoContextLoading] = useState(true);
    const [userInfo, setUserInfo] = useState(null);
    const [currentGlobalDecksViewMode, setCurrentGlobalDecksViewMode] = useState(null);
    const [userSettings, setUserSettings] = useState(null);
    const [error, setError] = useState(null);

    const [favorites, setFavorites] = useState(null);

    const refreshUserSettings = async () => {
        let settings = await fetchCurrentUserSettings();
        setUserSettings(settings);
    }

    const refreshUserInfo = async () => {
        let r = await fetchCurrentUserInfo().catch(error=>setError(error));
        let r2 = await getAccountInformationFromCurrentUser().catch(error=>setError(error));
        setUserInfo({...r, ...r2});
    }

    useEffect(()=>{
        if (userSettings !== null) {
          if ('my_decks' in userSettings && 'default_decks_view' in userSettings['my_decks']){
            //setCurrentGlobalDecksViewMode(userSettings['my_decks']['default_decks_view']);
            setCurrentGlobalDecksViewMode("all");
          } else {
            setCurrentGlobalDecksViewMode("all");
          }
        }
    },[userSettings]);


    useEffect(()=> {
        if (currentUser === null){
            setUserInfo(null);
            setUserInfoContextLoading(false);
            setCurrentGlobalDecksViewMode(null);
            setError(null);
            setFavorites(null);
            // reset all variables (log out and into new account should not persist state)
        }
    },[currentUser])

    useEffect(()=> {
        const fetchData = async () => {
            if (currentUser !== null){
                let r = await fetchCurrentUserInfo().catch(error=>setError(error));
                let r2 = await getAccountInformationFromCurrentUser().catch(error=>setError(error));
                let settings = await fetchCurrentUserSettings();
                setUserInfo({...r, ...r2});
                setUserSettings(settings);
                setUserInfoContextLoading(false);
            }
        }
        fetchData();
    },[currentUser]);

    useEffect(()=>{
        let unsub = null;
        const f = async () => {
            unsub = await getUserFavoritesRealtime(setFavorites);
        }
        if (currentUser !== null){
            f();
        }
        return () => {
            unsub !== null && unsub();
        }
    },[currentUser])


    const value = {
        userInfo, 
        userSettings, 
        refreshUserSettings, 
        currentGlobalDecksViewMode,
        setCurrentGlobalDecksViewMode, 
        favorites,
        userInfoContextLoading, 
        refreshUserInfo
    }

    return (
    <UserInfoContext.Provider value={value}>
        {children}
        {error !== null &&
        <>
          <div className="text-black">Error: {error}</div>
        </>
        }
    </UserInfoContext.Provider>
    )
}