import React, {useEffect, useState} from 'react';
import Button from '../../../../components/general/Button';
import {Link} from 'react-router-dom';

const FillInGameScreen = ({table, howManyItems, tableColumns, tableRows, hideTitle, hideInstructions}) => {
    
    const [tableRowsExercises, setTableRowsExercises] = useState(null);
    const [tableRowsCorrections, setTableRowsCorrection] = useState(null);
    const [showCorrections, setShowCorrections] = useState(false);
    const [showCorrectAnswers, setShowCorrectAnswers] = useState(false);
    const [nbOfCorrectAnswers, setNbOfCorrectAnswers] = useState(null);
    const [nbOfWrongAnswers, setNbOfWrongAnswers] = useState(null);

    useEffect(()=>{
        // make exercises
        function randomIntFromInterval(min, max) { // min and max included 
            return Math.floor(Math.random() * (max - min + 1) + min)
        }
        /* Randomize array in-place using Durstenfeld shuffle algorithm */
        function shuffleArray(array) {
            for (var i = array.length - 1; i > 0; i--) {
                var j = Math.floor(Math.random() * (i + 1));
                var temp = array[i];
                array[i] = array[j];
                array[j] = temp;
            }
        }

        if (tableRows !== null && tableColumns !== null){
            let list = [];
            tableRows.forEach((tableRow)=>{
                let i = randomIntFromInterval(0, tableColumns.length-1);
                let chosenColumn = tableColumns[i].id;
                let obj = {
                    'id': tableRow.id, 
                    'values': {}, 
                    'chosen': chosenColumn, 
                    'correct': {}
                };
                tableColumns.forEach((col)=>{
                    if (col.id === chosenColumn){
                        obj['values'][col.id] = tableRow.values[col.id];
                    } else {
                        obj['values'][col.id] = "";
                    }
                    obj['correct'][col.id] = tableRow.values[col.id];
                });
                list.push(obj);
            });
            shuffleArray(list);
            setTableRowsExercises(list);
        }
    },[tableRows, tableColumns, howManyItems]);

    const rowInputOnChange = (text, colId, rowIndex) => {
        showCorrections && setShowCorrections(false);
        let copy = JSON.parse(JSON.stringify(tableRowsExercises));

        copy[rowIndex]['values'][colId] = text;
        setTableRowsExercises(copy);
    }

    const verifyAnswersClick = () => {
        let corrections = [];
        let nbCorrect = 0;
        let nbWrong = 0;
        for (const newRow of tableRowsExercises){
            let rowCorrections = {};
            for (const originalRow of tableRows){
                if (newRow.id === originalRow.id){
                    for (const [key, value] of Object.entries(newRow.values)){
                        if (value !== undefined && value !== null && originalRow.values[key] !== undefined && originalRow.values[key] !== null && value.toLowerCase() === originalRow.values[key].toLowerCase()){
                            rowCorrections[key] = true;
                            if (newRow.chosen !== parseInt(key)){
                                nbCorrect = nbCorrect + 1;
                            }
                        } else {
                            rowCorrections[key] = false;
                            if (newRow.chosen !== parseInt(key)){
                                nbWrong = nbWrong + 1;
                            }
                        }
                    }
                }
            }
            corrections.push(rowCorrections);
        }
        setTableRowsCorrection(corrections);
        setShowCorrections(true);
        setNbOfCorrectAnswers(nbCorrect);
        setNbOfWrongAnswers(nbWrong);
    }

    const hideCorrections = () => {
        setShowCorrections(false);
        setShowCorrectAnswers(false);
    }

    return (
        <>
            {(table !== null && !hideTitle) && <h1>{table.name}</h1>}
            {!hideInstructions &&
                <p className="text-center font-bold text-gray-700 pb-4">Fill in the empty slots.</p>
            }
            <div>
                <div className="overflow-x-auto">
                <table className={"w-full  border-collapse "}>
                    <thead className="w-full text-white font-bold bg-persian-green">
                        <tr>
                            {tableColumns.map((col, index)=>(
                                <>
                                        <th key={"head_"+index} className="p-3 whitespace-nowrap py-4">
                                            {col.name}
                                        </th>
                                </>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableRowsExercises !== null && tableRowsExercises.map((row, rowIndex)=>(
                            <>
                                <tr key={"tr_"+rowIndex}>
                                    {tableColumns.map((col, colIndex)=>(
                                        <>
                                            <td className="p-5 overflow-x-auto whitespace-nowrap">
                                                {row['chosen'] === col.id ?
                                                    <input disabled={true} type="text" value={row['values'][col.id] !== undefined ? row['values'][col.id] : ''} className="cursor-disabled no-outline min-w-36 w-full border font-bold border-solid bg-gray-300  px-2 py-1" />
                                                :
                                                    showCorrections ?
                                                        <>
                                                            {showCorrectAnswers ?
                                                                <>
                                                                    <div className={"flex min-w-36 w-full overflow-x-auto flex-row gap-2 font-bold " + (row['values'][col.id] === row['correct'][col.id] ? 'text-green-500 ' : 'text-green-500')}>
                                                                        <span className="">{row['correct'][col.id] !== undefined ? row['correct'][col.id] : ''}</span>
                                                                        {row['values'][col.id] !== row['correct'][col.id] && 
                                                                            <span className="text-red-500 line-through">{row['values'][col.id]}</span>
                                                                        }
                                                                    </div>
                                                                </>
                                                            :
                                                                <>
                                                                    <input type="text" onChange={(e)=>rowInputOnChange(e.target.value, col.id, rowIndex)} value={row['values'][col.id] !== undefined ? row['values'][col.id] : ''} className={(tableRowsCorrections[rowIndex][col.id] ? 'border-green-400 ' : 'border-red-400 ') + " no-outline min-w-36 w-full border-2 rounded-lg border-solid bg-white   px-2 py-1"} />
                                                                </>
                                                            }
                                                        </>
                                                    :
                                                        <>
                                                            <input type="text" onChange={(e)=>rowInputOnChange(e.target.value, col.id, rowIndex)} value={row['values'][col.id] !== undefined ? row['values'][col.id] : ''} className="no-outline min-w-36 w-full border border-solid bg-white  border-gray-700 px-2 py-1" />
                                                        </>
                                                    
                                                }
                                            </td>
                                        </>
                                    ))}
                                </tr>
                            </>
                        ))}
                    </tbody>
                </table>
                </div>
            </div>
            <div className="flex flex-col gap-2 justify-center place-items-center my-4">
                <Button onClick={showCorrections ? hideCorrections : verifyAnswersClick} text={showCorrections ? "Continue playing" : "Check answers"} color="green" />
                {showCorrections &&
                    <>
                        <div onClick={()=>setShowCorrectAnswers(!showCorrectAnswers)} className="underline cursor-pointer">
                            {showCorrectAnswers ? 'Hide correct answers' : 'Show me the correct answers'}
                        </div>
                        <div className="font-bold text-gray-700 text-lg my-4">
                            You got {nbOfCorrectAnswers} out of {nbOfCorrectAnswers+nbOfWrongAnswers} correct!
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default FillInGameScreen